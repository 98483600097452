import React, { createContext, useState } from "react"

export const ChatContext = createContext(null)

const ChatContextProvider = ({children})=> {
  const [isChatOpen, setIsChatOpen] = useState(false)
  return (
    <ChatContext.Provider value={{isChatOpen, setIsChatOpen}}>{children}</ChatContext.Provider>
  )
}

export default ChatContextProvider