import React from "react";
import detailimg from "./../../assets/images/image-3.png";
import union from "./../../assets/images/unionicon.png";
import right from "./../../assets/images/right-arrow.png";
import calender from "./../../assets/images/calender.png";
import card_1 from "./../../assets/images/pdbanner.png";
import alpha from "./../../assets/images/alpha.png";
import { useState, useEffect } from "react";
import { getAPI } from "../../services/apiInstance";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ROUTE_CONST } from "../../constants/index";
import TimerComp from "./components/TimerComp";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import banner from "../../../src/assets/images/promotions/promotionDetailBanner.png";
import backIcon from "../../../src/assets/images/promotions/backIcon.png";

const PromotionDetail = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [endDate, setEndDate] = useState(null);
  const promotionId = useParams()?.promotionId;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = useAuth();

  const getPromotionDetails = async () => {
    setLoading(true);
    try {
      const res = await getAPI(
        `get-promotion-discription?promotionId=${promotionId}`
      );
      setData(res?.data?.data[0]);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getPromotionDetails();
  }, []);

  useEffect(() => {
    const el = document.getElementById("contentMaterial");
    if (el) {
      el.innerHTML = data?.description;
    }
  }, [data?.description]);

  useEffect(() => {
    if (data.endDate) {
      function getOrdinalSuffix(day) {
        if (day > 3 && day < 21) return `${day}th`;
        const suffixes = ["th", "st", "nd", "rd"];
        return `${day}${suffixes[day % 10 > 3 ? 0 : day % 10]}`;
      }

      function formatDate(dateString) {
        const date = new Date(dateString);
        const day = getOrdinalSuffix(date.getDate());
        const month = date.toLocaleString("en-GB", { month: "long" });
        const year = date.getFullYear();

        return `${day} of ${month} ${year}`;
      }
      setEndDate(formatDate(data.endDate));
    }
  }, [data]);

  return (
    <>
      <div className="containerSm">
        <div className="row gx-0 justify-content-center">
          <div className="col" style={{ maxWidth: 800 }}>
            <div className="d-flex justify-content-between align-items-center px-1">
              <div
                style={{
                  marginBottom: "33px",
                  display: "flex",
                  gap: "11px",
                  alignItems: "center",
                }}
              >
                <div
                onClick={() => navigate(-1)}
                  style={{
                    backgroundColor: "#2F364C",
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img src={backIcon} alt="" />
                </div>

                <div
                  style={{
                    fontWeight: 700,
                    fontSize: "18px",
                    lineHeight: "22.5px",
                  }}
                >
                  Promotion
                </div>
              </div>
              <div className=" d-lg-none img_none ratio-1x1">
                <img src={alpha} alt="" className="h-100 w-100" />
              </div>
            </div>
            <div className="w-100 ratio-16x9 overflow-hidden proBannerOuter">
              <img
                src={data?.image}
                alt=""
                className="w-100 h-100 object-fit-cover"
                style={{ minHeight: 150 }}
              />
            </div>

            <div style={{ marginTop: "14px" }} className="text-container">
              <div
                style={{
                  fontWeight: 700,
                  fontSize: "25px",
                  lineHeight: "31.25px",
                }}
              >
                {data?.text}
              </div>

              <div
                style={{
                  marginTop: "23px",
                  fontWeight: 400,
                  fontSize: "18px",
                  lineHeight: "22.5px",
                }}
              >
                Play Anywhere, Anytime!
              </div>

              <div
                style={{
                  marginTop: "6px",
                  fontWeight: 300,
                  fontSize: "16px",
                  lineHeight: "20px",
                  color: "#6A798C",
                }}
              >

                📅 {endDate}
              </div>

              <div
                style={{
                  marginTop: "14px",
                  fontWeight: 600,
                  fontSize: "18px",
                  lineHeight: "22.5px",
                }}
              >
                Download Now
              </div>

              {/* <div> */}
              {/* <div className="heading">Participating Games</div> */}
              {/* <div className="detail-text" id="contentMaterial">
                  {data?.description}
                </div> */}
              {/* </div> */}
              {/* <div className="main-heading">Participating Games to win</div>
              {
                data?.games?.length && (
                  <div className="cardHolder row g-2 row-cols-3 row-cols-lg-5">
                    {
                      data?.games?.map(item=> (
                        <div className="gameCardouter">
                          <div className="cardImg ">
                            <img src={item?.image} alt="" className="h-100 w-100" />
                          </div>
                          <div className="cardText">{item?.name}</div>
                        </div>
                      ))
                    }
                  </div>
                )
              } */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromotionDetail;
