import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AuthModalContextProvider from "../context/AuthModalContext";
import AccountContextProvider from "../context/AccountContext";
import LogoutModalContextProvider from "../context/LogoutContext";
import AuthContextProvider from "../context/authContext";
import store from "../store";
import { ToastContainer } from "react-toastify";
import SpinModalContextProvider from "../context/freeSpinModalContext";
import ReferralModalContextProvider from "../context/ReferralContext";
import AuthBtContextProvider from "../context/authBtContext";
import ClaimBonusContextProvider from "../context/ClaimBonusContext";
import NotificationProvider from "../context/notificationContext";
import WalletModalContextProvider from "../context/walletModalContext";
import ResultModalContextProvider from "../context/resultModalContext";
import GameContextProvider from "../context/gameContext";
import KadoWalletContextProvider from "../context/KadoWalletContext";
import DownloadModalContextProvider from "../context/downloadModalContext";
import SpinContextProvider from "../context/spinWheelContext";
import BonusContextProvider from "../context/bonusContext";
import SubscribeModalContextProvider from "../context/subscribeModalContext";
import ChatContextProvider from "../context/chatContext";
import LoginSuccessContextProvider from "../context/loginSuccessModalContext";

const Providers = ({ children }) => {
  return (
    <>
      <BrowserRouter>
        <ReduxProvider store={store}>
          <AuthModalContextProvider>
            <AccountContextProvider>
              <LogoutModalContextProvider>
                <LoginSuccessContextProvider>
                  <AuthContextProvider>
                    <SpinModalContextProvider>
                      <ReferralModalContextProvider>
                        <AuthBtContextProvider>
                          <ClaimBonusContextProvider>
                            <NotificationProvider>
                              <WalletModalContextProvider>
                                <ResultModalContextProvider>
                                  <GameContextProvider>
                                    <KadoWalletContextProvider>
                                      <DownloadModalContextProvider>
                                        <SpinContextProvider>
                                          <BonusContextProvider>
                                            <SubscribeModalContextProvider>
                                              <ChatContextProvider>
                                                {children}
                                              </ChatContextProvider>
                                            </SubscribeModalContextProvider>
                                          </BonusContextProvider>
                                        </SpinContextProvider>
                                      </DownloadModalContextProvider>
                                    </KadoWalletContextProvider>
                                  </GameContextProvider>
                                </ResultModalContextProvider>
                              </WalletModalContextProvider>
                            </NotificationProvider>
                          </ClaimBonusContextProvider>
                        </AuthBtContextProvider>
                      </ReferralModalContextProvider>
                    </SpinModalContextProvider>
                  </AuthContextProvider>
                </LoginSuccessContextProvider>
              </LogoutModalContextProvider>
            </AccountContextProvider>
          </AuthModalContextProvider>
          <ToastContainer />
        </ReduxProvider>
      </BrowserRouter>
    </>
  );
};

export default Providers;
