import React, { useState, useEffect } from "react";
import { getAPI } from "../../services/apiInstance";
import AllBetsTable from "./AllBetsTable.jsx";
import HighBetsTable from "./HighBetsTable.jsx";
import LowBetsTable from "./LowBetsTable.jsx";
import { useTranslation } from "react-i18next";

const BetTable = () => {
  const [activeTable, setActiveTable] = useState("get-bets");
  const [allBets, setAllBets] = useState([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation()
  const casinoLabels = t("casinoLabels", { returnObjects: true })

  const getAllBets = async (intervalCall) => {
    if(!intervalCall){
      setLoading(true);
    }
    try {
      const res = await getAPI(`casino/${activeTable}`);
        setAllBets(res?.data?.data);
    } catch (error) {
    }finally{
      if(!intervalCall){
        setLoading(false);
      }
    }
  };


  useEffect(() => {
    const interval = setInterval(() => {
      getAllBets(true);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);//eslint-disable-line

  useEffect(() => {
    getAllBets();
  }, [activeTable]);//eslint-disable-line


  return (
    <>
    <div className="col-lg-12 col-12">
      <div className="row mx-0 py-3 py-md-0 sectionSpacing">
        <div className="col-12 px-0">
          <div className="row mx-0 gx-0 text-nowrap flex-nowrap overflow-x-auto betTableOptionWrapper">
            <div className="col-auto">
              <div
                onClick={() => setActiveTable("get-bets")}
                className={`betTableOption ${
                  activeTable === "get-bets" ? "active" : ""
                }`}
              >
                {casinoLabels.All_bets}
              </div>
            </div>
            <div className="col-auto">
              <div
                onClick={() => setActiveTable("high-bet")}
                className={`betTableOption ${
                  activeTable === "high-bet" ? "active" : ""
                }`}
              >
                {casinoLabels.High_rollers}
              </div>
            </div>
            <div className="col-auto">
              <div
                onClick={() => setActiveTable("low-bet")}
                className={`betTableOption ${
                  activeTable === "low-bet" ? "active" : ""
                }`}
              >
                {casinoLabels.Lucky_wins}
              </div>
            </div>
            {/* <div className="col-auto">
              <div
                onClick={() => setActiveTable("trades")}
                className={`betTableOption ${
                  activeTable === "trades" ? "active" : ""
                }`}
              >
                {casinoLabels.Trades}
              </div>
            </div>
            <div className="col-auto">
              <div
                onClick={() => setActiveTable("withdrawal")}
                className={`betTableOption ${
                  activeTable === "withdrawal" ? "active" : ""
                }`}
              >
                {casinoLabels.Withdrawals}
              </div>
            </div> */}
          </div>
        </div>
        {activeTable === "get-bets" ? (
          <AllBetsTable data={allBets} loading={loading} />
        ) : (
          <></>
        )}
        {activeTable === "high-bet" ? (
          <HighBetsTable data={allBets} loading={loading} />
        ) : (
          <></>
        )}
        {activeTable === "low-bet" ? (
          <LowBetsTable data={allBets} loading={loading} />
        ) : (
          <></>
        )}
      </div>
      </div>
    </>
  );
};

export default BetTable;
