import React from "react";

const AffiliateIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66152 5.15665L4.77556 4.31934C4.87875 4.13186 4.94259 3.91959 4.94259 3.69058C4.94259 2.96483 4.35473 2.37698 3.62867 2.37698C2.90261 2.37698 2.31383 2.96452 2.31383 3.69058C2.31383 4.41664 2.90261 5.0048 3.62867 5.0048C3.88464 5.0048 4.12139 4.92857 4.32344 4.80214L5.20816 5.63852C5.34203 5.46158 5.49294 5.29951 5.66152 5.15665Z"
        fill="#2F93DD"
      />
      <path
        d="M9.40896 5.05191L10.5862 3.07081C10.7021 3.10397 10.8223 3.12783 10.9485 3.12783C11.6758 3.12783 12.2636 2.53966 12.2636 1.8136C12.2636 1.08785 11.6758 0.5 10.9485 0.5C10.2224 0.5 9.63487 1.08754 9.63487 1.8136C9.63487 2.174 9.78021 2.49969 10.0151 2.73737L8.84156 4.71289C9.0433 4.80431 9.23357 4.91834 9.40896 5.05191Z"
        fill="#2F93DD"
      />
      <path
        d="M13.4691 6.71042C12.9039 6.71042 12.4251 7.06957 12.2395 7.57035L10.6039 7.4089C10.6039 7.42129 10.6058 7.43307 10.6058 7.44515C10.6058 7.65836 10.5822 7.8666 10.5397 8.06771L12.1747 8.22916C12.2736 8.85637 12.8137 9.33731 13.4691 9.33731C14.1948 9.33731 14.7824 8.74977 14.7824 8.02402C14.7824 7.29858 14.1948 6.71042 13.4691 6.71042Z"
        fill="#2F93DD"
      />
      <path
        d="M8.95562 11.8768L8.58995 10.2791C8.38357 10.3509 8.16882 10.4024 7.94477 10.4281L8.31106 12.0262C7.89116 12.2459 7.60142 12.68 7.60142 13.187C7.60142 13.9128 8.18896 14.5 8.91626 14.5C9.64201 14.5 10.2296 13.9125 10.2296 13.187C10.2305 12.4746 9.66278 11.8979 8.95562 11.8768Z"
        fill="#2F93DD"
      />
      <path
        d="M4.6668 8.07948L2.63085 8.76154C2.39596 8.40269 1.99156 8.1647 1.53138 8.1647C0.805316 8.1647 0.217773 8.75255 0.217773 9.4783C0.217773 10.205 0.805316 10.7928 1.53138 10.7928C2.25682 10.7928 2.84591 10.205 2.84591 9.4783C2.84591 9.44793 2.8394 9.42004 2.83692 9.39029L4.87938 8.70638C4.78734 8.50774 4.71421 8.29857 4.6668 8.07948Z"
        fill="#2F93DD"
      />
      <path
        d="M7.60235 9.69832C8.84674 9.69832 9.85552 8.68954 9.85552 7.44515C9.85552 6.20075 8.84674 5.19197 7.60235 5.19197C6.35795 5.19197 5.34917 6.20075 5.34917 7.44515C5.34917 8.68954 6.35795 9.69832 7.60235 9.69832Z"
        fill="#2F93DD"
      />
    </svg>
  );
};

export default AffiliateIcon;
