import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userBalanceAction } from "../../store/action";
import { Dropdown, Spinner } from "react-bootstrap";
import { convertToEightDigitsAfterDecimal } from "../../utils/convertToEightDigitsAfterDecimal";
import { useAuth } from "../../hooks/useAuth";
import { getAPIAuth } from "../../services/apiInstance";
import FiatErrorModal from "../fiatErrorModal/FiatErrorModal";
import { useTranslation } from "react-i18next";
import NoData from "./../../assets/images/Isolation_Mode.png";

const option = {
  CURRENCY: "CURRENCY",
  mNFT: "mNFT",
};

const ModeOptionModal = ({
  setDemo,
  setShowOptionModal,
  isLoading,
  showError,
  setModeType,
}) => {
  const [loading, setLoading] = useState(false);
  // const favFiatDetails = useSelector(state => state.favFiat);
  // const favCryptoDetails = useSelector(state => state.favCrypto);
  const { t } = useTranslation();
  const appSideAppLabels = t("appSideApp", { returnObjects: true });
  const userDetails = useSelector((state) => state.userDetails);
  const userBalance = useSelector((state) => state.userBalance);
  const dispatch = useDispatch();
  const [search] = useState();
  const { token } = useAuth();
  const [cryptoData, setCryptoData] = useState([]);
  const [fiatData, setFiatData] = useState([]);
  const [showFavoriteModal, setShowFavoriteModal] = useState(false);
  const [favCrypto, setFavCrypto] = useState([]);
  const [favFiat, setFavFiat] = useState([]);
  const favFiatDetails = useSelector((state) => state.favFiat);
  const favCryptoDetails = useSelector((state) => state.favCrypto);
  const [isChecked, setIsChecked] = useState(false);
  const [userCurrency, setUserCurrency] = useState();
  const [currencyLoader, setCurrencyLoader] = useState({
    index: "",
    status: false,
    type: "",
  });
  const [isHidden, setIsHidden] = useState(false);
  const [showFiatModal, setShowFiatModal] = useState(false);
  const [currencySearch, setCurrencySearch] = useState("");
  const [selectedDrop, setSelectedDrop] = useState(option.CURRENCY);

  const getFav = async () => {
    try {
      const res = await getAPIAuth(
        `crypto/get-fav-currency-crypto?userId=${userDetails?.id}`,
        token
      );
      if (res?.data?.success) {
        setFavFiat(res?.data?.fiat);
        setFavCrypto(res?.data?.crypto);
      }
    } catch (error) {}
  };

  const getUserBal = async () => {
    try {
      const res = await getAPIAuth(
        `user/user-balance?userId=${userDetails?.id}`,
        token
      );
      if (res?.data?.success) {
        // setUserBal(res.data.data)
        dispatch(userBalanceAction(res.data.data));
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (userDetails?.id) {
      getUserBal();
      getFav();
      // userCryptoDetails();
    }
  }, [
    userDetails.id,
    favFiatDetails,
    favCryptoDetails,
    showFavoriteModal,
    isChecked,
  ]);
  useEffect(() => {
    if (favCryptoDetails) {
      setCryptoData(favCryptoDetails);
    }
  }, [favCryptoDetails]);

  useEffect(() => {
    if (favFiatDetails) {
      setFiatData(favFiatDetails);
    }
  }, [favFiatDetails]);

  const handlePlayDemo = () => {
    setDemo(true);
    // setLoading(true)
    // setTimeout(() => {
    setShowOptionModal(false);
    setLoading(false);
    // }, 4000);
  };

  useEffect(() => {
    if (search) {
      const data = favCryptoDetails.filter(
        (item) =>
          item?.code.toLowerCase().includes(search?.toLowerCase()) ||
          item?.name.toLowerCase().includes(search?.toLowerCase())
      );
      setCryptoData(data);

      const dataFiat = favFiatDetails.filter(
        (item) =>
          item?.code.toLowerCase().includes(search?.toLowerCase()) ||
          item?.name.toLowerCase().includes(search?.toLowerCase())
      );

      // setCryptoData(dataFiat)
      setFiatData(dataFiat);
    } else {
      setCryptoData(favCryptoDetails);
      setFiatData(favFiatDetails);
    }
  }, [search]);
  const handleChange = async (item) => {
    try {
      const res = await getAPIAuth(
        `user/setCryptoForUser?currency=${item.code}&type=${item.type}&userId=${userDetails.id}`,
        token
      );
      if (res?.data?.success) {
        // setUserCurrency(res.data.data);
        // get user updated wallet info--->>>
        const getUserBal = async () => {
          try {
            const res = await getAPIAuth(
              `user/user-balance?userId=${userDetails?.id}`,
              token
            );
            if (res?.data?.success) {
              // setUserBal(res.data.data)
              dispatch(userBalanceAction(res.data.data));
            }
          } catch (error) {}
        };
        getUserBal();
        // const dropdownMenus = document.getElementsByClassName("dropdown-menu show");
        // const dropdownMenusArray = Array.from(dropdownMenus);
        // dropdownMenusArray.map(menu => {
        //   menu.classList.remove("show");
        // });
      }
    } catch (error) {
    } finally {
      setCurrencyLoader((pre) => ({
        ...pre,
        index: "",
        status: false,
        type: "",
      }));
    }
  };

  const handleViewFiat = async (value) => {
    try {
      const res = await getAPIAuth(
        `user/setFiatForUser?fiatStatus=${value}&userId=${userDetails.id}`,
        token
      );
      if (res?.data?.success) {
        setIsChecked(res.data.data.fiatStatus);
        if (res.data.data.fiatStatus === true) {
          setShowFiatModal(true);
        } else if (res.data.data.fiatStatus === false) {
          setShowFiatModal(false);
        }
      }
    } catch (error) {}
  };
  const searchHandler = (e) => {
    if (e !== "") {
      const res = getAPIAuth(
        `crypto/get-fav-currency-crypto?userId=${
          userDetails.id
        }&search=${e.trim(" ")}`
      );
      if (res?.data?.success) {
        setFavFiat(res?.data?.fiat);
        setFavCrypto(res?.data?.crypto);
      }
    }
  };
  return (
    <>
      <div className="row mx-0 w-100 justify-content-center align-items-center modeSelectDialogWrapper">
        <div className="col-12 d-flex justify-content-center">
          <div className="modeSelectDialog w-100  d-flex flex-column newModeSelectDialog">
            <div className="row lower mx-0">
              <div className="col-12 col-12 mx-auto">
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    className="playwithHeading text-light"
                    style={{
                      fontSize: 18,
                      fontWeight: 600,
                    }}
                  >
                    {appSideAppLabels.Play_with_Balance}
                  </div>

                  <Dropdown className="coindropdown">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="headerFiatDrop coinBtn"
                    >
                      <span className="coinImgs d-flex align-items-center justify-content-center rounded-pill overflow-hidden">
                        <img
                          src={userBalance?.setCurrencyData?.icon}
                          alt="coin"
                          className="h-100 w-100"
                        />
                      </span>
                      {userBalance?.setCurrencyData?.code}{" "}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="innerUsdtBtn userBalInnerDrop">
                      <div className="row mx-0">
                        <div className="col-12 px-0">
                          <div className="row mx-0">
                            <div className="col px-0">
                              <div className="searchInp">
                                <img src="./assets/img/searchGray.png" alt="" />
                                <input
                                  type="text"
                                  className="form-control shadow-none"
                                  placeholder="Search"
                                  value={currencySearch}
                                  // onChange={(e) => searchHandler(e.target.value)}
                                  onChange={(e) =>
                                    setCurrencySearch(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-auto ps-2 pe-0">
                              <div
                                className="plusBtn"
                                onClick={() => setShowFavoriteModal(true)}
                              >
                                +
                                {/* <AddCurrency showFavoriteModal={showFavoriteModal} /> */}
                              </div>
                            </div>
                            <div className="col-12 px-0">
                              <div className="dropTabs">
                                <div
                                  className={`tab ${
                                    selectedDrop === option.CURRENCY
                                      ? "active"
                                      : ""
                                  } `}
                                  onClick={() =>
                                    setSelectedDrop(option.CURRENCY)
                                  }
                                >
                                  Currency
                                </div>
                                <div
                                  className={`tab ${
                                    selectedDrop === option.mNFT ? "active" : ""
                                  } `}
                                  onClick={() => setSelectedDrop(option.mNFT)}
                                >
                                  mNFT
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {selectedDrop === option.CURRENCY ? (
                          <>
                            <div className="innerScrollDiv">
                              <div className="col-12 px-0">
                                {favFiat.length > 0 ? (
                                  <>
                                    {isHidden ? (
                                      ""
                                    ) : (
                                      <div className="dropTitle mb-1">Fiat</div>
                                    )}

                                    <ul className="m-0 p-0 list-unstyled usdtImageDropdownInner">
                                      {favFiat?.map((item, index) => (
                                        <Dropdown.Item
                                          as="button"
                                          key={index}
                                          className={`usdtImageDropdownInnerList d-flex justify-content-between align-items-center ${
                                            isHidden &&
                                            parseFloat(item.totalBalance) ===
                                              0 &&
                                            parseFloat(item.totalBonus) === 0
                                              ? "d-none"
                                              : ""
                                          }  ${
                                            item.code ===
                                            userBalance?.setCurrencyData?.code
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            setCurrencyLoader((pre) => ({
                                              ...pre,
                                              index: index,
                                              status: true,
                                              type: "fiat",
                                            }));
                                            handleChange(item);
                                          }}
                                        >
                                          <div className="d-flex align-items-center">
                                            {currencyLoader.index == index &&
                                            currencyLoader.status &&
                                            currencyLoader.type == "fiat" ? (
                                              <>
                                                <Spinner size="sm" />
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  className="rounded"
                                                  src={item.icon}
                                                  alt=""
                                                />
                                              </>
                                            )}
                                            {item.code}
                                          </div>
                                          <div>
                                            {/* {userDetails.fiatStatus ? item.symbol + ' ' + item.totalBalance : item.wallet?.totalBalance ? item.wallet?.totalBalance : '0'} */}
                                            {userDetails.fiatStatus
                                              ? userDetails?.currency?.symbol
                                              : ""}{" "}
                                            {item?.totalBalance
                                              ? convertToEightDigitsAfterDecimal(
                                                  item?.totalBalance
                                                )
                                              : "0.00"}
                                            {userDetails.fiatStatus ? (
                                              <span className="smallVal">
                                                {" "}
                                                {item.wallet?.totalBalance
                                                  ? convertToEightDigitsAfterDecimal(
                                                      item.wallet?.totalBalance
                                                    )
                                                  : "0.00"}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </Dropdown.Item>
                                      ))}
                                    </ul>
                                  </>
                                ) : null}
                              </div>
                              <div className="col-12 px-0">
                                {favCrypto.length > 0 ? (
                                  <>
                                    {/* <div className="dropTitle mb-1">Crypto</div> */}
                                    {isHidden ? (
                                      ""
                                    ) : (
                                      <div className="dropTitle mb-1">
                                        Crypto
                                      </div>
                                    )}

                                    <ul className="m-0 p-0 list-unstyled usdtImageDropdownInner">
                                      {/*     */}
                                      {favCrypto?.map((item, index) => (
                                        <Dropdown.Item
                                          as="button"
                                          key={index}
                                          className={`usdtImageDropdownInnerList d-flex justify-content-between align-items-center ${
                                            isHidden &&
                                            parseFloat(item.totalBalance) ===
                                              0 &&
                                            parseFloat(item.totalBonus) === 0
                                              ? "d-none"
                                              : ""
                                          }  ${
                                            item.code ===
                                            userBalance?.setCurrencyData?.code
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            // setCurrencyLoader(true)
                                            setCurrencyLoader((pre) => ({
                                              ...pre,
                                              index: index,
                                              status: false,
                                              type: "crypto",
                                            }));

                                            handleChange(item);
                                          }}
                                        >
                                          <div className="d-flex align-items-center">
                                            {currencyLoader.index == index &&
                                            currencyLoader.status &&
                                            currencyLoader.type == "crypto" ? (
                                              <>
                                                <Spinner size="sm" />
                                              </>
                                            ) : (
                                              <>
                                                <img
                                                  className="rounded"
                                                  src={item.icon}
                                                  alt=""
                                                />
                                              </>
                                            )}
                                            {item.code}
                                            {/* <img className="infoIcon"
                                                            src="assets/img/header/exclamationYellow.png" alt=""
                                                            onClick={() => setShowUsdtDetailModal(true)} /> */}
                                          </div>
                                          <div className="text-end">
                                            {/* {userDetails.fiatStatus ? item.currency.symbol + ' ' + item.totalBalance : item.wallet?.totalBalance ? item.wallet?.totalBalance : '0'} */}
                                            {userDetails.fiatStatus
                                              ? userDetails?.currency?.symbol
                                              : ""}{" "}
                                            {item?.totalBalance
                                              ? convertToEightDigitsAfterDecimal(
                                                  item?.totalBalance
                                                )
                                              : "0.00"}
                                            {userDetails.fiatStatus ? (
                                              <span className="smallVal">
                                                {" "}
                                                {item.wallet?.totalBalance
                                                  ? convertToEightDigitsAfterDecimal(
                                                      item.wallet?.totalBalance
                                                    )
                                                  : "0.00"}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                            {/* <div className="usdtValue2"
                                                            onClick={() => setShowUsdtBonusModal(true)}
                                                        >
                                                            <img
                                                                src="assets/img/header/lockYellow.png" alt="" />
                                                            $ {userBalance?.lockedBonus || userBalance?.lockedBonus === 0 ? Number(userBalance?.lockedBonus).toFixed(2) : "0"}
                                                        </div> */}
                                          </div>
                                        </Dropdown.Item>
                                      ))}
                                      {/* {
                                                currencyExchange?.length ? 
                                                    currencyExchange?.map((item)=> (
                                                      <>
                                                        <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded" src={item?.icon} alt="" />
                                                                {item?.name}
                                                            </div>
                                                            <div>
                                                                {Number(item?.usdAmount)?.toFixed(2)}
                                                            </div>
                                                        </li>
                                                        <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded" src={item?.icon} alt="" />
                                                                {item?.name}
                                                            </div>
                                                            <div>
                                                                {Number(item?.usdAmount)?.toFixed(2)}
                                                            </div>
                                                        </li>
                                                        <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded" src={item?.icon} alt="" />
                                                                {item?.name}
                                                            </div>
                                                            <div>
                                                                {Number(item?.usdAmount)?.toFixed(2)}
                                                            </div>
                                                        </li>
                                                        <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center">
                                                                <img className="rounded" src={item?.icon} alt="" />
                                                                {item?.name}
                                                            </div>
                                                            <div>
                                                                {Number(item?.usdAmount)?.toFixed(2)}
                                                            </div>
                                                        </li>
                                                      </>  
                                                    ))
                                                : ''
                                            } */}
                                    </ul>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="innerScrollDiv d-flex justify-content-center align-items-center">
                              <div>
                                <img
                                  src={NoData}
                                  alt=""
                                  style={{
                                    width: "100px",
                                  }}
                                />
                                <div className="dropTitle mb-1 text-center mt-3">
                                  No Data Available
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div
              className="col-12 playwithdetail text-center  pt-3 px-3"
              style={{ fontSize: 14 }}
            >
              {appSideAppLabels.The_selected_currency}{" "}
              <span className="currency-text">
                {userDetails?.fiatStatus
                  ? `${userDetails?.currency?.code} Fiat`
                  : "USD"}
              </span>
              {appSideAppLabels.and_if_you}
            </div>
            {showError?.show ? (
              <>
                <h6 className="text-danger text-center mt-3 px-3">
                  {showError?.msg}
                </h6>
                <FiatErrorModal />
              </>
            ) : (
              <>
                <div className="row py-2 g-3 px-3 pb-3">
                  <div className="col-12 dialogTxt" style={{ fontSize: 16 }}>
                    {appSideAppLabels.Please_select_the_mode}
                  </div>
                  <div className="col-6">
                    <button
                      className="dialogBtn"
                      onClick={() => {
                        setModeType("real");
                        setDemo(false);
                        setShowOptionModal(false);
                      }}
                    >
                      {" "}
                      {appSideAppLabels.Play_with_real_money}
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      className="dialogBtn freePlay"
                      onClick={() => {
                        setModeType("free")
                        handlePlayDemo();
                      }}
                    >
                      {loading
                        ? "loading..."
                        : `${appSideAppLabels.Play_in_demo_mode}`}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModeOptionModal;
