import React from 'react'
import EstimatedPayoutModal from './components/EstimatedPayoutModal'
import StackingItems from './components/StackingItems'

const AboutDgwToken = () => {
  return (
    <>
          <main className="main stackingMain" id="main">
            <div className="mainContaint w-100
            ">
                <div className="homepage stackingPage">
                  <div className="container-fluid dgwtoken-section px-0">
                    <div className="container px-0">
                      <div className="row">
                        <div className="col-12 col-lg-8">
                          <div className="heading">
                            About 
                            <span> $DMBL Token</span>
                          </div>
                          <div className="sub-text py-2 py-md-4">
                          Stake $DMBL and receive daily multi-currency payouts in 5 popular tokens: USDT, BTC, ETH, BNB, and TRX, or in $DMBL.
                          </div>
                          <div className="cta-section my-4">
                            <div className="row align-items-center gy-3">
                              <div className="col-md-auto">
                                <div className="securityImg mx-auto mx-md-0">
                                <img src="./assets/img/stacking/CertikSecurity.png" alt="" className='h-100 w-100'/>
                                </div>
                              </div>
                              <div className="col-md">
                                <div className="security-txt text-center text-md-start">
                                $DMBL is certified by CertiK - the official confirmation of reliability and security.
                                </div>
                                <div className="audit-link pt-md-3 text-center text-md-end pt-4">
                                Check $DMBL audit
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="fire-section py-4">
                            <div className="row align-items-center">
                              <div className="col-auto">
                                <div className="fire-img">
                                  <img src="./assets/img/stacking/fire.png" alt="" className='h-100 w-100' />
                                </div>
                              </div>
                              <div className="col px-0">
                                <div className="fire-heading">
                                $DMBL Burning
                                </div>
                              </div>
                            </div>
                              <div className="row pt-4 align-items-center">
                                <div className="col-12 col-md-8">
                                  <div className="fire-txt">
                                  Damble team supports the healthy product economy and conducts a monthly $DMBL token burning. Planned burnings increase the value of $DMBL token, and keep the balance between holders and developers to support the token distribution model.
                                  </div>
                                  <div className="row pt-4">
                                <div className="fire-list">
                                  <ul>
                                    <li>
                                    $DMBL tokens that are owned by the team
                                    </li>
                                    <li>
                                    $DMBL tokens from lost bets
                                    </li>
                                  </ul>
                                </div>
                              </div>
                                </div>
                                <div className="col-12 col-md-4 d-flex justify-content-md-end justify-content-start">
                                  <div className="fire-Btn">
                                    <div className="row align-items-center">
                                      <div className="col-auto">
                                        <div className="btn-txt">
                                        Burn Contract
                                        </div>
                                      </div>
                                      {/* <div className="col">
                                        <div className="btn-svg">
                                          <img src="./assets/img/stacking/share.png" alt="" className='h-100 w-100' />
                                        </div>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </div>
                          <div className="col-12 col-lg-4 d-block d-md-none">
                          <div className="head position-sticky top-0">
                            <div className="heading">
                            $DMBL Key metrics
                            </div>
                            <div className="matrics-card mt-4">
                              <div className="matrics-table">
                                <table class="table table-dark">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div className="head">
                                        Price :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head d-flex gap-2">
                                          <span className='green-price'>$0.018567</span>
                                          <span className='yellow-text'> Buy/Sell</span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Ticker :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head">
                                        $DMBL
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Name :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head d-flex gap-2">
                                        $DMBL token
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Contract address :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head d-flex gap-1">
                                          <span className='yellow-text'>View Contract</span>
                                          <span className='contact-img'> <img src="./assets/img/stacking/contact.png" alt=""  className='h-100 w-100'/></span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Chain :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head">
                                          BNB Chain (BEP20)
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Total emission :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head">
                                          4 000 000 000 $DMBL
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Total circulation :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head">
                                          3 347 378 626 $DMBL
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Total staked :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head">
                                          3 311 485 312 $DMBL
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Total unstaked :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head">
                                          35 893 314 $DMBL
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Total emission :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head">
                                          4 000 000 000 $DMBL
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="burn-card mt-3 position-relative">
                              <div className="burn-table">
                              <table class="table table-dark">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Total burnt amount:
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head d-flex gap-2">
                                          1 652 621 374 
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          From total supply:
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head">
                                          33.05%
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="2">
                                      <div className="sub-head d-flex gap-1">
                                          <span className='yellow-text'>Burn contract</span>
                                          <span className='contact-img'> <img src="./assets/img/stacking/contact.png" alt=""  className='h-100 w-100'/></span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              {/* <div className="burn-img position-absolute">
                                <img src="./assets/img/stacking/blurCoin.png" alt="" className='h-100 w-100' />
                              </div>
                              <div className="burn-layer-img position-absolute">
                                <img src="./assets/img/stacking/burn-layer.svg" alt="" className='h-100 w-100' />
                              </div> */}
                            </div>
                          </div>
                        </div>
                          <div className="earn-section mt-md-3 position-relative">
                            <div className="row">
                              <div className='col-md-7'>
                                <div className='row'>
                                    <div className="col-12 ">
                                      <div className="heading">
                                      Earn up to 50% APY in $DMBL staking
                                      </div>
                                      <div className="sub-txt py-3">
                                      Stake $DMBL and receive daily multi-currency <br/> payouts or in $DMBL 
                                        <span className='learn-span ms-3'> Learn More  </span>
                                      </div>
                                    </div>
                                    {/* <div className="col-4 col-md-3 position-absolute top-2 end-0 z-n1">
                                      <div className="coins-img">
                                        <img src="./assets/img/stacking/earncoins.png" alt="" className='h-100 w-100' />
                                      </div>
                                    </div> */}
                                    <div className="col-12   d-md-flex d-none  ">
                                      <div className="stake-Btn">
                                        Stake $DMBL
                                      </div>
                                    </div>
                                </div>
                              </div>
                              <div className='col-md-5'>
                                <div className='row flex-column mt-5'>
                                <div className="col-md-7 col-10 mx-auto pb-3 right-line">
                                    <div className="head">
                                    Total paid
                                    </div>
                                    <div className="text">
                                    $..M
                                    </div>
                                  </div>
                                  <div className="col-md-7 col-10 mx-auto pt-3 ">
                                    <div className="head">
                                    Staking participants
                                    </div>
                                    <div className="text">
                                    ..K+
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 d-md-d-none  d-flex mt-3 text-center justify-content-center">
                                    <div className="stake-Btn">
                                      Stake $DMBL
                                    </div>
                              </div>

                            </div>
                          </div>
                          <div className="two-cards">
                            <div className="row align-items-stretch gy-3">
                            <div className="col-12 col-md-6 h-auto">
                                <div className="gaming-card d-flex d-md-block gap-3 gap-md-0 align-items-center">
                                <div className='row align-items-center w-100'>
                                  <div className='col'>
                                      <div className="">
                                      <div className="gaming-heading">
                                      Gaming & Betting
                                      </div>
                                      <div className="gaming-txt">
                                      Tokens are available for gaming & betting with min bet from $0.10
                                      </div>
                                      </div>
                                  </div>
                                  <div className='col-auto'>
                                      <div className="gaming-icon">
                                        <div className="hands-img">
                                          <img src="./assets/img/stacking/Union.png" alt="" className='h-100 w-100'/>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                                </div>
                              </div>
                              <div className="col-12 col-md-6 h-auto">
                                <div className="gaming-card d-flex d-md-block gap-3 gap-md-0 align-items-center">
                                <div className='row align-items-center w-100'>
                                  <div className='col'>
                                      <div className="">
                                      <div className="gaming-heading">
                                        Trading
                                      </div>
                                      <div className="gaming-txt">
                                      Buy & Sell $DMBL tokens on TOP exchanges
                                      </div>
                                      </div>
                                  </div>
                                  <div className='col-auto'>
                                      <div className="gaming-icon">
                                        <div className="hands-img">
                                          <img src="./assets/img/stacking/Union2.png" alt="" className='h-100 w-100'/>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="lineDivider mt-5 mb-4"></div>
                          <div className="fire-section py-3 get-dgn position-relative">
                            <div className="row align-items-center">
                              <div className="col">
                                <div className="fire-heading pb-2 text-md-start text-center">
                                How to get $DMBL?
                                </div>
                              </div>
                            </div>
                            <div className="row align-items-end">
                              <div className="col-12 col-md-8">
                                <div className="fire-txt py-2 py-md-0 text-md-start text-center">
                                Use the preferred crypto to buy $DMBL directly on Damble. Sell $DMBL tokens without having to switch to other platforms. 
                                </div>
                                <div className='text-md-start text-center'>
                                  <div className="fire-Btn mt-3 buy-sell-btn  ">
                                    <div className="row align-items-center">
                                      <div className="col-auto mx-auto">
                                        <div className="btn-txt">
                                          Buy-Sell $DMBL 
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="col-3 position-absolute top-0 end-0">
                                <div className="three-coin-img">
                                  <img src="./assets/img/stacking/three-coin.png" alt="" className='h-100 w-100' />
                                </div>
                              </div> */}
                            </div>
                          </div>
                          <div className="lineDivider mt-3 mb-4"></div>
                          <div className="trade-section py-4">
                            <div className="trade-heading text-md-start text-center">
                              Trade DGW on TOP exchanges
                            </div>
                            <div className="trade-btn text-md-start text-center">
                              <div className="trade-1 d-flex align-items-center justify-content-md-start justify-content-center mx-auto">
                                <img src="./assets/img/stacking/CoinWiMG.png" alt="" className='h-100 w-100' />
                              </div>
                              <div className="trade-1 d-md-block d-none ">
                              <img src="./assets/img/stacking/panScake.png" alt="" className='h-100 w-100' />
                              </div>
                            </div>
                          </div>
                          <div className="lineDivider mt-3 mb-4"></div>
                          <div className="mining-section py-4 d-sm-block d-none">
                            <div className="mine-heading">
                              DGW Mining ended on June 13
                            </div>
                            <div className="sub-para mt-4">
                              4 000 000 000 tokens have been mined
                            </div>
                            <div className="sub-para mt-3">
                            You could mine DGW tokens by playing games before, but mining has been completed. No new coins are entering the market, which makes DGW more valuable now.
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg-4 d-none d-md-block">
                          <div className="head position-sticky top-0">
                            <div className="heading">
                              DGW Key metrics
                            </div>
                            <div className="matrics-card mt-4">
                              <div className="matrics-table">
                                <table class="table table-dark">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Price :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head d-flex gap-2">
                                          <span className='green-price'>$0.018567 </span>
                                          <span className='yellow-text'> Buy/Sell</span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Ticker :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head">
                                          DGW
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Name :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head d-flex gap-2">
                                          DGW token
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Contract address :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head d-flex gap-1">
                                          <span className='yellow-text'>View Contract</span>
                                          <span className='contact-img'> <img src="./assets/img/stacking/contact.png" alt=""  className='h-100 w-100'/></span>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Chain :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head">
                                          BNB Chain (BEP20)
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Total emission :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head">
                                          4 000 000 000 $DMBL
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Total circulation :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head">
                                          3 347 378 626 $DMBL
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Total staked :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head">
                                          3 311 485 312 $DMBL
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Total unstaked :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head">
                                          35 893 314 $DMBL
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Total emission :
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head">
                                          4 000 000 000 $DMBL
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="burn-card mt-3 position-relative">
                              <div className="burn-table">
                              <table class="table table-dark">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          Total burnt amount:
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head d-flex gap-2">
                                          1 652 621 374 $DMBL
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <div className="head">
                                          From total supply:
                                        </div>
                                      </td>
                                      <td>
                                        <div className="sub-head">
                                          33.05%
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan="2">
                                      <div className="sub-head d-flex gap-1">
                                          <span className='yellow-text'>Burn contract</span>
                                          <span className='contact-img'> <img src="./assets/img/stacking/contact.png" alt=""  className='h-100 w-100'/></span>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              {/* <div className="burn-img position-absolute">
                                <img src="./assets/img/stacking/blurCoin.png" alt="" className='h-100 w-100' />
                              </div> */}
                              {/* <div className="burn-layer-img position-absolute">
                                <img src="./assets/img/stacking/burn-layer.svg" alt="" className='h-100 w-100' />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                {/* After log in it show like this start*/}

                  {/* <div className="container-fluid deposit-section">
                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5">
                          <div className="deposit-card">
                            <div className="deposit-header">
                              <div className="row align-items-center gy-3">
                                <div className="col-auto">
                                  <div className="coin-img">
                                    <img src="./assets/img/stacking/dAppCircle.png" alt="" className='h-100 w-100'/>
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <div className="d-flex flex-column gap-1">
                                    <div className="upper-txt d-flex align-items-center gap-1">
                                        My DGW <span className='upper-span d-flex'>
                                        <img src="./assets/img/stacking/questionS.png" alt="" className='h-100 w-100' />
                                      </span>
                                    </div>
                                    <div className="middle-txt">
                                      231.91242141 <span>DGW</span>
                                    </div>
                                    <div className="lower-txt">
                                      $4.88
                                    </div>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="buy-btn mx-auto">
                                    Buy DGW
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="lineDivider my-4"></div>
                            <div className="deposit-body mt-5">
                              <div className="row gy-4">
                                <div className="col-6">
                                  <div className="row">
                                    <div className="col-auto">
                                      <div className="coin-img">
                                        <img src="./assets/img/stacking/table02.png" alt="" className='h-100 w-100'/>
                                      </div>
                                    </div>
                                    <div className="col px-0">
                                      <div className="upper-txt">
                                        5.39013057 <span>
                                            BTC
                                        </span>
                                      </div>
                                      <div className="lower-txt">
                                        $380,050
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="row">
                                    <div className="col-auto">
                                      <div className="coin-img">
                                        <img src="./assets/img/stacking/table03.png" alt="" className='h-100 w-100'/>
                                      </div>
                                    </div>
                                    <div className="col px-0">
                                      <div className="upper-txt">
                                        5.39013057 <span>
                                            ETH
                                        </span>
                                      </div>
                                      <div className="lower-txt">
                                        $380,050
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="row">
                                    <div className="col-auto">
                                      <div className="coin-img">
                                        <img src="./assets/img/stacking/table01.png" alt="" className='h-100 w-100'/>
                                      </div>
                                    </div>
                                    <div className="col px-0">
                                      <div className="upper-txt">
                                        5.39013057 <span>
                                            USDT
                                        </span>
                                      </div>
                                      <div className="lower-txt">
                                        $380,050
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="row">
                                    <div className="col-auto">
                                      <div className="coin-img">
                                        <img src="./assets/img/stacking/table05.png" alt="" className='h-100 w-100'/>
                                      </div>
                                    </div>
                                    <div className="col px-0">
                                      <div className="upper-txt">
                                        5.39013057 <span>
                                            TRX
                                        </span>
                                      </div>
                                      <div className="lower-txt">
                                        $380,050
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="row">
                                    <div className="col-auto">
                                      <div className="coin-img">
                                        <img src="./assets/img/stacking/table04.png" alt="" className='h-100 w-100'/>
                                      </div>
                                    </div>
                                    <div className="col px-0">
                                      <div className="upper-txt">
                                        5.39013057 <span>
                                            BNB
                                        </span>
                                      </div>
                                      <div className="lower-txt">
                                        $380,050
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="dollar-Btn">
                                    <div className="row">
                                      <div className="col-auto">
                                        <div className="coin-img">
                                          <img src="./assets/img/stacking/table06.png" alt="" className='h-100 w-100'/>
                                        </div>
                                      </div>
                                      <div className="col px-0 px-sm-2">
                                        <div className="dollartxt">
                                          Total: <span>$0.00</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="deposit-footer">
                              <div className="deposit-btn">
                                deposit and stake
                              </div>
                              <div className="row justify-content-between mt-2">
                                <div className="col-auto">
                                  <div className="earned-txt">
                                    Earned for all time:
                                  </div>
                                </div>
                                <div className="col-auto">
                                  <div className="amount-txt">
                                    $0.00
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* After log in it show like this end  */}
                  
                </div>
            </div>
            {/* <EstimatedPayoutModal/> */}
            {/* <StackingItems/> */}
        </main>
    </>
  )
}

export default AboutDgwToken