import React from "react";

const SponsorshipIcon = () => {
  return (
    <svg
      width="17"
      height="11"
      viewBox="0 0 17 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.10832 1.32083C3.54164 0.754156 1.13332 0.612512 0.354145 0.541656C0.283322 0.541656 0.212467 0.541657 0.141645 0.612479C0.0708223 0.683334 0 0.825012 0 0.895834V7.27083C0 7.48333 0.141678 7.62501 0.354178 7.62501H2.47918C2.62086 7.62501 2.7625 7.55419 2.83336 7.41251C2.83336 7.20001 4.10836 3.44583 4.25003 1.60419C4.25 1.53333 4.25 1.39169 4.10832 1.32083Z"
        fill="#2F93DD"
      />
      <path
        d="M11.6875 3.02083C10.9792 2.80833 10.3417 2.52501 9.775 2.31251C8.5 1.74583 7.93332 2.02919 6.8 3.16251C6.30418 3.65833 5.95 4.36669 6.02082 4.65001C6.02082 4.72083 6.02082 4.72083 6.1625 4.79169C6.51668 4.93337 7.0125 5.00419 7.50832 4.22501C7.57915 4.15419 7.65 4.08333 7.79165 4.08333C8.00414 4.08333 8.07497 4.01251 8.28747 3.94166C8.42915 3.87083 8.57079 3.79998 8.78329 3.72916C8.85411 3.72916 8.85411 3.72916 8.92497 3.72916C8.99579 3.72916 9.13747 3.79998 9.20829 3.79998C9.5625 4.15419 10.2 4.65001 10.8375 5.21669C11.8292 5.99587 12.8208 6.84587 13.3167 7.48337H13.3875C12.8917 6.06669 12.0417 3.65833 11.6875 3.02083Z"
        fill="#2F93DD"
      />
      <path
        d="M16.6458 1.25001C13.8833 1.25001 12.325 1.95833 12.2541 1.95833C12.1833 2.02916 12.1125 2.10001 12.0416 2.17083C12.0416 2.24166 12.0416 2.38333 12.1125 2.45416C12.5375 3.16248 13.9541 7.05833 14.1666 8.04998C14.2375 8.19166 14.3791 8.3333 14.5208 8.3333H16.6458C16.8583 8.3333 17 8.19162 17 7.97912V1.60412C17 1.39169 16.8583 1.25001 16.6458 1.25001Z"
        fill="#2F93DD"
      />
      <path
        d="M12.8208 8.19169C12.5375 7.55419 11.3333 6.63337 10.2708 5.78337C9.70418 5.28751 9.1375 4.86251 8.7125 4.50833C8.64168 4.57916 8.5 4.57916 8.5 4.65001C8.2875 4.72083 8.21668 4.79169 7.93332 4.79169C7.36668 5.50001 6.65832 5.78333 5.87918 5.50001C5.525 5.42919 5.3125 5.14583 5.24168 4.86251C5.1 4.15419 5.7375 3.16251 6.23336 2.66669H4.81668C4.53336 4.08337 4.0375 5.92501 3.68336 6.91669C3.96668 7.20001 4.25003 7.55419 4.46253 7.69587C5.80836 8.82919 7.36671 9.96254 7.65003 10.175C7.86253 10.3167 8.28753 10.4584 8.50003 10.4584C8.57086 10.4584 8.64171 10.4584 8.71253 10.4584L7.57918 9.32501C7.4375 9.18333 7.4375 8.97083 7.57918 8.82919C7.72086 8.68754 7.93336 8.68751 8.075 8.82919L9.49168 10.2459C9.63336 10.3875 9.775 10.3167 9.91668 10.3167C10.1292 10.2459 10.2 10.1042 10.2709 9.89169L8.64168 8.26251C8.5 8.12083 8.5 7.90833 8.64168 7.76669C8.78336 7.62504 8.99586 7.62501 9.1375 7.76669L10.9083 9.53751C10.9791 9.60833 11.2625 9.60833 11.475 9.53751C11.5458 9.46669 11.6875 9.39583 11.7583 9.25419L9.775 7.27083C9.63332 7.12916 9.63332 6.91666 9.775 6.77501C9.91668 6.63337 10.1292 6.63333 10.2708 6.77501L12.325 8.82919C12.4667 8.90001 12.6083 8.82919 12.75 8.75837C12.8208 8.68751 12.9625 8.47501 12.8208 8.19169Z"
        fill="#2F93DD"
      />
    </svg>
  );
};

export default SponsorshipIcon;
