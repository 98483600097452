import React, { useState, useEffect } from "react";
import SettingDesktop from "./SettingDesktop";
import SettingMobile from "./SettingMobile";

const Profile2 = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {isMobile ? <SettingMobile /> : <SettingDesktop />}
    </>
  );
};

export default Profile2;
