import { useRef, useState } from "react";
import avatar from "../../../assets/images/profile/avatar.svg";
import { postAPIAuthFormData } from "../../../services/apiInstance";
import { useAuth } from "../../../hooks/useAuth";
import { succesToaster } from "../../../utils/toaster";

const ProfilePictureModal = ({
  handleOutsideClick,
  toggleModal,
  profilePic,
  setProfilePic,
}) => {
  const fileInputRef = useRef(null);
  const { token } = useAuth();
  const updateProfilePicture = async (file) => {
    try {
      const formData = new FormData();
      formData.append("typename", "profile");
      formData.append("image", file);
      const res = await postAPIAuthFormData(
        "user/update-profile-pic",
        formData,
        token
      );
      console.log(res, "ddduduommimnjkmn");
      if (res.data.success) {
        setProfilePic(res.data.data.image);
        succesToaster("Profile Pic Updated");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      updateProfilePicture(file);
    }
  };
  return (
    <div className=" modal-overlay" onClick={handleOutsideClick}>
      <div className="profilemodal-content">
        <button className="close-btn" onClick={toggleModal}>
          <span>
            <svg
              stroke="currentColor"
              fill="none"
              stroke-width="0"
              viewBox="0 0 15 15"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
        </button>
        <div className="circle">
          <img
            src={profilePic ? profilePic : avatar}
            alt="Avatar"
            className="profile-img"
          />
        </div>

        <input
          type="file"
          ref={fileInputRef}
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />

        <div
          onClick={() => fileInputRef.current.click()}
          className="camra-icon"
        >
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 512 512"
            height="20px"
            width="20px"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="32"
              d="m350.54 148.68-26.62-42.06C318.31 100.08 310.62 96 302 96h-92c-8.62 0-16.31 4.08-21.92 10.62l-26.62 42.06C155.85 155.23 148.62 160 140 160H80a32 32 0 0 0-32 32v192a32 32 0 0 0 32 32h352a32 32 0 0 0 32-32V192a32 32 0 0 0-32-32h-59c-8.65 0-16.85-4.77-22.46-11.32z"
            ></path>
            <path
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="32"
              d="M124 158v-22h-24v22m235.76 127.22v-13.31a80 80 0 0 0-131-61.6M176 258.78v13.31a80 80 0 0 0 130.73 61.8"
            ></path>
            <path
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="32"
              d="m196 272-20-20-20 20m200 0-20 20-20-20"
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ProfilePictureModal;
