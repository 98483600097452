import React from 'react';
import TelegramLogin from 'react-telegram-login';
import { postAPI } from './../../../../services/apiInstance';
import { useSelector } from 'react-redux';
import { USER_LANG } from '../../../../constants';
import { errorToaster, succesToaster } from '../../../../utils/toaster';
import { useAuth } from '../../../../hooks/useAuth';
import { useAuthModal } from '../../../../hooks/useAuthModal';
import { useBtAuth } from '../../../../hooks/useBtAuth';
import { useSpinModal } from '../../../../hooks/useSpinModal';
import telegram from './../../../../assets/images/telegrammodal.png'
import { useReferralModal } from '../../../../hooks/useReferralModal';

const TelegramLoginComp = ({
  userObj,
  setUserObj,
  setIsOpen,
  isOpen,
  // setIsReferral,
  authModalObject,
  heroBanner,
  checks,
  parent,
  click_id,
  new_click_id,
  bonus,
  isFromCrypto
}) => {
  const ipAddress = useSelector(state => state.ipAddress)
  const country = useSelector(state => state.country)
  const { setToken } = useAuth()
  const { setAuthModalObject } = useAuthModal()
  const { setBtToken } = useBtAuth()
  const { setSpinModalObject } = useSpinModal()
  const { isReferral, setIsReferral, isReferralInput, setIsReferralInput } = useReferralModal()


  const handleTelegramResponse = (response) => {
    // telegram will only work when the bot domain is set correctly 
    // to set use bot father bot of telegram 
    // if want to use telegram auth on localhost or on live use the localhost or domain respectively
    // setIsOpen(prev => !prev)
    // setUserObj(response)
    const userObj = response
    // if (parent !== "signIn") {
    if (!checks.terms) {
      // errorToaster()
      errorToaster("Please read and tick the bottom agreement");
      return
    }
    // }
    const telegramHandler = async (values) => {
      try {
        // const lang = JSON.parse(localStorage.getItem(USER_LANG))
        const lang = localStorage.getItem(USER_LANG)
        const body = {
          fullname: `${userObj?.first_name} ${userObj?.last_name}`,
          image: userObj?.photo_url,
          username: userObj?.username,
          userid: userObj?.id,
          // email: values?.email,
          loginMethod: "telegram",
          ipAddress: ipAddress ? ipAddress : "",
          country: country ? country : "",
          // language: lang?.symbol
          language: lang,
          trackingToken: click_id ? click_id : null,
          clickid : !!new_click_id ? new_click_id : null,
          bonus: !!bonus ? bonus.bonus : false

        }

        const res = await postAPI("user/social-authentication", body)
        if (res.data.success) {
          succesToaster(res.data.message);
          setToken(res?.data.data?.token);
          setBtToken(res?.data?.data?.betByToken)
          // setIsOpen(false)
          setAuthModalObject(pre => ({ ...pre, isAuthOpen: false }))
          if (parent === 'signup') {

            // setSpinModalObject(prev => ({
            //   ...prev,
            //   isModalOpen: true
            // }))
            // setIsReferral(true)
            // setIsReferralInput(true)
            window?.dataLayer?.push({
              'event': 'GA4_event',
              'event_name': 'game_sign_up'
            });
          }

        } else {
          errorToaster("Something went wrong")
        }
      } catch (error) {

      } finally {
        // setIsLoading(false)
      }
    }
    telegramHandler()
  };

  return (
    <div
      className="col-auto px-0"
    >
      <div   className="position-relative overflow-hidden">
        <div  className="telegramBtnParent d-flex justify-content-center position-absolute  h-100 w-100 top-0 bottom-0 start-0 end-0">
          <TelegramLogin
            dataOnauth={handleTelegramResponse}
            botName="dambleUserNew_bot"
            buttonSize="small"
          />
        </div>
          <div   className='  socialLinks'><img src={telegram} alt="" /></div>
        {/* <div className="linkImg">
          <img src={isFromCrypto ? './assets/img/stacking/telegramWhite.png' : 'assets/img/auth/telegram.png'} alt='' />
        </div> */}
      </div>
    </div>
  )
};

export default TelegramLoginComp;
