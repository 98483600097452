import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import userWallet from "./../../assets/images/wallet.svg";
import userWithdraw from "./../../assets/images/withdraw.svg";
import userTransactions from "./../../assets/images/transactions.svg";
import buy from "./../../assets/images/buy.svg";
import swap from "./../../assets/images/swap.svg";
import rollover from "./../../assets/images/rollover.svg";
import bitHistory from "./../../assets/images/bit-history.svg";
import deposit from "./../../assets/images/deposit.svg";
import totalBalance from "./../../assets/images/total-balance.svg";
import rupeeIcon from "./../../assets/images/rupee_icon.svg";
import USDT from "./../../assets/images/usdt.svg";
import BTC from "./../../assets/images/btc.svg";
import BNB from "./../../assets/images/bnb.svg";
import ETH from "./../../assets/images/eth.svg";
import TURBO from "./../../assets/images/turbo.svg";
import USDC from "./../../assets/images/usdc.svg";
import AVAX from "./../../assets/images/avax.svg";
import CBANK from "./../../assets/images/cbank.svg";
import CAF from "./../../assets/images/caf.svg";
import cairo from "./../../assets/images/cairo.svg";
import thunderIcon from "./../../assets/images/thunder-icon.svg";
import giftBox from "./../../assets/images/gift.svg";
import barImage from "./../../assets/images/barCode.svg";
import EUR from "./../../assets/images/eur.svg";
import sepaInstant from "./../../assets/images/sepa-instant.svg";
import Euro from "./../../assets/images/euro.svg";
import swapIcon from "./../../assets/images/swap-icon.svg";
import fileEmpty from "../../assets/images/fileEmpty.svg";
import { useDispatch, useSelector } from "react-redux";
import { getAPIAuth } from "../../services/apiInstance";
import {
  getFavCryptoAction,
  getFavFiatAction,
  selectCrypto,
  selectFiat,
} from "../../store/action";
import { convertToEightDigitsAfterDecimal } from "../../utils/convertToEightDigitsAfterDecimal";
import NoData from "../../components/noData/NoData";
import { Spinner } from "react-bootstrap";

const BalanceTab = ({ setSelectedTab }) => {
  const userDetails = useSelector((state) => state.userDetails);
  const favCryptoDetails = useSelector((state) => state.favCrypto);
  const favFiatDetails = useSelector((state) => state.favFiat);
  const userBalance = useSelector((state) => state.userBalance);
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [search, setSearch] = useState("");
  const [favCrypto, setFavCrypto] = useState([]);
  const [favFiat, setFavFiat] = useState([]);
  const [loading, setLoading] = useState(true);
  const getFav = async () => {
    try {
      setLoading(true);
      const res = await getAPIAuth(
        `crypto/get-fav-currency-crypto?userId=${userDetails?.id}&favcryptocurrency=${search}`
      );
      if (res?.data?.success) {
        const crypto = res?.data?.crypto;
        const fiat = res?.data?.fiat;
        const newArray = [...crypto, ...fiat];
        setFavCrypto(res?.data?.crypto ? res.data.crypto : []);
        setFavFiat(res?.data?.fiat ? res.data.fiat : []);
        dispatch(getFavFiatAction(res?.data?.fiat ? res.data.fiat : []));
        dispatch(getFavCryptoAction(res?.data?.crypto ? res.data.crypto : []));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (search) {
      const filterFiat = favFiatDetails.filter(
        (item) =>
          item.code.toLowerCase().includes(search.toLowerCase()) ||
          item.name.toLowerCase().includes(search.toLowerCase())
      );
      setFavFiat(filterFiat);
      const filterCrypto = favCryptoDetails.filter(
        (item) =>
          item.code.toLowerCase().includes(search.toLowerCase()) ||
          item.name.toLowerCase().includes(search.toLowerCase())
      );
      setFavCrypto(filterCrypto);
    } else {
      setFavFiat(favFiatDetails);
      setFavCrypto(favCryptoDetails);
    }
  }, [search]);

  useEffect(() => {
    if (userDetails.id) {
      getFav();
    }
  }, [userDetails.id, showWithdraw, userDetails]);

  return (
    <>
      <div className="wallet_balance_sec p-3 bg_main_color d-flex flex-column gap-3">
        <div className="row align-items-center gy-2">
          <div className="col-lg-8 col-md-8 col-12 order-md-1 order-2">
            <div className="wallet-search position-relative">
              <input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                type="text"
                placeholder="Search"
                className="ps-5 py-2 rounded_bg text-white rounded-pill w-100 border-0 shadow-none"
              />
              <button
                type="button"
                className="search_btn p-0 m-0 text-white border-0 shadow-none bg-transparent position-absolute"
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="20px"
                  width="20px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M443.5 420.2L336.7 312.4c20.9-26.2 33.5-59.4 33.5-95.5 0-84.5-68.5-153-153.1-153S64 132.5 64 217s68.5 153 153.1 153c36.6 0 70.1-12.8 96.5-34.2l106.1 107.1c3.2 3.4 7.6 5.1 11.9 5.1 4.1 0 8.2-1.5 11.3-4.5 6.6-6.3 6.8-16.7.6-23.3zm-226.4-83.1c-32.1 0-62.3-12.5-85-35.2-22.7-22.7-35.2-52.9-35.2-84.9 0-32.1 12.5-62.3 35.2-84.9 22.7-22.7 52.9-35.2 85-35.2s62.3 12.5 85 35.2c22.7 22.7 35.2 52.9 35.2 84.9 0 32.1-12.5 62.3-35.2 84.9-22.7 22.7-52.9 35.2-85 35.2z"></path>
                </svg>
              </button>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-12 order-md-2 order-1">
            <div className="hide_balance d-flex align-items-center gap-3 justify-content-end">
              <span className="hide_txt">Hide 0 balance</span>
              <div
                className="form-check form-switch "
                style={{ minHeight: "auto" }}
              >
                <input
                  onChange={(e) => setIsChecked(e.target.checked)}
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckChecked"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="total_balance_bx rounded_bg rounded-3">
          <div className="row px-md-3 px-0 py-md-3 py-2">
            <div className="col-lg-4 col-md-4 col-4 border_right">
              <div className="balance_box d-flex align-items-center p-md-3 p-2 gap-md-3 gap-1">
                <span className="flex-shrink-0 balance_icon">
                  <img src={totalBalance} alt="" className="img-fluid" />
                </span>
                <div className="balance_content">
                  <div className="balance_head text-white">Total Balance</div>
                  <div className="balance_txt">
                    {userDetails.fiatStatus
                      ? userDetails?.currency?.symbol
                      : ""}
                    {userBalance?.balance || userBalance?.balance === 0
                      ? Number(userBalance?.balance)?.toFixed(2)
                      : userDetails?.totalbalance
                      ? Number(userDetails?.totalbalance).toFixed(2)
                      : "0.00"}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-4 border_right">
              <div className="balance_box d-flex align-items-center p-md-3 p-2 gap-md-3 gap-1">
                <div className="balance_content">
                  <div className="balance_head text-white">Real Money</div>
                  <div className="balance_txt">$0.01</div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-4 border_right">
              <div className="balance_box d-flex align-items-center p-md-3 p-2 gap-md-3 gap-1">
                <div className="balance_content">
                  <div className="balance_head text-white">Bonus Money</div>
                  <div className="balance_txt">
                    $
                    {userBalance?.lockedBonus
                      ? userBalance?.lockedBonus?.toFixed(2)
                      : "0.00"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flat_sec">
          <div className="heading">
            <h6 className="text-white">Fiat</h6>
          </div>
          <div className="flat_row ">
            <div className="table-responsive w-100 table_sec">
              <table className="table align-middle w-100">
                <tbody className="align-middle">
                  {loading ? (
                    <div className="d-flex justify-content-center align-item-center my-5">
                      <Spinner />
                    </div>
                  ) : !favFiat.length ? (
                    <NoData />
                  ) : (
                    favFiat.map((item) => {
                      return (
                        <tr
                          className={`${
                            isChecked &&
                            parseFloat(item?.totalBalance) === 0 &&
                            parseFloat(item.totalBonus) === 0
                              ? "d-none"
                              : ""
                          }`}
                          key={item._id}
                        >
                          <td className="col-md-7 col m-0">
                            <div
                              className="d-flex text-white gap-1 align-items-center fw-bold"
                              style={{ fontSize: "14px" }}
                            >
                              <span className="rupee_icon flex-shrink-0">
                                <img
                                  src={item.icon}
                                  alt=""
                                  className="img-fluid"
                                />
                              </span>
                              {item.code?.toUpperCase()}
                            </div>
                          </td>
                          <td className=" justify-content-end align-middle ">
                            <span className="d-flex flex-column align-items-end justify-content-center">
                              <h6
                                className="d-flex align-items-center gap-1 my-0"
                                style={{ fontSize: "14px" }}
                              >
                                {userDetails.fiatStatus
                                  ? userDetails?.currency?.symbol
                                  : ""}
                                {item?.totalBalance
                                  ? convertToEightDigitsAfterDecimal(
                                      item?.totalBalance
                                    )
                                  : "0.00"}
                              </h6>

                              {userDetails.fiatStatus ? (
                                <span
                                  className="text-white text-end"
                                  style={{ opacity: "0.5", fontSize: "12px" }}
                                >
                                  {item.wallet?.totalBalance
                                    ? convertToEightDigitsAfterDecimal(
                                        item?.wallet?.totalBalance
                                      )
                                    : "0.00"}
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                          </td>

                          <td className="text-center">
                            <span
                              onClick={() => {
                                dispatch(selectFiat(item));
                              }}
                              style={{ fontSize: "14px" }}
                              className="  cursor-pointer deposit_txt"
                            >
                              Deposit
                            </span>
                          </td>
                          <td
                            className="text-center"
                            style={{ fontSize: "14px" }}
                          >
                            <span
                              onClick={() => {
                                setSelectedTab("Withdraw");
                                dispatch(selectFiat(item));
                              }}
                              className="cursor-pointer"
                            >
                              Withdraw
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="crypto_sec">
          <div className="heading">
            <h6 className="text-white">Crypto Currency</h6>
          </div>
          <div className="crypto_row">
            <div className="table-responsive w-100">
              <table className="table align-middle w-100">
                <tbody className="align-middle">
                  {loading ? (
                    <div className="d-flex justify-content-center align-item-center my-5">
                      <Spinner />
                    </div>
                  ) : !favCrypto.length ? (
                    <NoData />
                  ) : (
                    favCrypto.map((item) => {
                      return (
                        <tr
                          className={`${
                            isChecked &&
                            parseFloat(item?.totalBalance) === 0 &&
                            parseFloat(item?.totalBonus) === 0
                              ? "d-none"
                              : ""
                          }`}
                          key={item._id}
                        >
                          <td className="col-md-7 col m-0">
                            <div
                              className="d-flex text-white gap-1 align-items-center fw-bold"
                              style={{ fontSize: "14px" }}
                            >
                              <span className="rupee_icon flex-shrink-0">
                                <img
                                  src={item.icon}
                                  alt=""
                                  className="img-fluid"
                                />
                              </span>
                              {item.code?.toUpperCase()}
                            </div>
                          </td>
                          <td className="align-middle ">
                            <div className="d-flex justify-content-end ">
                              <span className="d-flex flex-column align-items-end justify-content-center">
                                <h6
                                  className="d-flex align-items-center gap-1 my-0"
                                  style={{ fontSize: "14px" }}
                                >
                                  {userDetails.fiatStatus
                                    ? userDetails?.currency?.symbol
                                    : ""}
                                  {item?.totalBalance
                                    ? convertToEightDigitsAfterDecimal(
                                        item?.totalBalance
                                      )
                                    : "0.00"}
                                </h6>

                                {userDetails.fiatStatus ? (
                                  <span
                                    className="text-white text-end"
                                    style={{
                                      opacity: "0.5",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {item.wallet?.totalBalance
                                      ? convertToEightDigitsAfterDecimal(
                                          item?.wallet?.totalBalance
                                        )
                                      : "0.00"}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </div>
                          </td>

                          <td>
                            <span
                              onClick={() => {
                                dispatch(selectCrypto(item));
                                setSelectedTab("Deposit");
                              }}
                              className=" cursor-pointer deposit_txt"
                              style={{ fontSize: "14px" }}
                            >
                              Deposit
                            </span>
                          </td>
                          <td style={{ fontSize: "14px" }}>
                            <span
                              onClick={() => {
                                setSelectedTab("Withdraw");
                                dispatch(selectCrypto(item));
                              }}
                              className="cursor-pointer"
                            >
                              Withdraw
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BalanceTab;
