import React ,{ useState, createContext  } from 'react'

export const ResultModalContext = createContext()

const ResultModalContextProvider = ({children}) => {
  const [resultObject, setResultObject] = useState({
    isResultOpen : false
  })
  return (
      <ResultModalContext.Provider value={{resultObject, setResultObject}}>{children}</ResultModalContext.Provider>
  )
}

export default ResultModalContextProvider