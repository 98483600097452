import React, { useState } from "react";
import { SPIN_MODAL_OPTIONS } from "../constants";

export const SpinContext = React.createContext(null)

const SpinContextProvider = ({ children }) => {
    const [spinObject, setSpinObject] = useState({
        isSpinOpen: false,
        selectedModal : SPIN_MODAL_OPTIONS.SPIN_MODAL
    })
    return <SpinContext.Provider value={{ spinObject, setSpinObject }}>{children}</SpinContext.Provider>;
};

export default SpinContextProvider;
