import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getAPIAuth } from '../../../../services/apiInstance';
import {useAuth} from './../../../../hooks/useAuth'
import { useAccount } from '../../../../hooks/useAccount';
import { ACCOUNT_TABS } from '../../../../constants';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

const CasinoTransaction = () => {
  const { accountTab} = useAccount()
  const [transactionData, setTransactionData] = useState()
  const userDetails = useSelector(state => state?.userDetails)
  const { t } = useTranslation()
  const accountPageLabels = t("accountPageLabels", { returnObjects: true })
  const { token } = useAuth()
  const [pageNo, setPageNo] = useState(1)
  const [totalPages, setTotalPage] = useState()
  const [pagination, setPagination] = useState({
      btnOne: 1,
      btnTwo: 2,
      btnThree: 3,
      btnFour: "...",
      btnFive: 0
  })
  const [isLoadMore, setIsLoadMore] = useState(false)
  const paginationHandler = (count) => {
      if (pagination.btnOne != pageNo) {
          if (pageNo - 1 == pagination.btnThree) {
              setPagination(
                  prev => ({
                      ...prev,
                      btnOne: pagination.btnOne + 1,
                      btnTwo: pagination.btnTwo + 1,
                      btnThree: pagination.btnThree + 1
                  })
              )
          } else if (pageNo + 1 == pagination.btnOne) {
              setPagination(
                  prev => ({ ...prev, btnOne: pagination.btnOne - 1, btnTwo: pagination.btnTwo - 1, btnThree: pagination.btnThree - 1 })
              )
          } else if (pageNo == totalPages) {
              setPagination(
                  prev => ({ ...prev, btnOne: totalPages - 2, btnTwo: totalPages - 1, btnThree: totalPages })
              )
          }
      }
  }
  const getData = async () => {
      try {
          const res = await getAPIAuth(`user/userTransactions/${userDetails?.id}?page=${pageNo}&gameType=casino`)
          // const res = await getAPIAuth(`user/userTransactions/${"64be2d4fcb1b31b159cc6378"}`)
          if (res.data.success) {
              setTransactionData(res.data.data)
              setTotalPage(res.data.totalPages)
              setPagination({
                  btnOne: 1,
                  btnTwo: 2,
                  btnThree: 3,
                  btnFour: "...",
                  btnFive: res.data.totalPages
              })
          }
      } catch (error) {
          //error handling
      }

  }

  const loadMore = async () => {
      try {
          const res = await getAPIAuth(`user/userTransactions/${userDetails?.id}?page=${pageNo}&gameType=casino`)
          // const res = await getAPIAuth(`user/userTransactions/${"64be2d4fcb1b31b159cc6378"}`)
          if (res.data.success) {
              setTransactionData(res.data.data)
              setTotalPage(res.data.totalPages)
          }
      } catch (error) {
          //error handling
      }

  }


  useEffect(() => {
      if (userDetails?.id && (accountTab?.selectedTab === ACCOUNT_TABS.TRANSACTIONS)) {
          getData()
      }
  }, [userDetails?.id, accountTab?.selectedTab, token])

  useEffect(() => {
      if (userDetails?.id && (accountTab?.selectedTab === ACCOUNT_TABS.TRANSACTIONS)) {
          if (pageNo >= 1 && isLoadMore) {
              paginationHandler()
              loadMore()
              setIsLoadMore(false)
          } else if (pageNo >= 1) {
              paginationHandler()
              loadMore()
          }
      }
  }, [pageNo, userDetails?.id, accountTab?.selectedTab])

  return (
    <>
      <div className="col-12 betTableNew mt-md-4 mt-3">
        <div className="table-responsive">
            <table className='table mb-0'>
                <thead className='bg-sucess'>
                    <tr>
                        <th>{accountPageLabels.AMOUNT}</th>
                        <th>{accountPageLabels.TIME}</th>
                        <th className='text-center'>{accountPageLabels.STATUS}</th>
                        <th className='text-center'>{accountPageLabels.TYPE}</th>
                        <th className='text-center'>{accountPageLabels.CREDIT_WITHDRAW}</th>
                    </tr>
                </thead>
                <tbody>
                  {transactionData?.length > 0 ?
                    transactionData?.map((item) => (
                      <tr key={item?.NAME}>
                        <td>
                          {item?.amount ? Number(item?.amount).toFixed(2) : '0.00'} <span className="text-uppercase">{item?.coin}</span>
                        </td>
                        <td>
                          { moment(item.createdAt).format("DD/MM/YY , HH:mm")}
                        </td>
                        <td className="text-capitalize">{item?.paymentstatus?.toLowerCase()}</td>
                        <td className="text-capitalize">{item?.type?.toLowerCase()}</td>
                        <td className="text-capitalize">{item?.transactionType?item?.transactionType?.toLowerCase():"---"}</td>
                      </tr>
                    ))
                    : (
                        <tr>
                            <td colSpan={5} className="px-0 text-center">{accountPageLabels.No_data_available}</td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </div>
      </div>
      
      {transactionData?.length > 0 ?
                    <>
                        <div className="row gap-4 mt-4">
                            {/* pagination */}
                            <div className="col-12">
                                <ul className="pageNavigationSection mb-0 d-flex gap-2 list-unstyled align-items-center justify-content-center">
                                    <li
                                        className=" cursor-pointer bg-dark p-2"
                                        onClick={() => {
                                            if (pageNo < 2) return
                                            setPageNo(pre => pre - 1)
                                        }}
                                    >
                                        <span
                                            className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation"
                                        >
                                            <img
                                                src="assets/img/prevIcon.png"
                                                alt="leftarro"
                                                className="img-fluid w-100 h-100"
                                            />
                                        </span>
                                    </li>
                                    {
                                        pagination.btnOne > 1 ? <li className="bg-dark p-2 text-light cursor-pointer" onClick={() => {
                                            setIsLoadMore(true)
                                            setPageNo(1)
                                        }}>
                                            <span className={`text-decoration-none d-flex align-items-center justify-content-center pageNavgation`}>
                                                1
                                            </span>
                                        </li> : ''
                                    }
                                    {
                                        pagination.btnOne > 2 ?
                                            <li className=" cursor-pointer bg-dark p-2 text-light">
                                                <span className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation ">
                                                    <img src="assets/img/Menu.svg" alt="menu" className="img-fluid w-100 h-100" />
                                                </span>
                                            </li> : ''
                                    }
                                    <li className=" cursor-pointer bg-dark p-2 text-light" onClick={() => setPageNo(pagination.btnOne)} >
                                        <span className={`text-decoration-none ${pageNo == pagination.btnOne ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                                            {pagination.btnOne}
                                        </span>
                                    </li>
                                    {
                                        totalPages <= pagination.btnTwo - 1 ? '' : <li onClick={() => setPageNo(pagination.btnTwo)} className=" bg-dark p-2 text-light cursor-pointer">
                                            <span className={`text-decoration-none ${pageNo == pagination.btnTwo ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                                                {pagination.btnTwo}
                                            </span>
                                        </li>
                                    }
                                    {
                                        totalPages <= pagination.btnThree - 1 ? '' : <li onClick={() => setPageNo(pagination.btnThree)} className="bg-dark p-2 text-light cursor-pointer ">
                                            <span className={`text-decoration-none ${pageNo == pagination.btnThree ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                                                {pagination.btnThree}
                                            </span>
                                        </li>
                                    }
                                    {
                                        totalPages >= pagination.btnThree + 2 ? <li className="bg-dark p-2 text-light cursor-pointer">
                                            <span className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation bg-transparent">
                                                <img src="assets/img/Menu.svg" alt="menu" className="img-fluid w-100 h-100" />
                                            </span>
                                        </li> : ''
                                    }
                                    {
                                        pagination.btnThree == totalPages || pagination.btnTwo == totalPages || pagination.btnOne == totalPages ? '' :
                                            <li className=" bg-dark p-2 text-light cursor-pointer" onClick={() => setPageNo(pagination.btnFive)}>
                                                <span className={`text-decoration-none text-light ${pageNo == pagination.btnFive ? "active" : ''}  d-flex align-items-center justify-content-center pageNavgation`}>
                                                    {pagination.btnFive}
                                                </span>
                                            </li>
                                    }
                                    <li
                                        className="bg-dark p-2 text-light cursor-pointer bg-dark p-2"
                                        onClick={() => {
                                            if (pageNo > totalPages - 1) return
                                            setPageNo(pre => pre + 1)
                                        }}
                                    >
                                        <span
                                            className="text-decoration-none d-flex align-items-center justify-content-center pageNavgation">
                                            <img src="assets/img/nextIcon.png" alt="leftarro" className="img-fluid w-100 h-100" />
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>
                    : ''}
    </>
  )
}

export default CasinoTransaction