import { createContext, useState } from "react";
import { ACCOUNT_TABS } from "../constants";

export const AccountContext = createContext(null);

const AccountContextProvider = ({ children }) => {
  const [accountTab, setAccountTab] = useState({
    selectedTab: "profile",
  });
  return (
    <AccountContext.Provider value={{ accountTab, setAccountTab }}>
      {children}
    </AccountContext.Provider>
  );
};

export default AccountContextProvider;
