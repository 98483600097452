import React, { useEffect, useRef } from 'react'
import Header from './../components/header/Header'
import Footer from './../components/Footer/Footer'
import Sidebar from './../components/sidebar/Sidebar'
import { useLocation } from 'react-router-dom'


const AppLayout = ({Component}) => {
  const scrollDiv = useRef()
  const location = useLocation()
  useEffect(()=> {
    scrollDiv?.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [location?.pathname])
  return (
    <>
    <div className="appLayout h-100 overflow-hidden d-flex flex-column">
      <Header/>
      <div className="mainContent h-100 overflow-hidden d-flex flexwrap">
        <Sidebar/>
        <div className="h-100 overflow-hidden scrollPart">
          <div className="h-100 overflow-y-auto text-white" ref={scrollDiv}>
            <div className="bodyScrollInnerPart">
              <>
                <Component/>
              </>
            </div>
            <Footer/>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}
export default AppLayout