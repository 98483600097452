import React, { useEffect, useState } from 'react'
import 'swiper/css';
import 'swiper/css/navigation';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../hooks/useAuth';
import { getAPI, getAPIAuth } from '../../../services/apiInstance';
import { RESPONSIVE_WIDTH } from '../../../constants';
import { useMediaQuery } from '../../../utils/useMediaQuery';
import GameCard from '../../../components/GameCard/GameCard';
import DropModal from '../../../components/ModalSection/DropModal';
import NoData from '../../../components/noData/NoData';


const SelectedGame = ({ selectedCategory,data }) => {
    const [allCats, setAllCats] = useState([])
    const [gamesLoader, setGamesLoader] = useState(true)
    const [isLoadMore, setIsLoadMore] = useState(false)
    const { token } = useAuth()
    const userDetails = useSelector(state => state.userDetails)
    const isMobile = useMediaQuery({ maxWidth: RESPONSIVE_WIDTH.SM_SCREEN })
    const { t } = useTranslation()
    const casinoLabels = t("casinoLabels", { returnObjects: true })
    const commonText = t("commonText", { returnObjects: true })
    const [sortBy, setSortBy] = useState('')
    const [selectedProvider, setSelectedProvider] = useState('')
    const [pageNo, setPageNo] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [providerList, setProviderList] = useState([]);
    const [providerName, setProviderName] = useState('All')

    const getGames = async () => {
        setGamesLoader(true)
        try {
            if (token && userDetails?.id) {
                const res = await getAPIAuth(`games/get-games-by-cat?is_mobile=${isMobile ? "true" : "false"}&categoryId=${selectedCategory}&providerId=${selectedProvider}&gameNameSort=${sortBy}&gameLimit=16&page=1`)
                if (res?.data?.success) {
                    setAllCats(res?.data?.data?.[0]?.data)
                    setTotalPages(res?.data?.data?.[0]?.totalGame)
                } else {
                    setAllCats([])
                    setTotalPages(0)
                }
            } else {
                const res = await getAPI(`games/get-games-by-cat?is_mobile=${isMobile ? "true" : "false"}&categoryId=${selectedCategory}&providerId=${selectedProvider}&gameNameSort=${sortBy}&gameLimit=16&page=1`)

                if (res?.data?.success) {
                    setAllCats(res?.data?.data?.[0]?.data)
                    setTotalPages(res?.data?.data?.[0]?.totalGame)
                }

            }
        } catch (error) {
            setAllCats([])
            setTotalPages(0)
        } finally {
            setGamesLoader(false)
        }
    }

    useEffect(() => {
        getGames()
    }, [token, userDetails?.id, selectedCategory, selectedProvider, sortBy])


    const loadMore = async () => {
        setIsLoadMore(true)
        try {
            if (token && userDetails?.id) {
                const res = await getAPIAuth(`games/get-games-by-cat?is_mobile=${isMobile ? "true" : "false"}&categoryId=${selectedCategory}&providerId=${selectedProvider}&gameNameSort=${sortBy}&gameLimit=16&page=${pageNo + 1}`)
                if (res?.data?.success) {
                    setAllCats([...allCats, ...res?.data?.data?.[0]?.data])
                    setTotalPages(res?.data?.data?.[0]?.totalGame)
                } else {
                    setAllCats([])
                    setTotalPages(0)
                }
            } else {
                const res = await getAPI(`games/get-games-by-cat?is_mobile=${isMobile ? "true" : "false"}&categoryId=${selectedCategory}&providerId=${selectedProvider}&gameNameSort=${sortBy}&gameLimit=16&page=${pageNo + 1}`)
                if (res?.data?.success) {
                    setAllCats([...allCats, ...res?.data?.data?.[0]?.data])
                    setTotalPages(res?.data?.data?.[0]?.totalGame)
                }

            }
        } catch (error) {
            setAllCats([])
            setTotalPages(0)
        } finally {
            setIsLoadMore(false)
        }
    }

    const getProvider = async () => {
        try {
          const result = await getAPI(`crypto/get-provider?casinoCatId=${selectedCategory}`);
          if (result.data.success) {
            setProviderList(result?.data?.data);
          } else {
          }
        } catch (error) { }
      };
    
      useEffect(() => {
        getProvider();
      }, [selectedCategory]);

    // blank selected provider whenever selecct category changes
    useEffect(() => {
        setSelectedProvider("")
        setPageNo(1)
    }, [selectedCategory])

    // blank sort by whenever selecct category changes
    useEffect(() => {
        setSortBy("")
        setPageNo(1)
    }, [selectedCategory])



    return (
        <>
        {/* <DropModal title={"Providers"} selected={selectedProvider} data={providerList}  />
        <DropModal title={"Sort"} selected={sortBy} /> */}
                                <div className='row row-cols-3 row-cols-sm-5 row-cols-xl-6 g-2 g-md-3 gameCardGrid sectionSpacing' >
                                {!gamesLoader ?
                                                allCats?.length > 0 ?
                                                allCats?.map((item) => {
                                                    return(
                                                        <div
                                                            key={item?._id}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                            }}
                                                        >
                                                            <GameCard data={item} />
                                                        </div>
                                                    )})
                                                    : 
                                                        <NoData/>
                                                :
                                                <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                                    <Spinner animation="border" variant="light" size="sm" />
                                                </div>
                                            }
                                </div>
                                {
                                    totalPages > pageNo && (

                                        <div className="col-12 d-flex align-items-center justify-content-center mt-3 sectionSpacing">
                                            <button onClick={() => {
                                                setPageNo(pageNo + 1)
                                                loadMore()
                                            }} className='gamePageLoadMoreBtn d-flex align-items-center justify-content-center border-0'>
                                                {
                                                    isLoadMore ? <Spinner animation="border" variant="light" size="sm" /> : 'Load More'
                                                }

                                            </button>
                                        </div>
                                    )
                                }
        </>
    )
}

export default SelectedGame