import React from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'
import { useClaimBonus } from '../../../hooks/useClaimBonus'
import { getAPIAuth } from '../../../services/apiInstance'
import plusIcon from './../../../assets/images/plus.png'
import eth from './../../../assets/images/blue.png'
import { userBalanceAction } from '../../../store/action'
import { socket } from '../../../services/socket'

const UserBalanceComp = () => {
  const { token, setToken } = useAuth()
  const userDetails = useSelector(state => state.userDetails)
  const intervalRef = useRef()
  const location = useLocation()
  const arrPath = location.pathname.split('/')
  const userBalance = useSelector(state => state.userBalance)
  const [userBal, setUserBal] = useState(userBalance)
  const [currencyExchange, setCurrencyExcgange] = useState([])
  const navigate = useNavigate()
  const {setClaimBonusObject} = useClaimBonus()
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false)

  const dispatch = useDispatch()
  const getUserBal = async () => {
      try {
          const res = await getAPIAuth(`user/user-balance?userId=${userDetails?.id}`, token, setToken)
          if (res?.data?.success) {
              setUserBal(res.data.data)
              dispatch(userBalanceAction(res.data.data))
          }
      } catch (error) {

      }
  }


  // to show updated user balance 
  // useEffect(() => {
      // if (userDetails?.id && token) {
      //     if (intervalRef.current) {
      //         clearInterval(intervalRef.current)
      //     }
      //     intervalRef.current = setInterval(() => 
          // getUserBal()
      //     , 300000000)
      // }
      // return () => clearInterval(intervalRef.current)

  // }, [token, userDetails?.id, location.pathname])

  useEffect(()=> {
    if(userDetails?.id) {
            try {

            socket.on(`userbalance-${userDetails?.id}`, (data) => {
                if (data?.status) {
                    setUserBal(data?.data)
                    dispatch(userBalanceAction(data?.data))
                }
            });
        } catch (error) {
        }
        }
}, [userDetails?.id])



useEffect(()=> {
    try {
        socket.on(`socket-connected`, (data) => {
        });
    } catch (error) {
    }
}, [])

  return (
    <>
      {/* <div className="userBalance d-flex align-items-center justify-content-between"> */}
        <span className='userBal userBal1 ps-2' 
          // style={{maxWidth:"90%", overflow:"scroll"}}
          ><img src={userBalance?.setCurrencyData?.icon} alt="" />
          <span className="dollorSymb">{userDetails.fiatStatus ? userDetails?.currency?.symbol : ''}</span> 
          <span className="d-block amountSpan">{userBalance?.balance || userBalance?.balance === 0 ? Number(userBalance?.balance)?.toFixed(5) : userDetails?.totalbalance ? Number(userDetails?.totalbalance).toFixed(5) : "0.00"}</span>
        </span>
        {/* <span className='plusBtn d-flex justify-content-center align-items-center'>
          <img src={plusIcon} alt="plus icon" className='object-fit-contain'/>
        </span> */}
      {/* </div> */}
    </>
  )
}

export default UserBalanceComp