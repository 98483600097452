import { useContext } from "react";
import { LoginSuccessModalContext } from "../context/loginSuccessModalContext";

export const useLoginSuccess = () => {
  const { loginModalObject, setLoginModalObject } = useContext(
    LoginSuccessModalContext
  );
  return {
    loginModalObject,
    setLoginModalObject,
  };
};
