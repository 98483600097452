import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import providers1 from "../../../assets/images/sidebar/Vector1.png";
import CasinoCard1 from "../../../assets/images/casino1-img1.png";
import CasinoCard2 from "../../../assets/images/casino1-img2.png";
import CasinoCard3 from "../../../assets/images/casino1-img3.png";
import CasinoCard4 from "../../../assets/images/casino1-img4.png";
import CasinoCard5 from "../../../assets/images/casino1-img5.png";
import CasinoCard6 from "../../../assets/images/casino1-img6.png";
import CasinoCard7 from "../../../assets/images/casino1-img7.png";
import CasinoCard8 from "../../../assets/images/casino1-img8.png";

import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import { Link, useNavigate } from "react-router-dom";
import { getAPIAuth } from "../../../services/apiInstance";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ROUTE_CONST } from "../../../constants";
import GameCard from "../../../components/GameCard/GameCard";

const CasinoPageSlider1 = ({ data }) => {
  const navigate = useNavigate();
  const [providers, setProviders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const sidebarLabels = t("gameProviderLabels", { returnObjects: true });
  const PlaySlotLabels = t("PlaySlotLabels", { returnObjects: true });

  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await getAPIAuth("games/get-all-providers");
      if (res.data.success) {
        setProviders(res.data.data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  const handleFunction = (provider) => {
    navigate(`${ROUTE_CONST.PROVIDERS}/${provider}`);
  };



  return (
    <>
      <div className="row py-2 md:py-3 pt-lg-0 sectionSpacing">
        <div className="col-12 px-0 ps-2 d-flex justify-content-between mb-1 pb-1 mb-lg-3 align-items-center">
          <div className="mainHeading d-flex align-items-center mainHeadingCasino">
            <img src={data?.name?.image} alt="" />
            {data?.name?.category_name}
          </div>
          <div className="d-flex align-items-center pe-3">
            <div className="mainSubHeading mainSubHeadingCasino d-flex align-items-center">
              <Link
                to={ROUTE_CONST.PROVIDERS}
                className="headingAnchor text-white"
              >
                {PlaySlotLabels.View_All}
              </Link>{" "}
              <img
                className="viewIcon"
                src="./assets/img/nextIcon.png"
                alt=""
              />
            </div>
            <div className="casinoSliderBtns casinoPageSliderBtns d-flex d-lg-flex align-items-center">
              <div className={`sliderBtn ${"providerPrev100" + data?._id}`}>
                <img src="./assets/img/prevIcon.png" alt="" />
              </div>
              <div className={`sliderBtn ${"providerNext100" + data._id}`}>
                <img src="./assets/img/nextIcon.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        {!isLoading ? (
          providers?.length ? (
            <Swiper
              className="mySwiper casinoSwiperWrapper"
              spaceBetween="10"
              loop={true}
              slidesPerView={'auto'}
              navigation={{
                prevEl: `.providerPrev100${data._id}`,
                nextEl: `.providerNext100${data._id}`,
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Navigation, Autoplay]}
            // breakpoints={{
            //   0: {
            //     slidesPerView: 3,
            //   },
            //   576: {
            //     slidesPerView: 4,
            //   },
            //   768: {
            //     slidesPerView: 5,
            //   },
            //   992: {
            //     slidesPerView: 5,
            //   },
            //   1200: {
            //     slidesPerView: 7,
            //   },
            //   1400: {
            //     slidesPerView: 7,
            //   },
            // }}
            >
              {/* {providers?.map((item)=>( 
                            <SwiperSlide className="providerSwiperSlider" key={item?.provider}>
                                <div className="ProviderSlider d-flex justify-content-center align-items-center cursor-pointer"  onClick={() => handleFunction(item?.provider)}>
                                    <img src={item.image} alt={`${item?.provider}`} />
                                </div>
                            </SwiperSlide>
                        ))} */}

              {data?.data?.map((game) => {
                return (
                  <SwiperSlide key={game._id}
                    className="popularcardsWidth"
                  >
                    {/* <div
                      onClick={() =>
                        navigate(ROUTE_CONST.CASINO + `/${game.uuid}`)
                      }
                      className="  cursor-pointer casino-card-img"
                    >
                      <img
                        src={game.image}
                        alt="CasinoCard1"
                        className="w-100 h-100 object-fit-cover"
                      />
                    </div> */}

                    <GameCard
                      data={game}
                      footer={false}

                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            ""
          )
        ) : (
          <div className="text-center">
            <Spinner
              className="m-auto"
              animation="border"
              variant="light"
              size="sm"
            />
          </div>
        )}

        <div className="col-12 d-lg-none" style={{ marginTop: 0 }}>
          <Swiper
            className="mySwiper"
            spaceBetween="10"
            dir="rtl"
            loop={true}
            
            slidesPerView={"auto"}
            // navigation={{
            // prevEl: '.providerPrev101',
            // nextEl: '.providerNext101',
            // }}
            modules={[Navigation, Autoplay]}
          // breakpoints={{
          // 0: {
          //     slidesPerView: 2,
          // },
          // 576: {
          //     slidesPerView: 4,
          // },
          // 768: {
          //     slidesPerView: 5,
          // },
          // 992: {
          //     slidesPerView: 5,
          // },
          // 1200: {
          //     slidesPerView: 5,
          // },
          // 1400: {
          //     slidesPerView: 5,
          // },
          // }}
          >
            {!isLoading ? (
              providers?.length ? (
                providers?.map((item) => (
                  <SwiperSlide className="providerSwiperSlider">
                    {/* <div className="ProviderSlider d-flex justify-content-center align-items-center">
                            <img src={evolution} alt="" />
                        </div> */}
                    {/* <div className="ProviderSlider d-flex justify-content-center align-items-center cursor-pointer"  onClick={() => handleFunction(item?.provider)}>
                            <img src={item.image} alt={`${item?.provider}`} />
                        </div> */}
                  </SwiperSlide>
                ))
              ) : null
            ) : (
              <div className="text-center">
                <Spinner
                  className="m-auto"
                  animation="border"
                  variant="light"
                  size="sm"
                />
              </div>
            )}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default CasinoPageSlider1;
