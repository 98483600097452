import React, { useEffect, useState } from 'react'
import menu from '../../../assets/images/profile/withdrawals.png'


import withdrawtab from '../../../assets/images/profile/withdrawtab.png';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAPIAuth } from '../../../services/apiInstance';
import { ACCOUNT_TABS } from '../../../constants';
import { useAccount } from '../../../hooks/useAccount';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';


const WidthdrawalTab = () => {
    const { accountTab} = useAccount()
    const [cryptoList, setCryptoList] = useState([])
    const [activeCrypto, setActiveCrypto] = useState('ALL')
    const [withdraws, setWithdraws] = useState([])
    const [transactionId, setTransactionId] = useState('')
    const [loader, setLoader] = useState(false)
    const [listLoader, setListLoader] = useState(true)
    const userDetails = useSelector((state) => state.userDetails);
    const userId = userDetails?.id;
    const userBalance = userDetails?.totalbalance;
    const [showTransaction, setShowTransaction] = useState(false)
    const { t } = useTranslation()
    const accountPageLabels = t("accountPageLabels", { returnObjects: true })
    const dgnLabels = t("dgnLabels", { returnObjects: true })
    const [pagination, setPagination] = useState({
      btnOne: 1,
      btnTwo: 2,
      btnThree: 3,
      btnFour: "...",
      btnFive: 0
    })
    const [pageNo, setPageNo] = useState(1)
    const [isLoadMore, setIsLoadMore] = useState(false)
    const [totalPages, setTotalPage] = useState()
  
    const paginationHandler = (count) => {
      if (pagination.btnOne != pageNo) {
        if (pageNo - 1 == pagination.btnThree) {
          setPagination(
            prev => ({
              ...prev,
              btnOne: pagination.btnOne + 1,
              btnTwo: pagination.btnTwo + 1,
              btnThree: pagination.btnThree + 1
            })
          )
        } else if (pageNo + 1 == pagination.btnOne) {
          setPagination(
            prev => ({ ...prev, btnOne: pagination.btnOne - 1, btnTwo: pagination.btnTwo - 1, btnThree: pagination.btnThree - 1 })
          )
        } else if (pageNo == totalPages) {
          setPagination(
            prev => ({ ...prev, btnOne: totalPages - 2, btnTwo: totalPages - 1, btnThree: totalPages })
          )
        }
      }
    }
  
  
    const loadMore = async () => {
      try {
        const res = await getAPIAuth(`user/get-withdraw-list?userId=${userId}&chainId=${activeCrypto == 'ALL' ? "" : activeCrypto}&transactionId=${transactionId}&page=${pageNo}`)
  
        if (res.data.success) {
          // setTransactionData(res.data.data)
          setWithdraws(res?.data?.data)
          setTotalPage(res.data.totalPages)
        }
      } catch (error) {
        //error handling
      }
  
    }
  
    useEffect(() => {
      if (userDetails?.id && (accountTab?.selectedTab === ACCOUNT_TABS.WITHDRAW)) {
        if (pageNo >= 1 && isLoadMore) {
          paginationHandler()
          loadMore()
          setIsLoadMore(false)
        } else if (pageNo >= 1) {
          paginationHandler()
          loadMore()
        }
      }
    }, [pageNo, userDetails?.id, accountTab?.selectedTab])
  
  
    // getting all crypto id
    const getCryptoList = async () => {
      setListLoader(true)
      try {
        const res = await getAPIAuth(`crypto/get-crypt-favorite-by-admin`)
        if (res?.data?.success) {
          setCryptoList(res?.data?.data)
          setActiveCrypto("ALL")
        }
      } catch (error) {
  
      } finally {
        setListLoader(false)
      }
    }
  
    useEffect(() => {
      getCryptoList()
    }, [])
  
  
    const getWithdraws = async () => {
      setLoader(true)
      try {
        if (userId) {
          // const res = await getAPIAuth(`user/find-transaction?userId=${userId}&chain=${activeCrypto}&type=withdraw&transactionId=${transactionId}`)
          const res = await getAPIAuth(`user/get-withdraw-list?userId=${userId}&chainId=${activeCrypto == 'ALL' ? "" : activeCrypto}&transactionId=${transactionId}&page=${pageNo}`)
          if (res?.data?.success) {
            setWithdraws(res?.data?.data)
            setTransactionId('')
            setTotalPage(res.data.pagination?.[0]?.totalPages)
            setPagination({
                btnOne: 1,
                btnTwo: 2,
                btnThree: 3,
                btnFour: "...",
                btnFive: res.data.pagination?.[0]?.totalPages
            })
          } else {
            setWithdraws([])
          }
        }
      } catch (error) {
  
      } finally {
        setLoader(false)
      }
    }
  
    useEffect(() => {
      if (accountTab?.selectedTab === ACCOUNT_TABS.WITHDRAW) {
        if (activeCrypto) {
          getWithdraws()
        }
      }
    }, [userId, activeCrypto, accountTab?.selectedTab])
    // getting data from transaction id
    const getDataFromTransactionId = () => {
      getWithdraws()
    }
  
    // for set  active crypto
    const handleActiveCrypto = (id) => {
      setActiveCrypto(id)
    }
    return (
        <>
        <div className='setting space'>
        <div className='d-flex align-items-center'>
            <div className='imgIcon'>
                <img src={menu} alt="" className='h-100 w-100' />
            </div>
            <div className='set-text ms-3'>
            {accountPageLabels.Withdrawals}
            </div>
           </div>
           <div className='d-flex mt-5 flex-nowrap overflow-y-auto pb-5 gap-2 align-items-center'>
           {!listLoader ?
              cryptoList?.length > 0 ?
              <>
            <button className={`cbtn ${"ALL" === activeCrypto ? 'gBtn' : ''}`} onClick={() => handleActiveCrypto("ALL")}>{accountPageLabels.ALL} {dgnLabels.Chain}</button>
                {
                    cryptoList?.map((item) => (
                      <button
                        key={item?.code}
                        className={`cbtn text-uppercase ${item?.code === activeCrypto ? 'gBtn' : ''}`}
                        onClick={() => handleActiveCrypto(item?.code)}
                      >
                        {item?.code}
                      </button>
                    ))
                }
            </>
            : ''
              : (
                <>
                  {/* <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="light" size="lg" />
                </div> */}
                </>
              )
            }
           </div>
           {!loader ?
              withdraws?.length > 0 ?
            <div className="col-12 px-0 betTable">
                <div className="table-responsive">
                    <table className='table mb-0'>
                    <thead>
                        <tr>
                        <th>{accountPageLabels.COIN}</th>
                        <th className=''>{accountPageLabels.AMOUNT}</th>
                        <th className=''>{accountPageLabels.DATE_TIME}</th>
                        <th className=''>{accountPageLabels.CREDIT_AMOUNT}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {withdraws?.map((item) => (
                            <tr key={item?._id}>
                                <td className=' text-white'>
                                    {item?.coin ? item?.coin : item?.currency ? item?.currency : "----"}
                                </td>
                                <td className=' text-white'>
                                    {item?.amount ? Number(item.amount).toFixed(2) : '0.00'}
                                </td>
                                <td className=' text-white'>
                                    {moment(item.createdAt).format("MMM DD YYYY , HH:mm")}
                                </td>
                                <td className=' text-white'>
                                    {item?.amountInUsdt ? Number(item?.amountInUsdt).toFixed(4) : item?.amount ? Number(item?.amount).toFixed(4) : '0.00'}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
            </div>
            
            : (
                <div className="d-flex justify-content-center my-5">
                <button className='dataBtn'>
                {accountPageLabels.NO_ENTRIES}
                </button>
            </div>
              )
            : (<div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="light" size="sm" />
            </div>)
          }
        </div>
            
        </>
    )
}

export default WidthdrawalTab