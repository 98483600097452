import React from 'react'
import visa from '../../assets/images/Visa.png'
import applePay from '../../assets/images/applepay.png'
import gpay from '../../assets/images/googlepay.png'
import master from '../../assets/images/Mastercard.png'
import { useTranslation } from 'react-i18next'
import { useWalletModal } from '../../hooks/useWallet'
import { AUTH_MODAL_TABS, WALLET_MODAL_TABS } from '../../constants'
import { useSearchParams } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import { useAuthModal } from '../../hooks/useAuthModal'

const DepositBanner = () => {
  const { t } = useTranslation()
  const appFooterLabels = t("appFooter", { returnObjects: true })
  const [query, setQuery] = useSearchParams()
  const { setWalletModalObject } = useWalletModal()
  const { token } = useAuth()
  const { setAuthModalObject } = useAuthModal();



  return (
    <>
      {/* <div className='banner my-3 my-md-0'>
        <div className="row align-items-center gap-4 gap-lg-0 mx-0">
          <div className="col px-0">
              <div className="row mx-0 align-items-lg-center">
                <div className="col-12 col-lg-auto px-0"><div className="bannerHeading">{appFooterLabels.depositbonus}</div></div>
                <div className="col-12 col-lg ps-0">
                  <div className="row justify-content-between justify-content-sm-start justify-content-lg-evenly gap-2 mx-0 bannerImgRow">
                    <div className="col-auto px-0">
                    <img src={visa} alt="" />
                    </div>
                    <div className="col-auto px-0">
                      <img src={applePay} alt="" />
                    </div>
                    <div className="col-auto px-0">
                      <img src={gpay} alt="" />
                    </div>
                    <div className="col-auto px-0">
                      <img src={master} alt="" />
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div className="col-auto px-0">
            <button      
              onClick={() => {
                if(token) {
                  setWalletModalObject((prev) => ({
                    isWalletOpen: true,
                    selectedTab: WALLET_MODAL_TABS.DEPOSIT
                  }))
                  setQuery({ action: "wallet" })
                } else {
                  setAuthModalObject(pre => ({
                    isAuthOpen: true,
                    selectedTab: AUTH_MODAL_TABS.LOG_IN
                  }))
                }
              }}
              className='depositBtn text-white'>{appFooterLabels.deposit_now}</button>
          </div>
        </div>
      </div> */}
    
    </>
  )
}

export default DepositBanner