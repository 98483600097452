import fileEmpty from "../../assets/images/fileEmpty.svg";
const MyRewards = () => {
  return (
    <div
      class="tab-pane fade"
      id="nav-rewards"
      role="tabpanel"
      aria-labelledby="nav-rewards-tab"
    >
      <div className="bg_main_color">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-12  mb-lg-0 mb-3">
            <div className="avaliable-rewards">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="rewards-text">Available Commission Rewards</h6>
                <div>
                  <button
                    type="button"
                    class="bg-transparent border-0"
                    data-bs-toggle="modal"
                    data-bs-target="#CommissionRewards"
                  >
                    <svg
                      stroke="currentColor"
                      fill="#D4D4D4"
                      stroke-width="0"
                      viewBox="0 0 1024 1024"
                      height="15px"
                      width="15px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                      <path d="M464 688a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path>
                    </svg>
                  </button>
                </div>

                {/*Available Commission Rewards modal  */}
                <div
                  class="modal fade"
                  id="CommissionRewards"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1 class="modal-title" id="exampleModalLabel">
                          Commission Rewards
                        </h1>
                        <div className="custom-close-btn">
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                      </div>
                      <div class="modal-body">
                        <h6 className="mb-3">
                          {" "}
                          <span className="mb-2">
                            Available Commission Rewards
                          </span>{" "}
                          <br /> The rewards you can withdraw to your wallet.
                        </h6>
                        <h6 className="mb-3">
                          {" "}
                          <span className="mb-2">
                            {" "}
                            Total received
                          </span> <br /> The rewards you have received up to
                          now. It includes the rewards already withdrawn and the
                          available commission rewards.
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h5 className="reward-money mb-2">$0.00</h5>
              </div>
              <div>
                <h6 className="total-recieved">
                  Total Received <span> $0.00</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mb-lg-0 mb-3">
            <div className="avaliable-rewards">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="rewards-text">Available Referral Rewards</h6>
                <div>
                  <button
                    type="button"
                    class="bg-transparent border-0"
                    data-bs-toggle="modal"
                    data-bs-target="#ReferralRewards"
                  >
                    <svg
                      stroke="currentColor"
                      fill="#D4D4D4"
                      stroke-width="0"
                      viewBox="0 0 1024 1024"
                      height="15px"
                      width="15px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                      <path d="M464 688a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path>
                    </svg>
                  </button>
                </div>

                {/*Available Referral Rewards modal  */}
                <div
                  class="modal fade"
                  id="ReferralRewards"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header ">
                        <h1 class="modal-title" id="exampleModalLabel">
                          Referral Rewards
                        </h1>
                        <div className="custom-close-btn">
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                      </div>
                      <div class="modal-body">
                        <h6 className="mb-3">
                          {" "}
                          <span className="mb-2">
                            Available Referral Rewards
                          </span>{" "}
                          <br /> The rewards you can withdraw to your wallet.
                        </h6>
                        <h6 className="mb-3">
                          {" "}
                          <span className="mb-2">
                            {" "}
                            Total received
                          </span> <br /> All unlocked rewards received so far,
                          including withdrawn and available for withdrawal
                        </h6>
                        <h6 className="mb-3">
                          {" "}
                          <span className="mb-2">
                            {" "}
                            Locked Rewards
                          </span> <br /> The rewards that you will receive in
                          the future when your friends’ VIP level increases.
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h5 className="reward-money mb-2">$0.00</h5>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h6 className="total-recieved">
                    Total Received <span>$0.00</span>
                  </h6>
                </div>
                <div>
                  <h6 className="total-recieved">
                    Locked Rewards <span>$0.00</span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-12 mb-md-0 mb-3">
            <div className="avaliable-rewards">
              <div className="d-flex align-items-center justify-content-center gap-3 h-100">
                <button className="swap-btn">Swap</button>
                <button className="withdraw-btn">Withdraw to Wallet</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg_main_color p-0 mt-4">
        <div>
          <div className="d-flex align-items-center justify-content-between rewards-tabs">
            <ul class="nav nav-pills" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="pills-Commission-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-Commission"
                  type="button"
                  role="tab"
                  aria-controls="pills-Commission"
                  aria-selected="true"
                >
                  Commission
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="pills-Refferal-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-Refferal"
                  type="button"
                  role="tab"
                  aria-controls="pills-Refferal"
                  aria-selected="false"
                >
                  Refferal
                </button>
              </li>
            </ul>
            <div className="d-flex align-items-center gap-2">
              <button type="button" class="rules-btn border-0">
                Rules
              </button>
              <button
                type="button"
                class="history-btn border-0"
                data-bs-toggle="modal"
                data-bs-target="#History"
              >
                History
              </button>
              {/* history modal */}
              <div
                class="modal fade"
                id="History"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title" id="exampleModalLabel">
                        Reward History
                      </h1>
                      <div className="custom-close-btn">
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                    </div>
                    <div class="modal-body">
                      <div className="row">
                        <div className="col-md-8">
                          <div class="dropdown">
                            <button
                              class="dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Referral Rewards
                            </button>
                            <ul class="dropdown-menu w-100">
                              <li>
                                <a class="dropdown-item" href="#">
                                  Action
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  Action two
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  Action three
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 table-responsive">
                        <table class="table refferal-modal-table">
                          <thead>
                            <tr>
                              <th scope="col">Currency</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Time</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td colSpan={4}>
                                <div className="activity_content text-center d-flex justify-content-center align-items-center flex-column gap-3 py-5">
                                  <div className="empty_img">
                                    <img
                                      src={fileEmpty}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="text-center">
                                    <div className="no-rewards-text">
                                      No rewards yet invite friends to join you
                                      now!
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-Commission"
              role="tabpanel"
              aria-labelledby="pills-Commission-tab"
              tabindex="0"
            >
              <div className="activity_content text-center d-flex justify-content-center align-items-center flex-column gap-3 py-5">
                <div className="empty_img">
                  <img src={fileEmpty} alt="" className="img-fluid" />
                </div>
                <div className="text-center">
                  <div className="no-rewards-text">
                    No rewards yet invite friends to join you now!
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-Refferal"
              role="tabpanel"
              aria-labelledby="pills-Refferal-tab"
              tabindex="0"
            >
              <div className="row">
                <div className="col-md-4">
                  <div className="d-flex align-items-center justify-content-between registration-date">
                    <h6 className="mb-0">
                      Registration Date: <span>All Range</span>
                    </h6>
                    <div className="cross-btn">
                      <svg
                        stroke="#fff"
                        fill="none"
                        stroke-width="0"
                        viewBox="0 0 15 15"
                        height="15px"
                        width="15px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 table-responsive">
                <table class="table refferal-table">
                  <thead>
                    <tr>
                      <th scope="col">Username</th>
                      <th scope="col">Registration Date</th>
                      <th scope="col">VIP Level</th>
                      <th scope="col">Code</th>
                      <th scope="col">Earned</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>example</td>
                      <td>example</td>
                      <td>example</td>
                      <td>example</td>
                      <td>example</td>
                    </tr>
                    <tr>
                      <td>example</td>
                      <td>example</td>
                      <td>example</td>
                      <td>example</td>
                      <td>example</td>
                    </tr>
                    <tr>
                      <td>example</td>
                      <td>example</td>
                      <td>example</td>
                      <td>example</td>
                      <td>example</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="activity_content text-center d-flex justify-content-center align-items-center flex-column gap-3 py-5">
                <div className="empty_img">
                  <img src={fileEmpty} alt="" className="img-fluid" />
                </div>
                <div className="text-center">
                  <div className="no-rewards-text">
                    No rewards yet invite friends to join you now!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyRewards;
