import Copy from "../../assets/images/copy.svg";
import fileEmpty from "../../assets/images/fileEmpty.svg";


const ReferralCode = () => {
  return (
    <div
      class="tab-pane fade"
      id="nav-referralCode"
      role="tabpanel"
      aria-labelledby="nav-referralCode-tab"
    >
      <div className="create-code-section">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-12 mb-lg-0 mb-3">
            <div className="Referral-Code">
              <div className="">
                <h6 className="rewards-text">Referral Code Created</h6>
              </div>
              <div>
                <h6 className="total-recieved">
                  1/ <span>20</span>
                </h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mb-lg-0 mb-3">
            <div className="Referral-Code">
              <div className="">
                <h6 className="rewards-text">Friends</h6>
              </div>
              <div>
                <h6 className="total-recieved">0</h6>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-12 mb-lg-0 mb-3">
            <div className="Referral-Code bg-transparent">
              <div className="h-100 d-flex align-items-center justify-content-lg-end justify-content-center">
                <button
                  className="create-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#CreateCode"
                >
                  Create Code
                </button>
              </div>
              {/* create code modal */}
              <div
                class="modal fade"
                id="CreateCode"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog  modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title fs-5" id="exampleModalLabel">
                        Create code
                      </h1>
                      <div className="custom-close-btn">
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                    </div>
                    <div class="modal-body">
                      <div className="mb-4">
                        <h6 className="modal-heading">
                          Campaign Name (Optional)
                        </h6>
                      </div>
                      <div className="mb-4">
                        <input
                          className="createCode-input"
                          type="text"
                          placeholder="Enter Campaign Name"
                        />
                      </div>
                      <div>
                        <button className="create-campaign">
                          Create Campaign
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg_main_color p-0 mt-4">
        <div>
          <div className="d-flex align-items-center justify-content-between referral-tabs">
            <ul class="nav nav-pills" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="pills-referralCode-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-referralCode"
                  type="button"
                  role="tab"
                  aria-controls="pills-referralCode"
                  aria-selected="true"
                >
                  Referral Code
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="pills-Friends-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-Friends"
                  type="button"
                  role="tab"
                  aria-controls="pills-Friends"
                  aria-selected="false"
                >
                  Friends
                </button>
              </li>
            </ul>
            <div className="d-flex align-items-center gap-2">
              <button type="button" class="rules-btn border-0">
                Rules
              </button>
              <button
                type="button"
                class="history-btn border-0"
                data-bs-toggle="modal"
                data-bs-target="#HistoryFriend"
              >
                History
              </button>
              {/* history modal */}
              <div
                class="modal fade"
                id="HistoryFriend"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title" id="exampleModalLabel">
                        Reward History
                      </h1>
                      <div className="custom-close-btn">
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                    </div>
                    <div class="modal-body">
                      <div className="row">
                        <div className="col-md-8">
                          <div class="dropdown">
                            <button
                              class="dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Referral Rewards
                            </button>
                            <ul class="dropdown-menu w-100">
                              <li>
                                <a class="dropdown-item" href="#">
                                  Action
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  Action two
                                </a>
                              </li>
                              <li>
                                <a class="dropdown-item" href="#">
                                  Action three
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 table-responsive">
                        <table class="table refferal-modal-table">
                          <thead>
                            <tr>
                              <th scope="col">Currency</th>
                              <th scope="col">Amount</th>
                              <th scope="col">Time</th>
                              <th scope="col">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td colSpan={4}>
                                <div className="activity_content text-center d-flex justify-content-center align-items-center flex-column gap-3 py-5">
                                  <div className="empty_img">
                                    <img
                                      src={fileEmpty}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="text-center">
                                    <div className="no-rewards-text">
                                      No rewards yet invite friends to join you
                                      now!
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-content refferal-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-referralCode"
              role="tabpanel"
              aria-labelledby="pills-referralCode-tab"
              tabindex="0"
            >
              <div>
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12 col-12 mb-lg-0 mb-3">
                    <div>
                      <div class="dropdown">
                        <button
                          class="dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Referral Rewards
                        </button>
                        <ul class="dropdown-menu w-100">
                          <li>
                            <a class="dropdown-item" href="#">
                              Action
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              Action two
                            </a>
                          </li>
                          <li>
                            <a class="dropdown-item" href="#">
                              Action three
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 col-12 mb-lg-0 mb-3">
                    <div className="username-search-wrapper">
                      <i class="fa-solid fa-magnifying-glass"></i>
                      <input
                        type="text"
                        placeholder="Search Username"
                        className="ms-2"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 col-12 mb-lg-0 mb-3">
                    <div class="d-flex align-items-center justify-content-between registration-date">
                      <h6 class="mb-0">
                        Registration Date: <span>All Range</span>
                      </h6>
                      <div class="cross-btn">
                        <svg
                          stroke="#fff"
                          fill="none"
                          stroke-width="0"
                          viewBox="0 0 15 15"
                          height="15px"
                          width="15px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 col-12 mb-lg-0 mb-3">
                    <div class="d-flex align-items-center justify-content-between wager-date">
                      <h6 class="mb-0">
                        Wager Date : <span>2024/10/21-2025/01/19</span>
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="mt-4 table-responsive">
                  <table class="table refferalCode-table">
                    <thead>
                      <tr>
                        <th scope="col">Username</th>
                        <th scope="col">User ID</th>
                        <th scope="col">Total Deposits (Past 7D)</th>
                        <th scope="col">Registration Date</th>
                        <th scope="col">Total Commission</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>example</td>
                        <td>example</td>
                        <td>example</td>
                        <td>example</td>
                        <td>example</td>
                        <td className="action-data">
                          <div class="">
                            <button
                              className="action-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#actionModel"
                              type="button"
                            >
                              <i class="fa-solid fa-chevron-right"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={5}>
                          <div className="activity_content text-center d-flex justify-content-center align-items-center flex-column gap-3 py-5">
                            <div className="empty_img">
                              <img
                                src={fileEmpty}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="text-center">
                              <div className="no-rewards-text">
                                No rewards yet invite friends to join you now!
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    class="modal fade"
                    id="actionModel"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h1 class="modal-title" id="exampleModalLabel">
                            Referral Code Details
                          </h1>
                          <div className="custom-close-btn">
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                        </div>
                        <div class="modal-body">
                          <div>
                            <div className="action-details-row d-flex align-items-center justify-content-between">
                              <h6 className="mb-0">name</h6>
                              <p className="mb-0">--</p>
                            </div>
                            <div className="action-details-row d-flex align-items-center justify-content-between">
                              <h6 className="mb-0">Code</h6>
                              <div>
                                <div className="copy-data detail-text">
                                  h47zsy7v{" "}
                                  <img
                                    src={Copy}
                                    alt=""
                                    className="img-fluid ps-3"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="action-details-row d-flex align-items-center justify-content-between">
                              <h6 className="mb-0">Link</h6>
                              <div>
                                <div className="copy-data detail-text">
                                  https://bc.game/i-h47zsy7v-n/{" "}
                                  <img
                                    src={Copy}
                                    alt=""
                                    className="img-fluid ps-3"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="action-details-row d-flex align-items-center justify-content-between">
                              <h6 className="mb-0">Date Created</h6>
                              <div>
                                <div className=" detail-text">
                                  2023-05-06 12:14:59
                                </div>
                              </div>
                            </div>
                            <div className="action-details-row d-flex align-items-center justify-content-between">
                              <h6 className="mb-0">Referrals</h6>
                              <div>
                                <div className=" detail-text">0</div>
                              </div>
                            </div>
                            <div className="action-details-row d-flex align-items-center justify-content-between">
                              <h6 className="mb-0">Commission Rate</h6>
                              <div>
                                <div className=" detail-text">25%</div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="wager-box">
                              <div className="modal-heading text-center">
                                <h6>Wager</h6>
                              </div>
                              <div className="modal-data">
                                <div className="modal-data-upper">
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6 className="mb-0 ">Originals ($)</h6>
                                    <p className="mb-0">--</p>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6 className="mb-0 ">
                                      Live Casino & 3rd Party Slots ($)
                                    </h6>
                                    <p className="mb-0">--</p>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6 className="mb-0 ">Sports ($)</h6>
                                    <p className="mb-0">--</p>
                                  </div>
                                </div>
                                <div className="modal-data-lower">
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6 className="mb-0 ">Total Wager ($)</h6>
                                    <p className="mb-0">--</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="Commission-box mt-4">
                              <div className="modal-heading text-center">
                                <h6>Commission</h6>
                              </div>
                              <div className="modal-data">
                                <div className="modal-data-upper">
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6 className="mb-0 ">Originals ($)</h6>
                                    <p className="mb-0">--</p>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6 className="mb-0 ">
                                      Live Casino & 3rd Party Slots ($)
                                    </h6>
                                    <p className="mb-0">--</p>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6 className="mb-0 ">Sports ($)</h6>
                                    <p className="mb-0">--</p>
                                  </div>
                                </div>
                                <div className="modal-data-lower">
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6 className="mb-0 ">Total Wager ($)</h6>
                                    <p className="mb-0">--</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-Friends"
              role="tabpanel"
              aria-labelledby="pills-Friends-tab"
              tabindex="0"
            >
              <div>
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-12 ">
                    <div class="d-flex align-items-center justify-content-between registration-friend-date">
                      <h6 class="mb-0">
                        Registration Date: <span>All Range</span>
                      </h6>
                      <div class="cross-btn">
                        <svg
                          stroke="#fff"
                          fill="none"
                          stroke-width="0"
                          viewBox="0 0 15 15"
                          height="15px"
                          width="15px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4 table-responsive">
                  <table class="table refferalCode-table">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Code</th>
                        <th scope="col">Link</th>
                        <th scope="col">Commission Rate</th>
                        <th scope="col">Date Created</th>
                        <th scope="col">Referral</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>--</td>
                        <td>
                          h47zsy7v{" "}
                          <img src={Copy} alt="" className="img-fluid ps-1" />
                        </td>
                        <td>
                          https://bc.game/i-h47zsy7v-n/{" "}
                          <img src={Copy} alt="" className="img-fluid ps-1" />{" "}
                        </td>
                        <td>25%</td>
                        <td>2023-05-06 12:14:59</td>
                        <td>0</td>
                        <td className="action-data">
                          <div class="">
                            <button
                              className="action-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#actionFriendModel"
                              type="button"
                            >
                              <i class="fa-solid fa-chevron-right"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6}>
                          <div className="activity_content text-center d-flex justify-content-center align-items-center flex-column gap-3 py-5">
                            <div className="empty_img">
                              <img
                                src={fileEmpty}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div className="text-center">
                              <div className="no-rewards-text">
                                No rewards yet invite friends to join you now!
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    class="modal fade"
                    id="actionFriendModel"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h1 class="modal-title" id="exampleModalLabel">
                            Referral Code Details
                          </h1>
                          <div className="custom-close-btn">
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                        </div>
                        <div class="modal-body">
                          <div>
                            <div className="action-details-row d-flex align-items-center justify-content-between">
                              <h6 className="mb-0">name</h6>
                              <p className="mb-0">--</p>
                            </div>
                            <div className="action-details-row d-flex align-items-center justify-content-between">
                              <h6 className="mb-0">Code</h6>
                              <div>
                                <div className="copy-data detail-text">
                                  h47zsy7v{" "}
                                  <img
                                    src={Copy}
                                    alt=""
                                    className="img-fluid ps-3"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="action-details-row d-flex align-items-center justify-content-between">
                              <h6 className="mb-0">Link</h6>
                              <div>
                                <div className="copy-data detail-text">
                                  https://bc.game/i-h47zsy7v-n/{" "}
                                  <img
                                    src={Copy}
                                    alt=""
                                    className="img-fluid ps-3"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="action-details-row d-flex align-items-center justify-content-between">
                              <h6 className="mb-0">Date Created</h6>
                              <div>
                                <div className=" detail-text">
                                  2023-05-06 12:14:59
                                </div>
                              </div>
                            </div>
                            <div className="action-details-row d-flex align-items-center justify-content-between">
                              <h6 className="mb-0">Referrals</h6>
                              <div>
                                <div className=" detail-text">0</div>
                              </div>
                            </div>
                            <div className="action-details-row d-flex align-items-center justify-content-between">
                              <h6 className="mb-0">Commission Rate</h6>
                              <div>
                                <div className=" detail-text">25%</div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="wager-box">
                              <div className="modal-heading text-center">
                                <h6>Wager</h6>
                              </div>
                              <div className="modal-data">
                                <div className="modal-data-upper">
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6 className="mb-0 ">Originals ($)</h6>
                                    <p className="mb-0">--</p>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6 className="mb-0 ">
                                      Live Casino & 3rd Party Slots ($)
                                    </h6>
                                    <p className="mb-0">--</p>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6 className="mb-0 ">Sports ($)</h6>
                                    <p className="mb-0">--</p>
                                  </div>
                                </div>
                                <div className="modal-data-lower">
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6 className="mb-0 ">Total Wager ($)</h6>
                                    <p className="mb-0">--</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="Commission-box mt-4">
                              <div className="modal-heading text-center">
                                <h6>Commission</h6>
                              </div>
                              <div className="modal-data">
                                <div className="modal-data-upper">
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6 className="mb-0 ">Originals ($)</h6>
                                    <p className="mb-0">--</p>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6 className="mb-0 ">
                                      Live Casino & 3rd Party Slots ($)
                                    </h6>
                                    <p className="mb-0">--</p>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6 className="mb-0 ">Sports ($)</h6>
                                    <p className="mb-0">--</p>
                                  </div>
                                </div>
                                <div className="modal-data-lower">
                                  <div className="d-flex align-items-center justify-content-between mb-2">
                                    <h6 className="mb-0 ">Total Wager ($)</h6>
                                    <p className="mb-0">--</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralCode;
