import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import TurboGames from "./../../assets/images/provider/Turbogames-logo.png";
import PragmaticPlay from "./../../assets/images/provider/Pragmatic-Play-logo.png";
import Netent from "./../../assets/images/provider/netent-logo.png";
import Evolution from "./../../assets/images/provider/evolution-logo.png";
import Quickspin from "./../../assets/images/provider/quickspin-logo.png";
import Spribe from "./../../assets/images/provider/spribe-logo.png";
import Thunderkick from "./../../assets/images/provider/thunderkick-logo.png";
import providers1 from "./../../assets/images/sidebar/game-vector.png";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import { Link, useNavigate } from "react-router-dom";
import { getAPIAuth } from "../../services/apiInstance";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ROUTE_CONST } from "../../constants";

const ProviderSlider = () => {
  const navigate = useNavigate();
  const [providers, setProviders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const sidebarLabels = t("gameProviderLabels", { returnObjects: true });
  const PlaySlotLabels = t("PlaySlotLabels", { returnObjects: true });

  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await getAPIAuth("games/get-all-providers");
      if (res.data.success) {
        setProviders(res.data.data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  const handleFunction = (provider) => {
    navigate(`${ROUTE_CONST.PROVIDERS}/${provider}`);
  };
  return (
    <>
      <div className="row py-3 pt-lg-0 sectionSpacing">
        <div className="col-12 px0 d-flex justify-content-between mb-1 pb-1 mb-lg-3 align-items-center">
          <div className="mainHeading d-flex align-items-center">
            <img src={providers1} alt="" />
            Game {sidebarLabels.Providers}
          </div>
          <div className="d-flex align-items-center">
            <div className="mainSubHeading">
              <Link to={ROUTE_CONST.PROVIDERS} className="headingAnchor">
                {PlaySlotLabels.View_All}
              </Link>
            </div>
            <div className="casinoSliderBtns d-flex d-lg-flex align-items-center">
              <div className="sliderBtn providerPrev100">
                <img src="./assets/img/prevIcon.png" alt="" />
              </div>
              <div className="sliderBtn providerNext100">
                <img src="./assets/img/nextIcon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          {!isLoading ? (
            providers?.length ? (
              <Swiper
                className="mySwiper"
                spaceBetween="10"
                // slidesPerView={'auto'}
                loop={true}
                navigation={{
                  prevEl: ".providerPrev100",
                  nextEl: ".providerNext100",
                }}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Navigation, Autoplay]}
                breakpoints={{
                  0: {
                    slidesPerView: 2,
                  },
                  576: {
                    slidesPerView: 4,
                  },
                  768: {
                    slidesPerView: 5,
                  },
                  992: {
                    slidesPerView: 5,
                  },
                  1200: {
                    slidesPerView: 7,
                  },
                  1400: {
                    slidesPerView: 9,
                  },
                }}
              >
                {providers?.map((item) => (
                  <SwiperSlide key={item._id} className="providerSwiperSlider">
                    <div
                      onClick={() =>
                        navigate(`${ROUTE_CONST.PROVIDERS}/${item.provider}`)
                      }
                      className="ProviderSlider d-flex justify-content-center align-items-center cursor-pointer"
                    >
                      <img src={item.image} alt="TurboGames" />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              ""
            )
          ) : (
            <div className="text-center">
              <Spinner
                className="m-auto"
                animation="border"
                variant="light"
                size="sm"
              />
            </div>
          )}
        </div>

        <div className="col-12 d-lg-none" style={{ marginTop: 10 }}>
          <Swiper
            className="mySwiper"
            spaceBetween="10"
            dir="rtl"
            loop={true}
            
            slidesPerView={"auto"}
            // navigation={{
            // prevEl: '.providerPrev101',
            // nextEl: '.providerNext101',
            // }}
            modules={[Navigation, Autoplay]}
            // breakpoints={{
            // 0: {
            //     slidesPerView: 2,
            // },
            // 576: {
            //     slidesPerView: 4,
            // },
            // 768: {
            //     slidesPerView: 5,
            // },
            // 992: {
            //     slidesPerView: 5,
            // },
            // 1200: {
            //     slidesPerView: 5,
            // },
            // 1400: {
            //     slidesPerView: 5,
            // },
            // }}
          >
            {!isLoading ? (
              providers?.length ? (
                providers?.map((item) => (
                  <SwiperSlide className="providerSwiperSlider">
                    {/* <div className="ProviderSlider d-flex justify-content-center align-items-center">
                            <img src={evolution} alt="" />
                        </div> */}
                    <div
                      className="ProviderSlider d-flex justify-content-center align-items-center cursor-pointer"
                      onClick={() => handleFunction(item?.provider)}
                    >
                      <img src={item.image} alt={`${item?.provider}`} />
                    </div>
                  </SwiperSlide>
                ))
              ) : null
            ) : (
              <div className="text-center">
                <Spinner
                  className="m-auto"
                  animation="border"
                  variant="light"
                  size="sm"
                />
              </div>
            )}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default ProviderSlider;
