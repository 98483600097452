import React, { useEffect } from 'react';
import ProfileTab from './components/ProfileTab';
import profile from '../../assets/images/profile/profile.png';
import wallet from '../../assets/images/profile/wallet.png';
import affiliate from '../../assets/images/profile/affiliate.png';
import deposits from '../../assets/images/profile/deposits.png';
import withdrawals from '../../assets/images/profile/withdrawals.png';
import settings from '../../assets/images/profile/settings.png';
import signout from '../../assets/images/profile/signout.png';
import WalletTab from './components/WalletTab';
import AffiliateTab from './components/AffiliateTab';
import SettingTab from './components/SettingTab';
import { DepositeTab } from './components/DepositeTab';
import WidthdrawalTab from './components/WidthdrawalTab';
import { useAccount } from '../../hooks/useAccount';
import { ACCOUNT_TABS } from '../../constants';
import { useLogoutModal } from '../../hooks/useLogout';
import TransactionTab from './components/TransactionTab';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useWalletModal } from '../../hooks/useWallet';


const AccountPage = () => {
  const {setIsLogoutOpen } = useLogoutModal()
  const { accountTab, setAccountTab} = useAccount()
  const { t } = useTranslation()
  const accountPageLabels = t("accountPageLabels", { returnObjects: true })
  const { walletModalObject, setWalletModalObject } = useWalletModal()
  const [query, setQuery] = useSearchParams()

useEffect(()=>{
        if(!walletModalObject.isWalletOpen && accountTab.selectedTab===ACCOUNT_TABS.PROFILE){
            if(query.get("action") == 'wallet' && !walletModalObject.isWalletOpen){
                setQuery({ action: '' })
                }
    }
},[walletModalObject.isWalletOpen])
    return (
        <>
            <div className="row profileMainSection justify-content-center">
                <div className='col-xl-12 mb-4'>
                    <div className='row'>
                        {/* <div className="col-lg-3 d-lg-block d-none">
                            <div className="sideContent">
                                <div>
                                    <ul className='profileSideBarMenu'>
                                        <li>
                                            <div 
                                                onClick={()=>setAccountTab({
                                                    selectedTab: ACCOUNT_TABS.PROFILE
                                                })} 
                                                className={`menuTab ${accountTab?.selectedTab === ACCOUNT_TABS.PROFILE ? 'active' : ''}`}>
                                                <span className='menuIocn'><img src={profile} alt="" /></span> {accountPageLabels.Profile}
                                            </div>
                                        </li>
                                        <li>
                                            <div onClick={()=>setAccountTab({
                                                    selectedTab: ACCOUNT_TABS.WALLET
                                                })} 
                                                className={`menuTab ${accountTab?.selectedTab === ACCOUNT_TABS.WALLET ? 'active' : ''}`}>
                                                <span className='menuIocn'><img src={wallet} alt="" /></span> {accountPageLabels.WALLET}
                                            </div>
                                        </li>
                                        <li>
                                            <div  onClick={()=>setAccountTab({
                                                    selectedTab: ACCOUNT_TABS.AFFILIATE
                                                })} 
                                                className={`menuTab ${accountTab?.selectedTab === ACCOUNT_TABS.AFFILIATE ? 'active' : ''}`}>
                                                <span className='menuIocn'><img src={affiliate} alt="" /></span> {accountPageLabels.AFFILIATE}
                                            </div>
                                        </li>
                                        <li>
                                            <div  onClick={()=>setAccountTab({
                                                    selectedTab: ACCOUNT_TABS.DEPOSIT
                                                })} 
                                                className={`menuTab ${accountTab?.selectedTab === ACCOUNT_TABS.DEPOSIT ? 'active' : ''}`}>
                                                <span className='menuIocn'><img src={deposits} alt="" /></span>{accountPageLabels.Deposits}
                                            </div>
                                        </li>
                                        <li>
                                            <div  onClick={()=>setAccountTab({
                                                    selectedTab: ACCOUNT_TABS.WITHDRAW
                                                })} 
                                                className={`menuTab ${accountTab?.selectedTab === ACCOUNT_TABS.WITHDRAW ? 'active' : ''}`}>
                                                <span className='menuIocn'><img src={withdrawals} alt="withdrawals" /></span> {accountPageLabels.Withdrawals}
                                            </div>
                                        </li>
                                        <li>
                                            <div  onClick={()=>setAccountTab({
                                                    selectedTab: ACCOUNT_TABS.TRANSACTIONS
                                                })} 
                                                className={`menuTab ${accountTab?.selectedTab === ACCOUNT_TABS.TRANSACTIONS ? 'active' : ''}`}>
                                                <span className='menuIocn'><img src={withdrawals} alt="withdrawals" /></span> {accountPageLabels.TRANSACTION}
                                            </div>
                                        </li>
                                        <li>
                                            <div  onClick={()=>setAccountTab({
                                                    selectedTab: ACCOUNT_TABS.SETTING
                                                })} 
                                                className={`menuTab ${accountTab?.selectedTab === ACCOUNT_TABS.SETTING ? 'active' : ''}`}>
                                                <span className='menuIocn'><img src={settings} alt="withdrawals" /></span> {accountPageLabels.SETTINGS}
                                            </div>
                                        </li>
                                        <li>
                                            <div className='menuTab ' onClick={()=>setIsLogoutOpen(true)}>
                                                <span className='menuIocn'><img src={signout} alt="withdrawals" /></span> {accountPageLabels.Log_Out}
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-12">
                            
                            {/* <div 
                                onClick={()=>setAccountTab({
                                    selectedTab: ACCOUNT_TABS.PROFILE
                                })} 
                                className={`mobileMenuTab d-lg-none ${accountTab?.selectedTab === ACCOUNT_TABS.PROFILE ? 'active' : ''}`}>
                                <span className='menuIocn'><img src={profile} alt="" /></span> Profile
                            </div> */}
                            {accountTab?.selectedTab === ACCOUNT_TABS.PROFILE && <ProfileTab/>}
                            
                            {/* <div onClick={()=>setAccountTab({
                                    selectedTab: ACCOUNT_TABS.WALLET
                                })} 
                                className={`mobileMenuTab d-lg-none ${accountTab?.selectedTab === ACCOUNT_TABS.WALLET ? 'active' : ''}`}>
                                <span className='menuIocn'><img src={wallet} alt="" /></span> Wallet
                            </div> */}
                            {accountTab?.selectedTab === ACCOUNT_TABS.WALLET && <WalletTab/>}
                            
                            {/* <div  onClick={()=>setAccountTab({
                                    selectedTab: ACCOUNT_TABS.AFFILIATE
                                })} 
                                className={`mobileMenuTab d-lg-none ${accountTab?.selectedTab === ACCOUNT_TABS.AFFILIATE ? 'active' : ''}`}>
                                <span className='menuIocn'><img src={affiliate} alt="" /></span> Affiliate
                            </div> */}
                            {accountTab?.selectedTab === ACCOUNT_TABS.AFFILIATE && <AffiliateTab/>}
                            
                            {/* <div  onClick={()=>setAccountTab({
                                    selectedTab: ACCOUNT_TABS.DEPOSIT
                                })} 
                                className={`mobileMenuTab d-lg-none ${accountTab?.selectedTab === ACCOUNT_TABS.DEPOSIT ? 'active' : ''}`}>
                                <span className='menuIocn'><img src={deposits} alt="" /></span> Deposits
                            </div> */}
                            {accountTab?.selectedTab === ACCOUNT_TABS.DEPOSIT && <DepositeTab/>}
                            
                            {/* <div  onClick={()=>setAccountTab({
                                                    selectedTab: ACCOUNT_TABS.WITHDRAW
                                                })} 
                                                className={`mobileMenuTab d-lg-none ${accountTab?.selectedTab === ACCOUNT_TABS.WITHDRAW ? 'active' : ''}`}>
                                                <span className='menuIocn'><img src={withdrawals} alt="withdrawals" /></span> Withdrawals
                                            </div> */}
                            {accountTab?.selectedTab === ACCOUNT_TABS.WITHDRAW && <WidthdrawalTab/>}
                            
                            {/* <div  onClick={()=>setAccountTab({
                                                    selectedTab: ACCOUNT_TABS.TRANSACTIONS
                                                })} 
                                                className={`mobileMenuTab d-lg-none ${accountTab?.selectedTab === ACCOUNT_TABS.TRANSACTIONS ? 'active' : ''}`}>
                                                <span className='menuIocn'><img src={withdrawals} alt="withdrawals" /></span> Transactions
                                            </div> */}
                                            {accountTab?.selectedTab === ACCOUNT_TABS.TRANSACTIONS && <TransactionTab/>}
                                            
                                            {/* <div  onClick={()=>setAccountTab({
                                                    selectedTab: ACCOUNT_TABS.SETTING
                                                })} 
                                                className={`mobileMenuTab d-lg-none ${accountTab?.selectedTab === ACCOUNT_TABS.SETTING ? 'active' : ''}`}>
                                                <span className='menuIocn'><img src={settings} alt="withdrawals" /></span> Settings
                                            </div> */}
                            {
                                accountTab?.selectedTab === ACCOUNT_TABS.SETTING && <SettingTab/> 
                            }
                            
                            {/* <div className='mobileMenuTab d-lg-none ' onClick={()=>setIsLogoutOpen(true)}>
                                                <span className='menuIocn'><img src={signout} alt="withdrawals" /></span> Sign Out
                                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AccountPage