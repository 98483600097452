import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  AUTH_MODAL_TABS,
  BONUS_DROPDOWN_OPTIONS,
  GAME_OPTION_TABS,
  ROUTE_CONST,
  SPIN_MODAL_OPTIONS,
  SPIN_TOKEN,
  WALLET_MODAL_TABS,
} from "../../constants";
import { useAuth } from "../../hooks/useAuth";
import { useWalletModal } from "../../hooks/useWallet";
import { useDownloadModal } from "../../hooks/useDownload";
import { useKado } from "../../hooks/useKado";
import { useSpin } from "../../hooks/useSpin";
import { useBonus } from "../../hooks/useBonus";
import { useAuthModal } from "../../hooks/useAuthModal";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useGames } from "../../hooks/useGame";
import { getAPI, getAPIAuth } from "../../services/apiInstance";
import {
  btUrlAction,
  categoryPillOptions,
  sportsBookAction,
  walletBuyAction,
} from "../../store/action";

import Sportsicon from "./../../assets/images/sidebar/Sportsicon.svg";
import arrow from "./../../assets/images/sidebar/arrow.svg";
import CasinoIcon from "../../assets/images/sidebar/CasinoIcon";
import LotterySvg from "../../assets/images/sidebar/LotterySvg";
import CryptoTradingSvg from "../../assets/images/sidebar/CryptoTradingSvg";
import StakingSvg from "../../assets/images/sidebar/StakingSvg";
import PromotionIconSvg from "../../assets/images/sidebar/PromotionIconSvg";
import VIPClubSvg from "../../assets/images/sidebar/VIPClubSvg";
import BonusIcon from "../../assets/images/sidebar/BonusIcon";
import AffiliateIcon from "../../assets/images/sidebar/AffiliateIcon";
import ForumIcon from "../../assets/images/sidebar/ForumIcon";
import ProvablyFairIcon from "../../assets/images/sidebar/ProvablyFairIcon";
import BlogIcon from "../../assets/images/sidebar/BlogIcon";
import InsigtsIcon from "../../assets/images/sidebar/InsigtsIcon";
import SponsorshipIcon from "../../assets/images/sidebar/SponsorshipIcon";
import LiveSupporIcon from "../../assets/images/sidebar/LiveSupporIcon";
import LanguageIcon from "../../assets/images/sidebar/LanguageIcon";
import { Accordion } from "react-bootstrap";

const Sidebar = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [sportsLink, setSportsLink] = useState("");
  const [sportsLoader, setSportsLoader] = useState(true);
  const { setWalletModalObject } = useWalletModal();
  const { setAuthModalObject } = useAuthModal();
  const userDetails = useSelector((state) => state.userDetails);
  const { setDownloadObject } = useDownloadModal();
  const location = useLocation().pathname;
  const { t } = useTranslation();
  const sidebarLabels = t("appSideApp", { returnObjects: true });
  const dispatch = useDispatch();
  const [isBackDrop, setIsBackDrop] = useState(false);
  const [dropdowns, setDropdowns] = useState({
    casinoGames: false,
    sports: false,
    spin: false,
    bonus: false,
    providers: false,
    promotions: false,
    sponsorship: false,
    language: false,
    download: false,
    token: false,
    staking: false,
    lottery: false,
    poker: false,
    CryptoStaking: false,
  });
  const [query, setQuery] = useSearchParams();
  const [sportsDropdown, setSportsDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ y: 0 });
  const divRef = useRef(null);
  const scrollPositionRef = useRef(null);
  const [casinoCate, setCasinoCate] = useState([]);
  const [cateLoading, setCateLoading] = useState(true);
  const { gameObject, setGameObject } = useGames();
  const [getData, setGetData] = useState([]);
  const { setKadoWalletObject } = useKado();
  const [isSpinner, setIsSpinner] = useState(false);
  const { spinObject, setSpinObject } = useSpin();
  const isSpinSet = localStorage.getItem(SPIN_TOKEN);
  const { bonusObject, setBonusObject } = useBonus();
  const userBonusDetail = useSelector((state) => state.userBonusDetail);

  const getPartnerApi = async () => {
    try {
      const res = await getAPIAuth("get-PartnersData");
      if (res.data.success) {
        setGetData(res.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (token) {
      getPartnerApi();
    }
  }, [token]);

  const handleButtonClick = () => {
    document.body.classList.toggle("sidebarToggle");
  };

  useEffect(() => {
    let valueArr = [];
    Object.entries(dropdowns).map((item) => {
      if (item[1]) {
        valueArr.push(true);
      }
    });
    if (valueArr.includes(true)) {
      const existss = document.body.classList.contains("shortSidebar");
      if (existss) {
        setIsBackDrop(true);
      } else {
        setIsBackDrop(false);
      }
    } else {
      setIsBackDrop(false);
    }
  }, [dropdowns]);

  const openBonusDropdown = () => {
    if (!token) {
      setAuthModalObject((pre) => ({
        // ...pre,
        selectedTab: AUTH_MODAL_TABS.LOG_IN,
        isAuthOpen: true,
      }));
    } else {
      document.body.classList.add("bonusDropdownOpen");
      setBonusObject((pre) => ({
        isBonusOpen: true,
        selectedTab: BONUS_DROPDOWN_OPTIONS.BONUS_MAIN,
      }));
    }
  };

  const closeBonusDropdown = () => {
    document.body.classList.remove("bonusDropdownOpen");
    setBonusObject((prev) => ({
      ...prev,
      isBonusOpen: false,
    }));
  };

  useEffect(() => {
    const time = setTimeout(() => {
      if (token) {
        setSpinObject((prev) => ({
          ...prev,
          isSpinOpen: false,
        }));
        return;
      }
      if (!isSpinSet) {
        setSpinObject({
          isSpinOpen: true,
          selectedModal: SPIN_MODAL_OPTIONS.SPIN_MODAL,
        });
      }
    }, 5000);
    return () => {
      clearTimeout(time);
    };
  }, []);

  useEffect(() => {
    if (token) {
      setSpinObject((prev) => ({
        ...prev,
        isSpinOpen: false,
      }));
      return;
    }
  }, [token]);

  // Function for getting sports
  const getSportsLinks = async () => {
    setSportsLoader(true);
    try {
      const res = await getAPI("crypto/sports-book");
      setSportsLink(res?.data?.getData);
      dispatch(sportsBookAction(res?.data?.getData));
    } catch (error) {
    } finally {
      setSportsLoader(false);
    }
  };

  const getCategory = async () => {
    setCateLoading(true);
    try {
      const res = await getAPI(`get-all-category`);
      if (res?.data?.success) {
        setCasinoCate(res?.data?.data);
        dispatch(categoryPillOptions(res?.data?.data));
      }
    } catch (error) {
    } finally {
      setCateLoading(false);
    }
  };

  useEffect(() => {
    getSportsLinks();
    getCategory();
  }, []);

  const toggleDropdown = (dropdown, event) => {
    // const el  = document.getElementById

    const rect = event.target.getBoundingClientRect();
    // setDropdownPosition({ y: rect.top + window.scrollY });
    // setDropdownPosition({ y: event.clientY });

    setDropdownPosition({
      y: event.target.offsetTop - scrollPositionRef.current,
    });
    // setDropdownPosition({ y: event.target.offsetTop });

    setDropdowns((prevDropdowns) => {
      return {
        ...Object.fromEntries(
          Object.keys(prevDropdowns).map((key) => [key, false])
        ),
        [dropdown]: true,
        // [dropdown]: !prevDropdowns[dropdown],
      };
    });
  };

  const onClickHandler = (dropdown, event) => {
    setDropdownPosition({
      y: event.target.offsetTop - scrollPositionRef.current,
    });
    setDropdowns((prevDropdowns) => {
      return {
        ...Object.fromEntries(
          Object.keys(prevDropdowns).map((key) => [key, false])
        ),
        [dropdown]: !prevDropdowns[dropdown],
      };
    });
  };

  const handleBuyCrypto = async () => {
    const res = await getAPIAuth(
      `getAddress?coinId=6501aa1008727d2352323254&networkId=650a90185cda4589587c52ea`
    );
    if (res.data?.success) {
      dispatch(walletBuyAction(res.data.data.address));
      setWalletModalObject((prev) => ({
        isWalletOpen: true,
        selectedTab: WALLET_MODAL_TABS.BUY_RAMPER,
      }));
    } else {
    }
  };

  const handleSidebar = () => {
    const body = document.body;
    if (body.classList.contains("showSidebar")) {
      body.classList.remove("showSidebar");
    } else {
      body.classList.add("showSidebar");
    }
  };

  useEffect(() => {
    setSportsDropdown(false);
  }, [location]);
  console.log({ location });
  return (
    <>
      <div className="sidebar h-100 ">
        <div className="sidebarInner h-100 d-flex flex-column overflow-y-auto">
          <div className="sidebarUpper flex-fill">
            <div className=" paddingBottom ">
              <div className="side_cls d-lg-none d-block">
                <button
                  onClick={handleSidebar}
                  className="close_sideBar border-0"
                >
                  <svg
                    stroke="currentColor"
                    fill="none"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    height="20px"
                    width="20px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m9 6-6 6 6 6"></path>
                    <path d="M3 12h14"></path>
                    <path d="M21 19V5"></path>
                  </svg>
                </button>
              </div>
              {/* <div className="row mb-2">
                <div className="col smallHiddden">
                  <div className='dblTxt'><img src={dblimg} alt="" /> <span style={{fontSize : 12}}>Coming soon</span></div>
                  <div className='dblTxtsub'>{sidebarLabels.Damble_Token} <span>{sidebarLabels.hot} <img src={hot} alt="" /></span></div>
                </div>
                <div className="col-auto">
                  <div className="closeside d-none d-lg-flex" onClick={handleButtonClick}><img src={closesidebar} alt="" /></div>
                  <div className="closeside d-lg-none" onClick={() => {
                    document.body.classList.remove('showSidebar')
                  }}><img src={closesidebar} alt="" /></div>
                </div>
              </div> */}

              {/* <div className="row gx-2 mb-2 smallHiddden">
                <div className="col-6">
                  <button className='headerBtns'>{sidebardgnLabels.BUY_PRESALE}</button>
                </div>
                <div className="col-6" onClick={() => navigate(ROUTE_CONST.COMING_SOON)}>
                  <button className='headerBtns'>{sidebarVIPClubLabels.DASHBOARD}</button>
                </div>
              </div> */}

              <Accordion className="col-12 mb-2 px-0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div
                      className={` ${
                        location === ROUTE_CONST.CASINO && !sportsDropdown
                          ? "active"
                          : ""
                      }  sidebarLink   w-100`}
                      onClick={() => {
                        setSportsDropdown(false);
                        setGameObject((prev) => ({
                          selectedTab: GAME_OPTION_TABS?.LOBBY,
                        }));
                        setQuery({ q: "lobby" });
                        navigate(
                          `${ROUTE_CONST.CASINO}?q=${encodeURIComponent(
                            "lobby"
                          )}`
                        );
                      }}
                    >
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <div className="sidebarImg small">
                            {/* <img src={"casinoIcon"} alt="" /> */}
                            <CasinoIcon />
                          </div>
                        </div>
                        <div className="col px-0 smallHiddden">
                          <div className="accrodianBtnTxt">
                            {/* {sidebarLabels.CasinoGames} */}
                            {sidebarLabels.Casino}
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className="sidebarImg small">
                            <img className="rotate-90" src={arrow} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="mt-2">
                    <div className="row mx-0 gap-2  accorduinBodyBg">
                      {/* <div
                        className={`col-12 sidebarLink sidebarLinkbody rounded-0 ${
                          gameObject?.selectedTab === GAME_OPTION_TABS?.LOBBY
                            ? "active"
                            : ""
                        }`}
                        onClick={() => {
                          setGameObject((prev) => ({
                            selectedTab: GAME_OPTION_TABS?.LOBBY,
                          }));
                          setQuery({ q: "lobby" });
                          navigate(
                            `${ROUTE_CONST.CASINO}?q=${encodeURIComponent(
                              "lobby"
                            )}`
                          );
                        }}
                      >
                        <div className="row align-items-center mb-0">
                          <div className="col-auto">
                            <div className="sidebarImg small">
                              <img src={"allGames"} alt="" />
                            </div>
                          </div>
                          <div className="col px-0 smallHiddden">
                            <div className="accrodianBtnTxt accrodianBtnTxtBody">
                              {sidebarLabels.AllGames}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-12 sidebarLink sidebarLinkbody rounded-0 ${
                          gameObject?.selectedTab === GAME_OPTION_TABS?.RECENT
                            ? "active"
                            : ""
                        }`}
                        onClick={() => {
                          if (token) {
                            setGameObject((prev) => ({
                              selectedTab: GAME_OPTION_TABS?.RECENT,
                            }));

                            setQuery({ q: "recent" });
                            navigate(
                              `${ROUTE_CONST.CASINO}?q=${encodeURIComponent(
                                "recent"
                              )}`
                            );
                          } else {
                            setAuthModalObject((pre) => ({
                              isAuthOpen: true,
                              selectedTab: AUTH_MODAL_TABS.LOG_IN,
                            }));
                          }
                        }}
                      >
                        <div className="row align-items-center mb-0">
                          <div className="col-auto">
                            <div className="sidebarImg small">
                              <img src={""} alt="" />
                            </div>
                          </div>
                          <div className="col px-0 smallHiddden">
                            <div className="accrodianBtnTxt accrodianBtnTxtBody">
                              {sidebarLabels.Recent}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-12 sidebarLink sidebarLinkbody rounded-0 ${
                          gameObject?.selectedTab ===
                          GAME_OPTION_TABS?.FAVOURITE
                            ? "active"
                            : ""
                        }`}
                        onClick={() => {
                          if (token) {
                            setGameObject((prev) => ({
                              selectedTab: GAME_OPTION_TABS?.FAVOURITE,
                            }));

                            setQuery({ q: "favourite" });
                            navigate(
                              `${ROUTE_CONST.CASINO}?q=${encodeURIComponent(
                                "favourite"
                              )}`
                            );
                          } else {
                            setAuthModalObject((pre) => ({
                              isAuthOpen: true,
                              selectedTab: AUTH_MODAL_TABS.LOG_IN,
                            }));
                          }
                        }}
                      >
                        <div className="row align-items-center mb-0">
                          <div className="col-auto">
                            <div className="sidebarImg small">
                              <img src={""} alt="" />
                            </div>
                          </div>
                          <div className="col px-0 smallHiddden">
                            <div className="accrodianBtnTxt accrodianBtnTxtBody">
                              {sidebarLabels.Favourite}
                            </div>
                          </div>
                        </div>
                      </div> */}
                      {casinoCate?.length
                        ? casinoCate?.map((item) => (
                            <div
                              className={`col-12 sidebarLink sidebarLinkbody rounded-0 ${
                                gameObject?.selectedId === item?._id
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                setGameObject((prev) => ({
                                  selectedTab: item?.category_name,
                                  selectedId: item?._id,
                                }));
                                // navigate(ROUTE_CONST.CASINO)
                                setQuery({
                                  q: item?.category_name?.split(" ").join(""),
                                });
                                navigate(
                                  `${ROUTE_CONST.CASINO}?q=${encodeURIComponent(
                                    item?.category_name
                                  )}&catid=${encodeURIComponent(item?._id)}`
                                );
                              }}
                            >
                              <div className="row align-items-center mb-0">
                                <div className="col-auto">
                                  <div className="sidebarImg small">
                                    <img src={item?.image} alt="" />
                                  </div>
                                </div>
                                <div className="col px-0 smallHiddden">
                                  <div className="accrodianBtnTxt accrodianBtnTxtBody">
                                    {item?.category_name}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        : ""}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <Accordion className="col-12  mb-2 px-0">
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    <div
                      onClick={() => setSportsDropdown((prv) => !prv)}
                      className={`col-12 sidebarLink ${
                        sportsDropdown ? "active" : ""
                      }`}
                      // onClick={()=>navigate(ROUTE_CONST.SPORTS_PAGE)}
                    >
                      <div className="row align-items-center">
                        <div className="col-auto">
                          <div className="sidebarImg small">
                            <img src={Sportsicon} alt="" />
                          </div>
                        </div>
                        <div className="col px-0 smallHiddden">
                          <div className="accrodianBtnTxt">
                            {sidebarLabels.Sports}
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className="sidebarImg small">
                            <img className="rotate-90" src={arrow} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body className="mt-2">
                    <div className="row mx-0  gap-2 accorduinBodyBg">
                      {sportsLink?.length
                        ? sportsLink?.map((item) => (
                            <div
                              className={`col-12 sidebarLink sidebarLinkbody rounded-0 ${
                                location.pathname === item?.url ? "active" : ""
                              }`}
                              key={item?._id}
                              onClick={() => {
                                dispatch(btUrlAction(item?.category));
                                navigate(item?.url);
                              }}
                            >
                              <div className="row align-items-center">
                                <div className="col-auto">
                                  <div className="sidebarImg small">
                                    <img
                                      src={item?.image}
                                      alt=""
                                      style={{ width: 33 }}
                                      // className="w-100"
                                    />
                                  </div>
                                </div>
                                <div className="col px-0 smallHiddden">
                                  <div className="accrodianBtnTxt">
                                    {item?.name}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        : ""}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              <div className="row mx-0 gap-2">
                {/* <div
                  className={` ${
                    location === ROUTE_CONST.CASINO ? "active" : ""
                  }  sidebarLink rounded-pill  w-100`}
                  onClick={() => {
                    setGameObject((prev) => ({
                      selectedTab: GAME_OPTION_TABS?.LOBBY,
                    }));
                    setQuery({ q: "lobby" });
                    navigate(
                      `${ROUTE_CONST.CASINO}?q=${encodeURIComponent("lobby")}`
                    );
                  }}
                >
                  <div className="   row align-items-center">
                    <div className="col-auto">
                      <div className="sidebarImg small">
                        <CasinoIcon />
                      </div>
                    </div>
                    <div className="col px-0 smallHiddden">
                      <div className="accrodianBtnTxt">
                        {sidebarLabels.Casino}
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div
                  className={`col-12 sidebarLink rounded-pill ${
                    location === ROUTE_CONST.SPORTS_PAGE ? "active" : ""
                  }`}
                  onClick={() => navigate(ROUTE_CONST.SPORTS_PAGE)}
                >
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div className="sidebarImg small">
                        <LotterySvg />
                      </div>
                    </div>
                    <div className="col px-0 smallHiddden">
                      <div className="accrodianBtnTxt">
                        {sidebarLabels.Lottery}
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div
                  className={`col-12 sidebarLink rounded-pill ${
                    location === ROUTE_CONST.SPORTS_PAGE ? "active" : ""
                  }`}
                  onClick={() => navigate(ROUTE_CONST.SPORTS_PAGE)}
                >
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div className="sidebarImg small">
                        <CryptoTradingSvg />
                      </div>
                    </div>
                    <div className="col px-0 smallHiddden">
                      <div className="accrodianBtnTxt">
                        {sidebarLabels.crypto_trading}
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div
                  className={`col-12 sidebarLink rounded-pill ${
                    location === ROUTE_CONST.SPORTS_PAGE ? "active" : ""
                  }`}
                  onClick={() => navigate(ROUTE_CONST.SPORTS_PAGE)}
                >
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div className="sidebarImg small">
                        <StakingSvg />
                      </div>
                    </div>
                    <div className="col px-0 smallHiddden">
                      <div className="accrodianBtnTxt">
                        {sidebarLabels.Staking}
                      </div>
                    </div>
                  </div>
                </div> */}

                <div
                  className={`col-12 sidebarLink rounded-pill   ${
                    location === ROUTE_CONST.PROMOTION ? "active" : ""
                  } `}
                  onClick={() => navigate(ROUTE_CONST?.PROMOTION)}
                >
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div className="sidebarImg small">
                        <PromotionIconSvg />
                      </div>
                    </div>
                    <div className="col px-0 smallHiddden">
                      <div className="accrodianBtnTxt">
                        {sidebarLabels.PROMOTIONS}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="">
                  <div class="sidebar-divider"></div>
                </div>

                <div
                  className={`col-12 sidebarLink rounded-pill
                    ${location === ROUTE_CONST.VIP_PAGE ? "active" : ""}
                    `}
                  onClick={() => navigate(ROUTE_CONST?.VIP_PAGE)}
                >
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div className="sidebarImg small">
                        <VIPClubSvg />
                      </div>
                    </div>
                    <div className="col px-0 smallHiddden">
                      <div className="accrodianBtnTxt">
                        {sidebarLabels.VIPClubLabels}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`col-12 sidebarLink rounded-pill
                    ${location === ROUTE_CONST.BONUS ? "active" : ""}
                    `}
                  onClick={() => navigate(ROUTE_CONST?.BONUS)}
                >
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div className="sidebarImg small">
                        <BonusIcon />
                      </div>
                    </div>
                    <div className="col px-0 smallHiddden">
                      <div className="accrodianBtnTxt">
                        {sidebarLabels.BONUS}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`col-12 sidebarLink rounded-pill 
                    
                     ${location === ROUTE_CONST.AFFILIATE_PAGE ? "active" : ""}
                    
                    `}
                  onClick={() => navigate(ROUTE_CONST?.AFFILIATE_PAGE)}
                >
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div className="sidebarImg small">
                        <AffiliateIcon />
                      </div>
                    </div>
                    <div className="col px-0 smallHiddden">
                      <div className="accrodianBtnTxt">
                        {sidebarLabels.Affiliate}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  className={`col-12 sidebarLink rounded-pill `}
                  onClick={() => navigate(ROUTE_CONST?.PROMOTION)}
                >
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div className="sidebarImg small">
                        <ForumIcon />
                      </div>
                    </div>
                    <div className="col px-0 smallHiddden">
                      <div className="accrodianBtnTxt">
                        {sidebarLabels.Forum}
                      </div>
                    </div>
                  </div>
                </div> */}
                <div
                  className={`col-12 sidebarLink rounded-pill `}
                  onClick={() => navigate(ROUTE_CONST?.COMING_SOON)}
                >
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div className="sidebarImg small">
                        <ProvablyFairIcon />
                      </div>
                    </div>
                    <div className="col px-0 smallHiddden">
                      <div className="accrodianBtnTxt">
                        {sidebarLabels.Provably_Fair}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  className={`col-12 sidebarLink rounded-pill `}
                  onClick={() => navigate(ROUTE_CONST?.PROMOTION)}
                >
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div className="sidebarImg small">
                        <BlogIcon />
                      </div>
                    </div>
                    <div className="col px-0 smallHiddden">
                      <div className="accrodianBtnTxt">
                        {sidebarLabels.Blog}
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div
                  className={`col-12 sidebarLink rounded-pill `}
                  onClick={() => navigate(ROUTE_CONST?.PROMOTION)}
                >
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div className="sidebarImg small">
                        <InsigtsIcon />
                      </div>
                    </div>
                    <div className="col px-0 smallHiddden">
                      <div className="accrodianBtnTxt">
                        {sidebarLabels.Sports_Betting_Insights}
                      </div>
                    </div>
                  </div>
                </div> */}

                <div class="">
                  <div class="sidebar-divider"></div>
                </div>

                <div
                  className={`col-12 sidebarLink rounded-pill `}
                  onClick={() => navigate(ROUTE_CONST?.COMING_SOON)}
                >
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div className="sidebarImg small">
                        <SponsorshipIcon />
                      </div>
                    </div>
                    <div className="col px-0 smallHiddden">
                      <div className="accrodianBtnTxt">
                        {sidebarLabels.Sponsorship}
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="sidebarImg small">
                        <img src={arrow} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`col-12 sidebarLink rounded-pill `}
                  onClick={() => navigate(ROUTE_CONST?.PROMOTION)}
                >
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div className="sidebarImg small">
                        <LiveSupporIcon />
                      </div>
                    </div>
                    <div className="col px-0 smallHiddden">
                      <div className="accrodianBtnTxt">
                        {sidebarLabels.Live_support}
                      </div>
                    </div>
                  </div>
                </div>

                

                {/* <Accordion className="col-12 px-0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <div className="sidebarLink rounded-pill w-100">
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <div className="sidebarImg small">
                              <img src={casinoIcon} alt="" />
                            </div>
                          </div>
                          <div className="col px-0 smallHiddden">
                            <div className="accrodianBtnTxt">
                              {sidebarLabels.earn}{" "}
                              <span className="pokerSpan">UP TO 130%</span>
                            </div>
                          </div>
                          <div className="col-auto smallHiddden">
                            <div className="rightArrow small">
                              <img src={rightArrow} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row mx-0 mb-1 accorduinBodyBg gap-1">
                        {earnGames?.length
                          ? earnGames?.map((item) => (
                              <div
                                className={`col-12 sidebarLink rounded-pill sidebarLink rounded-pillbody rounded-0 ${
                                  location === item?.link ? "active" : ""
                                }`}
                                onClick={() => navigate(item.link)}
                              >
                                <div className="row align-items-center">
                                  <div className="col-auto">
                                    <div className="sidebarImg small">
                                      <img src={item?.img} alt="" />
                                    </div>
                                  </div>
                                  <div className="col px-0 smallHiddden">
                                    <div className="accrodianBtnTxt">
                                      {item?.name}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          : ""}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion className="col-12 px-0">
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <div
                        onClick={() => navigate(ROUTE_CONST.SPONSERSHIP)}
                        className={`sidebarLink rounded-pill w-100 ${
                          location.pathname === ROUTE_CONST.SPONSERSHIP
                            ? "active"
                            : ""
                        }`}
                      >
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <div className="sidebarImg small">
                              <img src={sportsIcon} alt="" />
                            </div>
                          </div>
                          <div className="col px-0 smallHiddden">
                            <div className="accrodianBtnTxt">
                              {sidebarLabels.Sponsorship}
                            </div>
                          </div>
                          <div className="col-auto smallHiddden">
                            <div className="rightArrow small">
                              <img src={rightArrow} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="row mx-0 accorduinBodyBg gap-1">
                        {getData?.length
                          ? getData?.map((item) => (
                              <div
                                key={item?._id}
                                className={`col-12 sidebarLink rounded-pill sidebarLink rounded-pillbody rounded-0 ${
                                  location ===
                                  ROUTE_CONST.SPONSERSHIP + `/` + item?._id
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => {
                                  navigate(
                                    `${ROUTE_CONST.SPONSERSHIP}/${item?._id}`
                                  );
                                }}
                              >
                                <div className="row align-items-center">
                                  <div className="col-auto">
                                    <div className="sidebarImg small">
                                      <img src={item?.image} alt="" />
                                    </div>
                                  </div>
                                  <div className="col px-0">
                                    <div className="accrodianBtnTxt">
                                      {item?.title}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          : ""}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion> */}
              </div>
            </div>
          </div>
          {/* <div className="sidebarBottom smallHiddden">
            <div className="row gx-0">
              <div className="col-12">
                <button  onClick={handleBuyCrypto} className="getCryptoBtn border-0 shadow-none d-flex align-items-center justify-content-center w-100 text-uppercase">{sidebarLabels.GETCRYPTO}</button>
              </div>
              <div className="col-12 paymentMethod">
                <div className="row mx-0 g-2 justify-content-center flex-wrap w-100">
                  <div className="col-auto flex-shrink-1">
                    <img src={applePay} alt="" />
                  </div>
                  <div className="col-auto flex-shrink-1">
                    <img src={gpay} alt="" />
                  </div>
                  <div className="col-auto flex-shrink-1">
                    <img src={master} alt="" />
                  </div>
                  <div className="col-auto flex-shrink-1">
                    <img src={visa} alt="" />
                  </div>
                  <div className="col-auto flex-shrink-1 mt-2">
                    <img src={samsungpay} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
