import React, { useEffect, useState } from 'react'
import menu from './../../../assets/images/menu.png'
import enable from './../../../assets/images/ENABLE2FA.png'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAPIAuth } from '../../../services/apiInstance';
import { ACCOUNT_TABS } from '../../../constants';
import { useAccount } from '../../../hooks/useAccount';
import moment from 'moment';
import ConfirmPassword from '../../../components/ModalSection/ConfirmPassword';


const SettingTab = () => {
  const { accountTab} = useAccount()
  const userDetails = useSelector((state) => state.userDetails);
  const [userEmail, setUserEmail] = useState(userDetails.email)
  const [userPassword, setUserPassword] = useState('')
  const [userId, setUserId] = useState('')
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [actionType, setActionType] = useState('')
  const [error, setError] = useState({
    email: '',
    password: ''
  })
  const [isLoading, setIsLoading] = useState(true)
  const [loginData, setLoginData] = useState([])
  const { t } = useTranslation()
  const accountPageLabels = t("accountPageLabels", { returnObjects: true })
  const commonText = t("commonText", { returnObjects: true })
  const [setIsEmailLogin] = useState(false)

  useEffect(() => {
    setUserEmail(userDetails?.email)
    setUserId(userDetails?.id)
  }, [userDetails])


  const handleEmail = async () => {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userEmail)) {
      setError((prev) => ({
        ...prev,
        email: '',
      }));
      setActionType('changeEmail')
      setShowConfirmPassword(true)

    } else {
      setError((prev) => ({
        ...prev,
        email: 'Enter valid email',
      }));
    }
  }

  const handlePassword = async () => {
    if (userPassword.length >= 8) {
      setError((prev) => ({
        ...prev,
        password: '',
      }));

      
      setActionType('changePassword')
      setShowConfirmPassword(true)

    } else {
      setError((prev) => ({
        ...prev,
        password: 'Password must have at least 8 characters',
      }));
    }
  }


  const getLoginHistory = async () => {
    if (!userId) return
    try {
      const res = await getAPIAuth(`user/get-login-history?userId=${userId}`)
      if (res.data.success) {
        setLoginData(res.data.data)
        setIsEmailLogin(res?.data?.data?.[0]?.isLoginWithEmail)
      } else {
        //error
      }
    } catch (error) {
      // err
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (accountTab?.selectedTab === ACCOUNT_TABS.SETTING) {
      getLoginHistory()
    }
  }, [userId, accountTab?.selectedTab])


    return (
        <>
        <div className="setting space">
           <div className='d-flex align-items-center'>
            <div className='tabsIconHeading'>
                <img src={menu} alt="" className='h-100 w-100' />
            </div>
            <div className='set-text ms-3 tabsHeading'>
            {accountPageLabels.Settings}
            </div>
           </div>
           <div className='form'>
           <label htmlFor="newUser" className='inputLabel mt-4'>{accountPageLabels.CHANGE_EMAIL}</label>
                            <div className='emailGroup'> 
                                    <input className='inputUser common' 
                                      type="text" 
                                      placeholder='' 
                                      value={userEmail}
                                      onChange={(e) => { setUserEmail(e.target.value) }}/>
                                    <label htmlFor="newUser" className='placeHolder'>NEW EMAIL <span className='star'>*</span> </label>
                                    <button onClick={handleEmail} className='commenBtn1'>{accountPageLabels.CHANGE_EMAIL}</button>
                            </div>
                            {error?.email ? <div className="text-danger errorTxt">{error?.email}</div> : ''}
                            <label htmlFor="newUser-2" className='inputLabel mt-4'>{accountPageLabels.CREATE_PASSWORD}</label>
                            <div className='emailGroup'> 
                                    <input className='inputUser common' type="text" placeholder=''
                                      value={userPassword}
                                      onChange={(e) => setUserPassword(e.target.value)} />
                                    <label htmlFor="newUser-2" className='placeHolder'>NEW PASSWORD <span className='star'>*</span> </label>
                                    <button onClick={handlePassword} className='commenBtn1'>{accountPageLabels.CHANGE_PASSWORD}</button>
                            </div>
                            {error?.password ? <div className="text-danger errorTxt">{error?.password}</div> : ''}
           </div>
           {/* <div className='d-flex auth gap-5 mt-4'>
             <div className='auth_title'>TWO-FACTOR AUTHENTICATION</div>
             <div className='dis_text'>DISABLED</div>
           </div>
           <div className='auth_text'>
           Using two-factor authentication is highly recommended because it protects your account with both your password and your phone.
           </div>
           <div className='auth_text'>
           An email and password must be set to activate 2FA. While 2FA is enabled, you will not be able to login via Steam.
           </div>
           <button className=' imgbtn'>
            <div className=' '>
            <img src={enable}alt="" className='h-100 w-100 object-cover' />

            </div>
           </button>
           <div className='auth_title my-4'>TWO-FACTOR VERIFY</div>
           <button className='commenBtn1'>VERIFY</button> */}
           <div className='auth_title my-4'>{accountPageLabels.LOGIN_HISTORY}</div>
           <div className="col-12 px-0 betTable">
          <div className="table-responsive">
            <table className='table mb-0'>
              <thead>
                <tr>
                  <th>{accountPageLabels.DATE}</th>
                  <th className=''>{accountPageLabels.LOGIN_METHOD}</th>
                  <th className=''>{accountPageLabels.COUNTRY}</th>
                  <th className=''>{accountPageLabels.IP}</th>
                </tr>
              </thead>
              {
                isLoading ? (
                  <span className="w-100 h-100 d-flex justify-content-center align-items-center">
                    {/* <Spinner animation="border" variant="light" size="sm" /> */}
                  </span>
                ) : (
              <tbody>
                
                {
                      loginData?.length === 0 ? (
                        <tr className="text-nowrap">
                          <td colSpan={4} className="border-0 text-center px-4">
                            <div className="text-center">{commonText.NoDataAvailable}</div>
                          </td>
                        </tr>
                      ) : (
                        loginData?.map(
                          (item) => (
                          <tr key={item._id}>
                            <td className=' text-white'>{moment(item.createdAt).format("MMM DD YYYY , HH:mm")}</td>
                            <td className=' text-white'>{item.login_method === "Registeration" ? "Registration" : item.login_method}</td>
                            <td className=' text-white'>{item.country}</td>
                            <td className=' text-white'>{item.ip_address}</td>
                          </tr>
                          )
                        )
                      )
                    }
              </tbody>
            )
          }
            </table>
          </div>
        </div>


        </div>
        <ConfirmPassword 
          showConfirmPassword={showConfirmPassword} 
          setShowConfirmPassword={setShowConfirmPassword} 
          userEmail={userEmail}
          userPassword={userPassword}
          setUserPassword={setUserPassword}
          actionType={actionType}/>
        </>
    )
}

export default SettingTab