import React, { useEffect, useState } from 'react'
import affiliate from '../../../assets/images/profile/affiliate.png';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getAPIAuth, postAPIAuth } from '../../../services/apiInstance';
import { errorToaster, succesToaster } from '../../../utils/toaster';

const AffiliateTab = () => {
    const [activeTab, setActiveTab] = useState('referrals')
    const [refercode, setReferCode] = useState("");
    const [alldata, setAlldata] = useState([]);
    const [referal, setReferalcode] = useState([]);
    const [tabopen, setTabopen] = useState("");
    const [copied, setNCopied] = useState({ id: "", type: "", status: false });
    const userDetails = useSelector((state) => state.userDetails);
    const { t } = useTranslation()
    const accountPageLabels = t("accountPageLabels", { returnObjects: true })
    const navigate = useNavigate()
  
  
    const ReferalCodes = async () => {
      try {
        const res = await getAPIAuth("get-affiliate-details");
        setReferalcode(res.data?.data);
      } catch (error) { }
    };
  
    const handleAllData = async () => {
      try {
        
        const res = await getAPIAuth("get-affiliate-friend-details");
        setAlldata(res.data?.data);
      } catch (error) {
        
      }
    };
  
    useEffect(() => {
      ReferalCodes();
      handleAllData();
      setTabopen("referrals");
    }, []);
  
    const GenerateCodeFun = async () => {
      const body = {
        refer_code: refercode,
      };
      if (refercode.length < 6 && refercode.length < 15) {
        errorToaster("charcters must be between 6 to 15 digits");
        return;
      }
      const res = await postAPIAuth("create-refer-code", body);
      if (res.status) {
        setReferCode("");
        succesToaster(res.data.message);
        ReferalCodes();
      }
    };
    
    const handleCopy = (val, isActive, index) => {
      navigator.clipboard
        .writeText(val)
        .then(() => {
          setNCopied((prev) => {
            const updatevalue = { ...prev };
            updatevalue["id"] = index;
            updatevalue["type"] = isActive;
            updatevalue["status"] = true;
            return updatevalue;
          });
  
          setTimeout(
            () =>
              setNCopied((prev) => {
                const updatevalue = { ...prev };
                updatevalue["id"] = index;
                updatevalue["type"] = isActive;
                updatevalue["status"] = false;
                return updatevalue;
              }),
            2000
          );
        })
        .catch((err) => console.error("Failed to copy reference code:", err));
    };
    return (
        <>
        <div className='AffiliateSection'>
            <div className='row'>
                <div className="col-12">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-auto">
                                    <div className="add-icon">
                                        <img src={affiliate} alt="" className='h-100 w-100' />
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="add-txt">
                                    {accountPageLabels.AFFILIATE}
                                    </div>
                                </div>
                            </div>
                </div>
                <div className='col-12'>
                    <div>
                        <ul className='d-flex align-items-center gap-3 list-unstyled mt-lg-5 mt-md-4 mt-3 overflow-x-auto text-flex-nowrap'>
                            <li>
                                <div onClick={()=>setActiveTab('referrals')} className={`affiliateBtn ${activeTab === 'referrals' ? 'active' : ''}`}>{accountPageLabels.REFERRALS}</div>
                            </li>
                            <li>
                                <div onClick={()=>setActiveTab('referedUsers')} className={`affiliateBtn ${activeTab === 'referedUsers' ? 'active' : ''}`}>{accountPageLabels.REFERRED_USERS}</div>
                            </li>
                        </ul>
                    </div>
                </div>
                {
                    activeTab === 'referrals' ? <>
                                        <div className='col-12'>
                    <label htmlFor="newUser " className='inputLabel mt-lg-5 mt-md-4 mt-3 pb-1'>{accountPageLabels.SET_CUSTOM} </label>
                    <div className='inputGroup d-flex align-items-center gap-3'> 
                        <input className='inputUser common' 
                            type="text" 
                            placeholder='Set referral code...' 
                            value={refercode}
                            onChange={(e) => setReferCode(e.target.value)}/>
                        <button onClick={() => GenerateCodeFun()} className='changeBtn'>{accountPageLabels.SET_CODE}</button>
                    </div>
                </div>
                <div className='col-12 mt-3'>
                    <div className='row g-2'>
                        <div className='col-sm-4'>
                            <div className='comissionCard'>
                                <div className='comissionHeading'>${referal[0]?.total_commission_reward ? Number(referal[0]?.total_commission_reward).toFixed(2) : '0.00'}</div>
                                <div className='comissionSubheading'>{accountPageLabels.COMMISSION_EARNED}</div>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='comissionCard'>
                                <div className='comissionHeading'>
                                ${referal[0]?.totalClaimed ? Number(referal[0]?.totalClaimed).toFixed(2) : '0.00'}
                                </div>
                                <div className='comissionSubheading'>{accountPageLabels.TOTAL_CLAIMED}</div>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div className='comissionCard'>
                                <div className='comissionHeading'>
                                ${userDetails?.totalbalance ? Number(userDetails?.totalbalance).toFixed(2) : '0.00'}
                                </div>
                                <div className='comissionSubheading'>{accountPageLabels.TOTAL_AVAILABLE}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 betTableNew mt-md-5 mt-4">
                    <div className="table-responsive mt-3">
                        <table className='table mb-0'>
                            <thead className='bg-sucess'>
                                <tr>
                                    <th>{accountPageLabels.CODE}</th>
                                    <th>{accountPageLabels.CLAIMED}</th>
                                    <th>{accountPageLabels.REFS}</th>
                                    <th>%</th>
                                    <th>{accountPageLabels.WAGERED}</th>
                                    <th>{accountPageLabels.CLAIM}</th>
                                    <th>{accountPageLabels.LINK}</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                Array.isArray(referal) &&
                                referal?.map((item, i) => (
                                <tr key={`${Math.random()}--${Math.random()}`}>
                                    <td>{item?.refer_code ? item?.refer_code : ""}</td>
                                    <td>${item?.claimed ? Number(item?.claimed).toFixed(2) : '0.00'}</td>
                                    <td>{item?.refs ? item.refs : '0'}</td>
                                    <td>10%</td>
                                    <td>
                                        ${item?.wagred ? Number(item.wagred).toFixed(2) : "0.00"}
                                    </td>
                                    <td>
                                        <div className='clamSection'>
                                        {accountPageLabels.CLAIM} ${item?.claim ? Number(item?.claim).toFixed(2) : "0.00"}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="a" 
                                            onClick={() =>
                                                handleCopy(item?.refer_link, "Link", i)
                                            }>
                                                {copied.id == i &&
                                                    copied.type == "Link" &&
                                                    copied.status == true ? (
                                                    <span style={{ color: "green" }}>
                                                    {/* {accountPageLabels.copied}! */}
                                                    {accountPageLabels.copied}!
                                                    </span>
                                                ) : (
                                                    <span> 
                                                        {accountPageLabels.copy} 
                                                        
                                                    </span>
                                                )
                                                }
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                    </> :
                    activeTab === 'referedUsers' ? <>
                                    <div className="col-12 betTableNew mt-md-5 mt-4">
            <div className="table-responsive mt-md-3">
                <table className='table mb-0'>
                    <thead className='bg-sucess'>
                        <tr>
                        <th>{accountPageLabels.USERNAME}</th>
                        <th>{accountPageLabels.JOINED}</th>
                        <th className='text-center'>{accountPageLabels.WAGERED}</th>
                        <th className='text-center'>{accountPageLabels.COMMISSION_EARNED}</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                    {Array.isArray(alldata) &&
                        alldata?.map((item) => (
                            <tr key={`${Math.random()}-${Math.random()}`}>
                                <td>{item?.fullname}</td>
                                <td>{item?.createdAt?.slice(0, 10)}</td>
                                <td className='text-center'>{item?.total_wager ? Number(item.total_wager).toFixed(2) : '0.00'}</td>
                                <td className='text-center'>${item?.total_commission_rewards ? Number(item.total_commission_rewards).toFixed(2) : '0.00'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            </div>
                    </> : ''
                }
                <div className='col-12 mt-2'>
                    <div className='affilatepera mt-md-5 mt-3'>
                           {accountPageLabels.If_you_are} <span>{accountPageLabels.Affiliate_Program}</span> 
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}

export default AffiliateTab
