import React, { useState } from 'react'
import logo from './../../../assets/images/logo.jpeg'

const TokenTable = () => {
  const [activeTableIndex, setActiveTableIndex] = useState(0)


  const handleNext = ()=> {
    if(activeTableIndex === 3) {
      setActiveTableIndex(0)
    } else {
      setActiveTableIndex(activeTableIndex + 1)
    }
  }

  const handlePrev = ()=> {
    if(activeTableIndex === 0) {
      setActiveTableIndex(3)
    } else {
      setActiveTableIndex(activeTableIndex - 1)
    }
  }

  return (
    <>
    <div className="row g-3">
      <div className="col-lg-7">
        <div className="tokenTableWrapper table-responsive">
          <table className="table tokenTable mb-0">
            <thead>
              <tr>
                <th>Token/Est.APR</th>
                <th>
                  <span className="upperDiv"></span>
                  {/* <img className='tokenTableHeadImg' src={logo} alt="" style={{height : 18}} /> */}
                  {/* <img className='tokenTableHeadImg' src="./assets/img/stacking/dambleTableLogo.png" alt="" /> */}
                </th>
                <th className={`mobHiddenTd ${activeTableIndex === 0 ? 'active' : ''}`}>
                  <div className="navBtns">
                    <img onClick={handlePrev} src="./assets/img/stacking/tokenPrev.png" alt="" />
                    <img onClick={handleNext} src="./assets/img/stacking/tokenNext.png" alt="" />
                  </div>
                  <img className='tokenTableHeadImg' src="./assets/img/stacking/cex.png" alt="" />
                </th>
                <th className={`mobScrollTh mobHiddenTd ${activeTableIndex === 1 ? 'active' : ''}`}>
                  <div className="navBtns">
                    <img onClick={handlePrev} src="./assets/img/stacking/tokenPrev.png" alt="" />
                    <img onClick={handleNext} src="./assets/img/stacking/tokenNext.png" alt="" />
                  </div>
                  <img className='tokenTableHeadImg small' src="./assets/img/stacking/cexGreen.png" alt="" />
                </th>
                <th className={`mobHiddenTd ${activeTableIndex === 2 ? 'active' : ''}`}>
                  
                  <div className="navBtns">
                    <img onClick={handlePrev} src="./assets/img/stacking/tokenPrev.png" alt="" />
                    <img onClick={handleNext} src="./assets/img/stacking/tokenNext.png" alt="" />
                  </div>
                  <img className='tokenTableHeadImg' src="./assets/img/stacking/cexBlue.png" alt="" />
                </th>
                <th className={`mobHiddenTd ${activeTableIndex === 3 ? 'active' : ''}`}>
                  
                  <div className="navBtns">
                    <img onClick={handlePrev} src="./assets/img/stacking/tokenPrev.png" alt="" />
                    <img onClick={handleNext} src="./assets/img/stacking/tokenNext.png" alt="" />
                  </div>
                  <img className='tokenTableHeadImg' src="./assets/img/stacking/brandx.png" alt="" />
                </th>
              </tr>
            </thead>
            <tbody>
              {/* {[1,1,1,1]?.map(item=>( */}
                <tr>
                  <td>
                    <div className="d-flex align-items-center tableTokenBodyImg">
                      <img src="./assets/img/stacking/table04.png" alt="" />
                      BNB
                    </div>
                  </td>
                  <td>60%</td>
                  <td className={`mobHiddenTd ${activeTableIndex === 0 ? 'active' : ''}`}>7.7%</td>
                  <td className={`mobHiddenTd ${activeTableIndex === 1 ? 'active' : ''}`}>7.7%</td>
                  <td className={`mobHiddenTd ${activeTableIndex === 2 ? 'active' : ''}`}>7.7%</td>
                  <td className={`mobHiddenTd ${activeTableIndex === 3 ? 'active' : ''}`}>7.7%</td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center tableTokenBodyImg">
                      <img src="./assets/img/stacking/table01.png" alt="" />
                      USDT
                    </div>
                  </td>
                  <td>60%</td>
                  <td className='mobHiddenTd active'>0.3%</td>
                  <td className='mobHiddenTd'>0.3%</td>
                  <td className='mobHiddenTd'>0.3%</td>
                  <td className='mobHiddenTd'>0.3%</td>
                </tr><tr>
                  <td>
                    <div className="d-flex align-items-center tableTokenBodyImg">
                      <img src="./assets/img/stacking/table03.png" alt="" />
                      ETH
                    </div>
                  </td>
                  <td>60%</td>
                  <td className='mobHiddenTd active'>0.3%</td>
                  <td className='mobHiddenTd'>0.3%</td>
                  <td className='mobHiddenTd'>0.3%</td>
                  <td className='mobHiddenTd'>0.3%</td>
                </tr><tr>
                  <td>
                    <div className="d-flex align-items-center tableTokenBodyImg">
                      <img src="./assets/img/stacking/table02.png" alt="" />
                      BTC
                    </div>
                  </td>
                  <td>60%</td>
                  <td className='mobHiddenTd active'>1.1%</td>
                  <td className='mobHiddenTd'>1.1%</td>
                  <td className='mobHiddenTd'>1.1%</td>
                  <td className='mobHiddenTd'>1.1%</td>
                </tr>
              {/* ))} */}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-lg-5">
        <div className="tokenBanner ">
        <div className='row w-100'>
          <div className='col-6'>
            <img className='w-100 earnImge' src="./assets/img/stacking/tokenTableTxt.png" alt="" />
          </div>
          <div className='col-6'>
          <img className='speaker' src="./assets/img/stacking/tokenTableSpeaker.png" alt="" />

          </div>
        </div>
          {/* <img className='coin' src="./assets/img/stacking/tokenTableCoin.png" alt="" /> */}
        </div>
        {/* <div className="tokenBannerMob ">
          <img className='mobBanner w-100' src="./assets/img/stacking/earncard.svg" alt="" />
        </div> */}
      </div>
      <div className="col-12">
        <div className="sText">* Please, refer to the APR details on the popup section for each specific token.</div>
      </div>
    </div>
    </>
  )
}

export default TokenTable