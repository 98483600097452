import React from 'react'
import popularVector from '../../assets/images/popularVector.png'
import { useNavigate } from 'react-router-dom'
import { ROUTE_CONST } from '../../constants';


const GameCard = ({ data, isFreeSpinGame, footer = true }) => {
  const navigate = useNavigate();

  const handleClick = (id) => {
    if(isFreeSpinGame) {
      navigate(`${ROUTE_CONST.CASINO}/${id}?freeSpinGame`)
    }else {
      navigate(`${ROUTE_CONST.CASINO}/${id}`)
    }
  }

  return (
    <div className='popularCard' onClick={() => handleClick(data?.uuid)} >
      <div className="popularImg position-relative">
        <img src={data?.image} alt="" />
        {/* <div className='spanTxt'><img src={popularVector} alt="" />125 <span>Playing</span></div> */}
      </div>
      {
        footer ? (
          <div className='popularTxt'>{data?.provider}</div>
        ) : (<></>)
      }
    </div>
  )
}

export default GameCard;