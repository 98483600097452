import React, { useEffect, useState } from 'react'

const TimerComponent = ({targetTime, isClaimBonusModal, isBonusDropdown}) => {

  const calculateTimeLeft = () => {
    
    const now = new Date();
    const targ = new Date(targetTime);
    const difference = targ - now;
    
    if (difference < 0) {
      return {
        days: '00',
        hours: '00',
        minutes : '00',
        seconds: '00'
      };
    }

    const days = String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(2, '0');
    const hours = String(Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
    const minutes = String(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
    const seconds = String(Math.floor((difference % (1000 * 60)) / 1000)).padStart(2, '0');
    return [{
      days,
      hours,
      minutes,
      seconds
    }];
    // return `${days}:${hours}:${minutes}:${seconds}`
  };


  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetTime]);
  return (
    <>
    {
      isClaimBonusModal ? 
      <>
        <div className="tymBox">
          <div className="timeCount">
            {timeLeft?.[0]?.days} <br /> <span>Days</span>
          </div>:
          <div className="timeCount">
            {timeLeft?.[0]?.hours} <br /> <span>Hours</span>
          </div>:
          <div className="timeCount">
            {timeLeft?.[0]?.minutes} <br /> <span>Min</span>
          </div>:
          <div className="timeCount">
            {timeLeft?.[0]?.seconds} <br /> <span>Sec</span>
          </div>
        </div>
      </>
      : isBonusDropdown ? 
      <>
      {timeLeft?.[0]?.days !== '00' ? timeLeft?.[0]?.days + ':' : ''}
      {timeLeft?.[0]?.hours}:{timeLeft?.[0]?.minutes}:{timeLeft?.[0]?.seconds}
      </>
      : <>
      {timeLeft?.[0]?.days}:{timeLeft?.[0]?.hours}:{timeLeft?.[0]?.minutes}:{timeLeft?.[0]?.seconds}
      </>
    }
    </>
  )
}

export default TimerComponent