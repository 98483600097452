

import facebookIcon from "../../assets/images/facebook-icon.svg";
import vkIcon from "../../assets/images/vk-icon.svg";
import whatsappIcon from "../../assets/images/whatsapp-icon.svg";
import telegramIcon from "../../assets/images/telegram-icon.svg";
import skypeIcon from "../../assets/images/skype-icon.svg";
import oxIcon from "../../assets/images/ox-icon.svg";
import xIcon from "../../assets/images/x-icon.svg";
import rewardsAffiliate from "../../assets/images/reward-affiliate.png";
import peopleGroup from "../../assets/images/people-group.png";
import socialMedia from "../../assets/images/social-media-illustration.png";
import moneyBag from "../../assets/images/hand-hold-money-bag-sack.png";
import fileEmpty from "../../assets/images/fileEmpty.svg";
import ethereum from "../../assets/images/ethereum.svg";
import aboutAffiliate from "../../assets/images/about-affiliate.png";


const Dashboard = () => {

    
  return (
    <div class="tab-pane fade show active" id="nav-dashboard" role="tabpanel" aria-labelledby="nav-dashboard-tab">
                                       <div className="invite_code_sec bg_main_color p-3">
                                           <div className="d-flex align-items-center justify-content-md-between flex-md-row flex-column">
                                              <h4 className="m-0">Invite a Friend to Get</h4>
                                           <button type="button" className="border-0 shadow-none bg-transparent p-0 m-0" style={{color:'#0e95fa'}}>Referral Terms & Conditions</button>
                                           </div>
                                           <div className="invite_list mt-4">
                                            <ul className="p-0 d-flex align-items-center flex-wrap gap-3">
                                               <li><div className="list_item rounded-pill py-2 px-4">BGN 1,899.94 <span className="text-white">Referral Rewards</span></div></li>
                                               <li><div className="list_item rounded-pill py-2 px-4">25% <span className="text-white">Commission Rewards</span></div></li>
                                               <li><div className="list_item rounded-pill py-2 px-4">25% <span className="text-white">Commission Rewards</span></div></li>
                                            </ul>
                                           </div>
                                           <p className="my-2" style={{color:'#cdc5c5',fontWeight:'300'}}>Get $1,000.00 for each friend you invite, plus up to 25% commission on their wagers. Enjoy consistent commission, whether they win or lose, in our Casino and Sportsbook. Start earning now!</p>
                                           <div className="invite_refral mt-4">
                                            <ul className="p-0 d-flex align-items-center flex-wrap gap-4">
                                               <li>
                                                <label for="" class="form-label text-white">Referral Link</label>
                                               <div className="refral_link py-2 px-3 gap-5 rounded-pill position-relative d-flex justify-content-between align-items-center">
                                                    <input type="text" placeholder="https://bc.game/i-h47zsy7v-n/" className="bg-transparent border-0 shadow-none w-100" /> 
                                                    <button type="button" className="border-0"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M6.9998 6V3C6.9998 2.44772 7.44752 2 7.9998 2H19.9998C20.5521 2 20.9998 2.44772 20.9998 3V17C20.9998 17.5523 20.5521 18 19.9998 18H16.9998V20.9991C16.9998 21.5519 16.5499 22 15.993 22H4.00666C3.45059 22 3 21.5554 3 20.9991L3.0026 7.00087C3.0027 6.44811 3.45264 6 4.00942 6H6.9998ZM8.9998 6H16.9998V16H18.9998V4H8.9998V6Z"></path></svg></button>
                                               </div>
                                               </li>
                                               <li>
                                                <label for="" class="form-label text-white">Referral Code</label>
                                               <div className="refral_link py-2 px-3 gap-5 rounded-pill position-relative d-flex justify-content-between align-items-center">
                                                    <input type="text" placeholder="h47zsy7v" className="bg-transparent border-0 shadow-none w-100" /> 
                                                    <button type="button" className="border-0"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M6.9998 6V3C6.9998 2.44772 7.44752 2 7.9998 2H19.9998C20.5521 2 20.9998 2.44772 20.9998 3V17C20.9998 17.5523 20.5521 18 19.9998 18H16.9998V20.9991C16.9998 21.5519 16.5499 22 15.993 22H4.00666C3.45059 22 3 21.5554 3 20.9991L3.0026 7.00087C3.0027 6.44811 3.45264 6 4.00942 6H6.9998ZM8.9998 6H16.9998V16H18.9998V4H8.9998V6Z"></path></svg></button>
                                               </div>
                                               </li>
                                            </ul>
                                           </div>
                                           <div className="invite_social_links mt-4 ">
                                           <div className="col-lg-5 col-md-7 col-12 social_items rounded-pill py-2 px-4 d-flex flex-md-row flex-column align-items-center justify-content-center gap-3">
                                           <div className="text-white flex-shrink-0">Share via socials</div>
                                            <ul className="p-0 d-flex align-items-center gap-2 m-0">
                                              <li>
                                                 <button type="button" className="border-0 m-0 p-0 bg-transparent"><img src={facebookIcon} alt="" className="img-fluid" /></button>
                                              </li>
                                              <li>
                                                 <button type="button" className="border-0 m-0 p-0 bg-transparent"><img src={vkIcon} alt="" className="img-fluid" /></button>
                                              </li>
                                              <li>
                                                 <button type="button" className="border-0 m-0 p-0 bg-transparent"><img src={whatsappIcon} alt="" className="img-fluid" /></button>
                                              </li>
                                              <li>
                                                 <button type="button" className="border-0 m-0 p-0 bg-transparent"><img src={telegramIcon} alt="" className="img-fluid" /></button>
                                              </li>
                                              <li>
                                                 <button type="button" className="border-0 m-0 p-0 bg-transparent"><img src={skypeIcon} alt="" className="img-fluid" /></button>
                                              </li>
                                              <li>
                                                 <button type="button" className="border-0 m-0 p-0 bg-transparent"><img src={oxIcon} alt="" className="img-fluid" /></button>
                                              </li>
                                              <li>
                                                 <button type="button" className="border-0 m-0 p-0 bg-transparent"><img src={xIcon} alt="" className="img-fluid" /></button>
                                              </li>
                                            </ul>
                                           </div>
                                           </div>
                                       </div>
                                       <div className="invite_group_box bg_main_color p-3 mt-4">
                                        <div className="row g-4">
                                          <div className="col-lg-3 col-md-6 col-12">
                                            <div className="group_bx rounded-2 p-4 d-flex justify-content-center flex-column gap-2 align-items-center">
                                                <div className="group_img">
                                                  <img src={rewardsAffiliate} alt="Total Reward" className="img-fluid" />
                                                </div>
                                                  <div className="grp_txt text-white">Total Reward</div>
                                                   <h5 className="text-white">BGN 0.00</h5>
                                            </div>
                                          </div>
                                          <div className="col-lg-3 col-md-6 col-12">
                                            <div className="group_bx rounded-2 p-4 d-flex justify-content-center flex-column gap-2 align-items-center">
                                                <div className="group_img">
                                                  <img src={peopleGroup} alt="Total Friends" className="img-fluid" />
                                                </div>
                                                  <div className="grp_txt text-white">Total Friends</div>
                                                   <h5 className="text-white">0</h5>
                                            </div>
                                          </div>
                                          <div className="col-lg-3 col-md-6 col-12">
                                            <div className="group_bx rounded-2 p-4 d-flex justify-content-center flex-column gap-2 align-items-center">
                                                <div className="group_img">
                                                  <img src={socialMedia} alt="Referral Rewards" className="img-fluid" />
                                                </div>
                                                  <div className="grp_txt text-white">Referral Rewards</div>
                                                   <h5 className="text-white">BGN 0.00</h5>
                                            </div>
                                          </div>
                                          <div className="col-lg-3 col-md-6 col-12">
                                            <div className="group_bx rounded-2 p-4 d-flex justify-content-center flex-column gap-2 align-items-center">
                                                <div className="group_img">
                                                  <img src={moneyBag} alt="Commission Rewards" className="img-fluid" />
                                                </div>
                                                  <div className="grp_txt text-white">Commission Rewards</div>
                                                   <h5 className="text-white">BGN 0.00</h5>
                                            </div>
                                          </div>
                                        </div>
                                       </div>
                                       <div className="activity_sec p-3 bg_main_color mt-4">
                                           <h4 className="m-0 text-white">Rewards Activities</h4>

                                           <div className="activity_content text-center d-flex justify-content-center align-items-center flex-column gap-3 py-5">
                                              <div className="empty_img">
                                                  <img src={fileEmpty} alt="" className="img-fluid" />
                                              </div>
                                             <div className="text-center">
                                             <div>No info yet</div>
                                             <div>Invite friends to join you now!</div>
                                             </div>
                                           </div>
                                       </div>
                                       <div className="live_reward_sec bg_main_color p-3 mt-4">
                                           <div className="d-flex align-items-md-center flex-md-row flex-column justify-content-md-between gap-2">
                                             <h4 className="m-0 text-white">Live Rewards</h4>
                                             <div className="rounded_bg rounded-pill px-4 py-2 text-white">
                                                  Total Rewards Sent <span style={{color:'#0e95fa'}}>To-Date BGN 46,944,913.99</span>
                                             </div>
                                           </div>
                                           <div className="col-12 p-md-4 p-3 mt-3 rounded_bg rounded-2">
                                             <div className="row row-cols-md-5 row-cols-2">
                                               <div className="col my-2 brd_live_right py-2">
                                                  <div className="live_bx d-flex align-items-center justify-content-between gap-2">
                                                      <div className="user_txt">Username</div>
                                                      <div className="d-flex align-items-center gap-1">
                                                      <span className="live_sm_txt">
                                                       +25
                                                      </span>
                                                      <button type="button" className="border-0 shadow-none bg-transparent flex-shrink-0">
                                                           <span className="btn_img">
                                                              <img src={ethereum} alt="" className="img-fluid" />
                                                           </span>
                                                      </button>
                                                      </div>
                                                  </div>
                                               </div>
                                               <div className="col my-2 brd_live_right py-2">
                                                  <div className="live_bx d-flex align-items-center justify-content-between gap-2">
                                                      <div className="user_txt">Username</div>
                                                      <div className="d-flex align-items-center gap-1">
                                                      <span className="live_sm_txt">
                                                       +25
                                                      </span>
                                                      <button type="button" className="border-0 shadow-none bg-transparent flex-shrink-0">
                                                           <span className="btn_img">
                                                              <img src={ethereum} alt="" className="img-fluid" />
                                                           </span>
                                                      </button>
                                                      </div>
                                                  </div>
                                               </div>
                                               <div className="col my-2 brd_live_right py-2">
                                                  <div className="live_bx d-flex align-items-center justify-content-between gap-2">
                                                      <div className="user_txt">Username</div>
                                                      <div className="d-flex align-items-center gap-1">
                                                      <span className="live_sm_txt">
                                                       +25
                                                      </span>
                                                      <button type="button" className="border-0 shadow-none bg-transparent flex-shrink-0">
                                                           <span className="btn_img">
                                                              <img src={ethereum} alt="" className="img-fluid" />
                                                           </span>
                                                      </button>
                                                      </div>
                                                  </div>
                                               </div>
                                               <div className="col my-2 brd_live_right py-2">
                                                  <div className="live_bx d-flex align-items-center justify-content-between gap-2">
                                                      <div className="user_txt">Username</div>
                                                      <div className="d-flex align-items-center gap-1">
                                                      <span className="live_sm_txt">
                                                       +25
                                                      </span>
                                                      <button type="button" className="border-0 shadow-none bg-transparent flex-shrink-0">
                                                           <span className="btn_img">
                                                              <img src={ethereum} alt="" className="img-fluid" />
                                                           </span>
                                                      </button>
                                                      </div>
                                                  </div>
                                               </div>
                                               <div className="col my-2 brd_live_right py-2">
                                                  <div className="live_bx d-flex align-items-center justify-content-between gap-2">
                                                      <div className="user_txt">Username</div>
                                                      <div className="d-flex align-items-center gap-1">
                                                      <span className="live_sm_txt">
                                                       +25
                                                      </span>
                                                      <button type="button" className="border-0 shadow-none bg-transparent flex-shrink-0">
                                                           <span className="btn_img">
                                                              <img src={ethereum} alt="" className="img-fluid" />
                                                           </span>
                                                      </button>
                                                      </div>
                                                  </div>
                                               </div>
                                             
                                             </div>
                                             <div className="row row-cols-md-5 row-cols-2 brd_live_tp">
                                               <div className="col my-2 brd_live_right py-2">
                                                  <div className="live_bx d-flex align-items-center justify-content-between gap-2">
                                                      <div className="user_txt">Username</div>
                                                      <div className="d-flex align-items-center gap-1">
                                                      <span className="live_sm_txt">
                                                       +25
                                                      </span>
                                                      <button type="button" className="border-0 shadow-none bg-transparent flex-shrink-0">
                                                           <span className="btn_img">
                                                              <img src={ethereum} alt="" className="img-fluid" />
                                                           </span>
                                                      </button>
                                                      </div>
                                                  </div>
                                               </div>
                                               <div className="col my-2 brd_live_right py-2">
                                                  <div className="live_bx d-flex align-items-center justify-content-between gap-2">
                                                      <div className="user_txt">Username</div>
                                                      <div className="d-flex align-items-center gap-1">
                                                      <span className="live_sm_txt">
                                                       +25
                                                      </span>
                                                      <button type="button" className="border-0 shadow-none bg-transparent flex-shrink-0">
                                                           <span className="btn_img">
                                                              <img src={ethereum} alt="" className="img-fluid" />
                                                           </span>
                                                      </button>
                                                      </div>
                                                  </div>
                                               </div>
                                               <div className="col my-2 brd_live_right py-2">
                                                  <div className="live_bx d-flex align-items-center justify-content-between gap-2">
                                                      <div className="user_txt">Username</div>
                                                      <div className="d-flex align-items-center gap-1">
                                                      <span className="live_sm_txt">
                                                       +25
                                                      </span>
                                                      <button type="button" className="border-0 shadow-none bg-transparent flex-shrink-0">
                                                           <span className="btn_img">
                                                              <img src={ethereum} alt="" className="img-fluid" />
                                                           </span>
                                                      </button>
                                                      </div>
                                                  </div>
                                               </div>
                                               <div className="col my-2 brd_live_right py-2">
                                                  <div className="live_bx d-flex align-items-center justify-content-between gap-2">
                                                      <div className="user_txt">Username</div>
                                                      <div className="d-flex align-items-center gap-1">
                                                      <span className="live_sm_txt">
                                                       +25
                                                      </span>
                                                      <button type="button" className="border-0 shadow-none bg-transparent flex-shrink-0">
                                                           <span className="btn_img">
                                                              <img src={ethereum} alt="" className="img-fluid" />
                                                           </span>
                                                      </button>
                                                      </div>
                                                  </div>
                                               </div>
                                               <div className="col my-2 brd_live_right py-2">
                                                  <div className="live_bx d-flex align-items-center justify-content-between gap-2">
                                                      <div className="user_txt">Username</div>
                                                      <div className="d-flex align-items-center gap-1">
                                                      <span className="live_sm_txt">
                                                       +25
                                                      </span>
                                                      <button type="button" className="border-0 shadow-none bg-transparent flex-shrink-0">
                                                           <span className="btn_img">
                                                              <img src={ethereum} alt="" className="img-fluid" />
                                                           </span>
                                                      </button>
                                                      </div>
                                                  </div>
                                               </div>
                                           
                                            </div>
                                           </div>
                                            
                                       </div>
                                       <div className="about_affiliate_sec mt-4">
                                          <div className="row">
                                            <div className="col-12">
                                              <div className="row align-items-center p-3 m-0 rounded-3" style={{background:'#202733'}}>
                                              <div className="col-lg-5 col-md-6 col-12">
                                                      <div className="about_img text-center">
                                                        <img src={aboutAffiliate} alt="" className="img-fluid" />
                                                      </div>
                                                  </div>
                                                  <div className="col-lg-7 col-md-6 col-12">
                                                    <div className="about_content">
                                                      <h4 className="text-white m-0">Learn more about our <span style={{color:"#0e95fa"}}>Affiliate program</span></h4>
                                                      <p className="my-3">If you have a large audience and followers. We have special conditions for you to customize your referral program!
                                                      If you can invite players and their wager amount reaches a billion dollars and above, you will get your own customized casino! You can set up a casino website with your domain and design style.</p>
                                                      <p>for more details, please contact Us</p>               
                                                    </div>
                                                    <div className="about_input position-relative d-flex align-items-center justify-content-between gap-3 rounded-pill w-100 p-3">
                                                        <input type="text" placeholder="business@example" className="bg-transparent border-0 shadow-none w-100" />
                                                        <button type="button" className="border-0 rounded-pill px-4 py-2 flex-shrink-0">Send Now</button>
                                                    </div>
                                                  </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="faq_sec mt-4">
                                              <div className="row">
                                                  <div className="col-12">
                                                    <h4 className="affiliateheading">Frequently Asked Questions</h4>
                                                  </div>
                                                  <div className="col-12 mt-4">
                                                  <div className="accordion d-flex flex-column gap-4" id="accordionExample">
                                                      <div className="accordion-item">
                                                          <h2 className="accordion-header" id="headingOne">
                                                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                              How does the referral system work?
                                                          </button>
                                                          </h2>
                                                          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                          <div className="accordion-body text-white">
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, neque ipsa possimus aliquid itaque eligendi, quaerat sit numquam sunt excepturi quibusdam esse omnis vel veritatis similique dolorum quia. Reiciendis, vero!
                                                          </div>
                                                          </div>
                                                      </div>
                                                      <div className="accordion-item">
                                                          <h2 className="accordion-header" id="headingTwo">
                                                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                             How much can I earn from my referral?
                                                          </button>
                                                          </h2>
                                                          <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                          <div className="accordion-body text-white">
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, neque ipsa possimus aliquid itaque eligendi, quaerat sit numquam sunt excepturi quibusdam esse omnis vel veritatis similique dolorum quia. Reiciendis, vero!
                                                          </div>
                                                          </div>
                                                      </div>
                                                      <div className="accordion-item">
                                                          <h2 className="accordion-header" id="headingThree">
                                                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                             What is USD reward and how does it work?
                                                          </button>
                                                          </h2>
                                                          <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                          <div className="accordion-body text-white">
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, neque ipsa possimus aliquid itaque eligendi, quaerat sit numquam sunt excepturi quibusdam esse omnis vel veritatis similique dolorum quia. Reiciendis, vero!
                                                          </div>
                                                          </div>
                                                      </div>

                                                      <div className="accordion-item">
                                                          <h2 className="accordion-header" id="headingFour">
                                                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                             Are there any banners I can use to advertise?
                                                          </button>
                                                          </h2>
                                                          <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                          <div className="accordion-body text-white">
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, neque ipsa possimus aliquid itaque eligendi, quaerat sit numquam sunt excepturi quibusdam esse omnis vel veritatis similique dolorum quia. Reiciendis, vero!
                                                          </div>
                                                          </div>
                                                      </div>
                                                      <div className="accordion-item">
                                                          <h2 className="accordion-header" id="headingFive">
                                                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                             I have big audience, how I can get special deals?
                                                          </button>
                                                          </h2>
                                                          <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                          <div className="accordion-body text-white">
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, neque ipsa possimus aliquid itaque eligendi, quaerat sit numquam sunt excepturi quibusdam esse omnis vel veritatis similique dolorum quia. Reiciendis, vero!
                                                          </div>
                                                          </div>
                                                      </div>
                                                      <div className="accordion-item">
                                                          <h2 className="accordion-header" id="headingSix">
                                                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                             Can I see the data of my referral?
                                                          </button>
                                                          </h2>
                                                          <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                                          <div className="accordion-body text-white">
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, neque ipsa possimus aliquid itaque eligendi, quaerat sit numquam sunt excepturi quibusdam esse omnis vel veritatis similique dolorum quia. Reiciendis, vero!
                                                          </div>
                                                          </div>
                                                      </div>
                                                      <div className="accordion-item">
                                                          <h2 className="accordion-header" id="headingSeven">
                                                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                             Can I send tip or reward to my referrals?
                                                          </button>
                                                          </h2>
                                                          <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                                          <div className="accordion-body text-white">
                                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, neque ipsa possimus aliquid itaque eligendi, quaerat sit numquam sunt excepturi quibusdam esse omnis vel veritatis similique dolorum quia. Reiciendis, vero!
                                                          </div>
                                                          </div>
                                                      </div>
                                                  
                                                      </div>
                                                  </div>
                                          </div>
                                      </div>
                                    </div>
  )
}

export default Dashboard
