import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import userWallet from "./../../assets/images/wallet.svg";
import userWithdraw from "./../../assets/images/withdraw.svg";
import userTransactions from "./../../assets/images/transactions.svg";
import buy from "./../../assets/images/buy.svg";
import swap from "./../../assets/images/swap.svg";
import rollover from "./../../assets/images/rollover.svg";
import bitHistory from "./../../assets/images/bit-history.svg";
import deposit from "./../../assets/images/deposit.svg";
import totalBalance from "./../../assets/images/total-balance.svg";
import rupeeIcon from "./../../assets/images/rupee_icon.svg";
import USDT from "./../../assets/images/usdt.svg";
import BTC from "./../../assets/images/btc.svg";
import BNB from "./../../assets/images/bnb.svg";
import ETH from "./../../assets/images/eth.svg";
import TURBO from "./../../assets/images/turbo.svg";
import USDC from "./../../assets/images/usdc.svg";
import AVAX from "./../../assets/images/avax.svg";
import CBANK from "./../../assets/images/cbank.svg";
import CAF from "./../../assets/images/caf.svg";
import cairo from "./../../assets/images/cairo.svg";
import thunderIcon from "./../../assets/images/thunder-icon.svg";
import giftBox from "./../../assets/images/gift.svg";
import barImage from "./../../assets/images/barCode.svg";
import EUR from "./../../assets/images/eur.svg";
import sepaInstant from "./../../assets/images/sepa-instant.svg";
import Euro from "./../../assets/images/euro.svg";
import swapIcon from "./../../assets/images/swap-icon.svg";
import fileEmpty from "../../assets/images/fileEmpty.svg";
import BalanceTab from "./BalanceTab";
import WithdrawTab from "./WithdrawTab";
import DepositWalletTab from "./DepositWalletTab";
import SwapTab from "./SwapTab";
import TransactionTab from "./TransactionTab";
import RolloverTab from "./RolloverTab";
import BetHistoryTab from "./BetHistoryTab";
import { ROUTE_CONST } from "../../constants";


const Wallet = () => {
  const [selectedTab, setSelectedTab] = useState("Balance");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // Get specific query params
  const paramValue = searchParams.get("tab");
  console.log({ paramValue });
  console.log({ selectedTab });

  useEffect(() => {
    if (paramValue) {
      setSelectedTab(paramValue);
    }
  }, [paramValue]);


  return (
    <>
      <div className="container-fluid wallet_sec">
        <div className="row">
          <div className="col-12 ps-0">
            <h2 className="main_heading">WALLET</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-4 col-12 ps-0">
            <div className="position-sticky" style={{ top: "40px" }}>
              <div
                className="dropTabs dropDownMenu w-100 nav nav-tabs"
                id="nav-tab"
                role="tablist"
                style={{ minWidth: "100%" }}
              >
                <button
                  onClick={() => {
                    setSelectedTab("Balance");
                    navigate(ROUTE_CONST.WALLET);
                  }}
                  className={`dropdownItem dropdown-item nav-link ${
                    selectedTab === "Balance" ? "active" : ""
                  }`}
                  id="nav-balance-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-balance"
                  type="button"
                  role="tab"
                  aria-controls="nav-balance"
                  aria-selected="true"
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="dropIcon">
                      <img src={userWallet} alt="" />
                    </div>
                    <div className="DropTxt">Balance</div>
                  </div>
                </button>
                <button
                  onClick={() => {
                    navigate(ROUTE_CONST.WALLET);
                  }}
                  className={`dropdownItem dropdown-item nav-link ${
                    selectedTab === "Deposit" ? "active" : ""
                  }`}
                  id="nav-deposit-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-deposit"
                  type="button"
                  role="tab"
                  aria-controls="nav-deposit"
                  aria-selected="false"
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="dropIcon">
                      <img src={deposit} alt="" />
                    </div>
                    <div className="DropTxt">Deposit</div>
                  </div>
                </button>
                <button
                  onClick={() => {
                    navigate(ROUTE_CONST.WALLET);
                  }}
                  className={`dropdownItem dropdown-item nav-link ${
                    selectedTab === "Withdraw" ? "active" : ""
                  }`}
                  id="nav-withdraw-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-withdraw"
                  type="button"
                  role="tab"
                  aria-controls="nav-withdraw"
                  aria-selected="false"
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="dropIcon">
                      <img src={userWithdraw} alt="" />
                    </div>
                    <div className="DropTxt">Withdraw</div>
                  </div>
                </button>
                <button
                  onClick={() => {
                    navigate(ROUTE_CONST.WALLET);
                  }}
                  className="dropdownItem dropdown-item nav-link"
                  disabled
                  id="nav-buy-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-buy"
                  type="button"
                  role="tab"
                  aria-controls="nav-buy"
                  aria-selected="false"
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="dropIcon">
                      <img src={buy} alt="" />
                    </div>
                    <div className="DropTxt">Buy</div>
                  </div>
                </button>
                <button
                  onClick={() => {
                    navigate(ROUTE_CONST.WALLET);
                  }}
                  className="dropdownItem dropdown-item nav-link"
                  id="nav-swap-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-swap"
                  type="button"
                  role="tab"
                  aria-controls="nav-swap"
                  aria-selected="false"
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="dropIcon">
                      <img src={swap} alt="" />
                    </div>
                    <div className="DropTxt">Swap</div>
                  </div>
                </button>
                <button
                  onClick={() => {
                    navigate(ROUTE_CONST.WALLET);
                  }}
                  className={`dropdownItem dropdown-item nav-link ${
                    selectedTab === "Transaction" ? "active" : ""
                  }`}
                  id="nav-transaction-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-transaction"
                  type="button"
                  role="tab"
                  aria-controls="nav-transaction"
                  aria-selected="false"
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="dropIcon">
                      <img src={userTransactions} alt="" />
                    </div>
                    <div className="DropTxt">Transaction</div>
                  </div>
                </button>
                <button
                  onClick={() => {
                    navigate(ROUTE_CONST.WALLET);
                  }}
                  className="dropdownItem dropdown-item nav-link"
                  id="nav-rollover-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-rollover"
                  type="button"
                  role="tab"
                  aria-controls="nav-rollover"
                  aria-selected="false"
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="dropIcon">
                      <img src={rollover} alt="" />
                    </div>
                    <div className="DropTxt">Rollover</div>
                  </div>
                </button>
                <button
                  onClick={() => {
                    navigate(ROUTE_CONST.WALLET);
                  }}
                  className="dropdownItem dropdown-item nav-link"
                  id="nav-betHistory-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-betHistory"
                  type="button"
                  role="tab"
                  aria-controls="nav-betHistory"
                  aria-selected="false"
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="dropIcon">
                      <img src={bitHistory} alt="" />
                    </div>
                    <div className="DropTxt">Bet History</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-9 col-md-8 col-12 pe-0">
            <div className="wallet_box tab-content" id="nav-tabContent">
              <div
                className={`tab-pane fade    ${
                  selectedTab === "Balance" ? "show  active " : ""
                } `}
                id="nav-balance"
                role="tabpanel"
                aria-labelledby="nav-balance-tab"
              >
                <BalanceTab setSelectedTab={setSelectedTab} />
              </div>
              <div
                className={`tab-pane   ${
                  selectedTab === "Deposit" ? "show active " : ""
                } fade`}
                id="nav-deposit"
                role="tabpanel"
                aria-labelledby="nav-deposit-tab"
              >
                <DepositWalletTab />
              </div>
              <div
                className={`tab-pane   ${
                  selectedTab === "Withdraw" ? "show active " : ""
                } fade`}
                id="nav-withdraw"
                role="tabpanel"
                aria-labelledby="nav-withdraw-tab"
              >
                <WithdrawTab />
              </div>

              <div
                className="tab-pane fade"
                id="nav-buy"
                role="tabpanel"
                aria-labelledby="nav-buy-tab"
              ></div>
              <div
                className="tab-pane fade"
                id="nav-swap"
                role="tabpanel"
                aria-labelledby="nav-swap-tab"
              >
                <SwapTab />
              </div>
              <div
                className={`tab-pane   ${
                  selectedTab === "Transaction" ? "show active " : ""
                } fade`}
                id="nav-transaction"
                role="tabpanel"
                aria-labelledby="nav-transaction-tab"
              >
                <TransactionTab />
              </div>

              <div
                className="tab-pane fade"
                id="nav-rollover"
                role="tabpanel"
                aria-labelledby="nav-rollover-tab"
              >
                <RolloverTab />
              </div>

              <div
                className="tab-pane fade"
                id="nav-betHistory"
                role="tabpanel"
                aria-labelledby="nav-betHistory-tab"
              >
                <BetHistoryTab />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
