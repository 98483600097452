import React, { useEffect, useState } from "react";
import LiveSportsSection from "./components/LiveSportsSection";
import BonusSlider from "./components/BonusSlider";
import DesktopHero from "./components/DesktopHero/DesktopHero";
import { useMediaQuery } from "../../utils/useMediaQuery";
import {
  RESPONSIVE_WIDTH,
  AUTH_MODAL_TABS,
  ROUTE_CONST,
} from "../../constants";
import BetTable from "../../components/betTable/BetTable";
import DepositBanner from "../../components/depositBanner/DepositBanner";
import livsports from "../../assets/images/casino.png";
import popularGames from "./../../assets/images/populargame.png";
import CasinoSlider from "../../components/casinoSlider/CasinoSlider";
import SportsSlider from "../../components/sportsSlider/SportsSlider";
import { getAPI } from "../../services/apiInstance";
import { useAuthModal } from "../../hooks/useAuthModal";
import { useTranslation } from "react-i18next";
import SearchPopNew from "../../components/searchpop/SearchPopNew";
import SkeletonLoader from "../../components/skeletonLoader/SkeletonLoader";
import SportsBoxSec from "./././components/DesktopHero/SportsBoxSec";
import NewSportsBoxSec from "./components/DesktopHero/NewSportsBoxSec";
import SportsBannerSec from "./components/DesktopHero/SportsBannerSec";
import NewSportsSlider from "../../components/sportsSlider/NewSportsSlider";
import TopEventWidgets from "./components/DesktopHero/TopEventWidgets";
import LiveEventWidgets from "./components/DesktopHero/LiveEventWidgets";
import UpperCategories from "../../components/sportsSlider/UpperCategories";
import { set } from "date-fns";

const Home = () => {
  const { setAuthModalObject } = useAuthModal();
  const isLgScreen = useMediaQuery(RESPONSIVE_WIDTH.MD_SCREEN);
  const isSMScreen = useMediaQuery(RESPONSIVE_WIDTH.SM_SCREEN);
  const [games, setGames] = useState([]);
  const [gamesLoading, setGamesLoading] = useState(false);
  const [allCats, setAllCats] = useState([]);
  const [specialCategory, setSpecialCategory] = useState([]);
  const [allCatsLoading, setAllCatsLoading] = useState(false);
  const [selecedSpecialCat, setSelectedSpecialcat] = useState("");
  const [selectedSpecialCatName, setSelectedSpecialCatName] = useState("");
  const [specialGames, setSpecialGames] = useState([]);
  const [isLoadingSpecialCat, setIsLoadingSpecialCat] = useState(true);
  const [selectedUpperCategory, setSelectedUpperCategory] = useState({
    name: "sports",
  });
  const { t } = useTranslation();
  const casinoLabels = t("casinoLabels", { returnObjects: true });

  const handleRegister = () => {
    setAuthModalObject((prev) => ({
      isAuthOpen: true,
      selectedTab: AUTH_MODAL_TABS.SIGN_UP,
    }));
  };

  const getSpecialCategories = async () => {
    try {
      const res = await getAPI("/get-special-category");
      if (res.data.success) {
        setSpecialCategory(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getGamesBySpecailCatId = async () => {
    try {
      setIsLoadingSpecialCat(true);
      const res = await getAPI(
        `/get-games-by-special-category/${selecedSpecialCat}`
      );

      if (res.data.success) {
        setSpecialGames(res.data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingSpecialCat(false);
    }
  };

  const getGames = async () => {
    setGamesLoading(true);
    try {
      const res = await getAPI(
        `games/top-rated?is_mobile=${isSMScreen ? "true" : "false"}&limit=2`
      );
      setGames(res?.data?.data);
    } catch (error) {
    } finally {
      setGamesLoading(false);
    }
  };

  useEffect(() => {
    getSpecialCategories();
  }, []);

  useEffect(() => {
    if (selecedSpecialCat) {
      getGamesBySpecailCatId();
    }
  }, [selecedSpecialCat]);

  useEffect(() => {
    getGames();
  }, [isSMScreen]); //eslint-disable-line

  const getAllGames = async () => {
    setAllCatsLoading(true);
    try {
      const res = await getAPI(
        `games/get-games-by-cat?is_mobile=${
          isSMScreen ? "true" : "false"
        }&gameLimit=10`
      );
      if (res?.data?.success) {
        setAllCats(res?.data?.data);
      } else {
        setAllCats([]);
      }
    } catch (error) {
      setAllCats([]);
    } finally {
      setAllCatsLoading(false);
    }
  };

  useEffect(() => {
    if (specialCategory.length > 0) {
      setSelectedSpecialcat(specialCategory[0]._id);
      setSelectedSpecialCatName(specialCategory[0].category_name);
    }
  }, [specialCategory]);
  useEffect(() => {
    getAllGames();
  }, []); //eslint-disable-line

  return (
    <>
      {/* {!isLgScreen ? <DesktopHero /> : <BonusSlider />} */}
      {/* {true ? <DesktopHero /> : <BonusSlider />} */}
      <DesktopHero />
      <SportsBoxSec
        selectedUpperCategory={selectedUpperCategory}
        setSelectedUpperCategory={setSelectedUpperCategory}
      />

      {/* <LiveEventWidgets /> */}
      {selectedUpperCategory?.name === "sports" ? (
        <TopEventWidgets />
      ) : (
        <UpperCategories selectedUpperCategory={selectedUpperCategory} />
      )}

      {/* <RecentBigWin /> */}

      {!gamesLoading ? (
        <CasinoSlider
          title={"Casino"}
          icon={livsports}
          nextBtn={"casinoNext1"}
          prevBtn={"casinoPrev1"}
          showTabs={true}
          data={games}
          loading={gamesLoading}
        />
      ) : (
        <SkeletonLoader type={"gameCategory"} multiple={true} />
      )}

      {/* {isLgScreen && <LiveSportsSection />} */}

      <SportsSlider />

      <NewSportsBoxSec
        selecedSpecialCat={selecedSpecialCat}
        setSelectedSpecialcat={setSelectedSpecialcat}
        setSelectedSpecialCatName={setSelectedSpecialCatName}
        specialCategory={specialCategory}
      />

      <SportsBannerSec
        specialGames={specialGames}
        isLoadingSpecialCat={isLoadingSpecialCat}
        selectedSpecialCatName={selectedSpecialCatName}
      />

      <NewSportsSlider games={games} />

      {isLgScreen && <DepositBanner />}

      {!allCatsLoading ? (
        allCats.length ? (
          allCats.map((item, index) => (
            <CasinoSlider
              data={item?.data}
              loading={allCatsLoading}
              title={item?.name?.category_name}
              icon={item?.name?.image}
              nextBtn={`casinoNext${index + 2}`}
              prevBtn={`casinoPrev${index + 2}`}
              showTabs={false}
              navigateUrl={ROUTE_CONST.CASINO}
              selected_id={item?._id}
            />
          ))
        ) : null
      ) : (
        <>
          {[1, 1, 1, 1]?.map((item) => (
            <SkeletonLoader type={"gameCategory"} multiple={true} />
          ))}
        </>
      )}

      {!isLgScreen && <DepositBanner />}
      <div className="col-12">
        <BetTable />
      </div>
      {/* <SearchPopNew/> */}
    </>
  );
};
export default Home;
