import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  DEFAULT_IMAGE,
  LANGUAGE,
  TABS_OPTIONS,
  USER_LANG,
} from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../hooks/useAuth";
import i18next from "i18next";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { useLocation } from "react-router-dom";
// import starimg from "../../assets/img/star.png";
import { getAPI, postAPIAuth } from "../../../services/apiInstance";
import close from "./../../../assets/images/closeModal.png";
import search from "./../../../assets/images/search.png";
import usaFlag from "./../../../assets/images/usaFlag.webp";
import franceFlag from "./../../../assets/images/franceFlag.webp";

// import england from './../../../assets/images/';
import { useTranslation } from "react-i18next";

const UnAuthCurrencyModal = ({
  isOpen,
  setIsOpen,
  selectedTab,
  setSelectedTab,
}) => {
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [fiat, setFiat] = useState([]);
  const userDetails = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  const { token } = useAuth();
  const [language, setLanguage] = useState([]);
  const [isLangLoading, setIsLangLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const location = useLocation();
  const { t } = useTranslation();
  const commonText = t("commonText", { returnObjects: true });
  const textLabels = t("UnAuthCurrencyModal", { returnObjects: true });
  useEffect(() => {
    if (isOpen) {
      setLanguage(localStorage.getItem(USER_LANG));
    }
    getAllFiat();
  }, [isOpen]);

  const getAllFiat = async () => {
    try {
      const res = await getAPI("currency/get-currency");
      if (res?.data.success) {
        setFiat(res?.data.data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  // will be of used
  // const currencyHandler = async (item) => {

  //     if (!token) {
  //         setIsOpen(false)
  //         setAuthModalObject(pre => ({ ...pre, isAuthOpen: true }))
  //         return
  //     }
  //     if (!userDetails?.id) return
  //     if (userDetails?.usercurrency) {
  //         const body = {
  //             userId: userDetails.id,
  //             currency: item.code,
  //             image: item.icon,
  //         }

  //         try {
  //             const res = await postAPIAuth("user/save-user-currency", body)
  //             if (res?.data?.success) {
  //                 const response = await fetchUserDetails(token)
  //                 dispatch({ type: reducerConst.USER_DETAILS, payload: response?.data?.data ? response.data.data : {} })
  //             }
  //         } catch (error) {

  //         }
  //     }
  //     localStorage.setItem(SELECTED_CURRENCY, JSON.stringify(item))
  //     setSelectedCurrency(item)
  //     //------------------------------------////------------------------------------------------//

  //     if (token) {
  //         // when user is logged in
  //         if (!userDetails?.id) return
  //         if (userDetails?.usercurrency) {
  //             const body = {
  //                 userId: userDetails.id,
  //                 currency: item.code,
  //                 image: item.icon,
  //             }

  //             try {
  //                 const res = await postAPIAuth("user/save-user-currency", body)
  //                 if (res?.data?.success) {
  //                     const response = await fetchUserDetails(token)
  //                     dispatch({ type: reducerConst.USER_DETAILS, payload: response?.data?.data ? response.data.data : {} })
  //                     localStorage.setItem(SELECTED_CURRENCY, JSON.stringify(item))
  //                 }
  //             } catch (error) {

  //             }
  //         }
  //     } else {
  //         // when user is not logged in
  //         localStorage.setItem(SELECTED_CURRENCY, JSON.stringify(item))
  //         setSelectedCurrency(item)
  //         setIsOpen(false)
  //         setAuthModalObject(pre => ({ ...pre, isAuthOpen: true }))
  //     }
  // }

  // useEffect(() => {
  //     if (isOpen) {
  //         // getAllFiat()
  //     }
  // }, [isOpen])
  //---------------------------------this is language api , can be of used in future -----------//
  // const getLanguage = async () => {
  //     try {
  //         const res = await getAPI('user/language')
  //         if (res?.data?.status) {
  //             setLanguage(res.data.data)
  //         }
  //     } catch (error) {
  //     } finally {
  //         setIsLangLoading(false)
  //     }
  // }

  // {
  //   displayName: "English",
  //   symbol: "en"
  // },
  // {
  //   displayName: "Français",
  //   symbol: "fr"
  // },

  const languageHandler = async (item) => {
    if (token) {
      try {
        const body = {
          language: item?.symbol,
        };
        const res = await postAPIAuth(
          `user/change-user-lang?userId=${userDetails?.id}`,
          body,
          token
        );
        if (res.data.success) {
          setLanguage(item.symbol);
          if (!language) {
            return;
          }
          i18next
            .changeLanguage(item.symbol)
            .catch((err) => console.error("Failed to change language", err));
          localStorage.setItem(USER_LANG, item?.symbol);
          succesToaster(`${textLabels.Language_successfully}`);
          setSelectedLanguage(item);
        } else {
          errorToaster(`${textLabels.Language_Error}`);
        }
      } catch (error) {
        errorToaster(`${textLabels.Language_Error}`);
      }
    } else {
      localStorage.setItem(USER_LANG, item?.symbol);
      i18next.changeLanguage(item.symbol);
      setLanguage(item?.symbol);
      succesToaster(`${textLabels.Language_successfully}`);
    }
  };

  console.log(language, "dlkldldoidudfldod");

  return (
    <>
      <div
        className={`modal fade border-0 depositModalWrapper ${
          isOpen ? "show" : ""
        }`}
        id="currency"
        style={{ display: isOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modalDailog modal-dialog-centered   modal-dialog-scrollable">
          <div className="modal-content modalContentUser langModal mx-auto">
            <div className="languageModal d-flex flex-column">
              <div className="d-flex align-items-center justify-content-between gap-3">
                <div className="heading">Language</div>
                <div
                  onClick={() => setIsOpen(false)}
                  className="cursor-pointer"
                >
                  <img className="closeIcon   " src={close} alt="" />
                </div>
              </div>
              <div className="searchParent d-flex align-items-center gap-2">
                <img className="searchIcon" src={search} alt="" />
                <input
                  className="flex-grow-1"
                  type="text"
                  placeholder="Search"
                />
              </div>
              <div className="languageBoxparent flex-grow-1 overflow-y-auto overflow-hidden">
                <div
                  onClick={() =>
                    languageHandler({
                      displayName: "English",
                      symbol: "en",
                    })
                  }
                  className={`cursor-pointer languageBox ${
                    selectedLanguage?.symbol === "en" || language === "en"
                      ? "active"
                      : ""
                  } d-flex align-items-center justify-content-between`}
                >
                  <div className="languagecontent">
                    <div className="imgBox d-flex align-items-center justify-content-center">
                      <img src={usaFlag} alt="" />
                    </div>
                    <div className="text">English</div>
                  </div>
                  <label htmlFor="languagebtn" className="languagebtnLabel">
                    <input
                      className="d-none"
                      type="radio"
                      name=""
                      id="languagebtn"
                    />
                  </label>
                </div>
                <div
                  onClick={() =>
                    languageHandler({
                      displayName: "Français",
                      symbol: "fr",
                    })
                  }
                  className={`cursor-pointer languageBox ${
                    selectedLanguage?.symbol === "fr" || language === "fr"
                      ? "active"
                      : ""
                  } d-flex align-items-center justify-content-between`}
                >
                  <div className="languagecontent">
                    <div className="imgBox d-flex align-items-center justify-content-center">
                      <img src={franceFlag} alt="" />
                    </div>
                    <div className="text">France</div>
                  </div>
                  <label htmlFor="languagebtn" className="languagebtnLabel">
                    <input
                      className="d-none"
                      type="radio"
                      name=""
                      id="languagebtn"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnAuthCurrencyModal;
