import React from 'react'

const DisputeWalletModal = (props) => {
  return (
    <>
    <div class="modal fade" id={props.id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog deposit_issues_bx">
        <div className="modal-content">
          <div className="modal-header deposit_head p-4 border-0 justify-content-between">
            <h6 className="text-white m-0 fw-bold">
              Fiat deposit issues or disputes
            </h6>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
            <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="18px"
                            width="18px"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path>
                          </svg>
            </button>
          </div>
          <div className="modal-body deposit_body p-4">
            <p className="text-white">
              - For any issues or disputes arising out of any fiat
              transactions on Our Site, you must formally report and
              submit such issues or disputes to BC.GAME within 30
              calendar days from the date of the transaction(s) in
              question.
            </p>
            <p className="text-white">
              - For any issues or disputes received by BC.GAME exceeding
              30 calendar days from the date of such transaction(s), We
              will not accept them for resolution and such
              transaction(s) are ineligible for us to resolve.
            </p>
            <p className="text-white">
              - Any attempts to circumvent this rule by raising your
              issue or dispute in relation to the fiat transaction(s)
              beyond the 30 calendar days period on a public complaint
              forum of any website, whether online or otherwise, will
              not change this.
            </p>
            <p className="text-white">
              - BC.GAME will not be able to assist any issues or
              disputes submitted beyond the stipulated timeframe. There
              are no exceptions to this rule.
            </p>
          </div>

        </div>
      </div>
      </div>
    
    </>
  )
}

export default DisputeWalletModal
