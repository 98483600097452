import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';

const DGWStacking = () => {
    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
    };
    const [activeTab, setActiveTab] = useState(1); 
    return (
        <>
            <main className="main stackingMain DgwStackingWrapper" id="main">
            <div className="mainContaint">
                <div className="homepage stackingPage aboutStackingWrapper">
                  {/* ------------------------------------- */}
                    <div className="row pb-4">
                        <div className="col-lg-7 col-12">
                            <div className="heading">$DMBL Staking</div>
                        </div>
                        <div className="col-lg-5 col-12 d-xl-flex justify-content-end d-none">
                            <div className="row mx-0">
                                <div className="col-auto"><button className='dgwBtns'><img src="./assets/img/stacking/aboutStacking.png" alt="" />About Staking</button></div>
                                <div className="col-auto px-0"><button className='dgwBtns'><img src="./assets/img/stacking/dAppCircle.png" alt="" />About $DMBL</button></div>
                                <div className="col-auto pe-0"><button className='dgwBtns dgwBtnsCol'><img src="./assets/img/stacking/calculator.png" alt="" />Calculate</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-7 col-12">
                            <div className='row mx-0 mb-5'>
                                <div className='col-12 px-0'>
                                    <div className='chartAccountDbl'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='row align-items-center'>
                                                    <div className='col-md col-3'>
                                                        <div className='row align-items-center m-md-3'>
                                                            <div className='col-auto'>
                                                                <div className='chartImgtrade'><img src="./assets/img/stacking/trend1.png" alt="" className='w-100 h-100' /></div>
                                                            </div>
                                                            <div className='col'>
                                                                <div className='chartSubHeading'>Current APY</div>
                                                                <div className='headingChart'>15.00%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-auto col-9 d-flex align-items-center'>
                                                    <div className='chartImg d-md-none d-block'><img src="./assets/img/stacking/chart.png" alt="" /></div>

                                                        <div>
                                                            <ul className='chatValue mb-0'>
                                                                <li>30d</li>
                                                                <li>60d</li>
                                                                <li>90d</li>


                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='chartImg d-md-block d-none'><img src="./assets/img/stacking/chart.png" alt="" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-12'>
                                    <div className='overflow-hidden stackingPoolWrapper'>
                                        <div className="row  ">
                                            <div className="col-5 pe-0">
                                                <div className="row mx-0 g-3">
                                                    <div className="col-xl-6  poolBox ">
                                                        <div className="poolLight">Staking pool <img src="./assets/img/stacking/question.png" alt="" />
                                                        </div>
                                                        <div className="poolNumber">$1,625,413</div>
                                                    </div>
                                                    <div className="col-xl-6  poolBox ">
                                                        <div className="poolLight">Total next Payout
                                                        </div>
                                                        <div className="poolNumber">$48,751</div>
                                                    </div> 
                                                    <div className="col-12 poolBox ">
                                                        <div className="poolLight">Total next Payout  <img src="./assets/img/stacking/question.png" alt="" />
                                                        </div>
                                                        <div className="poolNumber">$48,751</div>
                                                    </div> 
                                                </div>
                                            </div>
                                            <div className="col-7 pe-xl-0 ">
                                                <div className="row mx-0">
                                                    <div className="col-12 px-0">
                                                    {/* after log in screen */}
                                                        {/* <div className="container-fluid deposit-section px-0">
                                                            <div className="container px-0">
                                                            <div className="row justify-content-center">
                                                                <div className="col-12">
                                                                <div className="deposit-card">
                                                                    <div className="deposit-header">
                                                                    <div className="row align-items-center gy-3">
                                                                        <div className="col-auto">
                                                                        <div className="coin-img">
                                                                            <img src="./assets/img/stacking/dAppCircle.png" alt="" className='h-100 w-100'/>
                                                                        </div>
                                                                        </div>
                                                                        <div className="col-auto ps-0">
                                                                        <div className="d-flex flex-column gap-1">
                                                                            <div className="upper-txt d-flex align-items-center gap-1">
                                                                                My DGW <span className='upper-span d-flex'>
                                                                                <img src="./assets/img/stacking/questionS.png" alt="" className='h-100 w-100' />
                                                                            </span>
                                                                            </div>
                                                                            <div className="middle-txt">
                                                                            231.91242141 <span>DGW</span>
                                                                            </div>
                                                                            <div className="lower-txt">
                                                                            $4.88
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                        <div className="col">
                                                                        <div className="buy-btn mx-auto">
                                                                            Buy DGW
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    <div className="lineDivider my-3 my-md-4"></div>
                                                                    <div className="deposit-body mt-lg-5 mt-3">
                                                                    <div className="row gy-4">
                                                                        <div className="col-6">
                                                                        <div className="row">
                                                                            <div className="col-auto">
                                                                            <div className="coin-img">
                                                                                <img src="./assets/img/stacking/table02.png" alt="" className='h-100 w-100'/>
                                                                            </div>
                                                                            </div>
                                                                            <div className="col px-0">
                                                                            <div className="upper-txt">
                                                                                5.39013057 <span>
                                                                                    BTC
                                                                                </span>
                                                                            </div>
                                                                            <div className="lower-txt">
                                                                                $380,050
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                        <div className="row">
                                                                            <div className="col-auto">
                                                                            <div className="coin-img">
                                                                                <img src="./assets/img/stacking/table03.png" alt="" className='h-100 w-100'/>
                                                                            </div>
                                                                            </div>
                                                                            <div className="col px-0">
                                                                            <div className="upper-txt">
                                                                                5.39013057 <span>
                                                                                    ETH
                                                                                </span>
                                                                            </div>
                                                                            <div className="lower-txt">
                                                                                $380,050
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                        <div className="row">
                                                                            <div className="col-auto">
                                                                            <div className="coin-img">
                                                                                <img src="./assets/img/stacking/table01.png" alt="" className='h-100 w-100'/>
                                                                            </div>
                                                                            </div>
                                                                            <div className="col px-0">
                                                                            <div className="upper-txt">
                                                                                5.39013057 <span>
                                                                                    USDT
                                                                                </span>
                                                                            </div>
                                                                            <div className="lower-txt">
                                                                                $380,050
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                        <div className="row">
                                                                            <div className="col-auto">
                                                                            <div className="coin-img">
                                                                                <img src="./assets/img/stacking/table05.png" alt="" className='h-100 w-100'/>
                                                                            </div>
                                                                            </div>
                                                                            <div className="col px-0">
                                                                            <div className="upper-txt">
                                                                                5.39013057 <span>
                                                                                    TRX
                                                                                </span>
                                                                            </div>
                                                                            <div className="lower-txt">
                                                                                $380,050
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                        <div className="row">
                                                                            <div className="col-auto">
                                                                            <div className="coin-img">
                                                                                <img src="./assets/img/stacking/table04.png" alt="" className='h-100 w-100'/>
                                                                            </div>
                                                                            </div>
                                                                            <div className="col px-0">
                                                                            <div className="upper-txt">
                                                                                5.39013057 <span>
                                                                                    BNB
                                                                                </span>
                                                                            </div>
                                                                            <div className="lower-txt">
                                                                                $380,050
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                        <div className="dollar-Btn">
                                                                            <div className="row">
                                                                            <div className="col-auto">
                                                                                <div className="coin-img">
                                                                                <img src="./assets/img/stacking/table06.png" alt="" className='h-100 w-100'/>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col px-0 px-sm-2">
                                                                                <div className="dollartxt">
                                                                                Total: <span>$0.00</span>
                                                                                </div>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                    <div className="deposit-footer">
                                                                    <button className="deposit-btn">
                                                                        deposit and stake
                                                                    </button>
                                                                    <div className="row justify-content-between mt-2">
                                                                        <div className="col-auto">
                                                                        <div className="earned-txt">
                                                                            Earned for all time:
                                                                        </div>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                        <div className="amount-txt">
                                                                            $0.00
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div> */}

                                                        <div className="darkBox pb-4">
                                                            <div className="row mx-0">
                                                                <div className="col-6 px-0">
                                                                    <div className="row mx-0">
                                                                        <div className="col-auto px-0"><div className="icons"><img src="./assets/img/stacking/crypto.png" alt="" /></div></div>
                                                                        <div className="col pe-0">
                                                                            <div className="textwhite">5.39013057 <span>BTC</span></div>
                                                                            <div className="textgray">$380,050</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 px-0">
                                                                    <div className="row mx-0">
                                                                        <div className="col-auto px-0"><div className="icons"><img src="./assets/img/stacking/ethereum.png" alt="" /></div></div>
                                                                        <div className="col pe-0">
                                                                            <div className="textwhite">5.39013057 <span>ETH</span></div>
                                                                            <div className="textgray">$380,050</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 px-0 py-1">
                                                                    <div className="row mx-0">
                                                                        <div className="col-auto px-0"><div className="icons"><img src="./assets/img/stacking/usdt.png" alt="" /></div></div>
                                                                        <div className="col pe-0">
                                                                            <div className="textwhite">5.39013057 <span>USDT</span></div>
                                                                            <div className="textgray">$380,050</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 px-0 py-1">
                                                                    <div className="row mx-0">
                                                                        <div className="col-auto px-0"><div className="icons"><img src="./assets/img/stacking/trx.png" alt="" /></div></div>
                                                                        <div className="col pe-0">
                                                                            <div className="textwhite">5.39013057  <span>TRX</span></div>
                                                                            <div className="textgray">$380,050</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 px-0">
                                                                    <div className="row mx-0">
                                                                        <div className="col-auto px-0"><div className="icons"><img src="./assets/img/stacking/bnb.png" alt="" /></div></div>
                                                                        <div className="col pe-0">
                                                                            <div className="textwhite">5.39013057 <span>BNB</span></div>
                                                                            <div className="textgray">$380,050</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 px-0">
                                                                    <div className="row mx-0">
                                                                        <div className="col-12 px-0"><div className="iconsgroup"><img src="./assets/img/stacking/groupoficons.png" alt="" /></div></div>
                                                                        <div className="col-12 ps-0">
                                                                            <Link to='' className="anchorstcking d-block mt-2">View all 33 staking items <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"></path></svg></Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-sm-3 col-2 px-0 d-flex align-items-end">
                                                        <div className="coinsImg"><img src="./assets/img/stacking/bitCoins.png" alt="" /></div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-12 ">
                            <div className="row mx-0">
                                <div className="col-12 d-xl-none d-block mb-4 px-0">
                                    <div className="row mx-0 mt-3">
                                        <div className="col-6"><button className='dgwBtns justify-content-center'><img src="./assets/img/stacking/aboutStacking.png" alt="" />About Staking</button></div>
                                        <div className="col-6 px-0"><button className='dgwBtns justify-content-center'><img src="./assets/img/stacking/dAppCircle.png" alt="" />About $DMBL</button></div>
                                        <div className="col-6 pe-0 mt-3"><button className='dgwBtns justify-content-center dgwBtnsCol'><img src="./assets/img/stacking/calculator.png" alt="" />Calculate</button></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mx-0">
                                <div className='col-12'>
                                    <div className="boxRyt">
                                        <div className="row">
                                            <div className="col-12 pt-5">
                                                <div className="rytImg"><img src="./assets/img/stacking/DBL.png" alt="" /></div>
                                            </div>
                                            <div className="col-12 pt-5">
                                                <div className="rytTxt">Start earning up to 50% APY in $DMBL staking.</div>
                                            </div>
                                            <div className="col-12 pt-3">
                                                <div className="rytSubTxt">Stake $DMBL and receive daily multi-currency payouts in USDT, BTC, ETH, BNB, and TRX, or in $DMBL <Link to='to'></Link></div>
                                            </div>
                                            <div className="seprator my-3"></div>
                                            <div className="col-12">
                                                <div className="row mx-0">
                                                    <div className="col-6 poolBox border-end-0 px-0">
                                                        <div className="poolLight">Total paid
                                                        </div>
                                                        <div className="poolNumber">$122.3M</div>
                                                    </div>
                                                    <div className="col-6 poolBox border-end-0 px-0">
                                                        <div className="poolLight">Staking participants
                                                        </div>
                                                        <div className="poolNumber">34K+</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 mt-5 d-flex justify-content-center">
                                                <button className='stakeBTn'>Stake $DMBL Now!</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 py-4">
                            <div>
                                <div className="tabsWrapper">
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-between">
                                        <div className='d-flex gap-lg-3 gap-2'>
                                            <button className={activeTab === 1 ? 'active' : 'tabsBtns'} onClick={() => handleTabClick(1)}>Total Payouts</button>
                                            <button className={activeTab === 2 ? 'active' : 'tabsBtns'} onClick={() => handleTabClick(2)}>My Payouts</button>
                                        </div>
                                        <div className=''>
                                        <Dropdown>
                                            <Dropdown.Toggle className='dropboxouter' variant="success" id="dropdown-basic">
                                                <div className='dropwrapper'>
                                                    10 <svg className='' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg>
                                                </div>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className='dropList'>
                                                <Dropdown.Item className='cursor-pointer dropListing' href="#/action-1">10</Dropdown.Item>
                                                <Dropdown.Item className='cursor-pointer dropListing' href="#/action-2">10</Dropdown.Item>
                                                <Dropdown.Item className='cursor-pointer dropListing' href="#/action-3">10</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="tab-content tabContentWrapper">
                                            {activeTab === 1 && <div>
                                                <div className="row mt-4">
                                                    <div className="col-12 tableResponse">
                                                        <table className='w-100 tableBox'>
                                                            <thead className='tableHead'>
                                                                <tr>
                                                                    <th className='tdwidth ps-4'>DATE</th>
                                                                    <th className='tdwidth text-center'>STAKED TOKENS</th>
                                                                    <th className='tdwidth text-center'>DISTRIBUTED</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className='tableBody'>
                                                                <tr className='trBack'>
                                                                    <td className='tableData ps-4'>03/26/24</td>
                                                                    <td className='tableData text-center'><div><img className='me-2 mb-1' src="./assets/img/stacking/DBL.png" alt="" />$62,164,482.24</div></td>
                                                                    <td className='tableData text-center'>
                                                                        <div className='d-flex align-items-center justify-content-center'>
                                                                            <img className='me-2' src="./assets/img/stacking/doller.png" alt="" />$62,164,482.24 
                                                                            <span>
                                                                                <Dropdown>
                                                                                <Dropdown.Toggle className='questiondropwrapper p-0' variant="success" id="dropdown-basic">
                                                                                    <img className='ms-2 mb-1 pe-2' src="./assets/img/stacking/question.png" alt="" />
                                                                                </Dropdown.Toggle>

                                                                                <Dropdown.Menu className='dropMenuWrapper'>
                                                                                    <Dropdown.Item className='dropmenuList' href="#"><img src="./assets/img/stacking/table02.png" alt="" />$52,704.08 <span>BTC</span></Dropdown.Item>
                                                                                    <Dropdown.Item className='dropmenuList' href="#"><img src="./assets/img/stacking/table03.png" alt="" />$52,704.08 <span>ETH</span></Dropdown.Item>
                                                                                    <Dropdown.Item className='dropmenuList' href="#"><img src="./assets/img/stacking/table01.png" alt="" />$52,704.08 <span>USDT</span></Dropdown.Item>
                                                                                    <Dropdown.Item className='dropmenuList' href="#"><img src="./assets/img/stacking/table05.png" alt="" />$52,704.08 <span>TRX</span></Dropdown.Item>
                                                                                    <Dropdown.Item className='dropmenuList' href="#"><img src="./assets/img/stacking/table04.png" alt="" />$52,704.08 <span>BNB</span></Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr className='trBack'>
                                                                    <td className='tableData ps-4'>03/26/24</td>
                                                                    <td className='tableData text-center'><div><img className='me-2 mb-1' src="./assets/img/stacking/DBL.png" alt="" />$62,164,482.24</div></td>
                                                                    <td className='tableData text-center'><div><img className='me-2 mb-1' src="./assets/img/stacking/doller.png" alt="" />$62,164,482.24 <img className='ms-2 mb-1 pe-2' src="./assets/img/stacking/question.png" alt="" /></div></td>                                                                </tr>
                                                                <tr className='trBack'>
                                                                    <td className='tableData ps-4'>03/26/24</td>
                                                                    <td className='tableData text-center'><div><img className='me-2 mb-1' src="./assets/img/stacking/DBL.png" alt="" />$62,164,482.24</div></td>
                                                                    <td className='tableData text-center'><div><img className='me-2 mb-1' src="./assets/img/stacking/doller.png" alt="" />$62,164,482.24 <img className='ms-2 mb-1 pe-2' src="./assets/img/stacking/question.png" alt="" /></div></td>                                                                </tr>
                                                                <tr className='trBack'>
                                                                    <td className='tableData ps-4'>03/26/24</td>
                                                                    <td className='tableData text-center'><div><img className='me-2 mb-1' src="./assets/img/stacking/DBL.png" alt="" />$62,164,482.24</div></td>
                                                                    <td className='tableData text-center'><div><img className='me-2 mb-1' src="./assets/img/stacking/doller.png" alt="" />$62,164,482.24 <img className='ms-2 mb-1 pe-2' src="./assets/img/stacking/question.png" alt="" /></div></td>                                                                </tr>
                                                                <tr className='trBack'>
                                                                    <td className='tableData ps-4'>03/26/24</td>
                                                                    <td className='tableData text-center'><div><img className='me-2 mb-1' src="./assets/img/stacking/DBL.png" alt="" />$62,164,482.24</div></td>
                                                                    <td className='tableData text-center'><div><img className='me-2 mb-1' src="./assets/img/stacking/doller.png" alt="" />$62,164,482.24 <img className='ms-2 mb-1 pe-2' src="./assets/img/stacking/question.png" alt="" /></div></td>                                                                </tr>
                                                                <tr className='trBack'>
                                                                    <td className='tableData ps-4'>03/26/24</td>
                                                                    <td className='tableData text-center'><div><img className='me-2 mb-1' src="./assets/img/stacking/DBL.png" alt="" />$62,164,482.24</div></td>
                                                                    <td className='tableData text-center'><div><img className='me-2 mb-1' src="./assets/img/stacking/doller.png" alt="" />$62,164,482.24 <img className='ms-2 mb-1 pe-2' src="./assets/img/stacking/question.png" alt="" /></div></td>                                                                </tr>
                                                                <tr className='trBack'>
                                                                    <td className='tableData ps-4'>03/26/24</td>
                                                                    <td className='tableData text-center'><div><img className='me-2 mb-1' src="./assets/img/stacking/DBL.png" alt="" />$62,164,482.24</div></td>
                                                                    <td className='tableData text-center'><div><img className='me-2 mb-1' src="./assets/img/stacking/doller.png" alt="" />$62,164,482.24 <img className='ms-2 mb-1 pe-2' src="./assets/img/stacking/question.png" alt="" /></div></td>
                                                                </tr>
                                                                <tr className='trBack'>
                                                                    <td className='tableData ps-4'>03/26/24</td>
                                                                    <td className='tableData text-center'><div><img className='me-2 mb-1' src="./assets/img/stacking/DBL.png" alt="" />$62,164,482.24</div></td>
                                                                    <td className='tableData text-center'><div><img className='me-2 mb-1' src="./assets/img/stacking/doller.png" alt="" />$62,164,482.24 <img className='ms-2 mb-1 pe-2' src="./assets/img/stacking/question.png" alt="" /></div></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>}
                                            {activeTab === 2 && <div>Content for Tab 2</div>}
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        </>
    )
}

export default DGWStacking