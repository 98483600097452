import React, { useEffect, useState } from 'react'
import { getAPIAuth } from '../../services/apiInstance'
import OurPartnerCard from './components/OurPartnerCard'

const Sponsership = () => {
  const [getData, setGetData] = useState('')

  const getPartnerApi = async () => {
      try {
          const res = await getAPIAuth('get-PartnersData')
          setGetData(res.data?.data)
          
      } catch (error) {
          
      }
  }
  useEffect(() => {
      getPartnerApi()
  }, [])
  return (
    <>
      <div className="row">
        {
            Array.isArray(getData) && getData?.map((item, index) => (
                <OurPartnerCard cardInfo={item} index={index} key={item?.id} />
            ))
        }
      </div>
    </>
  )
}

export default Sponsership