import React, { useState } from 'react'
import AllProducts from './components/AllProducts'
import StackingHistory from './components/StackingHistory'
import { useAuth } from '../../../../hooks/useAuth'
import NoData from '../NoData'
import ActivePositions from './components/ActivePositions'

const ProductTable = () => {
  const [activeTab, setActiveTab] = useState('allProducts')
  const {token} = useAuth()
  return (
    <div className="my-5">
      <div className="stackingTabs">
        <button onClick={()=>setActiveTab('allProducts')} className={`stackingTab ${activeTab === 'allProducts' ? 'active' : ''}`}>All products </button>
        {
          token ? 
            <>
              <button onClick={()=>setActiveTab('activePosition')} className={`stackingTab ${activeTab === 'activePosition' ? 'active' : ''}`}>Active positions</button>
              <button onClick={()=>setActiveTab('StackingHistory')} className={`stackingTab ${activeTab === 'StackingHistory' ? 'active' : ''}`}>Staking History</button>
            </>
          : ''
        }
      </div>
      {
        activeTab === 'allProducts' ? <AllProducts/> : 
        activeTab === 'StackingHistory' ? <StackingHistory/> : 
        activeTab === 'activePosition' ? <ActivePositions/> : ''

      }
    </div>
  )
}

export default ProductTable