import React, { useEffect, useRef, useState } from "react";
import ChatRulesModal from "./ChatRulesModal";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import EmojiPicker from "emoji-picker-react";
import { socket } from "../../services/socket";
import UserInfoModal from "./UserInfoModal";
import { useTranslation } from "react-i18next";
import { useChat } from "../../hooks/useChat";
import moment from "moment";
import PinnedIcon from "./../../assets/images/chat-section/pinned.svg";
import Emoji from "./../../assets/images/chat-section/emoji.svg";
import BitcoinIcon from "./../../assets/images/chat-section/bitcoin-icon.svg";
import TrophyIcon from "./../../assets/images/chat-section/trophy.svg";
import DropIcon from "./../../assets/images/chat-section/drop-icon.svg";
import profileImg from "./../../assets/images/chat-section/profile-img.svg";

let cursorRef = null;
const MessageOffcanvas = () => {
  const [globalChat, setGlobalChat] = useState([]);
  const [winnerDropDown, showWinnerDropDown] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [messageLoading, setMessageLoading] = useState(false);
  const [isEmoji, setIsEmoji] = useState(false);
  const userDetails = useSelector((state) => state.userDetails);
  const inputRef = useRef(null);
  const lastMsgRef = useRef(null);
  const [selectedUser, setSelectedUser] = useState({});
  const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);
  const emojiContainerRef = useRef(null);
  const emojiIconRef = useRef(null);
  const emojiDiv = document.getElementById("emojiDiv");
  const { t } = useTranslation();
  const chatOffcanvas = t("chatOffcanvas", { returnObjects: true });
  const { isChatOpen, setIsChatOpen } = useChat();

  console.log("globalChat", globalChat);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        emojiContainerRef?.current?.contains(event?.target) ||
        emojiIconRef?.current?.contains(event?.target)
      ) {
        setIsEmoji(true);
      } else {
        setIsEmoji(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isEmoji]);

  // adding emoji
  const handleEmojiClick = (event, emojiObject) => {
    const emoji = emojiObject.emoji;
    const cursorPosition = inputRef.current.selectionStart;
    if (cursorPosition !== 0) {
      cursorRef = cursorPosition;
    }
    if (cursorRef) {
      const newValue =
        message.slice(0, cursorRef) + emoji + message.slice(cursorRef);
      setMessage(newValue);
      inputRef.current.selectionStart = cursorRef + emoji?.length;
      inputRef.current.selectionEnd = cursorRef + emoji?.length;
    } else {
      const newValue =
        message.slice(0, cursorPosition) +
        emoji +
        message.slice(cursorPosition);
      setMessage(newValue);
      inputRef.current.selectionStart = cursorPosition + emoji?.length;
      inputRef.current.selectionEnd = cursorPosition + emoji?.length;
    }

    cursorRef = inputRef.current.selectionStart;
  };
  //send chat
  const sendChatGlobal = async () => {
    if (!!!message) {
      setMessageLoading(false);
      return;
    }
    // send message using emit
    socket.emit("sendMessage", { message: message, userId: userDetails?.id });
    setMessage("");
    // we will the updated chats (for sender)
    setGlobalChat((pre) => [
      ...pre,
      {
        message: message,
        messageType: "",
        _id: userDetails?.id,
        userData: {
          image: userDetails?.image,
          username: userDetails?.username,
          _id: userDetails?.id,
        },
      },
    ]);
    setMessageLoading(false);
  };
  // get chats and update chat
  useEffect(() => {
    try {
      // this is for global chat only
      // get all the chat in the room with .on
      socket.on("roomidCreated", (data) => {
        // we will get all the chats
        setGlobalChat(data.prevChats);
      });

      // receive updated chat
      socket.on("reciviedMessage", (data) => {
        // we will the updated chats (not for sender, for others only)
        setGlobalChat((list) => [...list, data]);
        setIsLoading(false);
      });
    } catch (error) {
      // handle errors
    } finally {
      setIsLoading(false);
    }
  }, []);
  // to scroll the new message into view
  useEffect(() => {
    if (lastMsgRef?.current) {
      lastMsgRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [globalChat?.length]);

  const userInfoDetails = async (user) => {
    setSelectedUser(user);
    setIsUserInfoOpen(true);
  };
  // showMsgOffcanvas remove this class from body
  return (
    <>
      <div
        className={`offcanvas offcanvas-end msgoffcanvas ${
          isChatOpen ? "show" : ""
        }`}
        style={{ backgroundColor: "#161a25" }}
        tabIndex={-1}
        id="message"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header msgHeader p-0">
          <div className="row w-100 align-items-center">
            <div className="col-auto">
              <div className="msgImg d-flex align-items-center">
                {/* <span className="d-flex align-items-center justify-content-center msgicon">
                                    <img src="assets/img/sidebar/chat.png" alt="message" className="h-100 w-100 img-fluid" />
                                </span> */}

                {chatOffcanvas.chat}
              </div>
            </div>
            <div className="col text-center px-0">
              <span
                className="chatrules cursor-pointer"
                data-bs-toggle="modal"
                data-bs-target="#chatrules"
              >
                {/* chat rules */}
                {/* {chatOffcanvas.CHAT_RULES} */}
              </span>
            </div>
            <div className="col-auto pe-0">
              <div className="d-flex align-items-center gap-2 h-100">
                <button
                  type="button"
                  className="btn-close offcanvasclose p0 shadow-none border-0 d-flex align-items-center justify-content-center"
                  // data-bs-dismiss="offcanvas"
                  // aria-label="Close"

                  data-bs-toggle="modal"
                  data-bs-target="#chatrules"
                  // // onClick={() => document.body.classList.remove("showMsgOffcanvas")}
                >
                  <img
                    src="assets/img/info.png"
                    alt="close"
                    className="h-100 w-100 img-fluid"
                  />
                </button>
                <button
                  type="button"
                  className="btn-close shadow-none border-0 closebtn pe-0 d-flex align-items-center justify-content-center"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  onClick={() => {
                    setIsChatOpen(false);
                    document.body.classList.remove("showMsgOffcanvas");
                  }}
                >
                  <img
                    src="assets/img/offcanvasclose.png"
                    alt="close"
                    className="h-100 w-100 img-fluid"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-body d-flex flex-column msgBody position-relative p-0">
          <div className="pinned-comment-section">
            <div className="pinned-side-border ">
              <div className="d-flex align-items-center gap-2 mb-3">
                <div>
                  <img src={PinnedIcon} alt="" />
                </div>
                <div>
                  <h6 className="pinned-msg mb-0">
                    Pinned by <span>Username</span>{" "}
                  </h6>
                </div>
              </div>
              <div className="pinned-comment collapse" id="collapseExample">
                <p className="mb-0">PRIMATE KING MEGAWAYS </p>
                <p>€17.5K Red Tiger Primate King Megaways™</p>
                <div>
                  <p className="mb-0">Duration</p>
                  <p className="mb-0">26 Apr 2024, 09:00 CET </p>
                  <p> 03 May 2024,18:00 CET</p>
                </div>
                <div>
                  <p className="mb-0">General Rules </p>
                  <p>
                    The players who achieve the 3 biggest win multipliers on
                    Primate King Megaways™ within the promotional period will
                    win the cash prizes.
                  </p>
                </div>
                <p>Minimum bet of 1 Euro or equivalent per spin.</p>
                <p className="mb-0">
                  Read the complete rules & details on the PROMOTIONS PAGE
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center mb-3 collapsed"  data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
              <svg
                stroke="#fff"
                fill="#fff"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="20px"
                width="20px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M256 217.9L383 345c9.4 9.4 24.6 9.4 33.9 0 9.4-9.4 9.3-24.6 0-34L273 167c-9.1-9.1-23.7-9.3-33.1-.7L95 310.9c-4.7 4.7-7 10.9-7 17s2.3 12.3 7 17c9.4 9.4 24.6 9.4 33.9 0l127.1-127z"></path>
              </svg>
            </div>
            <button
              className="position-absolute cross-icon border-0 bg-transparent"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#confirmModal"
            >
              <svg
                stroke="currentColor"
                fill="#fff"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="25px"
                width="25px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path>
              </svg>
            </button>
            {/* confirm modal */}
            <div
              class="modal fade"
              id="confirmModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body p-5">
                    <div className="text-center">
                      <h3 className="heading-text mb-3">Don’t show again</h3>
                      <p className="desc-text mb-3">
                        Message will not be displayed at the top.
                      </p>
                      <div className="d-flex align-items-center justify-content-center gap-3">
                        {/* <button className="cancle-btn">Cancle</button>
                        <button className="ok-btn">OK</button> */}
                        <button className="ok-btn">Coming soon</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           

            {winnerDropDown ? (
              <div className="trophy-dropdown ">
                <div className="p-3">
                  <h6 className="heading text-center mb-3">
                    BIGGEST WINNER TODAY
                  </h6>
                  <div className="row w-100 mx-auto mb-2">
                    <div className="col-2">
                      <div className="h-100 d-flex align-items-center justify-content-center">
                        <svg
                          stroke="#FFEA00"
                          fill="#FFEA00"
                          stroke-width="0"
                          viewBox="0 0 512 512"
                          height="15px"
                          width="15px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M463 192H315.9L271.2 58.6C269 52.1 262.9 48 256 48s-13 4.1-15.2 10.6L196.1 192H48c-8.8 0-16 7.2-16 16 0 .9.1 1.9.3 2.7.2 3.5 1.8 7.4 6.7 11.3l120.9 85.2-46.4 134.9c-2.3 6.5 0 13.8 5.5 18 2.9 2.1 5.6 3.9 9 3.9 3.3 0 7.2-1.7 10-3.6l118-84.1 118 84.1c2.8 2 6.7 3.6 10 3.6 3.4 0 6.1-1.7 8.9-3.9 5.6-4.2 7.8-11.4 5.5-18L352 307.2l119.9-86 2.9-2.5c2.6-2.8 5.2-6.6 5.2-10.7 0-8.8-8.2-16-17-16z"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="col-5 ">
                      <div className="d-flex align-items-center gap-3 h-100 justify-content-start">
                        <div className="profile-img">
                          <img src={profileImg} alt="" />
                        </div>
                        <div className="profile-name">Username</div>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="profile-income">$307855.36</div>
                    </div>
                  </div>
                  <div className="row w-100 mx-auto mb-2">
                    <div className="col-2">
                      <div className="h-100 d-flex align-items-center justify-content-center">
                        <svg
                          stroke="#FFEA00"
                          fill="#FFEA00"
                          stroke-width="0"
                          viewBox="0 0 512 512"
                          height="15px"
                          width="15px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M463 192H315.9L271.2 58.6C269 52.1 262.9 48 256 48s-13 4.1-15.2 10.6L196.1 192H48c-8.8 0-16 7.2-16 16 0 .9.1 1.9.3 2.7.2 3.5 1.8 7.4 6.7 11.3l120.9 85.2-46.4 134.9c-2.3 6.5 0 13.8 5.5 18 2.9 2.1 5.6 3.9 9 3.9 3.3 0 7.2-1.7 10-3.6l118-84.1 118 84.1c2.8 2 6.7 3.6 10 3.6 3.4 0 6.1-1.7 8.9-3.9 5.6-4.2 7.8-11.4 5.5-18L352 307.2l119.9-86 2.9-2.5c2.6-2.8 5.2-6.6 5.2-10.7 0-8.8-8.2-16-17-16z"></path>
                        </svg>
                      </div>
                    </div>
                    <div className="col-5 ">
                      <div className="d-flex align-items-center gap-3 h-100 justify-content-start">
                        <div className="profile-img">
                          <img src={profileImg} alt="" />
                        </div>
                        <div className="profile-name">Username</div>
                      </div>
                    </div>
                    <div className="col-5">
                      <div className="profile-income">$307855.36</div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className="row msgContent flex-column g-0">
            {isLoading ? (
              <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="dark" size="sm" />
              </div>
            ) : globalChat?.length === 0 ? (
              <div className="w-100 h-100">
                <h6 className="text-light"> No data available</h6>
              </div>
            ) : (
              globalChat?.map((item, index) => (
                <div
                  className="col-12"
                  key={item._id}
                  ref={index === globalChat.length - 1 ? lastMsgRef : null}
                >
                  <span className="msgBtn text-decoration-none d-flex align-items-start gap-3">
                    <span
                      onClick={() => {
                        userInfoDetails(item);
                      }}
                      className="userimg d-flex align-items-center overflow-hidden justify-content-center rounded-circle cursor-pointer"
                    >
                      <img
                        src={
                          item?.userData?.image
                            ? item?.userData?.image
                            : "assets/img/chatUser.png"
                        }
                        alt="user"
                        className="h-100 w-100 img-fluid"
                      />
                    </span>
                    <div className="usermsg">
                      <div
                        className="username cursor-pointer mb-3"
                        onClick={() => {
                          userInfoDetails(item);
                        }}
                      >
                        {item?.userData?.username
                          ? item?.userData?.username
                          : "Guest"}{" "}
                        {moment(item?.createdAt).format("lll")}
                      </div>
                      <div className="itemMsg">{item.message}</div>
                    </div>
                  </span>
                </div>
              ))
            )}
          </div>
          {isEmoji ? (
            <div
              ref={emojiContainerRef}
              className="sticky-bottom mt-auto w-100 border border-light rounded-top"
              style={{ backgroundColor: "#161a25", height: "250px", zIndex: 9 }}
            >
              <EmojiPicker
                emojiStyle="native"
                width={"100%"}
                height={"100%"}
                onEmojiClick={(item) => handleEmojiClick("e", item)}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
         
        <div className={`offcanvasFooter`}>
          <div className="d-flex align-items-center gap-3">
            <div className="input-group InputMain align-items-center">
              <input
                type="text"
                className="form-control sendInput shadow-none border-0 bg-transparent"
                placeholder={`${chatOffcanvas.Write_a_message}`}
                aria-label={`${chatOffcanvas.Write_a_message}`}
                ref={inputRef}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  if (cursorRef !== null || cursorRef !== undefined) {
                    cursorRef = null;
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setMessageLoading(true);
                    sendChatGlobal();
                  } else {
                    setMessage(e.target.value);
                    if (cursorRef !== null || cursorRef !== undefined) {
                      cursorRef = null;
                    }
                  }
                }}
              />
              <div className="plus-icon">
                <svg
                  stroke="currentColor"
                  fill="#fff"
                  stroke-width="0"
                  viewBox="0 0 448 512"
                  height="20px"
                  width="20px"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
                </svg>
              </div>
              <span className="btn d-flex text-decoration-none align-items-center justify-content-center bg-transparent border-0 shadow-none smileFace rounded-circle overflow-hidden p-0">
                <img
                  ref={emojiIconRef}
                  src={Emoji}
                  alt="smileface"
                  className="h-100 w-100 img-fluid"
                  onClick={() => setIsEmoji(!isEmoji)}
                />
              </span>
            </div>
            <button
              className="btn sendBtn shadow-none border-0 p-0 d-flex align-items-center justify-content-center"
              type="button"
              onClick={() => {
                setMessageLoading(true);
                sendChatGlobal();
              }}
            >
              <span className="d-flex align-items-center justify-content-center sendImg">
                {messageLoading ? (
                  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                    <Spinner animation="border" variant="light" size="sm" />
                  </div>
                ) : (
                  <img
                    src="assets/img/send.png"
                    alt="send"
                    className="h-100 w-100 img-fluid"
                  />
                )}
              </span>
            </button>
          </div>
          <div className="row mt-3">
            <div className="col-4">
              <div class="dropdown">
                <button
                  class="dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Sports
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <a class="dropdown-item" href="#">
                      Action
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Another action
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">
                      Something else here
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-2">
              <div
                className="d-flex h-100 justify-content-center align-items-center"
                data-bs-toggle="modal"
                data-bs-target="#dropModal"
                type="button"
              >
                <img src={DropIcon} alt="" />
              </div>
            </div>
            {/* drop modal */}
            <div
              class="modal fade"
              id="dropModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog  modal-dialog-centered modal-lg modal-dialog-scrollable">
                <div class="modal-content">
                  <div class="modal-header">
                    <h1 class="modal-title" id="exampleModalLabel">
                      Coindrop
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <div>
                      <div>
                        <div className="d-flex align-items-center justify-content-between">
                          <h6 className="dropModal-heading mb-3">Amount</h6>
                          <h6 className="dropModal-heading mb-3">
                            Min: 0.00001581 BTC
                          </h6>
                        </div>
                        <div className="input-box mb-4">
                          <div className="row">
                            <div className="col-8 px-0">
                              <input
                                type="text"
                                aria-label="First name"
                                class="form-control"
                                placeholder="0.00001581"
                              />
                            </div>
                            <div className="col-4 select-box px-0">
                              <select
                                class="form-select"
                                aria-label="Default select example"
                              >
                                <option selected>
                                  <img src={BitcoinIcon} alt="" /> BTC
                                </option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <h6 className="dropModal-heading mb-3">
                            Available: 0.00
                          </h6>
                          <h6 className="dropModal-heading mb-3">
                            Locked funds: <span>0.00</span>
                          </h6>
                        </div>
                        <div>
                          <h6 className="dropModal-heading mb-3">
                            Number of people
                          </h6>
                          <div className="input-box mb-4 position-relative">
                            <div className="row">
                              <div className="col-12 px-0">
                                <input
                                  type="text"
                                  aria-label="First name"
                                  class="form-control"
                                  placeholder="50"
                                />
                              </div>
                            </div>
                            <div className="number position-absolute">
                              <h6 className=" mb-0 d-flex align-items-center justify-content-center h-100">
                                2/100
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h6 className="dropModal-heading mb-3">
                            Message (Optional)
                          </h6>
                          <div className="input-box mb-4 position-relative rounded-3">
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                            ></textarea>
                            <div className="text-number position-absolute">
                              <h6 className=" mb-0 d-flex align-items-center justify-content-center h-100">
                                2/100
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h6 className="total-btc text-center mb-4">
                            0.00001578 <span>BTC</span>
                          </h6>
                        </div>
                        <div className="d-flex justify-content-center">
                          {/* <button className="drop-btn">Start Coindrop</button> */}
                          <button  disabled className="drop-btn">Coming soon</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="d-flex h-100 justify-content-center align-items-center">
                <h6 className="slash">/_</h6>
              </div>
            </div>
            <div className="col-2">
              <div className="d-flex h-100 justify-content-center align-items-center">
                <img src={BitcoinIcon} alt="" />
              </div>
            </div>
            <div className="col-2">
              <div
                onClick={() => showWinnerDropDown((prv) => !prv)}
                className=" cursor-pointer d-flex h-100 justify-content-center align-items-center"
              >
                <img src={TrophyIcon} alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* confirm modal */}
        <div
              class="modal fade"
              id="confirmModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body p-5">
                    <div className="text-center">
                      <h3 className="heading-text mb-3">Don’t show again</h3>
                      <p className="desc-text mb-3">
                        Message will not be displayed at the top.
                      </p>
                      <div className="d-flex align-items-center justify-content-center gap-3">
                        {/* <button className="cancle-btn">Cancle</button>
                        <button className="ok-btn">OK</button> */}
                        <button className="ok-btn">Comming soon</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
      <ChatRulesModal />
      <UserInfoModal
        isOpen={isUserInfoOpen}
        setIsOpen={setIsUserInfoOpen}
        selectedUser={selectedUser}
      />
    </>
  );
};

export default MessageOffcanvas;
