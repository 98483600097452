import React from "react";
import profileUser from "./../../assets/images/profile-2/profile-username.svg";

import avatar from "./../../assets/images/profile/avatar.svg";
import Google from "./../../assets/images/profile-2/google.svg";
import Telegram from "./../../assets/images/profile-2/telegram.svg";
import Line from "./../../assets/images/profile-2/line.svg";
import Twitter from "./../../assets/images/profile-2/twitter.svg";
import Steam from "./../../assets/images/profile-2/steam.svg";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { errorToaster, succesToaster } from "../../utils/toaster";
import { fetchUserDetails } from "../../services/fetchUserDetails";
import { reducerConst } from "../../constants/storeConstants";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { postAPIAuth } from "../../services/apiInstance";

const validationSchema = Yup.object({
  username: Yup.string()
    .min(3, "Username must be at least 3 characters")
    .required("Username is required"),
});
const AccountInfo = ({ selectedTab, setSelectedTab }) => {
  const userDetails = useSelector((state) => state.userDetails);

  const dispatch = useDispatch();
  const { token } = useAuth();

  const handleChangeUser = async (username, setSubmitting) => {
    try {
      const res = await postAPIAuth(
        "user/update-username",
        {
          userId: userDetails?.id,
          userName: username,
        },
        token
      );
      if (res?.data?.success) {
        succesToaster("Name Changed Successfully");
        const data = await fetchUserDetails(token);
        if (data.data.success) {
          dispatch({
            type: reducerConst.USER_DETAILS,
            payload: data?.data?.data ? data.data.data : {},
          });
        }
      } else {
        errorToaster(res?.data?.message);
      }
    } catch (error) {
      errorToaster(error?.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="Account-info">
      <div
        onClick={() => setSelectedTab("account")}
        className={`drop_mb_tab  cursor-pointer  ${
          selectedTab === "account" ? "active" : ""
        }  d-flex align-items-center gap-3 my-4`}
      >
        <div className="dropIcon">
          <img src={profileUser} alt="" className="img-fluid" />
        </div>
        <div class="DropTxt">Account Info</div>
      </div>
      {selectedTab === "account" ? (
        <div className="profile_box">
          <div className="profile_account_info bg_main_color  p-4">
            <div className="profile-main-heading">
              <h6 className="mb-3">Profile Info</h6>
            </div>
            <div className="mt-4 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-3">
                <div className="profile-img">
                  <img src={avatar} alt="" className="h-100 w-100" />
                </div>
                <div>
                  <h6 className="sub-heading">{userDetails?.username}</h6>
                  {/* <p className="sub-desc mb-0">User ID :123456789</p> */}
                </div>
              </div>
              <div>
                <button
                  type="button"
                  className="profile-cta-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#EditUsername"
                >
                  Edit
                </button>
                {/* <!-- Modal --> */}
                <div
                  className="modal fade"
                  id="EditUsername"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                          Edit Username
                        </h1>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <Formik
                          enableReinitialize
                          initialValues={{
                            username: userDetails?.username,
                          }}
                          validationSchema={validationSchema}
                          onSubmit={(values, { setSubmitting }) => {
                            handleChangeUser(values.username, setSubmitting);
                          }}
                        >
                          {({ isSubmitting }) => (
                            <Form>
                              <div className="mb-3">
                                <label
                                  htmlFor="username"
                                  className="form-label"
                                >
                                  Username
                                </label>
                                <Field
                                  type="text"
                                  name="username"
                                  className="form-control"
                                  id="username"
                                  placeholder="Enter Username"
                                />
                                <ErrorMessage
                                  name="username"
                                  component="div"
                                  className="text-danger"
                                />
                              </div>
                              <div className="d-flex align-items-center justify-content-center">
                                <button
                                  type="submit"
                                  className="profile-cta-btn"
                                  disabled={isSubmitting}
                                >
                                  Change Username
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="profile_account_info bg_main_color mt-4  p-4">
            <div className="profile-main-heading">
              <h6 className="mb-3">Contact Info</h6>
            </div>
            <div className="mt-4 d-flex align-items-center justify-content-between">
              <div className="contact-desc">
                <h6>E-mail Verification</h6>
                <h6>
                  Verify your email address is valid and accessible by you.
                </h6>
              </div>
              <div>
                <button className="profile-cta-btn">Add</button>
              </div>
            </div>
            <div className="mt-4 d-flex align-items-center justify-content-between">
              <div className="contact-desc">
                <h6>Phone Number</h6>
                <h6>
                  Verify your phone number and you can use the phone as your
                  second login method.
                </h6>
              </div>
              <div>
                <button className="profile-cta-btn">Add</button>
              </div>
            </div>
          </div>
          <div className="profile_account_info bg_main_color mt-4  p-4">
            <div className="profile-main-heading">
              <h6 className="mb-3">Account Connections</h6>
            </div>
            <div className="py-3 d-flex align-items-center justify-content-between connection-box">
              <div className="d-flex align-items-center gap-3 ">
                <div className="connection-img d-flex align-items-center justify-content-center">
                  <img src={Google} alt="" className="img-fluid" />
                </div>
                <div>
                  <h6 className="sub-heading">Google</h6>
                  <p className="sub-desc mb-0">Not Connected</p>
                </div>
              </div>
              <div>
                <button className="profile-cta-btn">Connect</button>
              </div>
            </div>
            <div className="py-3 d-flex align-items-center justify-content-between connection-box">
              <div className="d-flex align-items-center gap-3 ">
                <div className="connection-img d-flex align-items-center justify-content-center">
                  <img src={Telegram} alt="" className="img-fluid" />
                </div>
                <div>
                  <h6 className="sub-heading">Telegram</h6>
                  <p className="sub-desc mb-0">Not Connected</p>
                </div>
              </div>
              <div>
                <button className="profile-cta-btn">Connect</button>
              </div>
            </div>
            <div className="py-3 d-flex align-items-center justify-content-between connection-box">
              <div className="d-flex align-items-center gap-3 ">
                <div className="connection-img d-flex align-items-center justify-content-center">
                  <img src={Line} alt="" className="img-fluid" />
                </div>
                <div>
                  <h6 className="sub-heading">Line</h6>
                  <p className="sub-desc mb-0">Not Connected</p>
                </div>
              </div>
              <div>
                <button className="profile-cta-btn">Connect</button>
              </div>
            </div>
            <div className="py-3 d-flex align-items-center justify-content-between connection-box">
              <div className="d-flex align-items-center gap-3 ">
                <div className="connection-img d-flex align-items-center justify-content-center">
                  <img src={Twitter} alt="" className="img-fluid" />
                </div>
                <div>
                  <h6 className="sub-heading">Twitter</h6>
                  <p className="sub-desc mb-0">Not Connected</p>
                </div>
              </div>
              <div>
                <button className="profile-cta-btn">Connect</button>
              </div>
            </div>
            <div className="py-3 d-flex align-items-center justify-content-between connection-box">
              <div className="d-flex align-items-center gap-3 ">
                <div className="connection-img d-flex align-items-center justify-content-center">
                  <img src={Steam} alt="" className="img-fluid" />
                </div>
                <div>
                  <h6 className="sub-heading">Steam</h6>
                  <p className="sub-desc mb-0">Not Connected</p>
                </div>
              </div>
              <div>
                <button className="profile-cta-btn">Connect</button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AccountInfo;
