import React from 'react'


function ComingSoon() {
    return (
        <div className='bonus-page w-100 d-flex justify-content-center align-items-center'
            style={{
                height: "calc(100vh - 115px)",
            }}
        >
            <div className="heading"
                style={{
                    color: "white",
                    fontSize: "2rem",
                    fontWeight: "500",

                }}
            >
                Coming Soon
            </div>
        </div>
    )
}

export default ComingSoon
