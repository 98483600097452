import { useRef } from "react";
import Dashboard from "./Dashboard";
import MyRewards from "./MyRewards";
import ReferralCode from "./ReferralCode";
import RateRules from "./RateRules";
import DownloadBanners from "./DownloadBanners";

const AffiliatePageAfterLogin = () => {
  const scrollRef = useRef(null);
  let isDown = false;
  let startX, scrollLeft;

  const startDragging = (e) => {
    isDown = true;
    scrollRef.current.classList.add("grabbing");
    startX = e.pageX - scrollRef.current.offsetLeft;
    scrollLeft = scrollRef.current.scrollLeft;
  };

  const stopDragging = () => {
    isDown = false;
    scrollRef.current.classList.remove("grabbing");
  };

  const whileDragging = (e) => {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };
  return (
    <>
      <div className="affiliatepagenew">
        <div className="container-fluid affiliate_after_login">
          <div className="row">
            <div className="col-12">
              <h2 className="affiliateheading">Affiliate</h2>
            </div>
            <div className="col-12 mt-3">
              <div className="affiliate_tabs">
                <div className="col-lg-11 col-md-12 col-12 px-0">
                  <nav className="tab_list rounded-pill overflow-hidden">
                    <div
                      ref={scrollRef}
                      className="nav nav-tabs flex-nowrap gap-1 overflow-auto border-0 py-2 px-3"
                      id="nav-tab"
                      role="tablist"
                      onMouseDown={startDragging}
                      onMouseLeave={stopDragging}
                      onMouseUp={stopDragging}
                      onMouseMove={whileDragging}
                      style={{
                        whiteSpace: "nowrap",
                        overflowX: "auto",
                        display: "flex",
                        flexWrap: "nowrap",
                        scrollbarWidth: "thin" /* Thin scrollbar */,
                        scrollbarColor: "#6f6f6f transparent",
                      }}
                    >
                      <button
                        class="nav-link active"
                        id="nav-dashboard-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-dashboard"
                        type="button"
                        role="tab"
                        aria-controls="nav-dashboard"
                        aria-selected="true"
                      >
                        Dashboard
                      </button>
                      <button
                        class="nav-link"
                        id="nav-rewards-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-rewards"
                        type="button"
                        role="tab"
                        aria-controls="nav-rewards"
                        aria-selected="false"
                      >
                        My Rewards
                      </button>
                      <button
                        class="nav-link"
                        id="nav-referralCode-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-referralCode"
                        type="button"
                        role="tab"
                        aria-controls="nav-referralCode"
                        aria-selected="false"
                      >
                        Referral Codes & Friends
                      </button>
                      <button
                        class="nav-link"
                        id="nav-rate-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-rate"
                        type="button"
                        role="tab"
                        aria-controls="nav-rate"
                        aria-selected="false"
                      >
                        Rate & Rules
                      </button>
                      <button
                        class="nav-link"
                        id="nav-banner-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-banner"
                        type="button"
                        role="tab"
                        aria-controls="nav-banner"
                        aria-selected="false"
                      >
                        Download Banners
                      </button>
                    </div>
                  </nav>
                </div>
                <div class="tab-content mt-4" id="nav-tabContent">
                  <Dashboard />
                  <MyRewards />
                  <ReferralCode />
                  <RateRules />
                  <DownloadBanners />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AffiliatePageAfterLogin;
