import React, { useEffect, useState } from "react";
import level1 from "./../../assets/images/promotions/level1.svg";
import level2 from "./../../assets/images/promotions/level2.svg";
import level3 from "./../../assets/images/promotions/level3.svg";
import level4 from "./../../assets/images/promotions/level4.svg";
import box from "./../../assets/images/promotions/goldbox.svg";
import box2 from "./../../assets/images/promotions/goldbox2.svg";
import box3 from "./../../assets/images/promotions/goldbox3.svg";
import box4 from "./../../assets/images/promotions/goldbox4.svg";
import girl from "./../../assets/images/promotions/banner3.png";
import football from "./../../assets/images/promotions/banner2.png";
import hockey from "./../../assets/images/promotions/banner1.png";
import king from "./../../assets/images/promotions/king.png";
import Gift180 from "./../../assets/images/promotions/gift-180.svg";
import Gift240 from "./../../assets/images/promotions/gift-240.svg";
import Gift300 from "./../../assets/images/promotions/gift-300.svg";
import Gift360 from "./../../assets/images/promotions/gift-360.svg";
import { useAuth } from "../../hooks/useAuth";
import { useAuthModal } from "../../hooks/useAuthModal";

import {
  RESPONSIVE_WIDTH,
  AUTH_MODAL_TABS,
  WALLET_MODAL_TABS,
} from "../../constants";
import { getAPI } from "../../services/apiInstance";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "../../utils/useMediaQuery";
import { useWalletModal } from "../../hooks/useWallet";
import CasinoTab from "./CasinoTab";
import SportsTab from "./SportsTab";

const Promotion = () => {
  const [activeTab, setActiveTab] = useState("casino");
  const [casinoData, setCasinoData] = useState([]);
  const [casinoPageCount, setCasinoPageCount] = useState(1);
  const [sportsPageCount, setSportsPageCount] = useState(1);
  const [casinoPage, setCasinoPage] = useState(1);
  const [sportsPage, setSportsPage] = useState(1);
  const [sportData, setSportsData] = useState([]);
  const { setWalletModalObject } = useWalletModal();
  const { token } = useAuth();
  const { setAuthModalObject } = useAuthModal();
  const [selectedTab, setSelectedTab] = useState("casino");
  const handleRegister = () => {
    setAuthModalObject((prev) => ({
      isAuthOpen: true,
      selectedTab: AUTH_MODAL_TABS.SIGN_UP,
    }));
  };

  const [isLoading, setLoading] = useState(true);
  const [archiveActive, setArchiveActive] = useState(false);
  const [imageData, setImageData] = useState();
  const { t } = useTranslation();
  // const promotionLabels = t("promotionLabels", { returnObjects: true })
  const isMobile = useMediaQuery({ maxWidth: RESPONSIVE_WIDTH.SM_SCREEN });

  const getBanner = async () => {
    try {
      const res = await getAPI(
        `banner/getBannerByFilter?type=promotion&viewType=${
          isMobile ? "mobile" : ""
        }`
      );
      if (res?.data?.success) {
        setImageData(res?.data?.data?.[0]);
      }
    } catch (error) {
      //error
    } finally {
      setLoading(false);
    }
  };

  const getCasinoPromotions = async () => {
    setLoading(true);
    try {
      const res = await getAPI(
        `get-promotion-data?isArchived=false&page=1&limit=6`
      );
      console.log({ res }, "this is response");
      if (res?.data?.success) {
        setCasinoData(res?.data?.data);
        setCasinoPageCount(res.data.pageCount);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getMoreCasinoData = async () => {
    try {
      const res = await getAPI(
        `get-promotion-data?isArchived=false&page=${casinoPage}&limit=6`
      );
      if (res?.data?.success) {
        setCasinoData((pre) => [...pre, ...res?.data?.data]);
      }
    } catch (error) {}
  };

  const getMoreSportsData = async () => {
    try {
      const res = await getAPI(
        `get-promotion-data?isArchived=true&page=${sportsPage}&limit=6`
      );
      // setData(res?.data?.data)
      if (res?.data?.success) {
        setSportsData((pre) => [...pre, ...res?.data?.data]);
      }
    } catch (error) {}
  };

  const getSportsPromotions = async () => {
    setLoading(true);
    try {
      const res = await getAPI(
        `get-promotion-data?isArchived=true&page=1&limit=6`
      );
      if (res?.data?.success) {
        setSportsData(res?.data?.data);
        // setPageCount(res.data.pageCount);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBanner();
    getCasinoPromotions();
    getSportsPromotions();
  }, []);

  useEffect(() => {
    if (casinoPage >= 2) {
      getMoreCasinoData();
    }
  }, [casinoPage]);

  useEffect(() => {
    if (sportsPage >= 2) {
      getMoreSportsData();
    }
  }, [sportsPage]);
  return (
    <>
      <div className="title d-none d-lg-flex align-items-center pt-lg-2 pb-3 pb-lg-4">
        <div className="text">PROMOTIONS</div>
      </div>

      {/* <div className={`promotion-banner px-md-5 mb-1 mb-sm-2 mb-md-3 ${token ? 'loggedIn' : ''}`}>
        {!token && (
          <button className="signupBtn d-flex" onClick={handleRegister}>
            SIGN UP
          </button>
        )}
      </div> */}

      {/* {
        isLoading ? (
          <>
          </>
        ) : (
          imageData?.image ? (
            <div
              className={`promotion-banner px-md-5 mb-1 mb-sm-2 mb-md-3 ${token ? 'loggedIn' : ''}`}
              style={{
                backgroundImage: `url(${imageData?.image})`,
              }}
            >
              {!token && (
                <button className="signupBtn d-flex" onClick={handleRegister}>
                  SIGN UP
                </button>
              )}
            </div>
          ) : (
            ''
          )
        )
      } */}

      {imageData?.image ? (
        <div className="promotionBanner">
          <div className="position-relative d-flex flex-flex-md-column flex-column-reverse">
            <div className=" banner-buttons w-100">
              <div className="row">
                <div className="col-md-4 col-12">
                  <div className="mt-4 d-flex gap-2 justify-content-md-start justify-content-center mb-lg-0 mb-4">
                    <button className="button1 text-nowrap">Deposit Now</button>
                    <button
                      className="button2 text-nowrap"
                      data-bs-toggle="modal"
                      data-bs-target="#MoreDetail"
                    >
                      More Details
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <img src={imageData?.image} alt="" className="img-fluid" />
          </div>
          <div
            class="modal fade"
            id="MoreDetail"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
              <div class="modal-content">
                <div class="modal-header">
                  <h1 class="modal-title" id="exampleModalLabel">
                    Deposit Bonus Rules
                  </h1>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div>
                    <div className="deposite-rules">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <h6 className="deposit-number mb-1">
                            1st Deposit Bonus
                          </h6>
                          <h6 className="deposit-percentage mb-1">180%</h6>
                          <p className="min-deposit mb-0">
                            Min. deposit: $10.00
                          </p>
                        </div>
                        <div>
                          <img src={Gift180} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div className="deposite-rules">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <h6 className="deposit-number mb-1">
                            1st Deposit Bonus
                          </h6>
                          <h6 className="deposit-percentage mb-1">240%</h6>
                          <p className="min-deposit mb-0">
                            Min. deposit: $10.00
                          </p>
                        </div>
                        <div>
                          <img src={Gift240} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div className="deposite-rules">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <h6 className="deposit-number mb-1">
                            1st Deposit Bonus
                          </h6>
                          <h6 className="deposit-percentage mb-1">300%</h6>
                          <p className="min-deposit mb-0">
                            Min. deposit: $10.00
                          </p>
                        </div>
                        <div>
                          <img src={Gift300} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                    <div className="deposite-rules">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <h6 className="deposit-number mb-1">
                            1st Deposit Bonus
                          </h6>
                          <h6 className="deposit-percentage mb-1">360%</h6>
                          <p className="min-deposit mb-0">
                            Min. deposit: $10.00
                          </p>
                        </div>
                        <div>
                          <img src={Gift360} alt="" className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h6 className="detail-content-1 mt-3">
                      Platform name offers an outstanding bonus for 4
                      consecutive deposits. Each deposit earns you a higher
                      bonus percentage and a higher maximum limit than the last.
                      Enjoy increasing rewards with every deposit, supporting
                      any currency of your choice. Take advantage of this
                      opportunity to maximize your benefits with BC.GAME’s
                      generous bonuses!
                    </h6>
                    <ul className="detail-list mt-3">
                      <li>
                        The first deposit earns you180%(or300%for new
                        registrations within 7 minutes), up to a maximum
                        of$20,000.00 or an equivalent amount in other
                        currencies.
                      </li>
                      <li>
                        The second deposit earns you240%, up to a maximum
                        of $40,000.00 or an equivalent amount in other
                        currencies
                      </li>
                      <li>
                        The third deposit earns you300%, up to a maximum
                        of $60,000.00 or an equivalent amount in other
                        currencies.
                      </li>
                      <li>
                        The fourth deposit earns you360%, up to a maximum
                        of $100,000.00 or an equivalent amount in other
                        currencies.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
          <div className="col-xl-4">
            <div className="text-md-start text-center">
              <div className="tittle pe-md-5 pe-0">
                Great Bonus Every Deposit
              </div>
              <div className="tittle2">UP TO 360%</div>
              <div className="mt-4 gap-2 justify-content-md-start justify-content-center mb-lg-0 mb-4 d-md-flex d-none">
                <button
                  onClick={() =>
                    setWalletModalObject((prev) => ({
                      isWalletOpen: true,
                      selectedTab: WALLET_MODAL_TABS.DEPOSIT,
                    }))
                  }
                  className="button1"
                >
                  Deposit Now
                </button>
                <button
                  className="button2"
                  data-bs-toggle="modal"
                  data-bs-target="#MoreDetail"
                >
                  More Details
                </button>
              </div>

              
            </div>
          </div>
          <div className="col-xl-8 mt-xl-0 mt-3">
            <div className="row mx-0">
              <div className="col-3 px-1">
                <div className="cardOuter h-100">
                  <div className="level">
                    <img src={level1} alt="" className="img-fluid" />
                  </div>
                  <div className="d-flex align-items-center flex-column">
                    <div className="d-flex justify-content-center w-full">
                      <div className="box">
                        <img src={box} alt="" className="img-fluid" />
                      </div>
                    </div>
                    <div className="tittle-3">180% BONUS</div>
                    <button className="depositBtn">First Deposit</button>
                  </div>
                </div>
              </div>
              <div className="col-3 px-1">
                <div className="cardOuter h-100 bg-2">
                  <div className="level">
                    <img src={level2} alt="" className="img-fluid" />
                  </div>
                  <div className="d-flex align-items-center flex-column">
                    <div className="d-flex justify-content-center w-full">
                      <div className="box">
                        <img src={box2} alt="" className="img-fluid" />
                      </div>
                    </div>
                    <div className="tittle-3">240% BONUS</div>
                    <button className="depositBtn">Second Deposit</button>
                  </div>
                </div>
              </div>
              <div className="col-3 px-1">
                <div className="cardOuter h-100 bg-3">
                  <div className="level">
                    <img src={level3} alt="" className="img-fluid" />
                  </div>
                  <div className="d-flex align-items-center flex-column">
                    <div className="d-flex justify-content-center w-full">
                      <div className="box">
                        <img src={box3} alt="" className="img-fluid" />
                      </div>
                    </div>
                    <div className="tittle-3">240% BONUS</div>
                    <button className="depositBtn">Third Deposit</button>
                  </div>
                </div>
              </div>
              <div className="col-3 px-1">
                <div className="cardOuter h-100 bg-4">
                  <div className="level">
                    <img src={level4} alt="" className="img-fluid" />
                  </div>
                  <div className="d-flex align-items-center flex-column">
                    <div className="d-flex justify-content-center w-full">
                      <div className="box">
                        <img src={box4} alt="" className="img-fluid" />
                      </div>
                    </div>
                    <div className="tittle-3">240% BONUS</div>
                    <button className="depositBtn">Fourth Deposit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div> */}
        </div>
      ) : (
        ""
      )}

      <div className="bonus">
        <ul class="nav nav-pills" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              onClick={() => setSelectedTab("casino")}
              class="nav-link active"
              id="pills-promotion-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-promotion"
              type="button"
              role="tab"
              aria-controls="pills-promotion"
              aria-selected="true"
            >
              Casino
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              onClick={() => setSelectedTab("sports")}
              class="nav-link"
              id="pills-archived-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-archived"
              type="button"
              role="tab"
              aria-controls="pills-archived"
              aria-selected="false"
            >
              Sports
            </button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <CasinoTab
            selectedTab={selectedTab}
            casinoPageCount={casinoPageCount}
            casinoPage={casinoPage}
            setCasinoPage={setCasinoPage}
            casinoData={casinoData}
          />
          <SportsTab
            selectedTab={selectedTab}
            sportsPage={sportsPage}
            sportsPageCount={sportsPageCount}
            setSportsPage={setSportsPage}
            sportData={sportData}
          />
        </div>
      </div>
    </>
  );
};

export default Promotion;
