import React, { useEffect, useRef } from 'react'
import Header from '../components/header/Header'
import Footer from '../components/Footer/Footer'
import Sidebar from '../components/sidebar/Sidebar'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from '../utils/useMediaQuery'
import { RESPONSIVE_WIDTH } from '../constants'


const SportsLayout = ({Component, isSports}) => {
  const scrollDiv = useRef()
  const location = useLocation()
  const isLgScreen = useMediaQuery(RESPONSIVE_WIDTH.MD_SCREEN);


  useEffect(()=> {
    scrollDiv?.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [location?.pathname])

  return (
    <>
    <div className="appLayout h-100 overflow-hidden d-flex flex-column">
      <Header isSports={isSports}/>
      <div className="mainContent h-100 overflow-hidden d-flex flexwrap">
        {
          isSports && !isLgScreen ? '' : <Sidebar/> 
        }
        <div className={`h-100 overflow-hidden scrollPart ${isSports ? 'withoutSidebar' : ''}`}>
          <div className="h-100 text-white" ref={scrollDiv}>
            <div className="bodyScrollInnerPart p-0 h-100">
              <>
                <Component/>
                {/* <Footer/> */}
              </>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}
export default SportsLayout