import React, { useEffect, useRef, useState } from "react";
import close from "./../../../../assets/images/closeModal.png";
import dambletext from "./../../../../assets/images/dambletext.png";
import Accordion from "react-bootstrap/Accordion";
import { Link, useSearchParams } from "react-router-dom";
import SocialLogin from "../socialLogin/SocialLogin";
import { useAuthModal } from "../../../../hooks/useAuthModal";
import { AUTH_MODAL_TABS, baseURL, USER_LANG } from "../../../../constants";
import { getAPI, postAPI } from "../../../../services/apiInstance";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import SignInEmailOrNumber from "./SignInEmailOrNumber";
import LoginPassword from "./LoginPassword";
import LoginForgetPassword from "./LoginForgetPassword";
import LoginOtp from "./LoginOtp";
import Google from "./../../../../assets/images/login-signup/google.svg";
import MetaMask from "./../../../../assets/images/login-signup/metaMask.svg";
import Telegram from "./../../../../assets/images/login-signup/telegram.svg";
import ClickUp from "./../../../../assets/images/login-signup/click-up.svg";
import EMail from "./../../../../assets/images/login-signup/mail.svg";
import Password from "./../../../../assets/images/login-signup/password.svg";
import Tag from "./../../../../assets/images/login-signup/tag.svg";
import Flag from "./../../../../assets/images/login-signup/flag.svg";
import SigninForm from "./SigninForm";
import SignupForm from "./SignupForm";
import { succesToaster, errorToaster } from "../../../../utils/toaster";
import { signInWithPopup } from "firebase/auth";
import { auth, provider } from "../../../../config/firebase";
import { useAuth } from "../../../../hooks/useAuth";
import { useBtAuth } from "../../../../hooks/useBtAuth";
import axios from "axios";
import { MetamaskProviderComp } from "../socialLogin/MetamaskLogin";
import TelegramLogin from "react-telegram-login";

import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
import { ethers } from "ethers";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import { useLoginSuccess } from "../../../../hooks/useLoginSuccess";
import LoginToaster from "../../../loginToaster/LoginToaster";
import { toast } from "react-toastify";

const validationSchema = Yup.object({
  emailOrNumber: Yup.string()
    .required("Email/Phone Number is required")
    .matches(
      /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
      "Invalid email or mobile number"
    ),
});

const SignInComponent = ({
  handleClose,
  browserName,
  isForgotPassword,
  setIsForgotPassword,
}) => {
  const { authModalObject, setAuthModalObject } = useAuthModal();
  const { loginModalObject, setLoginModalObject } = useLoginSuccess();
  const [query, setQuery] = useSearchParams();
  const { t } = useTranslation();
  const ipAddress = useSelector((state) => state.ipAddress);
  const country = useSelector((state) => state.country);
  const { setToken } = useAuth();
  const { setBtToken } = useBtAuth();
  const authLabels = t("AuthLabels", { returnObjects: true });
  const [forgetpass, setForgetpass] = useState(false);
  const [logOn, setLogOn] = useState(true);
  const [emailPassword, setEmailPassword] = useState(false);
  const [phoneOtp, setPhoneOtp] = useState(false);
  const [emailOrNumber, setEmailOrNumber] = useState("");
  const [select, setSelect] = useState("");
  const [showTab, setShowTab] = useState("login");
  const [telegramUser, setTelegramUser] = useState(null);

  const telegramButtonRef = useRef(null);
  useEffect(() => {
    if (query.get("action") !== "forgotPassword") {
      setIsForgotPassword(false);
    }
  }, [query.get("action"), authModalObject.selectedTab]);

  console.log({ authModalObject });

  useEffect(() => {
    if (authModalObject.selectedTab === AUTH_MODAL_TABS.SIGN_UP) {
      setShowTab("signUp");
    }
  }, [authModalObject]);

  const handleGoogleLogin = async () => {
    try {
      const data = await signInWithPopup(auth, provider);
      const lang = localStorage.getItem(USER_LANG);
      const body = {
        email: data._tokenResponse.email,
        idToken: data._tokenResponse.idToken,
        localId: data._tokenResponse.localId,
        refreshToken: data._tokenResponse.refreshToken,
        loginMethod: "google",
        ipAddress: ipAddress ? ipAddress : "",
        country: country ? country : "",
        // language: lang?.symbol
        language: lang,
      };
      const res = await postAPI("user/social-authentication", body);
      if (res.data.success) {
        setToken(res.data.data.token);
        setAuthModalObject((pre) => ({ ...pre, isAuthOpen: false }));
        succesToaster("You have successfully logged in.",true)
        setBtToken(res?.data?.data?.betByToken);
      } else {
        errorToaster("something went wrong");
      }
      localStorage.setItem("email", data.user.email);
    } catch (error) {
      errorToaster(error.message);
    }
  };

  const metamaskHandler = (values) => {
    try {
      if (window.ethereum) {
        window.ethereum
          .request({ method: "eth_requestAccounts" })
          .then((res) => {
            metaMaskLogin(values, res[0]);
          });
      } else {
        errorToaster("Wallet not available");
      }
    } catch (error) {
      console.log("meta error", error);
    }
  };

  const metaMaskLogin = async (values, wallet_address) => {
    try {
      // if (values.email) {
      // const userCredential = await signInWithEmailAndPassword(
      //     auth,
      //     values.email,
      //     values.password
      // );
      const lang = localStorage.getItem(USER_LANG);
      // const lang = JSON.parse(localStorage.getItem(USER_LANG))

      // Signed in
      const body = {
        // email: values.email,
        // password: values.password,
        wallet_id: wallet_address,
        loginMethod: "metamask",
        ipAddress: ipAddress ? ipAddress : "",
        country: country ? country : "",
        language: lang?.symbol,
        // language: JSON.parse(lang)
      };
      const res = await axios.post(
        `${baseURL}/user/social-authentication`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json",
          },
        }
      );
      if (res?.data?.success) {
        setToken(res?.data?.data?.token);
        // alert(res?.data?.data?.token)
        setBtToken(res?.data?.data?.betByToken);
        // setIsOpen(false)
        succesToaster("You have successfully logged in.",true)
        setAuthModalObject((pre) => ({ ...pre, isAuthOpen: false }));

      } else {
        errorToaster(res?.data?.message);
      }
      // }
    } catch (error) {
      errorToaster("Something went wrong!");
    }
  };

  const walletLogin = async (wallet_address) => {
    try {
      // if (values.email) {
      // const userCredential = await signInWithEmailAndPassword(
      //     auth,
      //     values.email,
      //     values.password
      // );
      const lang = localStorage.getItem(USER_LANG);
      // const lang = JSON.parse(localStorage.getItem(USER_LANG))

      // Signed in
      const body = {
        // email: values.email,
        // password: values.password,
        wallet_id: wallet_address,
        loginMethod: "walletconnect",
        ipAddress: ipAddress ? ipAddress : "",
        country: country ? country : "",
        language: lang?.symbol,
        // language: JSON.parse(lang)
      };
      const res = await axios.post(
        `${baseURL}/user/social-authentication`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json",
          },
        }
      );
      if (res?.data?.success) {
        setToken(res?.data?.data?.token);
        // alert(res?.data?.data?.token)
        setBtToken(res?.data?.data?.betByToken);
        // setIsOpen(false)
        succesToaster("You have successfully logged in.",true)
        setAuthModalObject((pre) => ({ ...pre, isAuthOpen: false }));

      } else {
        errorToaster(res?.data?.message);
      }
      // }
    } catch (error) {
      errorToaster("Something went wrong!");
    }
  };

  const handleTelegramAuth = (user) => {
    console.log("Authenticated user:", user);
    setTelegramUser(user);
  };

  const handleDivClick = () => {
    console.log(telegramButtonRef.current, "telegramButtonRef.current");
    if (telegramButtonRef.current) {
      telegramButtonRef.current.dataOnauth();
    }
  };

  useEffect(() => {
    // Dynamically load the Telegram widget script
    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-widget.js?9";
    script.async = true;
    script.setAttribute("data-telegram-login", "memecoin01_bot");
    script.setAttribute("data-size", "small");
    script.setAttribute("data-request-access", "write");
    script.setAttribute("data-userpic", "true");
    script.setAttribute("data-lang", "en");
    script.setAttribute("data-onauth", "TelegramLoginWidget.dataOnauth(user)");

    // Append the script to the document body
    document.body.appendChild(script);

    // Cleanup: Remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const connector = new WalletConnect({
    bridge: "https://bridge.walletconnect.org", // WalletConnect bridge URL
    qrcodeModal: QRCodeModal,
  });

  const authenticateUser = async (userAddress) => {
    try {
      // Use BrowserProvider instead of Web3Provider
      const provider = new ethers.BrowserProvider(connector);
      const signer = await provider.getSigner();

      // Create a message for the user to sign
      const message = "Please sign this message to authenticate.";

      // Request a signature
      const signature = await signer.signMessage(message);
      console.log("Signature:", signature);

      // Verify the signature (optional)
      const recoveredAddress = ethers.verifyMessage(message, signature);
      if (recoveredAddress === userAddress) {
        console.log("Authentication successful!");
        // Proceed with your app logic (e.g., store user session)
      } else {
        console.error("Authentication failed: Signature mismatch");
      }
    } catch (error) {
      console.error("Authentication error:", error);
    }
  };

  const connectWallet = async () => {
    try {
      // Initialize the EthereumProvider
      const provider = await EthereumProvider.init({
        projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID, // Get this from WalletConnect Cloud
        chains: [1], // Ethereum mainnet
        showQrModal: true, // Enable QR code modal
      });

      // Enable the session (triggers QR code modal)
      await provider.enable();
      // await provider.disconnect();

      // Get the connected account
      const accounts = provider.accounts;
      const userAddress = accounts[0];

      // Authenticate the user
      authenticateUser(userAddress, provider);
      walletLogin(userAddress);
    } catch (error) {
      console.error("WalletConnect error:", error);
    }
  };

  return (
    <>
      {/* <div className="row signupForm mx-0 h-100 flex-column flex-nowrap">
        <div className="col-12 px-0">
          <div className="header d-flex justify-content-between align-items-center">
            <div className="headerTxt">
              {" "}
              {forgetpass ? authLabels.Forget_Password : authLabels.Sign_In}
            </div>
            <div>
              <div
                // onClick={handleClose}
                onClick={() => {
                  setAuthModalObject((pre) => ({
                    ...pre,
                    isAuthOpen: false,
                  }));
                  setQuery({ action: "" });
                  handleClose();
                }}
                className="closeBtn d-md-block d-none"
              >
                <img src={close} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 px-0 flex-fill">
          <div className="formbody h-100">
            <div className="tabs h-100">
              <div className="tab-content h-100">
                <div className="h-100">
                  <div className="row h-100 flex-column flex-nowrap">
                    {logOn && (
                      <SignInEmailOrNumber
                        setLogOn={setLogOn}
                        setEmailPassword={setEmailPassword}
                        setPhoneOtp={setPhoneOtp}
                        setEmailOrNumber={setEmailOrNumber}
                        setForgetpass={setForgetpass}
                        logOn={logOn}
                        select={select}
                        setSelect={setSelect}
                        browserName={browserName}
                      />
                    )}
                    {emailPassword ? (
                      <LoginPassword
                        setLogOn={setLogOn}
                        setEmailPassword={setEmailPassword}
                        emailOrNumber={emailOrNumber}
                        setForgetpass={setForgetpass}
                        emailPassword={emailPassword}
                        browserName={browserName}
                      />
                    ) : (
                      <></>
                    )}
                    {forgetpass && <LoginForgetPassword />}

                    {phoneOtp ? (
                      <LoginOtp
                        setLogOn={setLogOn}
                        setPhoneOtp={setPhoneOtp}
                        phoneOtp={phoneOtp}
                        emailOrNumber={emailOrNumber}
                        select={select}
                        setSelect={setSelect}
                        browserName={browserName}
                      />
                    ) : (
                      <></>
                    )}

                    <div className="col-12 mt-2 mb-2 flex-fill">
                      <div className="anchortext">
                        {authLabels.New_to_Damble}{" "}
                        <span
                          onClick={() => {
                            setAuthModalObject((prev) => ({
                              ...prev,
                              selectedTab: AUTH_MODAL_TABS.SIGN_UP,
                            }));
                            setQuery({ action: "register" });
                          }}
                          className="cursor-pointer"
                        >
                          {authLabels.Create_account}
                        </span>
                      </div>
                    </div>
                    <SocialLogin parent={"signin"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3 d-md-none d-block">
          <div className="damble">
            <img src={dambletext} alt="" />
          </div>
        </div>
      </div> */}
      <div className="row signupForm mx-0 h-100 flex-column flex-nowrap overflow-y-auto">
        <div className="col-12 px-0">
          <div className="header d-flex justify-content-end align-items-center mb-0">
            <div>
              <div
                // onClick={handleClose}
                onClick={() => {
                  setAuthModalObject((pre) => ({
                    ...pre,
                    isAuthOpen: false,
                  }));
                  setQuery({ action: "" });
                  handleClose();
                }}
                className="closeBtn"
              >
                <img src={close} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <ul className="nav nav-pills" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  authModalObject.selectedTab === AUTH_MODAL_TABS.LOG_IN
                    ? "active"
                    : ""
                }`}
                id="pills-Login-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Login"
                type="button"
                role="tab"
                aria-controls="pills-Login"
                aria-selected="true"
              >
                {" "}
                Login{" "}
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  authModalObject.selectedTab === AUTH_MODAL_TABS.SIGN_UP
                    ? "active"
                    : ""
                }`}
                id="pills-Signup-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Signup"
                type="button"
                role="tab"
                aria-controls="pills-Signup"
                aria-selected="false"
              >
                {" "}
                Sign Up{" "}
              </button>
            </li>
          </ul>
        </div>
        <div className="mb-3">
          <div className="d-flex align-items-center justify-content-center gap-2 mb-3">
            <div
              onClick={() => handleGoogleLogin()}
              className="direct-login cursor-pointer"
            >
              <img src={Google} alt="" />
            </div>
            <div
              onClick={() => {
                // setIsMetamask(true)
                if (!!!window.ethereum) {
                  errorToaster("Metamask browser extension not found");
                } else {
                  metamaskHandler();
                }
              }}
              className="direct-login cursor-pointer"
            >
              <img src={MetaMask} alt="" />

              <MetamaskProviderComp />
            </div>

            {/* <div
              
              className="direct-login cursor-pointer"
            >
              <img src={Telegram} alt="" />
            </div> */}

            <div className="position-relative overflow-hidden direct-login cursor-pointer">
              <div className="telegramBtnParent d-flex justify-content-center position-absolute h-100 w-100 bottom-0 start-0 end-0 top-0">
                <TelegramLogin
                  dataOnauth={handleTelegramAuth}
                  botName="memecoin01_bot"
                  buttonSize="small"
                />
              </div>
              <div className="  socialLinks">
                <img src={Telegram} alt="" />
              </div>
              {/* <div className="linkImg">
          <img src={isFromCrypto ? './assets/img/stacking/telegramWhite.png' : 'assets/img/auth/telegram.png'} alt='' />
        </div> */}
            </div>

            <div
              onClick={connectWallet}
              className="direct-login cursor-pointer"
            >
              <img src={ClickUp} alt="" />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <div className="line"></div>
            <div className=" text-center">
              <p className="all-methods">
                SHOW ALL METHODS <i className="fa-solid fa-angle-down"></i>
              </p>
            </div>
            <div className="line"></div>
          </div>
        </div>

        <div className="tab-content" id="pills-tabContent">
          <SigninForm showTab={showTab} />
          <SignupForm showTab={showTab} />
        </div>
      </div>
    </>
  );
};

export default SignInComponent;
