import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginToaster from "../components/loginToaster/LoginToaster";
import ErrorTaoster from "../components/loginToaster/ErrorTaoster";
export const ToasterContainer = () => <ToastContainer />;

let currentToast = 0;
export const succesToaster = (msg, login) => {
  // toast.dismiss()
  if (currentToast) {
    toast.dismiss(currentToast);
  }
  currentToast = toast(<LoginToaster msg={msg} login={login} />, {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
export const errorToaster = (msg) => {
  if (currentToast) {
    toast.dismiss(currentToast);
  }
  currentToast = toast(<ErrorTaoster msg={msg} />, {
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};
