import React, { useEffect, useState } from "react";
import BetTable from "../../components/betTable/BetTable";
import { useMediaQuery } from "../../utils/useMediaQuery";
import {
  AUTH_MODAL_TABS,
  GAME_OPTION_TABS,
  RESPONSIVE_WIDTH,
} from "../../constants";
import BonusSlider from "../home/components/BonusSlider";
import LobbySlider from "../../components/LobbySlider/LobbySlider";
import SearchBar from "../../components/SearchBar/SearchBar";
import ProviderSlider from "../../components/ProviderSlider/ProviderSlider";
import popularSlots from "./../../assets/images/popularSlots.png";
import CasinoSlider from "../../components/casinoSlider/CasinoSlider";
import { getAPI } from "../../services/apiInstance";
import toppicks from "../../assets/images/toppicks.png";
import slots from "../../assets/images/slots.png";
import hotgames from "../../assets/images/hotgames.png";
import livecasino from "../../assets/images/livecasino.png";
import SelectedGame from "./components/SelectedGames";
import { useGames } from "../../hooks/useGame";
import { useAuth } from "../../hooks/useAuth";
import { useAuthModal } from "../../hooks/useAuthModal";
import Favorites from "./components/Favorites";
import Recent from "./components/Recent";
import FreeSpin from "./components/FreeSpin";
import DropModal from "../../components/ModalSection/DropModal";
import { useTranslation } from "react-i18next";
import Lobby from "./components/Lobby";
import { useSearchParams } from "react-router-dom";
import CasinoPageSlider1 from "./components/CasinoPageSlider1";
import CasinoPageSlider2 from "./components/CasinoPageSlider2";
import CasinoPageSlider3 from "./components/CasinoPageSlider3";
import CasinoPageSlider4 from "./components/CasinoPageSlider4";
import CasinoPageSlider5 from "./components/CasinoPageSlider5";
import CasinoPageSlider6 from "./components/CasinoPageSlider6";
import CasinoPageSlider7 from "./components/CasinoPageSlider7";

import recent from "./../../assets/images/sidebar/recent.png";
import allGames from "./../../assets/images/sidebar/allGames.png";
import fav from "./../../assets/images/sidebar/fav.png";

const CasinoPage = () => {
  const isLgScreen = useMediaQuery(RESPONSIVE_WIDTH.MD_SCREEN);
  const isSMScreen = useMediaQuery(RESPONSIVE_WIDTH.SM_SCREEN);
  const { gameObject, setGameObject } = useGames();
  const { token } = useAuth();
  const { setAuthModalObject } = useAuthModal();
  const { t } = useTranslation();
  const sidebarLabels = t("appSideApp", { returnObjects: true });
  const [activeTab, setActiveTab] = useState("lobby");
  const [gamesData, setGamesData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [allGame, setAllGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useSearchParams();

  const getCategory = async () => {
    setLoading(true);
    try {
      const res = await getAPI(`get-all-category`);
      if (res?.data?.success) {
        setCategories(res?.data?.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getGames = async () => {
    try {
      const res = await getAPI(
        `games/get-games-by-cat?is_mobile=${
          isSMScreen ? "true" : "false"
        }&gameLimit=10&categoryId=${
          query.get("catid") ? query.get("catid") : ""
        }`
      );
      console.log({ res }, "kdkdkdkdkdk");
      if (res.data.success) {
        setGamesData(res.data.data);
        setAllGames(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCategory();
    getGames();
  }, []);

  useEffect(() => {
    if (query.get("q") == "lobby") {
      setGameObject((prev) => ({
        selectedTab: GAME_OPTION_TABS?.LOBBY,
      }));
    } else if (query.get("q") == "favourite") {
      if (token) {
        setGameObject((prev) => ({
          selectedTab: GAME_OPTION_TABS?.FAVOURITE,
        }));
      } else {
        setAuthModalObject((pre) => ({
          isAuthOpen: true,
          selectedTab: AUTH_MODAL_TABS.LOG_IN,
        }));
      }
    } else if (query.get("q") == "recent") {
      if (token) {
        setGameObject((prev) => ({
          selectedTab: GAME_OPTION_TABS?.RECENT,
        }));
      } else {
        setAuthModalObject((pre) => ({
          isAuthOpen: true,
          selectedTab: AUTH_MODAL_TABS.LOG_IN,
        }));
      }
    } else if (query.get("q") == "freeSpin") {
      if (token) {
        setGameObject((prev) => ({
          selectedTab: GAME_OPTION_TABS?.FREE_SPIN,
        }));
      } else {
        setAuthModalObject((pre) => ({
          isAuthOpen: true,
          selectedTab: AUTH_MODAL_TABS.LOG_IN,
        }));
      }
    } else {
      const catId = query.get("catid");
      const catName = query.get("q");

      setGameObject((prev) => ({
        selectedTab: catName,
        selectedId: catId,
      }));
    }
  }, [query.get("q"), query.get("catid"), token]);

  // useEffect(() => {
  //   if (query.get("q") === "lobby") {
  //     setGamesData(allGame);
  //     return
  //   }
  //   const filterData = allGame.filter((cat) => {
  //     return cat.name.category_name === query.get("q");
  //   });
  //   setGamesData(filterData);
  // }, [query.get("q")]);

  useEffect(() => {
    if (query.get("catid")) {
      getGames();
    }
  }, [query.get("catid")]);

  return (
    <>
      {/* {
        !isLgScreen ? <LobbySlider/> : <BonusSlider/>
      } */}
      <BonusSlider />
      <SearchBar />

      <div className="row mx-0 align-items-center pt-3 pt-md-0 sectionSpacing">
        <div className="col-12 px-0 casinoTabOptionsWrapper overflow-hidden">
          <div className="row flex-nowrap casinoTabOptions text-nowrap overflow-x-auto gx-lg-0 pb-3">
            {categories.length ? (
              <div className="col-auto">
                <div
                  onClick={() => {
                    setGameObject((prev) => ({
                      selectedTab: GAME_OPTION_TABS?.LOBBY,
                    }));
                    setQuery({ q: "lobby" });
                  }}
                  className={`casinoTabOption ${
                    gameObject?.selectedTab === GAME_OPTION_TABS?.LOBBY
                      ? "active"
                      : ""
                  }`}
                >
                  {" "}
                  <img src={allGames} alt="" />
                  Lobby
                </div>
              </div>
            ) : (
              ""
            )}

            {categories.map((catgory) => {
              return (
                <div key={catgory._id} className="col-auto">
                  <div
                    onClick={() => {
                      setGameObject((prev) => ({
                        selectedTab: catgory.category_name,
                      }));
                      setQuery({
                        q: catgory.category_name,
                        catid: catgory._id,
                      });
                    }}
                    className={`casinoTabOption ${
                      gameObject?.selectedTab === catgory.category_name
                        ? "active"
                        : ""
                    }`}
                  >
                    {" "}
                    <img src={allGames} alt="" />
                    {catgory.category_name}
                  </div>
                </div>
              );
            })}

            {/* {!loading ?
              categories?.length > 0 ?
                categories?.map((item)=> (
                  <div className="col-auto">
                    <div 
                      onClick={()=>{
                        setGameObject((prev)=> ({
                          selectedTab : item?.category_name?.split(" ").join(""),
                          selectedId : item?._id
                        }))
                        setQuery({ q: item?.category_name?.split(" ").join(""), categoryId: item?._id})
                        // setActiveTab(item?._id)
                      }}
                      className={`casinoTabOption text-capitalize
                        ${gameObject?.selectedTab === item?.category_name?.split(" ").join("") ? 'active' : ''}
                        `}>
                      <img src={item?.image} alt="" />
                      {item?.category_name?.toLowerCase()}
                    </div>
                  </div>
                ))
              : ''
            : ''} */}
          </div>
        </div>
      </div>

      {/* {gamesData.map((category) => {
        return <CasinoPageSlider1 key={category._id} data={category} />;
      })} */}
      {/* 
      <CasinoPageSlider2 />

      <CasinoPageSlider3 />

      <CasinoPageSlider4 />

      <CasinoPageSlider5 />

      <CasinoPageSlider6 />

      <CasinoPageSlider7 /> */}

      {gameObject?.selectedTab === GAME_OPTION_TABS?.LOBBY ? (
        <Lobby />

      ) : gameObject?.selectedTab === GAME_OPTION_TABS?.FAVOURITE ? (
        <Favorites />
      ) : gameObject?.selectedTab === GAME_OPTION_TABS?.RECENT ? (
        <Recent />
      ) : gameObject?.selectedTab === GAME_OPTION_TABS?.FREE_SPIN ? (
        <FreeSpin />
      ) : (
        <SelectedGame selectedCategory={gameObject?.selectedId} />
      )}
    </>
  );
};

export default CasinoPage;
