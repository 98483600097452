import React, { useEffect, useState } from "react";
import { getAPI } from "../../../../services/apiInstance";
import signupBtn from "./../../../../assets/images/signInBtn.png";
import { useSelector } from "react-redux";
import { isEmail } from "../../../../utils/isEmail";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../../config/firebase";
import { errorToaster, succesToaster } from "../../../../utils/toaster";
import * as Yup from "yup";
import { Formik } from "formik";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAuthModal } from "../../../../hooks/useAuthModal";
import { useSearchParams } from "react-router-dom";

const validationSchema = Yup.object({
  emailOrNumber: Yup.string()
    .required("Email/Phone Number is required")
    .matches(
      // /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
      /^(?![A-Z])([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,}|(\+\d{1,3}\s?)?\d{10,})$/,
      "Invalid email or mobile number"
    ),
});

const SignInEmailOrNumber = ({
  setEmailPassword,
  setLogOn,
  setPhoneOtp,
  setEmailOrNumber,
  setForgetpass,
  logOn,
  select,
  setSelect,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [matchedItem, setmatchedItem] = useState([]);
  const [countrycodedata, setCountryCode] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const countryName = useSelector((state) => state.country);
  const { t } = useTranslation();
  const authLabels = t("AuthLabels", { returnObjects: true });
  const [query, setQuery] = useSearchParams();
  const { authModalObject, setAuthModalObject } = useAuthModal();
  const [error, setError] = useState(false);

  const CountryCodeFun = async () => {
    const res = await getAPI("get-countries");
    setCountryCode(res.data.data);
    setmatchedItem(res.data.data);
  };

  useEffect(() => {
    CountryCodeFun();
  }, []);

  const onSubmit = (values) => {
    if (isEmail(values.emailOrNumber)) {
      setEmailPassword(true);
      setLogOn(false);
      setEmailOrNumber(values.emailOrNumber);
      setIsLoading(false);
    } else {
      // if (select == 0) {
      //   errorToaster("Choose a country code");
      //   setIsLoading(false);

      //   return;
      // }
      handleSend(values);
      setEmailOrNumber(values.emailOrNumber);
    }
  };
  const generateRecaptcha = (random) => {
    const recaptchaParent = document.getElementById("recaptcha-parent");
    if (recaptchaParent) {
      recaptchaParent.innerHTML = `<span id=${random} />`;
    }
    window.recaptchaVerifier = new RecaptchaVerifier(auth, `${random}`, {
      size: "invisible",
      callback: (response) => {},
    });
    window.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  };

  const handleSend = (values) => {
    const random = Math.random() + 1000000;
    generateRecaptcha(random);
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(
      auth,
      `${select?.phoneCode ? select.phoneCode : "+91"} ${values.emailOrNumber}`,
      appVerifier
    )
      .then((confirmationResult) => {
        succesToaster("OTP SENT!");
        window.confirmationResult = confirmationResult;
        setLogOn(false);
        setPhoneOtp(true);
      })
      .catch((error) => {
        errorToaster(error.message.split("(")[1].split(")")[0].split("/")[1]);
        const recaptchaContainer = document.getElementById(`${random}`);
        if (recaptchaContainer) {
          recaptchaContainer.remove();
        }

        // errorToaster(getFriendlyErrorMessage(error.code))
        // errorToaster(error.message)
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClick = () => {
    setForgetpass(true);
    setLogOn(false);
    setEmailPassword(false);
  };

  useEffect(() => {
    const codedata =
      countrycodedata.filter((item) => item.country == countryName).length == 0
        ? countrycodedata
        : countrycodedata.filter((item) => item.country === countryName);
    setmatchedItem(countrycodedata);
    setSelect(countryName && codedata[0]);
    localStorage.setItem("country_Id", codedata[0]?._id);
  }, [countryName, countrycodedata]);

  useEffect(() => {
    if (!logOn) {
      setIsLoading(false);
    }
  }, [logOn]);

  useEffect(() => {
    const codedata =
      countrycodedata.filter((item) => item.country == countryName).length == 0
        ? countrycodedata
        : countrycodedata.filter((item) => item.country === countryName);
    setmatchedItem(countrycodedata);
    setSelect(countryName && codedata[0]);
    localStorage.setItem("country_Id", codedata[0]?._id);
  }, [countryName, countrycodedata]);

  const handlesearch = (val) => {
    const valdata = val.trim();
    if (valdata.length > 0) {
      const filterdata = countrycodedata?.filter(
        (item) =>
          item.country.toLowerCase().includes(val.toLowerCase()) ||
          item.phoneCode.toLowerCase().includes(val.toLowerCase())
      );
      setmatchedItem(filterdata);
    } else {
      setmatchedItem(countrycodedata);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          emailOrNumber: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setIsLoading(true);
          onSubmit(values);
        }}
      >
        {({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          errors,
          touched,
        }) => (
          <form>
            <div
              className={`col-12 py-2 position-relative dropWrapper ${
                isActive ? "active" : ""
              }`}
            >
              <div className="position-relative">
                <input
                  className="commonInp commonInpNumber"
                  type="mail"
                  placeholder={`${authLabels.Email}/${authLabels.Phone_Number}`}
                  value={values.emailOrNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="emailOrNumber"
                />
                <div
                  onClick={() => setIsActive(true)}
                  className="numberWrapper"
                >
                  {select?.phoneCode ? select?.phoneCode : "+91"}
                  <svg
                    className=""
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                  >
                    <path
                      d="M5.02282 5.73994C4.92844 5.74049 4.83488 5.7224 4.74751 5.68671C4.66014 5.65102 4.58067 5.59844 4.51366 5.53198L0.210901 1.22922C0.0758633 1.09418 0 0.911032 0 0.72006C0 0.529089 0.0758633 0.345938 0.210901 0.210901C0.345938 0.0758633 0.529089 0 0.72006 0C0.911032 0 1.09418 0.0758633 1.22922 0.210901L5.02282 4.01167L8.81641 0.218072C8.9536 0.100588 9.13007 0.0391974 9.31055 0.0461687C9.49104 0.05314 9.66224 0.12796 9.78996 0.255676C9.91768 0.383392 9.99249 0.554598 9.99946 0.735082C10.0064 0.915565 9.94505 1.09203 9.82756 1.22922L5.52481 5.53198C5.39123 5.66446 5.21095 5.73915 5.02282 5.73994Z"
                      fill="#ffffff"
                    ></path>
                  </svg>
                </div>
                {isActive && (
                  <>
                    <div
                      className="countryCodeLayer position-fixed vh-100 w-100 top-0 start-0"
                      onClick={() => setIsActive(false)}
                      style={{ zIndex: 97 }}
                    >
                      {" "}
                    </div>
                  </>
                )}
                <div className="counteryCodeWrapper">
                  <div className="row overflow-y-auto h-100">
                    <div className="col-12 mb-2 position-relative">
                      <input
                        className="commonInp commonInpSearch"
                        onChange={(e) => handlesearch(e.target.value)}
                        placeholder="Search"
                        type="text"
                      />
                      <div className="searchImg">
                        <svg
                          stroke="currentColor"
                          fill="none"
                          stroke-width="0"
                          viewBox="0 0 15 15"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5ZM9.30884 10.0159C8.53901 10.6318 7.56251 11 6.5 11C4.01472 11 2 8.98528 2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 7.56251 10.6318 8.53901 10.0159 9.30884L12.8536 12.1464C13.0488 12.3417 13.0488 12.6583 12.8536 12.8536C12.6583 13.0488 12.3417 13.0488 12.1464 12.8536L9.30884 10.0159Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div className="col-12 d-flex flex-column gap-2">
                      {matchedItem?.length &&
                        matchedItem?.map((item) => (
                          <div
                            className="row countryList mx-0 py-2"
                            key={item?._id}
                            onClick={() => {
                              setSelect(item);
                              localStorage.setItem("country_Id", item?._id);
                              setIsActive(false);
                            }}
                          >
                            <div className="col-auto" style={{ width: 70 }}>
                              <div className="numberTxt">{item.phoneCode}</div>
                            </div>
                            <div className="col ps-0">
                              <div className="counteryName">{item.country}</div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {errors.emailOrNumber &&
            touched.emailOrNumber &&
            errors.emailOrNumber ? (
              <div className="text-danger">
                {errors.emailOrNumber &&
                  touched.emailOrNumber &&
                  errors.emailOrNumber}
              </div>
            ) : (
              ""
            )}
            <div className="col-12 pt-2 pb-4 text-end">
              <div
                className="anchortext"
                onClick={() => {
                  setForgetpass(true);
                  setLogOn(false);
                  setEmailPassword(false);
                  setPhoneOtp(false);
                  setQuery({ action: "forgotPassword" });
                }}
              >
                {authLabels.Forgot_your_password}
              </div>
            </div>
            <div className="col-12">
              <button
                onClick={handleSubmit}
                type="submit"
                className="signUpBtn text-white"
              >
                {isLoading ? <Spinner size="sm" variant="light" /> : "Continue"}
                {/* <img src={signupBtn} alt="" /> */}
              </button>
            </div>
            <div id="recaptcha-parent"> </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SignInEmailOrNumber;
