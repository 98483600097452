import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../../hooks/useAuth'
import { getAPIAuth } from '../../../../../services/apiInstance'
import moment from 'moment/moment'
import NoData from '../../NoData'
import { useSelector } from 'react-redux'
import Dropdown from 'react-bootstrap/Dropdown';
import {  DayPicker } from 'react-day-picker';

import 'react-day-picker/dist/style.css';
const pastMonth = new Date();



const StackingHistory = () => {
  const [data, setData] = useState([])
  const {token} = useAuth()
  const [activeTab, setActiveTab] = useState('Flexible')
  const userDetails = useSelector((state) => state.userDetails);
  const [selectedToken, setSelectedToken] = useState({})
  const [selectedType, setSelectedType] = useState('Claim')
  const [cryptoList, setCryptoList] = useState([])
  var currentDate = new Date();
  const [range, setRange] = useState({
    from: new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000),
    to: new Date()
  });




  const getEarnings = async ()=> {
    try {
      const res = await getAPIAuth(`crypto/staking-history?sel_curr=${userDetails?.usercurrency}&pool_type=${activeTab}&crypto_id=${selectedToken?._id ? selectedToken?._id : ''}&start_date=${range?.from}&end_date=${range?.to}`, token)
      if(res?.data?.success) {
        setData(res?.data?.data)
      } else {
        setData([])
      }
    } catch (error) {
      
    }
  }

  const getCrypto = async ()=> {
    try {
      const res = await getAPIAuth('crypto/get-crypto', token)
      if(res?.data?.success) {
        setCryptoList(res?.data?.data)
      }
    } catch (error) {
      
    }
  }

  useEffect(()=> {
    getCrypto()
  }, [])

  useEffect(()=> {
    if (token) {
      getEarnings()
    }
  }, [token, activeTab, selectedToken, range])

  const handleSearch = ()=> {
    getEarnings()
  }

  const handleReset = ()=> {
    setSelectedToken({})
    setRange(prev =>({
      from: new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000),
      to: new Date()
    }))
  }

  return (
    <>
      <div className="my-3">
        <div className="stackingTabs">
          <button onClick={()=>setActiveTab('Flexible')} className={`stackingTab ${activeTab === 'Flexible' ? 'active' : ''}`}>Flexible</button>
          <button onClick={()=>setActiveTab('Fixed-Term')} className={`stackingTab ${activeTab === 'Fixed-Term' ? 'active' : ''}`}>Fixed-Term</button>
        </div>
      </div>
      <div className="mb-3 row g-2 g-md-3">
        <div className="col-6 col-lg-3">
          <div className="stackingDropdown">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                Token : <span className='text-uppercase'> {selectedToken?.code ? selectedToken?.code : 'All'}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div class="searchInp">
                  <img src="./assets/img/searchGray.png" alt=""/>
                  <input type="text" class="form-control shadow-none" placeholder="Search"/>
                </div>
                
                <Dropdown.Item as="button" onClick={()=>setSelectedToken({})}>
                  All
                </Dropdown.Item>
                {
                  cryptoList?.length ?
                    cryptoList?.map((item)=> (
                      <Dropdown.Item as="button" onClick={()=>setSelectedToken(item)}>
                        <img src={item?.icon} alt="" />
                        {item?.code}
                      </Dropdown.Item>
                    )) : ''
                }
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        
        <div className="col-6 col-lg-3">
          <div className="stackingDropdown">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                Type : <span> {selectedType}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                  <Dropdown.Item onClick={()=>setSelectedType('Claim')} as="button">Claim</Dropdown.Item>
                  <Dropdown.Item onClick={()=>setSelectedType('Interest')} as="button">Interest</Dropdown.Item>
                  <Dropdown.Item onClick={()=>setSelectedType('Subscription')} as="button">Subscription</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="stackingDropdown">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <img className='calendarIcon' src="./assets/img/stacking/calendar.svg" alt="" />
                {moment(range?.from).format('l')} - {moment(range?.to).format('l')}
              </Dropdown.Toggle>
              <Dropdown.Menu className='calendarMenu'>
                <DayPicker
                  id="test"
                  mode="range"
                  defaultMonth={pastMonth}
                  selected={range}
                  toDate={new Date()}
                  // footer={footer}
                  onSelect={setRange}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="col-sm-6 col-lg-3">
          <div className="row g-2 g-md-3">
            <div className="col-6">
              <button className='commonBlueBtn small w-100' onClick={handleSearch}>Search</button>
            </div>
            <div className="col-6 ">
              <button className='commonLightBtn small w-100' onClick={handleReset}>Reset</button>
            </div>
          </div>
        </div>
      </div>
      <div className="stackingHistoryTable table-responsive">
        {
          data?.length ? 
            <table className="table text-white">
              <thead>
                <tr>
                  <th>token</th>
                  <th>date</th>
                  <th>amount</th>
                  <th>est.apr</th>
                </tr>
              </thead>
              <tbody>
                {
                    data?.map(item=>(
                      <tr key={item?._id}>
                        <td className='bold'>
                          <div className="d-flex align-items-center text-uppercase">
                              <img className='coinImg' src={item?.coin_icon} alt="" />
                              {item?.coin_name}
                          </div>
                        </td>
                        <td>
                          <span className='d-block'>{moment(item?.createdAt).format('L')}</span>
                          <span className='d-block'>{moment(item?.createdAt).format('LTS')}</span>
                        </td>
                        <td>
                          
                        {Number(item?.amount)?.toFixed(8)}{item?.coin_name?.toUpperCase()} <br />
                        {/* {userDetails?.currency?.symbol}{item?.amount_cnv?.toFixed(8)} */}
                        </td>
                        <td className='bold'>{item?.apr}%</td>
                      </tr>
                    ))
                }
              </tbody>
            </table>
          : <div className="mt-3">
              <NoData/>
          </div>
        }
      </div>
    </>
  )
}

export default StackingHistory