import React from 'react'
import { useNavigate } from 'react-router-dom';
import errorLogo from '../../assets/images/error.webp';
import star from '../../assets/images/star.svg';
import heart from '../../assets/images/fillHeart.svg';
import share from '../../assets/images/share.svg';
import vedio from '../../assets/images/vedio.svg';
import screen from '../../assets/images/screen.svg';
import errorPageLogo from '../../assets/images/errorPageLogo.webp';

const GameErrorScreen = () => {
  const navigate = useNavigate()

  const handleClick = ()=> {
    window.parent.postMessage('navigate-home', '*');
  }
  return (
    <>
      {/* <div className="vh-100 d-flex align-items-center justify-content-center p-3 gameErrorPage">
        <div className="text-white text-center gameErrorHeadingTxt d-flex flex-column align-items-center justify-content-center">
        <img src={logo}  className='w-100 mb-2 object-fit-contain'  alt=""  />
        <div className='mt-3'>
          Game is not available in your country, try disabling VPN
        </div>
        <button onClick={handleClick} className='returnBtn mt-4'>Return to Home Page</button>
          </div>
      </div> */}
      <div className="container-fluid vh-100">
        <div className="container h-100 w-100 d-flex align-content-center justify-content-center">
        <div className="gameErrorPageInner d-flex flex-column align-items-center justify-content-center w-100">
               <img className='gameErrorPageInnerlogo object-fit-cover mx-auto' src={errorLogo} alt="" />
               <div className="gameErrorPageInnerheading mx-auto">
                <img className='h-100 w-100' src={errorPageLogo} alt="" />
               </div>
               <div className="gameErrorPageInnerSubH mx-auto">Games is not available in your country, try disabling VPN</div>
               <button onClick={handleClick} className='gameErrorPageInnerBtn'>Return to Home Page</button>
             </div>
        </div>
      </div>
    </>
  )
}

export default GameErrorScreen