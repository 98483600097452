import React, { useEffect, useRef, useState } from 'react'
import { useResultModal } from '../../hooks/useResult';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RESPONSIVE_WIDTH } from '../../constants';
import { useTranslation } from 'react-i18next';
import { postAPIAuth } from '../../services/apiInstance';
import { Spinner } from 'react-bootstrap';
import SearchInput from './components/SearchInput';
import ProviderSelect from './components/ProviderSelect';
import SortBySelect from './components/SortBySelect';
import { useAuth } from '../../hooks/useAuth';
import { useMediaQuery } from '../../utils/useMediaQuery';
import GameCard from '../GameCard/GameCard';
import SearchPopNew from '../searchpop/SearchPopNew';
import { useGames } from '../../hooks/useGame';

const ResultModal = () => {
    // const searchValue = useLocation()?.search?.replace(/\?/, '')
    const userDetails = useSelector(state => state?.userDetails)
    const [gameList, setGameList] = useState([])
    const [search, setSearch] = useState('')
    const [sortBy, setSortBy] = useState('')
    const [selectedProvider, setSelectedProvider] = useState([])
    const [paging, setPaging] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [loader, setLoader] = useState(true)
    const [isLoadMore, setIsLoadMore] = useState(false)
    const debounceRef = useRef(null)
    const [noData, setNoData] = useState(false)
    const isMobile = useMediaQuery(RESPONSIVE_WIDTH.MOBILE_SCREEN)
    const isSmScreen = useMediaQuery(RESPONSIVE_WIDTH.SM_SCREEN)
    const isMdScreen = useMediaQuery(RESPONSIVE_WIDTH.MD_SCREEN)
    const isLgScreen = useMediaQuery(RESPONSIVE_WIDTH.LG_SCREEN)
    const isXlScreen = useMediaQuery(RESPONSIVE_WIDTH.XL_SCREEN)
    const is1500Screen = useMediaQuery(RESPONSIVE_WIDTH.MAX_1500)
    const is1320Screen = useMediaQuery(RESPONSIVE_WIDTH.MAX_1320)
    const is1120Screen = useMediaQuery(RESPONSIVE_WIDTH.MAX_1120)
    const [isClassAdded, setClassAdded] = useState(false);
    const [isClassAdded1, setClassAdded1] = useState(false);
    const { resultObject, setResultObject } = useResultModal()
    const {gameObject} = useGames()

    console.log('search', search)

    const { t } = useTranslation()
    const allGamesLabels = t("allGamesLabels", { returnObjects: true })
    const commonText = t('commonText', { returnObjects: true })
    const resultModal = t('resultModal', { returnObjects: true })
    const {token} = useAuth()
    // Function to get game list
    const getGameList = async () => {
        if(resultObject?.isResultOpen ) {
        
        
        setLoader(true)
        let params = {
            page: 1,
            userId: userDetails?.id,
            is_mobile: isMobile ? "true" : "false",
            stockCategoryId : gameObject?.selectedId ? gameObject?.selectedId : '',
            limit: isMobile ? 9 : isSmScreen ? 12 : isMdScreen ? 12 : is1120Screen ? 10 : isLgScreen ? 12 : is1320Screen ? 12 : isXlScreen ? 14 : is1500Screen ? 14 : 16,
            // limit: isMobile ? 9 : isSmScreen ? 12 : isMdScreen ? 10 : isLgScreen ? 10 : isXlScreen ? 12 : 14,
        }

        // name: search, sortBy: sortBy, 
        if (search) params.name = search;
        if (sortBy) params.sortBy = sortBy;
        if (selectedProvider?.length) params.providerNamesArray = selectedProvider

        const result = await postAPIAuth(`crypto/get-games`, params, token)
        // const result = await getAPI(`games/get-games-by-cat?is_mobile=${isMobile ? "true" : "false"}&categoryId=${selectedCategory}&providerId=${selectedProvider}&gameNameSort=${sortBy}&gameLimit=14&page=${pageNo + 1}`)

        if (result?.data?.success) {
            setGameList(result.data.data)
        } else {
            setGameList([])
        }
        setLoader(false)
        setNoData(false)
    }
    }

    const loadMore = async (page) => {
        setIsLoadMore(true)
        let params = {
            page: page ? page : 1,
            userId: userDetails.id,
            is_mobile: isMobile ? "true" : "false",
            // limit: isMobile ? 9 : isSmScreen ? 12 : isMdScreen ? 10 : isLgScreen ? 10 : isXlScreen ? 12 : 14,
            stockCategoryId : gameObject?.selectedId ? gameObject?.selectedId : '',
            limit: isMobile ? 9 : isSmScreen ? 12 : isMdScreen ? 12 : is1120Screen ? 10 : isLgScreen ? 12 : is1320Screen ? 12 : isXlScreen ? 14 : is1500Screen ? 14 : 16,

        }
        if (search) params.name = search.trim();
        if (sortBy) params.sortBy = sortBy;
        if (selectedProvider?.length) params.providerNamesArray = selectedProvider

        const result = await postAPIAuth(`crypto/get-games`, params, token)
        if (result?.data?.success) {
            if (page) setPaging(prev => prev + 1)
            if (!totalPage) setTotalPage(result.data.allData)
            if (page) setGameList(prev => ([...prev, ...result.data.data]))
            else setGameList(result.data.data)
            setNoData(false)

        } else {
            setNoData(true)
            // setGameList([])
        }
        setIsLoadMore(false)
    }

    useEffect(() => {
        //debouncing
        if (debounceRef?.current) {
            clearTimeout(debounceRef?.current)
        }
        debounceRef.current = setTimeout(() => {
            getGameList()
        }, 400)
        return () => clearTimeout(debounceRef?.current)
    }, [search, sortBy, selectedProvider, token, resultObject?.isResultOpen, gameObject?.selectedId])


    // useEffect(() => {
    //     setSearch(searchValue)
    // }, [searchValue])





    const handleButtonClick = () => {
        setClassAdded(!isClassAdded);
        setClassAdded1(false);

    };
    const handleButtonClick1 = () => {
        setClassAdded1(!isClassAdded1);
        setClassAdded(false);
    };
    const closeModal=() =>{
        setResultObject((prev) => ({
             isResultOpen: false
          }))
        
    }    
    console.log(selectedProvider)
    return (
        // <>
        //     <div className={`modal fade resultModalWrapper ${resultObject?.isResultOpen ? 'show d-block' : ''}`} id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        //         <div className="modal-dialog modal-fullscreen resultModalDialog">
        //             <div className="modal-content resultModalcontent">
        //                 <div className={`modal-header ${isClassAdded ? 'added-class' : ''} ${isClassAdded1 ? 'added-classs' : ''}`}>
        //                     <div className="container">
        //                     <div className="row align-items-center w-100 mx-0">
        //                         <div className="col-auto ps-0">
        //                             {/* <div className="modal-title" id="exampleModalLabel">RESULTS:</div> */}
        //                             <div className="modal-title" id="exampleModalLabel">{resultModal.RESULTS}:</div>
        //                         </div>
        //                         <div className="col d-flex justify-content-end">
        //                             <div className="row w-100 justify-content-end">
        //                                 <SearchInput search={search} setSearch={setSearch} />

        //                                 <div className="col-auto d-md-block d-none">
        //                                     {
        //                                         resultObject?.isResultOpen ? 
        //                                             <ProviderSelect
        //                                                 handleButtonClick={handleButtonClick}
        //                                                 selectedProvider={selectedProvider}
        //                                                 setSelectedProvider={setSelectedProvider}
        //                                             />
        //                                         : ''
        //                                     }
        //                                 </div>
        //                                 <div className="col-auto d-md-block d-none">
        //                                     {/* sort by */}
        //                                     <SortBySelect
        //                                         handleButtonClick1={handleButtonClick1}
        //                                         setSortBy={setSortBy}
        //                                         sortBy={sortBy}
        //                                     />

        //                                 </div>
        //                                 <div className="col-auto d-flex justify-content-center align-items-center">
        //                                     <button className="closeModal btn-close p-0 d-flex"
        //                                     style={{opacity: 1}}
        //                                         onClick={() => setResultObject((prev) => ({
        //                                             isResultOpen: false
        //                                         }))}
        //                                     >
        //                                         <img style={{
        //                                             height: 15,
                                                    
        //                                         }} src="assets/img/offcanvasclose.png" alt="" />
        //                                     </button>
        //                                 </div>
        //                             </div>
        //                             {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
        //                         </div>

        //                         <div className="col-12 px-0">
        //                             <div className="row mx-0 mt-3 justify-content-end">
        //                                 <div className="col-6 d-block d-md-none ps-0">
        //                                     {
        //                                         resultObject?.isResultOpen ?
        //                                             <ProviderSelect
        //                                                 handleButtonClick={handleButtonClick}
        //                                                 selectedProvider={selectedProvider}
        //                                                 setSelectedProvider={setSelectedProvider}
        //                                             />
        //                                         : ''
        //                                     }
        //                                     {/* <div className="dropDown">
        //                                         <button onClick={handleButtonClick} className=" dropBtn" type="button">
        //                                             Provider : <span>ALL</span>
        //                                         </button>
        //                                         <div className="dropMenu">
        //                                             <div className="row">
        //                                                 <div className="col-12">
        //                                                     <div className="dropHeader position-relative">
        //                                                         <input className='dropSearch' type="search" name="" id="" />
        //                                                         <div className="serachImg">
        //                                                             <img src="assets/img/heroBanner/searchh.png" alt="" />
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>
        //                                                 <div className="col-12 mt-2 bodyWrapper">
        //                                                     <div className="dropBody d-flex align-items-center">
        //                                                         <div className="form-check formCheck">
        //                                                             <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1" />
        //                                                             <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault1">
        //                                                                 <div className='dropdownCheckbox'></div>
        //                                                                 <div className='dropdownTxt'>Provider</div>
        //                                                                 <div className='dropdownValue ms-auto'>100</div>
        //                                                             </label>
        //                                                         </div>
        //                                                     </div>
        //                                                     <div className="dropBody mt-2 d-flex align-items-center">
        //                                                         <div className="form-check formCheck">
        //                                                             <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault11" id="flexRadioDefault11" />
        //                                                             <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault11">
        //                                                                 <div className='dropdownCheckbox'></div>
        //                                                                 <div className='dropdownTxt'>Provider</div>
        //                                                                 <div className='dropdownValue ms-auto'>100</div>
        //                                                             </label>
        //                                                         </div>
        //                                                     </div>
        //                                                     <div className="dropBody mt-2 d-flex align-items-center">
        //                                                         <div className="form-check formCheck">
        //                                                             <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault12" id="flexRadioDefault12" />
        //                                                             <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault12">
        //                                                                 <div className='dropdownCheckbox'></div>
        //                                                                 <div className='dropdownTxt'>Provider</div>
        //                                                                 <div className='dropdownValue ms-auto'>100</div>
        //                                                             </label>
        //                                                         </div>
        //                                                     </div>
        //                                                     <div className="dropBody mt-2 d-flex align-items-center">
        //                                                         <div className="form-check formCheck">
        //                                                             <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault12" id="flexRadioDefault12" />
        //                                                             <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault12">
        //                                                                 <div className='dropdownCheckbox'></div>
        //                                                                 <div className='dropdownTxt'>Provider</div>
        //                                                                 <div className='dropdownValue ms-auto'>100</div>
        //                                                             </label>
        //                                                         </div>
        //                                                     </div>
        //                                                     <div className="dropBody mt-2 d-flex align-items-center">
        //                                                         <div className="form-check formCheck">
        //                                                             <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault12" id="flexRadioDefault12" />
        //                                                             <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault12">
        //                                                                 <div className='dropdownCheckbox'></div>
        //                                                                 <div className='dropdownTxt'>Provider</div>
        //                                                                 <div className='dropdownValue ms-auto'>100</div>
        //                                                             </label>
        //                                                         </div>
        //                                                     </div>
        //                                                     <div className="dropBody mt-2 d-flex align-items-center">
        //                                                         <div className="form-check formCheck">
        //                                                             <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault12" id="flexRadioDefault12" />
        //                                                             <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault12">
        //                                                                 <div className='dropdownCheckbox'></div>
        //                                                                 <div className='dropdownTxt'>Provider</div>
        //                                                                 <div className='dropdownValue ms-auto'>100</div>
        //                                                             </label>
        //                                                         </div>
        //                                                     </div>
        //                                                     <div className="dropBody mt-2 d-flex align-items-center">
        //                                                         <div className="form-check formCheck">
        //                                                             <input className="dropdownInp  d-none" type="checkbox" name="flexRadioDefault12" id="flexRadioDefault12" />
        //                                                             <label className="dropdownLabel d-flex align-items-center" for="flexRadioDefault12">
        //                                                                 <div className='dropdownCheckbox'></div>
        //                                                                 <div className='dropdownTxt'>Provider</div>
        //                                                                 <div className='dropdownValue ms-auto'>100</div>
        //                                                             </label>
        //                                                         </div>
        //                                                     </div>
        //                                                 </div>
        //                                                 <div className="col-12">
        //                                                     <div className="dropFooter"></div>
        //                                                 </div>
        //                                             </div>
        //                                         </div>
        //                                     </div> */}
        //                                 </div>
        //                                 <div className="col-6 d-block d-md-none pe-0">
        //                                     <SortBySelect
        //                                         handleButtonClick1={handleButtonClick1}
        //                                         setSortBy={setSortBy}
        //                                         sortBy={sortBy}
        //                                     />

        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     </div>
        //                 </div>
        //                 <div className="modal-body toprated-games-main gameSearchPage">
        //                     <div className="container">
        //                         <div className="row toprated-games mx-0">
        //                             {/* <div className="col-12 px-0 games-card-main">
        //                             <div className="row mySwiper searchGameCardWrapper w-100 g-2 row-cols-8  casinoCard1 ">
        //                                 <div className="games-card h-100">
        //                                     <div className="games-img rounded-2 position-relative bigImg">
        //                                         <img src="https://stage.gis-static.com/games/3962be5e18b1e84fdd95613e87dfda1a/Thunderkick/29db6352d7edc6cd328a78b1dc320aab4e71b10a.png" alt="card" className="h-100 w-100 img-fluid rounded-2"/>
        //                                         <div className="overlay">
        //                                             <div className="row mx-0">
        //                                                 <div className="col-12">
        //                                                     <div className="overlayTxt text-uppercase">Triple Royal Gold</div>
        //                                                 </div>
        //                                                 <div className="col-12">
        //                                                     <div className="overlayTxt mt-1">EFFECTIVE RTP</div>
        //                                                 </div>
        //                                                 <div className="col-12">
        //                                                     <div className="overlayTxt1 overlayTxt2">0%</div>
        //                                                 </div>
        //                                                 <div className="col-12">
        //                                                     <button className="addToFav "> Add to favorites</button>
        //                                                 </div>
        //                                             </div>
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div> */}
        //                             {/* <div className="row toprated-games gap-2 mx-0 pt-4"> */}
        //                                 {
        //                                     loader && gameList.length === 0 ? (
        //                                         <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        //                                             <Spinner animation="border" variant="light" size="lg" />
        //                                         </div>
        //                                     ) : gameList.length === 0 ? (
        //                                         <div className="text-light">{commonText.NoDataAvailable}</div>
        //                                     ) : (
        //                                         <></>
        //                                     )
        //                                 }
        //                                 <div className="col-12 px-0 games-card-main">
        //                                     <div
        //                                         className="row mySwiper searchGameCardWrapper w-100 g-3 row-cols-8  casinoCard1 "
        //                                     >
        //                                         {gameList.length === 0 ? (
        //                                             <>
        //                                                 {/* // <div>No data available</div> */}
        //                                             </>
        //                                         ) : (
        //                                             gameList?.map((item, index) => (
        //                                                 <div className="a"  onClick={() => setResultObject((prev) => ({
        //                                                     isResultOpen: false
        //                                                 }))}>

        //                                                     <GameCard
        //                                                     data={item}
        //                                                     />
        //                                                 </div>
        //                                             ))
        //                                         )}
        //                                     </div>
        //                                 </div>
        //                                 <div className="col-12 mt-4">
        //                                     <div className="d-flex align-items-center justify-content-center loadmoremain">
        //                                         {
        //                                             !loader ? (
        //                                                 <span

        //                                                     onClick={() => {
        //                                                         if (noData) return
        //                                                         if (gameList.length === 0) {
        //                                                             setSearch('')
        //                                                             setSortBy('')
        //                                                             setSelectedProvider([])
        //                                                         } else {
        //                                                             loadMore(paging + 1)
        //                                                         }
        //                                                     }}
        //                                                     className="text-decoration-none loadBtn text-light rounded-2 cursor-pointer"
        //                                                 >
        //                                                     {
        //                                                         isLoadMore ? (
        //                                                             <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        //                                                                 <Spinner animation="border" variant="light" size="sm" />
        //                                                             </div>
        //                                                         ) : (
        //                                                             noData ? (
        //                                                                 commonText.NoDataAvailable
        //                                                             ) : (
        //                                                                 gameList.length === 0 ? (
        //                                                                     allGamesLabels.REMOVE_FILTERS
        //                                                                     // 'REMOVE FILTERS'
        //                                                                 ) : (
        //                                                                     allGamesLabels.LOAD_MORE
        //                                                                     // 'LOAD MORE'
        //                                                                 )
        //                                                             )
        //                                                         )
        //                                                     }
        //                                                 </span>
        //                                             ) : (
        //                                                 <></>
        //                                             )
        //                                         }
        //                                     </div>
        //                                 </div>
        //                             {/* </div> */}

        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </>

        <SearchPopNew 
        close={closeModal}
        resultObject={resultObject}
        setResultObject={setResultObject}
        loader={loader}
        gameList={gameList}
        searchGame={search}
        setSearchGame={setSearch}
        // handleButtonClick1={handleButtonClick1}
        setSortBy={setSortBy}
        sortBy={sortBy}
        // handleButtonClick={handleButtonClick}
        selectedProvider={selectedProvider}
        setSelectedProvider={setSelectedProvider}
        loadMore={loadMore}
        noData={noData}
        paging={paging}
        isLoadMore={isLoadMore}
        />
    )
}

export default ResultModal