import axios from "axios";
import { BT_TOKEN_NAME, TOKEN_NAME, baseURL } from "./../constants";
// import { useAuth } from "../hooks/useAuth";
import { signOut } from "firebase/auth";
import { auth } from "../config/firebase";
// const baseURL = 'http://3.6.235.42:3000/api'
// export const baseURL = "https://api.beta.damble.io/api"
const token = localStorage.getItem(TOKEN_NAME);
// no auth token required
export const getAPI = async (url, body) => {
  // try {
  const response = await axios.get(`${baseURL}/${url}`, body, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    },
  });
  return response;
  // } catch (error) {
  // return error
  // }
};

export const getAPIAuth = async (url, tokenInit ,setToken) => {
  const token = localStorage.getItem(TOKEN_NAME);
  try {
    const response = await axios.get(`${baseURL}/${url}`, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        Authorization: `Bearer ${tokenInit ? tokenInit : token}`,
      },
    });
    

    return response;
  } catch (error) {
    // return  error
    if (error?.response.data.status === 401 && setToken) {
      setToken("") 
  }
    if (error?.response?.data?.msg === "Invalid token") {
      localStorage.removeItem(TOKEN_NAME);
      localStorage.removeItem(BT_TOKEN_NAME);
      setToken("")
      signOut(auth)
        .then(() => {
          // succesToaster("Logged Out")
        })
        .catch((error) => {
          // An error happened.
        });
      // window.location.reload(true);
    }
    throw new Error(error);
  }
};

// no auth token required
export const postAPI = async (url, params) => {
  // try {
  const response = await axios.post(`${baseURL}/${url}`, params, {
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Accept: "application/json",
    },
  });
  return response;
  // } catch (error) {
  // return error

  // }
};

export const postAPIAuth = async (url, params, tokenInit) => {
  const token = localStorage.getItem(TOKEN_NAME);
  try {
    const response = await axios.post(`${baseURL}/${url}`, params, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
        Authorization: `Bearer ${tokenInit ? tokenInit : token}`,
      },
    });
    return response;
  } catch (error) {
    if (error?.response?.data?.msg === "Invalid token") {
      localStorage.removeItem(TOKEN_NAME);
      localStorage.removeItem(BT_TOKEN_NAME);
      signOut(auth)
        .then(() => {
          // succesToaster("Logged Out")
        })
        .catch((error) => {
          // An error happened.
        });
      // window.location.reload(true);
    }
    throw new Error(error);

    //   return error
  }
};

export const postAPIAuthFormData = async (url, params, tokenInit) => {
  const token = localStorage.getItem(TOKEN_NAME);
  try {
    const response = await axios({
      method: "post",
      url: `${baseURL}/${url}`,
      data: params,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${tokenInit ? tokenInit : token}`,
      },
    });
    return response;
  } catch (error) {
    
  }
};


export const deleteAPIAuth = async (url) => {
  const token = localStorage.getItem(TOKEN_NAME);
  try {
    const response = await axios.delete(`${baseURL}/${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
