import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AppLayout from '../Layout/AppLayout'
import Home from '../pages/home/Home'
import Promotion from '../pages/promotion/Promotion'
import Amlpolicy from '../pages/amlPolicy/Amlpolicy'
import Termsconditions from '../pages/termsConditions/Termsconditions'
import Responsibility from '../pages/responsibility/Responsibility'
import SportsPolicy from '../pages/sportsPolicy/SportsPolicy'
import { ROUTE_CONST } from '../constants'
import PromotionDetail from '../pages/promotion/PromotionDetail'
import CasinoPage from '../pages/casino'
import Providers from '../pages/Providers/Providers'
import GameScreen from '../pages/GameScreen/GameScreen'
import LoadingScreen from '../pages/LoadingScreen/LoadingScreen'
import Bonus from '../pages/bonus/Bonus'
import ProviderGameList from '../pages/providerGameList/ProviderGameList'
import AccountPage from '../pages/accountPage'
import Sports from '../pages/sports/Sports'
import SportsLayout from '../Layout/SportsLayout'
import Poker from '../pages/poker/Poker'
import Protected from './ProtectedRoutes'
import CryptoStacking from './../pages/cryptoStacking/CryptoStacking'
import AboutDgwToken from './../pages/cryptoStacking/AboutDgwToken'
import AboutStacking from './../pages/cryptoStacking/AboutStacking'
import DGWStacking from './../pages/cryptoStacking/DGWStacking'
import Dashboard from '../pages/dashboard/Dashboard'
import ComingSoon from '../pages/comingSoon'
import Sponsership from '../pages/sponsership'
import SponsershipDetail from '../pages/sponsershipDetail'
import GameErrorScreen from '../pages/gameErrorScreen/GameErrorScreen'
import PageNotFound from '../pages/pageNotFound/PageNotFound'
import AffiliateParent from '../pages/affilliatePage'
import VIP from '../pages/VIP/VIP'
import Wallet from '../pages/Wallet/Wallet'
import WalletIndex from '../pages/Wallet/WalletIndex'

import Profile2 from '../pages/Profile2/Profile2'



const RoutesComp = () => {
  return (
    <>
      <Routes>
        <Route path={'/'} element={<AppLayout Component={Home} />} />
        <Route path={'/promotion'} element={<AppLayout Component={Promotion} />} />
        <Route path={'/amlpolicy'} element={<AppLayout Component={Amlpolicy} />} />
        <Route path={'/termsconditions'} element={<AppLayout Component={Termsconditions} />} />
        <Route path={'/responsibility'} element={<AppLayout Component={Responsibility} />} />
        <Route path={'/sportspolicy'} element={<AppLayout Component={SportsPolicy} />} />
        <Route path={'/loadingScreen'} Component={LoadingScreen} />
        <Route path={ROUTE_CONST.AFFILIATE_PAGE} element={<AppLayout Component={AffiliateParent} />} />
        <Route path={ROUTE_CONST.VIP_PAGE} element={<AppLayout Component={VIP} />} />


        <Route path={ROUTE_CONST.HOME_PAGE} element={<AppLayout Component={Home} />} />
        <Route path={ROUTE_CONST.PROMOTION} element={<AppLayout Component={Promotion} />} />
        <Route path={`${ROUTE_CONST.PROMOTIONDETAIL}/:promotionId`} element={<AppLayout Component={PromotionDetail} />} />
        <Route path={ROUTE_CONST.PROFILE} element={<Protected Component={AccountPage} />} />
        <Route path={ROUTE_CONST.WALLET} element={<Protected Component={WalletIndex} />} />
        <Route path={ROUTE_CONST.PROFILE2} element={<Protected Component={Profile2} />} />
        <Route path={ROUTE_CONST.WALLET} element={<Protected Component={Wallet} />} />
        <Route path={ROUTE_CONST.CASINO} element={<AppLayout Component={CasinoPage} />} />
        <Route path={`${ROUTE_CONST.CASINO}/:gameId`} element={<Protected Component={GameScreen} />} />
        <Route path={ROUTE_CONST.PROVIDERS} element={<AppLayout Component={Providers} />} />
        <Route path={`${ROUTE_CONST.PROVIDERS}/:providerName`} element={<AppLayout Component={ProviderGameList} />} />
        <Route path={ROUTE_CONST.PROVIDERS_GAME_LIST} element={<AppLayout Component={ProviderGameList} />} />
        {/* <Route path={ROUTE_CONST.BONUS} element={<AppLayout Component={ComingSoon} />} /> */}
        <Route path={ROUTE_CONST.BONUS} element={<Protected Component={Bonus} />} />
        <Route path={ROUTE_CONST.SPORTS_PAGE} element={<SportsLayout Component={Sports} isSports={true}/>}/>
        <Route path={ROUTE_CONST.POKER_PAGE} element={<SportsLayout Component={Poker} />} />
        {/* <Route path={ROUTE_CONST.SPORTS_PAGE} element={<AppLayout Component={ComingSoon} />} /> */}
        {/* <Route path={ROUTE_CONST.POKER_PAGE} element={<AppLayout Component={Home}/>}/> */}
        <Route path={ROUTE_CONST.CRYPTO_STAKING} element={<AppLayout Component={CryptoStacking} />} />
        {/* <Route path={ROUTE_CONST.CRYPTO_STAKING} element={<AppLayout Component={ComingSoon} />} /> */}
        <Route path={ROUTE_CONST.ABOUT_DGW_TOKEN} element={<AppLayout Component={AboutDgwToken} />} />
        <Route path={ROUTE_CONST.ABOUT_STAKING} element={<AppLayout Component={AboutStacking} />} />
        <Route path={ROUTE_CONST.DGW_STAKING} element={<AppLayout Component={DGWStacking} />} />
        <Route path={ROUTE_CONST.DASHBOARD} element={<AppLayout Component={Dashboard} />} />
        <Route path={ROUTE_CONST.COMING_SOON} element={<AppLayout Component={ComingSoon} />} />
        <Route path={ROUTE_CONST.SPONSERSHIP} element={<AppLayout Component={Sponsership} />} />
        <Route path={`${ROUTE_CONST.SPONSERSHIP}/:id`} element={<AppLayout Component={SponsershipDetail} />} />
        <Route path={`/game-error`} element={<GameErrorScreen />} />
        <Route path={'*'} element={<PageNotFound/>}/>

      </Routes>
    </>
  );
};

export default RoutesComp;
