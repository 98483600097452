import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import banner from '../../../assets/images/weeklyBouns.png'
import moment from 'moment/moment';
import { useNotification } from '../../../hooks/useNotification';
import { useAuth } from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { getAPIAuth } from '../../../services/apiInstance';

const MessageComp = ({ message, id }) => {
    useEffect(() => {
        const element = document.getElementById(id)
        element.innerHTML = message

    }, [message])//react-hooks/exhaustive-deps

    return (
        <div className="text" id={id}>
            {message}
        </div>
    )
}

const returnShortMsg = (msg) => {
    const data = msg.substring(0, 70);
    return data
}

const Activities = () => {
    const [notification, setNotification] = useState([])
    const [perPage, setPerPage] = useState(2)
    const [pageNo, setPageNo] = useState(1)
    const [totalPage, setTotalPage] = useState(null)
    const { isNotificationOpen } = useNotification()
    const { token } = useAuth()
    const { t } = useTranslation()
    const notificationModal = t("notificationModal", { returnObjects: true })
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);




    const getNotification = async () => {
        try {
            // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws"
            const res = await getAPIAuth(`user/get-global-notification?page=${pageNo}&perPage=${perPage}&notificationType=activity`, token)
            if (res?.data?.success) {
                setNotification(res.data?.data)
                setTotalPage(res?.data?.pagination?.[0]?.totalPages)
            }
        } catch (error) {
        }
    }

    const loadMore = async () => {
        try {
            // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws"
            const res = await getAPIAuth(`user/get-global-notification?page=${pageNo + 1}&perPage=${perPage}&notificationType=activity`, token)
            if (res?.data?.success) {
                setNotification([...notification, ...res.data?.data])
                setPageNo(pageNo + 1)
                setTotalPage(res?.data?.pagination?.[0]?.totalPages)
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        if (token && isNotificationOpen) {
            getNotification()
        }
    }, [token, isNotificationOpen])

    return (
        <>
            <div className="col-12 flex-fill">


                <div className='col-12 d-flex align-item-center '>
                    <div className='col-12 d-flex flex-column  notification_section'>
                        <span className='date'> 23/12/2024, 10:56:19</span>
                        <h2 className='heading'>Its Weekly Sports Bonus Time!</h2>
                        <div className='notification_heading_image'     >
                            <img src="assets\img\notification_heading.jpeg" alt="" />
                        </div>
                        <p className='heading_p'>Weekly Sports bonus has been dropped
                            successfully!</p>

                        <div className='d-flex justify-content-end '>
                            <button className="show_btn text-white" onClick={() => setIsOpen1(!isOpen1)}>
                                {isOpen1 ? "Hide all" : "Show all"}
                            </button>
                        </div>

                        <div className="accordion" id="accordionExample">

                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingOne" style={{ borderBottom: "none " }}>
                                    <button
                                        className="accordion-button px-0"
                                        type="button"
                                        
                                      data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne1"
                                         aria-expanded="true"
                                      
                                        aria-controls="collapseOne1"
                                    >

                                    </button>
                                </h2>
                                <div id="collapseOne1" className={`accordion-collapse collapse ${isOpen1 ? "show" : ""}`} aria-labelledby="headingOne1">

                                    <div className="accordion-body">
                                        <ul className="notification_ul p-0">
                                            <li className="item">- Wager period Saturday January 6th to Friday January 12th</li>
                                            <li className="item">- Minimum VIP level 22+</li>
                                            <li className="item">- Wager $500 or more = $5 bonus</li>
                                            <li className="item">- Wager $2,500 or more = $30 bonus</li>
                                            <li className="item">- Wager $5,000 or more = $70 bonus</li>
                                            <li className="item">- Wager $10,000 or more = $150 bonus</li>
                                            <p className="mt-2">
                                                Grab your weekly bonus boost every Saturday with <strong>DAMBLE Sports Club!</strong>
                                            </p>
                                            <span className="mt-3">DAMBLE</span>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                            <div className="accordion-item">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button
                                        className="accordion-button px-0 collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded={isOpen1}
                                        aria-controls="collapseTwo"
                                    >
                                        <p>Weekly Sports Bonus? What is this? How can I take part?</p>
                                        <span>
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path>
                                            </svg>
                                        </span>
                                    </button>
                                </h2>
                                <div
                                    id="collapseTwo"
                                    className={`accordion-collapse collapse ${isOpen1 ? "show" : ""}`}
                                    aria-labelledby="headingTwo"
                                >
                                    <div className="accordion-body">
                                        <p>
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempora perspiciatis illum ullam nesciunt odit ipsum
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-12 mt-3'>
                    <div className='col-12 d-flex flex-column  notification_section'>
                        <span className='date'> 23/12/2024, 10:56:19</span>
                        <h2 className='heading'>Update: 10 Free Spins on Sea Boat
                            Adventure</h2>
                        <h4 className='heading_p'>Due to technical issues with Sea Boat Adventures free rounds,</h4>
                        <div className='d-flex justify-content-end '>
                            <button className="show_btn text-white" onClick={() => setIsOpen2(!isOpen2)}>
                                {isOpen2 ? "Hide all" : "Show all"}
                            </button>
                        </div>

                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingthree">
                                    <button class="accordion-button px-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapsethree" aria-expanded={isOpen2} >
                                        <p>Refer to the complete update here</p>
                                        <span>
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path>
                                            </svg>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapsethree" className={`accordion-collapse collapse ${isOpen2 ? "show" : ""}`} aria-labelledby="headingthree" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempora perspiciatis illum ullam nesciunt odit ipsum
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>



                      
                    </div>
                </div>
                <div className='col-12 mt-3'>
                    <div className='col-12 d-flex flex-column  notification_section'>
                        <span className='date'> 23/12/2024, 10:56:19</span>
                        <h2 className='heading'>Update: 10 Free Spins on Sea Boat
                            Adventure</h2>
                        <h4 className='heading_p'>Due to technical issues with Sea Boat Adventures free rounds,</h4>
                        <div className='d-flex justify-content-end '>
                            <button className="show_btn text-white" onClick={() => setIsOpen3(!isOpen3)}>
                                {isOpen3 ? "Hide all" : "Show all"}
                            </button>
                        </div>

                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="headingfour">
                                    <button class="accordion-button px-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded={isOpen3} >
                                        <p>Refer to the complete update here</p>
                                        <span>
                                            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path>
                                            </svg>
                                        </span>
                                    </button>
                                </h2>
                                <div id="collapsefour" className={`accordion-collapse collapse ${isOpen3 ? "show" : ""}`} aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <p>
                                            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempora perspiciatis illum ullam nesciunt odit ipsum
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>



                        {/* <span className='Show_all' >Hide all <span><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path></svg></span> </span>
<div className='hide_all_div pt-3' >
<p>Refer to the complete update here</p>
<span><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg></span>
</div> */}
                    </div>
                </div>

            </div>

        </>
    )
}

export default Activities