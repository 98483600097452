import React, { useEffect, useState } from 'react'
import eye from './../../../../assets/images/eye.png'
import signupBtn from './../../../../assets/images/signupbtntxt.png'
import Accordion from 'react-bootstrap/Accordion';
import { useAuthModal } from '../../../../hooks/useAuthModal';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { getAPI, postAPI } from '../../../../services/apiInstance';
import { errorToaster, succesToaster } from '../../../../utils/toaster';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { auth } from '../../../../config/firebase';
import * as Yup from "yup";
import { Formik } from 'formik/dist';
import ErrorMessage from '../../../common/errorMessage/ErrorMessage';
import { Spinner } from 'react-bootstrap';
import SignUpOtp from './SignUpOtp';


const validationSchema = Yup.object({
    number: Yup.string()
      .required("Phone Number is required")
      .matches(
        /^[0-9]{10}$/,
        "Invalid mobile number"
      ),
  });

const SignUpMobile = ({  browserName,
    setChecks,
    checks,
    click_id,
    new_click_id,
    bonus,}) => {
  const [isActive, setIsActive] = useState(false);
  const [isOtp, setIsOtp] = useState(false)
  const [isMetamask, setIsMetamask] = useState(false);
  const { authModalObject } = useAuthModal();
  const [isTelegram, setIsTelegram] = useState(false);
  const [userObj, setUserObj] = useState({});
  const [matchedItem, setmatchedItem] = useState([]);
  const [countrycodedata, setCountryCode] = useState([]);
  const countryName = useSelector((state) => state.country);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false)
  const { token } = useAuth()
  const [select, setSelect] = useState('')
  const [number, setNumber] = useState('')
  const ipAddress = useSelector(state => state.ipAddress)
  const [referral, setReferral] = useState(localStorage.getItem("middlePart"))
  const { t } = useTranslation()
  const authLabels = t("AuthLabels", { returnObjects: true }) 

  const handleButtonClick = () => {
    setIsActive(!isActive); 
    };

    
  const onSubmit = async (values, resetForm) => {
    if (!checks.terms) {
      errorToaster("User agreement is required");
      setIsLoading(false);
      return;
    }
    setNumber(values.number);
    setReferral(values.referral);
    if (!!values.referral) {
      const res = await postAPI("user/check-email", {
        email: "",
        refer_code: values.referral,
      });
      if (res?.data?.status) {
        handleSend(values, resetForm);

        // setIsLoading(false);
      } else {
        errorToaster(res?.data?.message)
        setIsLoading(false);
      }
      return
    }
    handleSend(values, resetForm);

  };

  const generateRecaptcha = (random) => {
    const recaptchaParent = document.getElementById("recaptcha-parent");
    if (recaptchaParent) {
      recaptchaParent.innerHTML = `<span id=${random} />`
    }
    window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
      `${random}`,
      {
        size: "invisible",
        callback: (response) => { },
      }
      
    );
    window.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  };

  const handleSend = (values, resetForm) => {
    const random = Math.random() + 1000000
    generateRecaptcha(random);
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(
      auth,
      `${select?.phoneCode ? select.phoneCode : "+91"} ${values.number}`,
      appVerifier
    ) //dynamic code
      .then((confirmationResult) => {
        succesToaster("OTP SENT!");
        // setOtpId(confirmationResult.verificationId);
        window.confirmationResult = confirmationResult;
        // setActive(false);
        // setOtpId(true);
        // setIsNumber(false)
        window?.recaptchaVerifier?.recaptcha?.reset(window.recaptchaWidgetId)
        setIsOtp(true)
      })
      .catch((error) => {
        errorToaster(error.message);
        const recaptchaContainer = document.getElementById(`${random}`)
        if (recaptchaContainer) { recaptchaContainer.remove(); }
        window?.recaptchaVerifier?.recaptcha?.reset(window.recaptchaWidgetId)
      })
      .finally(() => {
        setIsLoading(false);
        resetForm()
        setChecks(pre => ({ ...pre, terms: false }))
      });
  };
  const CountryCodeFun = async () => {
    const res = await getAPI("get-countries");
    setCountryCode(res.data.data);
  };
  useEffect(() => {
    const codedata =
      countrycodedata.filter((item) => item.country == countryName).length == 0
        ? countrycodedata
        : countrycodedata.filter((item) => item.country === countryName);
    setmatchedItem(countrycodedata);
    setSelect(countryName && codedata[0]);
    localStorage.setItem("country_Id", codedata[0]?._id);
  }, [countryName, countrycodedata]);

  useEffect(() => {
    if (!token) {
      CountryCodeFun();
    }
  }, []);

  // useEffect(() => {
  //   if (!isNumber) {
  //     setIsLoading(false);
  //   }
  // }, [isNumber]);

  const handlesearch = (val) => {
    if (val.length > 0) {
      const filterdata = countrycodedata?.filter((item) =>
        item.country.toLowerCase().includes(val.toLowerCase()) || item.phoneCode.toLowerCase().includes(val.toLowerCase())
      );
      setmatchedItem(filterdata);
    } else {
      setmatchedItem(countrycodedata)
    }
  };
  return (
    <>
        {
            !isOtp ? (
                <>
            <Formik
            initialValues={{
              number: "",
              referral: referral
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              setIsLoading(true);
              onSubmit(values, resetForm);
            }}
          >
            {
              ({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
              }) => (
                <form>

                                                        <div className={`col-12 py-2 position-relative dropWrapper ${isActive ? 'active' : ''}`}>
                                                          <div className="position-relative">
                                                              <input className='commonInp commonInpNumber' 
                                                                    name="number"
                                                                    id="number"
                                                                    value={values.number}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    type='text'
                                                                // placeholder='Phone number'
                                                                placeholder={authLabels.Phone_Number}
                                                                 />
                                                              <div onClick={handleButtonClick} className='numberWrapper'> {select?.phoneCode ? select?.phoneCode : "+91"}
                                                                  <svg className='' xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none"><path d="M5.02282 5.73994C4.92844 5.74049 4.83488 5.7224 4.74751 5.68671C4.66014 5.65102 4.58067 5.59844 4.51366 5.53198L0.210901 1.22922C0.0758633 1.09418 0 0.911032 0 0.72006C0 0.529089 0.0758633 0.345938 0.210901 0.210901C0.345938 0.0758633 0.529089 0 0.72006 0C0.911032 0 1.09418 0.0758633 1.22922 0.210901L5.02282 4.01167L8.81641 0.218072C8.9536 0.100588 9.13007 0.0391974 9.31055 0.0461687C9.49104 0.05314 9.66224 0.12796 9.78996 0.255676C9.91768 0.383392 9.99249 0.554598 9.99946 0.735082C10.0064 0.915565 9.94505 1.09203 9.82756 1.22922L5.52481 5.53198C5.39123 5.66446 5.21095 5.73915 5.02282 5.73994Z" fill="#ffffff"></path></svg>
                                                              </div>
                                                              <div className="counteryCodeWrapper">
                                                                  <div className="row overflow-y-auto h-100">
                                                                      <div className="col-12 mb-2 position-relative">
                                                                          <input 
                                                                            className='commonInp commonInpSearch' 
                                                                            placeholder='Search' 
                                                                            type="text" 
                                                                            />
                                                                          <div className="searchImg">
                                                                              <svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5ZM9.30884 10.0159C8.53901 10.6318 7.56251 11 6.5 11C4.01472 11 2 8.98528 2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 7.56251 10.6318 8.53901 10.0159 9.30884L12.8536 12.1464C13.0488 12.3417 13.0488 12.6583 12.8536 12.8536C12.6583 13.0488 12.3417 13.0488 12.1464 12.8536L9.30884 10.0159Z" fill="currentColor"></path></svg>
                                                                          </div>
                                                                      </div>
                                                                      <div className="col-12 d-flex flex-column gap-2">
                                                                      { matchedItem?.length && matchedItem?.map((item, index)=>(
                                                                        <div className="row countryList mx-0 py-2" 
                                                                            
                                                                                key={index}
                                                                                onClick={() => {
                                                                                    setSelect(item);
                                                                                    localStorage.setItem("country_Id", item?._id)
                                                                                    setIsOpen(false)
                                                                                    setIsActive(false)
                                                                                }}
                                                                        >
                                                                            <div className="col-auto"><div className="numberTxt">{item.phoneCode}</div></div>
                                                                            <div className="col ps-0">
                                                                                <div className="counteryName">{item.country}</div>
                                                                            </div>
                                                                        </div>
                                                                      ))}
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                        </div>
                                                        
                                                        <ErrorMessage>
                                                        {
                                                            errors.number &&
                                                            touched.number &&
                                                            errors.number
                                                        }
                                                        </ErrorMessage>
                                                        {/* <div className="col-12 position-relative">
                                                            <input 
                                                                className='commonInp' 
                                                                type="mail" name="" id="" 
                                                                placeholder='Login Password' />
                                                            <div className="eyeImg">
                                                                <img src={eye} alt="" />
                                                            </div>
                                                        </div> */}
                                                        <div className="col-12 pt-2">
                                                        <Accordion defaultActiveKey="0">
                                                            <Accordion.Item className='accordionItem' eventKey="0">
                                                                <Accordion.Header className='accordionHeader'>
                                                                  {/* Enter Referral/Promo Code  */}
                                                                  {authLabels.Enter_Referral}/{authLabels.Promo_Code}
                                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg></Accordion.Header>
                                                                <Accordion.Body className='accordionBody'>
                                                                    <input 
                                                                        className='commonInp' 
                                                                        type="mail"
                                                                        // placeholder='Enter Referral/Promo Code (optional)' 
                                                                       placeholder={`${authLabels.Enter_Referral}/${authLabels.Promo_Code}`}

                                                                        name="referral"
                                                                        id="referral"
                                                                        value={values.referral}
                                                                        onChange={handleChange}/>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                        </div>
                                                        <div className="col-12 py-3">
                                                            <div class="form-check">
                                                                <input class="form-check-input checkboxInp" 
                                                                type="checkbox" value="" id="flexCheckDefault"
                                                                checked={checks.terms}
                                                                onChange={(e) => {
                                                                setChecks(pre => ({
                                                                    ...pre,
                                                                    terms: e.target.checked,
                                                                }))
                                                                }}
                                                                />
                                                                <label class="form-check-label checkboxLbl" for="flexCheckDefault">
                                                                    {/* I agree to the <span>User Agreement</span> & confirm I am at least 18 years old */}
                                                                 {authLabels.I_agree_to_the} <span>{authLabels?.User_Agreement}</span> {authLabels.confirm_I_am}
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input checkboxInp" 
                                                                type="checkbox" value="" id="flexCheckDefault"
                                                                checked={checks.promotions}
                                                                onChange={(e) => {
                                                                  setChecks(pre => ({
                                                                    ...pre,
                                                                    promotions: e.target.checked,
                                                                  }))
                                                                }}
                                                                />
                                                                <label class="form-check-label checkboxLbl" for="flexCheckDefault">
                                                                    {/* I agree to receive marketing promotions from  */}
                                                                 {authLabels.I_agree_to_receive_marketing}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <button className='signUpBtn'
                                                            type='submit'
                                                                                  onClick={(e) => {
                                                                                    e.preventDefault()
                                                                                    handleSubmit()
                                                                                  }}>
                                                                                    {isLoading ? <Spinner size="sm" variant="light"/> : <img src={signupBtn} alt="" />}
                                                                                    
                                                            </button>
                                                        </div>
                  <div id="recaptcha-parent" />
                </form>
            )
          }

        </Formik>
                </>
            ) : <SignUpOtp
                browserName={browserName}
                checks={checks}
                referral={referral}
                click_id={click_id}
                new_click_id={new_click_id}
                setIsOtp={setIsOtp}
                select={select}
                setSelect={setSelect}
                number={number}
                setChecks={setChecks}
                bonus={bonus}
            />
        }

    </>
  )
}

export default SignUpMobile