import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ROUTE_CONST, USER_LANG } from "../../constants";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase";
import { getAPI, postAPI } from "../../services/apiInstance";
import { errorToaster, succesToaster } from "../../utils/toaster";
import { Spinner } from "react-bootstrap";
import NoData from "../../components/noData/NoData";
import affiliateProgram from "../../assets/images/affiliate-program.png";
import workStep1 from "../../assets/images/work-step-1.png";
import workStep2 from "../../assets/images/work-step-2.png";
import workStep3 from "../../assets/images/work-step-3.png";
import workStepIcon1 from "../../assets/images/work-step-icon-1.svg";
import workStepIcon3 from "../../assets/images/work-step-icon-3.svg";
import aboutAffiliate from "../../assets/images/about-affiliate.png";

const AffilliatePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const affiliateLabels = t("affiliateLabels", { returnObjects: true });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [browserName, setBrowserName] = useState();
  const ipAddress = useSelector((state) => state.ipAddress);
  const country = useSelector((state) => state.country);
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [select, setSelect] = useState("");
  const [matchedItem, setmatchedItem] = useState([]);
  const [countrycodedata, setCountryCode] = useState([]);
  const countryName = useSelector((state) => state.country);
  const country_id = localStorage.getItem("country_Id");

  const [checks, setChecks] = useState({
    terms: false,
  });

  const [values, setValues] = useState({
    email: "",
    password: "",
    username: "",
    phone: "",
  });

  console.log("values", values);

  const CountryCodeFun = async () => {
    const res = await getAPI("get-countries");
    setCountryCode(res.data.data);
  };

  useEffect(() => {
    CountryCodeFun();
  }, []);

  const handleChange = (e) => {
    setValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClick = async () => {
    if (!checks.terms) {
      errorToaster("User agreement is required");
      setIsLoading(false);
      return;
    }
    if (!values?.username) {
      errorToaster("Enter valid username");
      setIsLoading(false);
      return;
    }
    if (!values?.email) {
      errorToaster("Enter valid email");
      setIsLoading(false);
      return;
    }
    if (!values?.password) {
      errorToaster("Enter password");
      setIsLoading(false);
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      const user = userCredential.user;
      // const lang = JSON.parse(localStorage.getItem(USER_LANG))
      const lang = localStorage.getItem(USER_LANG);
      const body = {
        email: userCredential._tokenResponse.email,
        idToken: userCredential._tokenResponse.idToken,
        localId: userCredential._tokenResponse.localId,
        refreshToken: userCredential._tokenResponse.refreshToken,
        password: values.password,
        terms: checks.terms,
        promotion: checks.promotions,
        username: values?.username,
        ipAddress: ipAddress ? ipAddress : "",
        country: country ? country : "",
        language: lang,
        refercode: values.referral ? values.referral : "",
        browser: browserName,
        isLoginWithEmail: true,
        phone: values?.phone,
        phoneCodeId:
          country_id === "undefined" ? "654ca959a2a15f1ce43977d4" : country_id,
      };

      const res = await postAPI("user/influencerRequest", body);
      if (res.data.success) {
        succesToaster(res.data.message);
        setValues({
          email: "",
          password: "",
          username: "",
          phone: "",
        });
        window?.dataLayer?.push({
          event: "GA4_event",
          event_name: "game_sign_up",
        });
      } else {
        errorToaster(res?.data?.message);
      }
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message.substr(9, 30);
      if (error.message === "Firebase: Error (auth/email-already-in-use).") {
        errorToaster("Email already exists");
      } else {
        errorToaster(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleButtonClick = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    const codedata =
      countrycodedata.filter((item) => item.country == countryName).length == 0
        ? countrycodedata
        : countrycodedata.filter((item) => item.country === countryName);
    setmatchedItem(countrycodedata);
    setSelect(countryName && codedata[0]);
    localStorage.setItem("country_Id", codedata[0]?._id);
  }, [countryName, countrycodedata]);

  const handlesearch = (val) => {
    const valdata = val.trim();
    if (valdata.length > 0) {
      const filterdata = countrycodedata?.filter(
        (item) =>
          item.country.toLowerCase().includes(val.toLowerCase()) ||
          item.phoneCode.toLowerCase().includes(val.toLowerCase())
      );
      setmatchedItem(filterdata);
    } else {
      setmatchedItem(countrycodedata);
    }
  };

  return (
    <>
      <div className="affiliatepagenew">
        <div className="container-fluid affiliate_program_sec mb-5">
          <div className="row">
            <div className="col-12">
              <h2 className="affiliateheading">Example Affiliate Program</h2>
            </div>
            <div className="col-12 mt-3">
              <div className="row align-items-center p-3 m-0 rounded-3" style={{background:'#202733'}}>
                  <div className="col-lg-6 col-md-12 col-12 order-lg-1 order-md-2 order-2">
                    <div className="program_content">
                       <h5 className="text-white m-0" style={{fontWeight:'500'}}>Refer players now and start earning house edge!</h5>
                       <p className="my-3">Refer our brand and earn money through participating in Shuffle’s Affiliate Program. You will receive an affiliate commission for every user that registers and plays on Shuffle.com through your links.</p>
                       
                       <div className="btn_group d-flex align-items-center gap-3 mt-4">
                           <button type="button" className="rounded-pill btn_colored border-0">Get started</button>
                           <button type="button" className="rounded-pill btn_trans border-0">Learn More</button>
                       </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12 order-lg-2 order-md-1 order-1 mb-lg-0 mb-3">
                      <div className="program_img">
                        <img src={affiliateProgram} alt="" className="img-fluid w-100" />
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid work_sec mb-5">
            <div className="container">
                <div className="row">
                     <div className="col-12">
                       <h4 className="affiliateheading">How does it work?</h4>
                     </div>
                    <div className="col-12 px-0">
                    <div className="row m-0 g-4">
                       <div className="col-lg-4 col-md-4 col-12">
                            <div className="work_box">
                                 <div className="work_img position-relative">
                                    <img src={workStep1} alt="" className="img-fluid work_bg_img" />
                                    <div className="work_img_icons p-4 position-absolute gap-2 h-100 top-0 w-100 d-flex align-items-center justify-content-between">
                                        <span className="step_txt fw-bold text-white">Step 1</span>
                                        <span><img src={workStepIcon1} alt="" className="img-fluid" /></span>
                                    </div>
                                 </div>
                                 <p className="text-white m-0 pt-2">Create an account at Shuffle.com, follow the registration process and join the Shuffle community! You can find information regarding the Shuffle Affiliate Network and generating your unique referral code here.</p>
                            </div>
                       </div>
                       <div className="col-lg-4 col-md-4 col-12">
                            <div className="work_box">
                                 <div className="work_img position-relative">
                                    <img src={workStep2} alt="" className="img-fluid work_bg_img" />
                                    <div className="work_img_icons p-4 position-absolute h-100 top-0 w-100 d-flex align-items-center justify-content-between">
                                        <span className="step_txt fw-bold" style={{color:'#0E95FA'}}>Step 2</span>
                                        {/* <span><img src={workStepIcon1} alt="" className="img-fluid" /></span> */}
                                    </div>
                                 </div>
                                 <p className="text-white m-0 pt-2">Generate your unique code and share it with friends. Your friends can use your code to get exclusive sign up bonuses. You can also create multiple codes for tracking campaigns.</p>
                            </div>
                       </div>
                       <div className="col-lg-4 col-md-4 col-12">
                            <div className="work_box">
                                 <div className="work_img position-relative">
                                    <img src={workStep3} alt="" className="img-fluid work_bg_img" />
                                    <div className="work_img_icons p-4 position-absolute h-100 top-0 w-100 d-flex align-items-center justify-content-between">
                                        <span className="step_txt fw-bold text-white">Step 3</span>
                                        <span><img src={workStepIcon3} alt="" className="img-fluid" /></span>
                                    </div>
                                 </div>
                                 <p className="text-white m-0 pt-2">Earn 10%+ of net revenue from the house edge for every bet made. Take money from Shuffle, not your friends. Earnings are based on theoretical house edge (0.1 Net Theoretical House Edge Wagered Amount).</p>
                            </div>
                       </div>
                     </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="container-fluid about_affiliate_sec mb-5">
          <div className="row">
            <div className="col-12 mt-3">
              <div className="row align-items-center p-3 m-0 rounded-3" style={{background:'#202733'}}>
              <div className="col-lg-5 col-md-6 col-12">
                      <div className="about_img text-center">
                        <img src={aboutAffiliate} alt="" className="img-fluid" />
                      </div>
                  </div>
                  <div className="col-lg-7 col-md-6 col-12">
                    <div className="about_content">
                       <h4 className="text-white m-0">Learn more about our <span style={{color:"#0e95fa"}}>Affiliate program</span></h4>
                       <p className="my-3">If you have a large audience and followers. We have special conditions for you to customize your referral program!
                       If you can invite players and their wager amount reaches a billion dollars and above, you will get your own customized casino! You can set up a casino website with your domain and design style.</p>
                       <p>for more details, please contact Us</p>               
                    </div>
                     <div className="about_input position-relative d-flex align-items-center justify-content-between gap-3 rounded-pill w-100 p-3">
                         <input type="text" placeholder="business@example" className="bg-transparent border-0 shadow-none w-100" />
                         <button type="button" className="border-0 rounded-pill px-4 py-2 flex-shrink-0">Send Now</button>
                     </div>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid faq_sec mb-5">
                <div className="row">
                     <div className="col-12">
                       <h4 className="affiliateheading">Frequently Asked Questions</h4>
                     </div>
                    <div className="col-12 mt-4">
                    <div className="accordion d-flex flex-column gap-4" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              What is the example Affiliate Program?
                            </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div className="accordion-body text-white">
                               Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, neque ipsa possimus aliquid itaque eligendi, quaerat sit numquam sunt excepturi quibusdam esse omnis vel veritatis similique dolorum quia. Reiciendis, vero!
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                How do I join the example Affiliate Program?
                            </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div className="accordion-body text-white">
                               Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, neque ipsa possimus aliquid itaque eligendi, quaerat sit numquam sunt excepturi quibusdam esse omnis vel veritatis similique dolorum quia. Reiciendis, vero!
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                               How do I promote example products as an affiliate?
                            </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                            <div className="accordion-body text-white">
                               Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, neque ipsa possimus aliquid itaque eligendi, quaerat sit numquam sunt excepturi quibusdam esse omnis vel veritatis similique dolorum quia. Reiciendis, vero!
                            </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                               How do I earn commissions through the example Affiliate Program?
                            </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                            <div className="accordion-body text-white">
                               Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, neque ipsa possimus aliquid itaque eligendi, quaerat sit numquam sunt excepturi quibusdam esse omnis vel veritatis similique dolorum quia. Reiciendis, vero!
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                How do I get paid as a example affiliate?
                            </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                            <div className="accordion-body text-white">
                               Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, neque ipsa possimus aliquid itaque eligendi, quaerat sit numquam sunt excepturi quibusdam esse omnis vel veritatis similique dolorum quia. Reiciendis, vero!
                            </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSix">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                              Is there any support available for affiliates in the example Affiliate Program?
                            </button>
                            </h2>
                            <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                            <div className="accordion-body text-white">
                               Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum, neque ipsa possimus aliquid itaque eligendi, quaerat sit numquam sunt excepturi quibusdam esse omnis vel veritatis similique dolorum quia. Reiciendis, vero!
                            </div>
                            </div>
                        </div>
                     
                        </div>
                    </div>
            </div>
        </div>
        {/* <div className="container-fluid px-0 partnershipSection">
                            <div className="affiliateheading">
                                {affiliateLabels.Damble_partnership}
                            </div>
                            <div className="partnershiptitle">
                                {affiliateLabels.The_most_rewarding}
                            </div>
                            <div className="row partnershipCardWrapper parnershipCardMain">
                                <div className="col-sm-auto col-12 partnerShipColAuto1">
                                    <div className="row partnershipcolAuto mx-0 h-100">
                                        <div className="col-md-12 col-auto iconCol d-flex justify-content-center align-items-center">
                                            <div className="casinoicon d-flex align-items-center justify-content-center">
                                                <img src="assets/img/affiliatenew/casino.png" alt="casino" className="h-100 w-100" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 col txtCol d-flex flex-column justify-content-md-center align-items-md-center">
                                            <div className="gamename">CASINO</div>
                                            <div className="cardTitle d-md-none">+ 20,000 Games for the Best Casino experience.</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm col-12 partnerShipCol">
                                    <div className="row partnershipcolAuto mx-0 align-items-center h-100">
                                        <div className="col-lg col-12">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="cardTitle d-none d-md-block">+ 20,000 Games for the Best Casino experience.</div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="carddetail">We provide users with more than 20.000 games from Slots to live casino games & more. All our Games are from the top providers for the best Casino Experience.</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-auto col-12 mt-4 mt-lg-0">
                                            <button
                                                onClick={() => navigate(ROUTE_CONST.INDEX)}
                                                className="gotoBtn">Go to Casino<img src="assets/img/affiliatenew/gotocasino.png" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row partnershipCardWrapper parnershipCardMain">
                                <div className="col-sm-auto col-12 partnerShipColAuto1">
                                    <div className="row partnershipcolAuto mx-0 h-100">
                                        <div className="col-md-12 col-auto iconCol d-flex justify-content-center align-items-center">
                                            <div className="casinoicon d-flex align-items-center justify-content-center">
                                                <img src="assets/img/affiliatenew/sport.png" alt="casino" className="h-100 w-100" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 col txtCol d-flex flex-column justify-content-md-center align-items-md-center">
                                            <div className="gamename">SPORTS</div>
                                            <div className="cardTitle d-md-none">25+ Sports 1000+ Betting Markets 60 000+ events per Months</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm col-12 partnerShipCol">
                                    <div className="row partnershipcolAuto mx-0 align-items-center h-100">
                                        <div className="col-lg col-12">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="cardTitle d-none d-md-block">25+ Sports 1000+ Betting Markets 60 000+ events per Months</div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="carddetail">Damble sportsbook offers the widest range of sports events, from live sports to Esports betting, free bets & boosted odds are also available for the greatest betting experience. </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-auto col-12 mt-4 mt-lg-0">
                                            <button
                                                onClick={() => navigate(ROUTE_CONST.SPORTS)}
                                                className="gotoBtn">Go to Sports<img src="assets/img/affiliatenew/gotocasino.png" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row partnershipCardWrapper parnershipCardMain">
                                <div className="col-sm-auto col-12 partnerShipColAuto1">
                                    <div className="row partnershipcolAuto mx-0 h-100">
                                        <div className="col-md-12 col-auto iconCol d-flex justify-content-center align-items-center">
                                            <div className="casinoicon d-flex align-items-center justify-content-center">
                                                <img src="assets/img/affiliatenew/staking.png" alt="casino" className="h-100 w-100" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 col txtCol d-flex flex-column justify-content-md-center align-items-md-center">
                                            <div className="gamename">STAKING</div>
                                            <div className="cardTitle d-md-none">Stake your crypto and get up to 50% APR!</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm col-12 partnerShipCol">
                                    <div className="row partnershipcolAuto mx-0 align-items-center h-100">
                                        <div className="col-lg col-12">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="cardTitle d-none d-md-block">Stake your crypto and get up to 50% APR!</div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="carddetail">Damble allows you to stake your crypto in the platform where you can earn up to 50% APR! Stake your crypto and start earning! </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-auto col-12 mt-4 mt-lg-0">
                                        <button
                                                onClick={() => navigate(ROUTE_CONST.CRYPTO_STAKING)}
                                                className="gotoBtn">Go to Stacking<img src="assets/img/affiliatenew/gotocasino.png" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
        {/* <div className="container-fluid px-0 commissinplanSection">
                            <div className="affiliateheading">
                                {affiliateLabels.Сommission_plan}
                            </div>
                            <div className="commissioncard">
                                <div className="row">
                                    <div className="col-12 commissionData">
                                        {affiliateLabels.The_DAMBLE_Affiliate_program}
                                    </div>
                                    <div className="col-12 commissionTitle">
                                        *{affiliateLabels.Reward_for_Casino}
                                    </div>
                                </div>
                            </div>
                        </div> */}
        {/* <div className="container-fluid px-0 becomepartnerSection">
                            <div className="affiliateheading">
                                {affiliateLabels.Why_becoming}
                            </div>
                            <div className="row partnerCardMain">
                                <div className="col-md-6">
                                    <div className="partnerCard position-relative h-100 d-flex flex-column justify-content-between">
                                        <div>
                                            <div className="partnerHeading">
                                                {affiliateLabels.International_brand}
                                            </div>
                                            <div className="partnerDetail">
                                                {affiliateLabels.Damble_is_online}
                                            </div>
                                        </div>
                                        <div className="cardfooter d-flex align-items-center justify-content-between">
                                            <a href="javascript:;" className="d-flex w-100 stretched-link align-items-center text-decoration-none justify-content-center cardlink">
                                                {affiliateLabels.Check_our_Celebrity}
                                                <span className="d-flex align-items-center justify-content-center linkicon">
                                                    <img src="assets/img/affiliatenew/gotocasino.png" alt="check our celebrity list" className="h-100 w-100 img-fluid" />
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4 mt-md-0">
                                    <div className="partnerCard position-relative h-100 d-flex flex-column justify-content-between">
                                        <div>
                                            <div className="partnerHeading">
                                                {affiliateLabels.Professional_Support}
                                            </div>
                                            <div className="partnerDetail">
                                                {affiliateLabels.You_will_have}
                                            </div>
                                        </div>
                                        <div className="cardfooter d-flex align-items-center justify-content-between">
                                            <a href="javascript:;" className="d-flex w-100 stretched-link align-items-center text-decoration-none justify-content-center cardlink">
                                                {affiliateLabels.Contact_us}
                                                <span className="d-flex align-items-center justify-content-center linkicon">
                                                    <img src="assets/img/affiliatenew/gotocasino.png" alt="check our celebrity list" className="h-100 w-100 img-fluid" />
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div className="partnerCard position-relative h-100 d-flex flex-column justify-content-between">
                                        <div>
                                            <div className="partnerHeading">
                                                {affiliateLabels.Favorable_payouts}
                                            </div>
                                            <div className="partnerDetail">
                                                {affiliateLabels.With_our_program}
                                            </div>
                                        </div>
                                        <div className="cardfooter d-flex align-items-center justify-content-between">
                                            <a href="javascript:;" className="d-flex w-100 stretched-link align-items-center text-decoration-none justify-content-center cardlink">
                                                {affiliateLabels.Contact_us}
                                                <span className="d-flex align-items-center justify-content-center linkicon">
                                                    <img src="assets/img/affiliatenew/gotocasino.png" alt="check our celebrity list" className="h-100 w-100 img-fluid" />
                                                </span>
                                            </a>
                                        </div>
                                        <div className="ngrtitle ngrtitle1">
                                            {affiliateLabels.Net_gaming_revenue}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-4">
                                    <div
                                        onClick={() => {
                                            dispatch({
                                                type: "AFFILIATE",
                                                payload: "Affiliate",
                                            })
                                            // navigate(ROUTE_CONST.ACCOUNTE)
                                        }
                                        }
                                        className="partnerCard position-relative h-100 d-flex flex-column justify-content-between">
                                        <div>
                                            <div className="partnerHeading">
                                                {affiliateLabels.Traffic_performance}
                                            </div>
                                            <div className="partnerDetail">
                                                {affiliateLabels.You_will_have_access}
                                            </div>
                                        </div>
                                        <div className="cardfooter d-flex align-items-center justify-content-between">
                                            <Link to={ROUTE_CONST.ACCOUNT} className="d-flex w-100 stretched-link align-items-center text-decoration-none justify-content-center cardlink">
                                                {affiliateLabels.Go_to_Dashboard}
                                                <span className="d-flex align-items-center justify-content-center linkicon">
                                                    <img src="assets/img/affiliatenew/gotocasino.png" alt="check our celebrity list" className="h-100 w-100 img-fluid" />
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
        {/* <div className="container-fluid px-0 guidelinesSection">
                            <div className="affiliateheading">
                                {affiliateLabels.Guidelines}
                            </div>
                            <div className="row guidelinescardmain g-sm-3 g-2">
                                <div className="col-lg-3 col-sm-6">
                                    <div className="guidelinescard h-100">
                                        <div className="guidelinesimg d-flex align-items-center justify-content-center">
                                            <img src="assets/img/affiliatenew/marketing.png" alt="marketing" className="h-100 img-fluid" />
                                        </div>
                                        <div className="guidelinedata">
                                            {affiliateLabels.Affiliate_must_exclusively}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="guidelinescard h-100">
                                        <div className="guidelinesimg d-flex align-items-center justify-content-center">
                                            <img src="assets/img/affiliatenew/review.png" alt="review" className="h-100 img-fluid" />
                                        </div>
                                        <div className="guidelinedata">
                                            {affiliateLabels.Affiliate_must_represent}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="guidelinescard h-100">
                                        <div className="guidelinesimg d-flex align-items-center justify-content-center">
                                            <img src="assets/img/affiliatenew/notification.png" alt="notification" className="h-100 img-fluid" />
                                        </div>
                                        <div className="guidelinedata">
                                            {affiliateLabels.Affiliate_must_notify}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="guidelinescard h-100">
                                        <div className="guidelinesimg d-flex align-items-center justify-content-center">
                                            <img src="assets/img/affiliatenew/clipboard.png" alt="clipboard" className="h-100 img-fluid" />
                                        </div>
                                        <div className="guidelinedata">
                                            {affiliateLabels.Damble_reserves_the}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="guidelinescard cardlast d-flex align-items-lg-center justify-content-center">
                                        <div className="guidelinesimg d-flex align-items-center justify-content-center">
                                            <img src="assets/img/affiliatenew/streaming.png" alt="streaming" className="h-100 img-fluid" />
                                        </div>
                                        <div className="guidelinedata mt-0 text-start">
                                            {affiliateLabels.Affiliate_must_actively}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

        {/* <div className="container-fluid px-0 faqpage">
                            <div className="affiliateheading">
                                {affiliateLabels.FAQ}
                            </div>
                            <div className="faqCard">
                                <div className="row faqMain">
                                    <div className="col-12">
                                        <div className="faqinner">
                                            <div className="question d-flex align-items-end">
                                                <div className="faqcount">01</div>
                                                <span className='d-inline-block'>{affiliateLabels.What_is_an}</span>
                                            </div>
                                            <div className="answer">
                                                {affiliateLabels.Affiliate_Program_is}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="faqinner">
                                            <div className="question d-flex align-items-end">
                                                <div className="faqcount">02</div>
                                                <span className="d-inline-block">
                                                    {affiliateLabels.How_do_I}</span>
                                            </div>
                                            <div className="answer">
                                                {affiliateLabels.Whenever_a_player}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="faqinner border-0 pb-0">
                                            <div className="question d-flex align-items-end">
                                                <div className="faqcount">03</div>
                                                <span className=" d-inline-block">
                                                    {affiliateLabels.earn_commission}</span>
                                            </div>
                                            <div className="answer">
                                                {affiliateLabels.When_you_refer}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

        {/* <div className="container-fluid px-0 JoinusSection">
                            <div className="affiliateheading">
                                {affiliateLabels.Join_us}
                            </div>
                            <div className="joinuscard">
                                <div className="row g-3">
                                    <div className="col-12 signuptitle">
                                        {affiliateLabels.Sign_Up}
                                    </div>
                                    <div className="col-lg-8">
                                        <div className="row g-3">
                                            <div className="col-md-6 order-1">
                                                <div className="inputmain position-relative">
                                                    <input 
                                                        type="text" 
                                                        id="nameinput" 
                                                        name='username'
                                                        value={values?.username}
                                                        onChange={(e)=>handleChange(e)}
                                                        className="form-control shadow-none affiliateInput bg-transparent rounded-0 border-0 h-100" 
                                                        placeholder='Name'/> */}
        {/* <label for="nameinput" className="affiliateinputLabel position-absolute">{affiliateLabels.Name}</label> */}
        {/* </div> */}
        {/* </div> */}
        {/* <div className="col-md-6 order-md-2 order-5">
                                                <div className="socialmedia d-flex align-items-center gap-2 w-100">
                                                    <div className="dropdown instagramdropdown">
                                                        <button className="btn instabtn dropdown-toggle d-flex align-items-center justify-content-center" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                                            Instagram
                                                        </button>
                                                        <ul className="dropdown-menu instamenu w-100">
                                                            <li>
                                                                <a className="dropdown-item socialmedialink bg-transparent active mt-0" href="javascript:;">Instagram</a>
                                                            </li>
                                                            <li>
                                                                <a className="dropdown-item socialmedialink bg-transparent" href="javascript:;">Facebook</a>
                                                            </li>
                                                            <li>
                                                                <a className="dropdown-item socialmedialink bg-transparent" href="javascript:;">Youtube</a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="inputmain position-relative w-100">
                                                        <input type="text" id="yourinstagram" className="form-control shadow-none affiliateInput bg-transparent rounded-0 border-0 h-100" />
                                                        <label for="yourinstagram" className="affiliateinputLabel position-absolute">{affiliateLabels.Your} Instagram</label>
                                                    </div>
                                                </div>
                                            </div> */}
        {/* <div className="col-md-6 order-md-3 order-2">
                                                <div className="inputmain position-relative">
                                                    <input 
                                                        name='email'
                                                        value={values?.email}
                                                        onChange={(e)=>handleChange(e)} type="email" id="emailinput" className="form-control shadow-none affiliateInput bg-transparent rounded-0 border-0 h-100" placeholder='Email' /> */}
        {/* <label for="emailinput" className="affiliateinputLabel position-absolute">{affiliateLabels.Email}</label> */}
        {/* </div>
                                            </div>
                                            <div className="col-md-6 order-md-4 order-last">
                                                <div className="inputmain position-relative">
                                                    <input 
                                                        name='phone'
                                                        value={values?.phone}
                                                        style={{paddingLeft: 75}}
                                                        onChange={(e)=>handleChange(e)} type="text" id="phoneNumber" className="form-control shadow-none affiliateInput bg-transparent rounded-0 border-0 h-100" placeholder='Phone Number' /> */}
        {/* <label for="phoneNumber" className="affiliateinputLabel position-absolute">{affiliateLabels.Your_Company_name}</label> */}
        {/*                                                     
                                                    <div onClick={handleButtonClick} className='numberWrapper' style={{left: 10}}> {select?.phoneCode ? select?.phoneCode : "+91"}
                                                                  <svg className='' xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none"><path d="M5.02282 5.73994C4.92844 5.74049 4.83488 5.7224 4.74751 5.68671C4.66014 5.65102 4.58067 5.59844 4.51366 5.53198L0.210901 1.22922C0.0758633 1.09418 0 0.911032 0 0.72006C0 0.529089 0.0758633 0.345938 0.210901 0.210901C0.345938 0.0758633 0.529089 0 0.72006 0C0.911032 0 1.09418 0.0758633 1.22922 0.210901L5.02282 4.01167L8.81641 0.218072C8.9536 0.100588 9.13007 0.0391974 9.31055 0.0461687C9.49104 0.05314 9.66224 0.12796 9.78996 0.255676C9.91768 0.383392 9.99249 0.554598 9.99946 0.735082C10.0064 0.915565 9.94505 1.09203 9.82756 1.22922L5.52481 5.53198C5.39123 5.66446 5.21095 5.73915 5.02282 5.73994Z" fill="#ffffff"></path></svg>
                                                              </div>
                                                              <div className={`counteryCodeWrapper ${isActive && 'd-block'}`}>
                                                                  <div className="row overflow-y-auto h-100">
                                                                      <div className="col-12 mb-2 position-relative">
                                                                          <input 
                                                                            className='commonInp commonInpSearch' 
                                                                            onChange={(e) => handlesearch(e.target.value)}
                                                                            placeholder='Search' 
                                                                            type="text" 
                                                                            />
                                                                          <div className="searchImg">
                                                                              <svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 15 15" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5ZM9.30884 10.0159C8.53901 10.6318 7.56251 11 6.5 11C4.01472 11 2 8.98528 2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 7.56251 10.6318 8.53901 10.0159 9.30884L12.8536 12.1464C13.0488 12.3417 13.0488 12.6583 12.8536 12.8536C12.6583 13.0488 12.3417 13.0488 12.1464 12.8536L9.30884 10.0159Z" fill="currentColor"></path></svg>
                                                                          </div>
                                                                      </div>
                                                                      <div className="col-12 d-flex flex-column gap-2">
                                                                      { matchedItem?.length ? matchedItem?.map((item, index)=>(
                                                                        <div className="row countryList mx-0 py-2" 
                                                                            
                                                                                key={index}
                                                                                onClick={() => {
                                                                                    setSelect(item);
                                                                                    localStorage.setItem("country_Id", item?._id)
                                                                                    setIsOpen(false)
                                                                                    setIsActive(false)
                                                                                }}
                                                                        >
                                                                            <div className="col-auto"><div className="numberTxt">{item.phoneCode}</div></div>
                                                                            <div className="col ps-0">
                                                                                <div className="counteryName">{item.country}</div>
                                                                            </div>
                                                                        </div>
                                                                      )) : <NoData/>}
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                </div>
                                            </div> */}
        {/* <div className="col-md-6 order-md-5 order-3">
                                                <div className={`inputmain position-relative d-flex align-items-center ${isActive ? 'active' : ''}`}>
                                                    <input 
                                                        name='password'
                                                        value={values?.password}
                                                        onChange={(e)=>handleChange(e)} 
                                                        type={showPassword ? 'text' : 'password'}
                                                        id="Password" className="form-control shadow-none affiliateInput bg-transparent rounded-0 border-0 h-100" placeholder='Password' /> */}
        {/* <label for="Password" className="affiliateinputLabel position-absolute">{affiliateLabels.Password}</label> */}
        {/* <button onClick={()=>setShowPassword(!showPassword)} className="border-0 shadow-none d-flex align-items-center justify-content-center p-0 passwordvisible bg-transparent">
                                                        {
                                                            !showPassword ? 
                                                            <img src="assets/img/affiliatenew/eye.png" alt="password" className="h-100 w-100 img-fluid object-fit-contain" />
                                                            : <img src="assets/img/affiliatenew/passwordvisible.png" alt="password" className="h-100 w-100 img-fluid object-fit-contain" />
                                                        }
                                                    </button>
                                                </div>
                                            </div> */}
        {/* <div className="col-md-6 order-md-6 order-4">
                                                <div className="inputmain position-relative d-flex align-items-center">
                                                    <input type="text" id="confirmpassword" className="form-control shadow-none affiliateInput bg-transparent rounded-0 border-0 h-100" />
                                                    <label for="confirmpassword" className="affiliateinputLabel position-absolute">{affiliateLabels.Confirm_Password}</label>
                                                    <button className="border-0 shadow-none d-flex align-items-center justify-content-center p-0 passwordvisible bg-transparent">
                                                        <img src="assets/img/affiliatenew/passwordvisible.png" alt="password" className="h-100 w-100 img-fluid" />
                                                    </button>
                                                </div>
                                            </div> */}
        {/* </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="h-100 row flex-column align-items-center justify-content-between">
                                            <div className="col-12">
                                                <div className="termsservice d-flex align-items-start justify-content-start">
                                                    <input   checked={checks.terms}
                                                                  onChange={(e) => {
                                                                      setChecks(pre => ({
                                                                          ...pre,
                                                                          terms: e.target.checked,
                                                                      }))
                                                                  }} type="checkbox" id="termsofservice" className="checkinput form-check-input shadow-none" />
                                                    <label for="termsofservice">{affiliateLabels.By_accessing_you} <a href="javascript:;" className="text-decoration-none conditiontitle">Terms of Service</a></label>
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-lg-4 col-sm-5 col-sm-6  mx-auto">
                                                <button onClick={handleClick} className="btn resisterbtn d-flex align-items-center justify-content-center w-100">
                                                    {
                                                        isLoading ? <Spinner variant='light' size='sm'/> : affiliateLabels.Register_now
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
               */}
      </div>
    </>
  );
};

export default AffilliatePage;
