import usdt from '../../../assets/images/profile/usdt.png'
import fees from '../../../assets/images/profile/fees.png'
import rightArrow from '../../../assets/images/profile/rightArrow.png'
import React, { useEffect, useRef, useState } from 'react'
import { useWalletModal } from '../../../hooks/useWallet'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getAPIAuth, postAPIAuth } from '../../../services/apiInstance'
import { WALLET_MODAL_TABS } from '../../../constants'
import { errorToaster, succesToaster } from '../../../utils/toaster';
import { useAuth } from '../../../hooks/useAuth';
import Dropdown from 'react-bootstrap/Dropdown';
import {selectCrypto} from './../../../store/action'
import { Spinner } from 'react-bootstrap'


const CryptoWithdraw = () => {
  const { token } = useAuth();
  const { walletModalObject } = useWalletModal()
  const cryptoList = useSelector(state => state.cryptoList)
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(true)
  const [selectedCrypto, setSelectedCrypto] = useState({})
  const [address, setAddress] = useState("")
  const [amount, setAmount] = useState("")
  const userDetails = useSelector(state => state.userDetails)
  const [withdrawLoading, setWithdrawLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [availCrypto, setAvailCrypto] = useState([])
  const [filterData, setFilterData] = useState([])
  const [convertedPrice, setConvertedPrice] = useState(0.00)
  const timeoutRef = useRef()
  const { t } = useTranslation()
  const walletLabels = t("wallet", { returnObjects: true })
  const [allNetwork, setAllNewtWork] = useState([])
  const [selectedNetwork, setSelectedNetwork] = useState('')
  const [networkOpen, setNetworkOpen] = useState(false)
  const [networkFiltered, setNetworkFiltered] = useState([]);
  const [searchNetwork, setSearchNetwork] = useState('')
  const [selectedCurrencyBalance, setSelectedCurrencyBalance] = useState({})
  const networkRef = useRef();
  // const [withdrawLoading, setWithdrawLoading] = useState(false)
  // const cryt
  const crypto = useSelector(state => state?.selectedCurrency?.crypto)
  const userBalance = useSelector(state => state.userBalance)


  useEffect(() => {
    if (crypto?._id) {
      setSelectedCrypto(crypto)
    }
  }, [crypto?._id])

  // const userDetails = useSelector(state => state.userDetails)
  const getAvailableCrypto = async () => {
    try {
      const res = await getAPIAuth("crypto/get-crypt-favorite-by-admin")
      if (res.data.success) {
        // setAvailCrypto(res.data.data)
        const data = res?.data?.data?.filter(item => item?.code !== 'eth')
        setAvailCrypto(data)
        // setSelCryptoNetwork(res.data.data)
      }
    } catch (error) {
    }
  }

  //   function for filter network data
  const filterNetwork = () => {
    if (searchNetwork) {
      let filterData = allNetwork.filter((el) =>
        el.name
          .toUpperCase()
          .includes(searchNetwork?.toUpperCase())
      );
      setNetworkFiltered(filterData);
    } else {
      setNetworkFiltered(allNetwork);
    }
  };
  const getCrypto = async () => {
    if (!!search) {
      let filterDataArr = cryptoList.filter((el) => {
        return el.code.toUpperCase().includes(search.toUpperCase())
      });
      setFilterData(filterDataArr)
    } else {
      if (

        cryptoList.length === 0
      ) {
        try {
          const resp = await getAPIAuth("crypto/get-crypto", token);
          // setCrypto(resp?.data.data)
          const data = resp?.data?.data?.filter(item => item?.code !== 'eth')
          if (!crypto?._id) {
            setSelectedCrypto(data[0])
          }
          setFilterData(data)
          setAllNewtWork(data?.[0]?.allNetworks)
          setNetworkFiltered(data?.[0]?.allNetworks)
          setSelectedNetwork(data?.[0]?.allNetworks[0])

        } catch (error) {

        }
      } else if (

        cryptoList.length !== 0
      ) {
        const data = structuredClone(cryptoList)?.filter(item => item?.code !== 'eth')
        if (!crypto?._id) {
          setSelectedCrypto(data?.[0])
        }
        setFilterData(data)
        setAllNewtWork(data?.[0]?.allNetworks)
        setNetworkFiltered(data?.[0]?.allNetworks)
        setSelectedNetwork(data?.[0]?.allNetworks[0])
      }
      setIsLoading(false)
    }
  }

  const onWithdraw = async () => {

    setWithdrawLoading(true)
    if (!address) {
      errorToaster("Address can't be empty!")
      setWithdrawLoading(false)
      return
    }
    if (!!!amount || amount === '') {
      errorToaster("Add a valid amount to withdraw!")
      setWithdrawLoading(false)
      return
    }
    // if (convertedPrice === 0 || convertedPrice < 0) {
    //     errorToaster("Converted Amount isn't Valid")
    //     setWithdrawLoading(false)
    //     return
    // }
    try {
      const body = {
        userId: userDetails.id,
        address: address,
        withdraw_amount: amount,
        coinId: selectedCrypto._id,
        user_currency: userDetails?.usercurrency,
        chain: 'eth',
        type: "withdraw",
        // convertedCurrency: convertedPrice,
        network: selectedNetwork?.name
      }

      // const res = await postAPIAuth("user/save-request-withdraw", body)
      const res = await postAPIAuth("coin/withdrawRequest", body)

      if (res.data.success) {
        succesToaster(res.data.message)
      } else {
        errorToaster(res.data.message)
      }
    } catch (error) {
      errorToaster("Something went wrong")
    } finally {
      setTimeout(() => {
        setWithdrawLoading(false)
      }, 500)
      setAddress('')
      setAmount("")
    }
  }
  useEffect(() => {
    getCrypto()
  }, [search])

  useEffect(() => {
    getAvailableCrypto()
  }, [])

  const priceConverter = async () => {

    const body = {
      // source: "eth",
      // target: "usdt",
      source: selectedCrypto.code,
      target: "usdt",
      amount: amount
    }
    try {
      const res = await postAPIAuth("convertPrice", body)
      if (res.data.success) {
        setConvertedPrice(res.data.data.amount)
      } else {
        setConvertedPrice(0.00)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if (walletModalObject.isWalletOpen &&
      walletModalObject.selectedTab === WALLET_MODAL_TABS.WITHDRAW) {
      // debouncing
      if (!!amount) {
        setWithdrawLoading(true)
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = setTimeout(() => {
        if (!!amount) {
          // priceConverter()
        } else {
          if (!amount) setConvertedPrice(0.00)
        }
        setWithdrawLoading(false)
      }, 400)
    }

    return () => clearTimeout(timeoutRef.current)
  }, [amount, walletModalObject.selectedTab, walletModalObject.isWalletOpen, selectedCrypto])

  useEffect(() => {
    if (!!selectedCrypto?.allNetworks) {
      setAllNewtWork(selectedCrypto?.allNetworks)
      setNetworkFiltered(selectedCrypto?.allNetworks)
      setSelectedNetwork(selectedCrypto?.allNetworks[0])
    }
  }, [selectedCrypto])

  useEffect(() => {
    filterNetwork()
  }, [searchNetwork])

  console.log('selectedCrypto', selectedCrypto)

  const getUserCurrencyAmount = async ()=> {
    try {
        const res = await getAPIAuth(`crypto/crypto-wallet?cryptoId=${selectedCrypto?._id}`)
        if(res?.data?.success) {
          setSelectedCurrencyBalance(res?.data?.data)
        }
    } catch (error) {
        
    }
}

useEffect(()=> {
  if(selectedCrypto?._id) {
    getUserCurrencyAmount()
  }
}, [selectedCrypto])

  return (
    <>

                    <div className='col-md-8 col-sm-11 col-12'>
                        <div className='mainTabs'>
                            <div className='headingTabs'>Withdraw currency</div>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='row gap-sm-0 gap-3'>
                                        <div className='col-sm-6'>
                                            <div className='usdtSection usdtWithDrop'>  
                                            <Dropdown className='commonCurrDropdown h-100'>
                                                <Dropdown.Toggle className='w-100 h-100' id="dropdown-basic">
                                                <div className='row'>
                                                    <div className='col'>
                                                        <div className='d-flex align-items-center headingUsdt '>
                                                            <span className='logousdt rounded-circle overflow-hidden'><img src={selectedCrypto.icon} alt="usdt" /></span>  {selectedCrypto.code}
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <div className='rightArrow'>
                                                            <img src={rightArrow} alt="rightArrow" />       
                                                        </div>
                                                    </div> 
                                                </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="position-relative dropSearchInp">
                                                      <input type="text" className='commonInp' placeholder='Search'                       
                                                        value={search}
                                                        onChange={(e) =>
                                                          setSearch(e.target.value)
                                                        }/>
                                                      <img src="" alt="" />
                                                    </div>
                                                    {
                                                      isLoading ? ('') : (
                                                        filterData?.length === 0 ? (
                                                          <div className="w-100 h-100">
                                                            <h6 className="text-light"> No data available</h6>
                                                          </div>
                                                        ) : (filterData?.map((item) => (
                                                          <Dropdown.Item 
                                                          onClick={() => {
                                                            setSelectedCrypto(item)
                                                            dispatch(selectCrypto(item))
                                                          }}
                                                          className={`${selectedCrypto?._id == item?._id ? "active" : ""}`}
                                                          key={item?._id}
                                                          as='button'>
                                                            <img src={item?.icon} className='coin' alt="" />
                                                            {item?.code}
                                                          </Dropdown.Item>
                                                        )))
                                                      )
                                                    }
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </div>
                                        </div>
                                        <div className='col-sm-6 ps-sm-0'>
                                            <div className='usdtSection usdtWithDrop'>
                                              <Dropdown className='commonCurrDropdown h-100'>
                                                <Dropdown.Toggle className='w-100 h-100' id="dropdown-basic">
                                                  <div className='row align-items-center'>
                                                      <div className='col'>
                                                          <div className='d-flex align-items-center headingUsdt '>
                                                          {selectedNetwork?.name}
                                                          </div>
                                                      </div>
                                                      <div className="col-auto">
                                                          <div className='rightArrow d-flex'>
                                                              <img src={rightArrow} alt="rightArrow" />       
                                                          </div>
                                                      </div> 
                                                  </div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="position-relative dropSearchInp">
                                                      <input type="text" className='commonInp' placeholder='Search'
                                                        ref={networkRef}
                                                        value={searchNetwork}
                                                        onChange={(e) => setSearchNetwork(e.target.value)}/>
                                                      <img src="" alt="" />
                                                    </div>
                                                    {
                                                      networkFiltered?.map((item) => (
                                                      <Dropdown.Item 
                                                      key={item?._id}
                                                      onClick={() => {
                                                        setSelectedNetwork(item)
                                                        setNetworkOpen(!networkOpen);
                                                      }} className={`${selectedNetwork?._id == item?._id ? "active" : ""}`} as='button'>{item?.name}</Dropdown.Item>
                                                    ))
                                                  }
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='headingTabs'>Withdraw currency</div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='usdtSection p-0'>
                                                <div className='row'>
                                                    <div className='col'>
                                                      <input type="text"               
                                                        value={address}
                                                        onChange={(e) => {
                                                          setAddress(e.target.value)
                                                        }} className='commonInp withdrawInp' placeholder='Fill in carefully according to the specific currency' />
                                                        {/* <div className='d-flex align-items-center headingUsdt subHeading '>
                                                        Fill in carefully according to the specific currency
                                                        </div> */}
                                                    </div>
                                                    {/* <div className="col-auto">
                                                        <div className='rightArrow'>
                                                            <img src={rightArrow} alt="rightArrow" />       
                                                        </div>
                                                    </div>  */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='headingTabs'>Withdraw Amount</div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='usdtSection p-0'>
                                                <div className='row'>
                                                    <div className='col'>
                                                      <input type="text" className='commonInp withdrawInp' placeholder='Enter amount'
                                                                    value={amount}
                                                                    onChange={e => {
                                                                      if (isNaN(Number(e.target.value))) return
                                                                      setAmount(e.target.value)
                                                                      // priceConverter(e)
                                                                    }} />
                                                        {/* <div className='d-flex align-items-center headingUsdt  '>
                                                        0.0000
                                                        </div> */}
                                                    </div>
                                                    {/* <div className="col-auto">
                                                        <div className='rightArrow'>
                                                            <img src={rightArrow} alt="rightArrow" />       
                                                        </div>
                                                    </div>  */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className='headingTabs'>Available <span className='headingValue'>{selectedCurrencyBalance?.totalBalance ? selectedCurrencyBalance?.totalBalance?.toFixed(2) : '0.00'} {selectedCurrencyBalance?.coin ? selectedCurrencyBalance?.coin?.toUpperCase() : ''}</span></div>
                                        </div>
                                        <div className='col-auto'>
                                            <div className='headingTabs'>Locked funds: <span className='headingValue'>$ {userBalance?.lockedBonus || userBalance?.lockedBonus === 0 ? Number(userBalance?.lockedBonus).toFixed(2) : "0"}</span></div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='widthdrawAmmount'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <div className='subHeading'>Withdraw Amount</div>
                                                            </div>
                                                            <div className='col-auto'>
                                                                <div className='headingUsdt'>{amount ? amount : "0.00"} {selectedCrypto?.code?.toUpperCase()}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <div className='subHeading d-flex align-items-center'>Fee <span className='feesIcon'><img src={fees} alt="fees" /></span></div>
                                                            </div>
                                                            <div className='col-auto'>
                                                                <div className='headingUsdt'>0.00 {selectedCrypto?.code?.toUpperCase()}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <div className='subHeading d-flex align-items-center'>Total withdrawal amount</div>
                                                            </div>
                                                            <div className='col-auto'>
                                                                <div className='headingUsdt sucess'>{amount ? amount : "0.00"} {selectedCrypto?.code?.toUpperCase()}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 mt-2'>
                                    <div className='noticeSection'>
                                        <div className='noticeHeading'>Notice: For security purposes, large or suspicious withdrawal may take 1-6 hours for audit process. We appreciate your patience!</div>
                                    </div>
                                </div>
                                <div className='col-12 mt-sm-4 mt-3'>
                                    <button class="changeBtn mx-auto"  onClick={onWithdraw}>              {
                                      withdrawLoading ? (
                                        <Spinner animation="border" variant="dark" size="sm" />
                                      ) : (
                                        "Withdraw"
                                      )
                                    }</button>
                                </div>
                            </div>
                        </div>
                    </div>
    </>
  )
}

export default CryptoWithdraw