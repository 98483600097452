import React, { useEffect, useState } from "react";
import profileIcon from "../../../assets/images/profile/profileIcon.png";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { getAPIAuth, postAPIAuth } from "./../../../services/apiInstance.js";
import { fetchUserDetails } from "./../../../services/fetchUserDetails.js";
import { reducerConst } from "../../../constants/storeConstants";
import Background from "../../../assets/images/profile/profile-bg.svg";
import avatar from "../../../assets/images/profile/avatar.svg";
import avatarBg from "../../../assets/images/profile/avatar-bg.svg";
import prizeSvg from "../../../assets/images/Vector.svg";
import liveCasino from "../../../assets/images/live-casino.svg";
import slots from "../../../assets/images/slots.svg";
import poker from "../../../assets/images/poker.svg";
import sports from "../../../assets/images/sports.svg";
import cardGames from "../../../assets/images/card-games.svg";
import crash from "../../../assets/images/crash.svg";
import userProfile from "../../../assets/images/user-profile.svg";
import plateGraph from "../../../assets/images/plat-graph.svg";
import plateMeter from "../../../assets/images/plating-meter.svg";
import lineTop from "../../../assets/images/line-top.svg";
import lineBottom from "../../../assets/images/line-bottom.svg";
import ellipse from "../../../assets/images/ellipse.svg";
import thunder from "../../../assets/images/thunder.svg";
import taz from "../../../assets/images/taz.svg";
import profileUser from "../../../assets/images/profile-user.svg";
import girl from "../../../assets/images/girl.svg";
import playerGraph from "../../../assets/images/bg-graph-player.png";
import TotalWins from "../../../assets/images/profile/total-wins.svg";
import ProfilePictureModal from "./ProfilePictureModal.jsx";

const ProfileTab = () => {
  const userDetails = useSelector((state) => state.userDetails);
  const [profilePic, setProfilePic] = useState("");
  const [data, setData] = useState("");
  const [userName, setUserName] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const { token } = useAuth();
  const { t } = useTranslation();
  const [dynamicCategories, setDynamicCategories] = useState([]);
  const [staticCategories, setStaticCategories] = useState(null);
  const [statsData, setStatsData] = useState(null);
  const accountPageLabels = t("accountPageLabels", { returnObjects: true });
  const commonText = t("commonText", { returnObjects: true });
  const [modalOpen, setModalOpen] = useState(false);

  const userId = userDetails?.id;
  // const PrevioususerName = userDetails?.username;

  useEffect(() => {
    setUserName(userDetails?.username);
  }, [userDetails]);

  const handleChangeUser = async () => {
    if (userName?.length >= 3) {
      setError(false);
      try {
        const res = await postAPIAuth("user/update-username", {
          userId,
          userName,
        });
        if (res?.data?.success) {
          succesToaster("Name Changed Successfully");
          setData(res);
          // setUserName('')
        } else {
          errorToaster(res?.data?.message);
        }
      } catch (error) {
        errorToaster(error?.message);
      }
    } else {
      setError(true);
    }
  };

  const getStatesCategory = async () => {
    try {
      const res = await getAPIAuth("user/getStatsCategory", token);
      if (res.data.success) {
        setDynamicCategories(res.data.dynamicCategory);
        setStaticCategories(res.data.staticCategory);
        setStatsData(res.data.statsData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStatesCategory();
  }, []);

  useEffect(() => {
    if (token) {
      const getData = async () => {
        const res = await fetchUserDetails(token);
        dispatch({
          type: reducerConst.USER_DETAILS,
          payload: res?.data?.data ? res.data.data : {},
        });
      };
      getData();
    }
  }, [data, token]);

  useEffect(() => {
    if (userDetails.createdAt) {
      function formatDate(isoString) {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
      }
      setRegistrationDate(formatDate(userDetails.createdAt));
    }
  }, [userDetails]);
  // profile picute modal
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const handleOutsideClick = (e) => {
    // Check if the clicked element is the modal overlay itself
    if (e.target.classList.contains("modal-overlay")) {
      toggleModal();
    }
  };

  useEffect(() => {
    if (userDetails?.image) {
      setProfilePic(userDetails.image);
    }
  }, [userDetails]);

  return (
    <>
      <div className="container-fluid profile-section px-0  mb-5 position-relative">
        <div className="row g-4">
          <div className="col-12 mb-4">
            <div className="position-relative">
              <div className="profile-bg px-4 py-2">
                {/* <img className='w-100' src={Background} alt="" /> */}
                <div className="balance-box text-end">
                  <div>
                    <div className="pb-1">
                      <span class="sml_txt">Balance</span>
                    </div>
                    <span className="balance">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 24 24"
                        height="20px"
                        width="20px"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Dollar">
                          <g>
                            <path d="M12,21.934A9.934,9.934,0,1,1,21.934,12,9.945,9.945,0,0,1,12,21.934ZM12,3.066A8.934,8.934,0,1,0,20.934,12,8.944,8.944,0,0,0,12,3.066Z"></path>
                            <path d="M14.5,13.5a2.006,2.006,0,0,1-2,2v1.01a.5.5,0,0,1-1,0V15.5H10.25a.5.5,0,0,1,0-1H12.5a1,1,0,0,0,0-2h-1a2,2,0,0,1,0-4V7.49a.5.5,0,0,1,1,0V8.5h1.25a.5.5,0,0,1,0,1H11.5a1,1,0,0,0,0,2h1A2.006,2.006,0,0,1,14.5,13.5Z"></path>
                          </g>
                        </g>
                      </svg>
                      <span className="balance-money ps-1">1.720.000</span>
                    </span>
                  </div>
                </div>
                <div className="user-name text-center mt-md-0 mt-3">
                  <div className="text-center">
                    <h6>{userDetails?.username}</h6>
                    <p>
                      REGISTRATION DATE <span>{registrationDate}</span>{" "}
                    </p>
                  </div>
                </div>
                <div className="profle-img d-md-flex d-none justify-content-center">
                  <div className="d-flex align-items-center">
                    <div className="profile_counts mt-5">
                      <ul className="p-0 m-0 d-flex align-items-center gap-3">
                        <li>
                          <div className="list_count">351</div>
                        </li>
                        <li>
                          <div
                            className="list_count"
                            style={{ background: "#E5AB3D", color: "#fff" }}
                          >
                            351
                          </div>
                        </li>
                        <li>
                          <div className="list_count">351</div>
                        </li>
                      </ul>
                    </div>
                    <div
                      
                      className="position-relative avtar_img"
                    >
                      <img
                        src={avatarBg}
                        className="img-fluid bg_avatar"
                        alt=""
                      />
                      <div  onClick={toggleModal} className=" avatar_logo_img position-absolute overflow-hidden">
                        <img className="h-100 w-100 " src={profilePic ? profilePic : avatar} alt="" />
                      </div>
                      <span className="img_point position-absolute rounded-circle d-flex align-items-center justify-content-center">
                        0
                      </span>
                    </div>
                    {modalOpen && (
                      <ProfilePictureModal
                        profilePic={profilePic}
                        setProfilePic={setProfilePic}
                        handleOutsideClick={handleOutsideClick}
                        toggleModal={toggleModal}
                      />
                    )}

                    <div className="profile_counts count_right mt-5">
                      <ul className="p-0 m-0 d-flex align-items-center gap-3">
                        <li>
                          <div className="list_count">351</div>
                        </li>
                        <li>
                          <div
                            className="list_count"
                            style={{ background: "#0e95fa", color: "#fff" }}
                          >
                            351
                          </div>
                        </li>
                        <li>
                          <div className="list_count">351</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="profle-img mb-profile d-md-none d-flex justify-content-center">
                  <div className="d-flex align-items-center justify-content-center flex-column">
                    <div className="position-relative avtar_img">
                      <img
                        src={avatarBg}
                        className="img-fluid bg_avatar"
                        alt=""
                      />
                       <div  onClick={toggleModal} className=" avatar_logo_img position-absolute overflow-hidden">
                        <img className="h-100 w-100 " src={profilePic ? profilePic : avatar} alt="" />
                      </div>
                      <span className="img_point position-absolute rounded-circle d-flex align-items-center justify-content-center">
                        0
                      </span>
                    </div>
                    <div className="profile_counts mt-1">
                      <ul className="p-0 m-0 d-flex align-items-center gap-3">
                        <li>
                          <div className="list_count">351</div>
                        </li>
                        <li>
                          <div
                            className="list_count"
                            style={{ background: "#E5AB3D", color: "#fff" }}
                          >
                            351
                          </div>
                        </li>
                        <li>
                          <div className="list_count">351</div>
                        </li>

                        <li>
                          <div className="list_count">351</div>
                        </li>
                        <li>
                          <div
                            className="list_count"
                            style={{ background: "#0e95fa", color: "#fff" }}
                          >
                            351
                          </div>
                        </li>
                        <li>
                          <div className="list_count">351</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid rewards_sec mb-5 pb-lg-0 pb-md-3 pb-3">
        <div className="row">
          <div className="col-12">
            <div className="reward_prize_box d-flex justify-content-center flex-column align-items-center">
              <span className="reward_box">
                <div className="multi_txt d-flex align-items-center gap-2">
                  <span className="fw-bold text-white">X 2.00</span>
                  <span className="bonus_txt">MULTIPLY BONUSES</span>
                </div>
                <div className="prize_txt d-flex align-items-center gap-2 mt-1">
                  <span>
                    <img
                      src={prizeSvg}
                      style={{ height: "23px", objectFit: "contain" }}
                      alt="YOUR BEST REWARDS"
                    />
                  </span>
                  <span className="fw-bold text-white">YOUR BEST REWARDS</span>
                </div>
              </span>
            </div>
            <div className="rewards_list">
              <div className="col-lg-11 col-md-12 col-12 mx-auto">
                <div className="row align-items-center justify-content-center g-lg-0 g-md-4 g-4">
                  <div className="col-lg-2 col-md-3 col-6">
                    <div className="list_content d-flex flex-column justify-content-center align-items-center">
                      <div className="list_img">
                        <img src={poker} alt="" className="img-fluid" />
                      </div>
                      <h6 className="text-white my-2">POKER</h6>
                      <div className="list_num rounded-pill d-flex align-items-center gap-2">
                        <span className="list_num_count rounded-circle d-flex align-items-center justify-content-center">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 24 24"
                            height="15px"
                            width="15px"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M15.999 8.5h2c0-2.837-2.755-4.131-5-4.429V2h-2v2.071c-2.245.298-5 1.592-5 4.429 0 2.706 2.666 4.113 5 4.43v4.97c-1.448-.251-3-1.024-3-2.4h-2c0 2.589 2.425 4.119 5 4.436V22h2v-2.07c2.245-.298 5-1.593 5-4.43s-2.755-4.131-5-4.429V6.1c1.33.239 3 .941 3 2.4zm-8 0c0-1.459 1.67-2.161 3-2.4v4.799c-1.371-.253-3-1.002-3-2.399zm8 7c0 1.459-1.67 2.161-3 2.4v-4.8c1.33.239 3 .941 3 2.4z"></path>
                          </svg>
                        </span>
                        <span className="pe-3 py-1">
                          {staticCategories?.pokerWager.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-3 col-6">
                    <div className="list_content d-flex flex-column justify-content-center align-items-center">
                      <div className="list_img">
                        <img src={sports} alt="" className="img-fluid" />
                      </div>
                      <h6 className="text-white my-2">SPORTS</h6>
                      <div className="list_num rounded-pill d-flex align-items-center gap-2">
                        <span className="list_num_count rounded-circle d-flex align-items-center justify-content-center">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 24 24"
                            height="15px"
                            width="15px"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M15.999 8.5h2c0-2.837-2.755-4.131-5-4.429V2h-2v2.071c-2.245.298-5 1.592-5 4.429 0 2.706 2.666 4.113 5 4.43v4.97c-1.448-.251-3-1.024-3-2.4h-2c0 2.589 2.425 4.119 5 4.436V22h2v-2.07c2.245-.298 5-1.593 5-4.43s-2.755-4.131-5-4.429V6.1c1.33.239 3 .941 3 2.4zm-8 0c0-1.459 1.67-2.161 3-2.4v4.799c-1.371-.253-3-1.002-3-2.399zm8 7c0 1.459-1.67 2.161-3 2.4v-4.8c1.33.239 3 .941 3 2.4z"></path>
                          </svg>
                        </span>
                        <span className="pe-3 py-1">
                          {staticCategories?.sportsWager.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>

                  {dynamicCategories.map((cat) => {
                    return (
                      <div key={cat._id} className="col-lg-2 col-md-3 col-6">
                        <div className="list_content d-flex flex-column justify-content-center align-items-center">
                          <div className="list_img">
                            <img src={cat.image} alt="" className="img-fluid" />
                          </div>
                          <h6 className="text-white my-2">
                            {cat.category_name}
                          </h6>
                          <div className="list_num rounded-pill d-flex align-items-center gap-2">
                            <span className="list_num_count rounded-circle d-flex align-items-center justify-content-center">
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 24 24"
                                height="15px"
                                width="15px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M15.999 8.5h2c0-2.837-2.755-4.131-5-4.429V2h-2v2.071c-2.245.298-5 1.592-5 4.429 0 2.706 2.666 4.113 5 4.43v4.97c-1.448-.251-3-1.024-3-2.4h-2c0 2.589 2.425 4.119 5 4.436V22h2v-2.07c2.245-.298 5-1.593 5-4.43s-2.755-4.131-5-4.429V6.1c1.33.239 3 .941 3 2.4zm-8 0c0-1.459 1.67-2.161 3-2.4v4.799c-1.371-.253-3-1.002-3-2.399zm8 7c0 1.459-1.67 2.161-3 2.4v-4.8c1.33.239 3 .941 3 2.4z"></path>
                              </svg>
                            </span>
                            <span className="pe-3 py-1">
                              {cat.totalWagerAmount.toFixed(2)}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid latest_sec mb-5 px-0">
        <div className="row justify-content-center px-0 g-4">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="latest_box h-100">
              <div className="latest_txt mb-2">Statistics</div>
              {/* <div className="latest_list">
                <ul className="p-0 m-0 d-flex flex-column gap-2">
                  <li>
                    <div className="user_list d-flex align-items-center justify-content-between p-2">
                      <div className="d-flex align-items-center gap-2">
                        <span className="user_img">
                          <img src={userProfile} alt="" className="img-fluid" />
                        </span>
                        <div className="user_name">
                          <div className="text-white" style={{ fontSize: '14px', fontWeight: '500' }}>USERNAME</div>
                          <div className="nick_name">Nick name - LV 3</div>
                        </div>
                      </div>
                      <div>
                        <span className="user_counts rounded-circle d-flex align-items-center justify-content-center">
                          1
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="user_list d-flex align-items-center justify-content-between p-2" style={{ background: '#272a4a' }}>
                      <div className="d-flex align-items-center gap-2">
                        <span className="user_img">
                          <img src={userProfile} alt="" className="img-fluid" />
                        </span>
                        <div className="user_name">
                          <div className="text-white" style={{ fontSize: '14px', fontWeight: '500' }}>USERNAME</div>
                          <div className="nick_name">Nick name - LV 3</div>
                        </div>
                      </div>
                      <div>
                        <span className="user_counts rounded-circle d-flex align-items-center justify-content-center" style={{ background: '#E5AB3D', color: '#000' }}>2</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="user_list d-flex align-items-center justify-content-between p-2">
                      <div className="d-flex align-items-center gap-2">
                        <span className="user_img">
                          <img src={userProfile} alt="" className="img-fluid" />
                        </span>
                        <div className="user_name">
                          <div className="text-white" style={{ fontSize: '14px', fontWeight: '500' }}>USERNAME</div>
                          <div className="nick_name">Nick name - LV 3</div>
                        </div>
                      </div>
                      <div>
                        <span className="user_counts rounded-circle d-flex align-items-center justify-content-center">
                          3
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="user_list d-flex align-items-center justify-content-between p-2">
                      <div className="d-flex align-items-center gap-2">
                        <span className="user_img">
                          <img src={userProfile} alt="" className="img-fluid" />
                        </span>
                        <div className="user_name">
                          <div className="text-white" style={{ fontSize: '14px', fontWeight: '500' }}>USERNAME</div>
                          <div className="nick_name">Nick name - LV 3</div>
                        </div>
                      </div>
                      <div>
                        <span className="user_counts rounded-circle d-flex align-items-center justify-content-center">
                          4
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="user_list d-flex align-items-center justify-content-between p-2">
                      <div className="d-flex align-items-center gap-2">
                        <span className="user_img">
                          <img src={userProfile} alt="" className="img-fluid" />
                        </span>
                        <div className="user_name">
                          <div className="text-white" style={{ fontSize: '14px', fontWeight: '500' }}>USERNAME</div>
                          <div className="nick_name">Nick name - LV 3</div>
                        </div>
                      </div>
                      <div>
                        <span className="user_counts rounded-circle d-flex align-items-center justify-content-center">
                          5
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div> */}
              <div className="row">
                <div className="col-lg-6 mb-3">
                  <div className="">
                    <div className="stats-box">
                      <div className="d-flex align-items-center gap-2 stats-box-upper">
                        <div className="stats-img-box">
                          <img src={TotalWins} className="img-fluid" alt="" />
                        </div>
                        <div className="stats-heading">Total Payout</div>
                      </div>
                      <div className="stats-box-lower">
                        <div className="stats-amount">
                          {" "}
                          {statsData?.totalPayouts}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-3">
                  <div className="">
                    <div className="stats-box">
                      <div className="d-flex align-items-center gap-2 stats-box-upper">
                        <div className="stats-img-box">
                          <img src={TotalWins} className="img-fluid" alt="" />
                        </div>
                        <div className="stats-heading">Total Profit</div>
                      </div>
                      <div className="stats-box-lower">
                        <div className="stats-amount">
                          {" "}
                          {statsData?.totalProfit}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="">
                    <div className="stats-box">
                      <div className="d-flex align-items-center gap-3 stats-box-upper">
                        <div className="stats-img-box">
                          <img src={TotalWins} className="img-fluid" alt="" />
                        </div>
                        <div className="stats-heading">Total Wagered </div>
                      </div>
                      <div className="stats-box-lower">
                        <div className="stats-amount">
                          {" "}
                          {statsData?.totalWageAmount}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="latest_box h-100">
              <div className="latest_txt mb-2">Latest Added</div>
              <div className="latest_inner_box py-3 px-2">
                <div className="plating_num d-flex justify-content-between align-items-center">
                  <div className="plt_txt d-flex align-items-center gap-2">
                    <span className="text-white" style={{ fontSize: "10px" }}>
                      PLATING
                    </span>
                    <span
                      style={{
                        color: "#E5AB3D",
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                    >
                      12345
                    </span>
                  </div>
                  <span
                    className="latest_plt_num py-1 px-2 text-dark"
                    style={{
                      background: "#E5AB3D",
                      borderRadius: "6px",
                      fontWeight: "500",
                    }}
                  >
                    412
                  </span>
                </div>
                <div className="plat_img text-center mx-auto mt-2">
                  <img src={plateGraph} alt="" className="img-fluid mid_img" />
                </div>
                <span
                  className="latest_plt_num py-1 px-2 text-white"
                  style={{
                    background: "#0e95fa",
                    borderRadius: "6px",
                    fontWeight: "500",
                  }}
                >
                  412
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="latest_box h-100">
              <div className="latest_txt mb-2">Earning from games</div>
              <div className="latest_inner_box py-3 px-2">
                <div className="plating_num d-flex justify-content-between align-items-center">
                  <div className="plt_txt d-flex align-items-center gap-2">
                    <span className="text-white" style={{ fontSize: "10px" }}>
                      PLATING
                    </span>
                    <span
                      style={{
                        color: "#E5AB3D",
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                    >
                      12345
                    </span>
                  </div>
                </div>
                <div className="plat_img text-center mx-auto mt-2 position-relative">
                  <span className="position-relative">
                    <img
                      src={plateMeter}
                      alt=""
                      className="img-fluid mid_img"
                    />
                    <div className="ellipse_img position-absolute  left-0">
                      <img
                        src={ellipse}
                        style={{ height: "40px" }}
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="position-absolute line_box_vector">
                      <div className="line_top position-relative">
                        <img src={lineTop} alt="" className="img-fluid" />
                        <span
                          className=" position-absolute rounded-2 py-1 px-2 text-white"
                          style={{ background: "#5C1993" }}
                        >
                          11%
                        </span>
                      </div>
                    </div>
                    <div className="position-absolute line_box_vector_bottom">
                      <div className="line_bottom position-relative">
                        <img src={lineBottom} alt="" className="img-fluid" />
                        <span
                          className="position-absolute rounded-2 py-1 px-2 text-white"
                          style={{ background: "#0e95fa" }}
                        >
                          11%
                        </span>
                      </div>
                    </div>
                    <div className="earning_box flex-column">
                      <div className="ern_txt d-flex gap-1 align-items-center">
                        <span className="earn_img">
                          <img
                            src={thunder}
                            alt=""
                            style={{ height: "20px" }}
                            className="img-fluid"
                          />
                        </span>
                        <span>23,020.00</span>
                      </div>
                      <ul className="earn_list p-0 mt-3 d-flex align-items-center gap-1">
                        <li>
                          <span
                            className="rounded-2 px-2 py-1 text-dark"
                            style={{
                              backgroundImage:
                                "linear-gradient(35deg, #E5AB3D, #E5AB3D)",
                            }}
                          >
                            50%
                          </span>
                        </li>
                        <li>
                          <span
                            className="rounded-2 px-2 py-1 text-white"
                            style={{
                              backgroundImage:
                                "linear-gradient(35deg, #5593f7, #3652a2)",
                            }}
                          >
                            55%
                          </span>
                        </li>
                        <li>
                          <span
                            className="rounded-2 px-2 py-1"
                            style={{
                              backgroundImage:
                                "linear-gradient(35deg, #bf073f, #6d1882)",
                            }}
                          >
                            40%
                          </span>
                        </li>
                      </ul>
                      <div
                        className="speed_txt fw-bold text-white mt-1"
                        style={{ fontSize: "36px" }}
                      >
                        213.0
                      </div>
                    </div>
                  </span>
                </div>
                <span
                  className="latest_plt_num py-1 px-2 text-white"
                  style={{
                    background: "#0e95fa",
                    borderRadius: "6px",
                    fontWeight: "500",
                  }}
                >
                  412
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid news_latter my-5 p-lg-2 p-md-2 p-4">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-8 p-3 order-lg-1 order-md-1 order-1">
            <div className="news_content">
              <div className="news_txt d-flex gap-2 flex-md-row flex-column">
                <span className="crown_img">
                  <img src={taz} alt="" className="img-fluid" />
                </span>
                <span className="news_title fw-bold text-white mt-md-0 mt-5">
                  FREE{" "}
                  <span className="fw-bold" style={{ color: "#E5AB3D" }}>
                    $100!
                  </span>{" "}
                  REGISTER AND ENTER A SPECIAL CODE!
                </span>
              </div>
              <div className="user_abt d-flex align-items-md-center flex-md-row flex-column gap-2 mt-md-5">
                <span className="text-white">
                  Players Who Received The Bonus
                </span>
                <ul className="user_list p-0 m-0 d-flex align-items-center">
                  <li>
                    <span>
                      <img src={profileUser} alt="" className="img-fluid" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src={profileUser} alt="" className="img-fluid" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src={profileUser} alt="" className="img-fluid" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src={profileUser} alt="" className="img-fluid" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src={profileUser} alt="" className="img-fluid" />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-6 order-lg-2 order-md-2 order-3">
            <div className="bet_content text-center p-3 h-100 d-flex flex-column justify-content-center">
              <div className="fs-4 bt_head" style={{ color: "#E5AB3D" }}>
                YOUR BET
              </div>
              <h2 className="text-white fw-bold my-md-2 mb-m-1 mb-1">
                VIP CLUB
              </h2>
              <p className="text-white bt_txt">
                Free <span style={{ color: "#E5AB3D" }}>$100!</span> Register
                and enter a special code!
              </p>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-6 order-lg-3 order-md-3 order-2">
            <div className="news_img position-absolute top-0 h-100">
              <img src={girl} alt="" className="img-fluid h-100" />
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12 order-lg-4 order-md-4 order-4">
            <div className="news_input d-flex justify-content-center flex-column h-100">
              <h4 className="fw-bold d-md-block d-none">FREE BONUS</h4>
              <div className="input_bx position-relative d-flex align-items-center ps-3">
                <input
                  type="text"
                  placeholder="12A4 1233 322434 4343 4342"
                  className="w-100 bg-transparent"
                />
                <button type="button" className="m-0 border-0 h-100">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    height="17px"
                    width="17px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="14"
                      height="14"
                      x="8"
                      y="8"
                      rx="2"
                      ry="2"
                    ></rect>
                    <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid player_graph_sec px-0">
        <div className="row">
          <div className="col-12">
            <div className="player_box">
              <div class="player_txt mb-2">Player Statistics</div>
              <div class="player_inner_bx">
                <img src={playerGraph} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="profileTabsDiv">
                    <div className='row'>
                        <div className='col-12'>
                            <div className='tabsHeading'>
                                <span className='tabsIconHeading'><img src={profileIcon} alt="profile" /></span>  {accountPageLabels.Profile}
                            </div>
                        </div>
                        <div className='col-12'>
                            <div className='changeHeading'> {accountPageLabels.CHANGE_USERNAME}</div>
                        </div>
                        <div className='col-12'>
                                <label htmlFor="newUser" className='inputLabel'>{accountPageLabels.NEW_USERNAME} <span className='Star'> *</span></label>
                            <div className='inputGroup'> 
                                    <input className='inputUser common' type="text" 
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)} />
                                    <button onClick={handleChangeUser} className='changeBtn'>{accountPageLabels.CHANGE}</button>
                            </div>
                        </div>
                        <div className="col-12">
                            {error ? <div className="text-danger errorTxt">{accountPageLabels.Invalid_name}</div> : ''}
                        </div>
                        <div className='col-12'>
                            <Form>
                                <Form.Check className='onOffBtn' 
                                    type="switch"
                                    id="custom-switch"
                                    label="Private profile" 
                                />
                            </Form>
                        </div>
                    </div>
            </div> */}
    </>
  );
};

export default ProfileTab;
