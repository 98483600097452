import React, { createContext, useState } from "react"

export const NotificationContext = createContext(null)

const NotificationProvider = ({children})=> {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false)
  return (
    <NotificationContext.Provider value={{isNotificationOpen, setIsNotificationOpen}}>{children}</NotificationContext.Provider>
  )
}

export default NotificationProvider