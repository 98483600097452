// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import "firebase/auth";
import {
  getAuth,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  RecaptchaVerifier, signInWithPhoneNumber
} from "firebase/auth";


// const firebaseConfig = {
//   apiKey: "AIzaSyDalfl5YvNX3lDm9QRNB-KsmmdJIKD39Yg",
//   authDomain: "damble-969ae.firebaseapp.com",
//   projectId: "damble-969ae",
//   storageBucket: "damble-969ae.appspot.com",
//   messagingSenderId: "576839662693",
//   appId: "1:576839662693:web:41c02fd6e017fcb58f1a8c"
// };

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
const provider = new GoogleAuthProvider();
// provider.setCustomParameters({
//   prompt: "damble.io"
// })
export {
  auth,
  provider,
  firebase,
  createUserWithEmailAndPassword,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  sendPasswordResetEmail
};
