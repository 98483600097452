import React, { useState, useEffect } from "react";
import MobileBanner from "../../assets/images/mobile-vip-banner.png";
import Banner from "../../assets/images/vip/vipbanner.png";
import crown from "../../assets/images/vip/crown.png";
import flame from "../../assets/images/vip/flame.png";
import thunder from "../../assets/images/vip/thunder.png";
import claim from "../../assets/images/vip/vip1.png";
import claim2 from "../../assets/images/vip/vip2.png";
import claim3 from "../../assets/images/vip/vip3.png";
import arrow from "../../assets/images/vip/swiperArrow.png";
import bonus_1 from "../../assets/images/vip/superbonus1.png";
import bonus_2 from "../../assets/images/vip/bonus_2.png";
import bonus_3 from "../../assets/images/vip/bonus_3.png";
import bonus_4 from "../../assets/images/vip/bonus_4.png";
import bonus_5 from "../../assets/images/vip/bonus_5.png";
import bonus_6 from "../../assets/images/vip/bonus_6.png";
import star from "../../assets/images/vip/star.png";
import bgstar from "../../assets/images/vip/bgstar.png";
import layer from "../../assets/images/vip/blurlayer.png";
import girl_2 from "../../assets/images/vip/girl_2.png";
import check from "../../assets/images/vip/check-button.png";
import frozen from "../../assets/images/vip/frozenice.png";
import frozen_2 from "../../assets/images/vip/radio2.png";
import avatar from "../../assets/images/vip/avatar.png";
import coin from "../../assets/images/vip/coin.png";
import trophy from "../../assets/images/vip/trophy.png";
import thunder_2 from "../../assets/images/vip/thunder.png";
import frozen_3 from "../../assets/images/vip/radio3.png";
import frozen_4 from "../../assets/images/vip/radio5.png";
import frozen_5 from "../../assets/images/vip/radio4.png";
import profileUser from "../../assets/images/profile-user.svg";
import taz from "../../assets/images/taz.svg";
import girl from "../../assets/images/girl.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { getAPIAuth } from "../../services/apiInstance";
import { useAuth } from "../../hooks/useAuth";

// Import Swiper styles
// import 'swiper/css/Pagination';

const VIP = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { token } = useAuth();
  const [levels, setLevels] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getLevels = async () => {
    try {
      const res = await getAPIAuth("get-level", token);
      if (res.data.success) {
        setLevels(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLevels();
  }, []);
  return (
    <>
      <div className="herobanner mt-md-0 mt-4">
        <img src={isMobile ? MobileBanner : Banner} alt="" />
      </div>
      <div className="grid secondVip g-3">
        <div className="g-col-lg-4 g-col-md-6 g-col-sm-6 g-col-12 vipcard">
          <div className="d-flex gap-2">
            <div className="crownImg">
              <img src={crown} alt="" className="w-100 h-100 object-contain" />
            </div>
            <div className="crowntext">Latest Added</div>
          </div>
          <div className="innerCard">
            <div className="claimImg">
              <img src={claim} alt="" className="w-100 h-100" />
            </div>
            <div className="d-flex justify-content-between w-100 ">
              <div className="claimtext">Clamed so far:</div>
              <div className=" energy">
                <div className="energy_img">
                  <img src={thunder} alt="" className="w-100 h-100 d-flex" />
                </div>
                <div className="energy_text">00</div>
              </div>
            </div>
            <div className="clamed"></div>
            <div className="d-flex justify-content-between w-100 ">
              <div className="claimtext2">
                LOCKED <span className="neontext">$SHIP</span> BALANCE
              </div>
              <div className="d-flex energy">
                <div className="energy_img">
                  <img src={thunder} alt="" className="w-100 h-100 d-flex" />
                </div>
                <div className="energy_text">00</div>
              </div>
            </div>
            <div className="energybtn">
              <div className="thunder_img">
                <img src={thunder} alt="" className="w-100 h-100 d-flex" />
              </div>
              <div className="thunder_text">0,00</div>
            </div>
          </div>
        </div>
        <div className="g-col-lg-4 g-col-md-6 g-col-sm-6 g-col-12 vipcard">
          <div className="d-flex gap-2">
            <div className="crownImg">
              <img src={crown} alt="" className="w-100 h-100 object-contain" />
            </div>
            <div className="crowntext">Latest Added</div>
          </div>
          <div className="innerCard">
            <div className="claimImg">
              <img src={claim2} alt="" className="w-100 h-100" />
            </div>
            <div className="d-flex justify-content-between w-100 ">
              <div className="claimtext">Clamed so far:</div>
              <div className=" energy">
                <div className="energy_img">
                  <img src={thunder} alt="" className="w-100 h-100 d-flex" />
                </div>
                <div className="energy_text">00</div>
              </div>
            </div>
            <div className="clamed"></div>
            <div className="bonustabs">
              <div className="thunderbtn">
                <div className="thunder_img">
                  <img src={thunder} alt="" className="w-100 h-100 d-flex" />
                </div>
                <div className="tunder_text">0.00</div>
              </div>
              <div className="thunderbtn thunderbtnactive">
                <div className="thunder_img">
                  <img src={thunder} alt="" className="w-100 h-100 d-flex" />
                </div>
                <div className="tunder_text">0.00</div>
              </div>
              <div className="thunderbtn">
                <div className="thunder_img">
                  <img src={thunder} alt="" className="w-100 h-100 d-flex" />
                </div>
                <div className="tunder_text">0.00</div>
              </div>
              <div className="thunderbtn">
                <div className="thunder_img">
                  <img src={thunder} alt="" className="w-100 h-100 d-flex" />
                </div>
                <div className="tunder_text">0.00</div>
              </div>
            </div>
            <div className="bonusbar" style={{ "--width": "40%" }}>
              <div className="dott"></div>
              <div className="dott"></div>
              <div className="dott"></div>
              <div className="dott"></div>
            </div>
            <div className="bonustime">
              <div className="">1H</div>
              <div className="">6H</div>
              <div className="">12H</div>
              <div className="">24H</div>
            </div>
          </div>
        </div>
        <div className="g-col-lg-4 g-col-md-6 g-col-sm-6 g-col-12 vipcard">
          <div className="d-flex gap-2">
            <div className="crownImg">
              <img src={crown} alt="" className="w-100 h-100 object-contain" />
            </div>
            <div className="crowntext">Latest Added</div>
          </div>
          <div className="innerCard">
            <div className="claimImg">
              <img src={claim3} alt="" className="w-100 h-100" />
            </div>
            <div className="digitalWatch w-100">
              <div>
                {" "}
                00 <span className="ps-1"> : </span>
              </div>
              <div>
                {" "}
                09 <span className="ps-1"> : </span>
              </div>
              <div>
                {" "}
                47 <span className="ps-1"> : </span>
              </div>
              <div> 21 </div>
              <div className="time">
                <div className="day">day</div>
                <div className="day">Hours</div>
                <div className="day">MIN</div>
                <div className="day">SEC</div>
              </div>
            </div>
            <div className="d-flex justify-content-between w-100 ">
              <div className="claimtext2">
                LOCKED <span className="neontext">$SHIP</span> BALANCE
              </div>
              <div className="d-flex energy">
                <div className="energy_img">
                  <img src={thunder} alt="" className="w-100 h-100 d-flex" />
                </div>
                <div className="energy_text">00</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid news_latter my-5 p-lg-2 p-md-2 p-4">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-8 p-3 order-lg-1 order-md-1 order-1">
            <div className="news_content">
              <div className="news_txt d-flex gap-2 flex-md-row flex-column">
                <span className="crown_img">
                  <img src={taz} alt="" className="img-fluid" />
                </span>
                <span className="news_title fw-bold text-white mt-md-0 mt-5">
                  FREE{" "}
                  <span className="fw-bold" style={{ color: "#E5AB3D" }}>
                    $100!
                  </span>{" "}
                  REGISTER AND ENTER A SPECIAL CODE!
                </span>
              </div>
              <div className="user_abt d-flex align-items-md-center flex-md-row flex-column gap-2 mt-md-5">
                <span className="text-white">
                  Players Who Received The Bonus
                </span>
                <ul className="user_list p-0 m-0 d-flex align-items-center">
                  <li>
                    <span>
                      <img src={profileUser} alt="" className="img-fluid" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src={profileUser} alt="" className="img-fluid" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src={profileUser} alt="" className="img-fluid" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src={profileUser} alt="" className="img-fluid" />
                    </span>
                  </li>
                  <li>
                    <span>
                      <img src={profileUser} alt="" className="img-fluid" />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-6 order-lg-2 order-md-2 order-3">
            <div className="bet_content text-center p-3 h-100 d-flex flex-column justify-content-center">
              <div className="fs-4 bt_head" style={{ color: "#E5AB3D" }}>
                YOUR BET
              </div>
              <h2 className="text-white fw-bold my-md-2 mb-m-1 mb-1">
                VIP CLUB
              </h2>
              <p className="text-white bt_txt">
                Free <span style={{ color: "#E5AB3D" }}>$100!</span> Register
                and enter a special code!
              </p>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-6 order-lg-3 order-md-3 order-2">
            <div className="news_img position-absolute top-0 h-100">
              <img src={girl} alt="" className="img-fluid h-100" />
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-12 order-lg-4 order-md-4 order-4">
            <div className="news_input d-flex justify-content-center flex-column h-100">
              <h4 className="fw-bold d-md-block d-none">FREE BONUS</h4>
              <div className="input_bx position-relative d-flex align-items-center ps-3">
                <input
                  type="text"
                  placeholder="12A4 1233 322434 4343 4342"
                  className="w-100 bg-transparent"
                />
                <button type="button" className="m-0 border-0 h-100">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    height="17px"
                    width="17px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="14"
                      height="14"
                      x="8"
                      y="8"
                      rx="2"
                      ry="2"
                    ></rect>
                    <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid  g-3">
        <div className="g-col-lg-4 g-col-12 vip_latest">
          <div className="d-flex gap-2">
            <div className="crownImg">
              <img src={crown} alt="" className="w-100 h-100 object-contain" />
            </div>
            <div className="crowntext">Latest Added</div>
          </div>
          <div className="innerSection">
            <div className="text">Earn up to</div>
            <div className="subheading">
              <div className="label_1">50%</div>
              <div>revenue share</div>
            </div>
            <div className=" benefitList">
              <div className="checkImg">
                <img src={check} alt="" className="h-100 w-100 d-flex" />
              </div>
              <div className="benefittext">benefit 1 </div>
            </div>
            <div className=" benefitList">
              <div className="checkImg">
                <img src={check} alt="" className="h-100 w-100 d-flex" />
              </div>
              <div className="benefittext">benefit 1 </div>
            </div>
            <div className=" benefitList">
              <div className="checkImg">
                <img src={check} alt="" className="h-100 w-100 d-flex" />
              </div>
              <div className="benefittext">benefit 1 </div>
            </div>
            <div className="girlImg">
              <img src={girl_2} alt="" className="h-100 w-100" />
            </div>
          </div>
        </div>
        <div className="g-col-lg-8 g-col-12 vip_latest">
          <div className="d-flex  gap-2">
            <div className="crownImg">
              <img src={crown} alt="" className="w-100 h-100 object-contain" />
            </div>
            <div className="crowntext">Latest Added</div>
          </div>
          <div className="grid m-0 customGrid">
            <div class="RodeoCard g-col-md-1 g-col-4">
              <div className="blurlayer">
                <img src={layer} alt="" className="h-100 w-100" />
              </div>
              <div className="superpoint">103.32</div>
              <div className="rodeoimg">
                <img src={frozen} alt="" className="h-100 w-100" />
              </div>
              <div>super rodeo</div>
            </div>
            <div class="RodeoCard g-col-md-1 g-col-4">
              <div className="blurlayer">
                <img src={layer} alt="" className="h-100 w-100" />
              </div>
              <div className="superpoint">103.32</div>
              <div className="rodeoimg">
                <img src={frozen_2} alt="" className="h-100 w-100" />
              </div>
              <div>super rodeo</div>
            </div>
            <div class="RodeoCard g-col-md-1 g-col-4">
              <div className="blurlayer">
                <img src={layer} alt="" className="h-100 w-100" />
              </div>
              <div className="superpoint">103.32</div>
              <div className="rodeoimg">
                <img src={frozen_3} alt="" className="h-100 w-100" />
              </div>
              <div>super rodeo</div>
            </div>
            <div class="RodeoCard g-col-md-1 g-col-6">
              <div className="blurlayer">
                <img src={layer} alt="" className="h-100 w-100" />
              </div>
              <div className="superpoint">103.32</div>
              <div className="rodeoimg">
                <img src={frozen_4} alt="" className="h-100 w-100" />
              </div>
              <div>super rodeo</div>
            </div>
            <div class="RodeoCard g-col-md-1 g-col-6">
              <div className="blurlayer">
                <img src={layer} alt="" className="h-100 w-100" />
              </div>
              <div className="superpoint">103.32</div>
              <div className="rodeoimg">
                <img src={frozen_5} alt="" className="h-100 w-100" />
              </div>
              <div>super rodeo</div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        <div className="d-flex gap-2">
          <div className="flameImg">
            <img src={flame} alt="" className="w-100 h-100 object-contain" />
          </div>
          <div className="crowntext mt-1">Latest Added</div>
        </div>
        <div className="navigation">
          <div className=" cursor-pointer prevbtn latestPrev">
            <img src={arrow} alt="" className="arrow" />
          </div>
          <div className=" cursor-pointer nextbtn latestNext">
            <img src={arrow} alt="" className="arrow" />
          </div>
        </div>
      </div>
      <Swiper
        slidesPerView={1.7}
        spaceBetween={10}
        breakpoints={{
          400: {
            slidesPerView: 2.5,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 5.3,
            spaceBetween: 10,
          },
        }}
        navigation={{
          nextEl: `.latestPrev`,
          prevEl: `.latestNext`,
          // navigation : 'true',
        }}
        modules={[Navigation]}
        className="mySwiper position-relative"
      >
        <SwiperSlide>
          <div className="superbonusbox">
            <div
              className="imgbox"
              style={{
                "--bgcolor":
                  "linear-gradient(360deg, #0744FA 0%, #0F96F9 100%)",
              }}
            >
              <div className="jarimg">
                <img src={bonus_1} alt="" className="w-100 h-100" />
              </div>
            </div>
            <div className="">
              <div className="darktext">Super Bonus</div>
              <div className="money">$1000,000</div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="superbonusbox">
            <div
              className="imgbox"
              style={{
                "--bgcolor":
                  "linear-gradient(360deg, #E61105 0%, #D76C07 100%)",
              }}
            >
              <div className="jarimg">
                <img src={bonus_2} alt="" className="w-100 h-100" />
              </div>
            </div>
            <div className="">
              <div className="darktext">Super Bonus</div>
              <div className="money">$1000,000</div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="superbonusbox">
            <div
              className="imgbox"
              style={{
                "--bgcolor":
                  "linear-gradient(360deg, #FF8600 0%, #F4CE20 100%)",
              }}
            >
              <div className="jarimg">
                <img src={bonus_3} alt="" className="w-100 h-100" />
              </div>
            </div>
            <div className="">
              <div className="darktext">Super Bonus</div>
              <div className="money">$1000,000</div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="superbonusbox">
            <div
              className="imgbox"
              style={{
                "--bgcolor":
                  "linear-gradient(360deg, #D6073C 0%, #67129B 100%)",
              }}
            >
              <div className="jarimg">
                <img src={bonus_4} alt="" className="w-100 h-100" />
              </div>
            </div>
            <div className="">
              <div className="darktext">Super Bonus</div>
              <div className="money">$1000,000</div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="superbonusbox">
            <div
              className="imgbox"
              style={{
                "--bgcolor":
                  "linear-gradient(360deg, #F01303 0%, #D76D07 100%)",
              }}
            >
              <div className="jarimg">
                <img src={bonus_5} alt="" className="w-100 h-100" />
              </div>
            </div>
            <div className="">
              <div className="darktext">Super Bonus</div>
              <div className="money">$1000,000</div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="superbonusbox">
            <div
              className="imgbox"
              style={{
                "--bgcolor":
                  "linear-gradient(360deg, #5603F0 0%, #D105FF 100%)",
              }}
            >
              <div className="jarimg">
                <img src={bonus_6} alt="" className="w-100 h-100" />
              </div>
            </div>
            <div className="">
              <div className="darktext">Super Bonus</div>
              <div className="money">$1000,000</div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

      <div className="levelSection ">
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-2">
            <div className="crownImg">
              <img src={crown} alt="" className="w-100 h-100 object-contain" />
            </div>
            <div
              className=""
              style={{
                fontWeight: "500",
                fontSize: "13.36px",
                lineHeight: "16px",
                letterSpacing: "0",
                textAlign: "center",
                color: "#556573",
              }}
            >
              Latest Added
            </div>
          </div>
          <div className="navigation">
            <div className=" cursor-pointer prevbtn ranktNext">
              <img src={arrow} alt="" className="arrow" />
            </div>
            <div className="  cursor-pointer nextbtn   rankPrev ">
              <img src={arrow} alt="" className="arrow" />
            </div>
          </div>
        </div>

        <div className="levelcontain">
          <div className=" m-0 g-2 mt-4">
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              breakpoints={{
                400: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1150: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1300: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1400: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
              }}
              navigation={{
                nextEl: `.rankPrev`,
                prevEl: `.ranktNext`,
                // navigation : 'true',
              }}
              modules={[Navigation]}
              className="mySwiper position-relative"
            >
              {levels.map((item) => {
                return (
                  <SwiperSlide>
                    <div className=" levelCard active">
                      <div className="levelcardInner levelblock">
                        <div className="text">{item.name.toUpperCase()}</div>
                        <div className="d-flex star justify-content-center gap-1 mt-1">
                          <div className="starimg">
                            <img src={bgstar} alt="" className="w-100 h-100" />
                          </div>
                          <div className="starimg">
                            <img src={bgstar} alt="" className="w-100 h-100" />
                          </div>
                          <div className="starimg">
                            <img src={bgstar} alt="" className="w-100 h-100" />
                          </div>
                          <div className="starimg">
                            <img src={star} alt="" className="w-100 h-100" />
                          </div>
                          <div className="starimg">
                            <img src={star} alt="" className="w-100 h-100" />
                          </div>
                        </div>
                        <div>
                          <div className="points">
                            <div className="d-flex justify-content-between reward">
                              <div className="d-flex align-items-center gap-2">
                                <div className="coin">
                                  <img
                                    src={coin}
                                    alt=""
                                    className="h-100 w-100 d-flex"
                                  />
                                </div>
                                <div className="point_text">1.0</div>
                              </div>
                              <div className="point_text">0.00</div>
                            </div>
                            <div className="divider"></div>
                            <div className="d-flex justify-content-between reward">
                              <div className="d-flex align-items-center gap-2">
                                <div className="trophy">
                                  <img
                                    src={trophy}
                                    alt=""
                                    className="h-100 w-100 d-flex"
                                  />
                                </div>
                                <div className="point_text">1.0</div>
                              </div>
                              <div className="point_text">0.00</div>
                            </div>
                            <div className="divider"></div>
                            <div className="d-flex justify-content-between reward">
                              <div className="d-flex align-items-center gap-2">
                                <div className="coin">
                                  <img
                                    src={thunder_2}
                                    alt=""
                                    className="h-100 w-100 d-flex"
                                  />
                                </div>
                                <div className="point_text">1.0</div>
                              </div>
                              <div className="point_text">0.00</div>
                            </div>
                            <div className="divider"></div>
                          </div>
                          <div className="textbold">{item.max_xp}</div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="levelprogress">
            <div className="progress" style={{ "--width": "16.66%" }}>
              {/* width 8.33*2 */}
              <div className="dottsholder">
                <div className="dotts"></div>
                <div className="dotts"></div>
                <div className="dotts"></div>
                <div className="dotts"></div>
                <div className="dotts"></div>
                <div className="dotts"></div>
                <div className="dotts"></div>
                <div className="dotts"></div>
                <div className="dotts"></div>
                <div className="dotts"></div>
                <div className="dotts"></div>
                <div className="dotts"></div>
                <div className="dotts"></div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-2 dott_text">
              <div>0.00</div>
              <div>0.00</div>
              <div>0.00</div>
              <div>0.00</div>
              <div>0.00</div>
              <div>0.00</div>
              <div>0.00</div>
              <div>0.00</div>
              <div>0.00</div>
              <div>0.00</div>
              <div>0.00</div>
              <div>0.00</div>
              <div>0.00</div>
            </div>
          </div>
        </div>
      </div>

      <div className="topWinner">
        <div className="d-flex gap-2">
          <div className="crownImg">
            <img src={crown} alt="" className="w-100 h-100 object-contain" />
          </div>
          <div className="crowntext">Latest Added</div>
        </div>
        <div className="range_Bar position-relative">
          <div className="range_line position-absolute"></div>
          <div
            className="first first_1"
            style={{
              "--width": "70%",
              "--bg": "linear-gradient(180deg, #F8BA28 0%, #FFD163 100%)",
              "--border": "#F8BA28",
            }}
          >
            <div className="winner winner_1" style={{ "--left": "68%" }}>
              <div className="winnerImg">
                <img src={avatar} alt="" className="w-100 h-100" />
              </div>
            </div>
            <div className="position-absolute username username_1 text-uppercase text-white">
              Username
            </div>
            <div className="winnumber winneractive">1</div>
          </div>
          <div
            className="first first_2"
            style={{
              "--width": "66%",
              "--bg": "linear-gradient(180deg, #0E95FA 0%, #9FD6FF 100%)",
              "--border": "#0E95FA",
            }}
          >
            <div className="winner winner_2" style={{ "--left": "64%" }}>
              <div className="winnerImg">
                <img src={avatar} alt="" className="w-100 h-100" />
              </div>
            </div>
            <div className="position-absolute username username_2 text-uppercase text-white">
              Username
            </div>
            <div className="winnumber winneractive">2</div>
          </div>
          <div
            className="first first_3"
            style={{
              "--width": "62%",
              "--bg": "linear-gradient(180deg, #661393 0%, #D70A46 100%)",
              "--border": "#8E002A",
            }}
          >
            <div className="winner winner_3" style={{ "--left": "60%" }}>
              <div className="winnerImg">
                <img src={avatar} alt="" className="w-100 h-100" />
              </div>
            </div>
            <div className="position-absolute username username_3 text-uppercase text-white">
              Username
            </div>
            <div className="winnumber winneractive">3</div>
          </div>
          <div
            className="first first_4"
            style={{
              "--width": "58%",
              "--bg": "transparent",
              "--border": "#2F364C",
            }}
          >
            <div className="winner winner_4" style={{ "--left": "56%" }}>
              <div className="winnerImg">
                <img src={avatar} alt="" className="w-100 h-100" />
              </div>
            </div>
            <div className="position-absolute username username_4 text-uppercase text-white">
              Username
            </div>
            <div className="winnumber">4</div>
          </div>
          <div
            className="first first_5"
            style={{
              "--width": "54%",
              "--bg": "transparent",
              "--border": "#2F364C",
            }}
          >
            <div className="winner winner_5" style={{ "--left": "52%" }}>
              <div className="winnerImg">
                <img src={avatar} alt="" className="w-100 h-100" />
              </div>
            </div>
            <div className="position-absolute username username_5 text-uppercase text-white">
              Username
            </div>
            <div className="winnumber">5</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VIP;
