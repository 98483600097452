import AccountInfo from "./AccountInfo";
import Security from "./Security";
import AccountPrefernece from "./AccountPrefernece";
import { useState } from "react";


const SettingMobile = () => {
  const [selectedTab, setSelectedTab] = useState("account");
  return (
    <>
      <div className="profile_section">
        <AccountInfo  selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        <Security   selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        <AccountPrefernece   selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      </div>
    </>
  );
};

export default SettingMobile;
