import { sendPasswordResetEmail } from 'firebase/auth';
import { Formik } from 'formik'
import React from 'react'
import * as Yup from "yup";
import { auth } from '../../../../config/firebase';
import { errorToaster, succesToaster } from '../../../../utils/toaster';
import ErrorMessage from '../../../common/errorMessage/ErrorMessage';
import { useTranslation } from 'react-i18next';

const validationForgotPassword = Yup.object({
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
      "Invalid email"
    ),
});

const LoginForgetPassword = () => {
  const { t } = useTranslation()
  const authLabels = t("AuthLabels", { returnObjects: true })

  const handleSendLink = async (values) => {
    try {
      const res = await sendPasswordResetEmail(auth, values.email);
      succesToaster('Link sent Succesfully!!')
    } catch (error) {
      errorToaster("Something went wrong")
      console.error("Error occurred during password reset:", error);
    } finally {
      // setIsLoading(false)
    }
  };
  return (
    <>
      
      <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={validationForgotPassword}
            onSubmit={(values) => {
              // setIsLoading(true);
              // onSubmit(values);
              // signInWEAP(values)
              handleSendLink(values)
            }}
          >
            {({
              values,
              handleBlur,
              handleChange,
              handleSubmit,
              errors,
              touched,
            }) => (
              <form>
                {/* <div className='text-light mb-3'>Forgot Password</div> */}
                <div className="col-12">
                  <input
                    className='commonInp'
                    type="email"
                    // placeholder='Enter Your Email'
                    placeholder={authLabels.Email}
                    name="email"
                    id="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <ErrorMessage>
                  {errors.email && touched.email && errors.email}
                </ErrorMessage>
                <div className="col-12 mt-3">
                  <button
                    className='signUpBtn text-white'
                    type='button'
                    onClick={(e) => {
                      e.preventDefault()
                      handleSubmit()
                    }}
                  >
                    {/* Send Link */}
                    {authLabels.Send_Link}
                  </button>
                </div>
              </form>
            )}

          </Formik>
    </>
  )
}

export default LoginForgetPassword