import { useContext } from "react"
import { WalletModalContext } from "../context/walletModalContext"


export const useWalletModal = () => {
    const { walletModalObject, setWalletModalObject } = useContext(WalletModalContext)
    return {
        walletModalObject,
        setWalletModalObject
    }
}
