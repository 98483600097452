import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import userWallet from "./../../assets/images/wallet.svg";
import userWithdraw from "./../../assets/images/withdraw.svg";
import userTransactions from "./../../assets/images/transactions.svg";
import buy from "./../../assets/images/buy.svg";
import swap from "./../../assets/images/swap.svg";
import rollover from "./../../assets/images/rollover.svg";
import bitHistory from "./../../assets/images/bit-history.svg";
import deposit from "./../../assets/images/deposit.svg";
import totalBalance from "./../../assets/images/total-balance.svg";
import rupeeIcon from "./../../assets/images/rupee_icon.svg";
import USDT from "./../../assets/images/usdt.svg";
import BTC from "./../../assets/images/btc.svg";
import BNB from "./../../assets/images/bnb.svg";
import ETH from "./../../assets/images/eth.svg";
import TURBO from "./../../assets/images/turbo.svg";
import USDC from "./../../assets/images/usdc.svg";
import AVAX from "./../../assets/images/avax.svg";
import CBANK from "./../../assets/images/cbank.svg";
import CAF from "./../../assets/images/caf.svg";
import cairo from "./../../assets/images/cairo.svg";
import thunderIcon from "./../../assets/images/thunder-icon.svg";
import giftBox from "./../../assets/images/gift.svg";
import barImage from "./../../assets/images/barCode.svg";
import EUR from "./../../assets/images/eur.svg";
import sepaInstant from "./../../assets/images/sepa-instant.svg";
import Euro from "./../../assets/images/euro.svg";
import swapIcon from "./../../assets/images/swap-icon.svg";
import fileEmpty from "../../assets/images/fileEmpty.svg";
import BalanceTab from "./BalanceTab";
import WithdrawTab from "./WithdrawTab";
import DepositWalletTab from "./DepositWalletTab";
import SwapTab from "./SwapTab";
import TransactionTab from "./TransactionTab";
import RolloverTab from "./RolloverTab";
import BetHistoryTab from "./BetHistoryTab";

const WalletMobile = () => {
  const [searchParams] = useSearchParams();

  const paramValue = searchParams.get("tab");
  const [selectedTab, setSelectedTab] = useState("balance");

  useEffect(() => {
    if (paramValue) {
      console.log(paramValue.toLowerCase(), "paramValue");
      setSelectedTab(paramValue.toLowerCase());
    }
  }, [paramValue]);

  console.log({ selectedTab }, "ffhfhfhhff");

  return (
    <>
      <div className="container-fluid wallet_sec my-4">
        <div className="row">
          <div className="wallet_box wallet_mobile">
            <div className="balance_accord mb-3">
              <div className="mb-3">
                <button
                  onClick={() => setSelectedTab("balance")}
                  className={`drop_mb_tab ${
                    selectedTab.toLocaleLowerCase() === "balance"
                      ? "active"
                      : ""
                  }  w-100 rounded-pill`}
                  id="nav-balance-tab"
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="dropIcon">
                      <img src={userWallet} alt="" />
                    </div>
                    <div className="DropTxt">Balance</div>
                  </div>
                </button>
              </div>
              {selectedTab.toLocaleLowerCase() === "balance" ? (
                <BalanceTab setSelectedTab={setSelectedTab} />
              ) : null}
            </div>

            <div className="deposit_accord mb-3">
              <div className="mb-3">
                <button
                  onClick={() => setSelectedTab("deposit")}
                  className={`drop_mb_tab ${
                    selectedTab.toLocaleLowerCase() === "deposit"
                      ? "active"
                      : ""
                  }  w-100 rounded-pill`}
                  id="nav-deposit-tab"
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="dropIcon">
                      <img src={deposit} alt="" />
                    </div>
                    <div className="DropTxt">Deposit</div>
                  </div>
                </button>
              </div>
              {selectedTab.toLocaleLowerCase() === "deposit" ? (
                <DepositWalletTab />
              ) : null}
            </div>

            <div className="withdraw_accord mb-3">
              <div className="mb-3">
                <button
                  onClick={() => setSelectedTab("withdraw")}
                  className={`drop_mb_tab ${
                    selectedTab.toLocaleLowerCase() === "withdraw"
                      ? "active"
                      : ""
                  }  w-100 rounded-pill`}
                  id="nav-withdraw-tab"
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="dropIcon">
                      <img src={userWithdraw} alt="" />
                    </div>
                    <div className="DropTxt">Withdraw</div>
                  </div>
                </button>
              </div>
              {selectedTab.toLocaleLowerCase() === "withdraw" ? (
                <WithdrawTab />
              ) : null}
            </div>

            <div className="swap_accord mb-3">
              <div className="mb-3">
                <button
                  onClick={() => setSelectedTab("swap")}
                  className={`drop_mb_tab ${
                    selectedTab.toLocaleLowerCase() === "swap" ? "active" : ""
                  }  w-100 rounded-pill`}
                  id="nav-swap-tab"
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="dropIcon">
                      <img src={swap} alt="" />
                    </div>
                    <div className="DropTxt">Swap</div>
                  </div>
                </button>
              </div>
              {selectedTab === "swap" ? <SwapTab /> : null}
            </div>

            <div className="transaction_accord mb-3">
              <div className="mb-3">
                <button
                  onClick={() => setSelectedTab("transaction")}
                  className={`drop_mb_tab ${
                    selectedTab.toLocaleLowerCase() === "transaction"
                      ? "active"
                      : ""
                  }  w-100 rounded-pill`}
                  id="nav-transaction-tab"
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="dropIcon">
                      <img src={userTransactions} alt="" />
                    </div>
                    <div className="DropTxt">Transaction</div>
                  </div>
                </button>
              </div>
              {selectedTab === "transaction" ? <TransactionTab /> : null}
            </div>

            <div className="rollover_accord mb-3">
              <div className="mb-3">
                <button
                  onClick={() => setSelectedTab("rollover")}
                  className={`drop_mb_tab ${
                    selectedTab.toLocaleLowerCase() === "rollover"
                      ? "active"
                      : ""
                  }  w-100 rounded-pill`}
                  id="nav-rollover-tab"
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="dropIcon">
                      <img src={rollover} alt="" />
                    </div>
                    <div className="DropTxt">Rollover</div>
                  </div>
                </button>
              </div>
              {selectedTab.toLocaleLowerCase() === "rollover" ? (
                <RolloverTab />
              ) : null}
            </div>

            <div className="betHistory_accord mb-3">
              <div className="mb-3">
                <button
                  onClick={() => setSelectedTab("betHistory")}
                  className={`drop_mb_tab ${
                    selectedTab.toLocaleLowerCase() === "betHistory"
                      ? "active"
                      : ""
                  }  w-100 rounded-pill`}
                  id="nav-betHistory-tab"
                >
                  <div className="d-flex align-items-center gap-2">
                    <div className="dropIcon">
                      <img src={bitHistory} alt="" />
                    </div>
                    <div className="DropTxt">Bet History</div>
                  </div>
                </button>
              </div>
              {selectedTab.toLocaleLowerCase() === "betHistory" ? (
                <BetHistoryTab />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletMobile;
