import React, { useState, createContext } from 'react'
import { BONUS_DROPDOWN_OPTIONS } from '../constants'

export const BonusContext = createContext(null)

const BonusContextProvider = ({children}) => {
  const [bonusObject, setBonusObject] = useState({
    isBonusOpen : false,
    selectedTab : BONUS_DROPDOWN_OPTIONS.BONUS_MAIN
  })
  return (
    <BonusContext.Provider value={{bonusObject, setBonusObject}}>{children}</BonusContext.Provider>
  )
}

export default BonusContextProvider