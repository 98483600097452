import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { errorToaster, succesToaster } from '../../../utils/toaster';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import { getAPI, getAPIAuth, postAPIAuth } from '../../../services/apiInstance';
const SubscribeBTCModal = ({
    show,
    setShow,
    selectedItem,
    selectedFixedTerm,
    selectedBoostedTerm,
    setSelectedBoostedTerm,
    setSelectedFixedTerm,
    selectedPoolType,
    setSelectedPoolType
}) => {
    const [flexible, setFlexible] = useState([])
    const [fixedTerm, setFixedTerm] = useState([])
    const [boostedFixedTerm, setBoostedFixedTerm] = useState([])
    const [subscriptionAmount, setSunscriptionAmount] = useState('')
    const userBalance = useSelector(state => state.userBalance)
    const [termsChecked, setTermsChecked] = useState(false)
    const [userCurrencyBalance, setUserCurrencyBalance] = useState(0)
    const [loading, setLoading] = useState(false)
    const [converted, setConverted] = useState({
        balance: "",
        amount: ""
    })
    let timerRef


    const handleClose = () => setShow(false);

    const getUserCurrencyAmount = async ()=> {
        try {
            const res = await getAPIAuth(`crypto/crypto-wallet?cryptoId=${selectedItem?.currency_id}`)
            if(res?.data?.success) {
                setUserCurrencyBalance(res?.data?.data)
            }
        } catch (error) {
            
        }
    }

    useEffect(()=> {
        getUserCurrencyAmount()
    }, [selectedItem?.currency_id])

    const getConverted = async (field, amount) => {
        try {
            const res = await getAPI(`crypto/convert-amount?cryptoId=${selectedItem?.currency_id}&amount=${amount}`)
            if (res.data.success) {
                // setConvertedBalance(res.data.data.amount_usdt)
                setConverted(pre => ({
                    ...pre,
                    [field]: res.data.data.amount_usdt
                }))
                // setConvertedBalance('100.00')
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        getConverted("balance", userBalance?.balance)
    }, [userBalance?.balance, selectedItem?.currency_id])

    useEffect(() => {
        // getConverted("balance", userBalance?.balance)
        if (timerRef) {
            clearTimeout(timerRef)
        }
        timerRef = setTimeout(() => {
            if (selectedItem?.currency_id && subscriptionAmount) {
                getConverted("amount", subscriptionAmount)
            }

        }, 1000)
        return () => {
            clearTimeout(timerRef)
        }
    }, [subscriptionAmount, selectedItem?.currency_id])

    useEffect(() => {
        const flexibleData = selectedItem?.term?.filter(item => item?.poolType === "Flexible")
        setFlexible(flexibleData?.[0])
        const fixedTermData = selectedItem?.term?.filter(item => item?.poolType === "Fixed-Term")
        setFixedTerm(fixedTermData?.[0])
        const boostedFixedTermData = selectedItem?.term?.filter(item => item?.poolType === "Boosted Fixed-Term")
        setBoostedFixedTerm(boostedFixedTermData?.[0])
    }, [selectedItem])

    const handleSubscribe = async () => {
        // if(subscriptionAmount > userBalance?.balance ) {

        // }
        if (termsChecked) {
            if(subscriptionAmount > userCurrencyBalance.totalBalance) {
                errorToaster('Not enough funds')
                return
            }
            if(subscriptionAmount === '' || subscriptionAmount == 0  ) {
                errorToaster('Please enter vaild amount')
                return
            }
            setLoading(true)
            const tabValue = selectedPoolType === 'Fixed-Term' ? selectedFixedTerm?.name : selectedPoolType === "Boosted Fixed-Term" ? selectedBoostedTerm?.name : '';
            const aprValue = selectedPoolType === 'Fixed-Term' ? fixedTerm?.apr : selectedPoolType === "Boosted Fixed-Term" ? boostedFixedTerm?.apr : flexible?.apr;
            const termId = selectedPoolType === 'Fixed-Term' ? fixedTerm?._id : selectedPoolType === "Boosted Fixed-Term" ? boostedFixedTerm?._id : flexible?._id;

            const params = {
                pool_type: selectedPoolType,
                crypto_id: selectedItem?.currency_id,
                apr: aprValue,
                cryptostakingparamter: termId,
                tab_value: tabValue,
                amount: subscriptionAmount ? Number(subscriptionAmount) : '',
                principleAmount : selectedPoolType === "Boosted Fixed-Term" ? selectedBoostedTerm?.principleAmount : ''
            }

            // const params ={
            //     pool_type:"Fixed-Term",
            //     crypto_id:"6501aa1008727d2352323253",
            //     apr:15,
            //     cryptostakingparamter:"66114fe5cbab86ad32233dff",
            //     tab_value:30,
            //     amount:100
            // }

            try {
                const res = await postAPIAuth('crypto/subscribe-staking', params)
                if(res?.data?.success) {
                    succesToaster('Subscribed successfully')
                    setShow(false)
                    setSunscriptionAmount('')
                } else {
                    errorToaster(res?.data?.message)
                }
            } catch (error) {
                errorToaster('something error has happened')
                setShow(false)
                setSunscriptionAmount('')
            } finally {
                setLoading(false)
            }
        } else {
            errorToaster('Please agree terms and conditions')
        }
    }

    return (
        <>
            <Modal
                className='firstCryptoModal fourthCryptoModal'
                show={show}
                onHide={handleClose}
                centered
                scrollable
                size="xl"
                aria-labelledby="example-modal-sizes-title-xl"
            >
                <Modal.Header className='modalHeader d-flex justify-content-between align-items-center'>
                    <div className="row w-100 mx-0">
                        <div className="col-lg-7">
                            <Modal.Title className='modalTitle d-flex align-items-center'>Subscribe <span className="text-uppercase"> {selectedItem?.currency?.code}</span> <img className='ms-2' src={selectedItem?.currency?.icon} alt="" /></Modal.Title>
                        </div>
                        <div className="col-lg-5 d-lg-block d-none">
                            <Modal.Title className='modalTitle modalTitleLight' style={{ paddingLeft: '1.15rem' }}>Summary</Modal.Title>
                        </div>
                    </div>
                    <div className="closeBTn cursor-pointer" onClick={handleClose}>
                        <svg className='size-6 text-white' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path></svg>
                    </div>
                </Modal.Header>
                <Modal.Body className='modalBody overflow-x-hidden'>
                    <div className="row">
                        <div className="col-lg-7 col-12">
                            <div className="row">
                                <div className="col-12">
                                    <div className={`box1 ${(selectedPoolType === 'Flexible') || (selectedPoolType === 'Fixed-Term') ? 'active' : ''}`}>
                                        <div className="row">
                                            <div className="col-12"><div className='textlight'><span className=''>
                                                {flexible?._id ? 'Flexible | ' : ''}
                                                {fixedTerm?.tab?.length ? ' Fixed-Term' : ''}
                                            </span></div></div>
                                            <div className="col-12 mt-2">
                                                <div className="row gap-1 mx-0">
                                                    {
                                                        flexible?._id ?
                                                            <div className="col-auto px-0">
                                                                <button onClick={() => {
                                                                    setSelectedPoolType('Flexible')
                                                                }} className={`flexBtns ${(selectedPoolType === 'Flexible') ? 'active' : ''}`}>Flexible</button>
                                                            </div>
                                                            : ''
                                                    }
                                                    {
                                                        fixedTerm?.tab?.length ?
                                                            fixedTerm?.tab?.map(item => (
                                                                <div className="col-auto px-0" key={item?.name}>
                                                                    <button onClick={() => {
                                                                        setSelectedFixedTerm(item)
                                                                        setSelectedPoolType('Fixed-Term')
                                                                    }} className={`flexBtns ${(selectedPoolType === 'Fixed-Term') && selectedFixedTerm?.name === item?.name ? 'active' : ''}`}>{item?.name}</button>
                                                                </div>
                                                            ))
                                                            : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    boostedFixedTerm?.tab?.length ?
                                        <div className="col-12 mt-3">
                                            <div className={`box1 ${selectedPoolType === 'Boosted Fixed-Term' ? 'active' : ''}`}>
                                                <div className="row">
                                                    <div className="col-12"><div className='textlight'><span className=''>Boosted Fixed-Term</span></div></div>
                                                    <div className="col-12 mt-2">
                                                        <div className="row gap-1 mx-0">
                                                            {boostedFixedTerm?.tab?.map(item => (
                                                                <div className="col-auto px-0" key={item?.name}>
                                                                    <button onClick={() => {
                                                                        setSelectedBoostedTerm(item)
                                                                        setSelectedPoolType('Boosted Fixed-Term')
                                                                    }} className={`flexBtns ${(selectedPoolType === 'Boosted Fixed-Term') && selectedBoostedTerm?.name === item?.name ? 'active' : ''}`}>{item?.name}</button>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    {
                                                        selectedPoolType === 'Boosted Fixed-Term' ?
                                                            <div className="col-12 mt-3">
                                                                <div className='textlight'>Terms <img src="./assets/img/stacking/question.png" alt="" /></div>
                                                                <div className='textlight textgreen mt-1'>Principle Amount x {selectedBoostedTerm?.principleAmount}</div>
                                                            </div>
                                                            : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        : ''
                                }
                                {
                                    selectedPoolType === 'Flexible' ? <>
                                        <div className="col-12 d-flex justify-content-start my-2">
                                            <div className="textlight">Your balance: <img className='rounded-circle overflow-hidden' src={selectedItem?.currency?.icon} alt="" /> {
                                                userCurrencyBalance.totalBalance || userCurrencyBalance.totalBalance === 0 ? Number(userCurrencyBalance.totalBalance)?.toFixed(2)  : '0.00'}</div>
                                                {/* converted.balance || converted.balance === 0 ? Number(converted.balance)?.toFixed(2) : '0.00'}</div> */}
                                        </div>
                                        
                                        <div className="col-12">
                                            <div className="row mx-0">
                                                <div className="col-md-10 col-9 px-0">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="subscriptionInput">
                                                                <div className="bitImg"><img src={selectedItem?.currency?.icon} alt="" /></div>
                                                                <input
                                                                    className={`form-control shadow-none ${subscriptionAmount > userCurrencyBalance.totalBalance ? 'error' : ''}`}
                                                                    type="text"
                                                                    id='subscriptionAmount'
                                                                    placeholder=''
                                                                    value={subscriptionAmount}
                                                                    onChange={(e) => {
                                                                        if (isNaN(Number(e.target.value))) return
                                                                        setSunscriptionAmount(e.target.value)
                                                                    }}
                                                                />
                                                                <label htmlFor="subscriptionAmount" className='subLabel'>Subscription</label>
                                                                <div className="inpAmount">
                                                                    {
                                                                        converted.amount ? `$ ${converted.amount}` : '$0.00'
                                                                    }
                                                                </div>
                                                                {
                                                                    subscriptionAmount?.length ?
                                                                        <div className="crossBtn" onClick={() => setSunscriptionAmount('')}>
                                                                            <svg className='' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M278.6 256l68.2-68.2c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-68.2-68.2c-6.2-6.2-16.4-6.2-22.6 0-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3l68.2 68.2-68.2 68.2c-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3 6.2 6.2 16.4 6.2 22.6 0l68.2-68.2 68.2 68.2c6.2 6.2 16.4 6.2 22.6 0 6.2-6.2 6.2-16.4 0-22.6L278.6 256z"></path></svg>
                                                                        </div>
                                                                        : ''
                                                                }
                                                            </div>
                                                            {
                                                                subscriptionAmount > userCurrencyBalance.totalBalance ? <div className="errorTxt">Not enough funds</div> : ''
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2 col-3 pe-0">
                                                    <div onClick={() => setSunscriptionAmount(userCurrencyBalance.totalBalance)} className="max">Max</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <div className="row triedBox mx-0 px-3">
                                                <div className="col-12 mb-0 px-0 py-1"><div className="textlight">Tiered APY <img src="./assets/img/stacking/question.png" alt="" /></div></div>
                                                {
                                                    flexible?.Tiered_APY?.map(item => (
                                                        <div className="col-12 d-flex justify-content-between borderBottom mb-2 px-0 py-1">
                                                            <div><div className="textlight">{item?.name} $  {item?.min} ≤ $ {item?.max}</div></div>
                                                            <div><div className="textlight">{item?.percentage}%</div></div>
                                                        </div>
                                                    ))
                                                }
                                                {/* <div className="col-12 d-flex justify-content-between borderBottom mb-2 px-0 py-1">
                                                        <div><div className="textlight">Tier 1 $  0 ≤ $250 </div></div>
                                                        <div><div className="textlight">8%</div></div>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-between px-0 py-1">
                                                        <div><div className="textlight">Tier 1 $  0 ≤ $250 </div></div>
                                                        <div><div className="textlight">6%</div></div>
                                                    </div> */}
                                            </div>
                                        </div>
                                        <div className="col-12 mt-3">
                                            <div className="investmentBanner"><img src="./assets/img/stacking/investment.png" alt="" />
                                                <span>Hight yields, best for large <br></br>investments</span>
                                                <button className='tryBtn'>Try Now</button>
                                            </div>
                                        </div>
                                    </> : <>
                                        <div className="col-12 d-flex justify-content-end my-2">
                                            <div className="textlight">Your balance: $ {userCurrencyBalance.totalBalance || userCurrencyBalance.totalBalance === 0 ? Number(userCurrencyBalance.totalBalance)?.toFixed(2) : '0.00'}</div>
                                            {/* <div className="textlight">Your balance: $ {converted.balance || converted.balance === 0 ? Number(converted.balance)?.toFixed(2) : '0.00'}</div> */}
                                        </div>
                                        <div className="col-12">
                                            <div className="row mx-0">
                                                <div className="col-md-10 col-9 px-0">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="subscriptionInput">
                                                                <div className="bitImg"><img src={selectedItem?.currency?.icon} alt="" /></div>
                                                                <input
                                                                    className={`form-control shadow-none ${subscriptionAmount > userCurrencyBalance.totalBalance ? 'error' : ''}`}
                                                                    type="text"
                                                                    id='subscriptionAmount'
                                                                    placeholder=''
                                                                    value={subscriptionAmount}
                                                                    onChange={(e) => {
                                                                        if (isNaN(Number(e.target.value))) return
                                                                        setSunscriptionAmount(e.target.value)
                                                                    }}
                                                                />
                                                                <label htmlFor="subscriptionAmount" className='subLabel'>Subscription</label>
                                                                <div className="inpAmount">
                                                                    {
                                                                        converted.amount ? `$ ${converted.amount}` : '$0.00'
                                                                    }
                                                                </div>
                                                                {
                                                                    subscriptionAmount?.length ?
                                                                        <div className="crossBtn" onClick={() => setSunscriptionAmount('')}>
                                                                            <svg className='' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M278.6 256l68.2-68.2c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-68.2-68.2c-6.2-6.2-16.4-6.2-22.6 0-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3l68.2 68.2-68.2 68.2c-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3 6.2 6.2 16.4 6.2 22.6 0l68.2-68.2 68.2 68.2c6.2 6.2 16.4 6.2 22.6 0 6.2-6.2 6.2-16.4 0-22.6L278.6 256z"></path></svg>
                                                                        </div>
                                                                        : ''
                                                                }
                                                            </div>
                                                            {
                                                                subscriptionAmount > userCurrencyBalance.totalBalance ? <div className="errorTxt">Not enough funds</div> : ''
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-2 col-3 pe-0">
                                                    <div onClick={() => setSunscriptionAmount(userCurrencyBalance.totalBalance)} className="max">Max</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-3"><div className="textlight"><span>Amount Limits</span></div></div>
                                        <div className="col-12 mt-3"><div className="textlight">Min. Stake per user :<span> {selectedPoolType === 'Fixed-Term' ? selectedFixedTerm?.minStake : selectedBoostedTerm?.minStake} {selectedItem?.currency?.code}</span>
                                        ~ $
                                                {
                                                    selectedPoolType === 'Fixed-Term' ? 
                                                    Number(selectedFixedTerm?.minStake) * Number(selectedItem?.rate): 
                                                    Number(selectedBoostedTerm?.minStake) * Number(selectedItem?.rate)
                                                } 
                                        </div></div>
                                        <div className="col-12 mt-3"><div className="textlight">Max. Stake per user : <span>{selectedPoolType === 'Fixed-Term' ? selectedFixedTerm?.maxStake : selectedBoostedTerm?.maxStake} {selectedItem?.currency?.code}</span> 
                                        ~  ${
                                                    selectedPoolType === 'Fixed-Term' ? 
                                                    Number(selectedFixedTerm?.maxStake) * Number(selectedItem?.rate): 
                                                    Number(selectedBoostedTerm?.maxStake) * Number(selectedItem?.rate)
                                                } 
                                         <img src="./assets/img/stacking/question.png" alt="" /></div></div>

                                        <div className="col-12 mt-3"><div className="textlight">Amount Quota : <span>{selectedPoolType === 'Fixed-Term' ? selectedFixedTerm?.amountQuota : selectedBoostedTerm?.amountQuota} {selectedItem?.currency?.code}</span> ~$
                                        {
                                                    selectedPoolType === 'Fixed-Term' ? 
                                                    Number(selectedFixedTerm?.amountQuota) * Number(selectedItem?.rate): 
                                                    Number(selectedBoostedTerm?.amountQuota) * Number(selectedItem?.rate)
                                                } 
                                        <img src="./assets/img/stacking/question.png" alt="" /></div></div>

                                        {/* <div className="col-12 d-flex justify-content-end">
                                            <label class="switch">
                                                <input type="checkbox" checked/>
                                                <span class="slider round"></span>
                                            </label>
                                        </div> */}
                                        <div className="col-12 mt-3">
                                            <div className="box2">
                                                <div className="row">
                                                    <div className="col-12 d-flex align-items-center justify-content-between">
                                                        <div className="textlight">
                                                            🔄  Auto-Subscribe
                                                            <span className='popularSpan ms-2'>Popular</span>
                                                        </div>
                                                        <div>
                                                            <label class="switch">
                                                                <input type="checkbox" />
                                                                <span class="slider round"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 pt-2">
                                                        <div className="textlight">Auto-Subscribe enables to resubscribe to the same fixed-term product upon expiry. If disabled, your assets will be automatically transferred to your balance upon expiry</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        {
                            selectedPoolType === 'Flexible' ? <>
                                <div className="col-lg-5 col-12 mt-4 mt-lg-0 ">
                                    <div className="row justify-content-between mx-0 h-100 flex-column">
                                        <div className="col-12 d-block d-lg-none">
                                            <Modal.Title className='modalTitle modalTitleLight text-white mb-2'>Summary</Modal.Title>
                                        </div>
                                        <div className="col-12">
                                            <div className="estBox d-flex justify-content-between align-items-center">
                                                <div className='textlight'>Est. APR</div>
                                                <div className='textlight textgreen'>{flexible?.apr}%</div>
                                            </div>
                                            <div className="textlight pt-2">Rewards Distribution:</div>
                                            <div className="textlight pb-2">Balance - <span>100%</span>, Calendar - <span> 0%</span></div>
                                        </div>
                                        <div className="col-12 d-inline-flex justify-content-end">
                                            <div className="libox">
                                                <ul className='m-0'>
                                                    <li className='textlight'>Rewards start accruing the day after your subscribe and will be distributed on a daily basis at 01:00 UTC+0.</li>
                                                    <li className='textlight'>
                                                             Rewards can be claimed in $DMBL and USDT.
                                                    </li>
                                                    <li className='textlight'>
                                                            Early redemption implies 5% penalty fee and loss of all interest earned within the position.
                                                    </li>
                                                    <li className='textlight'>
                                                            Wager requirements apply. Please, refer to the details on the page.
                                                    </li>
                                                    <li className='textlight'>
                                                    If wager requirements are not met, your rewards will be based on 5% APR.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> : <>
                                <div className="col-lg-5 col-12 d-flex flex-column gap-4">
                                    <div className="row mx-0 track">
                                        <div className="col-12 d-block d-lg-none">
                                            <Modal.Title className='modalTitle modalTitleLight text-white mb-2 '>Summary</Modal.Title>
                                        </div>
                                        <div className="col-auto"><div className="circle"><img src="./assets/img/stacking/circle.png" alt="" /></div></div>
                                        <div className="col">
                                            <div className='textlight'>Subscription Date <br />
                                                {/* 03/30/24, 05:29:38 PM */}
                                                {moment().format('L')}, {moment().format('LTS')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mx-0 track">
                                        <div className="col-auto"><div className="circle"><img src="./assets/img/stacking/circle.png" alt="" /></div></div>
                                        <div className="col">
                                            <div className='textlight'>Value Date <br />
                                                {/* 03/31/24, 12:00:00 AM */}
                                                {moment().add(1, 'days').format('L')}, 12:00:00 AM
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mx-0 track">
                                        <div className="col-auto"><div className="circle"><img src="./assets/img/stacking/circle.png" alt="" /></div></div>
                                        <div className="col">
                                            <div className='textlight'>Interest End Date <br />
                                                {
                                                    selectedPoolType === 'Fixed-Term' ?
                                                        moment().add(selectedFixedTerm?.name, 'days').format('L') :
                                                        moment().add(selectedBoostedTerm?.name, 'days').format('L')
                                                }
                                                , 12:00:00 AM
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mx-0 track">
                                        <div className="col-auto"><div className="circle"><img src="./assets/img/stacking/circle.png" alt="" /></div></div>
                                        <div className="col">
                                            <div className='textlight'>
                                                Next Subscription Date <br />
                                                {
                                                    selectedPoolType === 'Fixed-Term' ?
                                                        moment().add(selectedFixedTerm?.name + 1, 'days').format('L') :
                                                        moment().add(selectedBoostedTerm?.name + 1, 'days').format('L')
                                                }
                                                , 12:00:00 AM
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mx-0">
                                        <div className="col-12">
                                            <div className="estBox d-flex justify-content-between align-items-center">
                                                <div className='textlight'>Est. APR</div>
                                                <div className='textlight textgreen'>
                                                    {selectedPoolType === 'Fixed-Term' ? selectedFixedTerm?.apr : selectedPoolType === 'Boosted Fixed-Term' ? selectedBoostedTerm?.apr : ''}
                                                    %</div>
                                            </div>
                                        </div>
                                        <div className="col-12 py-2">
                                            <div className="textlight">Rewards Distribution:</div>
                                            <div className="textlight">Balance - <span>100%</span>, Calendar - <span> 0%</span></div>
                                        </div>
                                        <div className="col-12">
                                            <div className="libox">
                                                <ul className='m-0'>
                                                    <li className='textlight'>Rewards start accruing the day after your subscribe and will be distributed on a daily basis at 01:00 UTC+0.</li>
                                                    <li className='textlight'>
                                                    Rewards can be claimed in $DMBL and USDT.
                                                    </li>
                                                    <li className='textlight'>
                                                        Early redemption implies 5% penalty fee and loss of all interest earned within the position..
                                                    </li>
                                                    <li className='textlight'>
                                                    Wager requirements apply. Please, refer to the details on the page.
                                                    </li>
                                                    <li className='textlight'>
                                                    If wager requirements are not met, your rewards will be based on 5% APR.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        }

                    </div>
                </Modal.Body>
                <Modal.Footer className='modalFooter'>
                    <div className="row w-100">
                        <div className="col-12 mb-2 d-flex align-items-center">
                            <input
                                className='agreeCheckbox form-check-input'
                                type="checkbox"
                                id="agree"
                                name="agree"
                                checked={termsChecked}
                                onChange={(e) => setTermsChecked(e.target.checked)}
                            />
                            <div className="px-2">

                                <label className='textlight' for="agree"><span>I have read and agree to</span></label>
                                <Link to='/' className='termsLink ps-2'>Damble simple earn service agreement</Link>
                            </div>
                        </div>
                        <div className="col-12">
                            <button onClick={handleSubscribe} className='confirmBtn'>
                                {
                                    loading ? <Spinner size='sm'/> : 'Confirm'
                                }
                                
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* <Modal 
            className='firstCryptoModal fourthCryptoModal' 
            show={show} 
            onHide={handleClose} 
            centered 
            scrollable
            size="xl"
            aria-labelledby="example-modal-sizes-title-xl"
            >
                <Modal.Header className='modalHeader d-flex justify-content-between align-items-center'>
                    <div className="row w-100">
                        <div className="col-lg-8 col-12">
                            <Modal.Title className='modalTitle d-flex align-items-center'>SubscribeBTC <img className='ms-2' src="./assets/img/stacking/crypto.png" alt="" /></Modal.Title>
                        </div>
                        <div className="col-4 d-lg-block d-none">
                            <Modal.Title className='modalTitle modalTitleLight'>Summary</Modal.Title>
                        </div>
                    </div>
                    <div className="closeBTn cursor-pointer">
                        <svg className='size-6 text-white' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path></svg>
                    </div>
                </Modal.Header>
                <Modal.Body className='modalBody'>
                    <div className="row">
                        <div className="col-lg-8 col-12">
                            <div className="row mx-0">
                                <div className="col-12">
                                    <div className="box1">
                                        <div className="row">
                                            <div className="col-12"><div className='textlight'><span className=''>Flexible Fixed-Term</span></div></div>
                                            <div className="col-12 mt-2">
                                                <div className="row gap-1 mx-0">
                                                    <div className="col-auto px-0"><button className='flexBtns active'>Flexible</button></div>
                                                    <div className="col-auto px-0"><button className='flexBtns'>30</button></div>
                                                    <div className="col-auto px-0"><button className='flexBtns'>60</button></div>
                                                    <div className="col-auto px-0"><button className='flexBtns'>90</button></div>
                                                    <div className="col-auto px-0"><button className='flexBtns'>180</button></div>
                                                    <div className="col-auto px-0"><button className='flexBtns'>365</button></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-3">
                                    <div className="box1">
                                        <div className="row">
                                            <div className="col-12"><div className='textlight'><span className=''>Boosted Fixed-Term</span></div></div>
                                            <div className="col-12 mt-2">
                                                <div className="row gap-1 mx-0">
                                                    <div className="col-auto px-0"><button className='flexBtns'>30</button></div>
                                                    <div className="col-auto px-0"><button className='flexBtns'>60</button></div>
                                                    <div className="col-auto px-0"><button className='flexBtns'>90</button></div>
                                                    <div className="col-auto px-0"><button className='flexBtns'>180</button></div>
                                                    <div className="col-auto px-0"><button className='flexBtns'>365</button></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 d-flex justify-content-start my-2">
                                    <div className="textlight">Your balance: <img src="./assets/img/stacking/crypto.png" alt="" /> 0</div>
                                </div>
                                <div className="col-12">
                                    <div className="row triedBox mx-0">
                                        <div className="col-12 mb-2"><div className="textlight">Tiered APY <img src="./assets/img/stacking/question.png" alt="" /></div></div>
                                        <div className="col-12 d-flex justify-content-between borderBottom mb-2">
                                            <div><div className="textlight">Tier 1 $  0 ≤ $250</div></div>
                                            <div><div className="textlight">11%</div></div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-between borderBottom mb-2">
                                            <div><div className="textlight">Tier 1 $  0 ≤ $250 </div></div>
                                            <div><div className="textlight">8%</div></div>
                                        </div>
                                        <div className="col-12 d-flex justify-content-between">
                                            <div><div className="textlight">Tier 1 $  0 ≤ $250 </div></div>
                                            <div><div className="textlight">6%</div></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-3">
                                    <div className="investmentBanner"><img src="./assets/img/stacking/investment.png" alt="" />
                                        <span>Hight yields, best for large <br></br>investments</span>
                                        <button className='tryBtn'>Try Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 mt-4 mt-lg:0">
                            <div className="row justify-content-between mx-0 h-100">
                            <div className="col-12">
                                <Modal.Title className='modalTitle modalTitleLight text-white mb-2 d-block d-lg-none'>Summary</Modal.Title>
                            </div>
                                <div className="col-12">
                                    <div className="estBox d-flex justify-content-between align-items-center">
                                        <div className='textlight'>Est. APR</div>
                                        <div className='textlight textgreen'>20%</div>
                                    </div>
                                    <div className="textlight pt-2">Rewards Distribution:</div>
                                    <div className="textlight pb-2">Balance - <span>100%</span>, Calendar - <span> 0%</span></div>
                                </div>
                                <div className="col-12 d-inline-flex justify-content-end">
                                    <div className="libox">
                                        <ul className='m-0'>
                                            <li className='textlight'>Rewards start accruing the day after your subscribe and will be distributed on a daily basis at 01:00 UTC+0.</li>
                                            <li className='textlight'>
                                                Rewards can be claimed in DGW and USDT.
                                            </li>
                                            <li className='textlight'>
                                                Early redemption implies 5% penalty fee and loss of all interest earned within the position.
                                            </li>
                                            <li className='textlight'>
                                                Wager requirements apply. Please, refer to the details on the page.
                                            </li>
                                            <li className='textlight'>
                                                If wager requirements are not met, your rewards will be based on 5% APR.
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='modalFooter'>
                    <div className="row w-100">
                        <div className="col-12 mb-2">
                        <input className='agreeCheckbox' type="checkbox" id="agree" name="agree" value="checkbox"/>
                        <label className='textlight' for="agree"><span className='px-2'>I have read and agree to</span></label>
                        <Link to='' className='termsLink'>Damble simple earn service agreement</Link></div>
                        <div className="col-12"><button className='confirmBtn'>Confirm</button></div>
                    </div>
                </Modal.Footer>
            </Modal> */}
        </>
    )
}

export default SubscribeBTCModal