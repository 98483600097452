import React, { useState } from 'react'
import eye from './../../../../assets/images/eye.png'
import signupBtn from './../../../../assets/images/signupbtntxt.png'
import Accordion from 'react-bootstrap/Accordion';
import * as Yup from "yup";
import { errorToaster, succesToaster } from '../../../../utils/toaster';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../../config/firebase';
import { useAuth } from '../../../../hooks/useAuth';
import { useSelector } from 'react-redux';
import { USER_LANG } from '../../../../constants';
import { useAuthModal } from '../../../../hooks/useAuthModal';
import { Spinner } from 'react-bootstrap';
import { useSpinModal } from '../../../../hooks/useSpinModal';
import { useReferralModal } from '../../../../hooks/useReferralModal';
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next';
import { postAPI } from '../../../../services/apiInstance';
import ErrorMessage from '../../../common/errorMessage/ErrorMessage';

const validationSchema = Yup.object({
  password: Yup.string().required("This field is required"),
  email: Yup.string()
      .required("Email is required")
      .matches(
          /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
          "Invalid email"
      ),
});

const SignUpEmail = ({ checks, browserName, setChecks, bonus, click_id, referral,new_click_id }) => {
  const [isPasswordHidden, setIsPasswordHidden] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { setToken } = useAuth()
  const country = useSelector(state => state.country)
  const { authModalObject, setAuthModalObject } = useAuthModal()
  const { setSpinModalObject } = useSpinModal()
  const ipAddress = useSelector(state => state.ipAddress)
  const { isReferral, setIsReferral, isReferralInput, setIsReferralInput } = useReferralModal()
  const { t } = useTranslation()
  const authLabels = t("AuthLabels", { returnObjects: true }) //string has to be same as language json key 



  async function createUserWithEmailAndPasswordF(values) {
      if (!checks.terms) {
          errorToaster("User agreement is required");
          setIsLoading(false)
          return;
      }

      try {
          const userCredential = await createUserWithEmailAndPassword(
              auth,
              values.email,
              values.password,
          );
          const user = userCredential.user;
          // const lang = JSON.parse(localStorage.getItem(USER_LANG))
          const lang = localStorage.getItem(USER_LANG)
          const body = {
              email: userCredential._tokenResponse.email,
              idToken: userCredential._tokenResponse.idToken,
              localId: userCredential._tokenResponse.localId,
              refreshToken: userCredential._tokenResponse.refreshToken,
              password: values.password,
              terms: checks.terms,
              promotion: checks.promotions,
              // username: username,
              ipAddress: ipAddress ? ipAddress : "",
              country: country ? country : "",
              // language: lang?.symbol ? lang?.symbol : localStorage.getItem(USER_LANG),
              language: lang,
              refercode: values.referral ? values.referral : "",
              browser: browserName,
              bonus: !!bonus ? bonus.bonus : false,
              trackingToken: !!click_id ? click_id : null,
              clickid : !!new_click_id ? new_click_id : null,
              refercode: values.referral ? values.referral : "",
              isLoginWithEmail : true,
          };

          const res = await postAPI("user/register-user", body);
          if (res.data.success) {
              succesToaster(res.data.message);
              setToken(res?.data.data?.auth_token);
              setAuthModalObject(pre => ({ ...pre, isAuthOpen: false }))
            //   setSpinModalObject(prev => ({
            //       ...prev,
            //       isModalOpen: true
            //   }))
            //   resetForm()
            //   setIsReferral(true)
              window?.dataLayer?.push({
                  'event': 'GA4_event',
                  'event_name': 'game_sign_up'
              });
          } else {
              errorToaster(res?.data?.message);
          }
      } catch (error) {
          const errorCode = error.code;
          const errorMessage = error.message.substr(9, 30);
          if (error.message === 'Firebase: Error (auth/email-already-in-use).') {
              errorToaster('Email already exists');
          } else {
              errorToaster(errorMessage);
          }
      } finally {
          setIsLoading(false)
      }
  }
  return (
    <>
            <Formik
            initialValues={{
                email: "",
                password: "",
                referral: referral
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                setIsLoading(true)
                createUserWithEmailAndPasswordF(values)
            }}
        >
                      {({
                values,
                errors,
                touched,
                handleSubmit,
                handleChange,
                handleBlur,
            }) => (
              <form>

                                                    <div className="col-12 py-2">
                                                            <input 
                                                              className='commonInp' 
                                                              type="mail" 
                                                              name="email"
                                                              id="email"
                                                              value={values.email}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              placeholder='Email' />
                                                        </div>
                                                        <ErrorMessage>
                                                            {errors.email && touched.email && errors.email}
                                                        </ErrorMessage>
                                                        <div className="col-12 position-relative">
                                                            <input className='commonInp' 
                                                              type={`${isPasswordHidden ? "password" : "text"}`}
                                                              name="password"
                                                              id="password"
                                                              value={values.password}
                                                              onChange={handleChange}
                                                              onBlur={handleBlur}
                                                              placeholder={`${authLabels.Password}`}
                                                            />
                                                            <div className="eyeImg" onClick={()=>setIsPasswordHidden(!isPasswordHidden)}>
                                                                {
                                                                    isPasswordHidden ?
                                                                    <img src={eye} alt="" /> 
                                                                    : <svg fill="#B2B6C5" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" stroke-width="0"><path d="M432 448a15.92 15.92 0 0 1-11.31-4.69l-352-352a16 16 0 0 1 22.62-22.62l352 352A16 16 0 0 1 432 448zM248 315.85l-51.79-51.79a2 2 0 0 0-3.39 1.69 64.11 64.11 0 0 0 53.49 53.49 2 2 0 0 0 1.69-3.39zm16-119.7L315.87 248a2 2 0 0 0 3.4-1.69 64.13 64.13 0 0 0-53.55-53.55 2 2 0 0 0-1.72 3.39z"></path><path d="M491 273.36a32.2 32.2 0 0 0-.1-34.76c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.68 96a226.54 226.54 0 0 0-71.82 11.79 4 4 0 0 0-1.56 6.63l47.24 47.24a4 4 0 0 0 3.82 1.05 96 96 0 0 1 116 116 4 4 0 0 0 1.05 3.81l67.95 68a4 4 0 0 0 5.4.24 343.81 343.81 0 0 0 67.24-77.4zM256 352a96 96 0 0 1-93.3-118.63 4 4 0 0 0-1.05-3.81l-66.84-66.87a4 4 0 0 0-5.41-.23c-24.39 20.81-47 46.13-67.67 75.72a31.92 31.92 0 0 0-.64 35.54c26.41 41.33 60.39 76.14 98.28 100.65C162.06 402 207.92 416 255.68 416a238.22 238.22 0 0 0 72.64-11.55 4 4 0 0 0 1.61-6.64l-47.47-47.46a4 4 0 0 0-3.81-1.05A96 96 0 0 1 256 352z"></path></svg>
                                                                }
                                                            </div>
                                                        </div>
                                                        <ErrorMessage>
                                                            {errors.password && touched.password && errors.password}
                                                        </ErrorMessage>
                                                        <div className="col-12 pt-2">
                                                        <Accordion defaultActiveKey="0">
                                                            <Accordion.Item className='accordionItem' eventKey="0">
                                                                <Accordion.Header className='accordionHeader'>{authLabels.Enter_Referral} <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg></Accordion.Header>
                                                                <Accordion.Body className='accordionBody'>
                                                                    <input 
                                                                      className='commonInp'     
                                                                      type='text'       
                                                                      name="referral"
                                                                      id="referral"
                                                                      value={values?.referral}
                                                                      onChange={handleChange}
                                                                      placeholder={`${authLabels.Enter_Referral}`} />
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </Accordion>
                                                        </div>
                                                        <div className="col-12 py-3">
                                                            <div class="form-check">
                                                                <input 
                                                                  class="form-check-input checkboxInp" 
                                                                  type="checkbox" 
                                                                  value="" 
                                                                  id="flexCheckDefault" 
                                                                  checked={checks.terms}
                                                                  onChange={(e) => {
                                                                      setChecks(pre => ({
                                                                          ...pre,
                                                                          terms: e.target.checked,
                                                                      }))
                                                                  }}
                                                                  />
                                                                <label class="form-check-label checkboxLbl" for="flexCheckDefault">
                                                                    {authLabels.I_agree_to_the} <span>{authLabels.User_Agreement}</span> {authLabels.confirm_I_am}
                                                                    {/* {authLabels.I_agree} */}
                                                                </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input checkboxInp" 
                                                                type="checkbox" value="" 
                                                                id="flexCheckDefault1"
                                                                checked={checks.promotions}
                                                                onChange={(e) => {
                                                                    setChecks(pre => ({
                                                                        ...pre,
                                                                        promotions: e.target.checked,
                                                                    }))
                                                                }}/>
                                                                <label class="form-check-label checkboxLbl" for="flexCheckDefault1">
                                                                    {authLabels.I_agree_to_receive_marketing}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <button
                                                            
                                                              onClick={(e) => {
                                                                e.preventDefault()
                                                                handleSubmit(values)
                                                            }}
                                                              className='signUpBtn'>
                                                                {isLoading ? <Spinner size="sm" variant="light"/> : <img src={signupBtn} alt="" />}
                                                              </button>
                                                        </div>
              </form>

            )}
                                                        </Formik>
    </>
  )
}

export default SignUpEmail