import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import bonus1 from './../../assets/images/bonus1.png'
import bonus2 from './../../assets/images/bonus2.png'
import bonus3 from './../../assets/images/bonus3.png'
import bonus4 from './../../assets/images/bonus4.png'
import bonus5 from './../../assets/images/bonus5.png'
import bonus6 from './../../assets/images/bonus6.png'
import bonus7 from './../../assets/images/bonus7.png'
import bonus8 from './../../assets/images/bonus8.png'
import bonus9 from './../../assets/images/bonus9.png'
import bonus10 from './../../assets/images/bonus10.png'
import green from './../../assets/images/grennbtn.png'
import nodata from './../../assets/images/Isolation_Mode.png'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { getAPIAuth } from '../../services/apiInstance';
import { useAuth } from '../../hooks/useAuth';
import moment from 'moment';
import { useSelector } from 'react-redux';

const bonusTypes = [
  {name: 'All Bonuses', value: 'lockedBonus'},
  {name: 'Hunts', value: 'Hunts'},
  {name: 'Lucky Spin', value: 'Lucky Spin'},
  {name: '1k Daily Race', value: 'lockedBonus'},
  {name: 'Deposit Bonus', value: 'lockedBonus'},
  {name: 'Supercharge', value: 'Supercharge'},
  {name: 'Weekly Cashback', value: 'weekly'},
  {name: 'Monthly Cashback', value: 'monthly'},
  {name: 'Sports Weekly Bonus', value: 'Sports Weekly Bonus'},
  {name: 'Level up Bonus', value: 'lockedBonus'}
]
const BonusDetails = ({ isOpen, setIsOpen }) => {
  const [selectedBonus, setSelectedBonus] = useState(bonusTypes?.[0])
  const [historyData, setHistoryData] = useState([])
  const userBonusDetail = useSelector(state => state.userBonusDetail)
  const userDetails = useSelector(state=>state.userDetails)

  const {token} = useAuth()

  const getBonusHistory = async ()=> {
    // const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws'
    try {
        const res = await getAPIAuth(`user/get-bonus-history?type=${selectedBonus?.value}`, token)
        if(res?.data?.status) {
            setHistoryData(res?.data?.data)
        } else {
            setHistoryData({})
        }
    } catch (error) {
        setHistoryData({})
    }
}


useEffect(()=>{
  if(token) {
      // console.log('hello', token)
      getBonusHistory()
    }
},[token, selectedBonus])

  return (
    <>
      <Modal show={isOpen} centered scrollable size={'lg'} className='modalSection'
        onHide={() => setIsOpen(false)}
      >
        <Modal.Header closeButton
        >
          <Modal.Title>Bonus Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalBody'>
          <div className='modalHeading'>
            Bonus Categories
          </div>
          <div className='category px-4 py-2'>
            <div className='d-flex row row-cols-md-5 row-cols-1 items-stretch'>
              <div className='d-flex bonusmcard h-auto flex-md-column justify-content-between'>
                <div className='d-flex align-items-center'>
                  <div className='icon-1'>
                    <img src={bonus1} alt="" className='h-100 w-100 object-cover' />
                  </div>
                  <div className='bonus-text'> Hunts</div>

                </div>
                <div className='Bonusmoney'> $ 0.000</div>
              </div>
              <div className='d-flex bonusmcard h-auto flex-md-column justify-content-between'>
                <div className='d-flex '>
                  <div className='icon-1'>
                    <img src={bonus2} alt="" className='h-100 w-100 object-cover' />
                  </div>
                  <div className='bonus-text'> Lucky Spin</div>

                </div>
                <div className='Bonusmoney'> $ 0.000</div>
              </div>
              <div className='d-flex bonusmcard h-auto flex-md-column justify-content-between'>
                <div className='d-flex '>
                  <div className='icon-1'>
                    <img src={bonus3} alt="" className='h-100 w-100 object-cover' />
                  </div>
                  <div className='bonus-text'> 1K Daily Race</div>

                </div>
                <div className='Bonusmoney'> $ 0.000</div>
              </div>
              <div className='d-flex bonusmcard h-auto flex-md-column justify-content-between'>
                <div className='d-flex '>
                  <div className='icon-1'>
                    <img src={bonus4} alt="" className='h-100 w-100 object-cover' />
                  </div>
                  <div className='bonus-text'> Supercharge</div>

                </div>
                <div className='Bonusmoney'> $ 0.000</div>
              </div>
              <div className='d-flex bonusmcard h-auto flex-md-column justify-content-between'>
                <div className='d-flex '>
                  <div className='icon-1'>
                    <img src={bonus5} alt="" className='h-100 w-100 object-cover' />
                  </div>
                  <div className='bonus-text'> Weekly Cashback</div>

                </div>
                <div className='Bonusmoney'> $ 0.000</div>
              </div>
              <div className='d-flex bonusmcard h-auto flex-md-column justify-content-between'>
                <div className='d-flex '>
                  <div className='icon-1'>
                    <img src={bonus6} alt="" className='h-100 w-100 object-cover' />
                  </div>
                  <div className='bonus-text'> Monthly Cashback</div>

                </div>
                <div className='Bonusmoney'> $ 0.000</div>
              </div>
              <div className='d-flex bonusmcard h-auto flex-md-column justify-content-between'>
                <div className='d-flex '>
                  <div className='icon-1'>
                    <img src={bonus7} alt="" className='h-100 w-100 object-cover' />
                  </div>
                  <div className='bonus-text'> Monthly Cashback</div>

                </div>
                <div className='Bonusmoney'> $ 0.000</div>
              </div>
              <div className='d-flex bonusmcard h-auto flex-md-column justify-content-between'>
                <div className='d-flex '>
                  <div className='icon-1'>
                    <img src={bonus8} alt="" className='h-100 w-100 object-cover' />
                  </div>
                  <div className='bonus-text'> Level-up Bonus</div>

                </div>
                <div className='Bonusmoney'> $ 0.000</div>
              </div>
              <div className='d-flex bonusmcard h-auto flex-md-column justify-content-between'>
                <div className='d-flex '>
                  <div className='icon-1'>
                    <img src={bonus9} alt="" className='h-100 w-100 object-cover' />
                  </div>
                  <div className='bonus-text'> Free Spin</div>

                </div>
                <div className='Bonusmoney'> $ 0.000</div>
              </div>
              <div className='d-flex bonusmcard h-auto flex-md-column justify-content-between'>
                <div className='d-flex '>
                  <div className='icon-1'>
                    <img src={bonus10} alt="" className='h-100 w-100 object-cover' />
                  </div>
                  <div className='bonus-text'> Deposit Bonus</div>

                </div>
                <div className='Bonusmoney'> $ {Number(userDetails?.totalbonus).toFixed(2)}</div>
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-between align-items-center my-3">
              <div className='modalHeading '>
                Bonus Transactions
              </div>
              <div>
                <DropdownButton className='BounseDropdown' id="dropdown-basic-button" title={selectedBonus?.name}>
                  
                  {
                    bonusTypes?.map((item)=> (
                      <Dropdown.Item as='button' 
                        className={`drop-item ${selectedBonus?.name === item?.name ? 'active' : ''}`}
                        onClick={()=>setSelectedBonus(item)}
                        >
                        <div className='d-flex justify-content-between align-items-center '>
                          <div>{item?.name}</div>
                          <div className='greenbtn'>
                            <img src={green} alt="" className='h-100 w-100' />
                          </div>
                        </div>
                      </Dropdown.Item>
                    ))
                  }
                </DropdownButton>
              </div>

            </div>
          </div>
          <div className='modal-text'>
            Only the latest 60 days transactions will be displayed below.
          </div>
          <div className='data'>
            <div className="table-responsive">
              <table className='amounthistory'>
                <thead>
                  <tr>
                    <th>Bonus Type</th>
                    <th>Amount Claimed</th>
                    <th> Current Amount</th>
                  </tr>
                </thead>
                {
                  historyData?.length ? 
                  historyData?.map((item)=>(
                    <tr>
                      <td className='dateTd'>
                        <div>{moment(item?.createdAt).format('h:mm:ss A')}</div>
                        <div>{moment(item?.createdAt).format('L')}</div>
                      </td>
                      <td className='typeTd'>{item?.type}</td>
                      <td className='amountTd'>
                        <div className="d-flex align-items-center justify-content-end">
                          <img src="assets/img/usdt.png" className='me-2' alt="" />
                          +{item?.bonus_amt}$
                        </div>
                      </td>
                    </tr>
                  )) : <>
                  <tr>
                    <td colSpan={3} className='noDataTd'>
                      <div className='d-flex flex-column justify-content-center align-items-center nodataimg'>
                        <img src={nodata} alt="" className='w-100 h-100' />
                        <div className="modal-text my-3 text-nowrap">
                          Oops! There is no data yet!
                        </div>
                      </div>
                    </td>
                  </tr>
                  </>
                }
              </table>
            </div>
          </div>

        </Modal.Body>

      </Modal>
    </>
  )
}

export default BonusDetails