import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import sport from "./../../../../assets/images/home/sports.png";
import slots from "./../../../../assets/images/home/slots.png";
import crash from "./../../../../assets/images/home/crash.png";
import casino from "./../../../../assets/images/home/casino.png";
import { Pagination, Navigation } from "swiper/modules";
import { getAPI, getAPIAuth } from "../../../../services/apiInstance";
import { Link, Route, useNavigate, useSearchParams } from "react-router-dom";
import { GAME_OPTION_TABS, ROUTE_CONST } from "../../../../constants";
import { useGames } from "../../../../hooks/useGame";
import SkeletonLoader from "../../../../components/skeletonLoader/SkeletonLoader";
import { useAuth } from "../../../../hooks/useAuth";
import { Spinner } from "react-bootstrap";

const SportsBoxSec = ({ setSelectedUpperCategory, selectedUpperCategory }) => {
  const { token } = useAuth();
  const [bannerData, setBannerData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dynamicCategories, setDynamicCategories] = useState([]);
  const [staticCategories, setStaticCategories] = useState(null);
  const [statsData, setStatsData] = useState(null);
  const navigate = useNavigate();
  const { setGameObject } = useGames();
  const [query, setQuery] = useSearchParams();

  const getBannerImage = async () => {
    setIsLoading(true);
    try {
      const res = await getAPI(
        `banner/getBannerByFilter?type=homepage&viewType=desktop`
      );
      if (res?.data?.success) {
        setBannerData(res?.data?.data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const getStatesCategory = async () => {
    try {
      const res = await getAPI("user/getHomePageCategory");
      console.log(res, "thisihhfluokplo");
      if (res.data.success) {
        setDynamicCategories(res.data.dynamicCategory);
        setStaticCategories(res.data.staticCategory);
        setStatsData(res.data.statsData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStatesCategory();
  }, []);

  console.log({ dynamicCategories });

  return (
    <>
      <div className="col-12">
        {dynamicCategories.length ? (
          <div className="row SportsBoxSec">
            <div
              onClick={() => setSelectedUpperCategory({name:"sports"})}
              className=" cursor-pointer col-lg-3 col-6 SportsBoxSecInnner"
            >
              <div
                className={` card   ${
                  selectedUpperCategory?.name === "sports" ? "active" : ""
                }  sport-bx-1`}
              >
                <div className="row gap-2 mx-0 flex align-items-center">
                  <div className="col-auto px-0">
                    <div className="sport">
                      <img src={sport} alt="" />
                    </div>
                  </div>
                  <div className="col px-0">
                    <h5 className="mb-0">Sport</h5>
                    <p className="mb-0">
                      Total Games : {staticCategories?.totalSportsGames}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {dynamicCategories.slice(0, 3).map((cat) => {
              return (
                <div
                  onClick={() => setSelectedUpperCategory({name:cat.category_name,id:cat._id})}
                  key={cat._id}
                  className="cursor-pointer col-lg-3 col-6 SportsBoxSecInnner"
                >
                  <div
                    className={`  ${
                      selectedUpperCategory?.name === cat.category_name
                        ? "active"
                        : ""
                    }  card sport-bx-1`}
                  >
                    <div className="row gap-2 mx-0 flex align-items-center">
                      <div className="col-auto px-0">
                        <div className="sport">
                          <img src={cat.image} alt="" />
                        </div>
                      </div>
                      <div className="col px-0">
                        <h5 className="mb-0">{cat.category_name}</h5>
                        <p className="mb-0">Total Games : {cat.totalGames}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};

export default SportsBoxSec;
