import React, { useEffect, useState } from "react";
import Esport from "./../../../../assets/images/home/e-sport.png";
import TableGame from "./../../../../assets/images/home/table-games.png";
import SpeedGame from "./../../../../assets/images/home/speed-game.png";
import BigWin from "./../../../../assets/images/home/big-win.png";
import { getAPI } from "../../../../services/apiInstance";
import { set } from "date-fns";

const NewSportsBoxSec = ({
  specialCategory,
  selecedSpecialCat,
  setSelectedSpecialcat,
  setSelectedSpecialCatName
}) => {
  const [bannerData, setBannerData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getBannerImage = async () => {
    setIsLoading(true);
    try {
      const res = await getAPI(
        `banner/getBannerByFilter?type=homepage&viewType=desktop`
      );
      if (res?.data?.success) {
        setBannerData(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching banner data", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBannerImage();
  }, []);

  return (
    <div className=" col-12">
      <div className="row SportsBoxSec pt-1 pb-4">
        {specialCategory.map((item, index) => (
          <div key={index} className="col-lg-3 col-6">
            <div
              onClick={() => {
                setSelectedSpecialCatName(item.category_name)
                setSelectedSpecialcat(item._id)}}
              className={`  cursor-pointer  card sport-bx-1  ${
                item._id === selecedSpecialCat ? "active" : ""
              }`}
            >
              <div className="row gap-2 mx-0 flex align-items-center">
                <div className="col-auto px-0">
                  <div className="sport">
                    <img src={item.image} alt={item.category_name} />
                  </div>
                </div>
                <div className="col px-0">
                  <h5 className="mb-0">{item.category_name}</h5>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewSportsBoxSec;
