import React, { useState } from "react";

export const ReferralModalContext = React.createContext(null)

const ReferralModalContextProvider = ({ children }) => {
    const [isReferral, setIsReferral] = useState(false)
    const [isReferralInput, setIsReferralInput] = useState(false)
    return (<ReferralModalContext.Provider
        value={{
            isReferral,
            setIsReferral,
            isReferralInput,
            setIsReferralInput
        }}
    >
        {children}
    </ReferralModalContext.Provider>);
};

export default ReferralModalContextProvider;
