import React from "react";

const BonusIcon = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7498 3.52696H7.28018V5.77771H12.5V4.27721C12.5 3.86617 12.1648 3.52696 11.7498 3.52696ZM1.25025 3.52696C0.835218 3.52696 0.5 3.86218 0.5 4.27721V5.77771H5.75175V3.52696H1.25025ZM1.25025 6.52796V11.7797C1.25025 12.1947 1.58547 12.53 2.0005 12.53H5.75175V6.52796H1.25025ZM10.9995 12.53C11.4145 12.53 11.7498 12.1947 11.7498 11.7797V6.52796H7.28018V12.53H10.9995ZM6.502 2.77272C6.50599 2.77272 6.50599 2.77671 6.50599 2.7807H9.50698C10.5446 2.7807 11.0075 2.26191 11.0075 1.62739C11.0075 0.992873 10.5406 0.474083 9.50698 0.474083C8.53725 0.474083 7.77503 0.921041 7.25624 1.43983C6.79332 1.14851 6.20668 1.14452 5.73977 1.42387C5.22098 0.909069 4.46275 0.470093 3.501 0.470093C2.46342 0.470093 2.0005 0.988882 2.0005 1.6234C2.0005 2.25792 2.46342 2.77671 3.501 2.77671H6.502V2.77272ZM10.2493 1.65533C10.2493 1.9666 9.8422 2.03045 9.499 2.03045H7.79498C7.79099 2.01848 7.78301 2.0105 7.77902 1.99853C8.16212 1.62739 8.73678 1.2802 9.499 1.2802C9.8422 1.2802 10.2493 1.34405 10.2493 1.65533ZM2.75075 1.65533C2.75075 1.34405 3.1578 1.2802 3.501 1.2802C4.24726 1.2802 4.81393 1.61542 5.19305 1.97059C5.18108 1.99055 5.1731 2.0105 5.16112 2.03045H3.501C3.1578 2.03045 2.75075 1.96261 2.75075 1.65533Z"
        fill="#2F93DD"
      />
    </svg>
  );
};

export default BonusIcon;
