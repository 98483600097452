import React, { useState, createContext } from "react";

export const LoginSuccessModalContext = createContext(null);

const LoginSuccessContextProvider = ({ children }) => {
  const [loginModalObject, setLoginModalObject] = useState({
    isModalOpen: false,
  });
  return (
    <LoginSuccessModalContext.Provider
      value={{ loginModalObject, setLoginModalObject }}
    >
      {children}
    </LoginSuccessModalContext.Provider>
  );
};

export default LoginSuccessContextProvider;
