import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useAuth } from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import { USER_LANG } from "../../constants";
import i18n from "i18next";
import { useSearchParams } from "react-router-dom";
import { getAPI, getAPIAuth } from "../../services/apiInstance";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

const Sports = () => {
  const { token } = useAuth();
  const userDetails = useSelector((state) => state.userDetails);
  const lang = localStorage.getItem(USER_LANG);
  const [language, setLanguage] = useState(lang);
  const [sports, setSports] = useState([]);
  const [gameUrl, setGameUrl] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [sessionId, setSessionId] = useState("");
  const userBalance = useSelector((state) => state.userBalance);
  const [query, setQuery] = useSearchParams();
  const [errorMsg, setErrorMsg] = useState({
    show: false,
    msg: "",
  });
  const [showIframe, setShowIframe] = useState(false);

  useEffect(() => {
    if (token) {
      if (userDetails?.id && userBalance.setCurrencyData?.code) {
        // for logged in users
        getSports();
      }
    } else {
      // for logged out user--->>
      const betSDKHandler = async () => {
        try {
          const bettingFrame = await window.BetSdk.init({
            mainFrameUrl: "http://52.6.45.46/sports", // for live
            //   mainFrameUrl: "https://bet.ga-stage.work/api/v1/sportsbooks/launch/702d9a23aa6f53a701d7f2ae93524aaf",
            // for development,
            host : 'https://gameosys-mu83s8.turbostars.gg',
            //   host: 'https://stage.sportbook.work/',
            cid: "damble",
            lang: "en",
            token: "",
            // token: "5bb47a3f2a2b65907927f9d4111216d9",
            containerId: "iFrame-container",
            width: "100%",
            height: "100%",
            parent: "https://damble.io", // for live
            customStyles: false,
            sub_partner_id: false,
            allowParentUrlUpdate: true,
          });
        } catch (error) {
          console.log("bet error", error);
        }
      };
      betSDKHandler();
    }
  }, [
    token,
    language,
    userDetails?.id,
    userBalance.setCurrencyData?.code,
    query.get("setIframePath"),
  ]);

  //   const selfValidateResult = async (task_id) => {
  //       try {
  //           const res = await getAPIAuth(`sport/selfValidateResult?task_id=${task_id}`)
  //           if (res.data.success) {
  //           }
  //       } catch (error) {
  //       }
  //   }

  //   const selfValidate = async (sessionId) => {
  //       try {
  //           const res = await getAPIAuth(`sport/selfValidate?session_id=${sessionId}`)
  //           if (res.data.status) {
  //               selfValidateResult(res.data?.data?.task_id)
  //           }
  //       } catch (error) {
  //       }
  //   }

  const getSports = async () => {
    try {
      // get uuid to generate url--->>>>
      const res = await getAPI("sport/getSportbooks");
      if (res.data.status) {
        setSports(res.data.data);
        //generate url --------------------------------->>
        const response = await getAPIAuth(
          `sport/urlGenerate?uuid=${res.data.data?.[0]?.uuid}`,
          token,
          "fromsports"
        );

        if (response.data.status) {
          setShowIframe(true);
          setErrorMsg((prev) => ({
            ...prev,
            show: false,
            msg: "",
          }));
          setSessionId(response.data.data?.session_id);
          setGameUrl(response.data.data?.data);
          //   selfValidate(response.data.data?.session_id)

          // try {
          // call url to get cid and token --------------------------------->>
          // const resp = await axios.get(response.data.data?.data?.url);
          // const resp = await getAPIAuth()

          // ----extract token value
          const tokenRegex = /token:\s*'([^']+)'/;
          const tokenMatch = tokenRegex.exec(response.data?.data?.gethtml);
          const tokenValue = tokenMatch && tokenMatch[1]; // Extracted token value
          // ----extract cid value
          const cidRegex = /cid:\s*'([^']+)'/;
          const cidMatch = cidRegex.exec(response.data?.data?.gethtml);
          const cidValue = cidMatch && cidMatch[1]; // Extracted cid value
          console.log("tokenMatch", { cidValue, tokenValue });

          // -------------initialize betSDK-------------->>>
          try {
            const bettingFrame = await window.BetSdk.init({
              //   mainFrameUrl: "https://bet.ga-stage.work/api/v1/sportsbooks/launch/702d9a23aa6f53a701d7f2ae93524aaf", // for live
              mainFrameUrl: "http://52.6.45.46/sports", // for live
              //   host: 'https://stage.sportbook.work/',
              host : 'https://gameosys-mu83s8.turbostars.gg',

              cid: cidValue,
              lang: "en",
              token: tokenValue,
              containerId: "iFrame-container",
              width: "100%",
              height: "100%",
              parent: "https://damble.io", // for live
              customStyles: false,
              sub_partner_id: false,
              allowParentUrlUpdate: true,
            });
          } catch (error) {
            console.log("bet error", error);
          }
          // } catch (error) {
          // console.error('Error in getting token and cid:', error);
          // }
        } else {
          setShowIframe(false);
          setErrorMsg((prev) => ({
            ...prev,
            show: true,
            msg: response?.data?.message,
          }));
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const handleLanguageChange = () => {
      // Handle language change here
      const currentLanguage = i18n.language;
      setLanguage(currentLanguage);
    };

    // Add an event listener for language changes
    i18n.on("languageChanged", handleLanguageChange);

    // Clean up the event listener on component unmount
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, []); // Empty dependency array, so this effect runs only once on mount
  return (
    <>
      <div
        className="homepage pt-0 sportsPageMainInner h-100"
        // style={{ height: "calc(100dvh - 58px)" }}
      >
        {token ? (
          showIframe ? (
            <>
              {/* <div
                                        id="iFrame-container"
                                        className="align-items-center mt-0 sports-div h-100 w-100"
                                    >
                                        {
                                            errorMsg?.show ? 
                                                <div className="text-white">{errorMsg?.msg}</div>
                                            :
                                                <div className="loader-div aaa w-100 h-100  d-flex justify-content-center align-items-center">
                                                    <Spinner animation="border" variant="light" />
                                                </div>
                                        }
                                    </div> */}
              {RenderIframe(errorMsg)}
              {/* <div className="text-white">iframe</div> */}
            </>
          ) : (
            <>
              <div className="align-items-center mt-0 sports-div h-100 w-100">
                {errorMsg?.show ? (
                  <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
                    <div className="text-white h5 text-center">
                      {errorMsg?.msg}
                    </div>
                    {/* <FiatErrorModal/> */}
                  </div>
                ) : (
                    //                       <div className="loader-div aaa w-100 h-100  d-flex justify-content-center align-items-center">
                    //                           <Spinner animation="border" variant="light" />
                    // <div className="text-white">loading 1</div>

                    //                       </div>
                  <LoadingScreen />
                )}
              </div>
            </>
          )
        ) : (
          <>
            <div
              id="iFrame-container"
              className="align-items-center mt-0 sports-div h-100 w-100"
            >
              <div className="loader-div w-100 h-100  d-flex justify-content-center align-items-center">
                                        <Spinner animation="border" variant="light" />
                          {/* <div className="text-white">loading 2</div> */}
                                        
                                    </div>
              <LoadingScreen />
            </div>
          </>
        )}

        {/* <div className="loader-div w-100 d-flex justify-content-center align-items-center">
                            <Spinner animation="border" variant="light" size="sm" />
                        </div> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default Sports;

const RenderIframe = ({ errorMsg }) => {
  return (
    <>
      <div
        id="iFrame-container"
        className="align-items-center mt-0 sports-div h-100 w-100"
      >
        {errorMsg?.show ? (
          <>
            <div className="d-flex flex-column">
              <div className="text-white">{errorMsg?.msg}</div>
              {/* <FiatErrorModal/> */}
            </div>
          </>
        ) : (
            // <div className="loader-div aaa w-100 h-100  d-flex justify-content-center align-items-center">
            //     <Spinner animation="border" variant="light" />
            //     <div className="text-white">loading 3</div>
            // </div>
            //     <LoadingScreen timer={70}/>
          <></>
        )}
      </div>
    </>
  );
};
