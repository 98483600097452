import React, { useEffect, useRef, useState } from 'react';
import searchIcon from './../../assets/images/searchIcon.png';
import { Link, useNavigate } from 'react-router-dom';
import { postAPIAuth } from '../../services/apiInstance';
import { ROUTE_CONST } from '../../constants';

const SearchBar = () => {
    const [searchValue, setSearchValue] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const debounceRef = useRef(null);
    const navigate = useNavigate();
    const getGames = async ()=> {
        setLoading(true)
        try {
          if(searchValue?.length > 2) {
            const params = {
              name : searchValue
            }
            const result = await postAPIAuth(`crypto/get-games`, params)
            setData(result?.data?.data)
          } else {
            setData([])
          }
        } catch (error) {
            setData([])
        } finally {
          setLoading(false)
        }
      };
      useEffect(()=> {
        if (debounceRef?.current) {
          clearTimeout(debounceRef?.current)
        }
        debounceRef.current = setTimeout(() => {
          getGames()
        }, 300)
        return () => clearTimeout(debounceRef?.current)
      }, [searchValue])//eslint-disable-line

      console.log({data},"gggthfjju")
    return (
        <>
            <div className="row pb-2 pt-5 sectionSpacing">
                <div className="col-12 ">
                    <div className='position-relative w-100' >
                    <input className='searchComp' type="text" placeholder='Search' value={searchValue} onChange={(e)=>setSearchValue(e.target.value)}  />
                    <div className="serachImg"><img src={searchIcon} alt="" /></div>
                                {
                        searchValue?.length > 0 ?
                        <div className="searchResultBox">
                        {
                            searchValue?.length > 2 ?
                            !loading ?
                            data?.length > 0 ?
                            data?.map((item)=> (
                            <Link to={`${ROUTE_CONST.CASINO}/${item?.uuid}`} className="searchResultCard d-flex align-items-center text-decoration-none"
                  //               onClick={(e) => {
                  //               e.stopPropagation()
                  //               // alert('clicked')
                  //                 navigate(`${ROUTE_CONST.CASINO}/${item?.uuid}`)
                  // }}
                  >
                      <div className="searchResultImg">
                          <img src={item?.image} className='h-100 w-100 object-fit-cover' alt="" />
                      </div>
                      <div>
                          <div className="searchGameName">{item?.name}</div>
                          <div className="searchProviderName">{item?.provider}</div>
                          <div className="searchGameCat d-flex align-items-center gap-2 mt-1">
                              <div className="searchCatPill">{item?.type}</div>
                          </div>
                      </div>
                  </Link>
                ))
                : <div className='text-white text-center'>No data</div>
                : <div className='text-white text-center'>Loading...</div>
                : <div className='text-white text-center'>Search requires at least 3 characters</div>
              }
            </div>
            : ''
          }
                    </div>
                </div>
            </div>
        </>
    )
}
export default SearchBar;
