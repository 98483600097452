import React, { useEffect, useRef, useState } from 'react'
import providers from './../../assets/images/providers.png'
import searchIcon from './../../assets/images/search.png'
import rightarrowprovider from './../../assets/images/right-arrowprovider.png'
import spribeportfolio from './../../assets/images/spribe-portfolio-narrow.png'
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '../../utils/useMediaQuery'
import { postAPIAuth } from '../../services/apiInstance'
import { ROUTE_CONST } from '../../constants'
import { Spinner } from 'react-bootstrap'
import GameCard from '../../components/GameCard/GameCard'
import NoData from '../../components/noData/NoData'


const ProviderGameList = () => {
    const [selectedProvider, setSelectedProvider] = useState([])
    const [gameList, setGameList] = useState([])
    const [paging, setPaging] = useState(1)
    const [totalPage, setTotalPage] = useState(0)
    const [loader, setLoader] = useState(true)
    const [isLoadMore, setIsLoadMore] = useState(false)
    const [noData, setNoData] = useState(false)
    const userDetails = useSelector(state => state?.userDetails)
    const [search, setSearch] = useState('')
    const [sortBy, setSortBy] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [data, setData] = useState([])

    // const isMobile = useMediaQuery({ maxWidth: MOBILE_SCREEN_WIDTH })
    // const isSmScreen = useMediaQuery({ maxWidth: SM_SCREEN_WIDTH })
    // const isMdScreen = useMediaQuery({ maxWidth: MD_SCREEN_WIDTH })
    // const isLgScreen = useMediaQuery({ maxWidth: LG_SCREEN_WIDTH })
    // const isXlScreen = useMediaQuery({ maxWidth: XL_SCREEN_WIDTH })
    const debounceRef = useRef(null)
    const {providerName} = useParams()
    const navigate = useNavigate()
    const {token} = useAuth()
  
    const getGameList = async () => {
      setLoader(true)
      let params = {
          page: 1,
          userId: userDetails?.id,
        //   is_mobile: isMobile ? "true" : "false",
        //   limit : isMobile ? 9 : isSmScreen ? 12 : isMdScreen ? 10 : isLgScreen ? 10 : isXlScreen ? 12 : 14,
          // is_mobile: "true"
      }
  
      // name: search, sortBy: sortBy, 
      if (search) params.name = search;
      if (sortBy) params.sortBy = sortBy;
      if (providerName) params.providerNamesArray = [providerName]
  
      const result = await postAPIAuth(`crypto/get-games`, params, token)
      if (result?.data?.success) {
          // if (page) setPaging(prev => prev + 1)
          // if (!totalPage) setTotalPage(result.data.allData)
          // if (page) setGameList(prev => ([...prev, ...result.data.data]))
          // setGameList(prev => ([...prev, ...result.data.data]))
          // else setGameList(result.data.data)
          setGameList(result.data.data)
          setData(result?.data?.data)

      } else {
          setGameList([])
          setData([])
      }
      setLoader(false)
      setNoData(false)
  }
  
  
  useEffect(()=> {
    if(token) {
      getGameList()
    }
  }, [providerName, token])
  
  const loadMore = async (page) => {
      setIsLoadMore(true)
      let params = {
          page: page ? page : 1,
          userId: userDetails.id,
          name : searchValue
        //   is_mobile: isMobile ? "true" : "false",
        //   limit : isMobile ? 9 : isSmScreen ? 12 : isMdScreen ? 10 : isLgScreen ? 10 : isXlScreen ? 12 : 14,
      }
      // name: search, sortBy: sortBy, 
      if (search) params.name = search;
      if (sortBy) params.sortBy = sortBy;    
      if (providerName) params.providerNamesArray = [providerName]
  
  
      const result = await postAPIAuth(`crypto/get-games`, params)
      if (result?.data?.success) {
          if (page) setPaging(prev => prev + 1)
          if (!totalPage) setTotalPage(result.data.allData)
          if (page) setGameList(prev => ([...prev, ...result.data.data]))
          else setGameList(result.data.data)
          setNoData(false)
  
      } else {
          setNoData(true)
          // setGameList([])
      }
      setIsLoadMore(false)
  }
  
  
  // useEffect(() => {
  //     if (debounceRef?.current) {
  //         clearTimeout(debounceRef?.current)
  //     }
  //     debounceRef.current = setTimeout(() => {
  //         getGameList()
  //     }, 400)
  //     return () => clearTimeout(debounceRef?.current)
  // }, [search, sortBy, selectedProvider])
  
  // useEffect(() => {
  //     setSearch(searchValue)
  // }, [searchValue])
  
  useEffect(()=> {
    if (debounceRef?.current) {
      clearTimeout(debounceRef?.current)
    }
    debounceRef.current = setTimeout(() => {
        getGameList()
    }, 300)
    return () => clearTimeout(debounceRef?.current)
  }, [search])
    return (
        <>
        <div className='provider providerList my-3'>
            <div className='row align-items-center'>
                <div className='col'>
                    <div className='navbtn commonProBtn gap-md-3 gap-2'>
                        <div className='btn-img1 my-auto' >
                            <img src={providers} alt="" className='w-100 my-auto h-100 object-fit-contain'/>
                        </div>
                        <div className='promo cursor-pointer' onClick={()=>navigate(ROUTE_CONST.PROVIDERS)}>Providers</div>
                        <div className='arrowBtn'>
                            <img src={rightarrowprovider} alt="rightarrowprovider" className='w-100 h-100'/>
                        </div>
                        <div className='det'>{providerName}</div>
                    </div>
                </div>
                <div className='col-auto ps-0'>
                    <div className='imgNav'>
                        <img src={gameList?.[0]?.result?.image}alt="spribeportfolio" />
                    </div>
                </div>
            </div>
            <div className="row pb-2 pt-2 pt-md-3 sectionSpacing gap-sm-0 gap-3">
                {/* <div className="col-sm col-12 position-relative">
                    <input className='searchComp' type="text" placeholder='Search' />
                    <div className="serachImg"><img src={searchIcon} alt="" /></div>
                </div> */}
                 <div className="col-sm col-12 position-relative">
          <input type="text" 
            placeholder='Search your game' 
            value={search}
            onChange={(e)=>setSearch(e.target.value)}
            className='searchComp'/>
          {/* <div className="searchIcon"> */}
          <div className="serachImg"><img src={searchIcon} alt="" /></div>
          {/* </div> */}
          {
            searchValue?.length > 0 ?
            <div className="searchResultBox">
              {
                searchValue?.length > 2 ? 
                // !loading ? 
                data?.length > 0 ? 
                data?.map((item)=> (
                  <div className="searchResultCard d-flex align-items-center"
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate(`${ROUTE_CONST.CASINO}/${item?.uuid}`)
                  }}>
                      <div className="searchResultImg">
                          <img src={item?.image} className='h-100 w-100 object-fit-cover' alt="" />
                      </div>
                      <div>
                          <div className="searchGameName">{item?.name}</div>
                          <div className="searchProviderName">{item?.provider}</div>
                          <div className="searchGameCat d-flex align-items-center gap-2 mt-1">
                              <div className="searchCatPill">{item?.type}</div>
                          </div>
                      </div>
                  </div>
                ))
                : <div className='text-white text-center'>No data</div>
                // : <div className='text-white text-center'>Loading...</div>
                : <div className='text-white text-center'>Search requires at least 3 characters</div> 
              }
            </div>
            : ''
          }
      </div>
                {/* <div className='col-sm-auto col-12'>
                    <div>
                    <Dropdown className='drodwonProvider'>
                        <Dropdown.Toggle className='dropBtn' variant="success" id="dropdown-basic">
                            Dropdown Button
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='dropMenu'>
                            <Dropdown.Item className='dropItem' href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item className='dropItem' href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item className='dropItem' href="#/action-3">Something else</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    </div>
                </div> */}
            </div>
            <div className='row row-cols-3 row-cols-sm-4 row-cols-xl-5 g-2 g-md-3 gameCardGrid' >
            {
            // !isLoading ?
                gameList?.length > 0 ?
                    gameList?.map((item) => {
                        return (
                            <div
                                key={item?._id}
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                            >
                                <GameCard data={item} />
                            </div>
                        )
                    })
                    : <NoData/>
                // :
                // <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                //     <Spinner animation="border" variant="light" size="sm" />
                // </div>
            }
        </div>
            <div className='row'>
                    {
                              gameList?.length > 0 ?
                              <div className="col-12">
                                  <div className="d-flex align-items-center justify-content-center loadmoremain">
                                      {
                                          !loader ? (
                                              <span
  
                                                  onClick={() => {
                                                      if (noData) return
                                                      if (gameList.length === 0) {
                                                          setSearch('')
                                                          setSortBy('')
                                                          setSelectedProvider([])
                                                      } else {
                                                          loadMore(paging + 1)
                                                      }
                                                  }}
                                                  className="text-decoration-none loadmorebtn rounded-2 cursor-pointer"
                                              >
                                                  {
                                                      isLoadMore ? (
                                                          <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                                              <Spinner animation="border" variant="light" size="sm" />
                                                          </div>
                                                      ) : (
                                                          noData ? (
                                                            <div className='my-2'>No Data Available</div>
                                                          ) : (
                                                              // gameList.length === 0 ? (
                                                              //     allGamesLabels.REMOVE_FILTERS
                                                              // ) : (
                                                              //     allGamesLabels.LOAD_MORE
                                                              // )
                                                            <div className='gamePageLoadMoreBtn d-flex align-items-center justify-content-center border-0 mt-3'>LOAD MORE</div>
  
                                                          )
                                                      )
                                                  }
                                              </span>
                                          ) : (
                                              <></>
                                          )
                                      }
                                  </div>
                              </div>
                              : ''
                            }
                
            </div>
        </div>
        </>
    )
}

export default ProviderGameList