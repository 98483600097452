import React from 'react'

const SkeletonLoader = ({type, multiple}) => {
  if(type === 'heroBanner') {
      return (
        <>
          <div className="skeleton heroSkeletonLoader"></div>
        </>
      )
  } else if(type === 'spinWheel') {
    return (
      <>
        <div className="skeleton spinWheelSkeletonLoader withoutLoader">
          <img src="./assets/img/spinWheelLoader.png" height={330} width={330}  alt="spin-wheel" />
        </div>
      </>
    )
  } else if(type === 'sportsCard') {
    return (
      <>
        <div className="skeleton sportsCardSkeletonLoader"></div>
      </>
    )
  } else if(type === 'provider') {
    return (
      <>
        <div className="skeleton providerCardSkeletonLoader"></div>
      </>
    )
  } 
  else if(type === 'gameCategory') {
    return (
      <>
        <div className={`container-fluid p-0 overflow-hidden ${multiple ? 'mb-4' : ''}`}>
          <div className="row mx-0 g-1">
            {
              multiple ? <>
                <div className="col">
                  <div className="skeleton titleSkeletonLoader"></div>
                </div>
                <div className="col-auto">
                  <div className="skeleton seeAllBtnSkeletonLoader"></div>
                </div>
              </> : ''
            }
            <div className={`col-12 pt-1 px-0 ${multiple ? 'mt-3' : ''}`}>
              <div className="row row-cols-3 row-cols-md-5 row-cols-lg-6 row-cols-xxl-7 g-0 mx-0 flex-nowrap skeletonGameRow">
                { [1,1,1,1,1,1,1,1,1]?.map(item=>(
                  <div className="col">
                    <div className="skeleton gameCardSkeletonLoader"></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  else if(type === 'casinoCatLoaderrrr') {
    return (
      <>
        <div className="skeleton casinoCatLoaderrrr"></div>
      </>
    )
  }
  {
    return
  }
}

export default SkeletonLoader