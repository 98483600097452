import React from "react";

const ProvablyFairIcon = () => {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.53964 9.8002C6.36772 9.62831 6.16696 9.50267 5.95289 9.42234C6.42311 8.79178 6.34782 7.83522 5.77839 7.28704L5.7637 7.27238C5.27774 6.78644 4.56237 6.66655 3.96303 6.9124C3.88569 6.65168 3.74434 6.41277 3.54666 6.21509L3.53177 6.20022C2.91581 5.55828 1.80701 5.55834 1.19103 6.20043L1.1495 6.24195L1.06329 6.15574C-0.343041 4.74941 -0.343041 2.46111 1.06332 1.05473C2.46967 -0.351631 4.75797 -0.351602 6.16436 1.05473L7.00547 1.89587L5.40957 3.44636C4.69815 4.10615 4.68835 5.32083 5.38935 5.99236C6.05427 6.68534 7.25059 6.68531 7.91545 5.99236L8.46562 5.44219C8.48289 5.42495 8.51116 5.42498 8.52842 5.44219L11.8252 8.7389C11.8259 8.73961 11.8266 8.74034 11.8273 8.74104L11.9443 8.85804C12.1622 9.06707 12.1621 9.44315 11.9443 9.65219C11.7254 9.87105 11.3692 9.87108 11.1502 9.65216C11.144 9.64589 11.1373 9.64042 11.1308 9.63457C11.1189 9.62196 11.1073 9.60923 11.095 9.59691L10.163 8.66489C9.96867 8.47058 9.65355 8.47058 9.45923 8.66489C9.26489 8.85921 9.26489 9.17433 9.45923 9.36864L10.3912 10.3007C10.6394 10.5387 10.6394 10.9672 10.3912 11.2053C10.1531 11.4534 9.72475 11.4534 9.48662 11.2053L8.55953 10.2782C8.36521 10.0839 8.05009 10.0839 7.85578 10.2782C7.66143 10.4725 7.66143 10.7876 7.85578 10.9819L8.7779 11.904C9.03107 12.1531 9.03429 12.5625 8.78261 12.8139C8.54449 13.062 8.11612 13.062 7.87802 12.8139L6.83919 11.7751C7.18759 11.1531 7.06948 10.3136 6.55431 9.8149L6.53964 9.8002Z"
        fill="#2F93DD"
      />
      <path
        d="M13.9964 1.16897C15.3222 2.49477 15.3237 4.65102 14.0011 5.97884L12.1876 7.69384L9.23217 4.73841C8.84521 4.33509 8.14883 4.33512 7.76187 4.73841L7.2117 5.28858C6.91727 5.59544 6.38753 5.59541 6.0931 5.28858C5.94198 5.13746 5.85972 4.93647 5.86147 4.72275C5.86323 4.50903 5.9488 4.30948 6.10238 4.16085L9.17665 1.17404C10.503 -0.157988 12.6674 -0.160388 13.9964 1.16897Z"
        fill="#2F93DD"
      />
      <path
        d="M2.64754 10.4179L2.63285 10.4032C2.37682 10.1575 2.37679 9.71545 2.63285 9.46978L3.54663 8.556C3.54681 8.55582 3.54698 8.55562 3.54716 8.55544L4.12648 7.9761C4.3888 7.71289 4.82022 7.72047 5.07458 7.99079C5.33062 8.23646 5.33065 8.67856 5.07458 8.92423L3.58093 10.4179C3.32364 10.6752 2.90489 10.6752 2.64754 10.4179Z"
        fill="#2F93DD"
      />
      <path
        d="M0.995715 8.766L0.980966 8.75125C0.723703 8.49387 0.723732 8.07522 0.981054 7.81787L1.89483 6.90409C2.14051 6.64805 2.58258 6.64802 2.82825 6.90409L2.84315 6.91895C3.09894 7.16439 3.09894 7.60658 2.84303 7.85211L1.92916 8.766C1.67178 9.02338 1.25304 9.02332 0.995715 8.766Z"
        fill="#2F93DD"
      />
      <path
        d="M5.25819 12.0445C5.00087 12.3018 4.58209 12.3018 4.32477 12.0445L4.31011 12.0298C4.05276 11.7724 4.05276 11.3537 4.31011 11.0963L4.90248 10.5039C5.16478 10.2407 5.59622 10.2483 5.85059 10.5186C6.10662 10.7643 6.10665 11.2064 5.85059 11.452L5.25819 12.0445Z"
        fill="#2F93DD"
      />
    </svg>
  );
};

export default ProvablyFairIcon;
