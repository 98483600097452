import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useSpinModal } from '../../hooks/useSpinModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGames } from '../../hooks/useGame';
import { GAME_OPTION_TABS, ROUTE_CONST } from '../../constants';
import { useSelector } from 'react-redux';
import { useAuth } from '../../hooks/useAuth';
import { getAPIAuth } from '../../services/apiInstance';
import logo from './../../assets/images/logo.jpeg'
import coin from './../../assets/images/coin2.png'
import shine from './../../assets/images/shine.png'

const FreeSpinMsgModal = () => {
  const {spinModalObject, setSpinModalObject} = useSpinModal()
  const {setGameObject} = useGames()
  const {token} = useAuth()
  const userDetails = useSelector((state) => state.userDetails);
  const navigate = useNavigate()
  const [query, setQuery] = useSearchParams()


  const handleClose = ()=> {
    setSpinModalObject(prev=>({
      ...prev,
      isModalOpen: false
    }))
  }

  const handlePlayGames = ()=> {
    handleClose()
    setGameObject((prev)=> ({
      selectedTab : GAME_OPTION_TABS?.FREE_SPIN
    }))
    // navigate(ROUTES_CONST.GAME_PAGE)
    setQuery({ q: "freeSpin" })
    navigate(`${ROUTE_CONST.CASINO}?q=${encodeURIComponent('freeSpin')}`)
  }


  const changeFreeSpinStatus = async ()=> {
    if(spinModalObject?.isModalOpen) {
      try {
        const res = await getAPIAuth('user/updateFreeSpinStatus?flag=true', token)
      } catch (error) {
        
      }
    }
  }

  useEffect(()=> {
    changeFreeSpinStatus()
  }, [spinModalObject?.isModalOpen])


  useEffect(()=> {
    if(userDetails?.freeSpinStatus === false) {
      // setSpinModalObject(prev=>({
      //   ...prev,
      //   isModalOpen: true
      // }))
    }

  }, [userDetails, token])


  return (
    <>
      <Modal className='spinWinnerModal overflow-hidden' onHide={handleClose} show={spinModalObject?.isModalOpen} centered>
        <Modal.Body className='d-flex align-items-center justify-content-center'>
            <div className="winnerInnerBox">
              <div className="row">
              <div className='shine-bg position-relative'>
                <div className='tittle-1'>Congratulations!</div>
                <div className='tittle-2'>You’ve got free money to bet on
                our games!</div>
                <div className='money'>
                5 USDT
                </div>
                <button className='claimClick'>Claim rewards</button>
               <div className='coinImage'>
               <img src={coin} alt="claim reward" className='w-100 h-100' />
               </div>
               <div className='shine'>
                <img src={shine} alt="" className='w-100 h-100'/>
               </div>
              </div>
              </div>
            </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default FreeSpinMsgModal