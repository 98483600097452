import { useContext } from "react"
// import { AuthBtContext } from "../../../../damble-fe-main/Damble-ReactApp/src/context/authBtContext"
// import { AuthBtContext } from "../../../../damble-fe-main/Damble-ReactApp/src/context/authBtContext"
import { AuthBtContext } from "../context/authBtContext"


export const useBtAuth = () => {
    const { btToken, setBtToken } = useContext(AuthBtContext)
    return {
        btToken,
        setBtToken
    }
}