import React from "react";
import profilePreference from "./../../assets/images/profile-2/profile-preference.svg";
import Flag from "./../../assets/images/profile-2/england-flag.svg";
import EditIcon from "./../../assets/images/profile-2/edit-icon.svg";

const AccountPrefernece = ({ selectedTab, setSelectedTab }) => {
  return (
    <div className="account-prefernece mb-4">
      <div
        className={`drop_mb_tab cursor-pointer ${
          selectedTab === "preference" ? "active" : ""
        } d-flex align-items-center gap-3 my-4`}
        onClick={() => setSelectedTab("preference")}
      >
        <div className="dropIcon">
          <img src={profilePreference} alt="" className="img-fluid" />
        </div>
        <div class="DropTxt">Account Preferences</div>
      </div>
      {selectedTab === "preference" && (
        <div className="profile_box">
          <div className="profile_account_info bg_main_color  p-4">
            <div className="profile-main-heading">
              <h6 className="mb-3">Account Preferences</h6>
            </div>
            <div className="mt-4 d-flex align-items-center justify-content-between">
              <div className="">
                <h6 className="account-list mb-0">View in currency</h6>
              </div>
              <div>
                <div class="dropdown">
                  <button
                    class="dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={Flag} alt="" className="img-fluid pe-1" /> USA{" "}
                    <img src={EditIcon} alt="" className="img-fluid ps-2" />
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="#">
                        <img src={Flag} alt="" className="img-fluid pe-1" /> USA
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <img src={Flag} alt="" className="img-fluid pe-1" /> USA
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        <img src={Flag} alt="" className="img-fluid pe-1" /> USA
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="mt-4 d-flex align-items-center justify-content-between">
              <div className="">
                <h6 className="account-list mb-0">Change Language</h6>
              </div>
              <div>
                <div class="dropdown">
                  <button
                    class="dropdown-toggle py-2"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    English{" "}
                    <img src={EditIcon} alt="" className="img-fluid ps-2" />
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="#">
                        English
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        English
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="#">
                        English
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="mt-4 d-flex align-items-center justify-content-between">
              <div className="">
                <h6 className="account-list mb-0">
                  Show full name of currency in Crypto list
                </h6>
              </div>
              <div>
                <div class="form-check form-switch">
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="profile_account_info bg_main_color mt-4  p-4">
            <div className="profile-main-heading">
              <h6 className="mb-3">Privacy Preferences</h6>
            </div>
            <div className="mt-4 d-flex align-items-center justify-content-between">
              <div className="">
                <h6 className="account-list mb-0">
                  Hide my gaming data on profile
                </h6>
              </div>
              <div>
                <div class="form-check form-switch">
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-4 d-flex align-items-center justify-content-between">
              <div className="">
                <h6 className="account-list mb-0">
                  Hide my username from public lists
                </h6>
              </div>
              <div>
                <div class="form-check form-switch">
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-4 d-flex align-items-center justify-content-between">
              <div className="">
                <h6 className="account-list mb-0">Refuse tip from strangers</h6>
              </div>
              <div>
                <div class="form-check form-switch">
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-4 d-flex align-items-center justify-content-between">
              <div className="">
                <h6 className="account-list mb-0">Max bet alert</h6>
              </div>
              <div>
                <div class="form-check form-switch">
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="profile_account_info bg_main_color mt-4  p-4">
            <div className="profile-main-heading">
              <h6 className="mb-3">Email Notifications</h6>
            </div>
            <div className="mt-4 d-flex align-items-center justify-content-between">
              <div className="">
                <h6 className="account-list mb-0">
                  Receive deposit successful email
                </h6>
              </div>
              <div>
                <div class="form-check form-switch">
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-4 d-flex align-items-center justify-content-between">
              <div className="">
                <h6 className="account-list mb-0">
                  Receive withdraw successful email
                </h6>
              </div>
              <div>
                <div class="form-check form-switch">
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="profile_account_info bg_main_color mt-4  p-4">
            <div className="profile-main-heading">
              <h6 className="mb-3">Email Notifications</h6>
            </div>
            <div className="mt-4 d-flex align-items-center justify-content-between">
              <div className="">
                <h6 className="account-list mb-0">
                  Receive marketing promotions by Email
                </h6>
              </div>
              <div>
                <div class="form-check form-switch">
                  <label class="switch">
                    <input type="checkbox" />
                    <span class="slider"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountPrefernece;
