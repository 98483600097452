import React, { useEffect, useState } from 'react'
import ProviderSlider from '../../../components/ProviderSlider/ProviderSlider'
import BetTable from '../../../components/betTable/BetTable'
import CasinoSlider from '../../../components/casinoSlider/CasinoSlider';
import { getAPI } from '../../../services/apiInstance';
import { useMediaQuery } from '../../../utils/useMediaQuery';
import { RESPONSIVE_WIDTH } from '../../../constants';
import CasinoPageSlider1 from './CasinoPageSlider1';

const Lobby = () => {
  const [allCats, setAllCats] = useState([]);
  const [ allCatsLoading,setAllCatsLoading ] =useState(false);
  const isSMScreen = useMediaQuery(RESPONSIVE_WIDTH.SM_SCREEN);

  const getAllGames = async () => {
    setAllCatsLoading(true);
    try {
      const res = await getAPI(
        `games/get-games-by-cat?is_mobile=${
          isSMScreen ? "true" : "false"
        }&gameLimit=10`
      );
      if (res?.data?.success) {
        setAllCats(res?.data?.data);
      } else {
        setAllCats([]);
      }
    } catch (error) {
      setAllCats([]);
    }finally{
      setAllCatsLoading(false);
    }
  };

  useEffect(() => {
    getAllGames();
  }, []); 
  return (
    <>
      {allCats.length 
        ? allCats.map((item,index) => (
            <CasinoPageSlider1 data={item}/>
          ))
        : null}
      <ProviderSlider/>
      <BetTable/>
    </>
  )
}

export default Lobby