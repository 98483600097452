import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import EMail from "./../../../../assets/images/login-signup/mail.svg";
import Password from "./../../../../assets/images/login-signup/password.svg";
import Tag from "./../../../../assets/images/login-signup/tag.svg";
import Flag from "./../../../../assets/images/login-signup/flag.svg";
import { COUNTRIES, USER_LANG } from "../../../../constants";
import { useSelector } from "react-redux";
import { postAPI } from "../../../../services/apiInstance";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { succesToaster, errorToaster } from "../../../../utils/toaster";
import { useAuth } from "../../../../hooks/useAuth";
import { useAuthModal } from "../../../../hooks/useAuthModal";
import { auth } from "../../../../config/firebase";
import { useSpinModal } from "../../../../hooks/useSpinModal";

const SignupForm = ({ showTab }) => {
  const [countries, setContries] = useState(COUNTRIES);
  const [showPassowrd, setShowPassword] = useState(false);
  const { spinModalObject, setSpinModalObject } = useSpinModal();
  const ipAddress = useSelector((state) => state.ipAddress);
  const { setToken } = useAuth();
  const country = useSelector((state) => state.country);
  const { setAuthModalObject } = useAuthModal();

  const signUpHandler = async (values, resetForm, setSubmitting) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.emailOrPhone,
        values.password
      );
      // const lang = JSON.parse(localStorage.getItem(USER_LANG))
      const lang = localStorage.getItem(USER_LANG);
      const body = {
        email: userCredential._tokenResponse.email,
        idToken: userCredential._tokenResponse.idToken,
        localId: userCredential._tokenResponse.localId,
        refreshToken: userCredential._tokenResponse.refreshToken,
        password: values.password,
        terms: values.terms,
        // promotion: checks.promotions,
        // username: username,
        ipAddress: ipAddress ? ipAddress : "",
        country: country ? country : "",
        // language: lang?.symbol ? lang?.symbol : localStorage.getItem(USER_LANG),
        language: lang,
        refercode: values.referralCode ? values.referralCode : "",
        browser: "",
        // bonus: !!bonus ? bonus.bonus : false,
        // trackingToken: !!click_id ? click_id : null,
        // clickid: !!new_click_id ? new_click_id : null,
        // refercode: values.referral ? values.referral : "",
        isLoginWithEmail: true,
      };

      const res = await postAPI("user/register-user", body);
      if (res.data.success) {
        succesToaster(res.data.message);
        setToken(res?.data.data?.auth_token);
        setAuthModalObject((pre) => ({ ...pre, isAuthOpen: false }));
        resetForm();
        // setSpinModalObject((prev) => ({
        //   ...prev,
        //   isModalOpen: true,
        // }));
        //   resetForm()
        //   setIsReferral(true)
        window?.dataLayer?.push({
          event: "GA4_event",
          event_name: "game_sign_up",
        });
      } else {
        errorToaster(res?.data?.message);
      }
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message.substr(9, 30);
      if (error.message === "Firebase: Error (auth/email-already-in-use).") {
        errorToaster("Email already exists");
      } else {
        errorToaster(errorMessage);
      }
    } finally {
      setSubmitting(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      emailOrPhone: "",
      password: "",
      country: "",
      referralCode: "",
      terms: false,
    },
    validationSchema: Yup.object({
      emailOrPhone: Yup.string().required("Email or phone number is required"),
      password: Yup.string().required("Password is required"),
      country: Yup.object().required("Country is required"),
      referralCode: Yup.string(),
      terms: Yup.boolean().oneOf(
        [true],
        "You must accept the terms and conditions"
      ),
    }),
    onSubmit: (values) => {
      signUpHandler(values, formik.resetForm, formik.setSubmitting);
    },
  });

  function filterCountries(searchTerm) {
    const filterCountry = COUNTRIES.filter((country) =>
      country.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setContries(filterCountry);
  }

  return (
    <div
      className={`tab-pane fade ${showTab === "signUp" ? "show active" : ""}`}
      id="pills-Signup"
      role="tabpanel"
      aria-labelledby="pills-Signup-tab"
      tabindex="0"
    >
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div
            className={`input-box   ${
              formik.touched.emailOrPhone && formik.errors.emailOrPhone
                ? ""
                : "mb-3"
            } `}
          >
            <img src={EMail} alt="" />
            <input
              type="text"
              placeholder="Enter Your Email/Phone Number"
              {...formik.getFieldProps("emailOrPhone")}
            />
          </div>
          {formik.touched.emailOrPhone && formik.errors.emailOrPhone ? (
            <div className="  mb-3 text-danger">
              {formik.errors.emailOrPhone}
            </div>
          ) : null}
          <div
            className={`input-box   ${
              formik.touched.password && formik.errors.password ? "" : "mb-3"
            } `}
          >
            <img src={Password} alt="" />
            <input
              type={showPassowrd ? "text" : "password"}
              placeholder="Enter Your Password"
              {...formik.getFieldProps("password")}
            />

            <i
              onClick={() => setShowPassword((prv) => !prv)}
              className={`fa-solid  cursor-pointer   ${
                showPassowrd ? "fa-eye" : "fa-eye-slash"
              }   text-white`}
            ></i>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className=" mb-3 text-danger">{formik.errors.password}</div>
          ) : null}
          <div
            className={`input-box-2   ${
              formik.touched.country && formik.errors.country ? "" : "mb-3"
            } `}
          >
            <div className="dropdown position-relative">
              <img className="position-absolute" src={Flag} alt="" />
              <button
                className="w-100 border-0 ps-4"
                type="button"
                id="customSelect"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Choose country of residence
              </button>
              <div className="selected-item ps-4">
                {formik.values.country.code}
              </div>
              <div
                className="dropdown-menu w-100"
                aria-labelledby="customSelect"
              >
                <div className="dropdown-search">
                  <i className="fa-solid fa-magnifying-glass position-absolute"></i>
                  <input
                    type="text"
                    className="form-control position-relative"
                    placeholder="Type your option..."
                    // {...formik.getFieldProps("country")}
                    onChange={(e) => filterCountries(e.target.value)}
                  />
                </div>
                <div className="listItems-scroll">
                  {countries.map((country, index) => {
                    return (
                      <div
                        onClick={() => formik.setFieldValue("country", country)}
                        key={index}
                      >
                        <p className=" cursor-pointer dropdown-item">
                          {country.name}
                        </p>
                      </div>
                    );
                  })}
                  {/* Add more country options here */}
                </div>
              </div>
              <i className="fa-solid fa-angle-down arrow-icon position-absolute"></i>
            </div>
          </div>
          {formik.touched.country && formik.errors.country ? (
            <div className=" mb-3 text-danger">{formik.errors.country}</div>
          ) : null}
          <div className="input-box-2 mb-3 p-2">
            <img src={Tag} alt="" />
            <input
              type="text"
              placeholder="Enter referral/promo code"
              {...formik.getFieldProps("referralCode")}
            />
          </div>
        </div>
        <div>
          <div className="form-check mt-4">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckChecked"
              {...formik.getFieldProps("terms")}
            />
            <label
              className="form-check-label confirm-text"
              htmlFor="flexCheckChecked"
            >
              I confirm that I am 18 years old and I have read the
              <span>
                {" "}
                <a href="" className="forgot-password">
                  {" "}
                   Terms of service{" "}
                </a>
              </span>
            </label>
          </div>
          {formik.touched.terms && formik.errors.terms ? (
            <div className="text-danger">{formik.errors.terms}</div>
          ) : null}
          <div className="mt-3">
            <button
              disabled={formik.isSubmitting}
              className="create-account"
              type="submit"
            >
              Create Account
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignupForm;
