import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useMediaQuery } from '../../../utils/useMediaQuery';
import { getAPIAuth } from '../../../services/apiInstance';
import GameCard from '../../../components/GameCard/GameCard';
import { Spinner } from 'react-bootstrap';
import { RESPONSIVE_WIDTH } from '../../../constants';
import NoData from '../../../components/noData/NoData';

const Recent = () => {
    const [gameList, setGameList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const userDetails = useSelector(state => state.userDetails)
    const isMobile = useMediaQuery(RESPONSIVE_WIDTH.MD_SCREEN)
    // const { t } = useTranslation()
    // const recentGames = t("recentGames", { returnObjects: true })
    // const commonText = t('commonText', { returnObjects: true })

    const recentGame = async () => {
        if (userDetails?.id) {
            setIsLoading(true);
            try {
                const res = await getAPIAuth(`games/recent-games?userId=${userDetails?.id}&is_mobile=${isMobile ? "true" : "false"}`);
                if (res?.data?.success) {
                    setGameList(res.data?.data ? res.data?.data : []);
                }
            } catch (error) {
                //   errorToaster(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    };
    useEffect(() => {
        recentGame();
    }, [userDetails?.id]);//eslint-disable-line
    return (
        <>
            <div className='row row-cols-3 row-cols-sm-5 row-cols-xl-6 g-2 g-md-3 gameCardGrid sectionSpacing' >
                {!isLoading ?
                    gameList?.length > 0 ?
                        gameList?.map((item) => {
                            return (
                                <div
                                    key={item?._id}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                    }}
                                >
                                    <GameCard data={item} />
                                </div>
                            )
                        })
                        : 
                            <NoData/>
                    :
                    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                        <Spinner animation="border" variant="light" size="sm" />
                    </div>
                }
            </div>
        </>
    )
}

export default Recent