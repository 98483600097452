import React, { useState ,useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import { useAuthModal } from '../hooks/useAuthModal'
import { AUTH_MODAL_TABS, ROUTE_CONST } from '../constants'
import AppLayout from '../Layout/AppLayout'
import Home from '../pages/home/Home'


const Protected = (props) => {
  const {Component} = props
  const [isLoggedIn, setLoggedIn] = useState(false)
  const { token } = useAuth();
  const { setAuthModalObject } = useAuthModal();
  const navigate = useNavigate()

  useEffect(()=> {
    if(!token) {
      navigate(ROUTE_CONST.HOME_PAGE)
      setAuthModalObject((pre) => ({
        selectedTab: AUTH_MODAL_TABS.LOG_IN,
        isAuthOpen: true,
        }))
    }else {
      setAuthModalObject((pre) => ({
        isAuthOpen: false,
        }))
      setLoggedIn(true)
    }
  },[token])
  return (
    isLoggedIn ? <AppLayout Component={Component}/> 
    : <AppLayout Component={Home}/>
  )
}

export default Protected


// const PageNotFound = ()=> {
//   return (
//     <>
//       <div className="row py-5 my-5">
//         <div className="col-12 text-uppercase h3 text-center py-5 my-5">page not found</div>
//       </div>
//     </>
//   )
// }