import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import banner1 from './../../../assets/images/casino-banner1.png'
import banner2 from './../../../assets/images/casino-banner2.png'
import banner3 from './../../../assets/images/casino-banner3.png'
import { getAPI } from '../../../services/apiInstance';
import SkeletonLoader from '../../../components/skeletonLoader/SkeletonLoader';
import { useNavigate } from 'react-router-dom';

const banners = [
  {id: '123', image: banner1},
  {id: '1234', image: banner2},
  {id: '1235', image: banner3},
  {id: '123', image: banner1},
  {id: '1234', image: banner2},
  {id: '1235', image: banner3},
]
const BonusSlider = () => {
  const [bannerData, setBannerData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

    const getBannerImage = async () => {
      setIsLoading(true)
        try {
            const res = await getAPI(`banner/getBannerByFilter?type=homepage&viewType=mobile`)
            if (res?.data?.success) {
                setBannerData(res?.data?.data)
            }
        } catch (error) {

        } finally {
          setIsLoading(false)
        }
    }

    useEffect(() => {
        getBannerImage()
    }, [])

  return (
    <>
      <div className="bonusSlider mt-2">
        <Swiper pagination={{clickable: true}} modules={[Pagination]} 
          spaceBetween={20}     
          breakpoints={{
            576: {
              slidesPerView: 1.5,
            },
            768: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
            },
          }} 
          className="mySwiper">
            {
              // !isLoading ? bannerData?.length ? 
              // bannerData?.map((item,index)=> (
              //     <SwiperSlide className='cursor-pointer' key={index} onClick={()=>item?.link ? window.open(item?.link, '_blank') :""}>
              //       <div className="bonusSliderImage">
              //         <img src={item?.image} alt="bonus banner" className='w-100 h-100 object-fit-cover' />
              //       </div>
              //     </SwiperSlide>
              //   ))
              // : '' : <SwiperSlide>
              // <SkeletonLoader type={'heroBanner'}/>
              // </SwiperSlide>
            }
             <SwiperSlide className='cursor-pointer'>
                <div className="bonusSliderImage">
                       <img src= {banner1} alt="bonus banner" className='w-100 h-100 object-fit-cover' />
              </div>
            </SwiperSlide>
            <SwiperSlide className='cursor-pointer'>
                <div className="bonusSliderImage">
                       <img src= {banner2} alt="bonus banner" className='w-100 h-100 object-fit-cover' />
              </div>
            </SwiperSlide>
            <SwiperSlide className='cursor-pointer'>
                <div className="bonusSliderImage">
                       <img src= {banner3} alt="bonus banner" className='w-100 h-100 object-fit-cover' />
              </div>
            </SwiperSlide>
            <SwiperSlide className='cursor-pointer'>
                <div className="bonusSliderImage">
                       <img src= {banner1} alt="bonus banner" className='w-100 h-100 object-fit-cover' />
              </div>
            </SwiperSlide>
            <SwiperSlide className='cursor-pointer'>
                <div className="bonusSliderImage">
                       <img src= {banner2} alt="bonus banner" className='w-100 h-100 object-fit-cover' />
              </div>
            </SwiperSlide>
            <SwiperSlide className='cursor-pointer'>
                <div className="bonusSliderImage">
                       <img src= {banner3} alt="bonus banner" className='w-100 h-100 object-fit-cover' />
              </div>
            </SwiperSlide>
        </Swiper>
      </div>
    </>
  )
}

export default BonusSlider