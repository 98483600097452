import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import dblImg from '../../assets/images/DBL.png'

const RaceStats = ({ isOpen, setIsOpen }) => {
  return (
    <>
      <Modal className='modalSection' centered show={isOpen} onHide={() => setIsOpen(false)} >
        <Modal.Header closeButton>
          <Modal.Title>
            Race Stats
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row gap-3'>
            <div className='col-12'>
              <div className='Raceheaing'> Race points </div>
              <div className='heaidngoneRace'>Here are your points displayed and the leaderboard from this week’s Race!</div>
            </div>
            <div className='col-12 mt-3'>
              <div className='racepointCard'>
                <ul className='list-unstyled m-0 p-0 d-flex align-items-center justify-content-around gap-2'>
                  <li className='d-flex align-items-center justify-content-center raceStatesCard'>
                    1
                  </li>
                  <li className='d-flex align-items-center justify-content-center raceStatesCard'>
                    1
                  </li>
                  <li className='d-flex align-items-center justify-content-center raceStatesCard'>
                    1
                  </li>
                  <li className='d-flex align-items-center justify-content-center raceStatesCard'>
                    1
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-12'>
              <button className='registernowBtn w-100'>Race</button>
            </div>
            <div className='col-12'>
              <div className='d-flex align-items-center justify-content-between border-bottom'>
                <div className='countDownheading'>The countdown: 14:06:11</div>
                <div className='countDownheading d-sm-none d-block text-end'>The remaining number: <span className='reward'>0 </span></div>
              </div>

            </div>
            <div className='col-12'>
              <div className='countDownheading border-0'>Today’s reward <span className='reward'>50 $DMBL </span></div>
            </div>
            <div className='col-12  BounseTable table-responsive'>
              <Table striped >
                <thead>
                  <tr>
                    <th className='text-start'>Ranking</th>
                    <th className='text-center'>Player</th>
                    <th className='text-center'>Points</th>
                    <th className='text-end'>Prize</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='text-start'> 1</td>
                    <td className='text-center '>Username</td>
                    <td className='text-center '>100</td>
                    <td className='text-end'>
                      <div className='amount'>+$ 0.04 </div>
                    </td>
                  </tr>
                  <tr>
                    <td className='text-start'> 2</td>
                    <td className='text-center '>Username</td>
                    <td className='text-center '>100</td>
                    <td className='text-end'>
                      <div className='amount'>+$ 0.04 </div>
                    </td>
                  </tr>
                  <tr>
                    <td className='text-start'> 2</td>
                    <td className='text-center '>Username</td>
                    <td className='text-center '>100</td>
                    <td className='text-end'>
                      <div className='amount'>+$ 0.04 </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default RaceStats