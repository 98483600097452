import React from 'react';
import ReactDOM from 'react-dom/client';
import "react-datepicker/dist/react-datepicker.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Buffer } from "buffer";
// import 'bootstrap/dist/css/bootstrap.min.css';
import Providers from './providers/Providers';
import "./i18n";
import 'swiper/css';
import 'swiper/css/navigation';
import "bootstrap/dist/css/bootstrap.min.css";
import './assets/styles/index.scss'

const root = ReactDOM.createRoot(document.getElementById('root'));

// Polyfill Buffer
if (typeof window !== "undefined") {
  window.Buffer = Buffer;
}
root.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
