import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useReferralModal } from '../../hooks/useReferralModal';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../hooks/useAuth';
import { getAPI, postAPIAuth } from '../../services/apiInstance';
import { errorToaster, succesToaster } from '../../utils/toaster';
import { reducerConst } from '../../constants/storeConstants';
import { fetchUserDetails } from '../../services/fetchUserDetails';
import { Spinner } from 'react-bootstrap';
import { signInWithEmailAndPassword, updateEmail, updatePassword } from 'firebase/auth';
import { auth } from '../../config/firebase';
import { useTranslation } from 'react-i18next';

const ConfirmPassword = ({showConfirmPassword, setShowConfirmPassword, userEmail, userPassword, setUserPassword, actionType}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [error, setError] = useState(false)
    const userDetail = useSelector(state=> state.userDetails)
    const [userId , setUserId] = useState('')
    const {setToken} = useAuth()
    const { t } = useTranslation()
  const accountPageLabels = t("accountPageLabels", { returnObjects: true })
  
    useEffect(()=> {
      setUserId(userDetail?.id)
    }, [userDetail])
  
  
    const handleChangeEmail = async () => {
      if(password.length >= 8){
        setError(false)
        setIsLoading(true)
        try {
          if(userDetail?.email) {
            const userCredential = await signInWithEmailAndPassword(
              auth,
              userDetail?.email,
              password
            );
            if(userCredential?.user?.accessToken) {
                    try {
                      updateEmail(auth.currentUser, userEmail).then(() => {
                        handleEmailChangeInNode()
                      }).catch((error) => {
                        handleClose()
                      });
  
                    } catch (error) {
                      errorToaster(error.message)
                      handleClose()
                    }
            }
          }
        } catch (error) {
          errorToaster(error?.message)
        }
      } else {
        setError(true)
      }
    }
  
    const handleEmailChangeInNode = async ()=> {
      try {
        const res = await postAPIAuth('user/update-username', {
          userId,
          email: userEmail
        })
        if (res?.data?.success) {
          setToken(res?.data?.data?.auth_token)
          succesToaster('Email Changed Successfully')
          handleClose()
          // setUserEmail('')
        } else {
          errorToaster('Something error has happened')
          updateEmail(auth.currentUser, userDetail?.email).then(() => {
          }).catch((error) => {
          });
          handleClose()
        }
      } catch (error) {
        
      }
    }
  
    const handleChangePassword = async () => {
      if(password.length >= 8){
        setError(false)
        setIsLoading(true)
        try {
          if(userDetail?.email) {
            const userCredential = await signInWithEmailAndPassword(
              auth,
              userDetail?.email,
              password
            );
            
            if(userCredential?.user?.accessToken) {
                try {
                  updatePassword(auth.currentUser, userPassword).then(() => {
                    handlePassChangeInNode()
                  }).catch((error) => {
                    errorToaster('Something error has happened')
                    handleClose()
                  });
                } catch (error) {
                  errorToaster(error.message)
                  handleClose()
                }
            }
          }
        } catch (error) {
          errorToaster(error?.message)
        }
      } else {
        setError(true)
      }
    }
  
    const handlePassChangeInNode = async ()=> {
      try {
        const res = await postAPIAuth('user/update-username', {
          userId,
          password: userPassword
        })
        if (res?.data?.success) {
          succesToaster('Password Changed Successfully')
          setUserPassword('')
          handleClose()
        } else {
          errorToaster('Something error has happened')
          handleClose()
          updatePassword(auth.currentUser, password).then(() => {
          }).catch((error) => {
            errorToaster('Something error has happened')
          });
        }
      } catch (error) {
        
      }
    }
  
    const handleClick = ()=> {
      if(actionType === 'changeEmail') {
        handleChangeEmail()
      } else if (actionType === 'changePassword') {
        handleChangePassword()
      } else {
        return
      }
    }

  const handleClose = ()=> {
    setShowConfirmPassword(false);
    setError(false)
    setPassword('')
    setIsLoading(false)
  }
  return (
  <>
  <Modal show={showConfirmPassword} onHide={handleClose} centered className='modalSection' >
        <Modal.Header closeButton>
          <Modal.Title>{accountPageLabels.Confirm_Your_Password}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalBody'>
          <div className="mt-2">
            <div className='modalSmallSubHeading '>
            {accountPageLabels.Enter_Password}
            </div>
            <div>
              <input class="commonInp" 
                type="text" 
                name="userName" 
                id="userName" 
                placeholder={`${accountPageLabels.Enter_Your_Password}`}
                value={password} 
                onChange={(e)=>setPassword(e.target.value)}
                />
            </div>
          </div>
          
          {
            error && (
              <div className="text-danger errorTxt mt-1">{accountPageLabels.Password_must_have}</div>
            )
          }
          <div className="mt-3 text-center">
            <button type="submit" onClick={handleClick} class="subBtn text-white">
            {
              isLoading ? (
                  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                      <Spinner animation="border" variant="light" size="sm" />
                  </div>
              ) : (
               ` ${accountPageLabels.CONFIRM_PASSWORD}`
              )
            }
            </button>
          </div>
        </Modal.Body>

      </Modal>
  </>
  )
}

export default ConfirmPassword