import React from 'react'
import CryptoWithdraw from './components/CryptoWithdraw'
import FiatWithdraw from './components/FiatWithdraw'

const WithDraw = ({activeTab, setActiveTab, setShowWithdraw}) => {
  return (
    <>
        <div className='withdrawTabs'>
          <div className='row'>
              <div className='col-12'>
                  <ul className='navTabs'>
                      <li style={{
                        height: '30px',
                        width: '30px',
                        background: '#2E3443',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '5px',
                        cursor : 'pointer',
                        marginRight: '8px'
                      }}
                      onClick={()=> setShowWithdraw(false)}
                      >
                        <div className='text-white'><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"></path></svg></div>
                      </li>
                      <li >
                          <div onClick={()=>setActiveTab('crypto')}  className={`navLink ${activeTab === 'crypto' ? 'active' : ''}`}>Crypto</div>
                      </li>
                      <li >
                          <div onClick={()=>setActiveTab('fiat')} className={`navLink ${activeTab === 'fiat' ? 'active' : ''}`}>Fiat</div>
                      </li>
                  </ul>
              </div>
              {
                activeTab === 'crypto' ?
                <CryptoWithdraw/> : <FiatWithdraw/>
              }
          </div>
        </div>
    </>
  )
}

export default WithDraw