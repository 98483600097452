import React from "react";

const LiveSupporIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.62731 3.37763L5.29013 4.04044C6.51094 2.81981 8.48925 2.81981 9.7095 4.04044L10.3729 3.37763C8.78588 1.79081 6.21431 1.79081 4.62731 3.37763Z"
        fill="#2F93DD"
      />
      <path
        d="M13.7499 6.24994C13.7499 2.79788 10.9528 0 7.5 0C4.04813 0 1.25006 2.79788 1.25006 6.24994C0.559687 6.24994 0 6.80963 0 7.5V11.25C0 11.9404 0.559687 12.5001 1.25006 12.5001H3.12506C3.47025 12.5001 3.75 12.2205 3.75 11.8749V8.75006C3.75 8.40638 3.594 7.89056 3.40331 7.605L2.50012 6.25013C2.50012 3.48825 4.7385 1.25006 7.50019 1.25006C10.2626 1.25006 12.5003 3.48825 12.5003 6.25013L11.5969 7.605C11.4062 7.89056 11.25 8.40638 11.25 8.75006V12.0497C10.3339 13.0903 8.9955 13.7499 7.5 13.7499H6.24994V15H7.5C9.54338 15 11.3513 14.0154 12.4914 12.5001H13.7499C14.4403 12.5001 15 11.9404 15 11.25V7.5C15 6.80963 14.4403 6.24994 13.7499 6.24994Z"
        fill="#2F93DD"
      />
    </svg>
  );
};

export default LiveSupporIcon;
