import React from "react";
import profileSecurity from "./../../assets/images/profile-2/profile-security.svg";
import Authentication from "./../../assets/images/profile-2/authentication.svg";
import Alert from "./../../assets/images/profile-2/alert.svg";
import Password from "./../../assets/images/profile-2/password.svg";
import Message from "./../../assets/images/profile-2/message.svg";
import Phone from "./../../assets/images/profile-2/phone.svg";
import Passkey from "./../../assets/images/profile-2/passkey.svg";
const Security = ({ selectedTab, setSelectedTab }) => {
  return (
    <div className="security">
      <div
        className={`drop_mb_tab cursor-pointer  ${
          selectedTab === "security" ? "active" : ""
        } d-flex align-items-center gap-3 my-4`}
        onClick={() => setSelectedTab("security")}
      >
        <div className="dropIcon">
          <img src={profileSecurity} alt="" className="img-fluid" />
        </div>
        <div className="DropTxt">Security</div>
      </div>
      {selectedTab === "security" && (
        <div className="profile_box nt_info bg_main_color p-4">
          <div className="profile-main-heading">
            <h6 className="mb-3">Security Setup</h6>
          </div>
          <div className="py-3">
            <div className="row">
              <div className="col-lg-6 mb-3">
                <div className="security-box p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <img src={Password} alt="" className="img-fluid" />
                    </div>
                    <div>
                      <img src={Alert} alt="" className="img-fluid" />
                    </div>
                  </div>
                  <div className="my-3">
                    <h6 className="sub-heading">Set Password</h6>
                    <p className="sub-desc mb-0">
                      Create your own password for security.
                    </p>
                  </div>
                  <div>
                    <button className="security-btn">Set Password</button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="security-box p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <img src={Message} alt="" className="img-fluid" />
                    </div>
                    <div>
                      <img src={Alert} alt="" className="img-fluid" />
                    </div>
                  </div>
                  <div className="my-3">
                    <h6 className="sub-heading">Email Verification</h6>
                    <p className="sub-desc mb-0">
                      Verify your email address is valid and accessible by you.
                    </p>
                  </div>
                  <div>
                    <button className="security-btn">Verify Email</button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="security-box p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <img src={Phone} alt="" className="img-fluid" />
                    </div>
                    <div>
                      <img src={Alert} alt="" className="img-fluid" />
                    </div>
                  </div>
                  <div className="my-3">
                    <h6 className="sub-heading">Phone Number Verification</h6>
                    <p className="sub-desc mb-0">
                      Verify your phone number is valid and accessible by you.
                    </p>
                  </div>
                  <div>
                    <button className="security-btn">
                      Verify Phone Number
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="security-box p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <img src={Authentication} alt="" className="img-fluid" />
                    </div>
                    <div>
                      <img src={Alert} alt="" className="img-fluid" />
                    </div>
                  </div>
                  <div className="my-3">
                    <h6 className="sub-heading">Two-factor authentication</h6>
                    <p className="sub-desc mb-0">
                      Enable Two-factor to protect your account from
                      unauthorized access.
                    </p>
                  </div>
                  <div>
                    <button className="security-btn">Enable 2FA</button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-3">
                <div className="security-box p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <img src={Passkey} alt="" className="img-fluid" />
                    </div>
                    <div>
                      <img src={Alert} alt="" className="img-fluid" />
                    </div>
                  </div>
                  <div className="my-3">
                    <h6 className="sub-heading">PassKey</h6>
                    <p className="sub-desc mb-0">
                      Using passkey to protect your account from unauthorized
                      access.
                    </p>
                  </div>
                  <div>
                    <button className="security-btn">Enable Passkey</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Security;
