import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

const Faq = () => {
  return (
    <div className="my-5">
      <div className="CommonTitle">FAQ </div>
      <div className="row">
        <div className="col-lg-6">
          <Accordion className='stackingAccordian'
          //  defaultActiveKey={['0', '1', '2', '3', '4', '5']} 
           alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>What Is Crypto Staking?</Accordion.Header>
            <Accordion.Body>
            Damble Crypto Staking is your one-stop wealth hub that allows you to enjoy the highest APRs in the industry on the cryptocurrency you hold. Amazing options if you are a HODLer.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>What Are the Differences Between Flexible and Fixed-Term Staking?</Accordion.Header>
            <Accordion.Body>
              Flexible Staking is a hassle-free way of enjoying higher-than-average APRs without the need to lock your funds on the platform. Simply deposit assets to your game balance, approve the cryptocurrency you want to participate in staking and start enjoying profit. On the other hand, fixed-term staking requires you to lock your assets for a predetermined period (e.g., 30, 60, 90 days), offering higher potential rewards. The choice depends on your financial goals and your willingness to commit to a specific timeframe.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              What Are the Differences Between Classic Fixed-Term Staking and the One With Wager?
            </Accordion.Header>
            <Accordion.Body>
              Even though these two staking types involve locking up your crypto assets to earn rewards, the key difference lies in the potential APRs and how they are achieved.Classic fixed-term staking does not require additional actions beyond locking up your crypto assets to earn the rewards. In case of fixed-term with wager staking offers significantly higher APRs but it is contingent on users meeting minimum wager requirements on the platform. In this case, you have the potential to enjoy much higher rewards if you meet the minimum wager requirements for each pool.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
            How Is My Wager Calculated?
            </Accordion.Header>
            <Accordion.Body>
              For all in-house games the multiplier is set at 0.75. Thus, for every 1,000 USD in bet amount, users will accumulate 750 USD in wager. For all slot games, the multiplier is set at 1, meaning that for every 1,000 USD in bet amount, users will accumulate 1,000 USDT in wager. In the case of Sports Betting, the multiplier is set at 3. This signifies that for every 1,000 USDT in bet amount, users will accumulate 3,000 USD in wager. Wager accrual does not apply to Live, Table and Special Games and is expressly excluded from the wager accrual process.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
             Can I Access My Staked Assets at Any Time?
            </Accordion.Header>
            <Accordion.Body>
              It depends on the staking type. In flexible staking, you can generally access your assets at any time. In fixed-term staking, you will have to wait until the staking period ends or meet specific requirements to make an early withdrawal.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>
              How Do Tiered APRs in Flexible Staking Work?
            </Accordion.Header>
            <Accordion.Body>
                Tiered APRs in flexible staking is a system where your staking rewards depend on the amount of tokens you have staked.
              <br />
              <br />
            Example:- 
              <br />
              <div>- Your game balance: 10,000 USDT</div>
              <div>- Tier 1 APR: 0 - 1,000 USDT: 10%</div>
              <div>- Tier 2 APR: > 1,000 USDT: 6%</div>
              <br />
              In this case, you will be getting 10% APR on your 1,000 USDT and 6% APR on the remaining 9,000 USDT on your game balance. Awesome, right?
            </Accordion.Body>
          </Accordion.Item>
          </Accordion>
        </div>
        
        <div className="col-lg-6">
          <Accordion className='stackingAccordian' 
          // defaultActiveKey={['0', '1', '2', '3', '4']} 
          alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>When and How Are the Rewards Distributed?</Accordion.Header>
            <Accordion.Body>
            When it comes to flexible staking, the rewards are distributed every 30 minutes. On the other hand, the rewards in fixed-term pools are accrued the day after subscription, starting at 00:00 UTC. You will start receiving reward distribution the day after accrual starts (two days after subscription) at 01:00 UTC.
            <br /> <br />
            Once the rewards are distributed, you can claim them from the active position tab. However, you need to make sure you have managed to accrue the minimum claim amount for the rewards to become available for claim.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
            Where Do I Receive My Rewards From Staking?
            </Accordion.Header>
            <Accordion.Body>
            If you are claiming your rewards from flexible and/or classic fixed term staking, 80% will be allocated to your balance and 20% will be linearly distributed to the calendar over the period of 3 (three) days for VIP users and 6 (six) days for common users. When it comes to the fixed-term staking where there are wager requirements, 100% of the rewards will be directly allocated to your game balance in the event of harvest.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
            What Is the Redemption Policy for Fixed-Term Staking?
            </Accordion.Header>
            <Accordion.Body>
            Damble allows you to make an early redemption provided that minimum platform requirements are met. Once you opt for an early redemption, you will be charged with a 5% penalty fee along with the rewards that have already been accrued.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
            What Is Auto-Subscribe?
            </Accordion.Header>
            <Accordion.Body>
            Auto-Subscribe allows you to resubscribe to the same lock period upon expiry. If disabled, your tokens will be automatically transferred to your game balance upon expiry. If enabled, you will automatically resubscribe to the same lock product for the same duration and start accrue interest immediately. Resubscription will occur on the day your lock product expires.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
            What Are the Key Characteristics of Exclusive Pools?
            </Accordion.Header>
            <Accordion.Body>
            Exclusive Staking Pools are a unique opportunity featuring superior APRs, a curated token portfolio, and strategic options. These pools appear only periodically, offering a limited-time opportunity to redefine your crypto investment strategy with precision and finesse. Don't miss out on this exclusive chance to maximize your returns!
            </Accordion.Body>
          </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  )
}

export default Faq