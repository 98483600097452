import React from "react";
import errorToaster from "../../assets/images/errorToaster.png";
const ErrorTaoster = ({ msg }) => {
  return (
    <div className="login-toast-container">
      <div>
        <img className="login-toast-image" src={errorToaster} alt="" />
      </div>
      <div className="login-toast-text">
        <div className="login-toast-msg">{msg}</div>
      </div>
    </div>
  );
};

export default ErrorTaoster;
