import React from "react";
// import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";

const Chart = ({ selectedYear }) => {
  // const options = {
  //   chart: {
  //     type: "areaspline",
  //     backgroundColor: null,
  //     height: 200,
  //     marginLeft: 0,
  //     events: {
  //       render: function () {
  //         let chart = this;
  //         chart.series[0].points.forEach((p) => {
  //           let x = p.plotX + chart.plotLeft;
  //           let y = p.plotY + chart.plotTop;

  //           if (p.customPath) {
  //             p.customPath.destroy();
  //           }

  //           p.customPath = chart.renderer
  //             .path(["M", x, y, "L", x, chart.plotSizeY + chart.plotTop])
  //             .attr({
  //               "stroke-width": 1,
  //               stroke: "#5BB54F",
  //               "stroke-dasharray": 5,
  //             })
  //             .add();
  //         });
  //       },
  //     },
  //   },
  //   title: false,
  //   yAxis: {
  //     title: false,
  //   },

  //   xAxis: {
  //     title: false,
  //     categories: ["1 year", "2 year", "3 year", "5 year"],
  //     labels: {
  //       style: {
  //         color: "#B2B6C5",
  //         fontSize: 13,
  //       },
  //     },
  //   },
  //   legend: {
  //     enabled: false,
  //   },
  //   tooltip: {
  //     backgroundColor: "#2C303F",
  //     borderColor: "#2C303F",
  //     style: {
  //       color: "white",
  //     },
  //   },
  //   plotOptions: {

  //     // area: {
  //     //   fillColor: {
  //     //     linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1},
  //     //     stops: [
  //     //         [0, '#ff4e00'],
  //     //         [1, '#ffe4d8']
  //     //     ]
  //     // },
  //     // },
  //     series: {
  //       fillColor: {
  //         linearGradient: [0, 0, 0, 110],
  //         stops: [
  //             [0, '#5BB54F'],
  //             [
  //                 1,
  //                 Highcharts.color('#5BB54F')
  //                     .setOpacity(0).get('rgba')
  //             ]
  //         ]
  //     },
  //       marker: {
  //         enabled: false,
  //         symbol: "url(./assets/img/stacking/marker.svg)",
  //         shadow: false,
  //       },
  //     },
  //   },
  //   series: [
  //     {
  //       name: "Estimated Earnings",
  //       lineColor: "#5BB54F",
  //       lineWidth: 3,
  //       // marker: false,
  //       data: [4, 7, 13, 25],
  //     },
  //   ],
  // };
  return (
      <>
        {/* <HighchartsReact highcharts={Highcharts} options={options} />; */}
        <div className="chartImg text-center mt-1">
          {
            selectedYear === 1 ? <img className="w-75" src="./assets/img/stacking/chart1.svg" alt="" /> :
            selectedYear === 2 ? <img className="w-75" src="./assets/img/stacking/chart2.svg" alt="" /> :
            selectedYear === 3 ? <img className="w-75" src="./assets/img/stacking/chart3.svg" alt="" /> :
            selectedYear === 5 ? <img className="w-75" src="./assets/img/stacking/chart4.svg" alt="" /> : ''
          }
        </div>
      </>
  )
};

export default Chart;
