import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Importing translation files
import translationEN from "./locales/en/translation.json";
import translationHI from "./locales/hi/translation.json";
import translationFR from "./locales/fr/translation.json";

//Creating object with the variables of imported translation files
const resources = {
    en: { //keys has to be similar to symbol of language const
        translation: translationEN,
    },
    hi: {
        translation: translationHI,
    },
    fr: {
        translation: translationFR,
    },
};

const option = {
    order: ['navigator', 'htmlTag', 'path', 'subdomail'],
    checkWhitelist: true
}
// const availableLanguages = ['ko', 'en', 'chi', 'hi']

//i18N Initialization
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        initImmediate: false,
        // lng: "'hi", //default language
        keySeparator: false,
        // whitelist:availableLanguages,
        detection: option,
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        
    });

export default i18n;



// i18n
//   .use(Backend)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     fallbackLng: 'en',
//     debug: true,
//     whitelist:availableLanguages,
//     detection:option,
//     interpolation: {
//       escapeValue: false, // not needed for react as it escapes by default
//     }
//   });
