import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../hooks/useAuth';
import { getAPIAuth, postAPIAuth } from '../../../services/apiInstance';
import { fetchUserDetails } from '../../../services/fetchUserDetails';
import { reducerConst } from '../../../constants/storeConstants';
import noData from './../../../assets/images/Isolation_Mode.png'
import { getFavCryptoAction, getFavFiatAction } from '../../../store/action';

const AddCurrencyModal = ({showFavoriteModal, setShowFavoriteModal}) => {  
  const dispatch = useDispatch();
  const { token } = useAuth();
  const [cryptoList, setCryptoList] = useState([]);
  const [fiatList, setFiatList] = useState([]);
  const [MyFavList, setMyFavList] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [search, setSearch] = useState('')
  const [isChecked, setIsChecked] = useState(false);
  const userDetails = useSelector(state => state.userDetails)
  const [isLoading, setLoading] = useState(false);
  const [filteredMyFavList, setFilteredMyFavList] = useState([])

  const getAvailableCrypto = async () => {
    try {
      const res = await getAPIAuth(`crypto/get-crypto?userId=${userDetails?.id}&crypto=${search}`, token);
      if (res?.data?.success) {
        setCryptoList(res.data.data);
      }
    } catch (error) {
    }
  }
  const getFiatCurrency = async () => {
    try {
      const res = await getAPIAuth(`currency/get-currency?userId=${userDetails?.id}&currency=${search}`, token);
      setFiatList(res.data.data);
    } catch (error) {
    }
  }


  const handleChecked = async (e, item) => {
    setIsChecked(e.target.checked);

    if (item?.type === "crypto" && activeTab === '1') {
      const body = {
        crypto_id: item._id,
        userid: userDetails?.id
      }
      const res = await postAPIAuth("crypto/save-crypt-favorite", body)
      getAvailableCrypto();
    }
    else if (item?.type === 'currency' && activeTab === '2') {
      const body = {
        currency_id: item._id,
        userid: userDetails?.id
      }
      const res = await postAPIAuth("currency/save-curr-favorite", body)
      getFiatCurrency();
    }

  }
  const getFav = async () => {
    try {
      const res = await getAPIAuth(`crypto/get-fav-currency-crypto?userId=${userDetails?.id}&favcryptocurrency=${search}`);
      if (res?.data?.success) {
        const crypto = res?.data?.crypto
        const fiat = res?.data?.fiat
        const newArray = [...crypto, ...fiat]
        setMyFavList(newArray);
        setFilteredMyFavList(newArray)
        dispatch(getFavFiatAction(res?.data?.fiat ? res.data.fiat : []))
        dispatch(getFavCryptoAction(res?.data?.crypto ? res.data.crypto : []))
      }
    } catch (error) {

    }
  }

  const filterMyList = () => {
    const filterData = MyFavList.filter(item => item.code.toLowerCase().includes(search.toLowerCase()) || item.name.toLowerCase().includes(search.toLowerCase()))
    setFilteredMyFavList(filterData)
  }
  useEffect(() => {
    if (userDetails.id) {
      getAvailableCrypto();
      getFiatCurrency();
      if (search) {
        filterMyList()
      } else {
        getFav();

      }
    }
  }, [userDetails.id, activeTab, search])

  const handleClose = ()=> {
    setShowFavoriteModal(false)
    setActiveTab('1');
  }
  return (
  <>
  <Modal show={showFavoriteModal} onHide={handleClose} scrollable centered className='modalSection viewInFiatModal' >
        <Modal.Header className='d-block' closeButton>
          <Modal.Title>Add Currency</Modal.Title>
          <div className="row w-100 mx-0 align-items-center mt-1">
            <div className="col-12 px-0">
              <div className="searchInp">
                <img src="./assets/img/searchGray.png" alt="" />
                <input type="text" className="form-control shadow-none" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
              </div>
            </div>
            <div className="col-12 px-0 mb-1">
              <div className="dropTabs">
                <div className={`tab ${activeTab === '1' ? 'active' : ''}`} onClick={() => setActiveTab('1')}>Crypto</div>
                <div className={`tab ${activeTab === '2' ? 'active' : ''}`} onClick={() => setActiveTab('2')}>Fiat</div>
                <div className={`tab ${activeTab === '3' ? 'active' : ''}`} onClick={() => setActiveTab('3')}>mNFT</div>
                {/* <div className={`tab ${activeTab === '4' ? 'active' : ''}`} onClick={() => setActiveTab('4')}>My Favourite</div> */}
              </div>
            </div>
            {/* <div className="col px-0 my FavCoin">My Favorite Coins</div> */}
            {/* <div className="col-auto pe-0">
              <div className="sortBtn">
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M348.3 295.6c-5-5.1-13.3-5.1-18.4-.1L269 356.2V124.9c0-7.1-5.8-12.9-13-12.9s-13 5.8-13 12.9v231.3l-60.9-60.8c-5.1-5-13.3-4.9-18.4.1-5 5.1-5 13.2.1 18.3l83 82.4c1.2 1.1 2.5 2 4.1 2.7 1.6.7 3.3 1 5 1 3.4 0 6.6-1.3 9.1-3.7l83-82.4c5.2-4.9 5.3-13.1.3-18.2z"></path></svg>
              </div>
            </div> */}
          </div>
        </Modal.Header>
        <Modal.Body className='modalBody'>
          
        <div className="row">
            <div className="col-12">
              <ul className='m-0 p-0 list-unstyled usdtImageDropdownInner'>
                {activeTab === '1' ? (
                  <>
                    {
                      cryptoList?.map((item, index) => (
                        <li key={index} className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <img className="rounded" src={item.icon} alt="" />
                            {item.code}
                          </div>
                          <div className='crName d-flex align-items-center'>
                            {item.name}
                            <div className="checkInp">
                              <input type="checkbox" className='chcck d-none' id={`cta-${item.id}`}
                                checked={item?.isFav}
                                onChange={(e) => handleChecked(e, item)} />
                              <label className='checkLabel' htmlFor={`cta-${item.id}`}></label>
                            </div>
                          </div>
                        </li>
                      ))
                    }
                  </>
                ) : activeTab === '2' ? (
                  <>
                    {fiatList?.map((item, index) => (
                      <li key={index} className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <img className="rounded" src={item.icon} alt="" />
                          {item.code}
                        </div>
                        <div className='crName d-flex align-items-center'>
                          {item.name}
                          <div className="checkInp">
                            <input type="checkbox" className='chcck d-none' id={`cta-${item.id}`}
                              checked={item.isFav}
                              onChange={(e) => handleChecked(e, item)} />
                            <label className='checkLabel text-white' htmlFor={`cta-${item.id}`}>{item.isFav}</label>
                          </div>
                        </div>
                      </li>
                    ))
                    }
                  </>
                ) : activeTab === '3' ? (
                  <>
                  <div className="innerScrollDiv d-flex justify-content-center align-items-center flex-column" style={{ gap: '20px' }}>
  {/* <div>
    <img
      src={NoData}
      alt=""
      style={{
        width: "100px",
      }}
    />
    <div className="dropTitle mb-1 text-center mt-3">
      No Data Available
    </div>
  </div> */}
  <div style={{ position: "relative" }}>
    <span>
      <svg width="74" height="61" viewBox="0 0 74 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.14062 9.43109V3.16768C1.14062 1.97355 2.114 1 3.30794 1H32.993C34.1869 1 35.1606 1.97941 35.1606 3.16768V6.30008C35.1606 7.48366 36.1342 8.46775 37.3281 8.46775H70.6045C71.9239 8.46775 72.9998 9.54354 72.9998 10.8629V53.17C72.9998 56.4666 70.311 59.1556 67.0142 59.1556H64.9196M1.14062 9.43109V45.0887M1.14062 9.43109C1.14062 8.23783 2.11244 7.26431 3.3076 7.26431H27.3076C28.5006 7.26431 29.4744 8.24269 29.4744 9.43111V12.5639C29.4744 13.7495 30.4479 14.7326 31.6427 14.7326H64.9197C66.1131 14.7326 67.0884 15.7137 67.0884 16.8994V56.9886C67.0884 58.1741 66.1053 59.1556 64.9197 59.1556H32.3302" stroke="#6A798C" strokeWidth="1.87194" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </span>
    <span style={{ position: 'absolute', bottom: '-6px', left: '0px' }}>
      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.12993 6.10063C11.9698 -0.739272 23.0596 -0.739272 29.8995 6.10063C36.7394 12.9405 36.7394 24.0303 29.8995 30.8702C23.0596 37.7101 11.9698 37.7101 5.12993 30.8702C-1.70998 24.0303 -1.70998 12.9405 5.12993 6.10063ZM10.0545 7.71085L28.2893 25.9456C31.8345 20.838 31.3316 13.7715 26.7801 9.22007C22.2287 4.66859 15.1622 4.16567 10.0545 7.71085ZM24.9751 29.26L6.74015 11.025C3.19485 16.1326 3.69779 23.1992 8.24934 27.7508C12.8009 32.3024 19.8676 32.8053 24.9751 29.26Z" fill="#F8BA28"/>
      </svg>
    </span>
  </div>
  <h1 style={{ fontSize: "18px", color: "#B2B6C5" }}>Oops! There is no data yet!</h1>
</div>
                  </>
                ) : (
                  <>
                    {filteredMyFavList?.map((item, index) => (
                      <li className="usdtImageDropdownInnerList d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <img className="rounded" src={item.icon} alt="" />
                          {item.code}
                        </div>
                        <div className='crName d-flex align-items-center'>
                          {item.name}
                          {/* <div className="checkInp">
                           <label className='checkLabel text-white' htmlFor={`cta-${item.id}`}>{item.isFav}</label>
                         </div> */}
                        </div>
                      </li>
                    ))
                    }
                  </>
                )}
              </ul>
            </div>
          </div>
        </Modal.Body>

      </Modal>
  </>
  )
}

export default AddCurrencyModal