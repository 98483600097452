import React, { useEffect, useState } from "react";

import AffilliatePage from "./AffilliatePage";
import AffiliatePageAfterLogin from "./AffiliatePageAfterLogin";
import { ROUTE_CONST } from "../../constants";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
const AffiliateParent = () => {
  const { token } = useAuth();
  console.log({ token });

  if (token) return <AffiliatePageAfterLogin />;
  return <AffilliatePage />;
};

export default AffiliateParent;
