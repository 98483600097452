import React, { useEffect, useState } from 'react'
import { getAPI } from '../../../../../services/apiInstance'
import SubscribeBTCModal from '../../SubscribeBTCModal'
import { useAuth } from '../../../../../hooks/useAuth'
import { useAuthModal } from '../../../../../hooks/useAuthModal'
import { AUTH_MODAL_TABS } from '../../../../../constants'
import { useSearchParams } from 'react-router-dom'
import NoData from '../../NoData'
import { useSubscribeModal } from '../../../../../hooks/useSubscribeModal'

const AllProducts = () => {
  const [activeTable, setActiveTable] = useState()
  const [data, setData] = useState([])

  const {
    selectedBoostedTerm,
    setSelectedBoostedTerm,
    showSubscribeModal, 
    setShowSubscribeModal,
    selectedItem, 
    setSelectedItem,
    selectedFixedTerm, 
    setSelectedFixedTerm,
    selectedPoolType, 
    setSelectedPoolType
} = useSubscribeModal();

  
  const {setAuthModalObject } = useAuthModal();
  const [query, setQuery] = useSearchParams()
  const {token} = useAuth()
  // set last tab of boosted fixed term as selected term

  useEffect(()=> {
    if(data?.length) {
      const boostedFixedTerm = data?.[activeTable]?.term?.filter(item=>item?.poolType === "Boosted Fixed-Term")?.[0]?.tab
      const fixedTerm = data?.[activeTable]?.term?.filter(item=>item?.poolType === "Fixed-Term")?.[0]?.tab
      if (boostedFixedTerm?.length) {
        const term = boostedFixedTerm[boostedFixedTerm?.length - 1]
        setSelectedBoostedTerm(term)
      }
      if (fixedTerm?.length) {
        const term = fixedTerm[fixedTerm?.length - 1]
        setSelectedFixedTerm(term)
      }
    }
  }, [data, activeTable])

  const getAllProductData = async ()=> {
    try {
      const res = await getAPI('get-crytoStaking-currency')
      if(res?.data?.success) {
        setData(res?.data?.data)
      }
    } catch (error) {
      
    }
  }

  useEffect(()=> {
    getAllProductData()
  }, [])


  return (
    <>
      <div className="customDivTable mt-4">
        {
          data?.length ? <>
            <div className="tableHeader text-uppercase">
              <div className="tableHead one sText">Token</div>
              <div className="tableHead two sText">EST. APR</div>
              <div className="tableHead three sText d-none d-lg-block">TERM</div>
            </div>
            {
              data?.map((item, index)=>(
                <>
                        <div 
                          key={item?._id}  
                          className={`tableBody cursor-pointer ${activeTable === index ? 'active' : ''}`}
                          onClick={()=>{
                            if(activeTable === index) {
                              setActiveTable()
                            } else {
                              setActiveTable(index)
                            }
                          }}
                          >
                          <div className="tbr tbr1 text-uppercase">
                            <img src={item?.currency?.icon} alt="" />
                            {item?.currency?.code}
                          </div>
                          <div className="tbr tbr2">
                            {
                              item?.estimated_Apr_Min === item?.estimated_Apr_Max ? <>{item?.estimated_Apr_Min} %</> : 
                              <>
                                {item?.estimated_Apr_Min}% - {item?.estimated_Apr_Max}%
                              </>
                            }
                          </div>
                          <div className="tbr tbr3 d-none d-lg-block">Fixed-Term</div>
                          <div className="tbr tbr4 d-none d-lg-block"></div>
                          <div className="tbr tbr5">
                            <div className="accBtn" >
                              <img src="./assets/img/stacking/down.png" alt="" />
                            </div>
                          </div>
                        </div>
                        {
                          activeTable === index ? 
                            <>
                              <div className="tableHeader text-uppercase">
                                <div className="tableHead one sText">POOL TYPE</div>
                                <div className="tableHead two sText">APR</div>
                                <div className="tableHead three sText d-none d-lg-block">TERM</div>
                                <div className="tableHead four sText d-none d-lg-flex align-items-center">
                                  WAGER
                                  <img className='ms-2' src="./assets/img/stacking/question.png" alt="" />
                                </div>
                                <div className="tableHead five sText">
                                  {/* need to be blank */}
                                </div>
                              </div>
                              {
                                item?.term?.length ? 
                                  item?.term?.map((ele)=> (
                                    <div className="tableBody inner" key={ele?._id}>
                                      <div className="tbr tbr1">
                                        <img src={item?.currency?.icon} alt="" />
                                        {ele?.poolType}
                                      </div>
                                      <div className="tbr tbr2">
                                      {ele?.poolType === "Boosted Fixed-Term" ? selectedBoostedTerm?.apr :
                                      ele?.poolType === "Fixed-Term" ? selectedFixedTerm?.apr : ''
                                      }
                                      
                                      %</div>
                                      <div className="tbr tbr3">
                                        <div className="d-flex align-items-center flex-wrap gap-2">
                                          {ele?.tab?.length ? ele?.tab?.map(element=>(
                                            <div 
                                              key={item?._id + ele?._id + element?.name}
                                              onClick={()=>{
                                                if(ele?.poolType === "Boosted Fixed-Term") {
                                                  setSelectedBoostedTerm(element)
                                                } else if (ele?.poolType === "Fixed-Term") {
                                                  setSelectedFixedTerm(element)
                                                }
                                              }} 
                                              className={`termBox ${ele?.poolType === "Boosted Fixed-Term" && selectedBoostedTerm?.name === element?.name ? 'active' : ''}  ${ele?.poolType === "Fixed-Term" && selectedFixedTerm?.name === element?.name ? 'active' : ''}`}>{element?.name}</div>
                                          )): '--'}
                                        </div>
                                      </div>
                                      <div className="tbr tbr4">
                                        <span className='d-flex align-items-center d-lg-none wagerrTxt'>
                                          Wager
                                          <img className='ms-2' src="./assets/img/stacking/question.png" alt="" />
                                        </span>
                                        {
                                          ele?.poolType === 'Boosted Fixed-Term' ? <>Principle Amount x {selectedBoostedTerm?.principleAmount} </>  : 'Not Required'
                                        }
                                        
                                      </div>
                                      <div className="tbr tbr5">
                                        <button onClick={()=>{
                                            if(token) {
                                              setSelectedItem(item)
                                              setShowSubscribeModal(true)
                                              setSelectedPoolType(ele?.poolType)
                                            } else {
                                              setAuthModalObject(pre => ({
                                                // ...pre,
                                              selectedTab: AUTH_MODAL_TABS.LOG_IN,
                                              isAuthOpen: true,
                                            }))
                                            setQuery({ action: "login" })
                                            }
                                        }} className='commonBlueBtn small'>Subscribe</button>
                                      </div>
                                    </div>
                                  ))
                                : ""
                              }
                            </>
                          : ''
                        }
                </>
              ))
            }
          </> : <NoData/>
        }
      </div>
      
    </>
  )
}

export default AllProducts