import React, { useEffect, useState } from "react";
import SportBanner1 from "./../../../../assets/images/home/sports-banner1.png";
import SportBanner2 from "./../../../../assets/images/home/sports-banner2.png";
import { getAPI } from "../../../../services/apiInstance";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link, useNavigate } from "react-router-dom";
import { ROUTE_CONST } from "../../../../constants";
import { Navigation } from "swiper/modules";
import GameCard from "../../../../components/GameCard/GameCard";
import SkeletonLoader from "../../../../components/skeletonLoader/SkeletonLoader";
import NoData from "../../../../components/noData/NoData";
import { useTranslation } from "react-i18next";

const SportsBannerSec = ({
  specialGames,
  isLoadingSpecialCat,
  selectedSpecialCatName,
}) => {
  const { t } = useTranslation();
  const PlaySlotLabels = t("PlaySlotLabels", { returnObjects: true });

  console.log({selectedSpecialCatName},"fjjfjfjfjf")
  return (
    <>
      <div className="col-12 pt-3 pt-lg-0 px-0 d-flex d-lg-flex justify-content-between mb-1 pb-1 mb-lg-3 align-items-center">
        <div className="mainHeading d-flex align-items-center">
          {selectedSpecialCatName ? selectedSpecialCatName : ""}
        </div>
        <div className="d-flex align-items-center">
          {/* <div className="mainSubHeading">
            <Link to={ROUTE_CONST.CASINO} className="headingAnchor">
              {casinoLabels.View_all}
              Upcoming Events
            </Link>
          </div> */}

          <div className="d-flex align-items-center ">
            <div className="mainSubHeading mainSubHeadingCasino d-flex align-items-center">
              <Link
                to={ROUTE_CONST.PROVIDERS}
                className="headingAnchor text-white"
              >
                {PlaySlotLabels.View_All}
              </Link>{" "}
              <img
                className="viewIcon"
                src="./assets/img/nextIcon.png"
                alt=""
              />
            </div>
            <div className="casinoSliderBtns casinoPageSliderBtns d-flex d-lg-flex align-items-center">
              <div className={`sliderBtn sportPrevUppergames`}>
                <img src="./assets/img/prevIcon.png" alt="" />
              </div>
              <div className={`sliderBtn sportNextUppergames `}>
                <img src="./assets/img/nextIcon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="sportsCard pb-3 py-lg-0 sectionSpacing">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            navigation={{
              prevEl: ".sportPrevUppergames",
              nextEl: ".sportNextUppergames",
            }}
            // breakpoints={{
            //   0: {
            //     slidesPerView: 3,
            //   },
            //   576: {
            //     slidesPerView: 3.2,
            //   },
            //   768: {
            //     slidesPerView: 4.5,
            //   },
            //   992: {
            //     slidesPerView: 4.6,
            //   },
            //   1200: {
            //     slidesPerView: 5,
            //   },
            //   1400: {
            //     slidesPerView: 6,
            //   },
            // }}
            modules={[Navigation]}
            loop={true}
            // className="mySwiper casinoSwiperWrapper "
            className="mySwiper casinoSwiperWrapper "
          >
            {!isLoadingSpecialCat ? (
              specialGames?.length ? (
                specialGames.map((item) => (
                  <SwiperSlide className="popularcardsWidth">
                    {/* <div
                      className="sportsCardBox"
                      onClick={() => {
                        dispatch(btUrlAction(item?.category));
                        navigate(ROUTE_CONST.CASINO + "/" + item.uuid);
                      }}
                    >
                      <div className="sportsImg">
                        <img src={item.image} alt={item.name} />
                      </div>
                      <div className="sportsTitle">
                        <h5>{item.provider}</h5>
                      </div>
                    </div> */}
                    <GameCard data={item} />
                  </SwiperSlide>
                ))
              ) : (
                <NoData />
              )
            ) : (
              [1, 1, 1, 1, 1, 1, 1, 1]?.map((item) => (
                <SwiperSlide>
                  <SkeletonLoader type={"sportsCard"} />
                </SwiperSlide>
              ))
            )}
          </Swiper>
        </div>
      </div>
    </>

    // <div className="col-12">
    //   <div className="row SportsBannerSec pt-1 pb-4">
    //     <Swiper
    //       slidesPerView={3}
    //       spaceBetween={10}
    //       pagination={{
    //         clickable: true,
    //       }}
    //       breakpoints={{
    //         0: {
    //           slidesPerView: 1,
    //         },
    //         576: {
    //           slidesPerView: 2,
    //         },
    //         768: {
    //           slidesPerView: 2.25,
    //         },
    //         992: {
    //           slidesPerView: 3,
    //         },
    //         1200: {
    //           slidesPerView: 3,
    //         },
    //         1400: {
    //           slidesPerView: 3,
    //         },
    //       }}
    //       className="mySwiper casinoSwiperWrapper "
    //     >
    //       {specialGames.map((game) => {
    //         return (
    //           <SwiperSlide
    //             onClick={() => navigate(ROUTE_CONST.CASINO + "/" + game.uuid)}
    //             key={game._id}
    //           >
    //             <div className="sportsCardBox">
    //               <div className="sports_banner">
    //                 <img className="h-100" src={game.posterImage} alt="" />
    //               </div>
    //               <p className="mt-2 text-center">{game.provider}</p>
    //             </div>
    //           </SwiperSlide>
    //         );
    //       })}
    //     </Swiper>
    //   </div>
    // </div>
  );
};

export default SportsBannerSec;
