import { useNavigate } from "react-router-dom";
import king from "./../../assets/images/promotions/king.png";
import { ROUTE_CONST } from "../../constants";
import NoData from "../../components/noData/NoData";
const CasinoTab = ({
  casinoData,
  setCasinoPage,
  casinoPage,
  casinoPageCount,
  selectedTab,
}) => {
  const navigate = useNavigate();
  return (
    <div
      class="tab-pane fade show active"
      id="pills-promotion"
      role="tabpanel"
      aria-labelledby="pills-promotion-tab"
      tabindex="0"
    >
      <div className="row gy-5">
        {selectedTab === "casino" && !casinoData.length ? (
          <NoData />
        ) : (
          casinoData.map((item) => {
            const date = new Date(item.endDate);

            date.setMinutes(date.getMinutes() + 329); // Adding 329 minutes (5 hours 30 mins - 1 sec)

            const formattedDate = `${
              date.getMonth() + 1
            }/${date.getDate()}/${date.getFullYear()}, ${date.toLocaleTimeString(
              "en-US"
            )}`;

            return (
              <div
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(`${ROUTE_CONST.PROMOTIONDETAIL}/${item?._id}`)
                }
                key={item._id}
                className="col-lg-4 col-md-6 col-sm-6 col-12 mb-lg-0 mb-3 "
              >
                <div className="bonus-card">
                  <div>
                    <img src={item.image} alt="" className="h-100 w-100" />
                  </div>
                  <div className="detail">
                    <div className="">
                      <div className="bonus_tittle">{item.text}</div>
                      <div className="bonus_text">Ends {formattedDate}</div>
                    </div>
                    <button
                      style={{ textTransform: "capitalize" }}
                      className="progressbtn"
                    >
                      {item.status}
                    </button>
                  </div>
                  <div className="exslusive">
                    <img src={king} alt="" className="h-100 w-100" />
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>

      {casinoPage >= casinoPageCount ? (
        <></>
      ) : (
        <div className="d-flex justify-content-center my-4 w-100">
          <button
            onClick={() => setCasinoPage((pre) => pre + 1)}
            className="btn-2"
          >
            LOAD MORE
          </button>
        </div>
      )}
    </div>
  );
};

export default CasinoTab;
