import React, { useEffect, useState } from 'react'
import union from '../../../assets/images/addIcon.png'
import Form from 'react-bootstrap/Form';
import magnifyingglass from '../../../assets/images/profile/magnifying-glass.png'
import { useDispatch, useSelector } from 'react-redux';
import { useWalletModal } from '../../../hooks/useWallet';
import { getAPIAuth } from '../../../services/apiInstance';
import { getFavCryptoAction, getFavFiatAction, selectCrypto, selectFiat } from '../../../store/action';
import { convertToEightDigitsAfterDecimal } from "../../../utils/convertToEightDigitsAfterDecimal";
import { useTranslation } from 'react-i18next';
import { ACCOUNT_TABS, WALLET_MODAL_TABS } from '../../../constants';
import WithDraw from '../../../components/withdraw';
import { useSearchParams } from 'react-router-dom';
import { useAccount } from '../../../hooks/useAccount';





const WalletTab = () => {
    const dispatch = useDispatch();
    const [showWithdraw, setShowWithdraw] = useState(false);
    const favCryptoDetails = useSelector(state => state.favCrypto);
    const favFiatDetails = useSelector(state => state.favFiat);
    const userDetails = useSelector(state => state.userDetails);
    const [search, setSearch] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const userBalance = useSelector(state => state.userBalance);
    const { walletModalObject, setWalletModalObject } = useWalletModal()
    const [activeTab, setActiveTab] = useState('crypto')
    const [favCrypto, setFavCrypto] = useState([]);
    const [favFiat, setFavFiat] = useState([]);
  const { accountTab, setAccountTab } = useAccount()


    const getFav = async () => {
        try {
            const res = await getAPIAuth(`crypto/get-fav-currency-crypto?userId=${userDetails?.id}&favcryptocurrency=${search}`);
            if (res?.data?.success) {
                const crypto = res?.data?.crypto
                const fiat = res?.data?.fiat
                const newArray = [...crypto, ...fiat]
                setFavCrypto(res?.data?.crypto ? res.data.crypto : [])
                setFavFiat(res?.data?.fiat ? res.data.fiat : [])
                dispatch(getFavFiatAction(res?.data?.fiat ? res.data.fiat : []))
                dispatch(getFavCryptoAction(res?.data?.crypto ? res.data.crypto : []))
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        if (userDetails.id) {
            getFav();
        }
    }, [userDetails.id, showWithdraw, userDetails])

    useEffect(() => {
        if (search) {
            const filterFiat = favFiatDetails.filter(item => item.code.toLowerCase().includes(search.toLowerCase()) || item.name.toLowerCase().includes(search.toLowerCase()))
            setFavFiat(filterFiat)
            const filterCrypto = favCryptoDetails.filter(item => item.code.toLowerCase().includes(search.toLowerCase()) || item.name.toLowerCase().includes(search.toLowerCase()))
            setFavCrypto(filterCrypto)
        } else {
            setFavFiat(favFiatDetails)
            setFavCrypto(favCryptoDetails)
        }
    }, [search])

    // to close the withdraw ui if user clicks on wallet tab from header dropdown
    useEffect(()=> {
        setShowWithdraw(false)
    }, [accountTab])


    return (
        <>
            {
                showWithdraw ? (
                    <WithDraw
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        setShowWithdraw={setShowWithdraw}
                    />
                ) : (
                    <WalletUi
                        setShowWithdraw={setShowWithdraw}
                        favFiat={favFiat}
                        favCrypto={favCrypto}
                        setSearch={setSearch}
                        search={search}
                        isChecked={isChecked}
                        setIsChecked={setIsChecked}
                        userBalance={userBalance}
                        setActiveTab={setActiveTab}
                    />)
            }
        </>
    )
}

export default WalletTab


const WalletUi = ({ setShowWithdraw, favFiat, favCrypto, setSearch, search, setIsChecked, isChecked, userBalance, setActiveTab }) => {
    const dispatch = useDispatch();
    const userDetails = useSelector(state => state.userDetails)
    const { setWalletModalObject, walletModalObject } = useWalletModal()
    const { t } = useTranslation()
    const accountPageLabels = t("accountPageLabels", { returnObjects: true })
    const [query, setQuery] = useSearchParams()
    
    useEffect(() => {
        
        if (query.get("action") == 'wallet' && walletModalObject.isWalletOpen) {
          setWalletModalObject(pre => ({ ...pre, isWalletOpen: true, selectedTab: walletModalObject.selectedTab }))
        }
    }, [query.get("action")])
  
    useEffect(()=>{
        if(query.get("action") == 'wallet' && !walletModalObject.isWalletOpen){
        setQuery({ action: '' })
        }
    },[walletModalObject.isWalletOpen])
   
    return (
        <>
            <div className=" wallet-section">
                <div className='row'>
                    <div className="col-12">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-auto">
                                <div className="add-icon tabsIconHeading">
                                    <img src={union} alt="" className='h-100 w-100' />
                                </div>
                            </div>
                            <div className="col">
                                <div className="add-txt tabsHeadings">
                                {accountPageLabels.WALLET}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-2">
                        <div className="row gap-md-0 gap-3 mt-4 align-items-stretch justify-content-between">
                            <div className="col-md-6 col-lg-7 col-xxl-6 h-100 ">
                                <div className="buttons-div">
                                    <div className="row">
                                        <div className="col-6 brDiv">
                                            <div className="row align-items-center mx-0">
                                                <div className="col-auto ps-1">
                                                    <div className="coin-icon">
                                                        <img src={userBalance?.setCurrencyData?.icon} alt="" className='h-100 w-100' />
                                                    </div>
                                                </div>
                                                <div className="col px-0">
                                                    <div className="button-txt button-right-border">
                                                        <div className="upper-txt pe-1">
                                                            Total Balance
                                                        </div>
                                                        <div className="lower-txt">
                                                            <span className="dollorSymb">{userDetails.fiatStatus ? userDetails?.currency?.symbol : ''}</span> 
                                                            {userBalance?.balance || userBalance?.balance === 0 ? Number(userBalance?.balance)?.toFixed(2) : userDetails?.totalbalance ? Number(userDetails?.totalbalance).toFixed(2) : "0.00"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-4">
                                            <div className="row align-items-center ">
                                                <div className="col px-0">
                                                    <div className="button-txt button-right-border">
                                                        <div className="upper-txt">
                                                            Real Money
                                                        </div>
                                                        <div className="lower-txt">
                                                            $0.01
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-6">
                                            <div className="row align-items-center justify-content-center">
                                                <div className="col-auto px-0">
                                                    <div className="button-txt">
                                                        <div className="upper-txt">
                                                           {accountPageLabels.Bonus}
                                                        </div>
                                                        <div className="lower-txt">
                                                            <span className="dollorSymb">$</span>
                                                            {userBalance?.lockedBonus?.toFixed(2)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 h-100 pe-md-0">
                                <div className="toggle-div">
                                    <div className="label-txt">
                                       {accountPageLabels.Hide_0_balance}
                                    </div>
                                    <div className="form-txt">
                                        <Form>
                                            <Form.Check className='onOffBtn'
                                                // label="Hide 0 balance" 
                                                type="switch"
                                                id="custom-switch 2"
                                                onChange={(e) => setIsChecked(e.target.checked)}
                                            />
                                        </Form>
                                    </div>
                                </div>
                                <div className="search-bar">
                                    <span className='searchIcon'> <img src={magnifyingglass} alt="search" className='h-100 w-100' /></span>
                                    <input
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        type="text" class="form-control formControl shadow-none border-0" id="exampleInputEmail1" placeholder='Search' />
                                </div>
                            </div>
                        </div>
                    </div>
                    {search ? "" : (
                        <div className='col-12'>
                            <div className='sectonMainHeading py-2'>Fiat</div>
                        </div>
                    )}
                    <div className='col-12'>
                        <div className='tableSection table-responsive w-100'>
                            <table className='w-100'>
                                <tbody>
                                    {
                                        favFiat?.map((item) => (
                                            <tr key={item.id} className={`px-3 ${isChecked && (parseFloat(item?.totalBalance) === 0 && parseFloat(item.totalBonus) === 0) ? "d-none" : ""}`}>
                                                <td className='tableBorderBottom '>
                                                    <div className='d-flex align-items-center gap-3 py-2 py-sm-3'>
                                                        <span className='rsIcon'><img src={item.icon} alt="inr" /></span> <span className='tableContent fw-semibold'>{item.code?.toUpperCase()}</span>
                                                    </div>
                                                </td>
                                                <td className='tdWidth tableBorderBottom '>
                                                    <div className='tableContentHeading'>
                                                        <div className='d-flex align-items-center justify-content-end tableContent text-md-center'>
                                                            {/* <span className='ruppyIcon'><img src={rssymbol} alt="rssymbol" /></span> 0.00 */}
                                                            <span className='dollorSymb'>{userDetails.fiatStatus ? userDetails?.currency?.symbol : ""} </span> {item?.totalBalance ? convertToEightDigitsAfterDecimal(item?.totalBalance) : "0.00"}
                                                        </div>

                                                        {userDetails.fiatStatus ? <>
                                                            <div className='tableSubheading'>
                                                                {" "} {item.wallet?.totalBalance ? convertToEightDigitsAfterDecimal(item?.wallet?.totalBalance) : "0.00"}
                                                            </div>
                                                        </> : ""}
                                                    </div>
                                                </td>
                                                <td className='tdWidth tableBorderBottom '>
                                                    <div className='tableContentHeading'>
                                                        <div
                                                            // onClick={() => {
                                                            //     setWalletModalObject((prev) => ({
                                                            //         isWalletOpen: true,
                                                            //         selectedTab: WALLET_MODAL_TABS.FIAT
                                                            //     }))
                                                            //     dispatch(selectFiat(item))
                                                            // }}
                                                            onClick={() => {
                                                                setWalletModalObject((prev) => ({
                                                                  isWalletOpen: true,
                                                                  selectedTab: WALLET_MODAL_TABS.FIAT
                                                                }))
                                                                dispatch(selectFiat(item))
                                                                setQuery({ action: "wallet" })
                                                              }}
                                                            className="depositeHeading tableContent d-flex justify-content-end cursor-pointer">{accountPageLabels.DEPOSIT}</div>
                                                    </div>
                                                </td>
                                                <td className='tdWidth tableBorderBottom '>
                                                    <div 
                                                        style={{fontSize : 14}}
                                                        onClick={() => {
                                                        setShowWithdraw(true)
                                                        dispatch(selectFiat(item))
                                                        setActiveTab("fiat")
                                                    }} className="withDrawHeading tableContent d-flex justify-content-end cursor-pointer">{accountPageLabels.Withdrawals}</div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {search ? "" : (
                        <div className='col-12 mt-2'>
                            <div className='sectonMainHeading py-2'>Crypto Currency</div>
                        </div>
                    )}
                    <div className='col-12'>
                        <div className='tableSection table-responsive w-100'>
                            <table className='w-100'>
                                <tbody>
                                    {favCrypto?.map((item) => (
                                        <tr key={item.id} className={`px-3 ${isChecked && (parseFloat(item?.totalBalance) === 0 && parseFloat(item?.totalBonus) === 0) ? "d-none" : ""}`}>
                                            <td className='tableBorderBottom '>
                                                <div className='d-flex align-items-center gap-3 py-2 py-sm-3'>
                                                    <span className='rsIcon'><img src={item.icon} alt="inr" /></span> <span className='tableContent fw-semibold'>{item.code?.toUpperCase()}</span>
                                                </div>
                                            </td>
                                            <td className='tdWidth tableBorderBottom '>
                                                <div className='tableContentHeading'>
                                                    <div className='d-flex align-items-center justify-content-end tableContent text-md-center'>
                                                    <span className='dollorSymb'>{userDetails.fiatStatus ? userDetails?.currency?.symbol + ' ' : ""} </span> {" "}
                                                        {item?.totalBalance ? convertToEightDigitsAfterDecimal(item?.totalBalance) : "0.00"}
                                                    </div>

                                                    {userDetails.fiatStatus ? <>
                                                        <div className='tableSubheading'>
                                                            {" "} {item.wallet?.totalBalance ? convertToEightDigitsAfterDecimal(item?.wallet?.totalBalance) : "0.00"}
                                                        </div>
                                                    </> : ""}
                                                </div>
                                            </td>
                                            <td className='tdWidth tableBorderBottom '>
                                                <div className='tableContentHeading'>

                                                    <div
                                                        onClick={() => {
                                                            setWalletModalObject((prev) => ({
                                                                isWalletOpen: true,
                                                                selectedTab: WALLET_MODAL_TABS.DEPOSIT
                                                            }))
                                                            dispatch(selectCrypto(item))
                                                            setQuery({ action: "wallet" })
                                                        }}
                                                        className="depositeHeading tableContent d-flex justify-content-end cursor-pointer">{accountPageLabels.DEPOSIT}</div>
                                                </div>
                                            </td>
                                            <td className='tdWidth tableBorderBottom '>
                                                <div 
                                                style={{fontSize : 14}}
                                                    onClick={() => {
                                                    setShowWithdraw(true)
                                                    dispatch(selectCrypto(item))
                                                    setActiveTab("crypto")
                                                }} className="withDrawHeading tableContent d-flex justify-content-end cursor-pointer">{accountPageLabels.Withdrawals}</div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}