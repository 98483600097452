import React from "react";

const VIPClubSvg = () => {
  return (
    <svg
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 3.34586L11.2791 4.67182L11.5006 5.1148C11.8159 5.74617 11.5121 6.52585 10.6958 6.75281C10.6855 6.75624 9.55481 7.05974 9.21143 6.03124L8.88048 5.03709L9.82226 4.09489L7.49829 0.609375L5.17431 4.09489L6.11609 5.03709L5.78514 6.03124C5.57093 6.67296 5.11122 6.88931 4.29649 6.73435C4.20752 6.69101 3.74292 6.67088 3.48006 6.09864C3.33498 5.78357 3.34099 5.42515 3.49595 5.1148L3.71701 4.67223L0 3.34542L1.65838 9.75329H5.55807L7.49829 7.81307L9.4385 9.75329H13.3391L15 3.34586Z"
        fill="#2F93DD"
      />
      <path
        d="M6.35977 10.1925L7.49626 9.05602L8.63274 10.1925L7.49626 11.329L6.35977 10.1925Z"
        fill="#2F93DD"
      />
      <path
        d="M5.55807 10.6324H1.78409V12.3907H7.31628L5.55807 10.6324Z"
        fill="#2F93DD"
      />
      <path
        d="M13.2125 10.6324H9.43848L7.68027 12.3907H13.2125V10.6324Z"
        fill="#2F93DD"
      />
    </svg>
  );
};

export default VIPClubSvg;
