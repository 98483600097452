import React from "react";
import profileUser from "./../../assets/images/profile-2/profile-username.svg";
import profileSecurity from "./../../assets/images/profile-2/profile-security.svg";
import profilePreference from "./../../assets/images/profile-2/profile-preference.svg";
import avatar from "./../../assets/images/profile/avatar.svg";
import Google from "./../../assets/images/profile-2/google.svg";
import Telegram from "./../../assets/images/profile-2/telegram.svg";
import Line from "./../../assets/images/profile-2/line.svg";
import Twitter from "./../../assets/images/profile-2/twitter.svg";
import Steam from "./../../assets/images/profile-2/steam.svg";
import Authentication from "./../../assets/images/profile-2/authentication.svg";
import Alert from "./../../assets/images/profile-2/alert.svg";
import Password from "./../../assets/images/profile-2/password.svg";
import Message from "./../../assets/images/profile-2/message.svg";
import Phone from "./../../assets/images/profile-2/phone.svg";
import Passkey from "./../../assets/images/profile-2/passkey.svg";
import Flag from "./../../assets/images/profile-2/england-flag.svg";
import EditIcon from "./../../assets/images/profile-2/edit-icon.svg";
import { postAPIAuth } from "../../services/apiInstance";
import { useDispatch, useSelector } from "react-redux";
import { errorToaster, succesToaster } from "../../utils/toaster";
import { fetchUserDetails } from "../../services/fetchUserDetails";
import { useAuth } from "../../hooks/useAuth";
import { reducerConst } from "../../constants/storeConstants";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
  username: Yup.string()
    .min(3, "Username must be at least 3 characters")
    .required("Username is required"),
});

const SettingDesktop = () => {
  const userDetails = useSelector((state) => state.userDetails);
  console.log({userDetails},"fhhfyyijdj")
  const { token } = useAuth();
  const dispatch = useDispatch();

  const handleChangeUser = async (username, setSubmitting) => {
    try {
      const res = await postAPIAuth(
        "user/update-username",
        {
          userId: userDetails?.id,
          userName: username,
        },
        token
      );
      if (res?.data?.success) {
        succesToaster("Name Changed Successfully");
        const data = await fetchUserDetails(token);
        if (data.data.success) {
          dispatch({
            type: reducerConst.USER_DETAILS,
            payload: data?.data?.data ? data.data.data : {},
          });
        }
      } else {
        errorToaster(res?.data?.message);
      }
    } catch (error) {
      errorToaster(error?.message);
    } finally {
      setSubmitting(false);
    }
  };

  

  return (
    <>
      <div className="container-fluid profile_section">
        <div className="row">
          <div className="col-xl-3 col-lg-4 col-md-5 col-12 mb-lg-0 mb-4 ps-0">
            <div className="profile-tabs">
              <div
                class="nav flex-column nav-pills  position-sticky top-0  w-100"
                style={{ minWidth: "100%" }}
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <button
                  class="nav-link active"
                  id="v-pills-account-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-account"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-account"
                  aria-selected="true"
                >
                  <div class="d-flex align-items-center gap-2">
                    <div class="dropIcon">
                      <img src={profileUser} alt="" />
                    </div>
                    <div class="DropTxt">Account Info</div>
                  </div>
                </button>
                <button
                  class="nav-link"
                  id="v-pills-security-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-security"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-security"
                  aria-selected="false"
                >
                  <div class="d-flex align-items-center gap-2">
                    <div class="dropIcon">
                      <img src={profileSecurity} alt="" />
                    </div>
                    <div class="DropTxt">Security</div>
                  </div>
                </button>
                <button
                  class="nav-link"
                  id="v-pills-preference-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-preference"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-preference"
                  aria-selected="false"
                >
                  <div class="d-flex align-items-center gap-2">
                    <div class="dropIcon">
                      <img src={profilePreference} alt="" />
                    </div>
                    <div class="DropTxt">Account Preferences</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-8 col-md-7 col-12 pe-0">
            <div class="tab-content" id="v-pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="v-pills-account"
                role="tabpanel"
                aria-labelledby="v-pills-account-tab"
                tabindex="0"
              >
                <div className="profile_box">
                  <div className="profile_account_info bg_main_color  p-4">
                    <div className="profile-main-heading">
                      <h6 className="mb-3">Profile Info</h6>
                    </div>
                    <div className="mt-4 d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center gap-3">
                        <div className="profile-img">
                          <img src={avatar} alt="" className="h-100 w-100" />
                        </div>
                        <div>
                          <h6 className="sub-heading">{userDetails?.username}</h6>
                          {/* <p className="sub-desc mb-0">User ID :123456789</p> */}
                        </div>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="profile-cta-btn"
                          data-bs-toggle="modal"
                          data-bs-target="#EditUsername"
                        >
                          Edit
                        </button>

                        {/* <!-- Modal --> */}
                        <div
                          className="modal fade"
                          id="EditUsername"
                          tabIndex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h1
                                  className="modal-title fs-5"
                                  id="exampleModalLabel"
                                >
                                  Edit Username
                                </h1>
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-body">
                                <Formik
                                  enableReinitialize
                                  initialValues={{
                                    username: userDetails?.username,
                                  }}
                                  validationSchema={validationSchema}
                                  onSubmit={(values, { setSubmitting }) => {
                                    handleChangeUser(
                                      values.username,
                                      setSubmitting
                                    );
                                  }}
                                >
                                  {({ isSubmitting }) => (
                                    <Form>
                                      <div className="mb-3">
                                        <label
                                          htmlFor="username"
                                          className="form-label"
                                        >
                                          Username
                                        </label>
                                        <Field
                                          type="text"
                                          name="username"
                                          className="form-control"
                                          id="username"
                                          placeholder="Enter Username"
                                        />
                                        <ErrorMessage
                                          name="username"
                                          component="div"
                                          className="text-danger"
                                        />
                                      </div>
                                      <div className="d-flex align-items-center justify-content-center">
                                        <button
                                          type="submit"
                                          className="profile-cta-btn"
                                          disabled={isSubmitting}
                                        >
                                          Change Username
                                        </button>
                                      </div>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profile_account_info bg_main_color mt-4  p-4">
                    <div className="profile-main-heading">
                      <h6 className="mb-3">Contact Info</h6>
                    </div>
                    <div className="mt-4 d-flex align-items-center justify-content-between">
                      <div className="contact-desc">
                        <h6>E-mail Verification</h6>
                        <h6>
                          Verify your email address is valid and accessible by
                          you.
                        </h6>
                      </div>
                      <div>
                        <button className="profile-cta-btn">Add</button>
                      </div>
                    </div>
                    <div className="mt-4 d-flex align-items-center justify-content-between">
                      <div className="contact-desc">
                        <h6>Phone Number</h6>
                        <h6>
                          Verify your phone number and you can use the phone as
                          your second login method.
                        </h6>
                      </div>
                      <div>
                        <button className="profile-cta-btn">Add</button>
                      </div>
                    </div>
                  </div>
                  <div className="profile_account_info bg_main_color mt-4  p-4">
                    <div className="profile-main-heading">
                      <h6 className="mb-3">Account Connections</h6>
                    </div>
                    <div className="py-3 d-flex align-items-center justify-content-between connection-box">
                      <div className="d-flex align-items-center gap-3 ">
                        <div className="connection-img d-flex align-items-center justify-content-center">
                          <img src={Google} alt="" className="img-fluid" />
                        </div>
                        <div>
                          <h6 className="sub-heading">Google</h6>
                          <p className="sub-desc mb-0">Not Connected</p>
                        </div>
                      </div>
                      <div>
                        <button className="profile-cta-btn">Connect</button>
                      </div>
                    </div>
                    <div className="py-3 d-flex align-items-center justify-content-between connection-box">
                      <div className="d-flex align-items-center gap-3 ">
                        <div className="connection-img d-flex align-items-center justify-content-center">
                          <img src={Telegram} alt="" className="img-fluid" />
                        </div>
                        <div>
                          <h6 className="sub-heading">Telegram</h6>
                          <p className="sub-desc mb-0">Not Connected</p>
                        </div>
                      </div>
                      <div>
                        <button className="profile-cta-btn">Connect</button>
                      </div>
                    </div>
                    <div className="py-3 d-flex align-items-center justify-content-between connection-box">
                      <div className="d-flex align-items-center gap-3 ">
                        <div className="connection-img d-flex align-items-center justify-content-center">
                          <img src={Line} alt="" className="img-fluid" />
                        </div>
                        <div>
                          <h6 className="sub-heading">Line</h6>
                          <p className="sub-desc mb-0">Not Connected</p>
                        </div>
                      </div>
                      <div>
                        <button className="profile-cta-btn">Connect</button>
                      </div>
                    </div>
                    <div className="py-3 d-flex align-items-center justify-content-between connection-box">
                      <div className="d-flex align-items-center gap-3 ">
                        <div className="connection-img d-flex align-items-center justify-content-center">
                          <img src={Twitter} alt="" className="img-fluid" />
                        </div>
                        <div>
                          <h6 className="sub-heading">Twitter</h6>
                          <p className="sub-desc mb-0">Not Connected</p>
                        </div>
                      </div>
                      <div>
                        <button className="profile-cta-btn">Connect</button>
                      </div>
                    </div>
                    <div className="py-3 d-flex align-items-center justify-content-between connection-box">
                      <div className="d-flex align-items-center gap-3 ">
                        <div className="connection-img d-flex align-items-center justify-content-center">
                          <img src={Steam} alt="" className="img-fluid" />
                        </div>
                        <div>
                          <h6 className="sub-heading">Steam</h6>
                          <p className="sub-desc mb-0">Not Connected</p>
                        </div>
                      </div>
                      <div>
                        <button className="profile-cta-btn">Connect</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="v-pills-security"
                role="tabpanel"
                aria-labelledby="v-pills-security-tab"
                tabindex="0"
              >
                <div className="profile_box nt_info bg_main_color  p-4">
                  <div className="profile-main-heading">
                    <h6 className="mb-3">Security Setup</h6>
                  </div>
                  <div className="p-3">
                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="security-box p-3">
                          <div className="d-flex align-items-center justify-content-between ">
                            <div>
                              <img
                                src={Password}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div>
                              <img src={Alert} alt="" className="img-fluid" />
                            </div>
                          </div>
                          <div className="my-3">
                            <h6 className="sub-heading">Set Password</h6>
                            <p className="sub-desc mb-0">
                              Create your own password for security.
                            </p>
                          </div>
                          <div>
                            <button className="security-btn">
                              Set Password
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="security-box p-3">
                          <div className="d-flex align-items-center justify-content-between ">
                            <div>
                              <img src={Message} alt="" className="img-fluid" />
                            </div>
                            <div>
                              <img src={Alert} alt="" className="img-fluid" />
                            </div>
                          </div>
                          <div className="my-3">
                            <h6 className="sub-heading">Email Verification</h6>
                            <p className="sub-desc mb-0">
                              Verify your email address is valid and accessible
                              by you.
                            </p>
                          </div>
                          <div>
                            <button className="security-btn">
                              Veirfy Email
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="security-box p-3">
                          <div className="d-flex align-items-center justify-content-between ">
                            <div>
                              <img src={Phone} alt="" className="img-fluid" />
                            </div>
                            <div>
                              <img src={Alert} alt="" className="img-fluid" />
                            </div>
                          </div>
                          <div className="my-3">
                            <h6 className="sub-heading">
                              Phone Number Verification
                            </h6>
                            <p className="sub-desc mb-0">
                              Verify your phone number is valid and accessible
                              by you.
                            </p>
                          </div>
                          <div>
                            <button className="security-btn">
                              Veirfy Phone Number
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="security-box p-3">
                          <div className="d-flex align-items-center justify-content-between ">
                            <div>
                              <img
                                src={Authentication}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                            <div>
                              <img src={Alert} alt="" className="img-fluid" />
                            </div>
                          </div>
                          <div className="my-3">
                            <h6 className="sub-heading">
                              Two-factor authentication
                            </h6>
                            <p className="sub-desc mb-0">
                              Enable Two-factor to protect your account from
                              unauthorized access.
                            </p>
                          </div>
                          <div>
                            <button className="security-btn">Enable 2FA</button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mb-3">
                        <div className="security-box p-3">
                          <div className="d-flex align-items-center justify-content-between ">
                            <div>
                              <img src={Passkey} alt="" className="img-fluid" />
                            </div>
                            <div>
                              <img src={Alert} alt="" className="img-fluid" />
                            </div>
                          </div>
                          <div className="my-3">
                            <h6 className="sub-heading">PassKey</h6>
                            <p className="sub-desc mb-0">
                              Using passkey to protect your account from
                              unauthorized access.
                            </p>
                          </div>
                          <div>
                            <button className="security-btn">
                              Enable Passkey
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="v-pills-preference"
                role="tabpanel"
                aria-labelledby="v-pills-preference-tab"
                tabindex="0"
              >
                <div className="profile_box">
                  <div className="profile_account_info bg_main_color  p-4">
                    <div className="profile-main-heading">
                      <h6 className="mb-3">Account Preferences</h6>
                    </div>
                    <div className="mt-4 d-flex align-items-center justify-content-between">
                      <div className="">
                        <h6 className="account-list mb-0">View in currency</h6>
                      </div>
                      <div>
                        <div class="dropdown">
                          <button
                            class="dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img src={Flag} alt="" className="img-fluid pe-1" />{" "}
                            USA{" "}
                            <img
                              src={EditIcon}
                              alt=""
                              className="img-fluid ps-2"
                            />
                          </button>
                          <ul class="dropdown-menu">
                            <li>
                              <a class="dropdown-item" href="#">
                                <img
                                  src={Flag}
                                  alt=""
                                  className="img-fluid pe-1"
                                />{" "}
                                USA
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                <img
                                  src={Flag}
                                  alt=""
                                  className="img-fluid pe-1"
                                />{" "}
                                USA
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                <img
                                  src={Flag}
                                  alt=""
                                  className="img-fluid pe-1"
                                />{" "}
                                USA
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 d-flex align-items-center justify-content-between">
                      <div className="">
                        <h6 className="account-list mb-0">Change Language</h6>
                      </div>
                      <div>
                        <div class="dropdown">
                          <button
                            class="dropdown-toggle py-2"
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            English{" "}
                            <img
                              src={EditIcon}
                              alt=""
                              className="img-fluid ps-2"
                            />
                          </button>
                          <ul class="dropdown-menu">
                            <li>
                              <a class="dropdown-item" href="#">
                                {" "}
                                English
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                {" "}
                                English
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="#">
                                {" "}
                                English
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 d-flex align-items-center justify-content-between">
                      <div className="">
                        <h6 className="account-list mb-0">
                          Show full name of currency in Crypto list
                        </h6>
                      </div>
                      <div>
                        <div class="form-check form-switch">
                          <label class="switch">
                            <input type="checkbox" />
                            <span class="slider"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profile_account_info bg_main_color mt-4  p-4">
                    <div className="profile-main-heading">
                      <h6 className="mb-3">Privacy Preferences</h6>
                    </div>
                    <div className="mt-4 d-flex align-items-center justify-content-between">
                      <div className="">
                        <h6 className="account-list mb-0">
                          Hide my gaming data on profile
                        </h6>
                      </div>
                      <div>
                        <div class="form-check form-switch">
                          <label class="switch">
                            <input type="checkbox" />
                            <span class="slider"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 d-flex align-items-center justify-content-between">
                      <div className="">
                        <h6 className="account-list mb-0">
                          Hide my username from public lists
                        </h6>
                      </div>
                      <div>
                        <div class="form-check form-switch">
                          <label class="switch">
                            <input type="checkbox" />
                            <span class="slider"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 d-flex align-items-center justify-content-between">
                      <div className="">
                        <h6 className="account-list mb-0">
                          Refuse tip from strangers
                        </h6>
                      </div>
                      <div>
                        <div class="form-check form-switch">
                          <label class="switch">
                            <input type="checkbox" />
                            <span class="slider"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 d-flex align-items-center justify-content-between">
                      <div className="">
                        <h6 className="account-list mb-0">Max bet alert</h6>
                      </div>
                      <div>
                        <div class="form-check form-switch">
                          <label class="switch">
                            <input type="checkbox" />
                            <span class="slider"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profile_account_info bg_main_color mt-4  p-4">
                    <div className="profile-main-heading">
                      <h6 className="mb-3">Email Notifications</h6>
                    </div>
                    <div className="mt-4 d-flex align-items-center justify-content-between">
                      <div className="">
                        <h6 className="account-list mb-0">
                          Receive deposit successful email
                        </h6>
                      </div>
                      <div>
                        <div class="form-check form-switch">
                          <label class="switch">
                            <input type="checkbox" />
                            <span class="slider"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 d-flex align-items-center justify-content-between">
                      <div className="">
                        <h6 className="account-list mb-0">
                          Receive withdraw successful email
                        </h6>
                      </div>
                      <div>
                        <div class="form-check form-switch">
                          <label class="switch">
                            <input type="checkbox" />
                            <span class="slider"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profile_account_info bg_main_color mt-4  p-4">
                    <div className="profile-main-heading">
                      <h6 className="mb-3">Email Notifications</h6>
                    </div>
                    <div className="mt-4 d-flex align-items-center justify-content-between">
                      <div className="">
                        <h6 className="account-list mb-0">
                          Receive marketing promotions by Email
                        </h6>
                      </div>
                      <div>
                        <div class="form-check form-switch">
                          <label class="switch">
                            <input type="checkbox" />
                            <span class="slider"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingDesktop;
