import React from 'react'
import NoDataImg from './../../assets/images/no-data-found.svg'
import { useTranslation } from 'react-i18next'

const NoData = () => {
  const { t } = useTranslation()
  const commonText = t('commonText',{ returnObjects: true })

  return (
    <>
      <div className="d-flex flex-column align-items-center justidy-content-center w-100 py-3">
        <img src={NoDataImg} alt="" />
        <div className="txt">{commonText.NoDataAvailable}</div>
      </div>
    </>
  )
}

export default NoData