import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import EMail from "./../../../../assets/images/login-signup/mail.svg";
import Password from "./../../../../assets/images/login-signup/password.svg";
import { useSelector } from "react-redux";
import { USER_LANG } from "../../../../constants";
import { getAPI, postAPI } from "../../../../services/apiInstance";
import { useAuth } from "../../../../hooks/useAuth";
import { useBtAuth } from "../../../../hooks/useBtAuth";
import { errorToaster, succesToaster } from "../../../../utils/toaster";
import { useSearchParams } from "react-router-dom";
import { useAuthModal } from "../../../../hooks/useAuthModal";
import {
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
} from "firebase/auth";
import { auth } from "../../../../config/firebase";
import { useLoginSuccess } from "../../../../hooks/useLoginSuccess";
import LoginToaster from "../../../loginToaster/LoginToaster";
import { toast } from "react-toastify";

const SigninForm = ({ showTab }) => {
  const ipAddress = useSelector((state) => state.ipAddress);
  const [showPassowrd, setShowPassword] = useState(false);
  const [matchedItem, setmatchedItem] = useState([]);
  const [countrycodedata, setCountryCode] = useState([]);
  const [select, setSelect] = useState("");
  const country = useSelector((state) => state.country);
  const { setAuthModalObject } = useAuthModal();
  const { setToken } = useAuth();
  const { setBtToken } = useBtAuth();
  const [query, setQuery] = useSearchParams();
  const [isActive, setIsActive] = useState(false);
  const [showNumber, setShowNumber] = useState(false);
  const { setLoginModalObject } = useLoginSuccess();

  const formik = useFormik({
    initialValues: {
      emailOrPhone: "",
      password: "",
    },
    validationSchema: Yup.object({
      emailOrPhone: Yup.string().required("Email or Phone Number is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values, { resetForm, setSubmitting }) => {
      handleLogin(values, resetForm, setSubmitting);
    },
  });

  const generateRecaptcha = (random) => {
    const recaptchaParent = document.getElementById("recaptcha-parent");
    if (recaptchaParent) {
      recaptchaParent.innerHTML = `<span id=${random} />`;
    }
    window.recaptchaVerifier = new RecaptchaVerifier(auth, `${random}`, {
      size: "invisible",
      callback: (response) => {},
    });
    window.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  };

  const handleSend = (values) => {
    const random = Math.random() + 1000000;
    generateRecaptcha(random);
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(
      auth,
      `${select?.phoneCode ? select.phoneCode : "+91"} ${values.emailOrNumber}`,
      appVerifier
    )
      .then((confirmationResult) => {
        succesToaster("OTP SENT!");
        window.confirmationResult = confirmationResult;
        // setLogOn(false);
        // setPhoneOtp(true);
      })
      .catch((error) => {
        errorToaster(error.message.split("(")[1].split(")")[0].split("/")[1]);
        const recaptchaContainer = document.getElementById(`${random}`);
        if (recaptchaContainer) {
          recaptchaContainer.remove();
        }

        // errorToaster(getFriendlyErrorMessage(error.code))
        // errorToaster(error.message)
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const handleLogin = async (
    { emailOrPhone, password },
    resetForm,
    setSubmitting
  ) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        emailOrPhone,
        password
      );

      const lang = localStorage.getItem(USER_LANG);
      const body = {
        email: emailOrPhone,
        password: password,
        ipAddress: ipAddress ? ipAddress : "",
        country: country ? country : "",
        // language: lang?.symbol
        language: lang,
        isLoginWithEmail: true,
      };

      const res = await postAPI("user/login-user", body);
      if (res?.data?.success) {
        setToken(res?.data?.data?.auth_token);
        setBtToken(res?.data?.data?.betByToken);
        setQuery({ action: "" });
        setAuthModalObject((pre) => ({ ...pre, isAuthOpen: false }));
        succesToaster("You have successfully logged in.",true)
        resetForm();
      } else {
        errorToaster(res?.data?.message);
      }
    } catch (error) {
      errorToaster(error.message);
    } finally {
      setSubmitting(false);
    }
  };
  const CountryCodeFun = async () => {
    const res = await getAPI("get-countries");
    setCountryCode(res.data.data);
    setmatchedItem(res.data.data);
  };

  const handlesearch = (val) => {
    const valdata = val.trim();
    if (valdata.length > 0) {
      const filterdata = countrycodedata?.filter(
        (item) =>
          item.country.toLowerCase().includes(val.toLowerCase()) ||
          item.phoneCode.toLowerCase().includes(val.toLowerCase())
      );
      setmatchedItem(filterdata);
    } else {
      setmatchedItem(countrycodedata);
    }
  };

  useEffect(() => {
    CountryCodeFun();
  }, []);

  function checkThreeDigit(input) {
    if (/^\d{3,10}$/.test(input)) {
      setShowNumber(true);
    } else {
      setShowNumber(false);
    }
  }

  useEffect(() => {
    if (formik.values.emailOrPhone) {
      const result = checkThreeDigit(formik.values.emailOrPhone);
      console.log({ result });
    }
  }, [formik.values.emailOrPhone]);

  return (
    <div
      className={`tab-pane fade     ${
        showTab === "login" ? "show active" : ""
      } `}
      id="pills-Login"
      role="tabpanel"
      aria-labelledby="pills-Login-tab"
      tabIndex="0"
    >
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <div>
          <div
            className={`input-box p-0    dropWrapper

              ${isActive ? "active" : ""}
                    ${
                      formik.touched.emailOrPhone && formik.errors.emailOrPhone
                        ? ""
                        : "mb-3"
                    }  `}
          >
            {showNumber ? null : (
              <img className="form-message-icon" src={EMail} alt="" />
            )}

            <div className="position-relative w-100">
              <input
                className={`  ${showNumber ? "ps-5" : ""} `}
                autoComplete="off"
                type="text"
                name="emailOrPhone"
                placeholder="Enter Your Email/Phone Number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.emailOrPhone}
              />

              {showNumber ? (
                <div
                  onClick={() => setIsActive(true)}
                  className="numberWrapper position-absolute"
                >
                  {select?.phoneCode ? select?.phoneCode : "+91"}
                  <svg
                    className=""
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                  >
                    <path
                      d="M5.02282 5.73994C4.92844 5.74049 4.83488 5.7224 4.74751 5.68671C4.66014 5.65102 4.58067 5.59844 4.51366 5.53198L0.210901 1.22922C0.0758633 1.09418 0 0.911032 0 0.72006C0 0.529089 0.0758633 0.345938 0.210901 0.210901C0.345938 0.0758633 0.529089 0 0.72006 0C0.911032 0 1.09418 0.0758633 1.22922 0.210901L5.02282 4.01167L8.81641 0.218072C8.9536 0.100588 9.13007 0.0391974 9.31055 0.0461687C9.49104 0.05314 9.66224 0.12796 9.78996 0.255676C9.91768 0.383392 9.99249 0.554598 9.99946 0.735082C10.0064 0.915565 9.94505 1.09203 9.82756 1.22922L5.52481 5.53198C5.39123 5.66446 5.21095 5.73915 5.02282 5.73994Z"
                      fill="#ffffff"
                    ></path>
                  </svg>
                </div>
              ) : null}

              {isActive && (
                <>
                  <div
                    className="countryCodeLayer position-fixed vh-100 w-100 top-0 start-0"
                    onClick={() => setIsActive(false)}
                    style={{ zIndex: 97 }}
                  >
                    {" "}
                  </div>
                </>
              )}
              <div className="counteryCodeWrapper">
                <div className="row overflow-y-auto h-100">
                  <div className="col-12 mb-2 position-relative">
                    <input
                      className="commonInp commonInpSearch ps-5"
                      onChange={(e) => handlesearch(e.target.value)}
                      placeholder="Search"
                      type="text"
                    />
                    <div className="searchImg">
                      <svg
                        stroke="currentColor"
                        fill="none"
                        stroke-width="0"
                        viewBox="0 0 15 15"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5ZM9.30884 10.0159C8.53901 10.6318 7.56251 11 6.5 11C4.01472 11 2 8.98528 2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 7.56251 10.6318 8.53901 10.0159 9.30884L12.8536 12.1464C13.0488 12.3417 13.0488 12.6583 12.8536 12.8536C12.6583 13.0488 12.3417 13.0488 12.1464 12.8536L9.30884 10.0159Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </div>
                  </div>
                  <div className="col-12 d-flex flex-column gap-2">
                    {matchedItem?.length &&
                      matchedItem?.map((item) => (
                        <div
                          className="row countryList mx-0 py-2"
                          key={item?._id}
                          onClick={() => {
                            setSelect(item);
                            localStorage.setItem("country_Id", item?._id);
                            setIsActive(false);
                          }}
                        >
                          <div className="col-auto" style={{ width: 70 }}>
                            <div className="numberTxt">{item.phoneCode}</div>
                          </div>
                          <div className="col ps-0">
                            <div className="counteryName">{item.country}</div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {formik.touched.emailOrPhone && formik.errors.emailOrPhone ? (
            <div className=" mb-3  text-danger error">
              {formik.errors.emailOrPhone}
            </div>
          ) : null}
          <div
            className={`input-box  ${
              formik.touched.password && formik.errors.password ? "" : "mb-3"
            }  `}
          >
            <img src={Password} alt="" />
            <input
              autoComplete="off"
              type={showPassowrd ? "text" : "password"}
              name="password"
              placeholder="Enter Your Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />

            <i
              onClick={() => setShowPassword((prv) => !prv)}
              className={`fa-solid  cursor-pointer   ${
                showPassowrd ? "fa-eye" : "fa-eye-slash"
              }   text-white`}
            ></i>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className="  mb-3  text-danger error">
              {formik.errors.password}
            </div>
          ) : null}
          <div className="mb-4">
            <a>
              <h6 className="forgot-password">Forgot your password?</h6>
            </a>
          </div>
          <div>
            <button
              disabled={formik.isSubmitting}
              type="submit"
              className="create-account"
            >
              Log In
            </button>
          </div>
          <div className="mt-4">
            <h6 className="confirm-text">
              By accessing you confirm that you are at least 18 years old and
              agree to the{" "}
              <span>
                {" "}
                <a className="forgot-password">  Terms of service </a>
              </span>{" "}
            </h6>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SigninForm;
