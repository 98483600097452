import React from 'react'
import { useState } from 'react'
import { createContext } from 'react'
import { GAME_OPTION_TABS } from '../constants'

export const GameContext = createContext(null)

const GameContextProvider = ({children}) => {
  const [gameObject, setGameObject] = useState({
    selectedTab: GAME_OPTION_TABS.LOBBY,
    selectedId : ''
  })
  return <GameContext.Provider value={{gameObject, setGameObject}}>{children}</GameContext.Provider>
}

export default GameContextProvider