import React, { useEffect, useState } from "react";
import transactionIcon from "../../../assets/images/profile/withdrawals.png";
import CasinoTransaction from "./transactionComponents/CasinoTransaction";
import SportsTransaction from "./transactionComponents/SportsTransaction";
import { useTranslation } from "react-i18next";
import PokerTransactions from "./transactionComponents/PokerTransactions";
import AllTransaction from "./transactionComponents/AllTransaction";

const TransactionTab = () => {
  const [activeTab, setActiveTab] = useState("all");
  const { t } = useTranslation();
  const accountPageLabels = t("accountPageLabels", { returnObjects: true });
  return (
    <>
      <div className="AffiliateSection">
        <div className="row">
          <div className="col-12">
            <div className="row align-items-center justify-content-center">
              <div className="col-auto">
                <div className="add-icon">
                  <img src={transactionIcon} alt="" className="h-100 w-100" />
                </div>
              </div>
              <div className="col">
                <div className="add-txt">{accountPageLabels.TRANSACTION}</div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div>
              <ul className="d-flex align-items-center gap-3 list-unstyled mt-lg-5 mt-md-4 mt-3 overflow-x-auto text-flex-nowrap">
                <li>
                  <div
                    onClick={() => setActiveTab("all")}
                    className={`affiliateBtn ${
                      activeTab === "all" ? "active" : ""
                    }`}
                  >
                    ALL
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => setActiveTab("casino")}
                    className={`affiliateBtn ${
                      activeTab === "casino" ? "active" : ""
                    }`}
                  >
                    CASINO
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => setActiveTab("sports")}
                    className={`affiliateBtn ${
                      activeTab === "sports" ? "active" : ""
                    }`}
                  >
                    SPORTS
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => setActiveTab("poker")}
                    className={`affiliateBtn ${
                      activeTab === "poker" ? "active" : ""
                    }`}
                  >
                    POKER
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {activeTab === "all" ? (
            <AllTransaction />
          ) : activeTab === "casino" ? (
            <CasinoTransaction />
          ) : activeTab === "sports" ? (
            <SportsTransaction />
          ) : activeTab === "poker" ? (
            <PokerTransactions />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default TransactionTab;
