import React, { useEffect } from "react";

import { useState } from "react";
import Collapse from "react-bootstrap/Collapse";
import Offcanvas from "react-bootstrap/Offcanvas";
import weeklyBouns from "../../assets/images/weeklyBouns.png";
import downArrow from "../../assets/images/down.png";
import topArow from "../../assets/images/topArow.png";
import rightarrow from "../../assets/images/notificationrightarrow.png";
import { useNotification } from "../../hooks/useNotification";
import { useAuth } from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { getAPIAuth } from "../../services/apiInstance";
import System from "./component/System";
import Activities from "./component/Activities";

const Notification = () => {
  const [open, setOpen] = useState(false);
  const [opencollepase2, setOpencollepase2] = useState(false);
  const { isNotificationOpen, setIsNotificationOpen } = useNotification();
  const handleClose = () => setIsNotificationOpen(false);
  const [activeTab, setActiveTab] = useState("system");
  const { token } = useAuth();
  const { t } = useTranslation();
  const notificationModal = t("notificationModal", { returnObjects: true });

  const notificationRead = async () => {
    try {
      const payload = {
        status: true,
      };
      // const res = await postAPIAuth('user/markRead', payload, token)
      // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWJiMzMwZDFkZmIzZWI1NDVkZDhjNDUiLCJyZWZlcl9jb2RlIjoiZGVnZW5XaW4taWFtc1VHRkciLCJpYXQiOjE3MDY3NjcxMTd9.qjzg-mcvDPkNgpn0pLJnCrGgnV2oNd7b2qm8jF8pwws"
      const res = await getAPIAuth("user/markRead", token);
    } catch (error) {}
  };
  useEffect(() => {
    if (isNotificationOpen) {
      notificationRead();
    }
  }, [isNotificationOpen]);

  const collepseBtn1 = () => {
    // setOpen(!open)
  };

  const collepseBtn2 = () => {
    setOpencollepase2(!opencollepase2);
  };

  return (
    <>
      <Offcanvas
        placement="end"
        onHide={handleClose}
        className=" NotificationoffcanvasWrapper NotificationOffcanvas"
        show={isNotificationOpen}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{notificationModal.Notification}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row gap-3">
            {/* <div className="col-12">
              <ul className="d-flex align-items-center notificationBtn gap-2 list-unstyled p-0 m-0">
                <li>
                  <div
                    onClick={() => setActiveTab("system")}
                    className={`tabsBtn ${
                      activeTab === "system" ? "active" : ""
                    }`}
                  >
                    {notificationModal.System_Notice}
                  </div>
                </li>
                <li>
                  <div
                    onClick={() => setActiveTab("activities")}
                    className={`tabsBtn ${
                      activeTab === "activities" ? "active" : ""
                    }`}
                  >
                    {notificationModal.Activities}
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-12 ">
              <div className="contentTabbs">
                <div className="row">
                  <div className="col-12">
                    <div className="notificationCard">
                      <div className="row gap-2">
                        <div className="col-12 mb-2">
                          <div className="time">23/12/2024, 10:56:19</div>
                        </div>
                        <div className="col-12">
                          <div className="titlemain">
                            🏀 Its Weekly Sports Bonus Time! ⚽️
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="WeeklybonusImg">
                            <img
                              src={weeklyBouns}
                              alt=""
                              className="w-100 h-100"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row">
                            <div className="col-10">
                              <div className="peranotif">
                                Weekly Sports bonus has been dropped
                                successfully! 🔥
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="dambleHideTbs">
                            <div className="row">
                              <div className="col-12">
                                <div className="text-end">
                                  <button
                                    className="showBtn text-end"
                                    onClick={collepseBtn1}
                                    aria-controls="example-collapse-text"
                                    aria-expanded={open}
                                  >
                                    Show all{" "}
                                    <span className="icon">
                                      <img src={downArrow} alt="" />
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div className="col-12">
                                <Collapse in={open}>
                                  <div className="row gap-4">
                                    <div className="col-10">
                                      <ul className="d-flex flex-column gap-1 m-0 p-0 list-unstyled mb-4">
                                        <li className="peranotif">
                                          - Wager period Saturday January 6th to
                                          Friday January 12th
                                        </li>
                                        <li className="peranotif">
                                          - Minimum VIP level 22+.
                                        </li>
                                        <li className="peranotif">
                                          - Wager $500 or more = $5 bonus
                                        </li>
                                        <li className="peranotif">
                                          - Wager $2,500 or more = $30 bonus
                                        </li>
                                        <li className="peranotif">
                                          - Wager $5,000 or more = $70 bonus
                                        </li>
                                        <li className="peranotif">
                                          - Wager $10,000 or more = $150 bonus
                                        </li>
                                      </ul>
                                      <div className="peranotif">
                                        Grab your weekly bonus boost every
                                        Saturday with DAMBLE Sports Club!
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="row">
                                        <div className="col">
                                          <div className="peranotif">
                                            DAMBLE
                                          </div>
                                        </div>
                                        <div className="col-auto">
                                          <div className="text-end">
                                            <button
                                              className="showBtn text-end"
                                              onClick={collepseBtn1}
                                              aria-controls="example-collapse-text"
                                              aria-expanded={open}
                                            >
                                              Hide{" "}
                                              <span className="icon">
                                                <img src={topArow} alt="" />
                                              </span>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Collapse>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row align-items-center">
                            <div className="col-9">
                              <div className="titlemain2 pe-4">
                                {" "}
                                Weekly Sports Bonus? What is this? How can I
                                take part?
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="rightarrownoti">
                                <img src={rightarrow} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 ">
              <div className="contentTabbs">
                <div className="row">
                  <div className="col-12">
                    <div className="notificationCard">
                      <div className="row gap-2">
                        <div className="col-12 mb-2">
                          <div className="time">23/12/2024, 10:56:19</div>
                        </div>
                        <div className="col-12">
                          <div className="titlemain">
                            🛑 Update: 10 Free Spins on Sea Boat <br />{" "}
                            Adventure 🛑
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row">
                            <div className="col-10">
                              <div className="peranotif">
                                Due to technical issues with Sea Boat Adventures
                                free rounds,
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="dambleHideTbs">
                            <div className="row">
                              <div className="col-12">
                                <div className="text-end">
                                  <button
                                    className="showBtn text-end"
                                    onClick={collepseBtn2}
                                    aria-controls="example-collapse-text"
                                    aria-expanded={opencollepase2}
                                  >
                                    Show all{" "}
                                    <span className="icon">
                                      <img src={downArrow} alt="" />
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div className="col-12">
                                <Collapse in={opencollepase2}>
                                  <div className="row gap-4">
                                    <div className="col-10">
                                      <ul className="d-flex flex-column gap-1 m-0 p-0 list-unstyled mb-4">
                                        <li className="peranotif">
                                          - Wager period Saturday January 6th to
                                          Friday January 12th
                                        </li>
                                        <li className="peranotif">
                                          - Minimum VIP level 22+.
                                        </li>
                                        <li className="peranotif">
                                          - Wager $500 or more = $5 bonus
                                        </li>
                                        <li className="peranotif">
                                          - Wager $2,500 or more = $30 bonus
                                        </li>
                                        <li className="peranotif">
                                          - Wager $5,000 or more = $70 bonus
                                        </li>
                                        <li className="peranotif">
                                          - Wager $10,000 or more = $150 bonus
                                        </li>
                                      </ul>
                                      <div className="peranotif">
                                        Grab your weekly bonus boost every
                                        Saturday with DAMBLE Sports Club!
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="row">
                                        <div className="col">
                                          <div className="peranotif">
                                            DAMBLE
                                          </div>
                                        </div>
                                        <div className="col-auto">
                                          <div className="text-end">
                                            <button
                                              className="showBtn text-end"
                                              onClick={collepseBtn2}
                                              aria-controls="example-collapse-text"
                                              aria-expanded={opencollepase2}
                                            >
                                              Hide{" "}
                                              <span className="icon">
                                                <img src={topArow} alt="" />
                                              </span>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Collapse>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row align-items-center">
                            <div className="col-9">
                              <div className="titlemain2 pe-4">
                                {" "}
                                Refer to the complete update here
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="rightarrownoti">
                                <img src={rightarrow} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {activeTab === "system" ? (
              <System />
            ) : activeTab === "activities" ? (
              <Activities />
            ) : (
              ""
            )}

            {/* <h2 className="text-white text-center mt-5">Coming Soon</h2> */}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Notification;
