import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import banner from "./../../../../assets/images/home/banner1.png";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { getAPI } from "../../../../services/apiInstance";
import SkeletonLoader from "../../../../components/skeletonLoader/SkeletonLoader";
import { useMediaQuery } from "../../../../utils/useMediaQuery";
import { RESPONSIVE_WIDTH } from "../../../../constants";
import bannersm from "../../../../assets/images/bannerSm.webp";


const DesktopHero = () => {
  const [bannerData, setBannerData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isMdScreen = useMediaQuery(RESPONSIVE_WIDTH.MD_SCREEN);

  const getBannerImage = async () => {
    setIsLoading(true);
    try {
      const res = await getAPI(
        `banner/getBannerByFilter?type=homepage&viewType=desktop`
      );
      if (res?.data?.success) {
        setBannerData(res?.data?.data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getBannerImage();
  }, []);

  console.log({ isMdScreen });
  return (
    <>
      <div className="row herowrapper d-flex gx-3">
        <div className=" col-12">
          {!isLoading ? (
            <Swiper
              slidesPerView={1}
              spaceBetween={10}
              navigation={{
                prevEl: ".prev",
                nextEl: ".next",
              }}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                992: {
                  slidesPerView: 1,
                },
                1200: {
                  slidesPerView: 1,
                },
                1400: {
                  slidesPerView: 1,
                },
              }}
              modules={[Pagination, Navigation]}
              className="mySwiper h-100 overflow-hidden"
              style={{ borderRadius: "15px 15px 0 15px" }}
            >
              {isMdScreen ? (
                <SwiperSlide
                  className="cursor-pointer"
                  //   onClick={() =>
                  //     item?.link ? window.open(item?.link, "_blank") : ""
                  //   }
                >
                  <div className="homebanner w-100 h-auto">
                    {/* <img src={item.image} alt="" /> */}
                    <img className="" src={bannersm} alt="" />
                  </div>
                </SwiperSlide>
              ) : bannerData.length ? (
                bannerData?.map((item) => (
                  <SwiperSlide
                    className="cursor-pointer"
                    //   onClick={() =>
                    //     item?.link ? window.open(item?.link, "_blank") : ""
                    //   }
                  >
                    <div className="homebanner w-100 h-auto">
                      <img src={item.image} alt="" />
                    </div>
                  </SwiperSlide>
                ))
              ) : (
                ""
              )}
              {/* {bannerData.length
                ? bannerData?.map((item) => (
                    <SwiperSlide
                      className="cursor-pointer"
                      onClick={() =>
                        item?.link ? window.open(item?.link, "_blank") : ""
                      }
                    >
                      <div className="homebanner w-100 h-auto">
                        <img src={banner} alt="" />
                      </div>
                    </SwiperSlide>
                  ))
                : ""}
              {bannerData.length
                ? bannerData?.map((item) => (
                    <SwiperSlide
                      className="cursor-pointer"
                      onClick={() =>
                        item?.link ? window.open(item?.link, "_blank") : ""
                      }
                    >
                      <div className="homebanner w-100 h-auto">
                        <img src={banner} alt="" />
                      </div>
                    </SwiperSlide>
                  ))
                : ""} */}
            </Swiper>
          ) : (
            <SkeletonLoader type={"heroBanner"} />
          )}
        </div>
        <div className="col-lg-2 col-12">
          <div className="bg-gradient"></div>
        </div>
        {/* <div className="col-lg-5 col-12 mt-5 mt-lg-0 px-0">
                    <div className="row mx-0 gx-3">
                        <div className="col-6">
                            <div className="pokerBanner">
                                <img src={pokerBanner} alt="" />
                                <div className="textupper">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="pokertextbig d-flex align-items-center">
                                                <div className="poker me-2"><img src={poker} alt="" /></div>
                                                Poker
                                            </div>
                                        </div>
                                        <div className="col-12"><div className="pokerText">Come in, play and win</div></div>
                                    </div>
                                </div>
                                <div className="lowertext">
                                    <Link to='/bonus'><button className="bonusbtn">GET BONUS</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="pokerBanner">
                                <img src={cashbanner} alt="" />
                                <div className="textupper">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="pokertextbig1 pokertextbig">
                                                Cashback up<br/>
                                                to 30% on<br/>
                                                casinos
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="lowertext">
                                <div 
                                    onClick={() => {
                                        setGameObject((prev)=> ({
                                          selectedTab : GAME_OPTION_TABS?.LOBBY
                                        }))
                                        setQuery({ q: "lobby" })
                                        navigate(`${ROUTE_CONST.CASINO}?q=${encodeURIComponent('lobby')}`)
                                      }}
                                ><button className="bonusbtn">GO TO CASINO</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
    </>
  );
};

export default DesktopHero;
