import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useReferralModal } from '../../hooks/useReferralModal';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../hooks/useAuth';
import { getAPI, postAPIAuth } from '../../services/apiInstance';
import { errorToaster, succesToaster } from '../../utils/toaster';
import { reducerConst } from '../../constants/storeConstants';
import { fetchUserDetails } from '../../services/fetchUserDetails';
import { Spinner } from 'react-bootstrap';

const ReferralModal = () => {
  const { isReferral: isOpen, setIsReferral: setIsOpen, isReferralInput, setIsReferralInput } = useReferralModal()
  const [isLoading, setIsLoading] = useState(false)
  const [referral, setReferral] = useState('')
  const [username, setUsername] = useState("")
  const userDetails = useSelector((state) => state.userDetails);
  const { token } = useAuth()
  const dispatch = useDispatch()
  
  
  const onClickHandler = () => {
      if (isReferralInput) {
          if (!!referral && !!username) {
              referralHandler()
              handleChangeUser()
          } else if (!!referral) {
              referralHandler()
          } else if (!!username) {
              handleChangeUser()
          }
      } else {
          if (!!username) {
              handleChangeUser()
          }
      }
      setIsOpen(false)
  }

  const referralHandler = async () => {
      try {
          const res = await getAPI(`user/verifyRefferCode?refferCode=${referral}`)
          if (res.data.success) {
              succesToaster("Referral Code Applied");
              setIsOpen(false)
              setReferral("")
          } else {
              errorToaster(res.data.message)
          }
      } catch (error) {
          errorToaster(error.message)
      } finally {
          setIsLoading(false)
      }
  }

  const handleChangeUser = async () => {
      if (username?.length >= 3) {
          try {
              const res = await postAPIAuth('user/update-username', {
                  userId: userDetails?.id,
                  userName: username
              }, token)
              if (res?.data?.success) {
                  succesToaster('Name Changed Successfully')
                  const data = await fetchUserDetails(token)
                  if (data.data.success) {
                      dispatch({
                          type: reducerConst.USER_DETAILS,
                          payload: data?.data?.data ? data.data.data : {},
                      });
                  }
              } else {
                  errorToaster(res?.data?.message)
              }
          } catch (error) {
              errorToaster(error?.message)
          }
      } else {
      }
  }

  const handleClose = ()=> {
    setIsOpen(false)
  }
  return (
  <>
  <Modal show={isOpen} onHide={handleClose} centered className='modalSection' >
        <Modal.Header closeButton>
          <Modal.Title>Complete Your Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalBody'>
          {
            isReferralInput && (
              <>
                <div className='modalSmallSubHeading mt-2'>
                  Enter Referral/Promo Code
                </div>
                <div className='pb-2'>
                  <input class="commonInp" 
                    type="text" 
                    name="referral" 
                    id="referral" 
                    placeholder="Enter Referral/Promo Code"
                    value={referral}
                    onChange={(e) => setReferral(e.target.value)}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            onClickHandler(event)
                        }

                    }}
                    />
                </div>
              </>
            )
          }
          {/* <div className="mt-2">
            <div className='modalSmallSubHeading '>
              Enter Username
            </div>
            <div>
              <input class="commonInp" 
                type="text" 
                name="userName" 
                id="userName" 
                placeholder="Enter Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        onClickHandler(event)
                    }
                }}
                />
            </div>
          </div> */}
          <div className="mt-3 text-center">
            <button type="submit" onClick={onClickHandler} class="subBtn text-white">
            {
              isLoading ? (
                  <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                      <Spinner animation="border" variant="light" size="sm" />
                  </div>
              ) : (
                  "Play now!"
              )
            }
            </button>
          </div>
        </Modal.Body>

      </Modal>
  </>
  )
}

export default ReferralModal