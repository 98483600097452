import React, { useEffect, useState } from 'react'
import deposit from './../../../assets/images/profile/deposits.png'


import depositedTabs from '../../../assets/images/profile/depositedTabs.png';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAPIAuth } from '../../../services/apiInstance';
import { useAccount } from '../../../hooks/useAccount';
import { ACCOUNT_TABS } from '../../../constants';
import { Spinner } from 'react-bootstrap';
import moment from 'moment/moment';


export const DepositeTab = () => {
    const { accountTab} = useAccount()
    const [cryptoList, setCryptoList] = useState([])
    const [activeCrypto, setActiveCrypto] = useState('ALL')
    const [deposits, setDeposits] = useState([])
    const [transactionId, setTransactionId] = useState('')
    const [loader, setLoader] = useState(false)
    const [listLoader, setListLoader] = useState(true)
    const [showTransaction, setShowTransaction] = useState(false)
    const userDetails = useSelector((state) => state.userDetails);
    const userId = userDetails?.id;
    const userBalance = userDetails?.totalbalance;
    const { t } = useTranslation()
    const accountPageLabels = t("accountPageLabels", { returnObjects: true })
    const dgnLabels = t("dgnLabels", { returnObjects: true })
    const [pagination, setPagination] = useState({
      btnOne: 1,
      btnTwo: 2,
      btnThree: 3,
      btnFour: "...",
      btnFive: 0
    })
    const [pageNo, setPageNo] = useState(1)
    const [isLoadMore, setIsLoadMore] = useState(false)
    const [totalPages, setTotalPage] = useState()
  
    const paginationHandler = (count) => {
      if (pagination.btnOne != pageNo) {
        if (pageNo - 1 == pagination.btnThree) {
          setPagination(
            prev => ({
              ...prev,
              btnOne: pagination.btnOne + 1,
              btnTwo: pagination.btnTwo + 1,
              btnThree: pagination.btnThree + 1
            })
          )
        } else if (pageNo + 1 == pagination.btnOne) {
          setPagination(
            prev => ({ ...prev, btnOne: pagination.btnOne - 1, btnTwo: pagination.btnTwo - 1, btnThree: pagination.btnThree - 1 })
          )
        } else if (pageNo == totalPages) {
          setPagination(
            prev => ({ ...prev, btnOne: totalPages - 2, btnTwo: totalPages - 1, btnThree: totalPages })
          )
        }
      }
    }
  
  
    const loadMore = async () => {
      try {
        const res = await getAPIAuth(`user/deposit-transaction-details?userId=${userId}&transactionId=${transactionId}&chain=${activeCrypto == 'ALL' ? "" : activeCrypto}&page=${pageNo}`)
  
        // const res = await getAPIAuth(`user/userTransactions/${"64be2d4fcb1b31b159cc6378"}`)
        if (res.data.success) {
          // setTransactionData(res.data.data)
          setDeposits(res?.data?.data)
          setTotalPage(res.data.pagination?.[0]?.totalPages)
        }
      } catch (error) {
        //error handling
      }
  
    }
  
    useEffect(() => {
      if (userDetails?.id && (accountTab?.selectedTab === ACCOUNT_TABS.DEPOSIT)) {
        if (pageNo >= 1 && isLoadMore) {
          paginationHandler()
          loadMore()
          setIsLoadMore(false)
        } else if (pageNo >= 1) {
          paginationHandler()
          loadMore()
        }
      }
    }, [pageNo, userDetails?.id, accountTab?.selectedTab])
  
    // getting all crypto id
    const getCryptoList = async () => {
      setListLoader(true)
      try {
        const res = await getAPIAuth(`crypto/get-crypt-favorite-by-admin`)
        if (res?.data?.success) {
          setCryptoList(res?.data?.data)
          setActiveCrypto("ALL")
        }
      } catch (error) {
  
      } finally {
        setListLoader(false)
      }
    }
  
    useEffect(() => {
      getCryptoList()
    }, [])
  
  
    const getDeposits = async () => {
      setLoader(true)
      try {
        if (userId) {
          const res = await getAPIAuth(`user/find-transaction?userId=${userId}&chain=eth&type=deposit&transactionId=${transactionId}`)
          if (res?.data?.success) {
            setDeposits(res?.data?.data)
          } else {
            setDeposits([])
          }
        }
      } catch (error) {
  
      } finally {
        setLoader(false)
      }
    }
  
    const newDeposit = async () => {
      setLoader(true)
  
      try {
        if (userId) {
          const res = await getAPIAuth(`user/deposit-transaction-details?userId=${userId}&transactionId=${transactionId}&chain=${activeCrypto == 'ALL' ? "" : activeCrypto}&page=${pageNo}`)
          if (res?.data?.success) {
            setDeposits(res?.data?.data)
            setTransactionId("")
            setTotalPage(res.data.pagination?.[0]?.totalPages)
            setPagination({
              btnOne: 1,
              btnTwo: 2,
              btnThree: 3,
              btnFour: "...",
              btnFive: res.data.pagination?.[0]?.totalPages
            })
          } else {
            setDeposits([])
          }
        }
      } catch (error) {
  
      } finally {
        setLoader(false)
      }
    }
    useEffect(() => {
      // getDeposits()
      if (accountTab?.selectedTab === ACCOUNT_TABS.DEPOSIT) {
        if (activeCrypto) {
          newDeposit()
        }
      }
    }, [userId, activeCrypto, accountTab?.selectedTab])
  
    // getting data from transaction id
    const getDataFromTransactionId = () => {
      newDeposit()
    }
  
    // for set  active crypto
    const handleActiveCrypto = (cryptoName) => {
      setActiveCrypto(cryptoName)
    }
    return (
        <>
           <div className='setting  space'>
        <div className='d-flex align-items-center'>
            <div className='imgIcon'>
                <img src={deposit} alt="" className='h-100 w-100' />
            </div>
            <div className='set-text ms-3'>
            {accountPageLabels.Deposits}
            </div>
           </div>
           <div className='d-flex mt-5 pb-5 gap-2 flex-nowrap overflow-y-auto align-items-center'>
            
           {!listLoader ?
              cryptoList?.length > 0 ?
              <>
            <button className={`cbtn ${"ALL" === activeCrypto ? 'gBtn' : ''}`} onClick={() => handleActiveCrypto("ALL")}>{accountPageLabels.ALL} {dgnLabels.Chain}</button>
                {
                    cryptoList?.map((item) => (
                      <button
                        key={item?.code}
                        className={`cbtn text-uppercase ${item?.code === activeCrypto ? 'gBtn' : ''}`}
                        onClick={() => handleActiveCrypto(item?.code)}
                      >
                        {item?.code}
                      </button>
                    ))
                }
            </>
            : ''
              : (
                <>
                  {/* <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="light" size="lg" />
                </div> */}
                </>
              )
            }
           </div>
           {!loader ?
              deposits?.length > 0 ?
            <div className="col-12 px-0 betTable">
                <div className="table-responsive">
                    <table className='table mb-0'>
                    <thead>
                        <tr>
                        <th>{accountPageLabels.COIN}</th>
                        <th className=''>{accountPageLabels.AMOUNT}</th>
                        <th className=''>{accountPageLabels.DATE_TIME}</th>
                        <th className=''>{accountPageLabels.CREDIT_AMOUNT}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deposits?.map((item) => (
                            <tr key={item?._id}>
                                <td className=' text-white'>
                                    {item?.coin ? item?.coin : item?.currency ? item?.currency : "----"}
                                </td>
                                <td className=' text-white'>
                                    {item?.amount ? Number(item.amount).toFixed(2) : '0.00'}
                                </td>
                                <td className=' text-white'>
                                    {moment(item.createdAt).format("MMM DD YYYY , HH:mm")}
                                </td>
                                <td className=' text-white'>
                                    {item?.amountInUsdt ? Number(item?.amountInUsdt).toFixed(4) : item?.amount ? Number(item?.amount).toFixed(4) : '0.00'}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
            </div>
            
            : (
                <div className="d-flex justify-content-center my-5">
                <button className='dataBtn'>
                {accountPageLabels.NO_ENTRIES}
                </button>
            </div>
              )
            : (<div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="light" size="sm" />
            </div>)
          }

        </div>  
        </>
    )
}
