import CasinoBanner from "../../assets/images/affilate/casino-banner.svg";
const DownloadBanners = () => {
  return (
    <div
      class="tab-pane fade"
      id="nav-banner"
      role="tabpanel"
      aria-labelledby="nav-banner-tab"
    >
      <div>
        <div className="bg_main_color">
          <div>
            <div className="row">
              <div className="col-md-6 mb-3 mb-md-0">
                <div className="download-banner-box">
                  <div className="download-banner-image">
                    <img
                      src={CasinoBanner}
                      alt=""
                      className="img-fluid w-100"
                    />
                  </div>
                  <div className="download-banner-content">
                    <div>
                      <h6 className="banner-heading">Banner Pack</h6>
                    </div>
                    <p className="banner-content">
                      Multilingual Package including events, animated banners,
                      and more...
                    </p>
                    <div>
                      <button className="banner-button">
                        Download ( <span>200MB</span> ){" "}
                        <svg
                          stroke="currentColor"
                          fill="none"
                          stroke-width="2"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          height="18px"
                          width="18px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                          ></path>
                        </svg>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3 mb-md-0">
                <div className="download-banner-box">
                  <div className="download-banner-image">
                    <img
                      src={CasinoBanner}
                      alt=""
                      className="img-fluid w-100"
                    />
                  </div>
                  <div className="download-banner-content">
                    <div>
                      <h6 className="banner-heading">Banner Pack</h6>
                    </div>
                    <p className="banner-content">
                      Multilingual Package including events, animated banners,
                      and more...
                    </p>
                    <div>
                      <button className="banner-button">
                        Download ( <span>200MB</span> ){" "}
                        <svg
                          stroke="currentColor"
                          fill="none"
                          stroke-width="2"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          height="18px"
                          width="18px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                          ></path>
                        </svg>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadBanners;
