import { useContext } from "react"
import { AccountContext } from "../context/AccountContext"


export const useAccount = () => {
    const { accountTab, setAccountTab} = useContext(AccountContext)
    return {
        accountTab, 
        setAccountTab
    }
}