import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import ErrorMessage from "./../../../common/errorMessage/ErrorMessage";
import { useAuth } from "../../../../hooks/useAuth";
import { useAuthModal } from "../../../../hooks/useAuthModal";
import * as Yup from "yup";
import { signInWithEmailAndPassword } from "firebase/auth";
import { postAPI } from "../../../../services/apiInstance";
import { auth } from "../../../../config/firebase";
import { errorToaster, succesToaster } from "../../../../utils/toaster";
import { useSelector } from "react-redux";
import { USER_LANG } from "../../../../constants";
import { Spinner } from "react-bootstrap";
import { useBtAuth } from "../../../../hooks/useBtAuth";
import signupBtn from './../../../../assets/images/signInBtn.png'
import eye from './../../../../assets/images/eye.png'
import { useSearchParams } from "react-router-dom";

const validationSchema = Yup.object({
  password: Yup.string().required("This field is required"),
});

const LoginPassword = ({
  setEmailPassword,
  setLogOn,
  emailOrNumber,
  setForgetpass,
  emailPassword,
  browserName
}) => {
  const { setToken } = useAuth();
  const { authModalObject, setAuthModalObject } = useAuthModal();
  const [isHidden, setIsHidden] = useState(true);
  const ipAddress = useSelector((state) => state.ipAddress);
  const country = useSelector((state) => state.country);
  const [isLoading, setIsLoading] = useState(false);
  const { setBtToken } = useBtAuth()
  const [query, setQuery] = useSearchParams()


  useEffect(() => {
    if (!authModalObject.isAuthOpen) {
      setEmailPassword(false);
      setLogOn(true);
    }
  }, [authModalObject.isAuthOpen]);

  async function signInWEAP(values) {
    try {
      if (emailOrNumber) {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          emailOrNumber,
          values.password
        );

        // Signed in
        // const lang = JSON.parse(localStorage.getItem(USER_LANG))
        const lang = localStorage.getItem(USER_LANG);
        const body = {
          email: emailOrNumber,
          password: values.password,
          ipAddress: ipAddress ? ipAddress : "",
          country: country ? country : "",
          // language: lang?.symbol
          language: lang,
          isLoginWithEmail: true,
          browser: browserName,
        };
        const user = userCredential.user.auth.email;
        const res = await postAPI("user/login-user", body);
        if (res?.data?.success) {
          setToken(res?.data?.data?.auth_token);
          setBtToken(res?.data?.data?.betByToken)
          succesToaster("Login successful");
          setEmailPassword(false);
          setLogOn(true);
          setQuery({ action: "" })
          setAuthModalObject((pre) => ({ ...pre, isAuthOpen: false }));
        } else {
          errorToaster(res?.data?.message);
        }
      }
    } catch (error) {
      errorToaster(error.message);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    if (!emailPassword) {
      setIsLoading(false);
    }
  }, [emailPassword]);
  return (
    <>      <Formik
    initialValues={{
      emailOrNumber: "",
    }}
    validationSchema={validationSchema}
    onSubmit={(values) => {
      setIsLoading(true);
      signInWEAP(values);
    }}
  >
    {({
      values,
      handleBlur,
      handleChange,
      handleSubmit,
      errors,
      touched,
    }) => (
      <form>
        <div className="col-12 position-relative">
            <input className='commonInp' 
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange} 
                    type={isHidden ? 'password' : 'text'} 
                    name="password" 
                    id="password1" 
                    placeholder='Login Password' />
            <div className="eyeImg" onClick={()=>setIsHidden(!isHidden)}>
              {
                isHidden ? 
                  <img src={eye} alt="" />
                  : 
                  <svg fill="#B2B6C5" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" stroke-width="0"><path d="M432 448a15.92 15.92 0 0 1-11.31-4.69l-352-352a16 16 0 0 1 22.62-22.62l352 352A16 16 0 0 1 432 448zM248 315.85l-51.79-51.79a2 2 0 0 0-3.39 1.69 64.11 64.11 0 0 0 53.49 53.49 2 2 0 0 0 1.69-3.39zm16-119.7L315.87 248a2 2 0 0 0 3.4-1.69 64.13 64.13 0 0 0-53.55-53.55 2 2 0 0 0-1.72 3.39z"></path><path d="M491 273.36a32.2 32.2 0 0 0-.1-34.76c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.68 96a226.54 226.54 0 0 0-71.82 11.79 4 4 0 0 0-1.56 6.63l47.24 47.24a4 4 0 0 0 3.82 1.05 96 96 0 0 1 116 116 4 4 0 0 0 1.05 3.81l67.95 68a4 4 0 0 0 5.4.24 343.81 343.81 0 0 0 67.24-77.4zM256 352a96 96 0 0 1-93.3-118.63 4 4 0 0 0-1.05-3.81l-66.84-66.87a4 4 0 0 0-5.41-.23c-24.39 20.81-47 46.13-67.67 75.72a31.92 31.92 0 0 0-.64 35.54c26.41 41.33 60.39 76.14 98.28 100.65C162.06 402 207.92 416 255.68 416a238.22 238.22 0 0 0 72.64-11.55 4 4 0 0 0 1.61-6.64l-47.47-47.46a4 4 0 0 0-3.81-1.05A96 96 0 0 1 256 352z"></path></svg>
              }
            </div>
        </div>
        
        <ErrorMessage>
                  {errors.password && touched.password && errors.password}
                </ErrorMessage>
                <div className="col-12 mx-auto mt-3">
              <button
                onClick={handleSubmit}
                className="signUpBtn"
                type="submit"
              >
                {
                  isLoading ? <Spinner size="sm" variant="light" /> : 
                  <img src={signupBtn} alt="" /> 
                }
            </button>
          </div>
          </form>
        )}
      </Formik>
    </>
  )
}

export default LoginPassword