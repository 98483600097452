import React, { useEffect, useRef, useState } from 'react'
import { POKER_OPERATOR_ID, RESPONSIVE_WIDTH, USER_LANG } from '../../constants'
import { useParams } from 'react-router-dom'
import i18n from 'i18next';
import { useSelector } from 'react-redux'
import { useAuth } from '../../hooks/useAuth'
import { useMediaQuery } from '../../utils/useMediaQuery';
import RealAndFunModal from './RealAndFunModal';

const Poker = () => {
    const [demo, setDemo] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(true)
    const lang = localStorage.getItem(USER_LANG)
    const [language, setLanguage] = useState(lang)
    const { pokerId } = useParams()
    const isSmScreen = useMediaQuery(RESPONSIVE_WIDTH.SM_SCREEN)
    const userDetails = useSelector(state => state.userDetails)
    const [url, setUrl] = useState()
    const [showIframe, setShowIframe] = useState(false)
    const iframeRef = useRef(null)
    const [lgLoader, setLgLoader] = useState(true)
    const { token } = useAuth()
    const userBalance = useSelector(state => state.userBalance)
    // const [farzi, setFarzi] = useState(false)
    let timeout
    let timeoutLoader

    useEffect(() => {
        if (!token) {
            setIsModalOpen(true)
            setUrl('')
        }
    }, [token])

    const lgLoaderHandler = (seconds = 3000) => {
        setTimeout(() => {
            setLgLoader(false)
        }, seconds)
    }



    // for production build

    useEffect(() => {
        if (!isModalOpen) {
            setUrl(`https://games.damble.io/authorization.php?partnerId=${POKER_OPERATOR_ID}&gameId=${"28"}&openType=real&language=${language}&devicetypeid=${isSmScreen ? 2 : 1}&token=${userDetails?.id}&isMobile=${isSmScreen}`)

        }else{
            setUrl("")
        }
    }, [isModalOpen, language, ])

    useEffect(() => {
        if (!isModalOpen) {
            setIsModalOpen(true)
        }
    }, [userBalance.setCurrencyData?.code, userDetails?.fiatStatus])

    useEffect(() => {
        const handleLanguageChange = () => {
            // Handle language change here
            const currentLanguage = i18n.language;
            setLanguage(currentLanguage)
        };

        // Add an event listener for language changes
        i18n.on('languageChanged', handleLanguageChange);

        // Clean up the event listener on component unmount
        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []); // Empty dependency array, so this effect runs only once on mount
    useEffect(() => {
        setLgLoader(true)
        if (timeoutLoader) {
            clearTimeout(timeoutLoader)
        }
        timeoutLoader = setTimeout(() => {
            setLgLoader(false)
        }, 3000)
        return () => {
            clearTimeout(timeoutLoader)
        }
    }, [language, userBalance.setCurrencyData?.code, userDetails?.fiatStatus])

    useEffect(() => {
        if (timeout) {
            clearTimeout(timeout)
        }
        timeout = setTimeout(() => {
            setShowIframe(true)
        }, 4000)

        return () => {
            clearTimeout(timeout)
        }
    }, [])

    useEffect(() => {
        if (isSmScreen && !isModalOpen) {
            const iframe = iframeRef.current;
            if (iframe) {
                if (iframe?.requestFullscreen) {
                    iframe?.requestFullscreen();
                } else if (iframe?.mozRequestFullScreen) {
                    iframe?.mozRequestFullScreen();
                } else if (iframe?.webkitRequestFullscreen) {
                    iframe?.webkitRequestFullscreen();
                } else if (iframe?.msRequestFullscreen) {
                    iframe?.msRequestFullscreen();
                }
            }
        }
    }, [isModalOpen])
    return (
        <>
            
            <div className="container-fluid holdwinHero gamesHeroSection pb3 p-0 h-100">
                                <div className="row pt3 h-100">
                                    <>
                                        <div className="col-12 px-0 position-relative h-100">
                                            <div className="iframeMain bg-black rounded-2 overflow-hidden position-relative h-100">
                                                {
                                                    !isModalOpen && lgLoader ? (
                                                        <div
                                                            // className="vh-100 vw-100 iframeLoader position-fixed d-flex align-items-center justify-content-center top-0 start-0"
                                                            className="game-iframe w-100 h-100"
                                                        >
                                                            {/* <img src="assets/img/gameLoader.gif" alt="" className="h-100 w-100 object-fit-contain" /> */}
                                                            <video src="assets/img/gameLoader.mp4" className="h-100 w-100 object-fit-contain" autoPlay muted loop ></video>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }

                                                <iframe
                                                    title="game iframe"
                                                    className="game-iframe w-100 h-100"
                                                    allow="autopaly fullscreen"
                                                    allowFullScreen
                                                    src={isSmScreen ? "" : url}
                                                />

                                                {
                                                    isModalOpen ? (
                                                        <RealAndFunModal
                                                            // setDemo={setDemo}
                                                            setShowOptionModal={setIsModalOpen}
                                                            lgLoaderHandler={lgLoaderHandler}
                                                        />
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                        </div>

                                        {
                                            isSmScreen ? (<>
                                                {/* '{
                                                    !showIframe && !isModalOpen ?
                                                        (
                                                        <div className="vh-100 vw-100 iframeLoader position-fixed d-flex align-items-center justify-content-center top-0 start-0">
                                                            <video
                                                                src="assets/img/gameLoader.mp4"
                                                                className="h-100 w-100 object-fit-contain"
                                                                style={{ mixBlendMode: 'lighten' }}
                                                                autoPlay muted loop ></video>
                                                        </div>
                                                        ) : (
                                                            ''
                                                        )
                                                }' */}
                                                <div className={`modal fade p-0 show d-block gameModalScreen ${showIframe ? '' : 'invisible'}`}
                                                    id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-fullscreen">
                                                        <div className="modal-content p-0" >
                                                            <div className="modal-body p-0" style={{ background: '#0d131c' }}
                                                            >
                                                                <div className="iframeMain h-100 bg-black position-relative overflow-hidden">
                                                                    <iframe
                                                                        ref={iframeRef}
                                                                        className="game-iframe w-100 h-100"
                                                                        src={isSmScreen ? url : ""}
                                                                        allow="autopaly fullscreen"
                                                                        allowFullScreen
                                                                        title="game mobile iframe"
                                                                        style={{ backgroundColor: '#0d131c' }}
                                                                    />
                                                                    {
                                                                        isModalOpen ? <RealAndFunModal setShowOptionModal={setIsModalOpen} /> : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            ) : (
                                                <></>
                                            )
                                        }
                                    </>

                                    {/* ) : (
                                            <>
                                                <div className="col-12 ">
                                                    <div className="blinkLayer">
                                                        <div className="msgText">{"Please Login"}</div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    } */}

                                </div>
                            </div>
        </>
    )
}

export default Poker

