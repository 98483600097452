import React, { useEffect, useState } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useAuth } from '../../../hooks/useAuth';
import { errorToaster, succesToaster } from '../../../utils/toaster';
import { useSelector } from 'react-redux';
import { getAPIAuth, postAPIAuth } from '../../../services/apiInstance';

const StackingEarning = () => {
  const [data, setData] = useState({})
  const {token} = useAuth()
  const userDetails = useSelector(state => state.userDetails)
  const userBalance = useSelector(state => state.userBalance)


  
  
  const getEarnings = async ()=> {
    try {
      const res = await getAPIAuth(`crypto/staking-earnings?sel_curr=${userDetails?.fiatStatus ? userDetails?.usercurrency : ''}`, token)
      if(res?.data?.success) {
        setData(res?.data?.data)
      }
    } catch (error) {
      
    }
  }

  useEffect(()=> {
    if (token) {
      getEarnings()
    }
  }, [token, userDetails, userBalance?.balance])


  const claimDailyEarning = async ()=> {
    const params = {}
    try {
      const res = await postAPIAuth('crypto/claim-staking', params, token)
      if(res?.data?.success) {
        succesToaster('Stacking earning claimed successfully')
      } else {
        errorToaster('Oops! you have nothing to claim')
      }
    } catch (error) {
      errorToaster('Oops! something wrong has happened')
    }
  }




  return (
    <>
      <div className="row my-4 g-3">
        <div className="col-12">
          <div className="earningBox greenBg h-100">
            <div className="CommonTitle text-center text-lg-start">My staking earnings</div>
            <div className="row row-cols-md-3 row-cols-2 g-3 g-md-4">
              <div className="col">
                <div className="sText d-flex align-items-center">
                  Est. Positions
                  <div className="dropdown tooltipDropdown">
                    <button className='border-0 p-0 shadow-none bg-transparent dropdown-toggle' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img className='ms-2 icon' src="./assets/img/stacking/question.png" alt="" />
                    </button>
                    <div className="dropdown-menu">
                      Estimated value of positions participating in staking.
                    </div>
                  </div>
                </div>
                <div className="sTextBold">{userDetails?.fiatStatus ? userDetails?.currency?.symbol : '$'} {data?.est_position ? Number(data?.est_position)?.toFixed(2) : '0.00'}</div>
              </div>
              <div className="col">
                <div className="sText d-flex align-items-center">
                  Total Profit
                  <div className="dropdown tooltipDropdown">
                    <button className='border-0 p-0 shadow-none bg-transparent dropdown-toggle' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img className='ms-2 icon' src="./assets/img/stacking/question.png" alt="" />
                    </button>
                    <div className="dropdown-menu">
                      All-time profit from staking across all types of staking.
                    </div>
                  </div>
                </div>
                <div className="sTextBold">{userDetails?.fiatStatus ? userDetails?.currency?.symbol : '$'} {data?.total_profit ? Number(data?.total_profit)?.toFixed(2) : '0.00'}</div>
              </div>
              <div className="col-md col-12 order1">
                <div className="sText d-flex align-items-center justify-content-center justify-content-md-start">
                  Available for claim
                  <div className="dropdown tooltipDropdown">
                    <button className='border-0 p-0 shadow-none bg-transparent dropdown-toggle' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img className='ms-2 icon' src="./assets/img/stacking/question.png" alt="" />
                    </button>
                    <div className="dropdown-menu">
                      Total profit across all the staking positions that is available for claim.
                    </div>
                  </div>
                </div>
                <div className="sTextBold green text-center text-md-start">{userDetails?.fiatStatus ? userDetails?.currency?.symbol : '$'} {data?.avl_claims ? Number(data?.avl_claims)?.toFixed(2) : '0.00'}</div>
              </div>
              <div className="col">
                <div className="sText d-flex align-items-center">
                  Monthly Profit
                  <div className="dropdown tooltipDropdown">
                    <button className='border-0 p-0 shadow-none bg-transparent dropdown-toggle' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img className='ms-2 icon' src="./assets/img/stacking/question.png" alt="" />
                    </button>
                    <div className="dropdown-menu">
                      Profit during the last 30 days.
                    </div>
                  </div>
                </div>
                <div className="sTextBold"> {userDetails?.fiatStatus ? userDetails?.currency?.symbol : '$'} {data?.monthly_profit ? Number(data?.monthly_profit)?.toFixed(2) : '0.00'}</div>
              </div>
              <div className="col">
                <div className="sText d-flex align-items-center">
                  Last Day Profit
                  <div className="dropdown tooltipDropdown">
                    <button className='border-0 p-0 shadow-none bg-transparent dropdown-toggle' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img className='ms-2 icon' src="./assets/img/stacking/question.png" alt="" />
                    </button>
                    <div className="dropdown-menu">
                      Profit during the previous day.
                    </div>
                  </div>
                </div>
                <div className="sTextBold"> {userDetails?.fiatStatus ? userDetails?.currency?.symbol : '$'} {data?.last_day_profit ? Number(data?.last_day_profit)?.toFixed(2) : '0.00'}</div>
              </div>
              <div className="col-md order2 col-12 d-flex align-items-end">
                <button onClick={claimDailyEarning} className='commonBlueBtn small w-100'>Claim All</button>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-5 col-xxl-4 mt-4 mt-lg-3">
          <div className="earningBox h-100">
            <div className="earnBox">EARN UP TO 15%</div>
            <div className="d-flex align-items-start justify-content-between w-100">
              <div className="CommonTitle">My staking earnings</div>
              <div className="moreTxt sText mt-2 ">More details</div>
            </div>
            <div className="row row-cols-2 g-4">
              <div className="col">
                <div className="sText d-flex align-items-center">
                  Total referrals
                  <div className="dropdown tooltipDropdown">
                    <button className='border-0 p-0 shadow-none bg-transparent dropdown-toggle' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img className='ms-2 icon' src="./assets/img/stacking/question.png" alt="" />
                    </button>
                    <div className="dropdown-menu">
                      Total number of your referrals within crypto staking
                    </div>
                  </div>
                </div>
                <div className="sTextBold">$0.00</div>
              </div>
              <div className="col">
                <div className="sText d-flex align-items-center">
                  Available for claim
                  <div className="dropdown tooltipDropdown">
                    <button className='border-0 p-0 shadow-none bg-transparent dropdown-toggle' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img className='ms-2 icon' src="./assets/img/stacking/question.png" alt="" />
                    </button>
                    <div className="dropdown-menu">
                      Total profit from crypto referral program that is available for claim
                    </div>
                  </div>
                </div>
                <div className="sTextBold green">${data?.avl_claims ? Number(data?.avl_claims)?.toFixed(2) : '0.00'}</div>
              </div>
              <div className="col">
                <div className="sText d-flex align-items-center">
                  Total profit
                  <div className="dropdown tooltipDropdown">
                    <button className='border-0 p-0 shadow-none bg-transparent dropdown-toggle' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img className='ms-2 icon' src="./assets/img/stacking/question.png" alt="" />
                    </button>
                    <div className="dropdown-menu">
                      Total profit earned from your referrals within crypto staking
                    </div>
                  </div>
                </div>
                <div className="sTextBold">${data?.total_profit ? Number(data?.total_profit)?.toFixed(2) : '0.00'}</div>
              </div>
              <div className="col d-flex align-items-end">
                <button className='commonBlueBtn small w-100'>Claim All</button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default StackingEarning