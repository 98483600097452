import React, { useEffect, useRef, useState } from "react";
import providersicon from "./../../assets/images/providers.png";
import searchIcon from "./../../assets/images/search.png";

import { Spinner } from "react-bootstrap";
import { getAPI, getAPIAuth } from "../../services/apiInstance";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONST } from "../../constants";
import { useTranslation } from "react-i18next";

const Providers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [providers, setProviders] = useState([]);
  const [search, setSearch] = useState();
  const debounceRef = useRef();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const gameProviderLabels = t("gameProviderLabels", { returnObjects: true });
  const commonText = t("commonText", { returnObjects: true });

  const getData = async () => {
    try {
      const res = await getAPI(
        `games/get-all-providers?provider=${search ? search : ""}`
      );
      if (res.data.success) {
        setProviders(res.data.data);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleFunction = (provider) => {
    // navigate("/all-games", { state: { provider_id: provider } });
    // navigate(ROUTES_CONST.SLOT_PAGE, { state: { provider_id: provider } });
    navigate(`${ROUTE_CONST.PROVIDERS}/${provider}`);
  };
  useEffect(() => {
    //debounce
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      getData();
    }, 500);
    return () => {
      clearTimeout(debounceRef.current);
    };
  }, [search]);
  return (
    <>
      <div className="provider my-3">
        <div className="navbtn commonProBtn gap-md-3 gap-2">
          <div className="btn-img1 my-auto">
            <img
              src={providersicon}
              alt=""
              className="w-100 my-auto h-100 object-fit-contain"
            />
          </div>
          <div className="promo">ProviderDasds</div>
          {/* <div className='btn-img2'>
                    <img src={right} alt="" className='w-100 h-100'/>
                </div> */}
          {/* <div className='det'>Details</div> */}
        </div>
        <div className="row pb-2 pt-2 pt-md-3 sectionSpacing">
          <div className="col-12 position-relative">
            <input
              className="searchComp"
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="serachImg">
              <img src={searchIcon} alt="" />
            </div>
          </div>
        </div>
        <div className="row g-3 row-cols-lg-5 row-cols-xxl-6 row-cols-md-4 row-col-sm-3 row-cols-2  align-items-center align-items-stretch">
          {isLoading ? (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="light" size="sm" />
            </div>
          ) : providers?.length === 0 ? (
            <div className="w-100 h-100">
              <h6 className="text-light">No data available</h6>
            </div>
          ) : (
            providers?.map((item) => (
              <div className="col" key={`${item?.provider}-${Math.random()}`}>
                <div
                  className="h-100 gameCard"
                  onClick={() => handleFunction(item?.provider)}
                >
                  <div className="h-auto d-flex flex-column align-items-center">
                    <div className="gameImg">
                      <img
                        src={item?.image}
                        alt={`${item?.provider}`}
                        className="w-100 h-100 object-fit-contain"
                      />
                    </div>
                    <div className="gameNum">{item?.gameCount}</div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Providers;
