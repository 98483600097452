import { connect } from 'socket.io-client';

// for production build
// export const socket = connect(process.env.REACT_APP_DEV_SOCKET_BASE_URL);
export const socket = connect(process.env.REACT_APP_PROD_SOCKET_BASE_URL);

// export const socket = {
//     on:()=>{},
//     emit:()=>{},
//     disconnect:()=>{},
// };





