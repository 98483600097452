import { createContext, useState } from "react";

export const SubscribeModalContext = createContext(null)

const SubscribeModalContextProvider = ({ children }) => {
    const [selectedBoostedTerm, setSelectedBoostedTerm] = useState({})
  const [selectedFixedTerm, setSelectedFixedTerm] = useState({})
  const [showSubscribeModal, setShowSubscribeModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [selectedPoolType, setSelectedPoolType] = useState('')

    return (
        <SubscribeModalContext.Provider value={{
            selectedBoostedTerm,
            setSelectedBoostedTerm,
            showSubscribeModal, 
            setShowSubscribeModal,
            selectedItem, 
            setSelectedItem,
            selectedFixedTerm, 
            setSelectedFixedTerm,
            selectedPoolType, 
            setSelectedPoolType
        }}>
            {children}
        </SubscribeModalContext.Provider>
    )
}

export default SubscribeModalContextProvider;