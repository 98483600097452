import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../../hooks/useAuth';
import { getAPIAuth, postAPIAuth } from '../../../services/apiInstance';
import { fetchUserDetails } from '../../../services/fetchUserDetails';
import { reducerConst } from '../../../constants/storeConstants';

const FiatModal = ({showFiatModal, setShowFiatModal}) => {  
    const dispatch = useDispatch();
    const { token } = useAuth();
      const [currency, setCurrency] = useState([]);
      const userDetails = useSelector(state => state.userDetails)
    
      const getCurrency = async () => {
        try {
            const res = await getAPIAuth("currency/get-currency", token)
            setCurrency(res.data.data);
        } catch (error) {
        }
    }
    
    const handleChange=(item)=>{
      if (!userDetails?.id) return
      if (userDetails?.usercurrency) {
          const body = {
              userId: userDetails.id,
              currency: item.code,
              image: item.icon,
          }
          try {
              const res =  postAPIAuth("user/save-user-currency", body);
                const response =  fetchUserDetails(token)
                  dispatch({ type: reducerConst.USER_DETAILS, payload: response?.data?.data ? response.data.data : {} })
                 handleClose()
            } catch (error) {
    
          }
      }
    
    
    }
    useEffect(()=>{
      getCurrency();
    },[showFiatModal])


  const handleClose = ()=> {
    setShowFiatModal(false)
  }
  return (
  <>
  <Modal show={showFiatModal} onHide={handleClose} centered fullscreen={'sm-down'} className='modalSection Information' >
        <Modal.Header closeButton>
          <Modal.Title>Information</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalBody'>
        <div className="row  pb-2">
            <div className="col-12 msg modalSmallSubHeading">
             Please note that these are currency approximations. All bets & transactions will be calculated in equivalent currencies. For more details feel free to contact our live support.
            </div>
            {
              currency?.map((item)=>(
                <div className="col-12 selFiatInpBox" key={item.id}>
                  <input type="radio" className='d-none fiatSelInp' name='fiat' id={`fiat-${item.id}`} onChange={()=>handleChange(item)}
                  checked={item.code===userDetails.usercurrency}
                  />
                  <label htmlFor={`fiat-${item.id}`} className='fiatSelLabel'>
                    <div className="checkDiv"></div>
                  <div className='d-flex align-items-center  gap-2'>
                  <div className="imgDiv d-flex justify-content-end">
                      <img src={item.icon} alt="" />
                    </div>
                  <div className="Txt">{item.code}</div>
                  </div>
                  </label>
                </div>
              ))
            }
          </div>

        </Modal.Body>

      </Modal>
  </>
  )
}

export default FiatModal