import React from 'react'
import { useTranslation } from 'react-i18next'

function Amlpolicy() {
const  {t} = useTranslation()
const Amlpolicy = t("AMLPolicy",{returnObjects:true})

    return (
        <div className='aml-policy-section'>
            <div className="heading">
                {Amlpolicy.AML_Policy_of}
            </div>
            <div className="sub-heading">
            {Amlpolicy.Last_updated}
            </div>
            <div className="sub-heading">
            {Amlpolicy.For_Deposits_and_Withdrawals}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.AML_Anti_Policy} <span className='link-span'><a href="/">www.memecoin.io/</a></span>
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.Introduction} <span className='link-span'><a href="/">www.memecoin.io/</a></span> {Amlpolicy.Is_operated}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.Objective_of_the_AML_Policy}
            </div>
            {/* <div className="sub-txt py-2">
                <span className='link-span'><a href="javascript:;">www.memecoin.io/</a></span> {Amlpolicy.For_that}
            </div> */}
            <div className="sub-txt py-2">
                <span className='link-span'><a href="javascript:;">www.memecoin.io/</a></span> {Amlpolicy.Reasonable_measures}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.AML_Program}<span className='link-span'><a href="javascript:;">www.memecoin.io/</a></span>{Amlpolicy.Designed}  
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.EU_Directive}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.EU_Regulation}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.EU_Various}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.BE_Law}
            </div>
            <div className="sub-heading">
                {Amlpolicy.Definition_of_money}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.Money_Laundering_understood}
            </div>
            <div className="list">
                <ul>
                    <li>
                        <div className="sub-txt py-2">
                            {Amlpolicy.P_1}
                        </div>
                    </li>
                    <li>
                        <div className="sub-txt py-2">
                            {Amlpolicy.P_2}
                        </div>
                    </li>
                    <li>
                        <div className="sub-txt py-2">
                            {Amlpolicy.P_3}
                        </div>
                    </li>
                    <li>
                        <div className="sub-txt py-2">
                            {Amlpolicy.P_4}
                        </div>
                    </li>
                </ul>
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.Money_laundering}
            </div>
            <div className="sub-heading">
                {Amlpolicy.Organization_of_AML} <span className='link-span'><a href="javascript:;">www.memecoin.io/</a></span> 
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.In_accordance} {Amlpolicy.Has_appointed} 
            </div>
            
            <div className="sub-txt py-2">
                {Amlpolicy.Furthermore}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.The_AMLCO}
            </div>
            <div className="sub-heading py-2">
                {Amlpolicy.AML_policy_changes}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.Each_Major}<span className='link-span'><a href="javascript:;">www.memecoin.io/</a></span>  {Amlpolicy.AML}  
            </div>
            <div className="sub-heading py-2">
               {Amlpolicy.Three_step_Verification}
            </div>
            <div className="list">
                <ul>
                    <li>
                        <div className="sub-txt py-2">
                            {Amlpolicy.Step_1}
                        </div>
                    </li>
                        <div className="sub-txt py-2">
                            {Amlpolicy.Step_one}
                        </div>
                    <li>
                        <div className="sub-txt py-2">
                            {Amlpolicy.Step_2}
                        </div>
                    </li>
                    <div className="sub-txt py-2">
                        {Amlpolicy.Step_two}
                    </div>
                    <li>
                        <div className="sub-txt py-2">
                            {Amlpolicy.Step_3}
                        </div>
                    </li>
                    <div className="sub-txt py-2">
                        {Amlpolicy.Step_three} 
                    </div>
                </ul>
            </div>
            <div className="sub-heading py-2">
                {Amlpolicy.KYC}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.The_formal} 
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.This_identification}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.A_copy_of}  
            </div>
            <div className="sub-txt pt-2">
                {Amlpolicy.Please_note_that}
            </div>
            <div className="sub-txt">
                {Amlpolicy.An_employee}
            </div>
            <div className="sub-heading py-2">
               {Amlpolicy.Proof_of_Address}
            </div>
            <div className="sub-txt pt-2">
                {Amlpolicy.Proof}
            </div>
            <div className="sub-txt">
                {Amlpolicy.A_recent} 
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.To_make}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.For_example}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.An_employee_may}
            </div>
            <div className="sub-heading py-2">
                {Amlpolicy.Source_of_funds}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.If_A_Player}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.Examples_of_SOW_are}
            </div>
            <div className="list">
                <ul>
                    <li>
                        <div className="sub-txt">
                           {Amlpolicy.Ownership_of_business}
                        </div>
                    </li>
                    <li>
                        <div className="sub-txt">
                            {Amlpolicy.Employment}
                        </div>
                    </li>
                    <li>
                        <div className="sub-txt">
                            {Amlpolicy.Inheritance}
                        </div>
                    </li>
                    <li>
                        <div className="sub-txt">
                            {Amlpolicy.Investment}
                        </div>
                    </li>
                    <li>
                        <div className="sub-txt">
                            {Amlpolicy.Family}
                        </div>
                    </li>
                </ul>
            </div>
            <div className="sub-txt pt-2">
                {Amlpolicy.Critical} 
            </div>
            <div className="sub-txt">
                {Amlpolicy.Frozen_account} <span className='link-span'><a href="javascript:;">www.memecoin.io/</a></span> {Amlpolicy.Also}
            </div>
            <div className="sub-heading py-2">
                {Amlpolicy.Basic_document} 
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.The_basic}<span className='link-span'><a href="javascript:;">www.memecoin.io/</a></span> {Amlpolicy.Every_user}
            </div>
            <div className="list">
                <ul>
                    <li>
                        <div className="sub-txt">
                            {Amlpolicy.First_name}
                        </div>
                    </li>
                    <li>
                        <div className="sub-txt">
                            {Amlpolicy.Second_name}
                        </div>
                    </li>
                    <li>
                        <div className="sub-txt">
                            {Amlpolicy.Nationality}
                        </div>
                    </li>
                    <li>
                        <div className="sub-txt">
                            {Amlpolicy.Gender}
                        </div>
                    </li>
                    <li>
                        <div className="sub-txt">
                            {Amlpolicy.Date_of_Birth}
                        </div>
                    </li>
                </ul>
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.The_document}
            </div>
            <div className="sub-heading py-2">
                {Amlpolicy.Risk_management}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.In_order}<span className='link-span'><a href="javascript:;">www.memecoin.io/</a></span> {Amlpolicy.Will_categorize}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.Region_one_Low_risk}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.For_every_nation} 
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.Region_two}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.For}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.Region_three}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.Regions_of_high}
            </div>
            <div className="sub-heading py-2">
                {Amlpolicy.Additional_measurements}
            </div>
            <div className="sub-txt pt-2">
                {Amlpolicy.In_addition} <span className='link-span'><a href="javascript:;">www.memecoin.io/</a></span>. 
            </div>
            <div className="sub-txt">
                {Amlpolicy.According} 
            </div>
            <div className="sub-txt">
                {Amlpolicy.Data_Scientist}
            </div>
            <div className="sub-txt">
                {Amlpolicy.Also_a_User}
            </div>
            <div className="sub-txt pt-2">
                {Amlpolicy.Enterprisewide_risk_assessment}
            </div>
            <div className="sub-txt">
                {Amlpolicy.As_part_of_its} <span className='link-span'><a href="javascript:;">www.memecoin.io/</a></span> {Amlpolicy.Has_conducted} 
            </div>
            <div className="sub-txt">
            {Amlpolicy.The_identification} {Amlpolicy.Understanding_of_regulatory} 
            </div>
            <div className="sub-txt">
                {Amlpolicy.The_EWRA_is_yearly_reassessed}
            </div>
            <div className="sub-heading py-2">
                {Amlpolicy.Ongoing_transaction_monitoring}
             
            </div>

            <div className="sub-txt py-2">
                {Amlpolicy.AMLComplianceensures}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.This_transaction}
            </div>
            <div className="list">
                <ol>
                    <li>
                        <div className="sub-txt py-2">
                            {Amlpolicy.The_first_Line_of_Control}  
                        </div>
                    </li>
                    <div className="sub-txt py-2">
                        <span className='link-span'><a href="javascript:;">www.memecoin.io/</a></span> {Amlpolicy.Works_solely} <span className='link-span'><a href="javascript:;">www.memecoin.io/</a></span> {Amlpolicy.From_taking} 
                    </div>
                    <li>
                        <div className="sub-txt py-2">
                            {Amlpolicy.The_second_Line_of_Control}
                        </div>
                    </li>
                    <div className="sub-txt py-2">
                        <span className='link-span'><a href="javascript:;">www.memecoin.io/</a></span> {Amlpolicy.Makes_its}
                    </div>
                    <div className="sub-txt py-2">
                        {Amlpolicy.In}
                    </div>
                    <div className="list">
                        <ul>
                            <li>
                                <div className="sub-txt py-2">
                                    {Amlpolicy.Requests}
                                </div>
                            </li>
                            <li>
                                <div className="sub-txt py-2">
                                    {Amlpolicy.Requests_in}
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="sub-txt py-2">
                        {Amlpolicy.Threestep}
                    </div>
                    <div className="sub-txt py-2">
                        {Amlpolicy.At_all_time}
                    </div>
                    <div className="sub-txt py-2">
                        {Amlpolicy.All_transaction}
                    </div>
                    <div className="sub-txt py-2">
                        {Amlpolicy.Determination}
                    </div>
                    <div className="sub-txt py-2">
                        {Amlpolicy.These_checks} 
                    </div>
                    <div className="sub-txt py-2">
                        {Amlpolicy.The_transactions}
                    </div>
                    <div className="sub-txt py-2">
                        {Amlpolicy.Any} <span className='link-span'><a href="javascript:;">www.memecoin.io/</a></span> {Amlpolicy.Staff} 
                    </div>
                    <li>
                        <div className="sub-txt py-2">
                            {Amlpolicy.The_third_Line_of_Control}
                        </div>
                    </li>
                    <div className="sub-txt py-2">
                        {Amlpolicy.As_a_last}<span className='link-span'><a href="javascript:;">www.memecoin.io/</a></span> {Amlpolicy.Will}
                    </div>
                    <div className="sub-txt py-2">
                        {Amlpolicy.If_fraud} 
                    </div>
                </ol>
            </div>
            <div className="sub-heading py-2">
                {Amlpolicy.Reporting_of_Suspicious} <span className='link-span'><a href="javascript:;">www.memecoin.io/</a></span>
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.In_its_internal} <span className='link-span'><a href="javascript:;">www.memecoin.io/</a></span> {Amlpolicy.Describes}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.Reports_of_atypical}
            </div>
            <div className="sub-txt py-2">
               {Amlpolicy.Depending_on_result}
            </div>
            <div className="list">
                <ul>
                    <li>
                        <div className="sub-txt py-2">
                            {Amlpolicy.Will_decide}
                        </div>
                    </li>
                    <li>
                        <div className="sub-txt py-2">
                            {Amlpolicy.Will_decide_whether}
                        </div>
                    </li>
                </ul>
            </div>
            <div className="sub-heading py-2">
                {Amlpolicy.Record_keeping}
            </div>
            <div className="sub-txt pt-2">
                {Amlpolicy.Records_of_data}
            </div>
            <div className="sub-txt">
                {Amlpolicy.Records_of_all}
            </div>
            <div className="sub-txt">
                {Amlpolicy.These_data}
            </div>
            <div className="sub-heading py-2">
                {Amlpolicy.Auditing}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.Internal_audit} 
            </div>
            <div className="sub-heading py-2">
                {Amlpolicy.Data_Security}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.All_data} 
            </div>
            <div className="sub-heading py-2">
                {Amlpolicy.Contact_us}
            </div>
            <div className="sub-txt py-2">
                {Amlpolicy.If_you_have_any_questions}
            </div>
            <div className="list">
                <ul>
                    <li>
                        <div className="sub-txt py-2">
                            {Amlpolicy.By_email} <span className='link-span'><a href="javascript:;">info@memecoin.io</a></span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Amlpolicy
