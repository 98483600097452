import React from 'react'
import { useTranslation } from 'react-i18next'
function Termsconditions() {
    const { t } = useTranslation()

    const termsAndCondition = t("termsAndCondition", { returnObjects: true })

    return (
        <div className='aml-policy-section'>
            <div className="heading">
                {termsAndCondition.Terms_and_Conditions_heading}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Last_Update}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Introduction}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Introduction_p1}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Introduction_p2}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Introduction_p3}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Introduction_p4}
            </div>
            <div className="sub-heading">
                {termsAndCondition.General_terms}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.General_terms_p}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Your_obligations}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_obligations_p1}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_obligations_p2}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_obligations_p3}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_obligations_p4}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_obligations_p5}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_obligations_p6}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_obligations_p7}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_obligations_p8}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_obligations_p9}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_obligations_p10}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_obligations_p11}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_obligations_p12}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_obligations_p13}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_obligations_p14}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Restricted_use}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Restricted_use_p1}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Restricted_use_p2}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Restricted_use_p3}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Restricted_use_p4}
            </div>
            <div className="list py-3">
                <ul>
                    <li className='sub-txt'>{termsAndCondition.Restricted_use_p4_b1}</li>
                    <li className='sub-txt'>{termsAndCondition.Restricted_use_p4_b2}</li>
                    <li className='sub-txt'>{termsAndCondition.Restricted_use_p4_b3}</li>
                    <li className='sub-txt'>{termsAndCondition.Restricted_use_p4_b4}</li>
                    <li className='sub-txt'>{termsAndCondition.Restricted_use_p4_b5}</li>
                    <li className='sub-txt'>{termsAndCondition.Restricted_use_p4_b6}</li>
                    <li className='sub-txt'>{termsAndCondition.Restricted_use_p4_b7}</li>
                    <li className='sub-txt'>{termsAndCondition.Restricted_use_p4_b8}</li>
                </ul>
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Restricted_use_p5}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Restricted_use_p6}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Restricted_use_p7}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Restricted_use_p8}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Restricted_use_p9}            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Restricted_use_p10}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Restricted_use_p11}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Restricted_use_p12}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Restricted_use_p13}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Restricted_use_p14}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Registration}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Registration_p1}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Registration_p2}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Registration_p3}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Registration_p4}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Registration_p5}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Registration_p6}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Registration_p7}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Registration_p8}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Registration_p9}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Registration_p10}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Registration_p11}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Registration_p12}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Registration_p13}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Your_Account}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_Account_p1}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_Account_p2}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_Account_p3}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_Account_p4}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_Account_p5}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_Account_p6}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_Account_p7}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_Account_p8}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_Account_p9}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_Account_p10}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_Account_p11}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Inactive_Accounts}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Inactive_Accounts_p1}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Inactive_Accounts_p2}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Inactive_Accounts_p3}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Inactive_Accounts_p4}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Inactive_Accounts_p5}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Deposit_of_Funds}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Deposit_of_Funds_p1}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Deposit_of_Funds_p2}
                <span className='link-span'><a href="javascript:;">www.memecoin.io.</a></span>
                {termsAndCondition.Deposit_of_Funds_p2i}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Deposit_of_Funds_p3}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Deposit_of_Funds_p4}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Deposit_of_Funds_p5}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Deposit_of_Funds_p6}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Deposit_of_Funds_p7}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Deposit_of_Funds_p8}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Withdrawal_of_Funds}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Withdrawal_of_Funds_p1}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Withdrawal_of_Funds_p2}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Withdrawal_of_Funds_p3}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Withdrawal_of_Funds_p4}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Withdrawal_of_Funds_p5}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Withdrawal_of_Funds_p6}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Withdrawal_of_Funds_p7}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Withdrawal_of_Funds_p8}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Payment_Transactions_and_Processors}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Payment_Transactions_and_Processors_p1}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Payment_Transactions_and_Processors_p2}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Payment_Transactions_and_Processors_p3}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Payment_Transactions_and_Processors_p4}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Errors}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Errors_p1}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Errors_p2}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Errors_p3}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Rules_of_Play_refunds_and_cancellations}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Rules_of_Play_refunds_and_cancellations_p1}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Rules_of_Play_refunds_and_cancellations_p2}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Rules_of_Play_refunds_and_cancellations_p3}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Rules_of_Play_refunds_and_cancellations_p4}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Rules_of_Play_refunds_and_cancellations_p5}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Rules_of_Play_refunds_and_cancellations_p6}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Rules_of_Play_refunds_and_cancellations_p7}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Rules_of_Play_refunds_and_cancellations_p8}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Rules_of_Play_refunds_and_cancellations_p9}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Rules_of_Play_refunds_and_cancellations_p10}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Rules_of_Play_refunds_and_cancellations_p11}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Rules_of_Play_refunds_and_cancellations_p12}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Rules_of_Play_refunds_and_cancellations_p13}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Communications_and_Notices}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Communications_and_Notices_p1}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Communications_and_Notices_p2}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Communications_and_Notices_p3}
            </div>
            <div className="sub-txt py-2">

                {termsAndCondition.Communications_and_Notices_p4}


                <span className='link-span'><a href="javascript:;">www.memecoin.io.</a></span>
                {termsAndCondition.Communications_and_Notices_p5}

            </div>
            <div className="sub-heading">
                {termsAndCondition.Matters_Beyond_Our_Control}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Matters_Beyond_Our_Control_p1}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Liability}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Liability_p1}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Liability_p2}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Liability_p3}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Gambling_By_Those_Under_Age}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Gambling_By_Those_Under_Age_p1}
            </div>
            <div className="list">
                <ul>
                    <li className='sub-txt'>
                        {termsAndCondition.Gambling_By_Those_Under_Age_p1_b1}
                    </li>
                    <li className='sub-txt'>
                        {termsAndCondition.Gambling_By_Those_Under_Age_p1_b2}
                    </li>
                    <li className='sub-txt'>
                        {termsAndCondition.Gambling_By_Those_Under_Age_p1_b3}<span className='link-span'><a href="javascript:;">www.memecoin.io/</a></span>
                        {termsAndCondition.Gambling_By_Those_Under_Age_p1_b3i}
                    </li>
                </ul>
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Gambling_By_Those_Under_Age_p2}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Gambling_By_Those_Under_Age_p3}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Fraud}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Fraud_p1}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Fraud_p2}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Intellectual_Property}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Intellectual_Property_p1}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Intellectual_Property_p2}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Intellectual_Property_p3}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Intellectual_Property_p4}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Your_License}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_License_p1}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_License_p2}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_License_p3}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Your_Conduct_and_Safety}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_Conduct_and_Safety_p1}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_Conduct_and_Safety_p2}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_Conduct_and_Safety_p3}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Your_Conduct_and_Safety_p4}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Links_to_Other_Websites}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Links_to_Other_Websites_p}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Complaints}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Complaints_p1}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Complaints_p2}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Complaints_p3}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Complaints_p4}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Complaints_p5}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Complaints_p6}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Assignment}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Assignment_p1}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Severability}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Severability_p1}
            </div>
            <div className="sub-heading">
                {termsAndCondition.Breach_of_These_Terms}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.Breach_of_These_Terms_p1}
            </div>
            <div className="sub-heading">
                {termsAndCondition.General_Provisions}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.General_Provisions_p1}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.General_Provisions_p2}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.General_Provisions_p3}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.General_Provisions_p4}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.General_Provisions_p5}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.General_Provisions_p6}
            </div>
            <div className="sub-txt py-2">
                {termsAndCondition.General_Provisions_p7}
            </div>
        </div>
    )
}
export default Termsconditions
