import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

const AboutStacking = () => {
  return (
    <>
          <main className="main stackingMain" id="main">
            <div className="mainContaint">
                <div className="homepage stackingPage aboutStackingWrapper">
                <div className='AboutStackingHero'>
                  <div className="row">
                    <div className=" col-12 px-0 pt-5">
                      <div className="row mx-0 d-flex flex-column justify-content-between h-100">
                        <div className="col-12 d-md-block d-none"><div className="stackingHeading text-center">EARN UP TO 50% APY IN DGW <br /> STAKING</div></div>
                        <div className="col-12 d-md-none d-block"><div className="stackingHeading text-center">EARN UP TO 50% APY IN DGW <br /> STAKING</div></div>
                        <div className="col-12 d-md-none d-block text-center"><div className="stackingsubHeading py-3"><span>Stake DGW and receive daily multi-currency payouts in 5 popular tokens: USDT, BTC, ETH, BNB, and TRX, or in DGW.</span></div></div>
                        <div className="col-12 d-lg-block d-none text-center">
                          <button className='stakeBtn mx-auto mt-md-5 mt-3' >Sign Up & stake DBL</button>
                        </div>
                      </div>
                    </div>
                    <div className=" col-12 px-0">
                    <div className="col-12 d-lg-none d-flex text-center justify-content-center">
                          <button className='stakeBtn mx-auto mt-md-5 mt-3' >Sign Up & stake DBL</button>
                        </div>
                      <div className="stackingBanner text-center mx-auto"><img src="./assets/img/stacking/aboutsticking.svg" alt="" /></div>
                     
                    </div>
                  </div>
                </div>
                  {/* ------------------------------------- */}
                  <div className="row py-lg-5 py-4">
                    <div className="col-lg-3 col-6">
                      <div className="row mx-0 percentageBox align-items-center">
                        <div className="col-6 px-0"><div className="textBold">33.62%</div></div>
                        <div className="col-6 px-0">
                          <div><div className="textlight">Annual</div></div>
                          <div><div className="textlight">Percentage Yield</div></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-6">
                      <div className="row mx-0 percentageBox percentageBox2 align-items-center">
                        <div className="col-lg-7 col-6 px-0"><div className="textBold">$101.8M</div></div>
                        <div className="col-lg-5 col-6 ps-0">
                          <div><div className="textlight">Total</div></div>
                          <div><div className="textlight">Paid</div></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-6 mt-3 mt-lg-0">
                      <div className="row mx-0 percentageBox  align-items-center">
                        <div className="col-6 px-0"><div className="textBold">34K+</div></div>
                        <div className="col-6 px-0">
                          <div><div className="textlight">Staking participants</div></div>
                          {/* <div><div className="textlight">Paid</div></div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-6 mt-3 mt-lg-0">
                      <div className="row mx-0 percentageBox  border-end-0 align-items-center">
                        <div className="col-6 px-0"><div className="textBold">$49.3K</div></div>
                        <div className="col-6 px-0">
                          <div><div className="textlight">Will be paid today</div></div>
                          <div><div className="textlight textSucess">in 12:49:26</div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='seprator my-3 d-md-none d-block'></div>
                  <div className="row">
                  <div className="col-xxl-8 col-xl-7  mt-4 mt-xxl-0">
                      <div className="row mx-0"></div>
                        <div className="col-12">
                          <div className="faqHeading">About DBL Staking </div>
                        </div>
                        <div className="col-12">
                          <Accordion className='stackingAccordian' defaultActiveKey={['0', '1', '2', '3', '4', '5']} alwaysOpen>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>What is DBL Staking?</Accordion.Header>
                            <Accordion.Body>
                              Staking on Damble is a unique system of multicurrency daily drops for all DBL holders. Staking allows users to get daily payouts just for keeping DBL tokens on balance.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>How is the Staking pool replenished?</Accordion.Header>
                            <Accordion.Body>
                            All funds from the non-winning bets placed on the platform go to the Staking pool and replenish the appropriate sub-pool. The Staking pool funds are used for daily Staking payouts and winning game payouts.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                            How do Staking payouts work?
                            </Accordion.Header>
                            <Accordion.Body>
                            Every 24 hours, Staking pool releases 3% of the total profit. Moreover, by holding just 100 DBL, you may get high income in DBL or 5 popular crypto: USDT, ETH, BTC, BNB, and TRX.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>
                            What crypto does the Staking pool consist of?
                            </Accordion.Header>
                            <Accordion.Body>
                            Staking pool consists of 5 main currencies: BTC, ETH, BNB, USDT, and TRX. All other available currencies on the platform are attached to each of these five according to the main network which they support. The total Staking pool replenishes with the sub-pool funds once a month.
                            </Accordion.Body>
                          </Accordion.Item>
                          </Accordion>
                        </div>
                    </div>
                    <div className="col-xxl-4 col-xl-5 ">
                      <div className="row mx-0 calculatorBox">
                        <div className="col-12 px-0"><div className="textlightabout mb-2">STAKING calculator</div></div>
                        <div className="col-12 px-0"><div className="textlightabout mb-3"><span>Calculate the estimated profit based on the current APY: <span className='text-white'>33.62%</span> </span></div></div>
                        <div className="col-12 px-0">
                        <div className="col-12 px-0">
                              <div className="box1">
                                  <div className="row">
                                      <div className="col">
                                          <div className="row align-items-center">
                                              <div className="col-auto"><div className="bit"><img src="./assets/img/stacking/DBL.png" alt="" /></div></div>
                                              <div className="col">
                                                  <div><div className="textlight"><span>Subscription</span></div></div>
                                                  <div><div className="textlight">13141</div></div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-auto">
                                          <div><div className="textlight"><span>$0.123131</span></div></div>
                                          <div><div className="textlight d-flex justify-content-end"><svg className='' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M278.6 256l68.2-68.2c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-68.2-68.2c-6.2-6.2-16.4-6.2-22.6 0-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3l68.2 68.2-68.2 68.2c-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3 6.2 6.2 16.4 6.2 22.6 0l68.2-68.2 68.2 68.2c6.2 6.2 16.4 6.2 22.6 0 6.2-6.2 6.2-16.4 0-22.6L278.6 256z"></path></svg></div></div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-12 px-0 py-3">
                            <div className="row g-2 mx-0">
                                <div className="col-3 ps-0"><button className='flexBtns active'>100k</button></div>
                                <div className="col-3"><button className='flexBtns'>500k</button></div>
                                <div className="col-3"><button className='flexBtns'>1M</button></div>
                                <div className="col-3 pe-0"><button className='flexBtns'>10M</button></div>
                            </div>
                          </div>
                          <div className="col-12 px-0 d-flex justify-content-between align-items-center pb-3">
                            <div><div className="textlightabout"><span>PER DAY:</span></div></div>
                            <div><div className="textlightabout"><span>$15.20</span></div></div>
                          </div>
                          <div className="col-12 px-0 d-flex justify-content-between align-items-center pb-3">
                            <div><div className="textlightabout"><span>PER MONTH:</span></div></div>
                            <div><div className="textlightabout"><span>$456.05</span></div></div>
                          </div>
                          <div className="col-12 px-0 d-flex justify-content-between align-items-center pb-3">
                            <div><div className="textlightabout"><span>PER YEAR:</span></div></div>
                            <div><div className="textlightabout textlightaboutGreen"><span>$5,548.65</span></div></div>
                          </div>
                          <div class="col-12 px-0"><button class="stakeBtn w-100">Sign up & stake DBL</button></div>
                        </div>
                      </div>
                    </div>
                   
                  </div>
                  <div className="row py-4">
                    <div className="col-md-2 col-4">
                      <div className="imageBox">
                        <div className="row">
                          <div className="col-12"><div className="boximg"><img src="./assets/img/stacking/OBJECTS.png" alt="" /></div></div>
                          <div className="col-12"><div className="boxtxt">Stake from 100 DBL</div></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 col-4">
                      <div className="imageBox">
                        <div className="row">
                          <div className="col-12"><div className="boximg"><img src="./assets/img/stacking/wallet.png" alt="" /></div></div>
                          <div className="col-12"><div className="boxtxt">Get Daily payouts</div></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 col-4">
                      <div className="imageBox">
                        <div className="row">
                          <div className="col-12"><div className="boximg"><img src="./assets/img/stacking/doller.png" alt="" /></div></div>
                          <div className="col-12"><div className="boxtxt">High return APY</div></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 col-4 mt-3 mt-md-0">
                      <div className="imageBox">
                        <div className="row">
                          <div className="col-12"><div className="boximg"><img src="./assets/img/stacking/DBL.png" alt="" /></div></div>
                          <div className="col-12"><div className="boxtxt">Unique DBL Token</div></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 col-4 mt-3 mt-md-0">
                      <div className="imageBox">
                        <div className="row">
                          <div className="col-12"><div className="boximg"><img src="./assets/img/stacking/hand3.png" alt="" /></div></div>
                          <div className="col-12"><div className="boxtxt">Trusted Platform</div></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 col-4 mt-3 mt-md-0">
                      <div className="imageBox">
                        <div className="row">
                          <div className="col-12"><div className="boximg"><img src="./assets/img/stacking/tokenTableCoin.png" alt="" /></div></div>
                          <div className="col-12"><div className="boxtxt">Top Crypto Payouts</div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='seprator my-3 d-md-none d-block'></div>
                  <div className="row">
                    <div className="col-12">
                      <div className="faqHeading">How to Earn Crypto through <br/>Staking?</div>
                    </div>
                    <div className="col-12 py-4">
                      <div className="row mx-0">
                        <div className="col-12 px-0">
                        <div className="row d-lg-block d-none">
                          <div className="col-12">
                          <div className="row mx-0 pb-3">
                            <div className="col-4 d-flex align-items-center">
                              <div className="rectangle rectangleHeight"><img src="./assets/img/stacking/Rectangle.png" alt="" /></div>
                              <div className="textlightabout ps-4">Make a deposit</div>
                              </div>
                            <div className="col-8">
                              <div className="textlightabout"><span>Deposit to your account in any available cryptocurrency. You can also deposit DGW straight away.</span></div>
                            </div>
                          </div>
                          <div className="row mx-0 pb-3">
                            <div className="col-4 d-flex align-items-center">
                              <div className="rectangle rectangle1 rectangleHeight"><img src="./assets/img/stacking/Rectangle.png" alt="" /></div>
                              <div className="textlightabout ps-4">Get Daily Payouts</div>
                              </div>
                            <div className="col-8">
                              <div className="textlightabout"><span>Buy DBL on the platform with top currencies. <button className='stackingBtn ms-2'>Buy DBL</button> <button className='stackingBtn1 ms-2'>About DBL</button></span></div>
                            </div>
                          </div>
                          <div className="row mx-0 pb-3">
                            <div className="col-4 d-flex align-items-center">
                              <div className="rectangle rectangle2 rectangleHeight"><img src="./assets/img/stacking/Rectangle.png" alt="" /></div>
                              <div className="textlightabout ps-4">Get Daily Payouts</div>
                              </div>
                            <div className="col-8">
                              <div className="textlightabout"><span>Every day, 3% of the Staking pool is distributed to all DBL holders in DBL or 5 popular crypto: USDT, BTC, ETH, BNB, and TRX. Reinvest the profit in DBL tokens to receive more next time.</span></div>
                            </div>
                          </div>
                          </div>
                        </div>
                        <div className="row d-lg-none d-block">
                          <div className="col-12">
                          <div className="row mx-0 pb-3">
                            <div className="col-12 px-0">
                              <div className="row">
                                <div className="col-auto">
                                  <div className="rectangle"><img src="./assets/img/stacking/Rectangle.png" alt="" /></div>
                                </div>
                                <div className="col">
                                  <div className="textlightabout">Make a deposit</div>
                                  <div className="textlightabout"><span>Deposit to your account in any available cryptocurrency. You can also deposit DBL straight away.</span></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mx-0 pb-3">
                            <div className="col-12 px-0">
                              <div className="row">
                                <div className="col-auto">
                                  <div className="rectangle rectangle1"><img src="./assets/img/stacking/Rectangle.png" alt="" /></div>
                                </div>
                                <div className="col">
                                  <div className="textlightabout">Buy DBL easily</div>
                                  <div className="textlightabout"><span>Buy DBL on the platform with top currencies.</span></div>
                                  <div className="textlightabout"><span><button className='stackingBtn ms-2 mt-3'>Buy DBL</button> <button className='stackingBtn1 ms-2 mt-3'>About DBL</button></span></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mx-0 pb-3">
                          <div className="col-12 px-0">
                              <div className="row">
                                <div className="col-auto">
                                  <div className="rectangle rectangle2"><img src="./assets/img/stacking/Rectangle.png" alt="" /></div>
                                </div>
                                <div className="col">
                                  <div className="textlightabout">Get Daily Payouts</div>
                                  <div className="textlightabout"><span>Every day, 3% of the Staking pool is distributed to all DBL holders in DBL or 5 popular crypto: USDT, BTC, ETH, BNB, and TRX. Reinvest the profit in DBL tokens to receive more next time.</span></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          </div>
                        </div>
                        <div className='seprator my-3 d-md-none d-block'></div>

                          <div className="row mx-0 mt-0 my-md-4 align-items-center">
                            <div className="col-lg px-0"><div className="textHeading">Buy DBL on the popular exchanges:</div></div>
                            <div className="col-lg-auto py-3 px-0 d-flex align-items-center gap-2">
                              <button className='bitBtn'><img src="./assets/img/stacking/coinw.svg" alt="" />CoinW</button>
                              <button className='bitBtn ms-3'><img src="./assets/img/stacking/pancake.png" alt="" />PancakeSwap</button>
                            </div>
                          </div>
                          
                        <div className='seprator my-3 d-md-none d-block'></div>

                          <div className="row">
                            <div className="col-12"><div className="faqHeading">FAQ about Staking</div></div>
                              <div className="col-12 px-0">
                                <div className="row mx-0 py-3">
                                  <div className="col-md-6 col-12">
                                      <Accordion className='stackingAccordian' defaultActiveKey={['0', '1', '2', '3', '4', '5']} alwaysOpen>
                                      <Accordion.Item eventKey="0">
                                        <Accordion.Header>What is DBL Staking?</Accordion.Header>
                                        <Accordion.Body>
                                            Play Originals, Slots, place bets on Sports and participate in Auction with DBL. Receive daily Staking payouts by holding at least 100 DBL on your balance.
                                        </Accordion.Body>
                                      </Accordion.Item>
                                      <Accordion.Item eventKey="1">
                                        <Accordion.Header>How and where to get DBL?</Accordion.Header>
                                        <Accordion.Body>
                                          Buy DBL on the platform with the most popular crypto. Also, use  CoinW or any other exchanges where DBL is listed. Play on the platform to receive different Bonuses in DBL.
                                        </Accordion.Body>
                                      </Accordion.Item>
                                      <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                          Will DBL be available for mining again?
                                        </Accordion.Header>
                                        <Accordion.Body>4 000 000 000 DBL tokens were already mined, so there will be no possibility of getting DBL from mining.
                                        </Accordion.Body>
                                      </Accordion.Item>
                                      <Accordion.Item eventKey="3">
                                        <Accordion.Header>  Will DBL Burning continue after all tokens are mined?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Yes. We’ll continue to burn DBL after all tokens are mined.
                                        </Accordion.Body>
                                      </Accordion.Item>
                                      </Accordion>
                                  </div>
                                  <div className="col-md-6 col-12">
                                      <Accordion className='stackingAccordian' defaultActiveKey={['0', '1', '2', '3', '4', '5']} alwaysOpen>
                                      <Accordion.Item eventKey="0">
                                        <Accordion.Header>How can I start getting Staking payouts?</Accordion.Header>
                                        <Accordion.Body>You can get daily Staking payouts by holding 100 or more DBL tokens on your balance. The more DBL you have, the bigger payouts you receive.
                                        </Accordion.Body>
                                      </Accordion.Item>
                                      <Accordion.Item eventKey="1">
                                        <Accordion.Header>Do I need to claim my Staking payouts every day?</Accordion.Header>
                                        <Accordion.Body>There is no need to claim them every day. Your payouts will accumulate. Claim it whenever you’d be comfortable.
                                        </Accordion.Body>
                                      </Accordion.Item>
                                      <Accordion.Item eventKey="2">
                                        <Accordion.Header>
                                        Is the Staking pool static or dynamic?
                                        </Accordion.Header>
                                        <Accordion.Body>
                                        Staking pool is dynamic as it depends on the users' activity. All funds from the non-winning bets placed on the platform go to the Staking pool and replenish the appropriate sub-pool
                                        </Accordion.Body>
                                      </Accordion.Item>
                                      <Accordion.Item eventKey="3">
                                        <Accordion.Header>Can I find the transaction history of my payouts?
                                        </Accordion.Header>
                                        <Accordion.Body>Staking pool is dynamic as it depends on the users' activity. All funds from the non-winning bets placed on the platform go to the Staking pool and replenish the appropriate sub-pool
                                        </Accordion.Body>
                                      </Accordion.Item>
                                      </Accordion>
                                  </div>
                                </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </main>
    </>
  )
}

export default AboutStacking