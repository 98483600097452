import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useAuth } from "../../../../../hooks/useAuth";
import { AUTH_MODAL_TABS } from "../../../../../constants";
import { useSearchParams } from "react-router-dom";
import { useSubscribeModal } from "../../../../../hooks/useSubscribeModal";
import { getAPI } from "../../../../../services/apiInstance";

const CalculateBox = ({
  data,
  inputValue,
  setInputValue,
  selectedCrypto,
  setSelectedCrypto,
  selectedPoolType,
  setSelectedPoolType,
  selectedTabValue,
  setSelectedTabValue,
}) => {

  console.log('selectedTabValue', selectedTabValue, selectedPoolType)
  
  const modalObject = useSubscribeModal();
  const [flexible, setFlexible] = useState();
  const [convertedAmount, setConvertedAmount] = useState(0.00)

  const { token } = useAuth();
  const [query, setQuery] = useSearchParams();

  useEffect(()=> {
    if(selectedPoolType?.tab?.length){
      setSelectedTabValue(selectedPoolType?.tab?.[selectedPoolType?.tab?.length - 1])
    }
  }, [selectedPoolType])


  const handleSelect = (item) => {
    setSelectedCrypto(item);
    setSelectedPoolType(item?.term?.[0]);
  };

  useEffect(() => {
    if (data?.length) {
      setSelectedCrypto(data?.[0]);
      setSelectedPoolType(data?.[0]?.term?.[0]);
    }
  }, [data]); //eslint-disable-line

  useEffect(()=> {
    const flexibleData = selectedCrypto?.term?.filter(item=>item?.poolType === "Flexible")?.[0]
    setFlexible(flexibleData)
  }, [selectedCrypto])



  const getConverted = async () => {
    try {
        const res = await getAPI(`crypto/convert-amount?cryptoId=${selectedCrypto?.currency_id}&amount=${inputValue}`)
        if (res.data.success) {
          setConvertedAmount(res?.data?.data?.amount_usdt)
        }
    } catch (error) {

    }
}

useEffect(()=> {
  if(selectedCrypto?.currency_id) {
    getConverted()
  }
}, [selectedCrypto, inputValue])


  return (
    <>
      <div className="row align-items-center">
        <div className="col-sm-4 col-5 pe-0">
          <Dropdown className="cDrop">
            <Dropdown.Toggle id="dropdown-basic">
              <div className="usdtIconBox">
                <img
                  src={selectedCrypto?.currency?.icon}
                  alt=""
                  className="usdtIcon"
                />
              </div>
              <div>
                <div className="sText">Currency</div>
                <div className="sTextBold">
                  {selectedCrypto?.currency?.name}
                </div>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {data?.map((item) => (
                <Dropdown.Item
                  as="button"
                  onClick={() => handleSelect(item)}
                  key={item._id}
                >
                  <img src={item?.currency?.icon} alt="" className="usdtIcon rounded-circle overflow-hidden d-flex" />
                  {item?.currency?.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-sm-8 col-7">
          <div className="calculateInput w-100">
            <div className="position-relative w-100">
              <img
                src={selectedCrypto?.currency?.icon}
                alt=""
                className="usdtIcon rounded-circle overflow-hidden"
              />
              <input
                type="text"
                className="form-control"
                placeholder="Enter Amount"
                value={inputValue}
                onChange={(e) =>{
                  if (isNaN(Number(e.target.value))) return
                  setInputValue(e.target.value)
                }}
              />
              <div className="sText balance">$ {convertedAmount ? Number(convertedAmount)?.toFixed(2) : '0.00'}</div>
            </div>
          </div>
        </div>

        <div className="col-12 mb-2 pb-1 mt-2">
          <div className="stackingTabs row gx-1 w-100">
            {selectedCrypto?.term?.map((item) => (
              <div
                className="col-4"
                key={item._id}
                onClick={() => {
                  if(item?.tab?.length){
                    setSelectedPoolType(item)
                    setSelectedTabValue(item?.tab?.[0])
                  }else{
                    setSelectedPoolType(item)
                  }
                }}
              >
                <button
                  className={`stackingTab w-100 ${
                    item._id === selectedPoolType._id ? "active" : ""
                  }`}
                >
                  {item?.poolType}
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="col-auto termBoxTxt pe-1">Products on offer</div>
        <div className="col ps-0">
          <div className="d-flex align-items-center justify-content-end flex-wrap gap-1 gap-sm-2">
            {selectedPoolType?.tab?.map((item) => (
              <div
                className={`termBox ${
                  selectedTabValue.name === item?.name ? "active" : ""
                }`}
                key={item?.name}
                onClick={() => setSelectedTabValue(item)}
              >
                {item?.name}
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 mt-3">
          <div className="subscribeBox">
            <div className="row align-items-center">
              <div className="col">
                <div className="sText">{selectedPoolType?.poolType}</div>
                <div>
                  <span className="sTextBold">{
                    selectedPoolType?.poolType === 'Flexible' ? flexible?.apr :
                  selectedTabValue?.apr
                  }%</span>
                  <span className="sText ps-2">APR</span>
                </div>
              </div>
              <div className="col-auto">
                <button
                  className="commonBlueBtn small"
                  onClick={() => {
                    if (token) {
                      modalObject.setSelectedItem(selectedCrypto);
                      modalObject.setShowSubscribeModal(true);
                      modalObject.setSelectedPoolType(selectedPoolType);
                    } else {
                      modalObject.setAuthModalObject((pre) => ({
                        // ...pre,
                        selectedTab: AUTH_MODAL_TABS.LOG_IN,
                        isAuthOpen: true,
                      }));
                      setQuery({ action: "login" });
                    }
                  }}
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CalculateBox;
