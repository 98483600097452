import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSpinModal } from "../../hooks/useSpinModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGames } from "../../hooks/useGame";
import { GAME_OPTION_TABS, ROUTE_CONST } from "../../constants";
import { useSelector } from "react-redux";
import { useAuth } from "../../hooks/useAuth";
import { getAPIAuth } from "../../services/apiInstance";
import logo from "./../../assets/images/logo.jpeg";
import LoginSuccesIcon from "./../../assets/images/login-signup/succes-icon.svg";
import { useLoginSuccess } from "../../hooks/useLoginSuccess";

const LoginSuccessModal = () => {
  const { loginModalObject, setLoginModalObject } = useLoginSuccess();
  const { setGameObject } = useGames();
  const { token } = useAuth();
  const userDetails = useSelector((state) => state.userDetails);
  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();

  const handleClose = () => {
    setLoginModalObject((prev) => ({
      ...prev,
      isModalOpen: false,
    }));
  };

  const handlePlayGames = () => {
    handleClose();
    setGameObject((prev) => ({
      selectedTab: GAME_OPTION_TABS?.FREE_SPIN,
    }));
    // navigate(ROUTES_CONST.GAME_PAGE)
    setQuery({ q: "freeSpin" });
    navigate(`${ROUTE_CONST.CASINO}?q=${encodeURIComponent("freeSpin")}`);
  };

  const changeFreeSpinStatus = async () => {
    if (loginModalObject?.isModalOpen) {
      try {
        const res = await getAPIAuth(
          "user/updateFreeSpinStatus?flag=true",
          token
        );
      } catch (error) {}
    }
  };

  useEffect(() => {
    changeFreeSpinStatus();
  }, [loginModalObject?.isModalOpen]);

  useEffect(() => {
    if (userDetails?.freeSpinStatus === false) {
      // setLoginModalObject(prev=>({
      //   ...prev,
      //   isModalOpen: true
      // }))
    }
  }, [userDetails, token]);

  return (
    <>
      <Modal
        className="loginSuccessModal"
        onHide={handleClose}
        show={loginModalObject?.isModalOpen}
        centered
      >
        <Modal.Body className="d-flex align-items-center justify-content-center py-4">
          <div className="">
            <div className="row">
              <div className="">
                <div className="tittle-1 text-center">Congratulations!</div>
                <div className="d-flex justify-content-center my-3">
                  <img src={LoginSuccesIcon} alt="" />
                </div>
                <div className="tittle-2 text-center">You have successfully logged in.</div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginSuccessModal;
