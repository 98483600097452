
import React from "react";
import { Link } from "react-router-dom";
import userWallet from "./../../assets/images/wallet.svg";
import userWithdraw from "./../../assets/images/withdraw.svg";
import userTransactions from "./../../assets/images/transactions.svg";
import buy from "./../../assets/images/buy.svg";
import swap from "./../../assets/images/swap.svg";
import rollover from "./../../assets/images/rollover.svg";
import bitHistory from "./../../assets/images/bit-history.svg";
import deposit from "./../../assets/images/deposit.svg";
import totalBalance from "./../../assets/images/total-balance.svg";
import rupeeIcon from "./../../assets/images/rupee_icon.svg";
import USDT from "./../../assets/images/usdt.svg";
import BTC from "./../../assets/images/btc.svg";
import BNB from "./../../assets/images/bnb.svg";
import ETH from "./../../assets/images/eth.svg";
import TURBO from "./../../assets/images/turbo.svg";
import USDC from "./../../assets/images/usdc.svg";
import AVAX from "./../../assets/images/avax.svg";
import CBANK from "./../../assets/images/cbank.svg";
import CAF from "./../../assets/images/caf.svg";
import cairo from "./../../assets/images/cairo.svg";
import thunderIcon from "./../../assets/images/thunder-icon.svg";
import giftBox from "./../../assets/images/gift.svg";
import barImage from "./../../assets/images/barCode.svg";
import EUR from "./../../assets/images/eur.svg";
import sepaInstant from "./../../assets/images/sepa-instant.svg";
import Euro from "./../../assets/images/euro.svg";
import swapIcon from "./../../assets/images/swap-icon.svg";
import fileEmpty from "../../assets/images/fileEmpty.svg";


const BetHistoryTab = () => {
  return (
    <>
           <div className="betHistory_sec">
                          <div className="heading d-md-block d-none">
                            <h4 className="text-white">Bet History</h4>
                          </div>
                          <div className="betHistory_row p-3 bg_main_color">
                            <div className="row align-items-center g-4">
                              <div className="col-lg-4 col-md-6 col-12">
                                <div className="select_drop">
                                  <div className="btn-group w-100">
                                    <button
                                      className="d-flex justify-content-between  px-3 py-2 rounded_bg align-items-center border-0 shadow-none dropdown-toggle w-100 rounded-pill"
                                      type="button"
                                      id="defaultDropdown"
                                      data-bs-toggle="dropdown"
                                      data-bs-auto-close="true"
                                      aria-expanded="false"
                                    >
                                      <div className="d-flex align-items-center gap-2 text-white">
                                        Deposit
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu w-100 rounded_bg p-3"
                                      aria-labelledby="defaultDropdown"
                                    >
                                      <div className="searchInpDrop position-relative">
                                        <input type="text" placeholder="Search" />
                                        <button
                                          type="button"
                                          className="searchImgg border-0 shadow-none p-0 m-0 bg-transparent"
                                        >
                                          <svg
                                            stroke="#93A6C4"
                                            height="22px"
                                            widths="22px"
                                            fill="#93A6C4"
                                            stroke-width="0"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M443.5 420.2L336.7 312.4c20.9-26.2 33.5-59.4 33.5-95.5 0-84.5-68.5-153-153.1-153S64 132.5 64 217s68.5 153 153.1 153c36.6 0 70.1-12.8 96.5-34.2l106.1 107.1c3.2 3.4 7.6 5.1 11.9 5.1 4.1 0 8.2-1.5 11.3-4.5 6.6-6.3 6.8-16.7.6-23.3zm-226.4-83.1c-32.1 0-62.3-12.5-85-35.2-22.7-22.7-35.2-52.9-35.2-84.9 0-32.1 12.5-62.3 35.2-84.9 22.7-22.7 52.9-35.2 85-35.2s62.3 12.5 85 35.2c22.7 22.7 35.2 52.9 35.2 84.9 0 32.1-12.5 62.3-35.2 84.9-22.7 22.7-52.9 35.2-85 35.2z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                      <div className="currency_search_list overflow-y-auto">
                                        <ul class="m-0  d-flex flex-column gap-2 p-2 mt-3">
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={BTC} alt="" />
                                              BTC
                                            </div>
                                          </li>
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={ETH} alt="" />
                                              ETH
                                            </div>
                                          </li>
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={USDT} alt="" />
                                              USDT
                                            </div>
                                          </li>
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={USDC} alt="" />
                                              trx
                                            </div>
                                          </li>
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={BNB} alt="" />
                                              bnb
                                            </div>
                                          </li>
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={BTC} alt="" />
                                              BTC
                                            </div>
                                          </li>
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={ETH} alt="" />
                                              ETH
                                            </div>
                                          </li>
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={USDT} alt="" />
                                              USDT
                                            </div>
                                          </li>
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={USDC} alt="" />
                                              trx
                                            </div>
                                          </li>
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={BNB} alt="" />
                                              bnb
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="dropFooter mx-1 mb-1 d-flex align-items-center justify-content-between">
                                        <div class="footerTxt text-white fw-bold">
                                          Hide 0 Balance
                                        </div>
                                        <div>
                                          <form class="">
                                            <div class="footerToggle form-check form-switch">
                                              <input
                                                type="checkbox"
                                                id="custom-switch"
                                                class="form-check-input"
                                              />
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-12">
                                <div className="select_drop">
                                  <div className="btn-group w-100">
                                    <button
                                      className="d-flex justify-content-between px-3 py-2 rounded_bg align-items-center border-0 shadow-none dropdown-toggle w-100 rounded-pill"
                                      type="button"
                                      id="defaultDropdown"
                                      data-bs-toggle="dropdown"
                                      data-bs-auto-close="true"
                                      aria-expanded="false"
                                    >
                                      <div className="d-flex align-items-center gap-2 text-white">
                                        All Assets
                                      </div>
                                    </button>
                                    <ul
                                      className="dropdown-menu w-100 rounded_bg p-3"
                                      aria-labelledby="defaultDropdown"
                                    >
                                      <div className="searchInpDrop position-relative">
                                        <input type="text" placeholder="Search" />
                                        <button
                                          type="button"
                                          className="searchImgg border-0 shadow-none p-0 m-0 bg-transparent"
                                        >
                                          <svg
                                            stroke="#93A6C4"
                                            height="22px"
                                            widths="22px"
                                            fill="#93A6C4"
                                            stroke-width="0"
                                            viewBox="0 0 512 512"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path d="M443.5 420.2L336.7 312.4c20.9-26.2 33.5-59.4 33.5-95.5 0-84.5-68.5-153-153.1-153S64 132.5 64 217s68.5 153 153.1 153c36.6 0 70.1-12.8 96.5-34.2l106.1 107.1c3.2 3.4 7.6 5.1 11.9 5.1 4.1 0 8.2-1.5 11.3-4.5 6.6-6.3 6.8-16.7.6-23.3zm-226.4-83.1c-32.1 0-62.3-12.5-85-35.2-22.7-22.7-35.2-52.9-35.2-84.9 0-32.1 12.5-62.3 35.2-84.9 22.7-22.7 52.9-35.2 85-35.2s62.3 12.5 85 35.2c22.7 22.7 35.2 52.9 35.2 84.9 0 32.1-12.5 62.3-35.2 84.9-22.7 22.7-52.9 35.2-85 35.2z"></path>
                                          </svg>
                                        </button>
                                      </div>
                                      <div className="currency_search_list overflow-y-auto">
                                        <ul class="m-0  d-flex flex-column gap-2 p-2 mt-3">
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={BTC} alt="" />
                                              BTC
                                            </div>
                                          </li>
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={ETH} alt="" />
                                              ETH
                                            </div>
                                          </li>
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={USDT} alt="" />
                                              USDT
                                            </div>
                                          </li>
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={USDC} alt="" />
                                              trx
                                            </div>
                                          </li>
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={BNB} alt="" />
                                              bnb
                                            </div>
                                          </li>
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={BTC} alt="" />
                                              BTC
                                            </div>
                                          </li>
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={ETH} alt="" />
                                              ETH
                                            </div>
                                          </li>
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={USDT} alt="" />
                                              USDT
                                            </div>
                                          </li>
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={USDC} alt="" />
                                              trx
                                            </div>
                                          </li>
                                          <li class="cursor-pointer d-flex justify-content-between align-items-center dropList">
                                            <div class="d-flex align-items-center gap-2">
                                              <img src={BNB} alt="" />
                                              bnb
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                      <div class="dropFooter mx-1 mb-1 d-flex align-items-center justify-content-between">
                                        <div class="footerTxt text-white fw-bold">
                                          Hide 0 Balance
                                        </div>
                                        <div>
                                          <form class="">
                                            <div class="footerToggle form-check form-switch">
                                              <input
                                                type="checkbox"
                                                id="custom-switch"
                                                class="form-check-input"
                                              />
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-6 col-12">
                                <div className="">
                                  <input
                                    type="text"
                                    className="rounded_bg border-0 shadow-none rounded-pill px-3 py-2 w-100 text-white"
                                    id=""
                                    value="Past 60 Days"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="rollover_table mt-5">
                              <label htmlFor="" className="form-label">
                                <span className="pe-2">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    version="1.1"
                                    viewBox="0 0 16 16"
                                    height="20px"
                                    width="20px"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M8 1.5c-1.736 0-3.369 0.676-4.596 1.904s-1.904 2.86-1.904 4.596c0 1.736 0.676 3.369 1.904 4.596s2.86 1.904 4.596 1.904c1.736 0 3.369-0.676 4.596-1.904s1.904-2.86 1.904-4.596c0-1.736-0.676-3.369-1.904-4.596s-2.86-1.904-4.596-1.904zM8 0v0c4.418 0 8 3.582 8 8s-3.582 8-8 8c-4.418 0-8-3.582-8-8s3.582-8 8-8zM7 11h2v2h-2zM7 3h2v6h-2z"></path>
                                  </svg>
                                </span>
                                Fiat Deposit issues or Disputes
                              </label>
                              <div className="table-responsive w-100 table_sec">
                                <table class="table align-middle w-100">
                                  <thead>
                                    <tr>
                                      <th scope="col">Type</th>
                                      <th scope="col">Payment Method</th>
                                      <th scope="col">Amount</th>
                                      <th scope="col">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {/* <tr>
                                        <td>example</td>
                                        <td>example</td>
                                        <td>example</td>
                                        <td>example</td>
                                      </tr> */}
                                    <tr>
                                      <td colSpan={4} className="data_not_found">
                                        <div className="d-flex flex-column gap-3 py-5">
                                          <div className="empty_img">
                                            <img
                                              src={fileEmpty}
                                              alt=""
                                              style={{ height: "60px" }}
                                              className="img-fluid"
                                            />
                                          </div>
                                          <div className="text-center">
                                            <div>No info yet</div>
                                            <div>Invite friends to join you now!</div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
    </>
  )
}

export default BetHistoryTab