import React, { useState } from "react";
import { postAPI } from "../../services/apiInstance";
import { errorToaster } from "../../utils/toaster";
import { ROUTE_CONST, SESSION_TOKEN } from "../../constants";
import { useNavigate } from "react-router-dom";

const MasterInput = ({ isAuth, setIsAuth }) => {
  const [materPassword, setMaterPassword] = useState("");
  const navigate = useNavigate();
  const clickHandler = async () => {
    try {
      const res = await postAPI("user/checkMasterPassword", {
        password: materPassword,
      });
      if (res.data.success) {
        if (res.data) {
          sessionStorage.setItem(SESSION_TOKEN, "true");
          setIsAuth(res.data.data);
          console.log("in if --->>>");
        }
      } else {
        setIsAuth(res.data.data);
        sessionStorage.setItem(SESSION_TOKEN, null);
      }
    } catch (error) {
      errorToaster("Something went wrong");
    }
  };
  return (
    <main className="main vh-100" id="main">
      <div className="mainContaint h-100 m-0 ">
        <div className="homepage h-100">
          <div className="d-flex flex-column h-100 align-items-center justify-content-center">
            <div className="master-box">
              <h1 className="master-heading">Master Password</h1>
              <div>
                <input
                  type="text"
                  className="masterInput my-3 w-100 px-2 py-3 text-white rounded-1"
                  placeholder="Enter Master Password"
                  value={materPassword}
                  onChange={(e) => setMaterPassword(e.target.value)}
                />
              </div>
              <div>
                <button
                  className="masterButton profile-cta-btn w-100 "
                  onClick={clickHandler}
                >
                  Confirm Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default MasterInput;
