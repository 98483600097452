import React, { useState } from 'react'
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import toppicks from '../../assets/images/toppicks.png'
import slots from '../../assets/images/slots.png'
import hotgames from '../../assets/images/hotgames.png'
import livecasino from '../../assets/images/livecasino.png'
import popularVector from '../../assets/images/popularVector.png'
import flag from  '../../assets/images/home/flag.png'
import headingIcon from  '../../assets/images/home/popular-icon.png'
import  team1 from '../../assets/images/home/team1.png'
import  team2 from '../../assets/images/home/team2.png'
import { Spinner } from 'react-bootstrap';
import GameCard from '../GameCard/GameCard';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGames } from '../../hooks/useGame';
import { ROUTE_CONST } from '../../constants';
import { useTranslation } from 'react-i18next';
import { AuthBtContext } from './../../context/authBtContext';
import { useSelector } from 'react-redux';

const CasinoSlider = ({ title, icon, nextBtn, prevBtn, showTabs, showParentTab, data, loading, navigateUrl ,selected_id}) => {
  const [activeTab, setActiveTab] = useState('top');
  const navigate = useNavigate();
  const {gameObject, setGameObject} = useGames()
  const { t } = useTranslation()
  const casinoLabels = t("casinoLabels", { returnObjects: true })
  const [query, setQuery] = useSearchParams()
  const categoryPillOptions = useSelector(state => state.categoryPillOptions)

  console.log('categoryPillOptions', categoryPillOptions)

  return (
    <>
    <div className='col-12'>
      {/* <div className="row mx-0 align-items-center py-3 pt-md-0 sectionSpacing">
        {
          showParentTab && <>
            <div className="col-12 px-0 casinoTabOptionsWrapper overflow-hidden mb-2">
              <div className="row flex-nowrap casinoTabOptions text-nowrap overflow-x-auto gx-lg-1 pb-3">
                {
                  categoryPillOptions?.length ? 
                    categoryPillOptions?.map(item => (
                      <div className="col-auto">
                        <div 
                            onClick={() => {
                              setGameObject((prev) => ({
                                selectedTab: item?.category_name,
                                selectedId: item?._id
                              }))
                              // navigate(ROUTE_CONST.CASINO)
                              setQuery({ q: item?.category_name?.split(" ").join("")})
                              navigate(`${ROUTE_CONST.CASINO}?q=${encodeURIComponent(item?.category_name?.split(" ").join(""))}&categoryId=${encodeURIComponent(item?._id)}`)
                            }}
                            className={`casinoTabOption ${gameObject?.selectedId === item?._id ? 'active' : ''}`}> 
                          <img src={item?.image} alt="" /> 
                          {item?.category_name}
                        </div>
                      </div>
                    ))
                  : ''
                }
              </div>
            </div>
          </>
        }
        <div className="col-lg-12 col-12 px-0 d-flex justify-content-between mb-1 mt-1 pb-1 mb-lg-3 align-items-center">
          <div className='mainHeading d-flex align-items-center'><img src= {headingIcon} alt="" />Popular Matches</div>
          <div className="d-flex align-items-center">
            <div className='mainSubHeading'>
                
              <div
                onClick={()=>{
                  setGameObject((prev)=> ({
                      selectedTab : title,
                      selectedId : selected_id
                  }))
                  // navigate(ROUTE_CONST.CASINO)
                  setQuery({ q: title?.split(" ").join("")})
                  if(selected_id) {
                    navigate(`${ROUTE_CONST.CASINO}?q=${encodeURIComponent(title?.split(" ").join(""))}&categoryId=${encodeURIComponent(selected_id)}`)
                  } else {
                    navigate(`${ROUTE_CONST.CASINO}?q=${encodeURIComponent(title === 'Casino' ? 'lobby' : title)}`)
                  }

              }}
            className='headingAnchor'>
              
               Upcoming Events</div></div>
            <div className="casinoSliderBtns d-none d-lg-flex align-items-center">
              
              <div className={`sliderBtn ${nextBtn}`}>
                <img src="./assets/img/nextIcon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        {
          showTabs && <>
            <div className="col-12 px-0 casinoTabOptionsWrapper overflow-hidden d-lg-none">
              <div className="row flex-nowrap casinoTabOptions text-nowrap overflow-x-auto pb-3">
              {
                  categoryPillOptions?.length ? 
                    categoryPillOptions?.map(item => (
                      <div className="col-auto">
                        <div 
                            onClick={() => {
                              setGameObject((prev) => ({
                                selectedTab: item?.category_name,
                                selectedId: item?._id
                              }))
                              // navigate(ROUTE_CONST.CASINO)
                              setQuery({ q: item?.category_name?.split(" ").join("")})
                              navigate(`${ROUTE_CONST.CASINO}?q=${encodeURIComponent(item?.category_name?.split(" ").join(""))}&categoryId=${encodeURIComponent(item?._id)}`)
                            }}
                            className={`casinoTabOption ${gameObject?.selectedId === item?._id ? 'active' : ''}`}> 
                          <img src={item?.image} alt="" /> 
                          {item?.category_name}
                        </div>
                      </div>
                    ))
                  : ''
                }
              </div>
            </div>
          </>
        }
        <div className='px-0'>
          <Swiper
            slidesPerView={'auto'}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            navigation={{
              prevEl: `.${prevBtn}`,
              nextEl: `.${nextBtn}`,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              576: {
                slidesPerView: 1.5,
              },
              768: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 4,
              },
              1200: {
                slidesPerView: 3.25,
              },
              1400: {
                slidesPerView: 4,
              },
            }}
            modules={[Navigation]}
            className="mySwiper casinoSwiperWrapper"
          >
            {!loading ? (["","","","" ]?.map((item) => (
              <SwiperSlide className='popularcardsWidth'>

                 <div className='PopularGameCard '>
                  <div className='card-tp d-flex align-items-center gap-2'>
                    <div className='flag-img'><img src= {flag} alt=""/></div>
                    <span className='tp-title'>La Liga</span>
                  </div>
                  <div className='card-mid-sec'>
                    <div className='card-mid-inner d-flex align-items-center justify-content-between'>
                    <div>
                      <div className='team-img1'><img src={team1} alt=""/></div>
                      <p className='team-title text-center mt-1'>Real M+</p>
                    </div>
                    <div className='time-bx text-center'>
                      <p className='mb-1'>Tommorrow</p>
                      <h4 className='time-title'>14:40</h4>
                    </div>
                    <div>
                      <div className='team-img1'><img src={team2} alt=""/></div>
                      <p className='team-title text-center mt-1'>barcelona</p>
                    </div>
                    </div>
                  </div>
                  <div className='card-btm-sec d-flex -mx-2 px-2 justify-content-between'>
                    <div className='button-bx'>
                      <div className='content1'>1</div>
                      <div className='content2'>21.00</div>
                    </div>
                    <div className='button-bx'>
                      <div className='content1'>x</div>
                      <div className='content2'>8.20</div>
                    </div>
                    <div className='button-bx'>
                      <div className='content1'>2</div>
                      <div className='content2'>1.13</div>
                    </div>
                  </div>
                  </div>
                  
              </SwiperSlide>)
            )) : (
              <div className='text-center' >
                <Spinner
                  className="m-auto"
                  animation="border"
                  variant="light"
                  size="sm"
                />
              </div>
            )
            }
          </Swiper>
        </div>
      </div> */}
      </div>
    </>
  )
}

export default CasinoSlider