import React, { useState } from 'react'
import { useAuth } from '../../../hooks/useAuth'
import { useAuthModal } from '../../../hooks/useAuthModal';
import { AUTH_MODAL_TABS, CLICK_ID, NEW_CLICK_ID } from '../../../constants';
import { useSearchParams } from 'react-router-dom';
import CalculateCryptoEarningModal from './modals/CalculateCryptoEarningModal';
import { GoogleLogin } from '../../../components/AuthModal/components/socialLogin/GoogleLogin';
import TelegramLoginComp from '../../../components/AuthModal/components/socialLogin/TelegramLogin';
import MetaMaskLogin from '../../../components/AuthModal/components/socialLogin/MetamaskLogin';
import criptoHero  from '../../../assets/images/criptoome.png';
import dogIcon    from '../../../assets/images/DogIcon.png';
import goolgeIcon  from '../../../assets/images/googleIcon.png';
import teligramIcon  from '../../../assets/images/teligramIcon.png';
import calcIocn  from '../../../assets/images/calcIocn.png';


const CryptoHero = ({ data, setModalObject }) => {
  const {token} = useAuth()
  const [showCalculator, setShowCalculator] = useState(false);
  const {authModalObject, setAuthModalObject } = useAuthModal();
  const [query, setQuery] = useSearchParams()
  const [checks, setChecks] = useState({
    terms: true,
    promotions: true
})
const [bonus, setBonus] = useState({ bonus: false })
const click_id = sessionStorage.getItem(CLICK_ID) === 'null' ? null : sessionStorage.getItem(CLICK_ID) 
const new_click_id = sessionStorage.getItem(NEW_CLICK_ID) === 'null' ? null : sessionStorage.getItem(NEW_CLICK_ID) // setup in appHeader 
// setup in appHeader 


  return (
    <>
      <div className="row align-items-center cryptoHeroSection">
        <div className="col-12 d-none d-md-block position-relative" style={{zIndex: 9}}>
          <div className="d-flex align-items-center justify-content-end w-100 gap-2 mb-4">
            <button className='commonLightBtn' onClick={()=>setModalObject(prev=>({
              ...prev,
              show: true,
              type: 'flexible'
            }))}>About staking type</button>
            <button className='commonBlueBtn' onClick={()=>setShowCalculator(true)} >
              <img className='btnIcon' src={calcIocn} alt="" />
              Calculate
            </button>
          </div>
        </div>
        <div className="col-md-5">
          {
            !token ? <>
              <div className="subTitle d-none d-md-block">Sign Up and Earn</div>
            </> : ''
          }
          <div className="title text-center text-md-start">CRYPTO STAKING <br className='d-none d-md-block' /> APR UP TO 130%</div>

          <div className="heroCoinImg mx-auto d-md-none d-block" >
            <img className='h-100 w-100 object-fit-contain' src={criptoHero} alt="" />
          </div>

          {
            !token ?
              (<div className="d-flex align-items-center mt-3 flex-column flex-md-row mb-3 mb-md-0">
                <button
                  onClick={() => {
                    setAuthModalObject(pre => ({
                       // ...pre,
                      selectedTab: AUTH_MODAL_TABS.LOG_IN,
                      isAuthOpen: true,
                    }))
                    setQuery({ action: "login" })
                  }}
                  className='criptoBtn px-5 px-md-4 px-lg-5'>Sign in & Earn</button>
                <div className="orTxt px-2 py-2 py-md-0">Or</div>
                <div className="d-flex align-items-center">
                  <GoogleLogin
                        parent={'signin'}
                        checks={checks}
                        click_id={click_id}
                        new_click_id={new_click_id}
                        bonus={bonus}
                    >
                      <button className='stackingSocialBtn'>
                      <img className='google' src={dogIcon } alt="" />
                      </button>
                    </GoogleLogin>
                  <button className='stackingSocialBtn'>
                    <TelegramLoginComp
                        checks={checks}
                        authModalObject={authModalObject}
                        click_id={click_id}
                        new_click_id={new_click_id}
                        bonus={bonus}
                        parent={'signin'}
                        isFromCrypto={true}
                    />
                    {/* <img src="./assets/img/stacking/telegramWhite.png" alt="" /> */}
                  </button>
                  <button className='stackingSocialBtn'>
                    {/* <img src="./assets/img/stacking/metamask.png" alt="" /> */}
                    <MetaMaskLogin
                        parent={'signin'}
                        checks={checks}
                        click_id={click_id}
                        new_click_id={new_click_id}
                        bonus={bonus}
                        isFromCrypto={true}
                    />
                  </button>
                </div>
              </div>):("")
          }
        </div>
        <div className="col-md-7 d-flex justify-content-center d-md-block d-none">
          <div className="heroCoinImg">
            <img className='h-100 w-100 object-fit-contain' src={criptoHero} alt="" />
          </div>
        </div>
      </div>
      <CalculateCryptoEarningModal show={showCalculator} setShow={setShowCalculator} data={data} />
    </>
  )
}

export default CryptoHero