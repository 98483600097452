import React from "react";

const CasinoIcon = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="#2F93DD"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.7701 4.4548L10.9568 9.98182C10.8424 10.3662 10.6416 10.7193 10.3697 11.0141C10.0979 11.3088 9.76217 11.5375 9.38834 11.6824C9.58691 11.2197 9.68905 10.7213 9.68853 10.2178V4.90334C9.68741 3.91705 9.29521 2.97148 8.59797 2.27407C7.90074 1.57665 6.95542 1.18435 5.96938 1.18323H5.08475C5.41283 0.749107 5.86589 0.425825 6.38304 0.256815C6.9002 0.0878055 7.45668 0.0811658 7.97772 0.237788L10.9987 1.16569C11.6656 1.37292 12.2247 1.83346 12.5559 2.44843C12.8871 3.06339 12.964 3.78374 12.7701 4.4548ZM8.62592 4.90334V10.2178C8.62507 10.9223 8.34492 11.5976 7.8469 12.0958C7.34889 12.5939 6.67368 12.8742 5.96938 12.875H2.78154C2.07724 12.8742 1.40203 12.5939 0.904013 12.0958C0.405999 11.5976 0.125844 10.9223 0.125 10.2178L0.125 4.90334C0.125844 4.19886 0.405999 3.52347 0.904013 3.02533C1.40203 2.52719 2.07724 2.24696 2.78154 2.24612H5.96938C6.67368 2.24696 7.34889 2.52719 7.8469 3.02533C8.34492 3.52347 8.62507 4.19886 8.62592 4.90334ZM6.50069 7.02911C6.50069 6.74722 6.38873 6.47687 6.18945 6.27754C5.99018 6.07821 5.7199 5.96623 5.43807 5.96623C5.15625 5.96623 4.88597 6.07821 4.68669 6.27754C4.48741 6.47687 4.37546 6.74722 4.37546 7.02911C4.37546 6.74722 4.2635 6.47687 4.06423 6.27754C3.86495 6.07821 3.59467 5.96623 3.31284 5.96623C3.03102 5.96623 2.76074 6.07821 2.56146 6.27754C2.36218 6.47687 2.25023 6.74722 2.25023 7.02911C2.25023 7.79971 3.07057 8.83124 3.6922 9.35578C3.88319 9.51741 4.12528 9.60611 4.37546 9.60611C4.62564 9.60611 4.86773 9.51741 5.05872 9.35578C5.68035 8.83124 6.50069 7.79971 6.50069 7.02911Z" />
    </svg>
  );
};

export default CasinoIcon;
