import React from 'react'
import UsdtLogo from "../../assets/images/affilate/usdtLogo.svg";
import Dollar from "../../assets/images/dollar.svg";
import One from "../../assets/images/one.svg";
import Two from "../../assets/images/two.svg";
import Three from "../../assets/images/three.svg";
import GreenCoin from "../../assets/images/green-coin.svg";
import Level1 from "../../assets/images/affilate/level-1.svg";
import Level2 from "../../assets/images/affilate/level-2.svg";
import Level3 from "../../assets/images/affilate/level-3.svg";
import Level4 from "../../assets/images/affilate/level-4.svg";
import Level5 from "../../assets/images/affilate/level-5.svg";
import Level6 from "../../assets/images/affilate/level-6.svg";
import Level7 from "../../assets/images/affilate/level-7.svg";
import Level8 from "../../assets/images/affilate/level-8.svg";
import Level9 from "../../assets/images/affilate/level-9.svg";
import Level10 from "../../assets/images/affilate/level-10.svg";

const RateRules = () => {
  return (
    <div
                    class="tab-pane fade"
                    id="nav-rate"
                    role="tabpanel"
                    aria-labelledby="nav-rate-tab"
                  >
                    <div>
                      <div className="bg_main_color position-relative">
                        <div className="row">
                          <div className="col-lg-8">
                            <div className="commisson-rate">
                              <h6 className="mb-3">Commission Reward Rate</h6>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-8 order-lg-first order-last">
                            <div>
                              <div className="mt-3">
                                <h6 className="mb-4">Casino</h6>
                                <div className="row">
                                  <div className="col-lg-5 col-md-6 col-12 mb-3 mb-md-0">
                                    <div className="original-game-box">
                                      <h6 className="mb-3">
                                        The Original Games
                                      </h6>
                                      <div className="original-game-text">
                                        <p className="mb-0 text-center">
                                          Wager× 1% ×{" "}
                                          <span>Commission Rate</span> ×{" "}
                                          <span>28%</span>{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-5 col-md-6 col-12 ">
                                    <div className="original-game-box">
                                      <h6 className="mb-3">
                                        3rd Party Slots, Live Casino
                                      </h6>
                                      <div className="original-game-text">
                                        <p className="mb-0 text-center">
                                          Wager× 1% ×{" "}
                                          <span>Commission Rate</span> ×{" "}
                                          <span>28%</span>{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-4">
                                <h6 className="mb-3">All Sports</h6>
                                <div className="row">
                                  <div className="col-lg-10 col-12">
                                    <div className="original-game-box">
                                      <div className="original-game-text">
                                        <p className="mb-0 text-center">
                                          Wager× 1% ×{" "}
                                          <span>Commission Rate</span> ×{" "}
                                          <span>28%</span>{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 order-first order-lg-last">
                            <div className="d-flex align-items-center justify-content-center">
                              <img src={Dollar} alt="" className="img-fluid" />
                            </div>
                          </div>
                        </div>
                        <div className="position-absolute ViewRules">
                          <div>
                            <button
                              class="dropdown-toggle"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#ViewRules"
                            >
                              View Rules
                            </button>
                            {/* view rules modal */}
                            <div
                              class="modal fade"
                              id="ViewRules"
                              tabindex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div class="modal-dialog">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h1
                                      class="modal-title"
                                      id="exampleModalLabel"
                                    >
                                      Rules
                                    </h1>
                                    <div className="custom-close-btn">
                                      <button
                                        type="button"
                                        class="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                  </div>
                                  <div class="modal-body">
                                    <h6 className="mb-3">
                                      {" "}
                                      In any public environment (eg,
                                      universities, schools, libraries, and
                                      office spaces), only one commission can be
                                      paid to each user, IP address, electronic
                                      device, home, phone number, billing
                                      method, email address, and computer and IP
                                      address shared with others.Our decision to
                                      draw a bet will be based entirely on our
                                      discretion after a deposit is made and a
                                      bet is successfully placed. We support the
                                      majority of currencies on the market.
                                      Commissions can be withdrawn into our
                                      internal BCgame wallet at anytime. (View
                                      your commission extraction in the
                                      dashboard and view the balance in the
                                      wallet). The system calculates the
                                      commission every 24 hours..
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg_main_color mt-4">
                        <div className="row">
                          <div className="col-lg-8">
                            <div className="commisson-rate">
                              <h6 className="mb-3">Commission Calculator</h6>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-8 order-lg-first order-last">
                            <div>
                              <div className="mt-3">
                                <div className="row">
                                  <div className="col-5 ">
                                    <div className="original-game-box">
                                      <h6 className="mb-3">Wager (USDT)</h6>
                                      <div className="original-game-text">
                                        <p className="mb-0 ">0</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-2">
                                    <div className="original-game-box h-100">
                                      <h6 className="mb-3 h-100 d-flex align-items-end pb-3 justify-content-center">
                                        × 1% ×
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="col-5">
                                    <div className="original-game-box">
                                      <h6 className="mb-3">Commission Rate</h6>
                                      <div className="original-game-text">
                                        <p className="mb-0 ">25% </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-4">
                                <h6 className="mb-3">Game</h6>
                                <div className="row">
                                  <div className="col-12">
                                    <div className="original-game-box">
                                      <div class="dropdown">
                                        <button
                                          class="btn btn-secondary dropdown-toggle"
                                          type="button"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          Original Games ( 28% )
                                        </button>
                                        <ul class="dropdown-menu w-100">
                                          <li>
                                            <a class="dropdown-item" href="#">
                                              Action
                                            </a>
                                          </li>
                                          <li>
                                            <a class="dropdown-item" href="#">
                                              Another action
                                            </a>
                                          </li>
                                          <li>
                                            <a class="dropdown-item" href="#">
                                              Something else here
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className="total-usdt">=0.70 USDT</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 order-first order-lg-last">
                            <div className="d-flex align-items-center justify-content-center">
                              <img
                                src={UsdtLogo}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg_main_color mt-4">
                        <div className="Referral-Reward">
                          <h6 className="mb-3">
                            How to Get your Referral Reward
                          </h6>
                        </div>
                        <div className="py-3">
                          <div className="row ">
                            <div className="col-lg-4 mb-lg-0 mb-3">
                              <div className="refferal-steps d-flex align-items-center justify-content-between gap-2">
                                <div className="flex-shrink-0">
                                  <img src={One} alt="" className="img-fluid" />
                                </div>
                                <div>
                                  <h6 className="steps-headline">
                                    <span>Share</span> to friends
                                  </h6>
                                  <p className="steps-detail mb-0">
                                    Share your referral link or code to your
                                    friends Share your referral link or code to
                                    your friends
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 mb-lg-0 mb-3">
                              <div className="refferal-steps d-flex align-items-center justify-content-between gap-2">
                                <div className="flex-shrink-0">
                                  <img src={Two} alt="" className="img-fluid" />
                                </div>
                                <div>
                                  <h6 className="steps-headline">
                                    Get <span> $1000</span>
                                  </h6>
                                  <p className="steps-detail mb-0">
                                    Share your referral link or code to your
                                    friends Share your referral link or code to
                                    your friends
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4 mb-lg-0 mb-3">
                              <div className="refferal-steps d-flex align-items-center justify-content-between gap-2">
                                <div className="flex-shrink-0">
                                  <img
                                    src={Three}
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <div>
                                  <h6 className="steps-headline">
                                    Level Up & <span>Receive</span> to friends
                                  </h6>
                                  <p className="steps-detail mb-0">
                                    Share your referral link or code to your
                                    friends Share your referral link or code to
                                    your friends
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="vip-table">
                          <div className="vip-table-header">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="heading">Friend’s Level</div>
                              <div className="heading">Total Wager</div>
                              <div className="heading">Unlock Amount</div>
                            </div>
                          </div>
                          <div className="vip-table-body">
                            {/* level 1 */}
                            <div className="d-flex align-items-center justify-content-between vip-table-row">
                              <div className="table-data">
                                {" "}
                                <img
                                  src={Level1}
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                VIP 04
                              </div>
                              <div className="table-data">1000</div>
                              <div className="table-data table-data-green">
                                +0.50{" "}
                                <img
                                  src={GreenCoin}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            {/* level 2 */}
                            <div className="d-flex align-items-center justify-content-between vip-table-row">
                              <div className="table-data">
                                {" "}
                                <img
                                  src={Level2}
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                VIP 04
                              </div>
                              <div className="table-data">1000</div>
                              <div className="table-data table-data-green">
                                +0.50{" "}
                                <img
                                  src={GreenCoin}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            {/* level 3 */}
                            <div className="d-flex align-items-center justify-content-between vip-table-row">
                              <div className="table-data">
                                {" "}
                                <img
                                  src={Level3}
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                VIP 04
                              </div>
                              <div className="table-data">1000</div>
                              <div className="table-data table-data-green">
                                +0.50{" "}
                                <img
                                  src={GreenCoin}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            {/* level 4 */}
                            <div className="d-flex align-items-center justify-content-between vip-table-row">
                              <div className="table-data">
                                {" "}
                                <img
                                  src={Level4}
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                VIP 04
                              </div>
                              <div className="table-data">1000</div>
                              <div className="table-data table-data-green">
                                +0.50{" "}
                                <img
                                  src={GreenCoin}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            {/* level 5 */}
                            <div className="d-flex align-items-center justify-content-between vip-table-row">
                              <div className="table-data">
                                {" "}
                                <img
                                  src={Level5}
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                VIP 04
                              </div>
                              <div className="table-data">1000</div>
                              <div className="table-data table-data-green">
                                +0.50{" "}
                                <img
                                  src={GreenCoin}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            {/* level 6 */}
                            <div className="d-flex align-items-center justify-content-between vip-table-row">
                              <div className="table-data">
                                {" "}
                                <img
                                  src={Level6}
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                VIP 04
                              </div>
                              <div className="table-data">1000</div>
                              <div className="table-data table-data-green">
                                +0.50{" "}
                                <img
                                  src={GreenCoin}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            {/* level 7 */}
                            <div className="d-flex align-items-center justify-content-between vip-table-row">
                              <div className="table-data">
                                {" "}
                                <img
                                  src={Level7}
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                VIP 04
                              </div>
                              <div className="table-data">1000</div>
                              <div className="table-data table-data-green">
                                +0.50{" "}
                                <img
                                  src={GreenCoin}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            {/* level 8 */}
                            <div className="d-flex align-items-center justify-content-between vip-table-row">
                              <div className="table-data">
                                {" "}
                                <img
                                  src={Level8}
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                VIP 04
                              </div>
                              <div className="table-data">1000</div>
                              <div className="table-data table-data-green">
                                +0.50{" "}
                                <img
                                  src={GreenCoin}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            {/* level 9 */}
                            <div className="d-flex align-items-center justify-content-between vip-table-row">
                              <div className="table-data">
                                {" "}
                                <img
                                  src={Level9}
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                VIP 04
                              </div>
                              <div className="table-data">1000</div>
                              <div className="table-data table-data-green">
                                +0.50{" "}
                                <img
                                  src={GreenCoin}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                            {/* level 10 */}
                            <div className="d-flex align-items-center justify-content-between vip-table-row">
                              <div className="table-data">
                                {" "}
                                <img
                                  src={Level10}
                                  alt=""
                                  className="img-fluid"
                                />{" "}
                                VIP 04
                              </div>
                              <div className="table-data">1000</div>
                              <div className="table-data table-data-green">
                                +0.50{" "}
                                <img
                                  src={GreenCoin}
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  )
}

export default RateRules
