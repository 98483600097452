import React, { useEffect, useState } from 'react'
import inr from '../../../assets/images/profile/inr.png'
import caf from '../../../assets/images/profile/caf.png'
import rightArrow from '../../../assets/images/profile/rightArrow.png'
import fees from '../../../assets/images/profile/fees.png'
import visa from '../../../assets/images/profile/visa.png'
import exclamation from '../../../assets/images/exclamation.png'
import arrowryt from '../../../assets/images/arrowryt.png'
import { useAuth } from '../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from 'react-bootstrap/Dropdown';
import { getAPIAuth } from '../../../services/apiInstance'
import { selectFiat } from '../../../store/action'

const FiatWithdraw = () => {
    const { token } = useAuth();
    const { t } = useTranslation();
    const walletLabels = t("wallet", { returnObjects: true });
    const [currency, setCurrency] = useState([]);
    const fiat = useSelector(state => state?.selectedCurrency?.fiat)
    const [selectedCurrency, setSelectedCurrency] = useState(fiat);
    const [amount, setAmount] = useState("")
    const userDetails = useSelector(state => state?.userDetails)
    const userBalance = useSelector(state => state.userBalance)
  const [selectedCurrencyBalance, setSelectedCurrencyBalance] = useState({})


    console.log('selectedCrypto', selectedCurrency)

    const getUserCurrencyAmount = async ()=> {
      try {
          const res = await getAPIAuth(`crypto/crypto-wallet?cryptoId=${selectedCurrency?._id}`)
          if(res?.data?.success) {
            setSelectedCurrencyBalance(res?.data?.data)
          }
      } catch (error) {
          
      }
  }
  
  useEffect(()=> {
    if(selectedCurrency?._id) {
      getUserCurrencyAmount()
    }
  }, [selectedCurrency])

    const dispatch = useDispatch()
  
    useEffect(() => {
      if (fiat?._id) {
        setSelectedCurrency(fiat)
      }
    }, [fiat?._id])
    const getCurrency = async () => {
      try {
        const res = await getAPIAuth(`currency/get-currency?userId=${userDetails?.id}`, token)
        setCurrency(res.data.data);
        if(!fiat?._id){
          setSelectedCurrency(res.data.data[0]);
  
        }
        if (res.data.success) {
          // setAvailCrypto(res.data.data)
          // const data = res?.data?.data?.filter(item => item?.code !== 'eth')
          // setSelCryptoNetwork(res.data.data)
        }
      } catch (error) {
      }
    }
    useEffect(() => {
      getCurrency();
    }, [])

  return (
    <>
                    <div className='col-md-8 col-sm-11 col-12'>
                        <div className='mainTabs mt-3'>
                            <div className='row'>
                                <div className='col-12 '>
                                    <div className='fletSection'>
                                        <div className='row'>
                                            <div className='col'>
                                                <div className='headingTabs'>Withdraw currency</div>
                                            </div>
                                            <div className='col-auto'>
                                                {/* <div className='flatdiv d-flex align-items-center gap-5'>
                                                    <div className='flatHeading'>INR</div>
                                                    <div>
                                                        <img src={inr} alt="inr" className='inrLogo' />
                                                        <img src={rightArrow} alt="rightArrow" className='rightArrow' />
                                                    </div>
                                                </div> */}
                                                            <Dropdown className='fialDropdown' align={'end'}>
                                                                    <Dropdown.Toggle id="dropdown-basic">
                                                                        {selectedCurrency.name}
                                                                        <img src={selectedCurrency.icon} alt="" />
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu className='currDropMenu'>
                                                                        <div className="header">
                                                                        <div class="searchInp">
                                                                            <img src="./assets/img/searchGray.png" alt="" />
                                                                            <input type="text" class="form-control shadow-none" placeholder="Search" />
                                                                        </div>
                                                                        </div>
                                                                        <div className="body">
                                                                        {currency?.length === 0 ? (
                                                                            <div className="w-100 h-100">
                                                                            <h6 className="text-light"> No data available</h6>
                                                                            </div>
                                                                        ) : (
                                                                            currency?.map((item) => (
                                                                            <Dropdown.Item key={item.id} className='menuItem' as="button" onClick={() => {
                                                                                setSelectedCurrency(item)
                                                                                dispatch(selectFiat(item))
                                                                            }}>
                                                                                <div className="country">
                                                                                <div className="img">
                                                                                    <img src={item.icon} alt="" />
                                                                                </div>
                                                                                {item.name}
                                                                                </div>
                                                                                <div className="cName">{item.code}</div>
                                                                                <div className="prizeeee">{item?.wallet?.totalBalance ? Number(item?.wallet?.totalBalance).toFixed(2) : '0.00'}</div>
                                                                            </Dropdown.Item>
                                                                            )))}
                                                                        </div>
                                                                        <div className="footer">
                                                                        <img src="./assets/img/weAccept.png" alt="" />
                                                                        </div>
                                                                    </Dropdown.Menu>
                                                                    </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 mt-3'>
                                    <div className='headingTabs'>Withdraw Amount</div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='usdtSection p-0'>
                                                <div className='row'>
                                                    <div className='col'>
                                                        {/* <div className='d-flex align-items-center headingUsdt  '>
                                                        0.0000
                                                        </div> */}
                                                        <input type="text" placeholder="Enter amount" className='commonInp withdrawInp' 
                                                                    value={amount}
                                                                    onChange={e => {
                                                                        if (isNaN(Number(e.target.value))) return
                                                                        setAmount(e.target.value)
                                                                        // priceConverter(e)
                                                                    }}/>
                                                    </div>
                                                    {/* <div className="col-auto">
                                                        <div className='rightArrow'>
                                                            <img src={rightArrow} alt="rightArrow" />       
                                                        </div>
                                                    </div>  */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className='headingTabs'>Available <span className='headingValue'>{selectedCurrencyBalance?.totalBalance ? selectedCurrencyBalance?.totalBalance?.toFixed(2) : '0.00'} {selectedCurrencyBalance?.coin ? selectedCurrencyBalance?.coin?.toUpperCase() : ''}</span></div>
                                        </div>
                                        <div className='col-auto'>
                                            <div className='headingTabs'>Locked funds: <span className='headingValue'>$ {userBalance?.lockedBonus || userBalance?.lockedBonus === 0 ? Number(userBalance?.lockedBonus).toFixed(2) : "0"}</span></div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className='widthdrawAmmount'>
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <div className='subHeading'>Withdraw Amount</div>
                                                            </div>
                                                            <div className='col-auto'>
                                                                <div className='headingUsdt'>{amount ? amount : "0.00"} {selectedCurrency?.code?.toUpperCase()}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <div className='subHeading d-flex align-items-center'>Fee <span className='feesIcon'><img src={fees} alt="fees" /></span></div>
                                                            </div>
                                                            <div className='col-auto'>
                                                                <div className='headingUsdt'>0.00 {selectedCurrency?.code?.toUpperCase()}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <div className='subHeading d-flex align-items-center'>Total withdrawal amount</div>
                                                            </div>
                                                            <div className='col-auto'>
                                                                <div className='headingUsdt sucess'>{amount ? amount : "0.00"} {selectedCurrency?.code?.toUpperCase()}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                               
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-2">
                                    <div className="fiatHeading py-2">Choose a payment method</div>
                                    <div className="recommandBox mt-2">
                                        <div className='headingTabs'>Recommended</div>
                                        <div className="cardWrapper">
                                            <div className="row h-100">
                                                <div className="col h-100 pe-0">
                                                    <div className="cardLeft h-100">
                                                        <div>
                                                            <div className="cardImg mb-1"><img src={visa} alt="" /></div>
                                                        </div>
                                                        <div className='divider'></div>
                                                        <div>
                                                            <div className='creditText'>Credit Card</div>
                                                            <div className='creditGray'>EAT: 1 min</div>
                                                        </div>
                                                        <div className='eurtxt'>10~500 EUR</div>
                                                        <div className="rytArroww"><img src={arrowryt} alt="" /></div>
                                                    </div>
                                                </div>
                                                <div className="col-auto d-flex flex-column justify-content-between pb-3">
                                                    <div className='fastestTxt'>Fastest</div>
                                                    <div className='exclaImg ms-auto me-2'><img src={exclamation} alt="" /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 mt-2'>
                                    <div className='noticeSection'>
                                        <div className='noticeHeading'>Notice: For security purposes, large or suspicious withdrawal may take 1-6 hours for audit process. We appreciate your patience!</div>
                                    </div>
                                </div>
                                <div className='col-12 mt-sm-4 mt-3'>
                                    <button class="changeBtn mx-auto">Withdraw</button>
                                </div>
                            </div>
                        </div>
                    </div>
    </>
  )
}

export default FiatWithdraw