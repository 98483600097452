import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Chart from '../calculateEarning/components/Chart';
import CalculateBox from '../calculateEarning/components/CalculateBox';
import { useAuth } from '../../../../hooks/useAuth';
import { postAPIAuth } from '../../../../services/apiInstance';


const CalculateCryptoEarningModal = ({ show,setShow,data }) => {

  const handleClose = () => setShow(false);
  const [ profit,setProfit ] = useState(null);

  const { token } = useAuth();
  const [ inputValue,setInputValue ] = useState("");
  const [ selectedCrypto,setSelectedCrypto ] = useState({});
  const [ selectedPoolType,setSelectedPoolType ] = useState({});
  const [ selectedTabValue,setSelectedTabValue ] =useState({});
  const [ selectedYear,setSelectedYear ]=useState(1);
  const [flexible, setFlexible] = useState([])
  const [fixedTerm, setFixedTerm] = useState([])
  const [boostedFixedTerm, setBoostedFixedTerm] = useState([])

  const getProfits = async(body)=>{
    try {
      const res =await postAPIAuth("crypto/calculate-profit",body,token);
      setProfit(res?.data?.data?.usdt_profit);

    } catch (error) {
      setProfit(null);
    }
  }

  useEffect(() => {
    const flexibleData = selectedCrypto?.term?.filter(item => item?.poolType === "Flexible")
    setFlexible(flexibleData?.[0])
    const fixedTermData = selectedCrypto?.term?.filter(item => item?.poolType === "Fixed-Term")
    setFixedTerm(fixedTermData?.[0])
    const boostedFixedTermData = selectedCrypto?.term?.filter(item => item?.poolType === "Boosted Fixed-Term")
    setBoostedFixedTerm(boostedFixedTermData?.[0])
}, [selectedCrypto])

  useEffect(()=>{
    const timer = setTimeout(()=>{
      if(inputValue){
        getProfits({
          crypto_id:selectedCrypto?.currency?._id,
          amount:Number(inputValue),
          apr:30,
          tab_val:selectedTabValue?.name?selectedTabValue?.name:0,
          year:selectedYear,
          pool_type:selectedPoolType?.poolType,
          cryptostakingparamter: selectedPoolType?.poolType === 'Fixed-Term' ? fixedTerm?._id : selectedPoolType?.poolType === "Boosted Fixed-Term" ? boostedFixedTerm?._id : flexible?._id
      })
      }else{
        setProfit(null);
      }
    },300);
    
    return ()=>clearTimeout(timer);

  },[inputValue,selectedCrypto,selectedPoolType,selectedTabValue,selectedYear]);//eslint-disable-line


  return (
    <>
      <Modal className='stackingCalculateModal' 
        show={show} 
        centered 
        scrollable 
        onHide={handleClose}
        size="lg"
        // show={lgShow}
        aria-labelledby="example-modal-sizes-title-lg"
        >
        <Modal.Header>
          <div className="row align-items-center w-100 mx-0">
            <div className="col modalTitle ps-0">Calculate your crypto earnings</div>
            <div className="col-auto pe-0">
              <div className="closeBTn cursor-pointer" onClick={handleClose}>
                <svg className='size-6 text-white' stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path></svg>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className='p-0'>
          <div className="row calculateCryptoEarning">
            <div className="col-12 calculateCryptoEarningInner">
              <CalculateBox 
              data={data} 
              inputValue={inputValue} 
              setInputValue={setInputValue}
              selectedCrypto={selectedCrypto} 
              setSelectedCrypto={setSelectedCrypto}
              selectedPoolType={selectedPoolType}
              setSelectedPoolType={setSelectedPoolType} 
              selectedTabValue={selectedTabValue}
              setSelectedTabValue={setSelectedTabValue}
              />
            </div>
            <div className="col-12 mt-3">
              <div className="charBox">
                <div className="row">
                  <div className="col-sm-3 col-12"> 
                    <div className="">
                        <div className=""><div className="estimateText">Estimated Earnings</div></div>
                        <div className=""><div className="estimategreenText">{!isNaN(profit) && profit !== null?`+$${profit}`:""}</div></div>
                        <div className=""><div className="estimatelightText">{!isNaN(profit) && profit !== null ?`${profit} ${selectedCrypto?.currency?.name}`:""}</div></div>
                    </div>    
                  </div>
                  <div className="col-sm-9 col-12">
                    <div className="stackingTabs w-100">
                        <button onClick={()=>setSelectedYear(1)} className={`stackingTab w-100 ${selectedYear === 1 ? 'active' : ''}`}>1 year</button>
                        <button onClick={()=>setSelectedYear(2)} className={`stackingTab w-100 ${selectedYear === 2 ? 'active' : ''}`}>2 year</button>
                        <button onClick={()=>setSelectedYear(3)} className={`stackingTab w-100 ${selectedYear === 3 ? 'active' : ''}`}>3 year</button>
                        <button onClick={()=>setSelectedYear(5)} className={`stackingTab w-100 ${selectedYear === 5 ? 'active' : ''}`}>5 year</button>
                    </div>
                  </div>
                </div>
              <Chart selectedYear={selectedYear}/>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CalculateCryptoEarningModal