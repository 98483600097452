import React, { useState } from "react";
import gaming from "./../../assets/images/gaming.png";
import brands from "./../../assets/images/brands.png";
import question from "./../../assets/images/footer/question.png";
import wind from "./../../assets/images/footer/window.png";
import fiba from "./../../assets/images/footer/fiba.png";
import atp from "./../../assets/images/footer/atp.png";
import itf from "./../../assets/images/footer/itf.png";
import wta from "./../../assets/images/footer/wta.png";
import ufc from "./../../assets/images/footer/ufc.png";
import uefa from "./../../assets/images/footer/ufea.png";
import fifa from "./../../assets/images/footer/fifa.png";
import nhl from "./../../assets/images/footer/nhl.png";
import insta from "./../../assets/images/footer/instagram.png";
import twitter from "./../../assets/images/footer/twitter.png";
import joystick from "./../../assets/images/footer/joystick.png";
import telegram from "./../../assets/images/footer/telegram.png";
import emog from "./../../assets/images/footer/emog.png";
import facebook from "./../../assets/images/footer/facebook.png";
import n1 from "./../../assets/images/footer/n1.png";
import n2 from "./../../assets/images/footer/n2.png";
import n3 from "./../../assets/images/footer/n3.png";
import n4 from "./../../assets/images/footer/n4.png";
import n5 from "./../../assets/images/footer/n5.png";
import n6 from "./../../assets/images/footer/n6.png";
import n7 from "./../../assets/images/footer/n7.png";
import n8 from "./../../assets/images/footer/n8.png";
import n9 from "./../../assets/images/footer/n9.png";
import n10 from "./../../assets/images/footer/n10.png";
import n11 from "./../../assets/images/footer/n11.png";
import n12 from "./../../assets/images/footer/n12.png";
import n13 from "./../../assets/images/footer/n13.png";
import n14 from "./../../assets/images/footer/n14.png";
import n15 from "./../../assets/images/footer/n15.png";
import n16 from "./../../assets/images/footer/n16.png";
import n17 from "./../../assets/images/footer/n17.png";
import n18 from "./../../assets/images/footer/n18.png";
import sigma from "./../../assets/images/footer/sigmafooter.png";
import itech from "./../../assets/images/footer/itechfooter.png";
import eighteen from "./../../assets/images/footer/eighteenplus.png";
import responsible from "./../../assets/images/footer/responsiblegaming.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTE_CONST } from "../../constants";
import facebookIcon from "./../../assets/images/footer/facebook.svg";
import instagramIcon from "./../../assets/images/footer/instagram.svg";
import shareIcon from "./../../assets/images/footer/share.svg";
import twitterIcon from "./../../assets/images/footer/twitter.svg";
import Country from "./../../assets/images/country.png";
import arrow from "./../../assets/images/sidebar/arrow.svg";
import GCB from "./../../assets/images/footer/GCB.svg";
import footer1 from "./../../assets/images/footer/footer1.svg";
import footer2 from "./../../assets/images/footer/footer2.svg";
import footer3 from "./../../assets/images/footer/footer3.svg";
import footer4 from "./../../assets/images/footer/footer4.svg";
import footer5 from "./../../assets/images/footer/footer5.svg";
import footer6 from "./../../assets/images/footer/footer6.svg";
// import CasinoLogo from './../../assets/images/CASINOLOGO.svg'
import CasinoLogo from "./../../assets/images/LOGO.svg";
import UnAuthCurrencyModal from "../header/components/UnAuthCurrencyModal";

const Footer = () => {
  const { t } = useTranslation();
  const [showLanguage, setShowLanguage] = useState(false);
  const appFooterLabels = t("appFooter", { returnObjects: true });
  console.log(showLanguage, "jjdjjdjdhijljjdo");
  return (
    <>
      <UnAuthCurrencyModal isOpen={showLanguage} setIsOpen={setShowLanguage} />
      <footer>
        {/* for mobile */}
        <div className="container-fluid footer px-0 d-block d-xl-none">
          <div className="container">
            <div className="row d-md-none d-block">
              <div className="col py-3 logoLinkCol d-flex align-items-center justify-content-center">
                <Link
                  to={"/"}
                  className="logoLink text-decoration-none d-block"
                >
                  <img
                    src={CasinoLogo}
                    alt="logo"
                    className="h-100 w-100 object-fit-contain"
                  />
                </Link>
              </div>
            </div>

            {/* <div className="row gx-4 mx-0 borderLight d-none">
                        <div className="col-4 px-0">
                            <div className="footerHeader mb-2">{appFooterLabels.Platform}</div>
                            <div className="footerList">
                            <ul className='p-0 m-0 list-unstyled'>
                                <li><Link className='footerAnchor' to={'/affiliate-page'}>{appFooterLabels.Affiliate_Program}</Link></li>
                                <li><Link className='footerAnchor' to={'#'}>{appFooterLabels.FAQ}</Link></li>
                                <li><Link className='footerAnchor' to={'#'}>{appFooterLabels.DownloadApp}</Link></li>
                                <li><Link className='footerAnchor' to={'#'}>{appFooterLabels.LiveSupport}</Link></li>
                            </ul>
                            </div>
                        </div>
                        <div className="col-5 px-0">
                        <div className="footerHeader mb-2">{appFooterLabels.Legal}</div>
                            <div className="footerList">
                            <ul className='p-0 m-0 list-unstyled'>
                                <li><Link className='footerAnchor' to={'/responsibility'}>{appFooterLabels.ResponsibleGambling}</Link></li>
                                <li><Link className='footerAnchor' to={'/amlpolicy'}>{appFooterLabels.AML_Policy}</Link></li>
                                <li><Link className='footerAnchor' to={'/sportspolicy'}>{appFooterLabels.SportsPolicy}</Link></li>
                                <li><Link className='footerAnchor' to={'/termsconditions'}>{appFooterLabels.TermsCondition}</Link></li>
                            </ul>
                            </div>
                        </div>
                        <div className="col-3 px-0">
                        <div className="footerHeader mb-2">{appFooterLabels.Community}</div>
                            <div className="footerList">
                            <ul className='p-0 m-0 list-unstyled'>
                                <li><Link className='footerAnchor' target='_blank' to='https://www.instagram.com/damble.io/'>{appFooterLabels.Instagram}</Link></li>
                                <li><Link className='footerAnchor' to={'#'}>{appFooterLabels.meta}</Link></li>
                                <li><Link className='footerAnchor' target='_blank' to='https://t.me/damblecommunity'>{appFooterLabels.Telegram}</Link></li>
                                <li><Link className='footerAnchor' target='_blank' to='https://discord.gg/3WQyZJ5x'>{appFooterLabels.discord}</Link></li>
                                <li><Link className='footerAnchor' target='_blank' to='https://x.com/Dambleio'>{appFooterLabels.TwitterX} </Link></li>
                            </ul>
                            </div>
                        </div>
                        </div> */}
            <div className="row gx-4 mx-0 borderLight ">
              <div className="col-lg-9 px-0 col-12 pt-3">
                <div className="row mx-0">
                  <div className="col-lg-2 col-6 mb-3">
                    <div className="footerHead">Info</div>
                    <ul className="m-0 p-0">
                      {/* <li className='list-unstyled'><Link className='footerlinks' to={`${ROUTE_CONST.AFFILIATE_PAGE}`}> {appFooterLabels.Affiliate_Program}</Link></li> */}
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          {" "}
                          About Us
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Careers
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Partnership
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          TOS
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Policies
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Provably Fair
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Official Sites
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Blog
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-2 col-6 text-end text-lg-start mb-3">
                    <div className="footerHead">Games</div>
                    <ul className="m-0 p-0">
                      {/* <li className='list-unstyled'><Link className='footerlinks' to={`${ROUTE_CONST.AFFILIATE_PAGE}`}> {appFooterLabels.Affiliate_Program}</Link></li> */}
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          {" "}
                          Crash
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Dice
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Roulette
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Hilo
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Casino
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Battles
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3 col-6 mb-3">
                    <div className="footerHead">Sports</div>
                    <ul className="m-0 p-0">
                      {/* <li className='list-unstyled'><Link className='footerlinks' to={`${ROUTE_CONST.AFFILIATE_PAGE}`}> {appFooterLabels.Affiliate_Program}</Link></li> */}
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          E-Sport betting
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          match Betting
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Horse Racing{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3 col-6 text-end text-lg-start mb-3">
                    <div className="footerHead">Promotional</div>
                    <ul className="m-0 p-0">
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Rewards
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Affilate
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          King of the Hill
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Redeem Code
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-2 col-6 mb-3">
                    <div className="footerHead">Support</div>
                    <ul className="m-0 p-0">
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Contact Us
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          FAQ
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Live Support
                        </Link>
                      </li>
                    </ul>
                  </div>

                  {/* <div className="col-2 px-0">
                                        <div className='footerHead'>{appFooterLabels.Community}</div>
                                        <ul className='m-0 p-0'>
                                            <li className='list-unstyled'><Link className='footerlinks' target='_blank' to='https://www.instagram.com/damble.io/'>{appFooterLabels.Instagram}</Link></li>
                                            <li className='list-unstyled'><Link className='footerlinks' target='_blank' to='https://www.meta.com'>{appFooterLabels.meta}</Link></li>
                                            <li className='list-unstyled'><Link className='footerlinks' target='_blank' to='https://t.me/damblecommunity'>{appFooterLabels.Telegram}</Link></li>
                                            <li className='list-unstyled'><Link className='footerlinks' target='_blank' to='https://discord.gg/3WQyZJ5x'>{appFooterLabels.discord}</Link></li>
                                            <li className='list-unstyled'><Link className='footerlinks' target='_blank' to='https://x.com/Dambleio'>{appFooterLabels.TwitterX} </Link></li>
                                        </ul>
                                    </div> */}
                  {/* <div className="col-xxl-2 col-lg-2 ps-0 pt-3">
                                        <div className="row mx-0 gap-4">
                                            <div className="col-12 px-0">
                                                <div className="socialBox">
                                                    <div className="row mx-0 align-items-center">
                                                        <div className="col px-0">
                                                            <div className="row gap-2 mx-0">
                                                                <div className="col-auto px-0"><div className="apple"><img src={apple} alt="" /></div></div>
                                                                <div className="col px-0">
                                                                    <div className='socialTxtGray'>Application</div>
                                                                    <div className='socialTxtwhite'>For IOS</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto px-0">
                                                            <div className="question"><img src={question} alt="" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 px-0">
                                                <div className="socialBox">
                                                    <div className="row mx-0 align-items-center">
                                                        <div className="col px-0">
                                                            <div className="row gap-2 mx-0">
                                                                <div className="col-auto px-0"><div className="apple"><img src={android} alt="" /></div></div>
                                                                <div className="col px-0">
                                                                    <div className='socialTxtGray'>Application</div>
                                                                    <div className='socialTxtwhite'>For Android</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto px-0">
                                                            <div className="question"><img src={question} alt="" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 px-0">
                                                <div className="socialBox">
                                                    <div className="row mx-0 align-items-center">
                                                        <div className="col px-0">
                                                            <div className="row gap-2 mx-0">
                                                                <div className="col-auto px-0"><div className="apple"><img src={wind} alt="" /></div></div>
                                                                <div className="col px-0">
                                                                    <div className='socialTxtGray'>Application</div>
                                                                    <div className='socialTxtwhite'>For Windows</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto px-0">
                                                            <div className="question"><img src={question} alt="" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
              <div className="dividerFooter d-md-none d-block"></div>
              <div className="col-lg-3 col-12 text-center">
                <div className="footerHead mb-3">Join Our Community</div>
                <div className="d-flex justify-content-center gap-2">
                  <div className="footerSocialIcons d-flex align-items-center justify-content-center">
                    <img src={shareIcon} alt="" />
                  </div>
                  <div className="footerSocialIcons d-flex align-items-center justify-content-center">
                    <img src={instagramIcon} alt="" />
                  </div>
                  <div className="footerSocialIcons d-flex align-items-center justify-content-center">
                    <img src={twitterIcon} alt="" />
                  </div>
                  <div className="footerSocialIcons d-flex align-items-center justify-content-center">
                    <img src={facebookIcon} alt="" />
                  </div>
                </div>

                <div
                  onClick={() => setShowLanguage(true)}
                  className="  cursor-pointer footerLanguageBox d-flex align-items-center justify-content-center "
                >
                  <div className="footerLanguage mt-3 py-xl-0 py-2">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center gap-2">
                        <h6 class="mb-0">Language : </h6>
                        <div className="d-flex align-items-center gap-2">
                          <img src={Country} alt="" />
                          <h6 class="mb-0">English</h6>
                        </div>
                      </div>
                      <div className="footerdropdown d-flex align-items-center justify-content-center d-xl-block d-none py-lg-0 py-2">
                        <img src={arrow} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-3 mt-4 mb-3">
                  <div className="itchimg">
                    <img className="h-100 w-100" src={sigma} alt="" />
                  </div>
                  <div className="itchimg">
                    <img className="h-100 w-100" src={itech} alt="" />
                  </div>
                  <div className="itchimg">
                    <img className="h-100 w-100" src={eighteen} alt="" />
                  </div>
                  <div className="itchimg">
                    <img className="h-100 w-100" src={responsible} alt="" />
                  </div>
                </div>
              </div>
              <div className="dividerFooter"></div>
            </div>
            <div className="row px-3">
              <div className="col-12 d-flex justify-content-center mb-4">
                <div className="footer-GCB-Logo">
                  <img src={GCB} alt="" />
                </div>
              </div>
              <div className="col-12">
                <div className="">
                  <p className="footer-para">
                    Memecoin.io is operated by Dream iGaming Tech N.V, a company
                    registered in Curaçao with its registered address at
                    Zuikertuintjeweg Z/N (Zuikertuin Tower), Curaçao. The
                    platform is operated independently and adheres to internal
                    compliance and responsible gaming standards. Access to the
                    platform may be restricted in certain jurisdictions based on
                    local regulations.
                  </p>
                </div>
              </div>
            </div>
            <div class="partnershipBox mb-4">
              <div className="partnershipt mb-4">
                {appFooterLabels.Partnerships_Networks}
              </div>
              <div className="row justify-content-between mx-0">
                <div className="col-12">
                  <div className="row mx-0 row-cols-9 justify-content-between">
                    <div className="col-auto px-0">
                      <div className="networkImg">
                        <img src={n1} alt="" />
                      </div>
                    </div>
                    <div className="col-auto px-0">
                      <div className="networkImg">
                        <img src={n2} alt="" />
                      </div>
                    </div>
                    <div className="col-auto px-0">
                      <div className="networkImg">
                        <img src={n3} alt="" />
                      </div>
                    </div>
                    <div className="col-auto px-0">
                      <div className="networkImg">
                        <img src={n4} alt="" />
                      </div>
                    </div>
                    <div className="col-auto px-0">
                      <div className="networkImg">
                        <img src={n5} alt="" />
                      </div>
                    </div>
                    <div className="col-auto px-0">
                      <div className="networkImg">
                        <img src={n6} alt="" />
                      </div>
                    </div>
                    <div className="col-auto px-0">
                      <div className="networkImg">
                        <img src={n7} alt="" />
                      </div>
                    </div>
                    <div className="col-auto px-0">
                      <div className="networkImg">
                        <img src={n8} alt="" />
                      </div>
                    </div>
                    <div className="col-auto px-0">
                      <div className="networkImg">
                        <img src={n9} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="row mx-0 row-cols-9 justify-content-between mt-3">
                    <div className="col-auto px-0">
                      <div className="networkImg">
                        <img src={n10} alt="" />
                      </div>
                    </div>
                    <div className="col-auto px-0">
                      <div className="networkImg">
                        <img src={n11} alt="" />
                      </div>
                    </div>
                    <div className="col-auto px-0">
                      <div className="networkImg">
                        <img src={n12} alt="" />
                      </div>
                    </div>
                    <div className="col-auto px-0">
                      <div className="networkImg">
                        <img src={n13} alt="" />
                      </div>
                    </div>
                    <div className="col-auto px-0">
                      <div className="networkImg">
                        <img src={n14} alt="" />
                      </div>
                    </div>
                    <div className="col-auto px-0">
                      <div className="networkImg">
                        <img src={n15} alt="" />
                      </div>
                    </div>
                    <div className="col-auto px-0">
                      <div className="networkImg">
                        <img src={n16} alt="" />
                      </div>
                    </div>
                    <div className="col-auto px-0">
                      <div className="networkImg">
                        <img src={n17} alt="" />
                      </div>
                    </div>
                    <div className="col-auto px-0">
                      <div className="networkImg">
                        <img src={n18} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col d-flex align-items-center justify-content-center">
              <div className="d-flex align-items-center gap-2">
                <div className="footer6 footer-desktop">
                  <img src={footer6} alt="" />
                </div>
                <div className="footer5 footer-desktop">
                  <img src={footer5} alt="" />
                </div>
                <div className="footer4 footer-desktop">
                  <img src={footer4} alt="" />
                </div>
                <div className="footer3 footer-desktop">
                  <img src={footer3} alt="" />
                </div>
                <div className="footer2 footer-desktop">
                  <img src={footer2} alt="" />
                </div>
                <div className="footer1 footer-desktop">
                  <img src={footer1} alt="" />
                </div>
                <div className="footer0 footer-desktop">
                  <h6 className="mb-0 plus-more">45+</h6>
                </div>
              </div>
            </div>
            {/* <div className="row justify-content-between flex-nowrap w-100 mx-0">
                            <div className="col-auto px-0 flex-shrink-1">
                                <div className="brandImg"><img src={fiba} alt="" /></div>
                            </div>
                            <div className="col-auto px-0 flex-shrink-1">
                                <div className="brandImg"><img src={atp} alt="" /></div>
                            </div>
                            <div className="col-auto px-0 flex-shrink-1">
                                <div className="brandImg"><img src={itf} alt="" /></div>
                            </div>
                            <div className="col-auto px-0 flex-shrink-1">
                                <div className="brandImg"><img src={wta} alt="" /></div>
                            </div>
                            <div className="col-auto px-0 flex-shrink-1">
                                <div className="brandImg"><img src={ufc} alt="" /></div>
                            </div>
                            <div className="col-auto px-0 flex-shrink-1">
                                <div className="brandImg"><img src={uefa} alt="" /></div>
                            </div>
                            <div className="col-auto px-0 flex-shrink-1">
                                <div className="brandImg"><img src={fifa} alt="" /></div>
                            </div>
                            <div className="col-auto px-0 flex-shrink-1">
                                <div className="brandImg"><img src={nhl} alt="" /></div>
                            </div>
                        </div>
                        <div className="dividerFooter"></div>
                        <div className="row justify-content-between mx-0">
                            <div className="col-auto px-0">
                                <div className="socialImg"><img src={insta} alt="" /></div>
                            </div>
                            <div className="col-auto px-0">
                                <div className="socialImg"><img src={twitter} alt="" /></div>
                            </div>
                            <div className="col-auto px-0">
                                <div className="socialImg"><img src={joystick} alt="" /></div>
                            </div>
                            <div className="col-auto px-0">
                                <div className="socialImg"><img src={telegram} alt="" /></div>
                            </div>
                            <div className="col-auto px-0">
                                <div className="socialImg"><img src={emog} alt="" /></div>
                            </div>
                            <div className="col-auto px-0">
                                <div className="socialImg"><img src={facebook} alt="" /></div>
                            </div>
                        </div>
                        <div className="dividerFooter"></div>
                       
                        <div className="row justify-content-between flex-nowrap mx-0 pt-5">
                            <div className="col-auto px-0 flex-shrink-1">
                                <div className="sigmaImg"><img src={sigma} alt="" /></div>
                            </div>
                            <div className="col-auto px-0 flex-shrink-1">
                                <div className="sigmaImg"><img src={itech} alt="" /></div>
                            </div>
                            <div className="col-auto px-0 flex-shrink-1">
                                <div className="sigmaImg"><img src={eighteen} alt="" /></div>
                            </div>
                            <div className="col-auto px-0 flex-shrink-1">
                                <div className="sigmaImg"><img src={responsible} alt="" /></div>
                            </div>
                        </div> */}
            <div className="row">
              <div className="col-12 copyRightTxt px-3">
                Copyright ©2024 PLATFORM NAME ALL RIGHTS RESERVED.
                1BTC=$106,140.94
              </div>
            </div>
          </div>
        </div>

        {/* for desktop */}
        <div className="footer-bottom-section container-fluid containerfluidFooterDesktop px-0 d-xl-block d-none">
          <div className="container">
            <div className="row px-3">
              <div className="col-8 pt-3">
                <div className="row mx-0">
                  <div className="col-2 ">
                    <div className="footerHead">Info</div>
                    <ul className="m-0 p-0">
                      {/* <li className='list-unstyled'><Link className='footerlinks' to={`${ROUTE_CONST.AFFILIATE_PAGE}`}> {appFooterLabels.Affiliate_Program}</Link></li> */}
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          {" "}
                          About Us
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Careers
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Partnership
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          TOS
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Policies
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Provably Fair
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Official Sites
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Blog
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-2 ">
                    <div className="footerHead">Games</div>
                    <ul className="m-0 p-0">
                      {/* <li className='list-unstyled'><Link className='footerlinks' to={`${ROUTE_CONST.AFFILIATE_PAGE}`}> {appFooterLabels.Affiliate_Program}</Link></li> */}
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          {" "}
                          Crash
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Dice
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Roulette
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Hilo
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Casino
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Battles
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-3 ">
                    <div className="footerHead">Sports</div>
                    <ul className="m-0 p-0">
                      {/* <li className='list-unstyled'><Link className='footerlinks' to={`${ROUTE_CONST.AFFILIATE_PAGE}`}> {appFooterLabels.Affiliate_Program}</Link></li> */}
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          E-Sport betting
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          match Betting
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Horse Racing{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-3">
                    <div className="footerHead">Promotional</div>
                    <ul className="m-0 p-0">
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Rewards
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Affilate
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          King of the Hill
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Redeem Code
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-2">
                    <div className="footerHead">Support</div>
                    <ul className="m-0 p-0">
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Contact Us
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          FAQ
                        </Link>
                      </li>
                      <li className="list-unstyled">
                        <Link className="footerlinks" to="">
                          Live Support
                        </Link>
                      </li>
                    </ul>
                  </div>

                  {/* <div className="col-2 px-0">
                                        <div className='footerHead'>{appFooterLabels.Community}</div>
                                        <ul className='m-0 p-0'>
                                            <li className='list-unstyled'><Link className='footerlinks' target='_blank' to='https://www.instagram.com/damble.io/'>{appFooterLabels.Instagram}</Link></li>
                                            <li className='list-unstyled'><Link className='footerlinks' target='_blank' to='https://www.meta.com'>{appFooterLabels.meta}</Link></li>
                                            <li className='list-unstyled'><Link className='footerlinks' target='_blank' to='https://t.me/damblecommunity'>{appFooterLabels.Telegram}</Link></li>
                                            <li className='list-unstyled'><Link className='footerlinks' target='_blank' to='https://discord.gg/3WQyZJ5x'>{appFooterLabels.discord}</Link></li>
                                            <li className='list-unstyled'><Link className='footerlinks' target='_blank' to='https://x.com/Dambleio'>{appFooterLabels.TwitterX} </Link></li>
                                        </ul>
                                    </div> */}
                  {/* <div className="col-xxl-2 col-lg-2 ps-0 pt-3">
                                        <div className="row mx-0 gap-4">
                                            <div className="col-12 px-0">
                                                <div className="socialBox">
                                                    <div className="row mx-0 align-items-center">
                                                        <div className="col px-0">
                                                            <div className="row gap-2 mx-0">
                                                                <div className="col-auto px-0"><div className="apple"><img src={apple} alt="" /></div></div>
                                                                <div className="col px-0">
                                                                    <div className='socialTxtGray'>Application</div>
                                                                    <div className='socialTxtwhite'>For IOS</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto px-0">
                                                            <div className="question"><img src={question} alt="" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 px-0">
                                                <div className="socialBox">
                                                    <div className="row mx-0 align-items-center">
                                                        <div className="col px-0">
                                                            <div className="row gap-2 mx-0">
                                                                <div className="col-auto px-0"><div className="apple"><img src={android} alt="" /></div></div>
                                                                <div className="col px-0">
                                                                    <div className='socialTxtGray'>Application</div>
                                                                    <div className='socialTxtwhite'>For Android</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto px-0">
                                                            <div className="question"><img src={question} alt="" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 px-0">
                                                <div className="socialBox">
                                                    <div className="row mx-0 align-items-center">
                                                        <div className="col px-0">
                                                            <div className="row gap-2 mx-0">
                                                                <div className="col-auto px-0"><div className="apple"><img src={wind} alt="" /></div></div>
                                                                <div className="col px-0">
                                                                    <div className='socialTxtGray'>Application</div>
                                                                    <div className='socialTxtwhite'>For Windows</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto px-0">
                                                            <div className="question"><img src={question} alt="" /></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
              <div className="col-4 text-center">
                <div className="footerHead">Join Our Community</div>
                <div className="d-flex justify-content-center gap-2">
                  <div className="footerSocialIcons d-flex align-items-center justify-content-center">
                    <img src={shareIcon} alt="" />
                  </div>
                  <div className="footerSocialIcons d-flex align-items-center justify-content-center">
                    <img src={instagramIcon} alt="" />
                  </div>
                  <div className="footerSocialIcons d-flex align-items-center justify-content-center">
                    <img src={twitterIcon} alt="" />
                  </div>
                  <div className="footerSocialIcons d-flex align-items-center justify-content-center">
                    <img src={facebookIcon} alt="" />
                  </div>
                </div>
                <div
                  onClick={() => setShowLanguage(true)}
                  className="  cursor-pointer footerLanguageBox d-flex align-items-center justify-content-center "
                >
                  <div className="footerLanguage mt-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center gap-2">
                        <h6 class="mb-0">Language : </h6>
                        <div className="d-flex align-items-center gap-2">
                          <img src={Country} alt="" />
                          <h6 class="mb-0">English</h6>
                        </div>
                      </div>
                      <div className="footerdropdown d-flex align-items-center justify-content-center">
                        <img src={arrow} alt="" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center gap-3 mt-3">
                  <div className="itchimg">
                    <img className="h-100 w-100" src={sigma} alt="" />
                  </div>
                  <div className="itchimg">
                    <img className="h-100 w-100" src={itech} alt="" />
                  </div>
                  <div className="itchimg">
                    <img className="h-100 w-100" src={eighteen} alt="" />
                  </div>
                  <div className="itchimg">
                    <img className="h-100 w-100" src={responsible} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="dividerFooter mb-0"></div>
            {/* <div className="row">
                            <div className="col-auto">
                                <div className="row justify-content-between mx-0 flex-nowrap">
                                    <div className="col-auto px-0 flex-shrink-1" onClick={()=> window.open('https://www.instagram.com/damble.io/', '_blank')}>
                                        <div className="socialImg"><img src={insta} alt="" /></div>
                                    </div>
                                    <div className="col-auto px-0 flex-shrink-1" onClick={()=> window.open('https://x.com/Dambleio', '_blank')}>
                                        <div className="socialImg"><img src={twitter} alt="" /></div>
                                    </div>
                                    <div className="col-auto px-0 flex-shrink-1" onClick={()=> window.open('https://discord.gg/3WQyZJ5x', '_blank')}>
                                        <div className="socialImg"><img src={joystick} alt="" /></div>
                                    </div>
                                    <div className="col-auto px-0 flex-shrink-1" onClick={()=> window.open('https://t.me/damblecommunity', '_blank')}>
                                        <div className="socialImg"><img src={telegram} alt="" /></div>
                                    </div>
                                    <div className="col-auto px-0 flex-shrink-1">
                                        <div className="socialImg"><img src={emog} alt="" /></div>
                                    </div>
                                    <div className="col-auto px-0 flex-shrink-1">
                                        <div className="socialImg"><img src={facebook} alt="" /></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row justify-content-end w-100 flex-nowrap mx-0">
                                    <div className="col-auto px-0 flex-shrink-1">
                                        <div className="brandImg"><img src={fiba} alt="" /></div>
                                    </div>
                                    <div className="col-auto px-0 flex-shrink-1">
                                        <div className="brandImg"><img src={atp} alt="" /></div>
                                    </div>
                                    <div className="col-auto px-0 flex-shrink-1">
                                        <div className="brandImg"><img src={itf} alt="" /></div>
                                    </div>
                                    <div className="col-auto px-0 flex-shrink-1">
                                        <div className="brandImg"><img src={wta} alt="" /></div>
                                    </div>
                                    <div className="col-auto px-0 flex-shrink-1">
                                        <div className="brandImg"><img src={ufc} alt="" /></div>
                                    </div>
                                    <div className="col-auto px-0 flex-shrink-1">
                                        <div className="brandImg"><img src={uefa} alt="" /></div>
                                    </div>
                                    <div className="col-auto px-0 flex-shrink-1">
                                        <div className="brandImg"><img src={fifa} alt="" /></div>
                                    </div>
                                    <div className="col-auto px-0 flex-shrink-1">
                                        <div className="brandImg"><img src={nhl} alt="" /></div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            <div className="row  align-items-center gap-4 footer-middle-section">
              <div className="col-auto">
                <div className="row justify-content-between mx-0 flex-nowrap">
                  <div className="footer-heading">Partnerships & Networks</div>
                </div>
              </div>
              <div className="col">
                <div className="row mx-0 justify-content-between">
                  <div className="col-auto px-0">
                    <div className="networkImg">
                      <img src={n1} alt="" />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <div className="networkImg">
                      <img src={n2} alt="" />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <div className="networkImg">
                      <img src={n3} alt="" />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <div className="networkImg">
                      <img src={n4} alt="" />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <div className="networkImg">
                      <img src={n5} alt="" />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <div className="networkImg">
                      <img src={n6} alt="" />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <div className="networkImg">
                      <img src={n7} alt="" />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <div className="networkImg">
                      <img src={n8} alt="" />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <div className="networkImg">
                      <img src={n9} alt="" />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <div className="networkImg">
                      <img src={n10} alt="" />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <div className="networkImg">
                      <img src={n11} alt="" />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <div className="networkImg">
                      <img src={n12} alt="" />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <div className="networkImg">
                      <img src={n13} alt="" />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <div className="networkImg">
                      <img src={n14} alt="" />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <div className="networkImg">
                      <img src={n15} alt="" />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <div className="networkImg">
                      <img src={n16} alt="" />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <div className="networkImg">
                      <img src={n17} alt="" />
                    </div>
                  </div>
                  <div className="col-auto px-0">
                    <div className="networkImg">
                      <img src={n18} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row px-3 mt-3 pb-2">
              <div className="col-auto">
                <div className="footer-GCB-Logo">
                  <img src={GCB} alt="" />
                </div>
              </div>
              <div className="col">
                <div className="">
                  <p className="footer-para">
                    Memecoin.io is operated by Dream iGaming Tech N.V, a company
                    registered in Curaçao with its registered address at
                    Zuikertuintjeweg Z/N (Zuikertuin Tower), Curaçao. The
                    platform is operated independently and adheres to internal
                    compliance and responsible gaming standards. Access to the
                    platform may be restricted in certain jurisdictions based on
                    local regulations.
                  </p>
                </div>
              </div>
              <div className="col-auto">
                <div className="d-flex align-items-center gap-2">
                  <div className="footer6 footer-desktop">
                    <img src={footer6} alt="" />
                  </div>
                  <div className="footer5 footer-desktop">
                    <img src={footer5} alt="" />
                  </div>
                  <div className="footer4 footer-desktop">
                    <img src={footer4} alt="" />
                  </div>
                  <div className="footer3 footer-desktop">
                    <img src={footer3} alt="" />
                  </div>
                  <div className="footer2 footer-desktop">
                    <img src={footer2} alt="" />
                  </div>
                  <div className="footer1 footer-desktop">
                    <img src={footer1} alt="" />
                  </div>
                  <div className="footer0 footer-desktop">
                    <h6 className="mb-0 plus-more">45+</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
