import { useContext } from "react"
import { ReferralModalContext } from "../context/ReferralContext"


export const useReferralModal = () => {
    const { isReferral, setIsReferral, isReferralInput, setIsReferralInput } = useContext(ReferralModalContext)
    return {
        isReferral,
        setIsReferral,
        isReferralInput,
        setIsReferralInput
    }
}