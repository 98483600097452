import React from 'react'
import { useTranslation } from 'react-i18next'
function Responsibility() {
    const { t } = useTranslation()

    const responsibility = t("responsibility", { returnObjects: true })
    return (
        <div className='aml-policy-section'>
            <div className="heading">
                {responsibility.GAMBLING_WITH_RESPONSIBILITY}
            </div>
            <div className="sub-heading">
                {responsibility.Last_updated_August}
            </div>
            <div className="sub-txt pt-2">
                {responsibility.information}
            </div>
            <div className="sub-txt pb-2">
                {responsibility.information_p}
            </div>
            <div className="sub-heading">
                {responsibility.Interpretation}
            </div>
            <div className="sub-txt py-2">
                {responsibility.Interpretation_p1}
            </div>
            <div className="sub-txt py-2">
                {responsibility.Interpretation_p2}
            </div>
            <div className="sub-heading">
                {responsibility.Definitions}
            </div>
            <div className="sub-txt pt-2">
                {responsibility.Definitions_p1}
            </div>
            <div className="list">
                <ul>
                    <li className='sub-txt'>
                        {responsibility.Definitions_p1_b1}
                    </li>
                    <li className='sub-txt'>
                        {responsibility.Definitions_p1_b2}
                    </li>
                    <li className='sub-txt'>
                        {responsibility.Definitions_p1_b3}
                    </li>
                    <li className='sub-txt'>
                        {responsibility.Definitions_p1_b4}  <span className='link-span'><a href="javascript:;">www.damble.io.</a></span>
                    </li>
                </ul>
            </div>
            <div className="sub-txt py-2">
                {responsibility.Definitions_p2}
            </div>
            <div className="sub-heading">
                {responsibility.Responsible_Gambling_and_Self_Exclusion}
            </div>
            <div className="sub-txt pt-2">
                {responsibility.Responsible_Gambling_and_Self_Exclusion_p1}
            </div>
            <div className="sub-txt py-2">
                {responsibility.Responsible_Gambling_and_Self_Exclusion_p2}
            </div>
            <div className="sub-txt py-2">
                {responsibility.Responsible_Gambling_and_Self_Exclusion_p3}
            </div>
            <div className="sub-heading">
                {responsibility.Information_and_contact}
            </div>
            <div className="sub-txt pt-2">
                {responsibility.Information_and_contact_p1}
            </div>
            <div className="sub-txt pt-2">
                {responsibility.Information_and_contact_p2}
            </div>
            <div className="sub-txt pt-2">
                {responsibility.Information_and_contact_p3}
            </div>
            <div className="sub-txt pt-2">
                <span className='link-span'><a href="javascript:;">https://www.begambleaware.org/gambling-problems/do-i-have-a-gambling-problem/</a></span>
            </div>
            <div className="sub-txt pt-2">
                {responsibility.Information_and_contact_p4}
            </div>
            <div className="sub-txt pt-2">
                <span className='link-span'><a href="javascript:;">https://www.begambleaware.org/safer-gambling/</a></span>
            </div>
            <div className="sub-heading">
                {responsibility.Helpful_hints_for_responsible_gambling}
            </div>
            <div className="sub-txt py-2">
                {responsibility.Helpful_hints_for_responsible_gambling_p1}
            </div>
            <div className="list">
                <ul>
                    <li className='sub-txt pt-2'>
                        {responsibility.Helpful_hints_for_responsible_gambling_p1_b1}
                    </li>
                    <div className="sub-txt pb-2">
                        {responsibility.Helpful_hints_for_responsible_gambling_p1_b2}
                    </div>
                    <li className='sub-txt pt-2'>
                        {responsibility.Helpful_hints_for_responsible_gambling_p1_b3}
                    </li>
                    <div className="sub-txt pb-2">
                        {responsibility.Helpful_hints_for_responsible_gambling_p1_b4}
                    </div>
                    <li className='sub-txt pt-2'>
                        {responsibility.Helpful_hints_for_responsible_gambling_p1_b5}
                    </li>
                    <div className="sub-txt pb-2">
                        {responsibility.Helpful_hints_for_responsible_gambling_p1_b6}
                    </div>
                    <li className='sub-txt pt-2'>
                        {responsibility.Helpful_hints_for_responsible_gambling_p1_b7}
                    </li>
                    <div className="sub-txt pb-2">
                        {responsibility.Helpful_hints_for_responsible_gambling_p1_b8}
                    </div>
                    <li className='sub-txt pt-2'>
                        {responsibility.Helpful_hints_for_responsible_gambling_p1_b9}
                    </li>
                    <div className="sub-txt pb-2">
                        {responsibility.Helpful_hints_for_responsible_gambling_p1_b10}
                    </div>
                </ul>
            </div>
            <div className="sub-heading">
                {responsibility.Minor_Protection}
            </div>
            <div className="sub-txt py-2">
                {responsibility.Minor_Protection_p1}
            </div>
            <div className="sub-txt py-2">
                {responsibility.Minor_Protection_p2}
            </div>
            <div className="sub-txt py-2">
                {responsibility.Minor_Protection_p3}
            </div>
            <div className="sub-txt py-2">
                <span className='link-span'><a href="javascript:;">https://famisafe.wondershare.com/internet-filter/best-internet-filters.html</a></span>
            </div>
            <div className="sub-heading">
                {responsibility.Self_Exclusion}
            </div>
            <div className="sub-txt py-2">
                {responsibility.Self_Exclusion_p1}
            </div>
            <div className="sub-txt py-2">
                {responsibility.Self_Exclusion_p2}
            </div>
            <div className="list">
                <ul>
                    <li className='sub-txt'>
                        {responsibility.Self_Exclusion_p3}
                    </li>
                    <li className='sub-txt'>
                        {responsibility.Self_Exclusion_p4}
                    </li>
                </ul>
            </div>
            <div className="sub-txt py-2">
                {responsibility.Self_Exclusion_p5}
            </div>
        </div>
    )
}

export default Responsibility
